import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormGroupDirective,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SuccessSnackbarComponent } from '../snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../snackbar/error-snackbar/error-snackbar.component';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/providers/translation.service';
import { ReadingJsonService } from 'src/app/providers/readingJson.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-session-upload-overlay',
  templateUrl: './session-upload-overlay.component.html',
  styleUrls: ['./session-upload-overlay.component.scss'],
})
export class SessionUploadOverlayComponent implements OnInit {
  keys: number[] = [];
  isLoading: boolean = false;
  uploadprogress: number = 0;
  color = 'primary';
  mode = 'determinate';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  translatedText;
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private readingJsonService: ReadingJsonService,
    public snackBar: MatSnackBar,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['keys'] !== 'undefined') {
        this.keys = this.injectedData['keys'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.readingJsonService.currentnLoadingStatus
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.uploadprogress = status.progress;
      });
    this.processAllEntries();
  }

  async processAllEntries() {
    if (this.keys.length > 0) {
      for (let i = 0; i < this.keys.length; i++) {
        const key = this.keys[i];
        try {
          const entry =
            await this.readingJsonService.getSessionFromLocalDatabase(key);
          await this.readingJsonService
            .uploadSession(
              entry.session,
              entry.user_uuid,
              entry.user_task_uuid,
              entry.contentLanguage,
              entry.task,
              entry.time_read,
              entry.num_images,
              key,
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .toPromise()
            .then((data: any) => {
              console.log(data);
              if (
                data['processing_status'] !== undefined &&
                data['processing_status'] === 'done'
              ) {
                this.uploadprogress = 100;
              }
            })
            .catch((error: any) => {
              this.uploadprogress = 100;
              console.log(error);
            });
        } catch (error) {
          console.error(
            'Error fetching data entry from local database and uploading it:',
            error,
          );
        }
      }
      console.log('All sessions uploaded');
      this.dialog.closeAll();
    } else {
      console.log('No keys found in the array.');
    }
  }
}
