import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-diagnostic-progress',
  templateUrl: './diagnostic-progress.component.html',
  styleUrls: ['./diagnostic-progress.component.scss'],
})
export class DiagnosticProgressComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input('progress') progress: any;
  progressValue = 0;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private translationService: TranslationService) {}

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress.previousValue != changes.progress.currentValue) {
      //setTimeout(() => {
      this.progressValue = this.progress;
      // }, 2000);
    }
  }
}
