<div class="trial-banner-wrapper">
  <div class="trial-banner-section">
    <img src="../../assets/new-license-overlay.svg" alt="icon with user" />
  </div>
  <button
    mat-mini-fab
    type="button"
    [mat-dialog-close]="'license-mapping-info'"
    class="close-button close-button--right"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="new-license-overlay-wrap">
    <h3 class="h3 h3--bold">{{ translatedText.new_license_overlay_title }}</h3>
    <p class="paragraph">{{ translatedText.new_license_overlay_text_1 }}</p>
    <p class="paragraph">
      {{ translatedText.new_license_overlay_text_2 }}
    </p>
    <p class="paragraph">
      {{ translatedText.new_license_overlay_text_3 }}
    </p>
    <p class="paragraph">
      {{ translatedText.new_license_overlay_text_4 }}
      <span class="paragraph paragraph--bold green-text">{{ translatedText.new_license_overlay_text_5 }}</span>

      {{ translatedText.new_license_overlay_text_6 }}
    </p>

    <p class="paragraph">
      {{ translatedText.new_license_overlay_text_7 }}<br />
      <span class="green-text paragraph paragraph--bold"> {{ translatedText.new_license_overlay_text_8 }} </span>
    </p>
    <div class="button-wrap">
      <!-- <button mat-flat-button color="secondary" (click)="closeDialog()">In Ordnung</button> -->
      <button
        mat-flat-button
        color="primary"
        type="button"
        (click)="openExternalUrl('https://support.laletu.de/hc/de/articles/14559661309724')"
      >
        {{ translatedText.new_license_overlay_text_9 }}
      </button>
    </div>
  </div>
</div>
