<!-- <button mat-icon-button type="button" class="circle light" [mat-dialog-close]="true"  matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button> -->
<div class="session-upload_container">
  <div class="session-upload-wrap">
    <img src="../../assets/pause.svg" alt="session-upload" />
    <h3 class="h3 h3--bold">{{ translatedText.session_upload_title }}</h3>
    <p class="paragraph paragraph--xl">
      {{ translatedText.session_upload_desc }}
    </p>
    <mat-progress-bar [color]="color" [mode]="mode" [(value)]="uploadprogress">
    </mat-progress-bar>
    <p>{{ uploadprogress }}%</p>
  </div>
</div>
