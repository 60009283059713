import { Component, OnInit, Inject, Optional } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { StudentService } from '../../providers/student.service';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from '../../providers/task.service';
import { MatDialog } from '@angular/material/dialog';
import { ArchivmentsOverlayComponent } from 'src/app/helpers/archivments-overlay/archivments-overlay.component';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-student-results',
  templateUrl: './student-results.component.html',
  styleUrls: ['./student-results.component.scss'],
})
export class StudentResultsComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  subscription: Subscription;
  studentId: string;
  private studentResultSub: Subscription;
  medals = {
    gold: 0,
    silver: 0,
    bronze: 0,
  };
  tasksPerformed = 0;
  wordsRead = 0;
  readingPoints = 0;
  taskResults = [];
  awards;
  groupReadingTime = 0;
  isHovered: boolean = false;
  translatedText;
  language: string;
  constructor(
    private authService: AuthService,
    private studentService: StudentService,
    private taskService: TaskService,
    public dialog: MatDialog,
    public translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.studentId = this.authService.getStudentId();
    console.log(this.studentId);

    //minute
    this.groupReadingTime = 0;

    this.studentService.getStudentResults(this.studentId);
    this.studentResultSub = this.studentService
      .getStudentsResultUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.tasksPerformed = res.studentWidgetData.completed_task_count;
        this.wordsRead = res.studentWidgetData.total_session_words_count;
        this.readingPoints = Math.round(
          res.studentWidgetData.average_reading_score.reading_combined_score,
        );
        this.taskResults = res.taskResults;
        // Add readingScore to tasks

        if (res?.studentWidgetData.medals == undefined) {
          this.medals.bronze = 0;
          this.medals.silver = 0;
          this.medals.gold = 0;
          this.awards = [];
        } else {
          this.medals.bronze = res?.studentWidgetData.medals.bronze;
          this.medals.silver = res?.studentWidgetData.medals.silver;
          this.medals.gold = res?.studentWidgetData.medals.gold;
          this.awards = res?.studentWidgetData.awards.map((obj) => {
            obj['hovered'] = false;
            return obj;
          });
          this.awards.forEach((award, lang) => {
            award.translatedTitle = this.translationService.getTranslatedText(
              award.title,
            );
            award.translatedDescription =
              this.translationService.getTranslatedText(award.description);

            award.translatedHoverText =
              this.translationService.getTranslatedText(award.hoverText);
          });
        }
      });
  }

  openNotificationList(): void {
    let dialogRef = this.dialog.open(ArchivmentsOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'archivments-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'archivments-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  grownTime() {
    const dividend = this.groupReadingTime / 60;
    const divisor = 5;
    const remainder = dividend % divisor;
    return remainder;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
