import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService, GroupData } from 'src/app/providers/admin.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-license-check',
  templateUrl: './license-check.component.html',
  styleUrls: ['./license-check.component.scss'],
})
export class LicenseCheckComponent implements OnInit, OnDestroy {
  licenseForm: FormGroup;
  rForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isFormView: boolean = true;
  licenseView: boolean = false;
  isApplyView: boolean = false;
  displayedColumns: string[] = [
    'master_license_key',
    'school',
    'tag',
    'used',
    'diagnostic',
    'training',
  ];
  dataSource: any;
  isLoading: boolean = false;
  licenseInfo: any = [];
  licenseError: boolean = false;
  licensesToSchool: any = [];
  disableButton: boolean = false;
  masterLicenseKeys = [];
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  license_status: any[] = [
    { value: 'active', viewValue: 'Verfügbar' },
    { value: 'inactive', viewValue: 'Eingelöst' },
  ];
  license_type: any[] = [
    { value: 'FO', viewValue: 'Förderung' },
    { value: 'DI', viewValue: 'Diagnose' },
    { value: 'DF', viewValue: 'Diagnose & Förderung' },
    { value: 'KI', viewValue: 'KI @ School' },
    { value: 'MK', viewValue: 'Marketing' },
    { value: 'TG', viewValue: 'Helpdesk' },
    { value: 'TF', viewValue: 'Helpdesk' },
    { value: 'YY', viewValue: 'Intern' },
    { value: 'ZZ', viewValue: 'DEV' },
  ];
  filterFormGroup: FormGroup;
  licenseArray;
  translatedText: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: unused? private or public?
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.licenseForm = this.formBuilder.group({
      license: ['', Validators.required],
    });

    this.filterFormGroup = new FormGroup({
      status: new FormControl(),
      license_type: new FormControl(),
    });
    this.getGroups();
  }

  getGroupName() {
    const selectedGroupId = this.rForm.get('groups').value;
    const selectedGroup = this.groups.find(
      (group) => group._id === selectedGroupId,
    );
    return selectedGroup
      ? selectedGroup.name + selectedGroup.school_year.slice(2)
      : '';
  }

  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .subscribe((groupData: GroupData) => {
        this.groups = groupData.data;
        console.log(this.groups);
      });
  }

  selectlicenseType(event) {
    if (event.value.length === 0) {
      this.dataSource = this.licenseArray;
      return;
    }
    this.dataSource = this.licenseArray;
    const filteredData = this.dataSource.filter((item) => {
      const masterKeyPrefix = item[0].master_license_key
        .substring(0, 2)
        .toUpperCase();
      return event.value.includes(masterKeyPrefix);
    });

    this.dataSource = filteredData;
  }

  selectStatus(event) {
    if (event.value.includes('inactive')) {
      this.dataSource = this.dataSource.filter((innerArray) => {
        return innerArray.some((item) => item.used && item.exists);
      });
    } else if (event.value.includes('active')) {
      this.dataSource = this.dataSource.filter((innerArray) => {
        return innerArray.some((item) => !item.used && item.exists);
      });
    } else {
      this.dataSource = this.licenseArray;
    }
    console.log(this.dataSource);
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection !== undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value === selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  submitForm(form: FormGroupDirective) {
    if (this.licenseForm.invalid) {
      return;
    }
    this.isLoading = true;
    console.log(this.licenseForm.value.license);
    this.licenseService
      .checkMultipleLicenses(this.licenseForm.value.license)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.dataSource = res;
          this.licenseArray = res;
          this.isLoading = false;
          this.licenseError = false;
          this.licenseView = true;
          this.disableButton = true;
        },
        error: (error: any) => {
          console.log(error);
          this.licenseError = true;
          this.licenseForm.markAsDirty();
        },
      });
  }

  getLicenseAmountWithText(tag) {
    let license = this.licenseInfo.filter((item) => item.tag === tag);
    let amount = license.length > 0 ? license[0].amount : 0;
    if (tag === 'fo') {
      let minutes = amount > 0 ? amount / 60 : amount;
      amount = minutes.toString() + ' LaLeTu Minuten';
    }
    return amount.toString();
  }

  getLicenseAmount(tag, type) {
    let amount = 0;
    if (this.licensesToSchool.length > 0) {
      this.licensesToSchool.map((el) => {
        if (el.type_key === tag) {
          if (type === 'total') {
            amount += el.amount;
          } else {
            amount += el.amount_left;
          }
        }
      });
      if (tag === 'fo') {
        amount = amount > 0 ? amount / 60 : amount;
      }
    }
    return amount;
  }

  showApplyLicense() {
    this.licenseView = false;
    this.isApplyView = true;
  }

  closeOverlay() {
    // Reset the form
    this.licenseForm.reset();
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
