import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-passwords-warn',
  templateUrl: './passwords-warn.component.html',
  styleUrls: ['./passwords-warn.component.scss'],
})
export class PasswordsWarnComponent {
  blobUrl: string = '';
  groupName: string = '';
  formattedDate: string;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
