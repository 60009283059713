import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { AdminManagingOverlayComponent } from './admin-managing-overlay.component';

@NgModule({
  declarations: [AdminManagingOverlayComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [AdminManagingOverlayComponent],
})
export class AdminManagingOverlayModule {}
