<app-teacher-delete-stepper
  #stepper
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeOverlay($event)"
>
  <!-- step 1 -->
  <cdk-step [stepControl]="rForm" #step0="cdkStep" class="teacher-delete_container">
    <div class="teacher-delete_wrap">
      <mat-icon svgIcon="streamline_outlined:warning-circle-2" class="warn-icon"> </mat-icon>
      <h2 class="h2 teacher-delete_header">
        {{ translatedText.teacher_delete_title }}
      </h2>
      <p class="paragraph paragraph--xl">{{ translatedText.teacher_delete_text }}</p>
      <div class="teacher-name-wrap">
        <mat-icon svgIcon="streamline_filled:user" class="user-icon"></mat-icon>
        <h3 class="h3 h3--bold">{{ teacherName }}</h3>
      </div>
      <div class="button_wrap">
        <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
          {{ translatedText.teacher_delete_cancel }}
        </button>
        <button mat-flat-button color="warning" type="button" class="teacher-delete_button" (click)="checkForTaskTransfer()">
          {{ translatedText.teacher_delete_accept }}
        </button>
      </div>
    </div>
  </cdk-step>
  <!-- step 2 -->
  <cdk-step *ngIf="teacherElement._tasks.length > 0">
    <div class="teacher-selection-info">
      <form [formGroup]="rForm" #formDirective="ngForm">
        <h3 class="h3 h3--bold">{{ translatedText.teacher_selection_title }}</h3>
        <p class="paragraph">{{ translatedText.teacher_selection_description }}</p>
        <mat-form-field class="group-select">
          <mat-select formControlName="teacher" panelClass="admin-group-select" disableOptionCentering>
            <mat-option class="teacher-selection" *ngFor="let teacher of teachers" [value]="teacher._id">
              <div class="teacher_with_icon teacher_with_icon--gray option">
                <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                <p class="paragraph paragraph--s">
                  {{ teacher?.name }}
                  <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                </p>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="teacher-selection-info_button">
        <button
          mat-flat-button
          [disabled]="isLoading"
          color="secondary"
          (click)="notAssignTeacher()"
        >
          {{ translatedText.teacher_selection_do_not_transfer }}
        </button>
        <button
          [disabled]="rForm.invalid || isLoading"
          mat-flat-button
          color="primary"
          (click)="assignTeacher()"
        >
          <span *ngIf="!isLoading">{{ translatedText.teacher_selection_save }}</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
  </cdk-step>
  <!-- step 3 -->
  <cdk-step>
    <div class="teacher-delete-confirmation">
      <mat-icon svgIcon="streamline_outlined:checkmark-2" class="check-icon"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.teacher_selection_success }}</h3>

      <ng-container *ngIf="!replaceTeacher">
        <p>{{ translatedText.teacher_selection_success_no_transfer }}</p>
        <button [mat-dialog-close]="'close'" mat-flat-button color="primary">{{ translatedText.teacher_selection_done }}</button>
      </ng-container>

      <ng-container *ngIf="replaceTeacher">
        <p>{{ translatedText.teacher_selection_success_transfered }}</p>
        <div class="teachers-icons-reassign">
          <div class="icons-wrap">
            <mat-icon svgIcon="streamline_filled:user" class="user-icon"></mat-icon>
            <p class="paragraph--xl light-text">{{ teacherName }}</p>
          </div>
          <div class="icons-wrap">
            <mat-icon svgIcon="streamline_outlined:arrow-right" class="arrow-icon"></mat-icon>
          </div>
          <div class="icons-wrap">
            <mat-icon svgIcon="streamline_filled:user" class="user-icon"> </mat-icon>
            <p class="paragraph--xl">{{ selectedTeacher }}</p>
          </div>
        </div>
        <button [mat-dialog-close]="'close'" mat-flat-button color="primary">
          {{ translatedText.teacher_selection_done }}
        </button>
      </ng-container>
    </div>
  </cdk-step>
</app-teacher-delete-stepper>
