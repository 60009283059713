import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Optional,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { GroupService } from '../../providers/group.service';
import { StudentService } from '../../providers/student.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { CdkStepper } from '@angular/cdk/stepper';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  AdminService,
  /*   StudentData,
  TeacherData, */
} from 'src/app/providers/admin.service';
//import { TeacherService } from 'src/app/providers/teacher.service';
import { DatePipe } from '@angular/common';
import { CsvImportStudentComponent } from 'src/app/csv-import/csv-import-students/csv-import-student.component';
import { TranslationService } from 'src/app/providers/translation.service';
//import { LicenseService } from 'src/app/providers/license.service';
//import { ValueAccessor } from '@ionic/angular/common';
import { MatTable } from '@angular/material/table';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { CancelAddStudentComponent } from 'src/app/helpers/cancel-add-student/cancel-add-student.component';
import { environment } from 'src/environments/environment';

export interface DialogData {
  currentStudentAmount: number;
}
export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

@Component({
  selector: 'app-students-add',
  templateUrl: './students-add.component.html',
  styleUrls: [
    './students-add.component.scss',
    '../../admin/admin-group/admin-group-create/admin-group-create.component.scss',
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class StudentsAddComponent implements OnInit {
  isLoading: boolean = false;
  //selectedIndex: number;
  studentAmountForm: FormGroup;
  secondFormStep: FormGroup;
  languages: any = [];
  filteredOptions: Observable<Languages[]>[] = new Array();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  STUDENT_COUNT: number = environment.whitelabel.trial_number_students;
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  groupGrade;
  groupName;
  selectGroupType;
  isTeacherFill: boolean = false;
  isAdminFill: boolean = false;
  isClosedDialog: boolean = false;
  checked: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  generatePDF;
  studentsArray = [];
  stud;
  maxBirthday;
  licenseStep;
  lastStep;
  blobUrl = null;
  groupId;
  teachers;
  teacherIds;
  studentsAmount: number;
  groupYear;
  translatedText;
  recommendedAvatar = 'animal';
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  totalCountDiagnostic: number = 0;
  totalCountTraining: number = 0;
  totalCountLeftDiagnostic: number = 0;
  totalCountLeftTraining: number = 0;
  totalStudents: any = 0;
  displayedColumns: string[] = ['fullname', 'diLicense', 'foLicense'];
  dataSource = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren('licenseCheckbox') licensecheckbox: Array<MatCheckbox>;
  fullname;
  selectedAll: any;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  diLicenseLeft;
  foLicenseLeft;
  isFormInvalid: boolean = true;
  avatar: string = 'animal';
  clickedDownload: boolean = false;

  constructor(
    //private route: ActivatedRoute,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private groupService: GroupService,
    private studentService: StudentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private adminService: AdminService,
    //private teacherService: TeacherService,
    //private licenseService: LicenseService,
    private translationService: TranslationService,

    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
      if (typeof injectedData['groupYear'] !== 'undefined') {
        this.groupYear = this.injectedData['groupYear'];
      }
      if (typeof injectedData['teachers'] !== 'undefined') {
        this.teachers = this.injectedData['teachers'];
      }
      if (typeof injectedData['totalCountDiagnostic'] !== 'undefined') {
        this.totalCountDiagnostic = this.injectedData['totalCountDiagnostic'];
      }
      if (typeof injectedData['totalCountLeftDiagnostic'] !== 'undefined') {
        this.totalCountLeftDiagnostic =
          this.injectedData['totalCountLeftDiagnostic'];
      }
      if (typeof injectedData['totalCountTraining'] !== 'undefined') {
        this.totalCountLeftTraining = this.injectedData['totalCountTraining'];
      }
      if (typeof injectedData['totalCountLeftTraining'] !== 'undefined') {
        this.totalCountLeftTraining =
          this.injectedData['totalCountLeftTraining'];
      }
      if (typeof injectedData['currentStudentAmount'] !== 'undefined') {
        this.studentsAmount = this.injectedData['currentStudentAmount'];
      }
    }
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);

    this.teacherIds = this.teachers.map((item) => item._id);

    this.studentAmountForm = this._formBuilder.group({
      studentAmount: [0, Validators.required],
      avatar: ['', Validators.required],
    });

    this.secondFormStep = this._formBuilder.group({
      group: this._formBuilder.array([]),
    });

    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.languages = res;
        for (const la of this.languages) {
          la.translate =
            this.translatedText[
              this.languages.filter((obj) => obj.code == la.code)[0]?.code
            ];
        }
      });

    this.trialStatus = this.authService.getUserTrialStatus();
    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data) {
        this.isFormInvalid = data.status.trim() === 'INVALID' ? true : false;
      }
    });
    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data && data.form === 'studentFormStep') {
        this.secondFormStep = data.formData;
      }
    });
  }

  increaseValue() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    const maxStudentsDuringTrialPhase = this.STUDENT_COUNT;
    if (
      this.trialStatus.isActive &&
      this.studentsAmount + studentAmount + 1 > maxStudentsDuringTrialPhase
    ) {
      this.openUpgradeModal();
      return;
    }
    this.studentAmountForm.get('studentAmount').setValue(studentAmount + 1);
  }

  decreaseValue() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    if (studentAmount > 0) {
      this.studentAmountForm.get('studentAmount').setValue(studentAmount - 1);
    }
  }


  allFilled(i: number) {
    let arrayControl = this.secondFormStep.get('group') as FormArray;
    let status = arrayControl.at(i).valid;
    if (status) {
      return 'active';
    }
  }
  onStudentAmountInput() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    const maxStudentsDuringTrialPhase = this.STUDENT_COUNT;
    if (
      this.trialStatus.isActive &&
      this.studentsAmount + studentAmount > maxStudentsDuringTrialPhase
    ) {
      this.openUpgradeModal();
      this.studentAmountForm
        .get('studentAmount')
        .setValue(maxStudentsDuringTrialPhase - this.studentsAmount);
    }
  }

  displayEnoughLicensesNote() {
    let currentAmount = this.studentAmountForm.get('studentAmount').value;
    return currentAmount > this.totalCountLeftDiagnostic &&
      currentAmount > this.totalCountLeftTraining
      ? true
      : false;
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    index,
  ) {
    const element = this.secondFormStep.get('group');
    if (element != undefined) {
      const ctrlValue = element.value[index].age;
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      ((this.secondFormStep.get('group') as FormArray).at(index) as FormGroup)
        .get('age')
        .patchValue(ctrlValue);
      datepicker.close();
    }
  }

  createClassArray(): FormGroup {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );
    return this._formBuilder.group({
      name: ['', Validators.required],
      gender: ['', Validators.required],
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [moment(birthyearEstimation), Validators.required],
    });
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code == code)[0]?.code
        ]
      : '';
  }

  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.secondFormStep.get('group') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages?.slice()
              : [];
        }),
      );
  }

  setLanguageOption(value, index) {
    ((this.secondFormStep.get('group') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  addNewStudent() {
    if (this.totalStudents > 0) {
      const currentClass = this.secondFormStep?.get('group') as FormArray;
      currentClass.push(this.createClassArray());
      this.setLanguages(this.secondFormStep.value.group.length - 1);
      this.totalStudents = currentClass.length;
    }
  }

  createStudents(studentAmount: number) {
    const currentClass = this.secondFormStep?.get('group') as FormArray;
    for (let index = 0; index < studentAmount; index++) {
      currentClass.push(this.createClassArray());
    }
    // Creates language observables
    let count = studentAmount;
    for (let index = 0; index < count; index++) {
      this.setLanguages(index);
    }
    this.totalStudents = currentClass.length;
  }

  prepareNextStep() {
    this.totalStudents = this.studentAmountForm.get('studentAmount').value;
    this.createStudents(this.studentAmountForm.get('studentAmount').value);
  }

  removeStudent(i: number) {
    const currentClass = this.secondFormStep?.get('group') as FormArray;
    currentClass.removeAt(i);
    this.totalStudents = currentClass.length;
  }
  clearStudentList() {
    for (let i = this.totalStudents; i > -1; i--) {
      this.removeStudent(i);
    }
    this.totalStudents = 0;
  }

  finishedSecondStep() {
    const studentsData = this.secondFormStep?.get('group').value;
    for (let i = 0; i < studentsData.length; i++) {
      this.dataSource[i] = {
        fullname: studentsData[i].name,
        diLicense: '',
        foLicense: '',
      };
      this.fullname = studentsData[i].name;
    }
    this.foLicenseLeft = this.totalCountLeftTraining;
    this.diLicenseLeft = this.totalCountLeftDiagnostic;
    console.log('second step', this.secondFormStep?.get('group').value);
  }

  getClassFormControls(): AbstractControl[] {
    return (<FormArray>this.secondFormStep?.get('group'))?.controls;
  }

  genderSelection() {
    setTimeout(() => {
      this.maleCount = this.secondFormStep
        .get('group')
        .value.filter((item) => item.gender == 'male').length;
      this.femaleCount = this.secondFormStep
        .get('group')
        .value.filter((item) => item.gender == 'female').length;
      this.diversCount = this.secondFormStep
        .get('group')
        .value.filter((item) => item.gender == 'divers').length;
    }, 0);
  }

  setGroupType(value) {
    if (value == 'admin') {
      this.isAdminFill = true;
      this.isTeacherFill = false;
    } else if (value == 'teacher') {
      this.isAdminFill = false;
      this.isTeacherFill = true;
    }
  }

  removeValidations() {
    const formArray = this.secondFormStep.get('group') as FormArray;
    let count = this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      if (formGroup != undefined) {
        formGroup.get('gender').clearValidators();
        formGroup.get('language').clearValidators();
        formGroup.get('age').clearValidators();

        // Aktualisieren Sie die Validierungszustände - TODO not used. remove?
        formGroup.get('gender').updateValueAndValidity();
        formGroup.get('language').updateValueAndValidity();
        formGroup.get('age').updateValueAndValidity();
      }
    }
  }

  setValidation() {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );

    const formArray = this.secondFormStep.get('group') as FormArray;
    let count = this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      formGroup.get('gender').setValidators([Validators.required]);
      formGroup
        .get('language')
        .setValidators([
          Validators.required,
          this.validatorRequireMatch.bind(this),
        ]);
      formGroup.get('age').setValidators([Validators.required]);
      formGroup.get('age').setValue(moment(birthyearEstimation));

      // Aktualisieren Sie die Validierungszustände - TODO not used. remove?
      formGroup.get('gender').updateValueAndValidity();
      formGroup.get('language').updateValueAndValidity();
      formGroup.get('age').updateValueAndValidity();
    }
  }

  //not used
  navigateToSelection() {
    //this.stepper.selectedIndex = 1;
    this.removeValidations();
    this.setValidation();
  }

  selectedGroup(value?) {
    if (value == 'dataType') {
      if (this.isAdminFill) {
        //this.stepper.selectedIndex = 2;
        this.secondFormStep = this._formBuilder.group({
          group: this._formBuilder.array([this.createClassArray()]),
        });
        this.createStudents(this.totalStudents);
        this.generatePDF = true;
      } else if (this.isTeacherFill) {
        this.removeValidations();
        this.generatePDF = false;
        for (let i = 0; i < this.totalStudents; i++) {
          this.studentsArray.push({
            name: '',
            gender: '',
            language: '',
            age: '',
          });
        }
        //this.stepper.selectedIndex = 2;
        //this.stepper.selectedIndex = 3;
        this.submitForm();
      }
    }
  }

  closeOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  selectAllCheckboxes(event) {
    for (let c of this.licensecheckbox) {
      if (c.checked === false) {
        c.checked = true;
      }
    }
  }

  updateTotalTrainingLicense(event) {
    if (event.target.checked) {
      this.foLicenseLeft--;
    } else {
      this.foLicenseLeft++;
    }
  }

  updateTotalDiagnosticLicense(event) {
    if (event.target.checked) {
      this.diLicenseLeft--;
    } else {
      this.foLicenseLeft++;
    }
  }
  /*  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  } */

  showAlert() {
    alert('Under Construction');
  }

  downloadPDF() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const formattedDate = this.datePipe.transform(
      currentDate,
      `${year}${month}${day}_`,
    );
    var element = document.createElement('a');
    element.setAttribute('href', this.blobUrl);
    element.setAttribute('download', formattedDate + this.groupName + '.pdf');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.clickedDownload = true;

    //this.dialog.closeAll();
  }

  submitForm() {
    let groupData = {
      groupId: this.groupId,
      students: this.secondFormStep.get('group')?.value,
      pdfPrintLayout: '1',
      genaratePDF: true,
      teacher: [],
      translatedText: this.translatedText,
      namesPackageType: this.avatar,
    };
    this.isLoading = true;
    this.groupService
      .addStudentsToGroupAndGeneratePDF(groupData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response: any) => {
          if (response) {
            //this.dialog.closeAll();
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.blobUrl = blobUrl;
            /* this.groupService.openPdfOverlay(
              blobUrl,
              this.groupName,
              'rebuild',
            ); */
          }
          this.isLoading = false;
        },
        (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  openCancelDialog() {
    let dialogRef = this.dialog.open(CancelAddStudentComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-delete-overlay-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      //console.log(res);
    });
  }

  formatDate(inputDate: string): string {
    const parts = inputDate.split('/');
    if (parts.length !== 2) {
      return '';
    }
    const year = parts[1];
    const month = parts[0];
    const day = '01';
    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate.getTime())) {
      return '';
    }
    const formattedOutput = this.datePipe.transform(
      parsedDate,
      "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ (zzzz)",
      'en-US',
    );
    return formattedOutput || '';
  }

  openStudentsCsvImport(): void {
    let dialogRef = this.dialog.open(CsvImportStudentComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'csv-import-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'csv-import-panel-backdrop',
      data: {
        students: this.secondFormStep.get('group').value.length,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        if (res?.length > 0) {
          if (res.length === this.secondFormStep.get('group').value.length) {
            // Durchlaufen Sie beide Arrays und setzen Sie die Werte mit setValue
            for (let i = 0; i < res.length; i++) {
              this.secondFormStep
                .get('group')
                .get(i.toString())
                .setValue({
                  name: res[i].name,
                  gender: res[i].gender,
                  language: res[i].language,
                  age: moment(this.formatDate(res[i].age)),
                });
            }
            this.genderSelection();
          } else {
            //console.error("Die Längen der Arrays stimmen nicht überein.");
          }
        }
      });
  }

  openUpgradeModal() {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'maxStudentsReached' },
      },
    );
  }
}
