<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">{{ translatedText.privacy_policy_data_protection_info }}</h1>
          <p>{{ translatedText.privacy_policy_paragraph_27 }}</p>
          <h3>{{ translatedText.privacy_policy_title_23 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_28 }}</p>
          <h3>{{ translatedText.privacy_policy_title_24 }}</h3>
          <p>
            {{ translatedText.privacy_policy_address_1 }}<br />
            {{ translatedText.privacy_policy_address_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_title_25 }}</h3>
          <p>{{ translatedText.privacy_policy_data_protection_officer }}</p>
          <h3>{{ translatedText.privacy_policy_title_26 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_29 }}</p>
          <h3>{{ translatedText.privacy_policy_title_27 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_30 }}</p>
          <h3>{{ translatedText.privacy_policy_title_28 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_31 }}</p>
          <h3>{{ translatedText.privacy_policy_title_29 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_32 }}</p>
          <h3>{{ translatedText.privacy_policy_title_30 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_33 }}</p>
          <br />
          <!--  <strong>
            {{ translatedText.privacy_policy_legal_notice }}
          </strong> -->
        </div>
      </div>
    </div>
  </div>
</div>
