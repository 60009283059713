<div>
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="activate-license-container">
    <mat-icon svgIcon="streamline_outlined:help-circle" class="icon-120" [ngStyle]="{ color: '#5F798C' }"></mat-icon>

    <h3 class="h3 h3--bold mt-4">
      {{ translatedText.admin_licenses_activate }}
    </h3>
    <p class="paragraph paragraph--xl mt-2">
      {{ translatedText.admin_licenses_activate_desc }}
    </p>

    <div class="activate-license-details">
      <div class="row activate-license-type">
        <div class="col-4">
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.activate_license_content }}</p>
          <p class="paragraph paragraph--m paragraph--bold text-left">{{ content_Type }}</p>
        </div>
        <div class="col-4">
          <p class="paragraph paragraph--s paragraph--bold">{{ translatedText.activate_license_type }}</p>
          <p class="paragraph paragraph--m paragraph--bold text-left">{{ licenses_Type }}</p>
        </div>
        <div class="col-4">
          <p class="paragraph paragraph--s paragraph--bold text-left">
            {{ translatedText.activate_license_expiry_date }}
          </p>
          <p class="paragraph paragraph--m paragraph--bold text-left">{{ expiry_Date | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
      <div class="separation-line-bottom-1 mt-4 mb-4"></div>
      <p class="paragraph paragraph--s mb-4">{{ translatedText.activate_license_desc }}</p>

      <ul class="activate-license-list">
        <li class="Diagnostic">
          <div class="activate-license-name">
            <mat-icon svgIcon="streamline_outlined:target" [ngStyle]="{ color: '#8654B9' }"></mat-icon>
            <p class="paragraph paragraph--s">Diagnoselizenzen</p>
          </div>
          <div class="license-count">
            <span *ngIf="getLicenseAmount('di', 'total') >= 100">∞</span>
            <span *ngIf="getLicenseAmount('di', 'total') < 100">{{ getLicenseAmount('di', 'total') }}</span>
          </div>
        </li>
        <li class="Training">
          <div class="activate-license-name">
            <mat-icon svgIcon="streamline_outlined:lightning" [ngStyle]="{ color: '#13794F' }"></mat-icon>
            <p class="paragraph paragraph--s">Training licenses</p>
          </div>
          <div class="license-count">{{ getLicenseAmount('fo', 'total') }}</div>
        </li>
      </ul>
    </div>

    <div class="button-wrap">
      <button mat-flat-button color="secondary" type="button" (click)="closePopup()">
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <button mat-flat-button color="primary" type="button" (click)="activateLicenses()">
        {{ translatedText.admin_licenses_activate }}
      </button>
    </div>
  </div>
</div>
