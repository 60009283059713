<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <button mat-fab type="button" (click)="navigateBack()" color="primary" class="backButton" matSuffix>
          <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
        </button>
        <div class="static_content">
          <h1 class="h1 static_header">
            {{ translatedText.release_notes_title }}
          </h1>
          <!-- <h2>Current version ({{ APP_VERSION }})</h2> -->
          <!-- <span class="hotfix-version" [hidden]="hideHotfixVersion">
            - {{ HOTFIX_VERSION }}
            </span> -->
          <!-- 1.1.2 -->
          <h2>{{ translatedText.release_notes_version }} 1.1.2</h2>
          <p>11{{ translatedText.release_notes_september }} 2024</p>

          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_1_1_2_notes_summary_1 }}</p>

          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <p>{{ translatedText.release_1_1_2_notes_improvement_1 }}</p>

          <h3>{{ translatedText.release_notes_error_title }}</h3>

          <ul>
            <li>{{ translatedText.release_1_1_2_notes_error_1 }}</li>
            <li>{{ translatedText.release_1_1_2_notes_error_2 }}</li>
            <li>{{ translatedText.release_1_1_2_notes_error_3 }}</li>
          </ul>          

          <!-- 1.1.1 -->
          <h2>{{ translatedText.release_notes_version }} 1.1.1</h2>
          <p>20{{ translatedText.release_notes_august }} 2024</p>

          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_82 }}</p>

          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <h4>{{ translatedText.release_notes_text_83 }}</h4>
          <p>{{ translatedText.release_notes_text_84 }}</p>
          <h4>{{ translatedText.release_notes_text_85 }}</h4>
          <p>{{ translatedText.release_notes_text_86 }}</p>
          <h4>{{ translatedText.release_notes_text_87 }}</h4>
          <p>{{ translatedText.release_notes_text_88 }}</p>
          <h4>{{ translatedText.release_notes_text_89 }}</h4>
          <p>{{ translatedText.release_notes_text_90 }}</p>
          <h4>{{ translatedText.release_notes_text_91 }}</h4>
          <p>{{ translatedText.release_notes_text_92 }}</p>

          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <p>{{ translatedText.release_notes_text_93 }}</p>

          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <p>{{ translatedText.release_notes_text_94 }}</p>

          <!-- 1.1.0 -->
          <h2>{{ translatedText.release_notes_version }} 1.1.0</h2>
          <p>24 {{ translatedText.release_notes_july }} 2024</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_1_1_0_notes_summary_1 }}</p>
          <p>{{ translatedText.release_1_1_0_notes_summary_2 }}</p>
          <p>{{ translatedText.release_1_1_0_notes_summary_3 }}</p>

          <h3>{{ translatedText.release_notes_new_features }}</h3>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_1 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_1_text }}</p>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_2 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_2_text }}</p>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_3 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_3_text }}</p>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_4 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_4_text }}</p>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_5 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_5_text }}</p>

          <p>{{ translatedText.release_1_1_0_notes_new_features_5_list_text }}</p>
          <ul>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_1 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_2 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_3 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_4 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_5 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_6 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_7 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_8 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_9 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_10 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_11 }}</li>
            <li>{{ translatedText.release_1_1_0_notes_new_features_5_list_12 }}</li>
          </ul>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_6 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_6_text }}</p>

          <h4>{{ translatedText.release_1_1_0_notes_new_features_7 }}</h4>
          <p>{{ translatedText.release_1_1_0_notes_new_features_7_text }}</p>

          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <p>{{ translatedText.release_1_1_0_notes_improvement_1 }}</p>
          <p style="padding-top: 20px"></p>
          <p>{{ translatedText.release_1_1_0_notes_improvement_2 }}</p>
          <p style="padding-top: 20px"></p>
          <p>{{ translatedText.release_1_1_0_notes_improvement_3 }}</p>
          <p style="padding-top: 20px"></p>
          <p>{{ translatedText.release_1_1_0_notes_improvement_4 }}</p>
          <p style="padding-top: 20px"></p>

          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <p>{{ translatedText.release_1_1_0_notes_error }}</p>

          <!-- 1.0.9 -->
          <h2>{{ translatedText.release_notes_version }} 1.0.9</h2>
          <p>16{{ translatedText.release_notes_may }} 2024</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_76 }}</p>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <p>{{ translatedText.release_notes_text_77 }}</p>
          <p>{{ translatedText.release_notes_text_78 }}</p>
          <p>{{ translatedText.release_notes_text_79 }}</p>
          <p>{{ translatedText.release_notes_text_80 }}</p>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <p>{{ translatedText.release_notes_text_81 }}</p>
          <!-- 1.0.8 -->
          <h2>{{ translatedText.release_notes_version }} 1.0.8</h2>
          <p>11{{ translatedText.release_notes_april }} 2024</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_64 }}</p>

          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_65 }}</li>
            <li>{{ translatedText.release_notes_text_66 }}</li>
            <li>{{ translatedText.release_notes_text_67 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_68 }}</li>
            <li>{{ translatedText.release_notes_text_69 }}</li>
            <li>{{ translatedText.release_notes_text_70 }}</li>
            <li>{{ translatedText.release_notes_text_71 }}</li>
            <li>{{ translatedText.release_notes_text_72 }}</li>
            <li>{{ translatedText.release_notes_text_73 }}</li>
            <li>{{ translatedText.release_notes_text_74 }}</li>
          </ul>

          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_75 }}</li>
          </ul>

          <!-- 1.0.7 -->
          <h2>{{ translatedText.release_notes_version }} 1.0.7 ({{ translatedText.release_notes_interim }})</h2>
          <p>27{{ translatedText.release_notes_february }} 2024</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_61 }}</li>
            <li>{{ translatedText.release_notes_text_62 }}</li>
            <li>{{ translatedText.release_notes_text_63 }}</li>
          </ul>
          <h2>{{ translatedText.release_notes_version }} 1.0.7</h2>
          <p>30{{ translatedText.release_notes_january }} 2024</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_46 }}</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_47 }}</li>
            <li>{{ translatedText.release_notes_text_48 }}</li>
            <li>{{ translatedText.release_notes_text_49 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_50 }}</li>
            <li>{{ translatedText.release_notes_text_51 }}</li>
            <li>{{ translatedText.release_notes_text_52 }}</li>
            <li>{{ translatedText.release_notes_text_53 }}</li>
            <li>{{ translatedText.release_notes_text_54 }}</li>
            <li>{{ translatedText.release_notes_text_55 }}</li>
            <li>{{ translatedText.release_notes_text_56 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_57 }}</li>
            <li>{{ translatedText.release_notes_text_58 }}</li>
            <li>{{ translatedText.release_notes_text_59 }}</li>
            <li>{{ translatedText.release_notes_text_60 }}</li>
          </ul>
          <!-- 1.0.6 -->
          <h2>{{ translatedText.release_notes_version }} 1.0.6</h2>
          <p>28{{ translatedText.release_notes_november }} 2023</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_45 }}</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_44 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_43 }}</li>
            <li>{{ translatedText.release_notes_text_42 }}</li>
            <li>{{ translatedText.release_notes_text_41 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_40 }}</li>
            <li>{{ translatedText.release_notes_text_39 }}</li>
            <li>{{ translatedText.release_notes_text_38 }}</li>
            <li>{{ translatedText.release_notes_text_32 }}</li>
          </ul>

          <h2>{{ translatedText.release_notes_version }} 1.0.5</h2>
          <p>9{{ translatedText.release_notes_november }} 2023</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_37 }}</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_36 }}</li>
            <li>{{ translatedText.release_notes_text_35 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_34 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_33 }}</li>
            <li>{{ translatedText.release_notes_text_32 }}</li>
          </ul>

          <h2>{{ translatedText.release_notes_version }} 1.0.4</h2>
          <p>20{{ translatedText.release_notes_october }} 2023</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_31 }}</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_30 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_29 }}</li>
            <li>{{ translatedText.release_notes_text_28 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_27 }}</li>
          </ul>

          <!-- <h2>Version 1.0.3</h2>
          <h3>{{translatedText.release_notes_new_features}}</h3>
          <ul>
            <li>Admin bereich</li>
            <li>Alle aufgaben einzeln</li>
            <li>Aufgaben filter</li>
            <li>Es gibt jetzt eine Audio Test für Lernenden unter dem Profil Dropdown. So können Kinder eigenständig prüfen, dass sie richtig aufgenommen werden, bevor sie mit einer Aufgabe starten.</li>
            <li>Tooltips added in Klassenübersicht, Lernendeübersicht, Aufgabenübersicht, auf dem Dashboard, in den Aufgaben Ergebnisse.</li>
            <li>Automode in Beta Modus freigeschaltet</li>
          </ul>
          <h3>{{translatedText.release_notes_improvement}}</h3>
          <ul>
            <li>Verlinkung von Übersicht auf aktive, erledigte, und nicht erledigte Aufgaben für den aktuelle Woche verbessert. Jetzt wird man auf die Aufgabenübersicht mit den richtigen vorausgewählten Filtern weitergeleitet.</li>
            <li>Der Lehrkraft Feedback zu einzelne Ergebnisse wurde überarbeitet und verbessert.</li>
            <li>Die Aufgaben Ergebnisse Seite für Lehrkräfte wurde verbessert</li>
            <li>In der Lernende Ansicht werden jetzt unter Erledigte Aufgaben auch WCPM angezeigt, damit der Lehrkraft schnell den Überblick von Leseleistung bekommt</li>
            <li>Die Auszeichnung „pünktlich“ wurde in „Zeitmeister“ umbenannt</li>
            <li>Mehrere Tooltips wurden korrigiert oder ergänzt</li>
            <li>Tabellen angepasst und informativer und einheitlicher zu sein.</li>
            <li>Diverse Textanpassungen und Verbesserungen wurden durchgeführt.</li>
            <li>Das Scrollen und Layout wurden an verschiedenen Stellen im System optimiert.</li>
            <li>Es ist jetzt möglich eine Klassen mit einen Umlaut in der Name anzulegen</li>
          </ul>
          <h3>{{translatedText.release_notes_error_title}}</h3>
          <ul>
            <li>Nicht erledigte Aufgaben werden jetzt für einzelne Lernenden angezeigt bei dem Filter „nicht erledigte Aufgaben“ auf der Aufgaben übersicht, regardless of whether the entire class has finished the task</li>
            <li>Fehlende Thumbnails für manche Bücher korrigiert</li>
            <li>Mehrere kleine Fixes und verbesserungen, sowie Kosmetische und Text Anpassungen.</li>
          </ul>
          <h3>BACKEND</h3>
          <ul>
            <li>Sessions are now processed more reliably. Aligners were improved to handle large sessions and ensure more sessions are processed.</li>
            <li>Performance metrics were adapted and improved.</li>
            <li>Matching of audio and text was improved so that mark up and analysis are shown correctly.</li>
            <li>Fix for creating automode tasks.</li>
            <li>Calculation of text complexity was accelerated.</li>
            <li>Improvements were made in the reader so that starting point is selected correctly.</li>
          </ul>

          <h2>Version 1.0.2</h2>
          <h3>{{translatedText.release_notes_new_features}}</h3>
          <h3>{{translatedText.release_notes_improvement}}</h3>
          <ul>
            <li>Updates zu Diagnose Text und Reihenfolge Änderungen</li>
          </ul>
          <h3>{{translatedText.release_notes_error_title}}</h3>
          <ul>
            <li>Chart für 7 Tage in der Klassenübersicht korrigiert</li>
          </ul> -->

          <h2>{{ translatedText.release_notes_version }} 1.0.1 - 1.0.3</h2>
          <p>15{{ translatedText.release_notes_october }} 2023</p>
          <h3>{{ translatedText.release_notes_summary }}</h3>
          <p>{{ translatedText.release_notes_text_26 }}</p>
          <h3>{{ translatedText.release_notes_new_features }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_25 }}</li>
            <li>{{ translatedText.release_notes_text_24 }}</li>
            <li>{{ translatedText.release_notes_text_23 }}</li>
            <li>{{ translatedText.release_notes_text_22 }}</li>
            <li>{{ translatedText.release_notes_text_21 }}</li>
            <li>{{ translatedText.release_notes_text_20 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_improvement }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_19 }}</li>
            <li>{{ translatedText.release_notes_text_18 }}</li>
            <li>{{ translatedText.release_notes_text_17 }}</li>
            <li>{{ translatedText.release_notes_text_16 }}</li>
            <li>{{ translatedText.release_notes_text_15 }}</li>
            <li>{{ translatedText.release_notes_text_14 }}</li>
            <li>{{ translatedText.release_notes_text_13 }}</li>
            <li>{{ translatedText.release_notes_text_12 }}</li>
            <li>{{ translatedText.release_notes_text_11 }}</li>
            <li>{{ translatedText.release_notes_text_10 }}</li>
            <li>{{ translatedText.release_notes_text_9 }}</li>
            <li>{{ translatedText.release_notes_text_8 }}</li>
          </ul>
          <h3>{{ translatedText.release_notes_error_title }}</h3>
          <ul>
            <li>{{ translatedText.release_notes_text_7 }}</li>
            <li>{{ translatedText.release_notes_text_6 }}</li>
            <li>{{ translatedText.release_notes_text_5 }}</li>
            <li>{{ translatedText.release_notes_text_4 }}</li>
            <li>{{ translatedText.release_notes_text_3 }}</li>
            <li>{{ translatedText.release_notes_text_2 }}</li>
          </ul>

          <h2>{{ translatedText.release_notes_version }} 1.0.0</h2>
          <p>4{{ translatedText.release_notes_september }} 2023</p>
          <!-- <h3>What's new in this release</h3> -->
          <ul>
            <li>{{ translatedText.release_notes_text_1 }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
