import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { PdfOverlayComponent } from './pdf-overlay.component';

@NgModule({
  declarations: [PdfOverlayComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [PdfOverlayComponent],
})
export class PdfOverlayModule {}
