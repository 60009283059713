import { Component, OnInit, Inject, Optional } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReportErrorComponent } from '../../report-error/report-error.component';
import { StudentService } from '../../providers/student.service';
import { TaskService } from '../../providers/task.service';
import { ActivatedRoute } from '@angular/router';
import { ReadingJsonService } from '../../providers/readingJson.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import { BookReaderSettingsComponent } from '../../book-reader/book-reader-settings/book-reader-settings.component';
import { BookReaderService } from '../../book-reader/book-reader-service';
import { CapacitorService } from '../../providers/capacitor.service';
import { NotificationOverlayComponent } from 'src/app/helpers/notification-overlay/notification-overlay.component';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { CryptoService } from 'src/app/providers/crypto.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AdminService } from 'src/app/providers/admin.service';
export interface Group {
  name?: string;
  grade?: string;
  stream?: string;
  school_year?: string;
}
export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: Group[];
}

export interface DialogData {}

@Component({
  selector: 'app-student-report',
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
})
export class StudentReportComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  view: any[] = [140, 140];
  isDoughnut: boolean = true;
  bookReaderNav: boolean = false;
  loadResult: boolean = true;
  colorScheme = {
    domain: ['#03CC90', '#F6F8FB'],
  };
  errorInfo = [];
  student: Student;
  studentData;
  task;
  dsefVersion = 1.0;
  studentId;
  taskId;
  id;
  sessionUuid;
  userTaskUuid;
  reading_combined_score;
  reading_duration;
  annotated_words;
  dsefMarkup: any = [];
  FilteredDSEF: any;
  reading_errors_normalized;
  reading_errors_score;
  readingErrorsCount = 0;
  correctly_read_words_percentage;
  reading_speed_correct_words_per_minute;
  num_deletions;
  num_insertions;
  num_substitutions;
  num_correct;
  num_omits;
  num_read_words;
  num_longpause;
  reading_speed_words;
  reading_speed_words_normalized;
  reading_dynamic_normalized;
  error_percent;
  text_coverage;
  list_of_operations;
  issue_list_of_operations;
  annotated_text = '';
  hideAnnotation: boolean = false;
  taskRange;
  content;
  audioUrl;
  subscription: Subscription;
  teacherId;
  isDiagnosticTest: boolean = false;
  level_wpm;
  level_wcpm;
  sessionHash;
  translatedText;
  isLoading: boolean = false;

  // evaluationInfoText: string = 'Wir berechnen die Anzahl der Fehler, indem wir die Audioanalyse mit dem tatsächlichen Text vergleichen und Abweichungen identifizieren. \n\n Auslassungen: Wenn Leser ein Wort überspringen. \n\n Ersetzungen: Wenn Leser ein Wort durch ein anderes Wort ersetzten. \n\n Hinzufügungen: Wenn Leser ein Wort hinzufügen, das nicht im Originaltext enthalten ist. \n\n Die Werte werden weiterhin aktualisiert.';
  // readingPointsInfoText: string = 'Lesepunkte sind der kumulierte Wert von Lesegeschwindigkeit, Anzahl der Fehler und der Stimmdynamik. Die Lesepunkte können einen Wert von 0 bis 100 annehmen und bilden im LaLeTu somit eine vereinfachte, übergeordnete Kennzahl, um die Leseflüssigkeit in einer einzigen Zahl abzubilden. \n\n Diese Zahl wird zukünftig mit Parametern angepasst, die wir zusätzlich einführen werden.';
  // readingFluencyInfoText: string = 'Die Leseflüssigkeit wird mit "korrekte Wörter pro Minute" ermittelt. Dieser Parameter ist in fünf Perzentile aufgeteilt und stellt die fünf Lesekompetenzlevels dar. Diese Lesekompetenzlevels gibt es für jede Klassenstufe. Ihnen werden die einzelnen Ergebnisse der Lernende zugeordnet. \n\n Diese Werte werden weiterhin aktualisiert.';
  // readingSpeedInfoText: string = 'Die Lesegeschwindigkeit wird berechnet durch Wörter pro Minute (WPM) geteilt durch die Lesezeit. \n\n Die Werte werden weiterhin aktualisiert.';
  // readingDynamicInfoText: string = 'Um die Stimmdynamik zu berechnen, analysieren wir die durchschnittliche Stimmfrequenz der Leser und berechnen die Standardabweichung. Dieser Wert wird auf einen Wert zwischen 0 und 100 normiert. Werte am unteren Ende der Skala werden als "monoton" gewertet, während Werte am oberen Ende als "überbetont" betrachtet werden. \n\nDie Werte werden weiterhin aktualisiert.';
  constructor(
    private authService: AuthService,
    private location: Location,
    private studentService: StudentService,
    private taskService: TaskService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private readingJsonService: ReadingJsonService,
    private _bottomSheet: MatBottomSheet,
    private bookReaderService: BookReaderService,
    private capacitorService: CapacitorService,
    private cryptoService: CryptoService,
    private translationService: TranslationService,
    private adminService: AdminService,
    public snackBar: MatSnackBar,
    //@Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog, //public resDialog: MatDialogRef<StudentReportComponent>
  ) {
    // if (injectedData) {
    //   if (typeof(injectedData['taskId'])!=="undefined") {
    //     this.taskId = this.injectedData["taskId"];
    //   }
    //   if (typeof(injectedData['studentId'])!=="undefined") {
    //     this.studentId = this.injectedData["studentId"];
    //   }
    // }
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.taskId = this.route.snapshot.params['id'];
    this.studentId = this.route.snapshot.queryParams.studentId;
    this.sessionUuid = this.route.snapshot.queryParams.sessionUuid;
    this.teacherId = this.authService.getTeacherId();

    this.studentService
      .getStudentbyId(this.studentId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.student = res;
      });

    this.studentService
      .getStudentTaskReport(this.teacherId, this.taskId, this.sessionUuid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);

          if (res.task) {
            this.task = res.task;
            this.dsefVersion = this.task?.dsef_version
              ? this.task?.dsef_version
              : 1.0;
            let splitTaskRange = res.task._split_task.filter(
              (subTask) => subTask.session_uuid === this.sessionUuid,
            );
            this.taskRange =
              this.sessionUuid && splitTaskRange.length > 0
                ? splitTaskRange[0].range
                : res.task.range;
            this.sessionUuid = this.sessionUuid
              ? this.sessionUuid
              : res.task.session_uuid;
            if (this.sessionUuid) {
              // Get hash for frontend
              this.sessionHash = res.task.session_hash;
            }
            this.reading_combined_score =
              res.sessionData.reading_combined_score;
            this.reading_duration = res.sessionData.reading_duration;
            this.annotated_words = res.sessionData.annotated_words;
            this.reading_errors_normalized =
              res.sessionData.reading_errors_normalized;
            this.reading_errors_score = res.sessionData.reading_errors_score;
            this.correctly_read_words_percentage = res.sessionData
              .correctly_read_words_percentage
              ? Math.round(res.sessionData.correctly_read_words_percentage)
              : null;
            this.reading_speed_correct_words_per_minute =
              res.sessionData.reading_speed_correct_words_per_minute;
            this.num_deletions = res.sessionData.num_deletions;
            this.num_insertions = res.sessionData.num_insertions;
            this.num_substitutions = res.sessionData.num_substitutions;
            this.num_correct = res.sessionData.num_correct;
            this.num_omits = res.sessionData.num_omits;
            this.num_read_words = res.sessionData.num_read_words;
            this.num_longpause = res.sessionData.num_longpause;
            this.text_coverage = res.sessionData.text_coverage
              ? Math.round(res.sessionData.text_coverage)
              : null;
            this.readingErrorsCount =
              this.num_deletions + this.num_insertions + this.num_substitutions;
            this.reading_speed_words = res.sessionData.reading_speed_words;
            this.reading_speed_words_normalized =
              res.sessionData.reading_speed_words_normalized;
            this.reading_dynamic_normalized =
              res.sessionData.reading_dynamic_normalized;
            this.list_of_operations =
              res.sessionData.list_of_operations == null
                ? res.sessionData.list_of_operations
                : res.sessionData.list_of_operations.replace(/'/g, '"');
            this.issue_list_of_operations =
              res.sessionData.issue_list_of_operations == null
                ? res.sessionData.issue_list_of_operations
                : res.sessionData.issue_list_of_operations.replace(/'/g, '"');
            this.annotated_text =
              res.sessionData.annotated_text == null
                ? null
                : res.sessionData.annotated_text.replace(/\\/g, '');
            this.dsefMarkup = res.sessionData.dsef_text;
            this.FilteredDSEF = res.sessionData.FilteredDSEF;
            this.userTaskUuid = res.sessionData.user_task_uuid;
            this.level_wcpm = res.sessionData.level_wcpm;
            this.level_wpm = res.sessionData.level_wpm;
            if (
              this.task.type == 'diagnostic-pre' ||
              this.task.type == 'diagnostic-mid' ||
              this.task.type == 'diagnostic-post'
            ) {
              this.isDiagnosticTest = true;
            }
            // chart
            console.log(this.annotated_words);
            this.error_percent =
              this.correctly_read_words_percentage != null
                ? Math.round(this.correctly_read_words_percentage)
                : 0;
            this.errorInfo = [
              {
                name: 'Richtig',
                value: this.error_percent,
              },
              {
                name: 'Falsch',
                value: 100 - this.error_percent,
              },
            ];
            this.getAudioToSession(this.sessionUuid);
          }
        },
        (error: any) => {
          this.annotated_text = null;
        },
      );
  }

  navigateBack(): void {
    this.location.back();
  }

  reportError(): void {
    let dialogRef = this.dialog.open(ReportErrorComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'report-error-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'report-error-overlay-panel-backdrop',
      data: {
        session_uuid: this.sessionUuid,
        list_of_operations:
          this.issue_list_of_operations == null
            ? this.list_of_operations
            : this.issue_list_of_operations,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  toggleResults() {
    this.hideAnnotation = !this.hideAnnotation;
    const markupTypes = ['m-sub', 'm-del', 'm-omit', 'm-ins'];
    if (this.hideAnnotation) {
      for (let mt of markupTypes) {
        let wordsSub = document.getElementsByClassName(mt);
        let wordsSubLen = wordsSub.length;
        for (let i = 0; i < wordsSubLen; ++i) {
          let word = wordsSub[0];
          if (mt == 'm-ins') {
            word.setAttribute('savedinnerhtml', word.innerHTML);
            word.innerHTML = ' ';
          }
          word.classList.remove(mt);
          word.classList.add(`rem-${mt}`);
        }
      }
    } else {
      for (let mt of markupTypes) {
        let wordsSub = document.getElementsByClassName(`rem-${mt}`);
        let wordsSubLen = wordsSub.length;
        for (let i = 0; i < wordsSubLen; ++i) {
          let word = wordsSub[0];

          if (mt == 'm-ins') {
            word.innerHTML = word.getAttribute('savedinnerhtml');
          }

          word.classList.add(mt);
          word.classList.remove(`rem-${mt}`);
        }
      }
    }
  }

  findClickedWordInMarkup(clickedWordData) {
    const foundIndex = this.dsefMarkup.findIndex((markup) => {
      if (markup.name == 'ins') {
        return (
          markup.insertIndex === clickedWordData.index &&
          markup.parentId === clickedWordData.parentId &&
          markup.name === clickedWordData.name
        );
      } else {
        return (
          markup.wordIndex === clickedWordData.index &&
          markup.parentId === clickedWordData.parentId &&
          markup.name === clickedWordData.name
        );
      }
    });
    return foundIndex;
  }

  substractFoundWordFromNum(foundIndex, clickedWordData) {
    let name = this.dsefMarkup[foundIndex].name;
    // Remove the found markup from the array
    this.dsefMarkup.splice(foundIndex, 1);
    // Add word correct
    this.num_correct++;
    // Substract name
    switch (name) {
      case 'omit':
        this.num_omits--;
        this.num_correct++;
        break;
      case 'del':
        this.num_deletions--;
        break;
      case 'ins':
        const insString = clickedWordData.insertContent;
        const insCount = insString ? insString.split(' ').length : 1;
        this.num_insertions = this.num_insertions - insCount;
        break;
      case 'sub':
        this.num_substitutions--;
        this.num_correct++;
        break;
      default:
        break;
    }
    this.annotated_words =
      this.num_correct +
      this.num_insertions +
      this.num_deletions +
      this.num_substitutions +
      this.num_omits;
    // Update markup in reader
    this.dsefMarkup = [...this.dsefMarkup];
    // Save to readalizer
    this.taskService
      .reportResultFault({
        session_uuid: this.sessionUuid,
        teacher_id: this.authService.getTeacherId(),
        dsef_text: JSON.stringify(this.dsefMarkup),
        calculation: JSON.stringify({
          annotated_words: this.annotated_words,
          num_correct: this.num_correct,
          num_omits: this.num_omits,
          num_deletions: this.num_deletions,
          num_insertions: this.num_insertions,
          num_read_words: this.num_read_words,
          num_substitutions: this.num_substitutions,
        }),
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: 'Fehler erfolgreich gemeldet.',
            duration: 3000,
            horizontalPosition: 'left',
          });

          // Remove markup after successful report
          this.bookReaderService.removeMarkup(clickedWordData);
        },
        (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: 'Der Fehler konnte leider nicht gemeldet werden.',
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  getMarkup() {
    if (this.hideAnnotation) {
      return [];
    } else {
      return this.dsefMarkup;
    }
  }

  clickedWordReportError(clickedWordData) {
    const foundIndex = this.findClickedWordInMarkup(clickedWordData);
    if (foundIndex !== -1) {
      // Modify markup
      this.substractFoundWordFromNum(foundIndex, clickedWordData);
    }
  }

  getAudioToSession(session_uuid) {
    let browser = this.capacitorService.getBrowserName();
    let teacherUuid = this.authService.getUserUuid();
    this.subscription = this.readingJsonService
      .getAudio(teacherUuid, session_uuid, browser)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        if (response) {
          // blob
          let dataType = response.type;
          if (browser == 'safari') {
            dataType = 'audio/aac';
          }
          let binaryData = [];
          binaryData.push(response);

          let blob = new Blob(binaryData, { type: dataType });
          let blobUrl = URL.createObjectURL(blob);
          this.audioUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        }
      });
  }

  openNotificationOverlay(): void {
    let dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        taskId: this.taskId,
        student: this.student,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  openBookReaderSettings() {
    let maxCharcount = this.bookReaderService.getMaxCharcount();
    let paragraphClass = this.bookReaderService.getParagraphClass();
    const config: MatBottomSheetConfig = {
      data: {
        maxCharcount: maxCharcount,
        paragraphClass: paragraphClass,
      },
    };
    this._bottomSheet.open(BookReaderSettingsComponent, config);
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
