import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-student-progress-bar',
  templateUrl: './student-progress-bar.component.html',
  styleUrls: ['./student-progress-bar.component.scss'],
})
export class StudentProgressBarComponent implements OnInit, OnChanges {
  progressValue = 0;
  @Input('progress') progress: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress.previousValue != changes.progress.currentValue) {
      //setTimeout(() => {
      this.progressValue = this.progress;
      // }, 2000);
    }
  }
}
