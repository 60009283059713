<app-students-edit-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeOverlay($event)"
  ><!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT -->
  <cdk-step [stepControl]="rForm" #step0="cdkStep">
    <div class="training-task_select_container edit-group">
      <div class="training-task_create_headline">
        <h3 class="h3 h3--bold">
          {{ translatedText.students_edit_personal_data }}
        </h3>
      </div>
      <div class="group-info">
        <h3 class="h3 h3--bold">{{ groupName }} ({{ students?.length }})</h3>
        <div class="gender_header">
          <div class="inline_text">
            <mat-icon
              class="male-icon"
              svgIcon="streamline_outlined:male"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.students_edit_gender_male }} - {{ maleCount }}
            </p>
          </div>
          <div class="inline_text">
            <mat-icon
              class="female-icon"
              svgIcon="streamline_outlined:female"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.students_edit_gender_female }} -
              {{ femaleCount }}
            </p>
          </div>
          <div class="inline_text">
            <mat-icon
              class="divers-icon"
              svgIcon="streamline_outlined:divers"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.students_edit_gender_divers }} -
              {{ diversCount }}
            </p>
          </div>
        </div>
      </div>
      <form [formGroup]="rForm" #formDirective="ngForm">
        <div formArrayName="students" class="third_step">
          <div
            *ngFor="let student of getStudentsFormControls(); let i = index"
            [formGroupName]="i"
            class="children_row"
          >
            <p class="paragraph paragraph--bold paragraph--l group_row_number">
              <span class="gender-index">{{ i + 1 }}</span>
            </p>
            <div class="row group_row">
              <div class="col-md-12">
                <span
                  class="paragraph paragraph--bold paragraph--l username-with-avatar username-edit"
                >
                  <span
                    ><img
                      *ngIf="getStudentInfo(student.value.id).avatarUrl"
                      src="{{
                        getStudentInfo(student.value.id).avatarUrl
                      }}" /></span
                  >{{ getStudentInfo(student.value.id).username }}
                </span>
                <mat-label>{{
                  translatedText.students_edit_fullname
                }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="{{ translatedText.students_edit_fullname }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-label
                  >{{ translatedText.students_edit_language }}*</mat-label
                >
                <mat-form-field>
                  <input
                    type="text"
                    placeholder="{{ translatedText.students_edit_language }}"
                    aria-label="Muttersprache"
                    matInput
                    formControlName="language"
                    [matAutocomplete]="auto"
                    appAutocompletePosition="trigger"
                    required
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn.bind(this)"
                    (optionSelected)="
                      setLanguageOption($event?.option?.value, i)
                    "
                  >
                    <mat-option
                      *ngFor="let language of filteredOptions[i] | async"
                      [value]="language?.code"
                      >{{ getLanguageByCode(language?.code) }}</mat-option
                    >
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="
                      validadeStudentField(i)
                        .get('language')
                        .hasError('incorrect') &&
                      validadeStudentField(i).get('language').touched &&
                      !validadeStudentField(i)
                        .get('language')
                        .hasError('required')
                    "
                  >
                    {{ translatedText.students_edit_language_error }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      validadeStudentField(i)
                        .get('language')
                        .hasError('required')
                    "
                  >
                    <!-- Please enter a class name. -->
                    {{ translatedText.students_edit_language_selection }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-label
                  >{{ translatedText.students_edit_birthdate }}*</mat-label
                >
                <mat-form-field>
                  <input
                    matInput
                    [max]="maxBirthday"
                    formControlName="age"
                    type="text"
                    (click)="birthdaypicker.open()"
                    [matDatepicker]="birthdaypicker"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="birthdaypicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #birthdaypicker
                    startView="multi-year"
                  ></mat-datepicker>
                  <!-- <mat-error *ngIf="thirdFormStep.get('age').hasError('required')">Please choose a birth year and month.</mat-error>  -->
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <div class="gender-selection">
                  <mat-label
                    >{{ translatedText.students_edit_gender }}*</mat-label
                  >
                  <mat-radio-group
                    formControlName="gender"
                    aria-label="Select an option"
                    [value]="student.gender"
                  >
                    <mat-radio-button (change)="genderSelection()" value="male"
                      ><mat-icon
                        class="male-icon"
                        svgIcon="streamline_outlined:male"
                      ></mat-icon
                      >{{
                        translatedText.students_edit_gender_male
                      }}</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="genderSelection()"
                      value="female"
                      ><mat-icon
                        class="female-icon"
                        svgIcon="streamline_outlined:female"
                      ></mat-icon
                      >{{
                        translatedText.students_edit_gender_female
                      }}</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="genderSelection()"
                      value="divers"
                      ><mat-icon
                        class="divers-icon"
                        svgIcon="streamline_outlined:divers"
                      ></mat-icon
                      >{{
                        translatedText.students_edit_gender_divers
                      }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="training-task_select_button">
      <button
        [disabled]="rForm.invalid"
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.students_edit_save }}
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep" #step3="cdkStep">
    <div class="loading-step" *ngIf="isLoading">
      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      <h3 class="h3 h3--bold">{{ translatedText.students_edit_pdf }}</h3>
      <p class="paragraph">
        <strong>{{ translatedText.attention }} </strong
        >{{ translatedText.students_edit_attention_text }}
      </p>
    </div>
    <div class="finished-step edit-group" *ngIf="!isLoading">
      <div class="pdf-info">
        <img
          class="last-step-img-key"
          src="../../../../assets/key.svg"
          alt=""
        />
        <h3 class="h3 h3--boldd">
          {{ translatedText.students_edit_group_list }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.students_edit_pdf_download_text }}
        </p>
        <button
          mat-flat-button
          color="primary"
          type="button"
          class="pdf_overlay_button"
          (click)="downloadPDF()"
          [hidden]="blobUrl == ''"
        >
          <mat-icon svgIcon="streamline_outlined:download"></mat-icon
          >{{ translatedText.students_edit_pdf_download }}
        </button>
      </div>
      <div class="pdf-warn-box">
        <div class="row">
          <div class="col-8">
            <p class="paragraph paragraph--l paragraph--bold">
              {{ translatedText.students_edit_attention_save }}
            </p>
            <p class="paragraph paragraph--s">
              {{ translatedText.students_edit_attention_save_text }}
            </p>
          </div>
          <div class="col-4">
            <img
              class="megaphone"
              src="../../../../assets/megaphone.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
</app-students-edit-stepper>
