import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { HintBoxComponent } from './hint-box.component';

@NgModule({
  declarations: [HintBoxComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [HintBoxComponent],
})
export class HintBoxModule {}
