<section class="container-fluid overlay_container create_school-container">
  <div class="step_container">
    <div class="training-task-stepper-col">
      <div class="training-task_container">
        <div class="step_content">
          <div class="training-task_wrap teacher" [style.display]="'block'">
            <button
              mat-icon-button
              type="button"
              class="circle light task_close"
              [mat-dialog-close]="'close'"
              [disabled]="isLoading"
              matSuffix
            >
              <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
            </button>
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold">
                {{ translatedText.school_create_title }}
              </h2>
            </div>
            <form [formGroup]="rForm" #formDirective="ngForm">
              <div class="personal-info">
                <div class="row">
                  <div class="col-md-12 form_header-headline">
                    <p class="paragraph paragraph--bold paragraph--m">
                      {{ translatedText.school_create_info }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <mat-label>{{ translatedText.school_create_school_name }}</mat-label>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="name"
                        type="text"
                        placeholder="{{ translatedText.school_create_school_name }}"
                        required
                      />
                      <mat-error *ngIf="rForm.get('name').hasError('required')">
                        {{ translatedText.school_create_school_name_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label>{{ translatedText.school_create_school_type }}</mat-label>
                    <mat-form-field>
                      <mat-select
                        formControlName="school_type"
                        type="role"
                        disableOptionCentering
                        panelClass="grade-select"
                      >
                        <mat-option value="Abendgymnasium">Abendgymnasium</mat-option>
                        <mat-option value="Abendrealschule">Abendrealschule</mat-option>
                        <mat-option value="Berufskolleg">Berufskolleg</mat-option>
                        <mat-option value="Förderschule">Förderschule</mat-option>
                        <mat-option value="Förderschule für Lernhilfe">Förderschule für Lernhilfe</mat-option>
                        <mat-option value="Förderschule im Bereich der Realschule"
                          >Förderschule im Bereich der Realschule</mat-option
                        >
                        <mat-option value="Förderschule im Bereich des Berufskollegs"
                          >Förderschule im Bereich des Berufskollegs</mat-option
                        >
                        <mat-option value="Förderschule im Bereich des Gymnasiums"
                          >Förderschule im Bereich des Gymnasiums</mat-option
                        >
                        <mat-option value="Gemeinschaftsschule (Schulversuch)"
                          >Gemeinschaftsschule (Schulversuch)</mat-option
                        >
                        <mat-option value="Gesamtschule">Gesamtschule</mat-option>
                        <mat-option value="Grundschule">Grundschule</mat-option>
                        <mat-option value="Grundschule mit Förderstufe">Grundschule mit Förderstufe</mat-option>
                        <mat-option value="Gymnasiale Oberstufenschule">Gymnasiale Oberstufenschule</mat-option>
                        <mat-option value="Gymnasium">Gymnasium</mat-option>
                        <mat-option value="Gymnasium mit gymnasialer Oberstufe"
                          >Gymnasium mit gymnasialer Oberstufe</mat-option
                        >
                        <mat-option value="Gymnasium ohne gymnasiale Oberstufe"
                          >Gymnasium ohne gymnasiale Oberstufe</mat-option
                        >
                        <mat-option value="Hauptschule">Hauptschule</mat-option>
                        <mat-option value="Hauptschule mit Förderstufe">Hauptschule mit Förderstufe</mat-option>
                        <mat-option value="Hiberniaschule">Hiberniaschule</mat-option>
                        <mat-option value="Kolleg">Kolleg</mat-option>
                        <mat-option value="Primus (Schulversuch)">Primus (Schulversuch)</mat-option>
                        <mat-option value="Realschule">Realschule</mat-option>
                        <mat-option value="Schule für Kranke">Schule für Kranke</mat-option>
                        <mat-option value="schulformbezogene (kooperative) Gesamtschule"
                          >schulformbezogene (kooperative) Gesamtschule</mat-option
                        >
                        <mat-option value="schulformübergreifende (integrierte) Gesamtschule"
                          >schulformübergreifende (integrierte) Gesamtschule</mat-option
                        >
                        <mat-option value="Sekundarschule">Sekundarschule</mat-option>
                        <mat-option value="Sonstige Förderschule">Sonstige Förderschule</mat-option>
                        <mat-option value="Volksschule">Volksschule</mat-option>
                        <mat-option value="Waldorfschule">Waldorfschule</mat-option>
                        <mat-option value="Weiterbildungskolleg">Weiterbildungskolleg</mat-option>
                      </mat-select>
                      <mat-error *ngIf="rForm.get('school_type').hasError('required')">
                        {{ translatedText.school_create_school_type_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="personal-info">
                <div class="row">
                  <div class="col-md-12 form_header-headline">
                    <p class="paragraph paragraph--bold paragraph--m">
                      {{ translatedText.school_create_school_address }}
                    </p>
                  </div>
                  <div class="col-md-12">
                    <mat-label>{{ translatedText.school_create_school_state }}</mat-label>
                    <mat-form-field>
                      <mat-select formControlName="state" type="role" disableOptionCentering panelClass="grade-select">
                        <mat-option value="BW">Baden-Württemberg</mat-option>
                        <mat-option value="BY">Bayern</mat-option>
                        <mat-option value="BE">Berlin</mat-option>
                        <mat-option value="BB">Brandenburg</mat-option>
                        <mat-option value="HB">Bremen</mat-option>
                        <mat-option value="HH">Hamburg</mat-option>
                        <mat-option value="HE">Hessen</mat-option>
                        <mat-option value="MV">Mecklenburg-Vorpommern</mat-option>
                        <mat-option value="NI">Niedersachsen</mat-option>
                        <mat-option value="NRW">Nordrhein-Westfalen</mat-option>
                        <mat-option value="RP">Rheinland-Pfalz</mat-option>
                        <mat-option value="SL">Saarland</mat-option>
                        <mat-option value="SN">Sachsen</mat-option>
                        <mat-option value="ST">Sachsen-Anhalt</mat-option>
                        <mat-option value="SH">Schleswig-Holstein</mat-option>
                        <mat-option value="TH">Thüringen</mat-option>
                      </mat-select>
                      <mat-error *ngIf="rForm.get('state').hasError('required')">
                        {{ translatedText.school_create_school_state_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-8">
                    <mat-label>{{ translatedText.school_create_school_city }}</mat-label>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="city"
                        type="text"
                        placeholder="{{ translatedText.school_create_school_city }}"
                        required
                      />
                      <mat-error *ngIf="rForm.get('city').hasError('required')">
                        {{ translatedText.school_create_school_city_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-label>{{ translatedText.school_create_school_zip }}</mat-label>
                    <mat-form-field>
                      <input matInput formControlName="zip" type="text" placeholder="000000" required />
                      <mat-error *ngIf="rForm.get('zip').hasError('required')">
                        {{ translatedText.school_create_school_zip_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-label>{{ translatedText.school_create_school_address }}</mat-label>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="address"
                        type="text"
                        placeholder="{{ translatedText.school_create_street }}"
                        required
                      />
                      <mat-error *ngIf="rForm.get('address').hasError('required')">
                        {{ translatedText.school_create_school_address_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label>{{ translatedText.school_create_school_latitude }}</mat-label>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="latitude"
                        type="text"
                        placeholder="{{ translatedText.school_create_school_latitude }}"
                        required
                      />
                      <mat-error *ngIf="rForm.get('latitude').hasError('required')">
                        {{ translatedText.school_create_school_latitude_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label>{{ translatedText.school_create_school_longitude }}</mat-label>
                    <mat-form-field>
                      <input
                        matInput
                        formControlName="longitude"
                        type="text"
                        placeholder="{{ translatedText.school_create_school_longitude }}"
                        required
                      />
                      <mat-error *ngIf="rForm.get('longitude').hasError('required')">
                        {{ translatedText.school_create_school_longitude_error }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="training-task_select_button">
                <button
                  mat-flat-button
                  color="secondary"
                  class="mat-flat-button--full"
                  [mat-dialog-close]="'close'"
                  [disabled]="isLoading"
                >
                  {{ translatedText.school_create_back }}
                </button>
                <button
                  [disabled]="rForm.invalid || isLoading"
                  mat-flat-button
                  color="primary"
                  class="mat-flat-button--full"
                  (click)="submitForm(formDirective)"
                >
                  <span *ngIf="!isLoading">{{ translatedText.school_create_add }}</span>
                  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
