import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GroupService } from 'src/app/providers/group.service';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { ErrorSnackbarComponent } from '../snackbar/error-snackbar/error-snackbar.component';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-unassigned-student-to-class',
  templateUrl: './unassigned-student-to-class.component.html',
  styleUrls: ['./unassigned-student-to-class.component.scss'],
})
export class UnassignedStudentToClassComponent implements OnInit, OnDestroy {
  masterTaskId: string;
  groupName: string;
  translatedText: any;
  currentLanguage: string;
  student: any;
  groupSelect = new FormControl('');
  groups: any = [];
  schoolYear: string;
  form: FormGroup;
  studentAssigned: boolean = false;
  totalUnassignedStudents: number;
  currentYearGroups: any = [];
  evolutionApiUrl: any;
  teacherId: string;
  snackBar: any;
  selectedGroup: any;

  @Input() url: string;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public groupService: GroupService,
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private studentService: StudentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.url = data.url;
    if (injectedData) {
      if (typeof injectedData['student'] !== 'undefined') {
        this.student = this.injectedData['student'];
      }
    }
    this.evolutionApiUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.form = this._formBuilder.group({
      group: ['', Validators.required],
    });
    this.getUnassignedStudents();
    this.totalUnassignedStudents = this.getUnassignedStudents.length;

    this.getUnarchivedGroupsToTeacher();
  }

  getUnassignedStudents() {
    this.studentService
      .getUnassignedStudents()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {});
  }

  groupSelection(event) {
    this.selectedGroup = {
      value: event.value,
      text: event.source.triggerValue,
    };
  }

  getUnarchivedGroupsToTeacher() {
    this.groupService
      .getUnarchivedGroupsToTeacher()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        for (let group of data.unarchivedGroups) {
          if (!group.archived) {
            this.groups.push(group);
          }
        }
        for (let group of this.groups) {
          let arr = group.school_year.split('');
          arr.splice(0, 2);
          this.schoolYear = arr.join('');
          for (let teacher of group._teacher) {
            this.teacherId = teacher;
          }
        }
      });
  }

  formatYear(year) {
    return year.slice(2);
  }

  addStudentToCurrentClass(selectedGroup: any) {
    //add api call here
    this.studentService
      .addExistingStudentsToGroup(
        this.selectedGroup.value._id,
        [this.student._id],
        this.translatedText,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}

/*   getGroupsToTeacher() {
    this.groupService
      .getGroupsToTeacher()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        //console.log(data);
        this.groups = data;
      });
  } */
