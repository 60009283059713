import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslationService } from 'src/app/providers/translation.service';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import {
  AdminService,
  SchoolYears,
  TeacherData,
} from 'src/app/providers/admin.service';
import { AuthService } from 'src/app/auth/auth.service';
import { map as lsmap, find, findIndex } from 'lodash';
@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent implements OnInit, OnChanges, OnDestroy {
  translatedText: any;
  classForm: FormGroup;
  newStreamItem: FormControl;
  userType: string;
  teacherId: string;
  recommendedAvatar: string | null = null;
  filteredItems: Observable<any[]>;
  stream: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  grades: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  isMultipleSelection: boolean = true;
  teachers;
  selectedTeacher;
  groupTeachers: any = [];
  isEnableAvatar: boolean = false;
  selectedText: string | null = null;
  actualGrade: string | null = null;
  newSchoolYear: string = '';
  $timenewSchoolYear: any;
  @Input() selectedGroup: any;
  @Input() isCurrentYearValidationRequired: boolean = false;
  @Output() formDataEvent = new EventEmitter<string>();
  schoolYears: SchoolYears[] = [];
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private translationService: TranslationService,
    private adminService: AdminService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.getTeachers();
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.buildClassForm();
    this.newStreamItem = new FormControl();
    this.filteredItems = this.newStreamItem.valueChanges.pipe(
      startWith(''),
      map((item) => (item ? this.filterItems(item) : this.stream?.slice())),
    );
    this.classForm.statusChanges.subscribe((val) => {
      if (val) {
        this.formDataEvent.emit(
          JSON.stringify({ status: val, payload: this.classForm.value }),
        );
      }
    });

    this.getSchoolYearUpdateInfo();
  }

  onlyLettersValidator() {
    return (control) => {
      const value = control.value;
      if (!/^[a-zA-ZäöüÄÖÜ]+$/.test(value)) {
        return { onlyLetters: true };
      }
      return null;
    };
  }

  buildClassForm() {
    this.classForm = this._formBuilder.group({
      grade: ['', Validators.required],
      stream: ['', [Validators.required, this.onlyLettersValidator()]],
      school_year: ['', Validators.required],
      avatar: [''],
      teachers: [
        this.userType == 'teacher' ? [this.teacherId] : '',
        Validators.required,
      ],
    });

    // IF we send existing group it will be filled in the form
    if (this.selectedGroup) {
      this.classForm.patchValue({ stream: this.selectedGroup.stream });
      this.classForm.patchValue({
        school_year: this.newSchoolYear,
      });
      const selectedTeacherIds = lsmap(this.selectedGroup._teacher, '_id');
      this.classForm.patchValue({ teachers: selectedTeacherIds });
      this.groupTeachers = this.selectedGroup._teacher;
      if (this.isCurrentYearValidationRequired) {
        this.actualGrade = this.selectedGroup.grade.toString();
        const newSchoolYearGrade = Number(this.selectedGroup.grade) + 1;
        this.classForm.patchValue({
          grade: newSchoolYearGrade.toString(),
        });
        this.classForm.controls['school_year'].disable();
      }
      // this.classForm.markAllAsTouched();
    }
  }

  gradeSelection(event) {
    if (event.value >= 1 && event.value <= 4) {
      this.classForm.get('avatar').setValue('animal');
      this.recommendedAvatar = 'animal';
    } else if (event.value >= 5 && event.value <= 10) {
      this.classForm.get('avatar').setValue('planet');
      this.recommendedAvatar = 'planet';
    }
  }

  filterItems(name: string) {
    let results = this.stream.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );
    return results;
  }

  selectedTeacherValue(value) {
    for (const id of value) {
      const exactTeacher = find(this.teachers, { _id: id });
      const teacherIndex = findIndex(
        this.groupTeachers,
        (e: any) => {
          return e._id == id;
        },
        0,
      );
      if (exactTeacher && teacherIndex === -1) {
        this.groupTeachers.push(exactTeacher);
      }
    }
  }

  filteredTeachers(): string[] {
    return this.teachers?.filter((teacher) =>
      this.classForm.get('teachers').value?.includes(teacher._id),
    );
  }

  checkForCurrentTeacher(teacher): boolean {
    return teacher?._id == this.teacherId && teacher?._user?.role == 'teacher';
  }

  // Remove teacher from list when we click on close icon
  removeSelectedTeacher(teacher: any, event): void {
    this.groupTeachers = this.groupTeachers.filter((e: any) => {
      return e._id !== teacher._id;
    });
    const selectedTeacherIds = lsmap(this.groupTeachers, '_id');
    this.classForm.controls['teachers'].patchValue(selectedTeacherIds);
    event.stopPropagation();
  }

  getSelectedText() {
    this.selectedText = this.teachers?.filter((teacher: any) =>
      this.classForm.get('teachers').value?.includes(teacher._id),
    );
    return this.selectedText;
  }

  checkTeacherArray(teacher, groupTeacher) {
    // get disabled teacher length
    if (groupTeacher) {
      return groupTeacher.some((item) => item._id === teacher);
    }
  }

  // Maximum 3 teachers allowed
  checkGrouplength(groupTeacher) {
    if (groupTeacher?.length === 3) {
      return true;
    }
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      this.newSchoolYear = data.nextYear;
      if (data.yearChange) {
        this.schoolYears.push({
          name: data.nextYear.slice(2),
          value: data.nextYear,
        });
      } else {
        this.schoolYears.push({
          name: data.year.slice(2),
          value: data.year,
        });
      }
      this.$timenewSchoolYear = setTimeout(() => {
        this.classForm.patchValue({
          school_year: data.yearChange ? data.nextYear : data.year,
        });
      }, 500);
    });
  }

  getTeachers() {
    this.adminService
      .getTeacherList(1, 50, 'lastname', 1, {
        search: '',
        groups: [],
        status: [],
        role: [],
      })
      .pipe(
        map((teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data;
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedGroup']['currentValue']) {
      this.selectedGroup = null;
      this.selectedGroup = changes['selectedGroup']['currentValue'];
    }
  }

  ngOnDestroy(): void {
    if (this.$timenewSchoolYear) {
      clearTimeout(this.$timenewSchoolYear);
    }
  }
}
