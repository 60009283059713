import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject, first, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DiagnosticRepeatComponent } from 'src/app/diagnostic/diagnostic-repeat/diagnostic-repeat.component';
import { NotificationOverlayComponent } from 'src/app/helpers/notification-overlay/notification-overlay.component';
import { BookService } from 'src/app/providers/book.service';
import { TaskData, TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { TaskEditDiagnosticComponent } from 'src/app/task/task-edit-diagnostic/task-edit-diagnostic.component';
import { TaskEditTrainingComponent } from 'src/app/task/task-edit-training/task-edit-training.component';
//import { MatTableDataSource } from '@angular/material/table';
//import { StudentReportComponent } from 'src/app/student/student-report/student-report.component';

export interface Tasks {}

@Component({
  selector: 'app-teacher-task-list',
  templateUrl: './teacher-task-list.component.html',
  styleUrls: ['./teacher-task-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class TeacherTaskListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'username',
    '_group',
    'type',
    'created',
    'deadline',
    'description',
    'book_name',
    'button',
  ];
  finishedColumns: string[] = [
    'groupName',
    'type',
    'completed_at',
    'description',
    'book_name',
    /*'reading_points',*/ 'button',
  ];
  finishedNestedColumns: string[] = [
    'placeholder',
    'username',
    '_group',
    'type',
    'created',
    'completed_at',
    'description',
    'book_name',
    /*'reading_points',*/ 'button',
  ];
  nestedColumns: string[] = [
    'placeholder',
    'username',
    '_group',
    'type',
    'created',
    'deadline',
    'description',
    'book_name',
    'button',
  ];
  dataSource: TaskData = null;
  pageEvent: PageEvent;
  @Input('activeTab') activeTab: string;
  status = 'all';
  @Output('totalCounts') informTotalCounts = new EventEmitter<number>();
  page = 1;
  limit = 10;
  sortBy = 'deadline';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    type: [],
    deadline: {},
    groups: [],
    school_year: [],
  };
  isLoading: boolean = true;
  isActive: boolean = false;
  isFinished: boolean = false;
  isExpired: boolean = false;
  id;
  // tasks: Tasks[] = [];
  finishedTasks = [];
  activeTasks = [];
  expiredTasks = [];
  book: any;
  @Input('selectedView') selectedView: any;
  isCardView: boolean = true;
  isTableView: boolean = false;
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
  finishedColumnsToDisplayWithExpand = ['expand', ...this.finishedColumns];
  expandedElement: any | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  taskDeadline;

  taskTypes: any[] = [];
  filterFormGroup: FormGroup;
  deadlineRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  taskGroupOptions: any[] = [];
  pageIsloaded: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog, // TODO: private or public?
    public router: Router, // TODO: private or public?
    private route: ActivatedRoute,
    private authService: AuthService,
    private bookService: BookService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.setTranslation(this.route.snapshot.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });
    this.filterFormGroup = new FormGroup({
      type: new FormControl(),
      groups: new FormControl(),
    });

    this.route.queryParamMap.pipe(first()).subscribe((queryParams) => {
      if (queryParams.get('date') !== null) {
        const currentDate = moment();
        const startDate = moment(currentDate)
          .subtract(7, 'days')
          .startOf('day')
          .toDate();
        const endDate = moment(currentDate).endOf('day').toDate();

        this.deadlineRange.get('start').setValue(startDate);
        this.deadlineRange.get('end').setValue(endDate);
        this.filterValues.deadline = this.deadlineRange.value;
      }
      if (queryParams.get('groupId') !== null) {
        const groupIdsArray = queryParams.get('groupId').split(',');

        this.filterFormGroup.get('groups').setValue(groupIdsArray);
        this.filterValues['groups'].push(...groupIdsArray);
      }
      if (queryParams.get('status') !== null) {
        this.status = queryParams.get('status');
        if (this.status === 'finished') {
          this.sortBy = 'completed_at';
          this.sortDirection = -1;
        } else {
          this.sortBy = 'deadline';
          this.sortDirection = 1;
        }
      }
      this.isLoading = true;
      this.getSubTasks();
    });

    // teacher id
    this.id = this.authService.getTeacherId();
    if (this.status === 'finished') {
      this.sortBy = 'completed_at';
      this.sortDirection = -1;
    }

    // Subscribe to service to load new data as soon as new task got created
    this.taskService.getSubTasksFromTeacherTrigger$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.getSubTasks();
      });
  }

  setTranslation(translatedText: any) {
    this.translatedText = translatedText;
    this.taskTypes = [
      { value: 'diagnostic-pre', viewValue: 'Diagnose 1' },
      { value: 'diagnostic-mid', viewValue: 'Diagnose 2' },
      { value: 'diagnostic-post', viewValue: 'Diagnose 3' },
      {
        value: 'single',
        viewValue: this.translationService.getTranslatedText('single_mode'),
      },
      {
        value: 'multiple',
        viewValue: this.translationService.getTranslatedText('multiple_mode'),
      },
      {
        value: 'auto',
        viewValue: this.translationService.getTranslatedText('auto_mode'),
      },
    ];
  }

  // getTasksByStatus(status) {
  //   return this.tasks.filter((item) =>{
  //     return item["status"] === status
  //   });
  // }

  // getTableData(status) {
  //   return this.dataSource = new MatTableDataSource<TaskData>(this.tasks.filter((item) =>{
  //     return item["status"] === status
  //   }));
  // }

  getNestedTableData(element) {
    console.log(element);
  }

  getBookTitle(bookIsbn) {
    // Get book
    this.bookService.getBookById(bookIsbn);
    this.bookService
      .getBookUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.book = data['title'];
      });
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value === selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  resetFilter() {
    this.status = 'all';
    this.filterFormGroup.get('type').setValue([]);
    this.filterFormGroup.get('groups').setValue([]);
    this.deadlineRange.reset();
    this.filterValues = {
      search: '',
      type: [],
      deadline: {},
      groups: [],
      school_year: [],
    };
    this.getSubTasks();
  }

  /**
   * API call on adminService to retrieve requested teachers with sorted direction
   */
  onSortChange(event: MatSort) {
    this.status = this.status;
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction === 'asc' ? 1 : -1;
    this.getSubTasks();
  }

  /**
   * API call on taskService to retrieve subTasks from teacher
   */
  getSubTasks() {
    this.taskService
      .getSubTasksFromTeacher(
        this.status,
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (taskData: TaskData) => {
          this.dataSource = taskData;
          this.taskGroupOptions = taskData['groups'].map((item) => {
            return {
              value: item['_id'],
              viewValue:
                item['grade'] +
                item['stream'] +
                ' - ' +
                item['school_year'].slice(2),
            };
          });
          this.isLoading = false;
        },
        error: () => (this.isLoading = false),
      });
  }

  /**
   * API call on adminService to retrieve requested teachers by pagination
   */
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getSubTasks();
  }

  onSelectChange(event: any, filter) {
    console.log(event, filter);
    //if (this.filterValues[filter].length !== event.value.length) {
    console.log(this.filterValues);
    if (filter === 'groups') {
      this.pageIsloaded = true;

      let queryParams = { ...this.route.snapshot.queryParams };
      if (event.value.length > 0 && event.value[0] !== '') {
        // If there are selected values (and not an empty string), set the 'groupId' parameter in url
        this.filterValues[filter] = event.value;
        queryParams['groupId'] = event.value.join(',');
      } else {
        // If nothing is selected, remove the 'groupId' parameter from url
        this.filterValues[filter] = [];
        queryParams['groupId'] = null;
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // reuse existing query params
      });
    } else {
      this.filterValues[filter] = event.value;
    }
    // Load subtasks
    this.getSubTasks();
    // }
  }

  openStudentReport(taskId, studentId): void {
    this.router.navigate(['/student-report/' + taskId], {
      queryParams: { studentId: studentId },
    });
    // let dialogRef;
    //   dialogRef = this.dialog.open(StudentReportComponent, {
    //     width: '100%',
    //     autoFocus: false,
    //     panelClass: 'student-report-overlay-panel',
    //    // hasBackdrop: false,
    //     disableClose: true,
    //     backdropClass: 'student-report-overlay-panel-backdrop',
    //     data: {
    //       taskId: taskId,
    //       studentId: studentId
    //     }
    //   });
    // // dialogRef.disableClose = true;
    // dialogRef.afterClosed().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(res => {
    // });
  }

  checkFinishedLatelly(task) {
    return task?.finishedTasks === task?.tasks?.length ? true : false;
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'diagnostic-pre':
        title = 'Diagnose 1';
        break;
      case 'diagnostic-mid':
        title = 'Diagnose 2';
        break;
      case 'diagnostic-post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  generateTaskTypeTitle(type, mode) {
    return this.taskService.generateTaskTypeTitle(type, mode);
  }

  generateTaskTypeClasses(prefix, type, mode) {
    return this.taskService.generateTaskTypeClasses(prefix, type, mode);
  }

  generateTaskEvaluationTitle(evaluation) {
    return this.taskService.generateTaskEvaluationTitle(evaluation);
  }

  generatePercentForContinousTask(tasks: any[]) {
    let totalSum = [];
    if (tasks != undefined) {
      const taskTime = tasks[0].time;
      totalSum = tasks.map((task) => {
        let timeUsed = 0;
        if (task?._split_task && task?._split_task.length > 0) {
          timeUsed = task._split_task.reduce((sum, splitTask) => {
            return sum + splitTask.time_used;
          }, 0);
        }
        return Math.min((timeUsed / taskTime) * 100, 100);
      });
      //console.log(totalSum)
    }
    // Sum up progress of all tasks and devide by tasks length for average
    let average = totalSum.reduce((a, b) => a + b, 0) / totalSum.length;
    return Math.min(Math.round(average), 100);
  }

  getAvatarUrl(studentId, students) {
    return students.filter((item) => item._id === studentId)[0]?.avatarUrl;
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  ngOnDestroy(): void {
    this.pageIsloaded = false;
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  openNotificationOverlay(taskId, notification, student): void {
    console.log(taskId);
    console.log(student);
    let dialogRef;
    dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        taskId: taskId,
        //recipient: this.student["_user"],
        student: student,
        notification: notification,
      },
    });
    // dialogRef.disableClose = true;
    // dialogRef.afterClosed().subscribe();
  }

  showAlert() {
    alert('Under Construction');
  }

  editTask(taskId, type) {
    let taskType;
    let diagnosticType;
    if (
      type === 'diagnostic-pre' ||
      type === 'diagnostic-mid' ||
      type === 'diagnostic-post'
    ) {
      taskType = 'diagnostic';
      diagnosticType = type;
    } else {
      taskType = 'training';
    }
    if (taskType === 'training') {
      let dialogRef = this.dialog.open(TaskEditTrainingComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'create-training-task-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {
          taskId: taskId,
          taskType: taskType,
          diagnosticType: diagnosticType,
        },
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.getSubTasks();
        });
    } else if (taskType === 'diagnostic') {
      let dialogRef = this.dialog.open(TaskEditDiagnosticComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'create-training-task-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {
          taskId: taskId,
          taskType: taskType,
          diagnosticType: diagnosticType,
        },
      });
      // dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((res) => {
        this.getSubTasks();
      });
    }
  }

  selectTaskStatus(task) {
    this.status = task.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';

    if (task.value === 'finished') {
      this.sortBy = 'completed_at';
      this.sortDirection = -1;
    } else {
      this.sortBy = 'deadline';
      this.sortDirection = 1;
    }

    const currentParams = { ...this.route.snapshot.queryParams };
    currentParams.status = this.status;

    // Erzeuge die aktualisierte URL
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: currentParams,
    });

    this.isLoading = true;
    this.getSubTasks();
  }

  selectTaskType(task) {
    console.log(task.value);
    this.filterValues.type = task.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';

    if (this.status === 'finished') {
      this.sortBy = 'completed_at';
      this.sortDirection = -1;
    } else {
      this.sortBy = 'deadline';
      this.sortDirection = 1;
    }
    this.isLoading = true;
    this.getSubTasks();
  }

  selectTaskDeadline() {
    console.log(this.deadlineRange);
    if (
      this.deadlineRange.value.start != null &&
      this.deadlineRange.value.end != null
    ) {
      this.filterValues.deadline = this.deadlineRange.value;
      this.page = 1;
      this.limit = 10;
      this.matSortDirection = 'asc';
      this.sortBy = 'deadline';
      this.sortDirection = 1;

      this.isLoading = true;
      this.getSubTasks();
    } else if (
      this.deadlineRange.value.start == null &&
      this.deadlineRange.value.end == null
    ) {
      this.filterValues.deadline = {};
      this.page = 1;
      this.limit = 10;
      this.matSortDirection = 'asc';
      this.sortBy = 'deadline';
      this.sortDirection = 1;
      this.getSubTasks();
    }
  }

  repeatDiagnostic(masterTaskId, type, studentId) {
    let taskType;
    let diagnosticType;
    if (
      type === 'diagnostic-pre' ||
      type === 'diagnostic-mid' ||
      type === 'diagnostic-post'
    ) {
      taskType = 'diagnostic';
      diagnosticType = type;
    } else {
      taskType = 'training';
    }
    let dialogRef = this.dialog.open(DiagnosticRepeatComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        taskId: masterTaskId,
        diagnosticType: diagnosticType,
        studentId: studentId,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.getSubTasks();
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.getSubTasks();
      });
  }

  checkSubTasksFinished(task) {
    return task.subtasks?.some((subtask) => subtask.status === 'finished');
  }

  openStudentOverview(studentId, groupId): void {
    this.router.navigate(['/student-overview/' + studentId], {
      queryParams: { groupId: groupId },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab?.previousValue !== changes.activeTab?.currentValue) {
      this.page = 1;
      this.limit = 10;
      this.sortBy = 'deadline';
      this.sortDirection = 1;
      this.matSortDirection = 'asc';
    }

    // if (changes.activeTab?.currentValue === "isActive") {
    //   this.isActive = true;
    //   this.isFinished = false;
    //   this.isExpired = false;
    //   if (this.status === 'active') {
    //     // Load content only on tab change
    //     this.getSubTasks();
    //   }
    // } else if (changes.activeTab?.currentValue === "isFinished") {
    //   this.isActive = false;
    //   this.isFinished = true;
    //   this.isExpired = false;
    //   if (this.status === 'finished') {
    //     this.sortBy = 'completed_at';
    //     this.sortDirection = -1;
    //     // Load content only on tab change
    //     this.getSubTasks();
    //   }
    // } else if (changes.activeTab?.currentValue === "isExpired") {
    //   this.isActive = false;
    //   this.isFinished = false;
    //   this.isExpired = true;
    //   if (this.status === 'expired') {
    //     // Load content only on tab change
    //     this.getSubTasks();
    //   }
    // }

    // if(changes.tasks != undefined) {
    //   if (changes.tasks?.firstChange === false) {
    //     //console.log(changes)
    //     this.isLoading = false;
    //   }
    // }
    if (changes.selectedView?.currentValue === 'card') {
      this.isCardView = true;
      this.isTableView = false;
    } else if (changes.selectedView?.currentValue === 'table') {
      this.isCardView = false;
      this.isTableView = true;
    }
  }
}
