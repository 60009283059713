import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  progressValue = 0;
  @Input('progress') progress: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress.previousValue != changes.progress.currentValue) {
      //setTimeout(() => {
      this.progressValue = this.progress;
      // }, 2000);
    }
  }
}
