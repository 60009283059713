<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="row">
      <div class="col-md-5 training-task-stepps"></div>
      <div class="col-md-7 training-task-stepper-col">
        <button
          mat-mini-fab
          type="button"
          class="close-button close-button--left"
          (click)="closeButton.emit(true)"
          matSuffix
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
        </button>
        <div class="training-task_container">
          <div class="step_content">
            <div class="training-task_wrap" [style.display]="selected ? 'block' : 'none'">
              <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
