import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '../../../helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from '../../../material.module';
import { AdminTeacherManagingComponent } from './admin-teacher-managing.component';
import { TeacherManagingStepperComponent } from './teacher-managing-stepper/teacher-managing-stepper.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  declarations: [
    AdminTeacherManagingComponent,
    TeacherManagingStepperComponent,
  ],
  exports: [AdminTeacherManagingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminTeacherManagingModule {}
