<div class="container-fluid login-container">
  <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
  <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
  <div class="login row">
    <div class="col-md-12 login_left">
      <div class="login_wrap">
        <h2 class="h2 h2--bold" *ngIf="userRole === 'teacher'">
          {{ translatedText?.login_title }}
        </h2>
        <h2 class="h2 h2--bold" *ngIf="userRole !== 'teacher'">
          {{ translatedText?.login_student_title }}
        </h2>
        <form (submit)="login(loginForm)" #loginForm="ngForm">
          <div class="login-input-wrapper">
            <mat-label *ngIf="userRole === 'teacher'">{{ translatedText?.login_email }}</mat-label>
            <mat-label *ngIf="userRole !== 'teacher'">{{ translatedText?.login_username }}</mat-label>
            <mat-form-field floatLabel="auto">
              <input required matInput name="username" ngModel type="username" #usernameInput="ngModel" />
              <mat-error *ngIf="usernameInput.invalid">
                {{ translatedText?.login_error }}
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="loginStatus === 'credentials'" class="login-input-wrapper">
            <mat-label>{{ translatedText?.login_password }}</mat-label>
            <mat-form-field floatLabel="auto">
              <input
                required
                #passwordField
                type="password"
                name="password"
                ngModel
                matInput
                #passwordInput="ngModel"
              />
              <button
                mat-fab
                type="button"
                class="hide-password"
                (click)="
                  passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
                "
                matSuffix
              >
                <mat-icon *ngIf="passwordField.type === 'password'" svgIcon="streamline_outlined:closed_eye"></mat-icon>
                <mat-icon *ngIf="passwordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
              </button>
              <mat-error *ngIf="passwordInput.invalid">
                {{ translatedText?.login_error }}
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="loginStatus === 'otp' || loginStatus === 'otpError'" class="login-input-wrapper">
            <mat-label>{{ translatedText?.login_otp }}</mat-label>
            <mat-form-field floatLabel="auto">
              <input
                matInput
                name="otpToken"
                ngModel
                #otpTokenInput
                aria-label="OTP Token"
                placeholder="Verifizierungs-Code"
                required
              />
              <mat-error *ngIf="loginStatus === 'otpError'"> Please enter a valid OTP token. </mat-error>
            </mat-form-field>
          </div>

          <div class="remember-forgot-password">
            <mat-checkbox
              class="remember-me"
              name="rememberMe"
              ngModel
              #rememberMeCheckbox="ngModel"
              aria-label="Remember me"
            >
              <p class="paragraph">{{ translatedText?.login_remember }}</p>
            </mat-checkbox>
            <div class="links-wrap" *ngIf="userRole === 'teacher'">
              <a class="links" [routerLink]="['/forgot-password']">{{ translatedText?.login_forgot_password }}</a>
            </div>
            <div class="links-wrap" *ngIf="userRole !== 'teacher'">
              <a class="links" (click)="openForgotPasswordOverlay()">{{ translatedText?.login_forgot_password }}</a>
            </div>
          </div>
          <div class="instructionVideo_wrap">
            <button
              [disabled]="loginForm.invalid"
              mat-flat-button
              color="primary"
              type="submit"
              class="mat-flat-button--full"
              aria-label="LOG IN"
            >
              {{ translatedText?.login_button }}
            </button>
          </div>
        </form>
        <img class="footer-img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
      </div>
    </div>
  </div>
</div>
