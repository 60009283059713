<div
  class="container-fluid container-fluid--bg-gray container-fluid--full-height task-preview-container"
  *ngIf="!isTaskStarted && !isBookViewStudent"
>
  <div class="row justify-content-center">
    <div class="col-md-4 col-sm-12 task-preview_book-info-col">
      <div class="task-preview_info">
        <div class="task-preview_book-info">
          <div class="task-preview_book-info--title">
            <h2 class="h2 h2--bold">{{ replaceHtmlContent(book?.title) }}</h2>
            <p class="paragraph" *ngIf="book?.author">{{ translatedText.task_preview_from }} {{ book?.author }}</p>
          </div>
          <div
            class="task-preview_chip-wrap"
            *ngIf="book?.lower_grade_barrier || book?.upper_grade_barrier || book?.upper_grade_barrier"
          >
            <div class="task-preview_chip">
              <p class="paragraph">
                <!-- <span *ngIf="book?.lower_grade_barrier">{{book?.lower_grade_barrier}}.</span><span *ngIf="book?.lower_grade_barrier && book?.upper_grade_barrier">-</span><span *ngIf="book?.upper_grade_barrier">{{book?.upper_grade_barrier}}.</span> Klasse -->
                <span *ngIf="book?.upper_grade_barrier"
                  >{{ translatedText.task_preview_time }}
                  {{ book?.lower_age_barrier }}
                </span>
                {{ translatedText.task_preview_years }}
              </p>
            </div>
          </div>
          <div class="task-preview_book-description">
            <p class="paragraph">
              <!-- {{book?.book_description}} -->
              {{
                book?.book_description.length > 350
                  ? (replaceHtmlContent(book?.book_description) | slice: 0 : 350) + '...'
                  : replaceHtmlContent(book?.book_description)
              }}
            </p>
          </div>
        </div>
        <div class="task-preview-reading-info">
          <div class="reading-info_box">
            <mat-icon svgIcon="streamline_outlined:headphones"></mat-icon>
            <p class="paragraph">
              {{ translatedText.task_preview_use_headset }}
            </p>
          </div>
          <div class="reading-info_box">
            <mat-icon svgIcon="streamline_outlined:mic"></mat-icon>
            <p class="paragraph">{{ translatedText.task_preview_start }}</p>
          </div>
          <div class="reading-info_box">
            <mat-icon svgIcon="streamline_outlined:stop"></mat-icon>
            <p class="paragraph">{{ translatedText.task_preview_stop }}</p>
          </div>
          <div class="reading-info_box">
            <mat-icon svgIcon="streamline_outlined:couch"></mat-icon>
            <p class="paragraph">{{ translatedText.task_preview_keep }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-sm-12 task-preview_book_col">
      <button mat-mini-fab type="button" class="close-button close-button--right" [routerLink]="['/dashboard-student']">
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      </button>
      <div class="task-preview_book">
        <div class="task-preview_book_hint">
          <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
          <p class="paragraph">
            <ng-container
              *ngIf="
                task?.type == 'diagnostic-pre' ||
                task?.type == 'diagnostic-mid' ||
                task?.type == 'diagnostic-post' ||
                task?.type == 'autonomous-placement'
              "
            >
              {{ translatedText.task_preview_read_loud }}
              <strong
                >{{ task?.time }}
                {{ task?.time > 1 ? translatedText.task_preview_minutes : translatedText.task_preview_minute }}
              </strong>
              {{ translatedText.task_preview_shown_text }}<br />
            </ng-container>

            <ng-container *ngIf="task?.type == 'text'">
              {{ translatedText.task_preview_read_loud }} {{ task?.wordcount }}
              <strong>
                {{ translatedText.task_preview_words }}
                {{ replaceHtmlContent(task?.book_name) }}</strong
              >.<br />
            </ng-container>
            <ng-container *ngIf="task?.type == 'time'">
              {{ translatedText.task_preview_read_loud }}
              <strong>
                {{ task?.time }}
                {{
                  task?.time === 1 ? translatedText.task_preview_minute : translatedText.task_preview_minutes
                }}</strong
              >
              <span *ngIf="task?.book_name">
                {{ translatedText.task_preview_in }}&nbsp;<strong
                  ><span>{{ replaceHtmlContent(task?.book_name) }}</span></strong
                ></span
              ><strong
                ><span *ngIf="task?.mode == 'multiple'"> {{ translatedText.task_preview_multiple_steps }}</span></strong
              >.<br />
            </ng-container>
            <ng-container *ngIf="task?.type == 'time-choice'">
              {{ translatedText.task_preview_read_loud }}
              <strong
                >{{ task?.time }}
                {{ task?.time === 1 ? translatedText.task_preview_minute : translatedText.task_preview_minutes }}
              </strong>
              {{ translatedText.task_preview_book }}
              <span *ngIf="task?.mode == 'multiple'"> {{ translatedText.task_preview_multiple_steps }}</span>
              <br />
            </ng-container>
          </p>
        </div>
        <img
          src="{{ getBookCoverByIsbn(book?.isbn, 'normal') }}"
          alt="{{ book?.title }}"
          (error)="handleImageError($event, book)"
          *ngIf="book"
        />
        <div class="task-preview-buttons">
          <button
            mat-flat-button
            color="secondary-student"
            (click)="selectBook(task?._id)"
            *ngIf="task?.type == 'time-choice' && task?._split_task.length == 0"
          >
            {{ translatedText.task_preview_choose_another }}</button
          ><!-- task?.type == 'time' && task?.mode == 'autonomous' || -->
          <button mat-flat-button color="primary-student" (click)="startTaskOverlay(task)">
            {{ translatedText.task_preview_start_reading }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isTaskStarted">
  <app-record-rtc [startFrom]="startFrom" [task]="task" [startWord]="startWord" [bookReaderSettings]="bookReaderSettings"></app-record-rtc>
</ng-container>

<ng-container *ngIf="isBookViewStudent">
  <app-book-view-student [task]="task" (selectedStartEvent)="selectedStartWord($event)" (backToTaskPreview)="bookViewClosed($event)"></app-book-view-student>
</ng-container>
