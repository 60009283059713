import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';

@NgModule({
  declarations: [SuccessSnackbarComponent, ErrorSnackbarComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [SuccessSnackbarComponent, ErrorSnackbarComponent],
})
export class SnackbarModule {}
