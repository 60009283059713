import { Directionality } from '@angular/cdk/bidi';
import {
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-task-edit-training-stepper',
  templateUrl: './task-edit-training-stepper.component.html',
  styleUrls: ['./task-edit-training-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: TaskEditTrainingStepperComponent },
  ],
})
export class TaskEditTrainingStepperComponent extends CdkStepper {
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('isClosedDialog') closeButton = new EventEmitter<boolean>();
  @Input('stepValue') stepValue: number;
  @Input('bookSelected') bookSelected: boolean;

  backButton: boolean = true;
  isBookSelected;

  onClick(index: number): void {
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    if (this.selectedIndex > 0) {
      this.backButton = false;
    } else {
      this.backButton = true;
    }
  }

  ngOnInit() {}

  moveBack() {
    if (this.selectedIndex == 1) {
      this.backButton = true;
    }
    if (this.isBookSelected == true && this.selectedIndex == 2) {
      this.selectedIndex = this.selectedIndex - 2;
      this.backButton = true;
    } else {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.stepValue);
    if (changes.stepValue != undefined) {
      if (changes.stepValue.currentValue === 0) {
        this.backButton = true;
      }
      if (changes.stepValue.currentValue > 0) {
        this.backButton = false;
      }
      if (changes.stepValue.currentValue > 0) {
        this.backButton = false;
      }
    }
    if (changes.bookSelected != undefined) {
      this.isBookSelected = this.bookSelected;
    }
  }
}
