import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';

export type UpgradeDialogData = {
  upgradeModalText?:
    | 'maxGroupsReached'
    | 'maxStudentsReached'
    | 'maxTeachersReached'
    | 'task'
    | 'readingMinutes';
};

@Component({
  selector: 'app-upgrade-banner',
  templateUrl: './upgrade-banner.component.html',
  styleUrls: ['./upgrade-banner.component.scss'],
})
export class UpgradeDialogComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<UpgradeDialogComponent>,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: UpgradeDialogData,
  ) {}

  ngOnInit(): void {
    // this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
