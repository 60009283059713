<div class="license_tail_extension">
  <button
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    (click)="closeOverlay()"
    [matDialogClose]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="license_tail_extension_overlay_wrap" *ngIf="!isTrialRequest">
    <mat-icon
      class="icon_120 text-center"
      svgIcon="streamline_outlined:trial_progress_big"
    ></mat-icon>
    <h3 class="h3 h3--bold text-center">
      {{ translatedText.license_trial_phase }}
    </h3>
    <p class="paragraph">
      {{ translatedText.license_trial_request_description }}
    </p>
    <div class="license_trial_input">
      <textarea
        matInput
        #inputs
        [(ngModel)]="trialReason"
        placeholder="{{ translatedText.license_trial_request_placeholder }}"
        required
      ></textarea>
      <!-- <mat-icon svgIcon="streamline_outlined:tips" matTooltip="{{translatedText.license_check_tooltip}}"></mat-icon> -->
      <p class="paragraph">0/200</p>
    </div>
    <div class="button_wrap">
      <button
        mat-flat-button
        color="secondary"
        type="button"
        [mat-dialog-close]="'close'"
      >
        {{ translatedText.diagnostic_create_cancel }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        [disabled]="trialReason == ''"
        (click)="sendRequset()"
      >
        <span>{{ translatedText.license_trial_send }}</span>
      </button>
    </div>
  </div>

  <div class="license_tail_extension_overlay_wrap" *ngIf="isTrialRequest">
    <mat-icon
      class="icon_132 text-center"
      svgIcon="streamline_outlined:success_tick_v2"
    ></mat-icon>
    <h3 class="h3 h3--bold text-center">
      {{ translatedText.license_request_sent_title }}
    </h3>
    <p class="paragraph">{{ translatedText.license_request_sent_desc }}</p>

    <div class="button_wrap">
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="closeOverlay()"
      >
        <span>{{ translatedText.license_trial_request_ok }}</span>
      </button>
    </div>
  </div>
</div>
