<!-- <button mat-icon-button type="button" class="circle light" (click)="closePdfOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button> -->
<div class="pdf_overlay_container">
  <div class="pdf_overlay_wrap">
    <img class="pdf_overlay_img" src="../../../assets/key.svg" alt="" />
    <h2 class="h2 h2--bold">{{ translatedText.pdf_overlay_title }}</h2>

    <p class="paragraph paragraph--l">
      {{ translatedText.pdf_overlay_desc }}
    </p>

    <div class="pdf_overlay_divider"></div>

    <p class="paragraph paragraph--l">
      {{ translatedText.pdf_overlay_warning }}
    </p>

    <button
      mat-flat-button
      color="primary"
      type="button"
      class="pdf_overlay_button"
      (click)="downloadPDF()"
      [hidden]="blobUrl == ''"
    >
      <mat-icon svgIcon="streamline_outlined:download"></mat-icon>{{ translatedText.pdf_overlay_downlaod }}
    </button>
  </div>
</div>
