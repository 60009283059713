import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReleaseNotesComponent } from './release-notes.component';
import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ReleaseNotesComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [ReleaseNotesComponent],
})
export class ReleaseNotesModule {}
