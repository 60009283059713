import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round',
})
export class RoundPipe implements PipeTransform {
  transform(value: number, digits: number = 0): number {
    return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
  }
}
