import { DatePipe, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';

export interface DialogData {
  studentId: string;
  username: string;
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-change-student-password',
  templateUrl: './change-student-password.component.html',
  styleUrls: ['./change-student-password.component.scss'],
})
export class ChangeStudentPasswordComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  resetToken;
  isLoading: boolean = false;
  headline: string = '';
  passwordMinLength = 8;
  username: string;
  studentId: string;
  group;
  isForm: boolean = true;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public resDialog: MatDialogRef<ChangeStudentPasswordComponent>,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private location: Location,
    private datePipe: DatePipe,
    private studentService: StudentService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['group'] !== 'undefined') {
        this.group = this.injectedData['group'];
      }
      if (typeof injectedData['username'] !== 'undefined') {
        this.username = this.injectedData['username'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    let currentLocation = this.location.path();
    if (currentLocation.includes('/create-password')) {
      this.headline = this.translatedText?.change_student_password_title;
    } else if (currentLocation.includes('/reset-password')) {
      this.headline = this.translatedText?.change_student_password_reset;
    }

    this.resetToken = this.route.snapshot.params['resetToken'];
    // Form init
    this.rForm = this._formBuilder.group(
      {
        password: ['', [Validators.required]],
        /*  password: [
          '',
          [
            Validators.required,
            //Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
            Validators.pattern(
              '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-_+={}\\[\\]|;:\'",.<>?]).{8,30})',
            ),
          ],
        ], */
        confirmPassword: [''],
      },
      { validators: this.checkPassword },
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  navigateToLogin(): void {
    this.router.navigate(['/login/teacher']);
  }

  checkPassword: ValidatorFn = (
    group: AbstractControl,
  ): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
    /*  if (group.get('password').valid && confirmPass !== '') {
      return pass === confirmPass ? null : { notSame: true };
    } else {
      return null;
    } */
  };

  getSinglePdf() {
    this.isLoading = true;
    this.studentService
      .regeneratePdfForStudent(
        this.group._id,
        this.studentId,
        false,
        this.translatedText,
        this.rForm.value.password,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.isLoading = false;
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
            const day = ('0' + currentDate.getDate()).slice(-2);
            let formattedDate = this.datePipe.transform(
              currentDate,
              `${year}${month}${day}_`,
            );
            let groupName =
              this.group?.grade +
              this.group?.stream +
              ' - ' +
              this.group?.school_year.slice(2);
            // create download object
            var element = document.createElement('a');
            element.setAttribute('href', blobUrl);
            element.setAttribute(
              'download',
              formattedDate + groupName + '.pdf',
            );
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            // Close overlay
            this.resDialog.close();
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }

    let formData = {
      _id: this.studentId,
      password: this.rForm.value.password,
    };

    this.studentService.updateStudent(formData).subscribe({
      next: (res) => {
        this.isForm = false;
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }
}
