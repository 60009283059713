import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormControlName,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import { TaskService } from 'src/app/providers/task.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface Tasks {}
export interface DialogData {}

@Component({
  selector: 'app-teacher-selection',
  templateUrl: './teacher-selection.component.html',
  styleUrls: ['./teacher-selection.component.scss'],
})
export class TeacherSelectionComponent implements OnInit, OnDestroy {
  userType;
  rForm: FormGroup;
  teachers;
  teacherElement;
  page = 1;
  limit = 10;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    groups: [],
    status: [],
    role: [],
  };
  selectReplacementTeacher;
  @ViewChild('step1') step1: CdkStep;
  @ViewChild('step2') step2: CdkStep;
  @ViewChild('stepper') stepper: CdkStepper;
  replaceTeacher: boolean = false;
  schoolId;
  selectedText;
  groupId;
  managingType;
  isLoading: boolean = false;
  transferTasks: boolean = false;
  teachersLoaded: boolean = false;
  groupName;
  teacherName;
  teacherId;
  translatedText: any;
  selectedTeacher;
  secondStep: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private taskService: TaskService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['teacher'] !== 'undefined') {
        this.teacherElement = this.injectedData['teacher'];
      }
      if (typeof injectedData['teacherName'] !== 'undefined') {
        this.teacherName = this.injectedData['teacherName'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    console.log(this.userType);
    this.rForm = this._formBuilder.group({
      teacher: ['', Validators.required],
    });
    this.getTeachers();
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
        this.schoolId,
      )
      .subscribe({
        next: (teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data.filter(
            (teacher) =>
              teacher._group.some((group) =>
                this.teacherElement._group
                  .map((g) => g._id)
                  .includes(group._id),
              ) && teacher._id != this.teacherElement._id,
          );
          this.teachersLoaded = true;
        },
        error: () => (this.teachersLoaded = true),
        complete: () => console.log('completed', this.teachers),
      });
  }

  deleteTeacher() {
    this.teacherService.deleteTeacher(this.teacherElement._id).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.teacher_delete_success,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        let message = this.translatedText?.technical_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    const taker_teacher_id = this.rForm.value.teacher;
    this.deleteTeacher();
    this.taskService
      .transferTasksToTeacher(
        this.teacherElement._id,
        taker_teacher_id,
        this.groupId,
      )
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
        },
      });
  }

  nextStep(formDirective: FormGroupDirective) {
    this.secondStep = true;
    this.replaceTeacher = true;
    const taker_teacher_id = this.rForm.value.teacher;
    this.teacherName = this.injectedData['teacherName'];
    this.teachers.forEach((teacher) => {
      if (teacher._id === taker_teacher_id) {
        this.selectedTeacher = teacher.firstname + ' ' + teacher.lastname;
      }
    });
  }

  removeTeacherFromGroup() {
    this.isLoading = true;
    const data = {
      teacherId: this.teacherElement._id,
      groupId: this.groupId,
    };
    console.log(data);
    this.teacherService.removeTeachersFromGroup(data).subscribe({
      next: (res) => {
        console.log(res);
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data:
            'Lehrkraft ' +
            this.teacherName +
            ' wurde von der Klasse ' +
            this.groupName +
            ' entfernt.',
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  cancelRemoval() {
    this.dialog.closeAll();
  }

  chooseTeacherForGroup() {
    this.getTeachers();
    this.transferTasks = true;
  }

  notAssignTeacher() {
    this.secondStep = true;
    this.replaceTeacher = false;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
