import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartComponent } from './combo-chart.component';
import { ComboCustomChartComponent } from './combo-custom-chart.component';
import { ComboSeriesVerticalComponent } from './combo-series-vertical.component';
@NgModule({
  declarations: [
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    ComboCustomChartComponent,
  ],
  imports: [BrowserModule, NgxChartsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    ComboCustomChartComponent,
  ],
})
export class ComboChartModule {}
