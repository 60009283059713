<div class="home-login-dialog">
  <button type="button" class="close-button close-button--right" mat-mini-fab [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="row visible-mobile">
    <div class="col-sm-12 text-center">
      <h3 class="home-login-dialog-title">{{ translatedText.parents_login_dialog_school_admin }}</h3>
      <p class="home-login-dialog-text">
        {{ translatedText.parents_login_dialog_login_text }} <span class="red">*</span>
      </p>
      <div class="home-login-dialog-btn-wrap">
        <button
          mat-flat-button
          color="primary"
          class="home-login-dialog-btn"
          type="button"
          routerLinkActive="active-link"
          [routerLink]="['/login/teacher']"
          (click)="closeDialog()"
        >
          {{ translatedText.parents_login_dialog_button_school_admin }}
        </button>
      </div>
      <div>
        <p  class="home-login-dialog-footer">
          <span class="red">*</span> {{ translatedText.parents_login_dialog_warning }}
        </p>
      </div>
    </div>
    <div class="col-sm-12 text-center">
      <h3 class="home-login-dialog-title">{{ translatedText.parents_login_dialog_student }}</h3>
      <p class="home-login-dialog-text">{{ translatedText.parents_login_dialog_student_text }}</p>
      <div class="home-login-dialog-btn-wrap">
        <button
          mat-flat-button
          class="home-login-dialog-btn orange-btn"
          routerLinkActive="active-link"
          [routerLink]="['/login']"
          (click)="closeDialog()"
        >
          {{ translatedText.parents_login_dialog_button_student }}
        </button>
      </div>
    </div>
  </div>

  <div class="visible-tablet-or-larger">
    <div class="home-login-dialog-content">
      <img src="../../assets/ellipse-man.png" />
      <img src="../../assets/ellipse-boy.png" />
    </div>
    <div class="home-login-dialog-content">
      <div class="home-login-dialog-content--desc">
        <h3 class="home-login-dialog-title">{{ translatedText.parents_login_dialog_school_admin }}</h3>
        <p class="home-login-dialog-text">
          {{ translatedText.parents_login_dialog_login_text }} <span class="red">*</span>
        </p>
      </div>
      <div class="home-login-dialog-content--desc">
        <h3 class="home-login-dialog-title">{{ translatedText.parents_login_dialog_student }}</h3>
        <p class="home-login-dialog-text">{{ translatedText.parents_login_dialog_student_text }}</p>
      </div>
    </div>
    <div class="home-login-dialog-content">
      <div class="home-login-dialog-content--btns">
        <button
          mat-flat-button
          color="primary"
          class="home-login-dialog-btn"
          type="button"
          routerLinkActive="active-link"
          [routerLink]="['/login/teacher']"
          (click)="closeDialog()"
        >
          {{ translatedText.parents_login_dialog_button_school_admin }}
        </button>
        <p *ngIf="!environment.whitelabel.hide_register_text" class="home-login-dialog-footer">
          <span class="red">*</span> {{ translatedText.parents_login_dialog_warning }}
        </p>
      </div>
      <div class="home-login-dialog-content--btns">
        <button
          mat-flat-button
          class="home-login-dialog-btn orange-btn"
          routerLinkActive="active-link"
          [routerLink]="['/login']"
          (click)="closeDialog()"
        >
          {{ translatedText.parents_login_dialog_button_student }}
        </button>
      </div>
    </div>
  </div>
</div>
