import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { MaterialModule } from 'src/app/material.module';
import { SchoolAdminCreateTrialStepperComponent } from './school-admin-create-trial-stepper/school-admin-create-trial-stepper.component';
import { SchoolAdminCreateTrialComponent } from './school-admin-create-trial.component';

@NgModule({
  declarations: [
    SchoolAdminCreateTrialComponent,
    SchoolAdminCreateTrialStepperComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [SchoolAdminCreateTrialComponent],
})
export class SchoolAdminCreateTrialModule {}
