import {
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import ysFixWebmDuration from 'fix-webm-duration';
import * as RecordRTC from 'recordrtc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../auth/auth.service';

export interface DialogData {
  message: string;
}

var n = <any>navigator; // TODO: global?

@Component({
  selector: 'app-microphone-check',
  templateUrl: './microphone-check.component.html',
  styleUrls: ['./microphone-check.component.scss'],
})
export class MicrophoneCheckComponent implements OnInit, OnDestroy {
  isStartScreen: boolean = true;
  isRecordScreen: boolean = false;
  isStartRecording: boolean = false;
  isStopRecording: boolean = false;
  isAudioScreen: boolean = false;
  isFinished: boolean = false;
  isFAQ: boolean = false;
  sendChunksToServer: boolean = false;
  chunkCount: number = 0;
  chunkHash;
  audioUrl;
  panelOpenState = false;
  isAudioAvalable: boolean = false;
  public blobIsReady: boolean = false;
  borderState = 'initial';
  showProgress: boolean = false;
  loaderState = 'void';
  translatedText;
  questions;
  randomQuestion: any;

  private stream: MediaStream;
  private config: object;
  private recorder: any;
  private timer: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.questions = [
          {
            value: translatedText?.['microphone_check_question_1'],
          },
          {
            value: translatedText?.['microphone_check_question_2'],
          },
          {
            value: translatedText?.['microphone_check_question_3'],
          },
          {
            value: translatedText?.['microphone_check_question_4'],
          },
          {
            value: translatedText?.['microphone_check_question_5'],
          },
        ];
        const randomIndex = Math.floor(Math.random() * this.questions.length);
        this.randomQuestion = this.questions[randomIndex];
        console.log(this.randomQuestion);
      });
  }

  showRecordScreen() {
    this.isStartScreen = false;
    this.isStartRecording = false;
    this.isRecordScreen = true;
  }

  async startRecording() {
    this.isStartScreen = false;
    this.isRecordScreen = false;
    this.isStartRecording = true;

    if (typeof n.mediaDevices === 'undefined' || !n.mediaDevices.getUserMedia) {
      alert('This browser does not support WebRTC getUserMedia API.');

      if (!!n.getUserMedia) {
        alert('This browser seems supporting deprecated getUserMedia API.');
      }
    }
    await this.getMedia();
  }

  getMedia() {
    n.mediaDevices
      .getUserMedia({
        video: false,
        audio: {
          echoCancellation: true,
        },
      })
      .then(this.successCallback.bind(this))
      .catch(this.errorCallback.bind(this));
  }

  successCallback(stream: MediaStream) {
    this.stream = stream;
    this.stream.getAudioTracks().forEach(function (track, index, array) {
      console.log('track ' + index);
      console.log(track);
      //track.enabled = true;
    });
    // Create hash for blob chunks to merge later
    this.chunkHash = Math.random().toString(36).substring(2, 15);
    // Change options for audio only
    this.config = {
      type: 'audio',
      //mimeType: 'audio/wav',
      mimeType: 'audio/webm;codecs=opus',
      audio: true,
      video: false,
      // FINETUNING start
      // get intervals based blobs in milliseconds
      timeSlice: 1000,
      // both for audio and video tracks
      bitsPerSecond: 128000,
      // ignored when codecs=pcm
      audioBitsPerSecond: 128000,
      // // the range 22050 to 96000.
      // sampleRate: 96000,
      // // let us force 16khz recording:
      desiredSampRate: 16000,
      // // Legal values are (256, 512, 1024, 2048, 4096, 8192, 16384).
      bufferSize: 16384,
      // FINETUNING end
      numberOfAudioChannels: 1,
      ondataavailable: this.dataAvailable.bind(this),
      recorderType: RecordRTC.MediaStreamRecorder,
    };

    console.log(this.stream);

    this.recorder = new RecordRTC.RecordRTCPromisesHandler(
      this.stream,
      this.config,
    );
    this.recorder.startRecording();

    //start 15sec timer
    this.startTimer();
  }

  startTimer() {
    this.isAudioAvalable = true;
    this.timer = setTimeout(() => {
      this.ngZone.run(() => {
        this.stopRecording();
      });
    }, 16000);
  }

  errorCallback(error) {
    this.dialog.closeAll();
    this.isAudioAvalable = false;
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      panelClass: 'snack-error',
      data: 'Mikrofonrechte wurden nicht freigegeben',
      duration: 3000,
      horizontalPosition: 'left',
    });
    console.log('error record', error);
  }

  dataAvailable(blob) {
    if (this.sendChunksToServer) {
      console.log(blob);
      //this.recordedChunks.push(blob);
      this.chunkCount++;

      // Build a form
      const chunkForm = new FormData();
      chunkForm.append('file', blob);
      // chunkForm.append('name', file.name);
      // chunkForm.append('total', blockCount);
      chunkForm.append('index', this.chunkCount.toString());
      //chunkForm.append('size', blob.size);
      chunkForm.append('chunkHash', this.chunkHash);
      // console.log(chunkForm)
    }
  }

  stopRecording() {
    // Inform to stop metamorphose
    this.isStopRecording = true;
    this.turnOffUserMedia();
    console.log(this.recorder);
    clearInterval(this.timer);
    // Stop recording
    var _this = this; // TODO: global?
    console.log('---- record stop now ----');
    this.recorder
      .stopRecording()
      .then(function () {
        //this.recorder.stopRecording(this.stopRecordingCallback()).then(function() {
        console.log('stop then fired');
        _this.blobIsReady = true;
        _this.getBlob();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  getBlob() {
    // check if blob is ready
    var _this = this; // TODO: global?
    // Generate blob and send it to server
    // let browser = this.capacitorService.getBrowserName();
    // this.recorder.getBlob().then(function(blob) {
    //   console.log('blob fired')
    //       console.log('seekable blob')
    //       RecordRTC.getSeekableBlob(blob, function(seekableBlob) {
    //         console.log(seekableBlob)
    //         let dataType = seekableBlob.type;
    //         if (browser === 'safari') {
    //           dataType = "audio/aac";
    //         }
    //         let binaryData = [];
    //             binaryData.push(seekableBlob);

    //         let newblob = new Blob(binaryData, {type: dataType});
    //         let blobUrl = URL.createObjectURL(newblob);
    //         _this.audioUrl = _this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    //         _this.isStartRecording = false;
    //         _this.isAudioScreen = true;
    //       });

    // });
    this.recorder.getBlob().then(function (blob) {
      console.log('blob fired');
      if (
        _this.capacitorService.getDevicePlatform() === 'android' ||
        (_this.capacitorService.getDevicePlatform() === 'web' &&
          _this.capacitorService.getBrowserName() === 'chrome')
      ) {
        console.log('seekable blob');
        RecordRTC.getSeekableBlob(blob, function (seekableBlob) {
          console.log(seekableBlob);
          let blob = new Blob([seekableBlob], {
            type: seekableBlob.type.replace('video', 'audio'),
          });
          console.log(blob);
          _this.showAudioPlayer(blob);
        });
      } else {
        // Get duration and fix blob
        ysFixWebmDuration(blob, 15, function (fixedBlob) {
          _this.showAudioPlayer(fixedBlob);
        });
      }
    });
    // this.reset();
  }

  showAudioPlayer(blob) {
    let dataType = blob.type;
    let browser = this.capacitorService.getBrowserName();
    if (browser === 'safari') {
      dataType = 'audio/aac';
    }
    let binaryData = [];
    binaryData.push(blob);

    let newblob = new Blob(binaryData, { type: dataType });
    let blobUrl = URL.createObjectURL(newblob);
    this.audioUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    this.isStartRecording = false;
    this.isAudioScreen = true;
  }

  turnOffUserMedia() {
    // Close Microphone on single reading
    if (this.stream != undefined) {
      this.stream.getTracks().forEach(function (track, index, array) {
        track.stop();
      });
    }
  }

  finishedScreen() {
    this.isAudioScreen = false;
    this.isFinished = true;
  }

  faqScreen() {
    this.isAudioScreen = false;
    this.isFAQ = true;
  }

  repeatTest() {
    this.isFAQ = false;
    this.isAudioScreen = false;
    this.isStartScreen = false;
    this.isStopRecording = false;
    clearInterval(this.timer);
    this.chunkCount = 0;
    this.chunkHash = null;
    this.audioUrl = null;
    this.startRecording();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.turnOffUserMedia();
  }
}
