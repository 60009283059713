import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StudentProgressLevelComponent } from './student-progress-level.component';

@NgModule({
  declarations: [StudentProgressLevelComponent],
  imports: [BrowserModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [StudentProgressLevelComponent],
})
export class StudentProgressLevelModule {}
