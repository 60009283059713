import { CdkStepper } from '@angular/cdk/stepper';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { HelpdeskOverlayComponent } from 'src/app/helpers/helpdesk-overlay/helpdesk-overlay.component';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface School {
  _id?: string;
  name?: string;
  zip?: string;
  city?: string;
  address?: string;
  school_type?: string;
  state?: string;
  uuid: string;
}

/**
 * Teacher create form
 */
@Component({
  selector: 'app-school-admin-create-no-school',
  templateUrl: './school-admin-create-no-school.component.html',
  styleUrls: ['./school-admin-create-no-school.component.scss'],
})
export class SchoolAdminCreateNoSchoolComponent implements OnInit {
  environment = environment;
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('schoolNameInput', { static: true }) schoolNameInput: ElementRef;
  rForm: FormGroup;
  personalForm: FormGroup;
  schoolForm: FormGroup;
  confirmStep;
  createSchool;
  userTypes: string[];
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation: string[] = ['Herr', 'Frau', 'Divers'];
  showAddButton: boolean = false;
  selectedIndex: number;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  isWebmail: boolean = false;
  isPhone: boolean = false;
  infoEmailText: string =
    'Dieser Dienst darf nur von Lehrkräften im Rahmen Ihrer Lehrtätigkeit und zur Evaluation des Dienstes genutzt werden. \n\n Bitte nutzen Sie für die Registrierung Ihre dienstliche E-Mail-Adresse. Sollten Sie über keine entsprechende E-Mail-Adresse verfügen und eine Webmail-Adresse (@gmail, @aol, @web, etc.) nutzen wollen, werden wir vor Gewährung eines Zugangs ggf. Ihre Identität überprüfen. Geben Sie hierzu bitte auch Ihre Telefonnummer an.';
  isLoading: boolean = false;
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  lastStep;
  isCreated: boolean = false;
  schools: School[] = [];
  filteredOptions: Observable<School[]>;
  filteredSchools: Observable<{ name: string }>;
  schoolName = new FormControl<string | School>('');
  schoolId;
  reviewCheck;
  legalMessage;
  licenseError: boolean = false;
  selectedState: string = '';
  license;
  licenseInfo = [];
  visibleSchools: boolean = true;
  isHepldesk: boolean = false;
  selectedSchool = {
    name: '',
    address: '',
    zip: '',
    city: '',
    school_type: '',
    state: '',
  };
  translatedText: any;
  states: any[] = [
    { value: 'BW', viewValue: 'Baden-Württemberg' },
    { value: 'BY', viewValue: 'Bayern' },
    { value: 'BE', viewValue: 'Berlin' },
    { value: 'BB', viewValue: 'Brandenburg' },
    { value: 'HB', viewValue: 'Bremen' },
    { value: 'HH', viewValue: 'Hamburg' },
    { value: 'HE', viewValue: 'Hessen' },
    { value: 'MV', viewValue: 'Mecklenburg-Vorpommern' },
    { value: 'NI', viewValue: 'Niedersachsen' },
    { value: 'NRW', viewValue: 'Nordrhein-Westfalen' },
    { value: 'RP', viewValue: 'Rheinland-Pfalz' },
    { value: 'SL', viewValue: 'Saarland' },
    { value: 'SN', viewValue: 'Sachsen' },
    { value: 'ST', viewValue: 'Sachsen-Anhalt' },
    { value: 'SH', viewValue: 'Schleswig-Holstein' },
    { value: 'TH', viewValue: 'Thüringen' },
  ];

  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  /**
   * Constructor registers userService and router
   */
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    console.log(this.schoolId);
    // Set school list for testing data on production
    this.route.queryParamMap.subscribe((queryParams) => {
      const debugParam = queryParams.get('debug');
      if (debugParam === 'true') {
        this.visibleSchools = false;
      }
    });

    this.translatedText = this.route.snapshot.data.translation;
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.salutation = [
          this.translatedText.salutation_male,
          this.translatedText.salutation_female,
          this.translatedText.salutation_divers,
        ];
        // legal message
        this.legalMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.translatedText.t('school_admin_create_accept_terms', {
            termsandconditionslink: `<a href="${this.environment.whitelabel.footer_terms_and_conditions_url}" target="_blank">${this.translatedText.school_admin_create_accept_terms_terms_and_conditions_text}</a>`,
            legallink: `<a href="${this.environment.whitelabel.footer_terms_of_use_url}" target="_blank">${this.translatedText.school_admin_create_accept_terms_legal_text}</a>`,
            privacylink: `<a href="${this.environment.whitelabel.footer_privacy_url}" target="_blank">${this.translatedText.school_admin_create_accept_terms_privacy_text}</a>`,
          }),
        );
      });

    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();
    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length === 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        // Check for search results
        if (value !== '') {
          this.getSchools(value);
        } else {
          this.getSchools('stringToShowNoSchool');
        }
      });

    this.personalForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
    });

    this.schoolForm = this._formBuilder.group({
      name: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', [Validators.required, this.onlyDigitsValidator()]],
      address: ['', Validators.required],
    });

    this.reviewCheck = this._formBuilder.group({
      correctData: false,
    });
  }

  navigateTo(url: string): void {
    console.log(url);
    this.router.navigateByUrl(url);
  }

  onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\d*$/.test(value)) {
        return { onlyDigits: true };
      }
      return null;
    };
  }

  getSchoolSelectionName(school) {
    if (school.name !== undefined) {
      return (
        school.zip +
        ' ' +
        school.city +
        ' | ' +
        school.name +
        ' | ' +
        school.school_type +
        ' | ' +
        school.address
      );
    } else {
      return (
        school.zip +
        ' ' +
        school.city +
        ' | ' +
        school.school_type +
        ' | ' +
        school.address
      );
    }
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  /**
   * API call on userService to retrieve requested teacher
   */
  getSchools(value) {
    this.authService
      .getSchoolList(value, this.visibleSchools)
      .pipe(
        map((schools) => {
          this.filteredSchools = of(schools);
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  checkState(state) {
    if (state !== '') {
      this.selectedState =
        this.states.find((item) => item.value === state)?.viewValue ?? null;
      this.stepper.selectedIndex = 2;
    }
  }

  /**
   * Send new input value to subscription
   */
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
    console.log(event);
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  optionSelected(option) {
    if (option.value.indexOf(this.prompt) === 0) {
      this.addOption();
    }
  }

  addOption() {
    let option = this.removePromptFromOption(this.newSalutationItem.value);
    if (!this.salutation.some((entry) => entry === option)) {
      const index = this.salutation.push(option) - 1;
      this.newSalutationItem.setValue(this.salutation[index]);
    }
  }
  removePromptFromOption(option) {
    if (option.startsWith(this.prompt)) {
      option = option.substring(this.prompt.length, option.length - 1);
    }
    return option;
  }

  displayFn(school: School) {
    console.log(school);
    return school && school.name ? school.name : '';
  }

  private _filter(name: string): School[] {
    const filterValue = name.toLowerCase();

    return this.schools.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  schoolSelection(value) {
    if (value === 'selected') {
      this.stepper.selectedIndex = 3;
      this.stepper.selectedIndex = 4;
      console.log(this.schoolName);
    } else {
      this.openSchoolRequestOverlay();
      //this.stepper.selectedIndex = 3;
    }
  }

  getSelectedSchool(school: School): void {
    const stateFullName = this.getStateFullName(school?.state);
    if (school?.uuid !== undefined) {
      this.openHelpdeskOverlay();
      this.isHepldesk = true;
    } else {
      this.isHepldesk = false;
    }
    console.log(school);
    this.selectedSchool = {
      name: school?.name,
      address: school?.address,
      zip: school?.zip,
      city: school?.city,
      school_type: school?.school_type,
      state: stateFullName,
    };
    this.schoolId = school._id;
  }

  maskEmail(email: string): string {
    if (!email) {
      return '';
    }

    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];

    const maskedUsername =
      username?.charAt(0) + '*'.repeat(username?.length - 1);
    const maskedEmail = maskedUsername + '@' + domain;

    return maskedEmail;
  }

  EmailAvailabilty() {
    this.authService
      .checkEmailAvailabilty(this.personalForm.value.email)
      .subscribe({
        next: (res) => {
          this.stepper.selectedIndex = 1;
        },
        error: (error: any) => {
          this.isLoading = false;
          if (error?.status === 403) {
            this.personalForm.get('email').setErrors({ emailTaken: true });
            // this.snackBar.open("E-Mail-Adresse bereits vergeben", "",{
            //     panelClass: 'snack-error',
            //     duration: 3000,
            //     horizontalPosition: 'right'
            //     });
          }
        },
      });
  }

  openHelpdeskOverlay(): void {
    let dialogRef = this.dialog.open(HelpdeskOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'helpdesk-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'helpdesk-panel-backdrop',
      data: {
        email: this.personalForm.value.email,
        request: 'school-taken',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openSchoolRequestOverlay(): void {
    let dialogRef = this.dialog.open(HelpdeskOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'helpdesk-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'helpdesk-panel-backdrop',
      data: {
        email: this.personalForm.value.email,
        request: 'school-missing',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  getStateFullName(stateAbbreviation: string): string {
    switch (stateAbbreviation) {
      case 'BW':
        return 'Baden-Württemberg';
      case 'BY':
        return 'Bayern';
      case 'BE':
        return 'Berlin';
      case 'BB':
        return 'Brandenburg';
      case 'HB':
        return 'Bremen';
      case 'HH':
        return 'Hamburg';
      case 'HE':
        return 'Hessen';
      case 'MV':
        return 'Mecklenburg-Vorpommern';
      case 'NI':
        return 'Niedersachsen';
      case 'NRW':
        return 'Nordrhein-Westfalen';
      case 'RP':
        return 'Rheinland-Pfalz';
      case 'SL':
        return 'Saarland';
      case 'SN':
        return 'Sachsen';
      case 'ST':
        return 'Sachsen-Anhalt';
      case 'SH':
        return 'Schleswig-Holstein';
      case 'TH':
        return 'Thüringen';
      default:
        return stateAbbreviation;
    }
  }

  resendRegistrationEmail() {
    this.authService.resendRegistrationEmail(
      this.personalForm.value.email,
      this.translatedText,
    );
  }

  navigateToPersonalInfo() {
    this.stepper.selectedIndex = 0;
  }

  navigateToSchoolSelection() {
    this.stepper.selectedIndex = 1;
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.personalForm.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    this.isCreated = true;

    this.authService
      .createSchoolAdminTrial(
        this.personalForm.value.email,
        this.personalForm.value.salutation,
        this.personalForm.value.firstname,
        this.personalForm.value.lastname,
        'school_admin',
        'Schule',
        'CH',
        '',
        '',
        '',
        this.personalForm.value.email,
        null,
        this.translatedText,
      )
      .subscribe({
        next: (res) => {
          console.log(res);
          this.isLoading = false;
          this.stepper.next();
          this.lastStep = true;
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error);
          if (error?.status === 403) {
            this.snackBar.open('E-Mail-Adresse bereits vergeben', '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          }
        },
      });
  }

  /**
   *  get active step from stepper
   */
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  // disable school if school have uuid
  isSchoolDisabled(school) {
    return school.uuid !== undefined ? true : false;
  }

  /**
   *  close overlay
   */
  closeTeacherOverlay() {
    this.dialog.closeAll();
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
