import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-reading-level-overlay',
  templateUrl: './reading-level-overlay.component.html',
  styleUrls: ['./reading-level-overlay.component.scss'],
})
export class ReadingLevelOverlayComponent implements OnInit, OnDestroy {
  studentId;
  student;
  readingLevelUpgrade;
  readingLevel;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private studentService: StudentService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['readingLevel'] !== 'undefined') {
        this.readingLevel = this.injectedData['readingLevel'];
      }
      if (typeof injectedData['readingLevelUpgrade'] !== 'undefined') {
        this.readingLevelUpgrade = this.injectedData['readingLevelUpgrade'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.studentId = this.authService.getStudentId();
    this.studentService.getStudentbyId(this.studentId).subscribe((res) => {
      this.student = res;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
