import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  Optional,
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { GroupService } from '../../providers/group.service';
import { TaskService } from '../../providers/task.service';
import { takeUntil } from 'rxjs/operators';
import { BookListComponent } from '../../books/book-list/book-list.component';
import { CdkStepper } from '@angular/cdk/stepper';
export interface Group {}
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookReaderService } from '../../book-reader/book-reader-service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TranslationService } from 'src/app/providers/translation.service';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface DialogData {}

class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class TaskCreateComponent implements OnInit {
  restServerUrl;
  firstFormStep: FormGroup;
  secondFormStep: FormGroup;
  thirdFormStep: FormGroup;
  fourthFormStep: FormGroup;
  lastFormStep: FormGroup;
  id;
  firstStep;
  groups;
  selectedStudents = [];
  isReadingTime: boolean = true;
  bookIsbn;
  selectedIndex: number;
  isNavStep: boolean = false;
  tasks;
  bookSelected: boolean = false;
  allSelected = false;
  isRead: boolean = false;
  isBook: boolean = false;
  taskType;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('stepper') stepper: CdkStepper;
  studentsArray = [];
  groupArray = [];
  groupOverview = [];
  teacherId;
  bookTitle;
  avatarArray = [];
  taskOverview = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  singleGroupStudents = [];
  singleStudent = [];
  studentId;
  todayDate: Date = new Date();
  isLoading: boolean = false;
  taskDetail = [];
  wordcount = 0;
  groupGrade;
  translatedText;
  constructor(
    private authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    private taskService: TaskService,
    private bookReaderService: BookReaderService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['singleGroup'] !== 'undefined') {
        this.isSingleGroup = this.injectedData['singleGroup'];
      }
      if (typeof injectedData['students'] !== 'undefined') {
        this.singleGroupStudents = this.injectedData['students'];
      }
      if (typeof injectedData['isSingleStudent'] !== 'undefined') {
        this.isSingleStudent = this.injectedData['isSingleStudent'];
      }
      if (typeof injectedData['student'] !== 'undefined') {
        this.singleStudent = this.injectedData['student'];
      }
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.id = this.authService.getUserId();
    this.teacherId = this.authService.getTeacherId();
    this.groupService
      .getGroupsToTeacher(false)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.groups = res;
        this.groups.sort(function (a, b) {
          return a['group'].name.localeCompare(b['group'].name);
        });
        this.groups.forEach((element) => {
          element.group._students.sort(function (a, b) {
            return a['username'].localeCompare(b['username']);
          });
        });
      });

    this.firstFormStep = this._formBuilder.group({
      taskType: ['', Validators.required],
    });

    this.secondFormStep = this._formBuilder.group({
      readingTime: ['', [Validators.required, Validators.min(1)]],
    });

    this.thirdFormStep = this._formBuilder.group({
      students: [''],
    });

    this.fourthFormStep = this._formBuilder.group({
      deadline: ['', Validators.required],
    });
    this.lastFormStep = this._formBuilder.group({});

    if (this.isSingleGroup == true) {
      this.singleGroupStudents.forEach((element) => {
        element['avatarUrl'] =
          this.restServerUrl +
          '/files/images/avatar/' +
          element.gender +
          '/' +
          element.avatar +
          '.svg';
        this.studentsArray.push(element);
        this.thirdFormStep.get('students').setValidators(null);
      });
    } else if (this.isSingleGroup == false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }

    if (this.isSingleStudent == true) {
      this.studentsArray.push(this.singleStudent);
      this.thirdFormStep.get('students').setValidators(null);
    } else if (this.isSingleGroup == false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }
  }

  // to fix error in class detail
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // add style to step 0 and set task type for selectedType()
  addStyle(value) {
    if (value == 'read') {
      this.isRead = !this.isRead;
      this.isBook = false;
    }

    if (value == 'book') {
      this.isRead = false;
      this.isBook = !this.isBook;
    }

    if (this.isRead == true) {
      this.taskType = 'time';
    } else if (this.isBook == true) {
      this.taskType = 'text';
    } else {
      this.taskType = '';
    }
  }

  // step 0 logic
  selectedType() {
    if (this.taskType == 'text') {
      // this.firstFormStep.get('taskType').setValidators(null);
      this.firstFormStep.get('taskType').setValue('read');
      this.openBookList();
      this.stepper.selectedIndex = 1;
    }
    if (this.taskType == 'time') {
      // this.firstFormStep.get('taskType').setValidators(null);
      this.bookSelected = false;
      this.firstFormStep.get('taskType').setValue('time');
      this.stepper.selectedIndex = 1;
    }
  }

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select == true) {
      students.selectAll();
      students._value.forEach((element) => {
        this.studentsArray.push(element);
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    if (this.isSingleGroup == false) {
      if (event.options[0].selected == true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected == false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup == true || this.isSingleStudent == true) {
      if (
        event.options[0].selected == false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected == true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }
  }

  closeAll() {
    this.dialog.closeAll();
  }

  openBookList(): void {
    let dialogRef = this.dialog.open(BookListComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      data: {
        groupGrade: this.groupGrade,
      },
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.path == 'book-list') {
          //set value to taskType and readingTime to skip the first
          this.firstFormStep.get('taskType').setValidators(null);
          this.secondFormStep.get('readingTime').setValidators(null);
          this.firstFormStep.get('taskType').setValue('0');
          this.secondFormStep.get('readingTime').setValue('0');
          this.bookSelected = true;
          //get isbn
          this.bookIsbn = res.res.res.isbn;
          this.bookTitle = res.res.res.title;
          // skip step 0 and 1
          this.stepper.selectedIndex = 2;
          this.isReadingTime = false;
        } else {
          this.stepper.selectedIndex = 0;
          this.bookIsbn = '';
          this.bookIsbn = '';
        }
      });
  }

  checkForSelection(student, index) {
    if (this.isSingleGroup === true || this.isSingleStudent === true) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  // Aufgaben Übersicht
  setTaskData() {
    this.taskOverview = [];
    this.groupArray = [];
    this.groupOverview = [];
    this.studentsArray.forEach((element) => {
      this.taskOverview.push({
        _id: element._id,
        gender: element.gender,
        username: element.username,
        avatar: element.avatar,
        _group: element._group,
        groupName: this.groups.filter(
          (group) => group?.group?._id == element?._group,
        )[0]?.group?.name,
        avatarUrl:
          this.restServerUrl +
          '/files/images/avatar/' +
          element.gender +
          '/' +
          element.avatar +
          '.svg',
      });
    });
    this.tasks = this.taskOverview;

    this.tasks.forEach((element) => {
      this.groupArray.push(
        this.tasks.filter((task) => element._group == task._group)[0]._group,
      );
    });

    this.wordcount = this.bookReaderService.getWordCountForTask();

    this.groupArray = this.groupArray.filter(
      (value, index) => this.groupArray.indexOf(value) === index,
    );
    console.log(this.groupArray);
    this.groupArray.forEach((element) => {
      let group = {
        id: element,
        name: this.tasks.filter((task) => element == task._group)[0].groupName,
      };
      this.groupOverview.push(group);
    });
    // this.groupArray = this.groupArray.map(obj => {
    //   //obj =  [];
    //   console.group(obj)
    //   // obj["id"] = obj;
    //  //  obj["name"] = this.tasks.filter(task => obj == task._group)[0].groupName;
    //   return obj;
    // })
    console.log(this.groupOverview);
  }

  getStundentsByGroup(id) {
    return this.tasks.filter((task) => task._group == id);
  }

  submitForm(formDirective: FormGroupDirective) {
    if (
      this.secondFormStep.invalid ||
      this.thirdFormStep.invalid ||
      this.fourthFormStep.invalid
    ) {
      console.log('Form invalid');
      return;
    }

    this.isLoading = true;
    //let studentsArray = this.thirdFormStep.value.students;
    let task;
    if (this.taskType == 'text') {
      task = {
        teacherId: this.teacherId,
        description: 'Lies ' + this.wordcount + ' Wörter in ' + this.bookTitle,
        status: 'active',
        wordcount: this.wordcount,
        type: this.taskType,
        deadline: this.fourthFormStep.value.deadline.endOf('day'),
        book_isbn: this.bookIsbn,
        book_name: this.bookTitle,
        range: this.bookReaderService.getRangeForTask(),
      };
    }
    if (this.taskType == 'time') {
      task = {
        teacherId: this.teacherId,
        description:
          'Lies ' + this.secondFormStep.value.readingTime + ' Minuten',
        status: 'active',
        time: this.secondFormStep.value.readingTime,
        type: this.taskType,
        deadline: this.fourthFormStep.value.deadline.endOf('day'),
      };
    }

    this.taskService
      .createMasterTask(task, this.teacherId, this.studentsArray)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.dialog.closeAll();
          let message = 'Die Aufgabe wurde erfolgreich erstellt';
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          this.isLoading = false;
          // update task list in studentOverview
          if (this.isSingleStudent === true) {
            this.taskService.getTasksToStudents(this.studentId);
          }
        },
        (error: any) => {
          console.log(error);
          let message = 'Es ist ein technischer Fehler aufgetreten';
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
