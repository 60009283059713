<div>
  <button mat-mini-fab type="button" [mat-dialog-close]="'classes-year-update'" class="close-button close-button--right"
    matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="text-center"><mat-icon class="icon-120" svgIcon="streamline_outlined:graduation-cap"></mat-icon></div>

  <div class="classes_year_info">
    <h3 class="h3 h3--bold mt-3">{{ translatedText.classes_year_update_heading }}</h3>
    <p class="paragraph paragraph--xl mt-3">
      {{ translatedText.classes_year_update_desc }}
    </p>
  </div>
  <div class="button_wrap_row">
    <button mat-flat-button color="secondary" type="button" (click)="closeDialog()">
      {{ translatedText.classes_update_later }}
    </button>
    <button mat-flat-button color="primary" type="button" (click)="updateClassOverLay()">{{
      translatedText.classes_update }}</button>
  </div>
</div>