import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { CdkStepper } from '@angular/cdk/stepper';
import { TranslationService } from 'src/app/providers/translation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.scss'],
})
export class AdminManagementComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  displayedColumns: string[] = ['select', 'name', 'email', 'status'];
  dataSource;
  selection;
  lastStep;
  firstStep;
  isLoading: boolean = false;
  isClosedDialog: boolean = false;
  selectedIndex;
  rForm: FormGroup;
  page = 1;
  limit = -1;
  filterValues = {
    search: '',
    groups: [],
    status: [],
    role: ['teacher'],
  };
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  teachers;
  EmailArray: string[] = [];
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.getTeachers();

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected.length;
    const numRows = this.dataSource?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /** The label for the checkbox on the passed row */

  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .pipe(
        map((teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data;
          console.log(teacherData.data);
          this.dataSource = teacherData.data;
          // this.dataSource = teacherData.data.filter(teacher => teacher["status"] === 'active');
          this.selection = new SelectionModel<any>(true, []);
        }),
      )
      .subscribe();
  }

  resetForm() {
    this.getTeachers();
    this.selection.clear();
    this.stepper.selectedIndex = 0;
  }

  submitSelection() {
    const teacherIds = this.selection._selected.map((item) => item._id);
    this.EmailArray = this.selection._selected.map((item) => item._user.email);
    console.log(this.EmailArray);
    this.isLoading = true;

    this.adminService.upgradeTeachersToSchoolAdmin(teacherIds).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.stepper.selectedIndex = 1;
        // Update teacher list
      },
      error: (error: any) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  closeTeacherOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }
}
