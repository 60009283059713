<section class="container-fluid book-list-student-container">
  <div class="step_container">
    <button
      mat-mini-fab
      type="button"
      class="close-button close-button--right task-preview"
      [routerLink]="['/dashboard-student']"
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button>
    <div class="row">
      <div class="col-md-12 book-list-col">
        <div class="row">
          <!-- <div class="col-md-9"> -->
          <div class="col-md-8">
            <h2 class="h2 h2--bold">
              {{ translatedText.book_list_student_selection }}
            </h2>
          </div>
          <div class="col-md-4">
            <mat-form-field class="book_list_search" floatLabel="never">
              <mat-label>{{
                translatedText.book_list_student_search
              }}</mat-label>
              <input
                matInput
                type="text"
                (input)="onSearchInputFilterChange($event)"
                placeholder="{{ translatedText.book_list_student_search }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="book-list-filter" *ngIf="!isTask">
          <!-- <div class="row">
              <div class="col-md-12">
                <mat-form-field class="full-width search-teacher" floatLabel="never">
                  <mat-label>Suche</mat-label>
                  <input matInput type="text" (input)="onSearchInputFilterChange($event)" placeholder="Suche">
                </mat-form-field>
              </div>
            </div> -->
          <div class="row">
            <div class="col-md-3">
              <mat-form-field class="book_list_select book_list_select--age">
                <div>
                  <mat-label>{{
                    translatedText.book_list_student_difficulty
                  }}</mat-label>
                </div>
                <mat-select
                  [formControl]="filterFormGroup.get('difficulty')"
                  floatLabel="never"
                  panelClass="dashboard-task-select"
                  disableOptionCentering
                  multiple
                >
                  <mat-select-trigger>
                    {{
                      generateSelectionText(
                        difficultyOptions,
                        filterFormGroup.get("difficulty").value
                      )
                    }}
                  </mat-select-trigger>
                  <app-select-all-option
                    [control]="filterFormGroup.get('difficulty')"
                    [values]="difficultyOptions"
                    [valuetype]="'object'"
                    (selectionChange)="onSelectChange($event, 'difficulty')"
                    [translatedText]="translatedText"
                  ></app-select-all-option>
                  <mat-option
                    *ngFor="let difficulty of difficultyOptions"
                    [value]="difficulty?.value"
                  >
                    {{ difficulty.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <mat-form-field class="book_list_select book_list_select--age">
                  <div>
                    <mat-label>Alter:</mat-label>
                  </div>
                  <mat-select [formControl]="filterFormGroup.get('age')" floatLabel="never" panelClass="dashboard-task-select" disableOptionCentering multiple>
                    <mat-select-trigger>
                      {{generateSelectionText(ageOptions, filterFormGroup.get('age').value)}}
                    </mat-select-trigger>
                    <app-select-all-option [control]="filterFormGroup.get('age')" [values]="ageOptions" [valuetype]="'object'" (selectionChange)="onSelectChange($event, 'age')"></app-select-all-option>
                    <mat-option *ngFor="let age of ageOptions" [value]="age?.value">
                      {{age.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
            <!-- <div class="col-md-3">
                <mat-form-field class="book_list_select book_list_select--grade">
                  <div>
                    <mat-label>Klasse:</mat-label>
                  </div>
                  <mat-select [formControl]="filterFormGroup.get('grade')" floatLabel="never" panelClass="dashboard-task-select" disableOptionCentering multiple>
                    <mat-select-trigger>
                      {{generateSelectionText(gradeOptions, filterFormGroup.get('grade').value)}}
                    </mat-select-trigger>
                    <app-select-all-option [control]="filterFormGroup.get('grade')" [values]="gradeOptions" [valuetype]="'object'" (selectionChange)="onSelectChange($event, 'grade')"></app-select-all-option>
                    <mat-option *ngFor="let grade of gradeOptions" [value]="grade?.value">
                      {{grade.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
            <div class="col-md-3">
              <mat-form-field
                class="book_list_select book_list_select--categories"
              >
                <div>
                  <mat-label>{{
                    translatedText.book_list_student_genre
                  }}</mat-label>
                </div>
                <mat-select
                  [formControl]="filterFormGroup.get('categories')"
                  floatLabel="never"
                  panelClass="dashboard-task-select"
                  disableOptionCentering
                  multiple
                >
                  <mat-select-trigger>
                    {{
                      generateSelectionText(
                        categoryOptions,
                        filterFormGroup.get("categories").value
                      )
                    }}
                  </mat-select-trigger>
                  <app-select-all-option
                    [control]="filterFormGroup.get('categories')"
                    [values]="categoryOptions"
                    [valuetype]="'object'"
                    (selectionChange)="onSelectChange($event, 'categories')"
                    [translatedText]="translatedText"
                  ></app-select-all-option>
                  <mat-option
                    *ngFor="let category of categoryOptions"
                    [value]="category?.value"
                  >
                    {{ category.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <ng-container *ngIf="openPreferences">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="openBookPreferences()"
                  class="book-preference-button"
                >
                  <mat-icon svgIcon="streamline_filled:magic-wand"></mat-icon>
                  {{ preferenceText }}
                </button>
              </ng-container>
              <ng-container *ngIf="!openPreferences">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="openBookPreferences()"
                  class="book-preference-button"
                >
                  <mat-icon svgIcon="streamline_filled:magic-wand"></mat-icon>
                  {{ preferenceText }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="book_list_content" *ngIf="!isLoading">
          <div *ngFor="let category of filterCategories">
            <div class="row">
              <div class="col-md-12" *ngIf="categoryCheck(category)">
                <h2>{{ category }}</h2>
              </div>
              <div class="col-md-12">
                <div class="book_wrap">
                  <swiper-container
                    #swiperRef
                    initial-slide="0"
                    navigation="true"
                    slides-per-view="4"
                  >
                    <swiper-slide *ngFor="let book of categoryFilter(category)">
                      <div class="book">
                        <div class="book_cover">
                          <img
                            src="{{
                              getBookCoverByIsbn(book?._book?.isbn, 'normal')
                            }}"
                            alt="{{ book?._book?.title }}"
                            loading="lazy"
                            (error)="handleImageError($event, book?._book)"
                            *ngIf="book?._book"
                          />
                          <div class="book_age">
                            <span
                              *ngIf="
                                book?._meta?.lower_grade_barrier &&
                                book?._meta?.lower_grade_barrier !=
                                  book?._meta?.upper_grade_barrier
                              "
                              >{{ book?._meta?.lower_grade_barrier }}.</span
                            ><span
                              *ngIf="
                                book?._meta?.lower_grade_barrier &&
                                book?._meta?.upper_grade_barrier &&
                                book?._meta?.lower_grade_barrier !=
                                  book?._meta?.upper_grade_barrier
                              "
                              >-</span
                            ><span *ngIf="book?._meta?.upper_grade_barrier"
                              >{{ book?._meta?.upper_grade_barrier }}.</span
                            >
                            {{ translatedText.book_list_student_group }}
                          </div>
                          <div class="book_difficulty">
                            <ng-container
                              *ngFor="
                                let star of getBookComplexity(
                                  book?._contenthub?.book_complexity,
                                  false
                                )
                              "
                            >
                              <mat-icon
                                color="orange"
                                svgIcon="streamline_filled:star"
                              ></mat-icon>
                            </ng-container>
                            <ng-container
                              *ngFor="
                                let star of getBookComplexity(
                                  book?._contenthub?.book_complexity,
                                  true
                                )
                              "
                            >
                              <mat-icon
                                color="gray"
                                svgIcon="streamline_filled:star"
                              ></mat-icon>
                            </ng-container>
                          </div>
                        </div>
                        <div class="book_title">
                          <p
                            class="paragraph paragraph--bold paragraph--xl"
                            [innerHtml]="book?._book?.title"
                          ></p>
                        </div>
                        <div class="view_button book_button">
                          <button
                            *ngIf="!isTask"
                            (click)="
                              updateTask(
                                book?._book?.title,
                                book?._book?.isbn,
                                book?._contenthub?.dsef_version
                              )
                            "
                            mat-flat-button
                            color="primary"
                            type="button"
                            [disabled]="!licenseActive"
                          >
                            <mat-icon
                              svgIcon="streamline_outlined:eye"
                            ></mat-icon>
                            {{ translatedText.book_list_student_view }}
                          </button>
                          <button
                            *ngIf="isTask"
                            (click)="
                              updateTask(
                                book?._book?.title,
                                book?._book?.isbn,
                                book?._contenthub?.dsef_version
                              )
                            "
                            mat-flat-button
                            color="primary-student"
                            type="button"
                            [disabled]="!licenseActive"
                          >
                            <mat-icon
                              class="icon_transparent"
                              svgIcon="streamline_outlined:eye"
                            ></mat-icon>
                            {{ translatedText.book_list_student_view }}
                          </button>
                        </div>
                        <div
                          *ngIf="!isTask"
                          (click)="
                            updateTask(
                              book?._book?.title,
                              book?._book?.isbn,
                              book?._contenthub?.dsef_version
                            )
                          "
                          class="blur_bg"
                        ></div>
                        <div
                          *ngIf="isTask"
                          (click)="
                            updateTask(
                              book?._book?.title,
                              book?._book?.isbn,
                              book?._contenthub?.dsef_version
                            )
                          "
                          class="blur_bg"
                        ></div>
                      </div>
                    </swiper-slide>
                  </swiper-container>
                </div>
              </div>
            </div>
          </div>

          <div class="book_list_empty" *ngIf="dataSource?.data?.length == 0">
            <img
              class="empty-books-img"
              src="../../../assets/no-books.svg"
              alt=""
            />
            <p class="paragraph paragraph--bold paragraph--xl">
              {{ translatedText.book_list_student_not_found }}
            </p>
          </div>

          <mat-paginator
            [hidden]="true"
            [hidePageSize]="true"
            [length]="dataSource?.meta.totalItems"
            [pageSize]="
              dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
                ? dataSource?.meta.itemsPerPage
                : dataSource?.meta.totalItems
            "
            [pageSizeOptions]="[10, 25, 100]"
            (page)="pageEvent = $event; onPaginateChange($event)"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <div class="book_list_content_loading" *ngIf="isLoading">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </div>
    </div>
  </div>
</section>
