import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminNewSchoolYearStepperComponent } from "./admin-new-school-year-stepper/admin-new-school-year-stepper.component";
import { AdminNewSchoolYearComponent } from "./admin-new-school-year/admin-new-school-year.component";
import { MaterialModule } from "src/app/material.module";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClassDetailsComponent } from "src/app/helpers/class-details/class-details.component";
import { SuccessOverlayComponent } from "src/app/helpers/success-overlay/success-overlay.component";
import { LoadingSpinnerModule } from "src/app/helpers/loading-spinner/loading-spinner.module";

@NgModule({
  declarations: [
    AdminNewSchoolYearStepperComponent,
    AdminNewSchoolYearComponent,
    ClassDetailsComponent,
    SuccessOverlayComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminNewSchoolYearModule {}
