import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-license-decline',
  templateUrl: './license-decline.component.html',
  styleUrls: ['./license-decline.component.scss'],
})
export class LicenseDeclineComponent implements OnInit, OnDestroy {
  license;
  isLoading: boolean = false;
  isForm: boolean = true;
  isInfo: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    private formBuilder: FormBuilder, // TODO: unused?
    private route: ActivatedRoute,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['license'] !== 'undefined') {
        this.license = this.injectedData['license'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  submitForm() {
    this.isLoading = true;

    let data = {
      requestId: this.license?._id,
      status: 'decline',
    };
    this.licenseService.updateSingleRequest(data).subscribe((res) => {
      this.licenseService.getRequestsToSchool();
      this.isLoading = false;
      this.isForm = false;
      this.isInfo = true;
    });
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
