import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SubscribeDialogComponent } from './subscribe-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [SubscribeDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    LoadingSpinnerModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [SubscribeDialogComponent],
})
export class SubscribeDialogModule {}
