<div class="container-fluid">
  <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" mattSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="row">
    <div class="col-md-12">
      <div class="change-student-password_wrap" *ngIf="isForm">
        <div class="change-student-password-header">
          <img src="../../../assets/password-cursor.svg" alt="password-cursor" />
          <h3 class="h3 h3--bold">{{ translatedText.change_student_password_title }}</h3>
        </div>
        <form [formGroup]="rForm" #formDirective="ngForm">
          <mat-form-field>
            <input
              #passwordField
              matInput
              formControlName="password"
              type="password"
              placeholder="{{ translatedText.change_student_password_placeholder }}"
              required
            />
            <button
              mat-mini-fab
              type="button"
              (click)="
                passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
              "
              matSuffix
            >
              <mat-icon *ngIf="passwordField.type === 'password'" svgIcon="streamline_outlined:closed_eye"></mat-icon>
              <mat-icon *ngIf="passwordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
            </button>

            <mat-error *ngIf="rForm.get('password').hasError('required')">
              <!-- Please enter your new password -->
              {{ translatedText.change_student_password_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('password').hasError('pattern')">
              <!-- Password not strong enough -->
              {{ translatedText.change_student_password_strong }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              #passwordFieldRepeat
              matInput
              formControlName="confirmPassword"
              type="password"
              placeholder="{{ translatedText.change_student_password_repeat }}"
              [errorStateMatcher]="matcher"
              required
            />
            <button
              mat-mini-fab
              type="button"
              (click)="
                passwordFieldRepeat.type === 'password'
                  ? (passwordFieldRepeat.type = 'text')
                  : (passwordFieldRepeat.type = 'password')
              "
              matSuffix
            >
              <mat-icon
                *ngIf="passwordFieldRepeat.type === 'password'"
                svgIcon="streamline_outlined:closed_eye"
              ></mat-icon>
              <mat-icon *ngIf="passwordFieldRepeat.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
            </button>
            <mat-error [hidden]="!rForm.hasError('notSame') && !rForm.get('confirmPassword').touched">
              <!-- Passwords do not match -->
              {{ translatedText.change_student_password_repeat_error }}
            </mat-error>
          </mat-form-field>

          <div class="instructionVideo_wrap">
            <button
              mat-flat-button
              color="secondary"
              [mat-dialog-close]="'close'"
              type="button"
              class="mat-flat-button--full"
              aria-label="LOG IN"
            >
              {{ translatedText.change_student_password_cancel }}
            </button>
            <button
              [disabled]="rForm.invalid"
              mat-flat-button
              color="primary"
              (click)="submitForm(formDirective)"
              type="submit"
              class="mat-flat-button--full"
              aria-label="LOG IN"
            >
              {{ translatedText.change_student_password_save }}
            </button>
          </div>
        </form>
      </div>

      <div class="change-student-password_wrap" *ngIf="!isForm">
        <div class="change-student-password-header pdf">
          <img src="../../../assets/password-pass.svg" alt="password-cursor" />
          <h2 class="h2 h2--bold">{{ translatedText.change_student_password_success }}</h2>
        </div>
        <button
          mat-flat-button
          color="primary"
          (click)="getSinglePdf()"
          type="submit"
          class="mat-flat-button--full"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading"
            ><mat-icon svgIcon="streamline_outlined:download"></mat-icon>
            {{ translatedText.change_student_password_download }}</span
          >
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
