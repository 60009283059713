import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { AdminService, GroupData } from '../../../providers/admin.service';

export interface DialogData {}

/**
 * Teacher create form
 */
@Component({
  selector: 'app--adminteacher-create',
  templateUrl: './admin-teacher-create.component.html',
  styleUrls: ['./admin-teacher-create.component.scss'],
})
export class AdminTeacherCreateComponent implements OnInit {
  rForm: FormGroup;
  userTypes: string[];
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation;
  isClosedDialog: boolean = false;
  showAddButton: boolean = false;
  selectedIndex: number;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  isWebmail: boolean = false;
  isPhone: boolean = false;
  infoEmailText: string =
    'Dieser Dienst darf nur von Lehrkräften im Rahmen Ihrer Lehrtätigkeit und zur Evaluation des Dienstes genutzt werden. \n\n Bitte nutzen Sie für die Registrierung Ihre dienstliche E-Mail-Adresse. Sollten Sie über keine entsprechende E-Mail-Adresse verfügen und eine Webmail-Adresse (@gmail, @aol, @web, etc.) nutzen wollen, werden wir vor Gewährung eines Zugangs ggf. Ihre Identität überprüfen. Geben Sie hierzu bitte auch Ihre Telefonnummer an.';
  isLoading: boolean = false;
  /**
   * Constructor registers userService and router
   */
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  lastStep;
  isCreated: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  teacherId;
  schoolId;
  roleText: string = '';
  roleTextMultiple: string = '';
  createAdmin;
  selectedRole: string = 'teacher';
  buttonText: string = '';
  translatedText;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private teacherService: TeacherService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
  ) {
    if (injectedData) {
      if (typeof injectedData['createAdmin'] !== 'undefined') {
        this.createAdmin = this.injectedData['createAdmin'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.setTranslations();
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.setTranslations();
      });

    // get teacher to get school id
    this.teacherId = this.authService.getTeacherId();
    this.teacherService
      .readSingleTeacher(this.teacherId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.schoolId = res['_school'];
      });
    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();
    this.getGroups();
    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.rForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
      position: [this.selectedRole, Validators.required],
      phone: ['', [Validators.pattern('[- +()0-9]+')]],
      groups: [],
    });
    this.cdr.detectChanges();

    this.trialStatus = this.authService.getUserTrialStatus();
    this.authService.getCurrentTrialStatus().subscribe({
      next: (trialStatus) => {
        this.trialStatus = trialStatus;
        console.log('Admin dashboard status', this.trialStatus);
      },
    });
  }

  setTranslations() {
    if (this.createAdmin === true) {
      this.selectedRole = 'school_admin';
      this.buttonText = this.translatedText.admin_teacher_create_admin;
    } else if (this.createAdmin == false) {
      this.selectedRole = 'teacher';
      this.buttonText =
        this.translatedText.admin_teacher_create_position_teacher;
    } else {
      this.selectedRole = 'teacher';
      this.buttonText =
        this.translatedText.admin_teacher_create_position_teacher;
    }
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  /**
   *  reset form and navigate to first step
   * skipTrialDialog options allows for skipping it if the function is just used
   * to reset the form on component exit
   */
  resetForm({ skipTrialDialog } = { skipTrialDialog: false }) {
    if (this.trialStatus.isActive && !skipTrialDialog) {
      this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
        UpgradeDialogComponent,
        {
          width: '85vw',
          autoFocus: false,
          disableClose: true,
          panelClass: 'home-trial-dialog-panel',
          // hasBackdrop: false,
          backdropClass: 'license-add-backdrop',
          data: { upgradeModalText: 'maxTeachersReached' },
        },
      );
      return;
    }

    this.isCreated = false;
    this.rForm.reset();
    this.rForm.get('position').setValue('teacher');
    this.markFormAsUntouched();
    this.stepper.selectedIndex = 0;
  }

  markFormAsUntouched() {
    Object.keys(this.rForm.controls).forEach((key) => {
      this.rForm.get(key).markAsUntouched();
    });
  }

  getSelectedText(): string[] {
    const selectedTexts = this.groups
      ?.filter((group) => this.rForm.get('groups').value?.includes(group._id))
      ?.map((group) => `${group.name} - ${group.school_year.slice(2)}`);
    return selectedTexts;
  }

  removeSelectedGroup(index: number, event): void {
    event.stopPropagation();
    this.rForm.get('groups').value?.splice(index, 1);
    this.rForm.get('groups').setValue(this.rForm.get('groups')?.value);
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .pipe(
        map((groupData: GroupData) => {
          console.log(groupData);
          this.groups = groupData.data;
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  createTeacher() {
    this.authService
      .createUser(
        this.rForm.value.email,
        this.rForm.value.salutation,
        this.rForm.value.firstname,
        this.rForm.value.lastname,
        this.selectedRole,
        this.schoolId,
        this.rForm.value.email,
        this.rForm.value.phone,
        this.rForm.value.comment,
        this.rForm.value.groups,
        this.rForm.value.position,
        this.translatedText,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          this.isLoading = false;
          this.stepper.selectedIndex = 1;
          this.getGroups();
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          if (error.error.message == 'E-Mail bereits vergeben') {
            this.snackBar.open(
              this.translatedText?.admin_teacher_create_email_error_used,
              '',
              {
                panelClass: 'snack-error',
                duration: 3000,
                horizontalPosition: 'right',
              },
            );
          }
          if (error.error.message == 'username taken') {
          } else {
          }
        },
      );
  }

  // createSchoolAdmin() {
  //   this.authService.createSchoolAdmin(
  //     this.rForm.value.email,
  //     this.rForm.value.salutation,
  //     this.rForm.value.firstname,
  //     this.rForm.value.lastname,
  //     'school_admin',
  //     this.schoolId,
  //     this.rForm.value.email,
  //     this.rForm.value.groups,
  //     this.rForm.value.license
  //   ).pipe(
  //     takeUntil(this._unsubscribeAll)
  //   ).subscribe(res => {
  //       console.log(res)
  //       this.isLoading = false;
  //       this.stepper.selectedIndex = 1;
  //       this.getGroups();
  //   }, (error: any) => {
  //     this.isLoading = false;
  //     	console.log(error)
  //       if (error.error.message == 'E-Mail-Adresse bereits vergeben') {
  //           this.snackBar.open("E-Mail-Adresse bereits vergeben", "",{
  //               panelClass: 'snack-error',
  //               duration: 3000,
  //               horizontalPosition: 'right'
  //               });
  //       }
  //       if (error.error.message == 'username taken') {
  //           } else {
  //       }
  //   });
  // }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      console.log('Form invalid');
      return;
    }

    this.isLoading = true;
    this.isCreated = true;
    console.log(this.rForm.value.position);
    this.createTeacher();
    this.roleText = this.translatedText?.admin_teacher_create_send_teacher;
    this.roleTextMultiple = this.translatedText?.admin_teacher_create_teachers;
  }

  /**
   *  get active step from stepper
   */
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  /**
   *  close overlay
   */
  closeTeacherOverlay(): void {
    // Reset form
    this.resetForm({ skipTrialDialog: true });
    this.dialog.closeAll();
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
