import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { TaskCompletedOverlayComponent } from './task-completed-overlay.component';

@NgModule({
  declarations: [TaskCompletedOverlayComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [TaskCompletedOverlayComponent],
})
export class TaskCompletedOverlayModule {}
