import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { StudentService } from 'src/app/providers/student.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { register } from 'swiper/element/bundle';
import { Swiper } from 'swiper/types';
import { AuthService } from '../../auth/auth.service';
import { BookData, BookService } from '../../providers/book.service';
import { LicenseService } from '../../providers/license.service';
import { BookPreferencesComponent } from '../book-preferences/book-preferences.component';
import { BookPreviewComponent } from '../book-preview/book-preview.component';

export interface DialogData {
  name: string;
  _id: string;
  url: string;
}

export interface Book {
  _id?: string;
  title?: string;
  file?: File;
  type?: string;
  size?: string;
  originalname?: string;
  path?: string;
}

// TODO: unused?
@Component({
  selector: 'app-book-list-student',
  templateUrl: './book-list-student.component.html',
  styleUrls: ['./book-list-student.component.scss'],
})
export class BookListStudentComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name'];
  dataSource: any;
  pageEvent: PageEvent;
  dialogConfig: any;
  books: any;
  categories: any = [];
  contentType: 'klett' | 'full' | 'trial' = 'full';
  userType;
  userAge = 0;
  isAdmin = false;
  quizIsVisible = false;
  id;
  screenHeight: number;
  screenWidth: number;
  pageSize = 4;
  disableBookSelection: boolean = false;
  page = 1;
  limit = 10;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    grade: [],
    age: [],
    difficulty: [],
    categories: [],
  };
  difficultyOptions: any[] = [
    { value: 1, viewValue: '1 Stern' },
    { value: 2, viewValue: '2 Sterne' },
    { value: 3, viewValue: '3 Sterne' },
    { value: 4, viewValue: '4 Sterne' },
    { value: 5, viewValue: '5 Sterne' },
  ];
  // ageOptions: any[] = [
  //   {value: '7', viewValue: 'Ab 7 Jahre'},
  //   {value: '8', viewValue: 'Ab 8 Jahre'},
  //   {value: '9', viewValue: 'Ab 9 Jahre'},
  //   {value: '10', viewValue: 'Ab 10 Jahre'},
  //   {value: '11', viewValue: 'Ab 11 Jahre'},
  //   {value: '12', viewValue: 'Ab 12 Jahre'},
  //   {value: '13', viewValue: 'Ab 13 Jahre'},
  //   {value: '14', viewValue: 'Ab 14 Jahre'}
  // ];
  // gradeOptions: any[] = [
  //   {value: '1', viewValue: 'Klasse 1'},
  //   {value: '2', viewValue: 'Klasse 2'},
  //   {value: '3', viewValue: 'Klasse 3'},
  //   {value: '4', viewValue: 'Klasse 4'},
  //   {value: '5', viewValue: 'Klasse 5'},
  //   {value: '6', viewValue: 'Klasse 6'},
  //   {value: '7', viewValue: 'Klasse 7'},
  //   {value: '8', viewValue: 'Klasse 8'},
  //   {value: '9', viewValue: 'Klasse 9'},
  //   {value: '10', viewValue: 'Klasse 10'}
  // ];
  ageOptions: any[] = [];
  gradeOptions: any[] = [];
  categoryOptions: any[] = [];
  filterFormGroup: FormGroup;
  filterCategories;
  //   BOOKSTORE_ACTIVE = environment.bookstoreModule;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  booksObs: Observable<any>;
  taskType;
  readingTime;
  taskTitle;
  isTask: boolean = false;
  licenseActive: boolean = false;
  taskId;
  userTaskUuid;
  isLoading: boolean = true;
  studentGrade;
  selectedGrade = '';
  selectedAge = '';
  selectedCategories = '';
  singleGroup: boolean = false;
  groupGrade;
  studentId;
  @Output() bookListData = new EventEmitter<any>();
  @Output() bookListClose = new EventEmitter<boolean>();
  // swiper
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  preferenceText;
  openPreferences;
  translatedText: any;
  firstBookLoad: boolean = false;

  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public resDialog: MatDialogRef<BookListStudentComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private bookService: BookService,
    private licenseService: LicenseService,
    private studentService: StudentService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['type'] !== 'undefined') {
        this.taskType = this.injectedData['type'];
      }
      if (typeof injectedData['time'] !== 'undefined') {
        this.readingTime = this.injectedData['time'];
      }
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['userTaskUuid'] !== 'undefined') {
        this.userTaskUuid = this.injectedData['userTaskUuid'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
        if (this.groupGrade) {
          this.singleGroup = true;
          this.selectedGrade = this.groupGrade.toString();
        }
      }
    }
    // Define filter group
    this.filterFormGroup = new FormGroup({
      grade: new FormControl(),
      age: new FormControl(),
      categories: new FormControl(),
      difficulty: new FormControl(),
    });
  }

  ngOnInit() {
    this.setTranslation(this.route.snapshot.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });

    this.taskId = this.route.snapshot.params['id'];
    this.studentId = this.authService.getStudentId();

    // Check license before starting task
    this.licenseService.checkLicense().subscribe((data) => {
      console.log(data);
      this.licenseActive = true;
    });

    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        if (this.paginator != undefined) {
          this.paginator.pageIndex = 0;
        }
        // Check for search results
        this.getBookList();
      });

    if (this.taskType == 'time' || this.taskType == 'self') {
      this.isTask = true;
    }

    this.studentService.getStudentbyId(this.studentId).subscribe((res) => {
      // if (res["categories"].length == 0 || res["categories"] == undefined) {
      this.preferenceText = this.translatedText?.book_list_student_preferences;
      this.openPreferences = true;
      this.studentGrade = res['_group'][0].grade;
      // } else {
      //   this.preferenceText = "Meine Auswahl";
      //   this.openPreferences = false;
      // }
      // Only show books of same grade and 2 grades below
      this.filterValues.grade.push(this.studentGrade);
      // if (this.studentGrade - 1 > 0) {
      //   this.filterValues.grade.push(this.studentGrade - 1);
      // }
      // if (this.studentGrade - 2 > 0) {
      //   this.filterValues.grade.push(this.studentGrade - 2);
      // }
      // 2 grades above
      // this.filterValues.grade.push(this.studentGrade + 1);
      // this.filterValues.grade.push(this.studentGrade + 2);
      // Init datasource
    });

    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.contentType =
          this.licenseService.getContentTypeByLicense(licenses);
        this.getBookList(); // Get books after the license is determined
      });
  }

  setTranslation(translatedText: any) {
    this.translatedText = translatedText;
    this.difficultyOptions = [
      {
        value: 1,
        viewValue: '1 ' + this.translatedText?.book_list_student_star,
      },
      {
        value: 2,
        viewValue: '2 ' + this.translatedText?.book_list_student_stars,
      },
      {
        value: 3,
        viewValue: '3 ' + this.translatedText?.book_list_student_stars,
      },
      {
        value: 4,
        viewValue: '4 ' + this.translatedText?.book_list_student_stars,
      },
      {
        value: 5,
        viewValue: '5 ' + this.translatedText?.book_list_student_stars,
      },
    ];
  }

  getBookList() {
    this.isLoading = true;
    this.limit = 1000;
    console.log('GET_BOOKS');
    this.bookService
      .getBookList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
        this.taskId,
        this.contentType,
      )
      .pipe(
        map((bookData: BookData) => {
          this.dataSource = bookData;
          if (!this.firstBookLoad) {
            bookData.data.forEach((book) => {
              const ageValue = book['_book'].lower_age_barrier;
              const lowerGradeBarrier = book['_meta'].lower_grade_barrier;

              if (ageValue !== undefined && ageValue >= 7) {
                const ageViewValue = 'Ab ' + ageValue + ' Jahre';

                if (
                  !this.ageOptions.some((option) => option.value === ageValue)
                ) {
                  // this.ageOptions.push({
                  //   value: ageValue,
                  //   viewValue: ageViewValue,
                  // });
                }
              }

              if (lowerGradeBarrier !== undefined) {
                const lowerGradeBarrierValue = 'Klasse ' + lowerGradeBarrier;

                if (
                  !this.gradeOptions.some(
                    (option) => option.value === lowerGradeBarrier,
                  )
                ) {
                  this.gradeOptions.push({
                    value: lowerGradeBarrier,
                    viewValue: lowerGradeBarrierValue,
                  });
                }
              }
            });
            if (!this.ageOptions.some((option) => option.value === 15)) {
              this.ageOptions.push({ value: 15, viewValue: 'Ab 15 Jahre' });
            }
            // Sortieren Sie ageOptions nach den lower_age_barrier-Werten
            this.ageOptions.sort((a, b) => a.value - b.value);
            this.gradeOptions.sort((a, b) => a.value - b.value);
            this.firstBookLoad = true;
          }
          // Move Übungsbücher to top
          const firstEntry = 'Übungsbücher';
          if (bookData['categories'].includes(firstEntry)) {
            const tempCategories = bookData['categories'].filter(
              (item) => item !== firstEntry,
            );
            const finalCategories = bookData['categories'].includes(firstEntry)
              ? [firstEntry].concat(tempCategories)
              : tempCategories;
            this.categories = finalCategories;
          } else {
            this.categories = bookData['categories'];
          }
          this.filterCategories = this.categories;
          if (this.filterValues.categories.length == 0) {
            this.categoryOptions = bookData['categories'].map((item) => {
              return {
                value: item,
                viewValue: item,
              };
            });
          }
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value == selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  onSelectChange(event: any, filter) {
    if (this.filterValues[filter].length != event.value.length) {
      this.filterValues[filter] = event.value;
      console.log(this.filterValues);
      // Load books
      this.getBookList();
    }
  }

  /**
   * API call on adminService to retrieve requested teachers by pagination
   */
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getBookList();
  }

  /**
   * Send new input value to subscription
   */
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  categoryCheck(category) {
    let count = this.dataSource.data.filter((book) =>
      book?._meta?.categories.includes(category),
    ).length;
    return count > 0 ? true : false;
  }

  categoryFilter(category) {
    return this.dataSource.data.filter((book) =>
      book?._meta?.categories.includes(category),
    );
  }

  bookCheck(books) {
    return books?._value;
  }

  openBookPreview(isbn, title, description, author): void {
    let dialogRef = this.dialog.open(BookPreviewComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel_backdrop',
      data: {
        isbn: isbn,
        title: title,
        description: description,
        author: author,
      },
    });

    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res.path == 'book-preview') {
        let data = {
          res: res,
          path: 'book-list',
        };
        this.resDialog.close(data);
      }
      // this.resDialog.close(res);
    });
  }

  openBookPreviewTask(isbn, title, description, author): void {
    let taskData = {
      isbn: isbn,
      title: title,
      description: description,
      author: author,
      type: this.taskType,
      taskId: this.taskId,
      userTaskUuid: this.userTaskUuid,
    };
    if (this.taskType == 'time') {
      taskData['time'] = this.readingTime;
    }
    let dialogRef = this.dialog.open(BookPreviewComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel-backdrop',
      data: taskData,
    });

    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res?.path == 'book-preview') {
        let data = {
          res: res,
          path: 'book-list',
        };
        this.resDialog.close(data);
      }
      if (res == 'taskStarted') {
        this.resDialog.close();
      }
      // this.resDialog.close(res);
    });
  }

  getBookComplexity(book_complexity, invertRating) {
    if (book_complexity != undefined) {
      const foundGrade = book_complexity.filter(
        (item) => item?.grade == this.studentGrade,
      );
      let stars = foundGrade.length > 0 ? foundGrade[0].complexity : 5;
      if (invertRating) {
        stars = 5 - stars;
      }
      return new Array(stars);
    } else {
      return [];
    }
  }

  closeAll() {
    this.dialog.closeAll();
  }

  setBook(isbn, title, description, author) {
    let bookData = {
      isbn: isbn,
      title: title,
      description: description,
      author: author,
      listview: false,
      preview: true,
    };
    this.bookListData.emit(bookData);
  }

  closeBookList() {
    this.bookListClose.emit(true);
  }

  ngAfterViewInit(): void {
    register();
  }

  onActiveIndexChange() {
    console.log(this.swiper?.activeIndex);
  }

  openBookPreviewStudent(isbn) {
    this.router.navigate(['/book-preview-student/' + this.taskId], {
      queryParams: { isbn: isbn },
    });
  }

  updateTask(title, isbn, dsef_version) {
    this.taskService
      .updateSingleTaskBook({
        _id: this.taskId,
        book_name: title,
        book_isbn: isbn,
        dsef_version: dsef_version,
      })
      .subscribe((res) => {
        this.router.navigate(['/task-preview/' + this.taskId]);
      });
  }

  openBookPreferences(): void {
    let dialogRef = this.dialog.open(BookPreferencesComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-preferences-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-preferences-overlay-panel_backdrop',
      data: {
        step: 'selection',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(event: any, book) {
    // just try once
    if (!book.imageLoadFailed) {
      book.imageLoadFailed = true;
      event.target.src = book.imageUrl;
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
