<div class="forgotpassword_overlay_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" (click)="closeOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="forgotpassword_overlay_wrap">
    <img src="../../../assets/forgot-password.svg" alt="" />
    <h1 class="h1 h1--bold">{{ translatedText.forgot_passsword_title }}</h1>
    <p class="paragraph paragraph--l">
      {{ translatedText.forgot_passsword_desc }}
    </p>
    <button mat-flat-button color="primary-student" (click)="closeOverlay()" type="button">
      {{ translatedText.forgot_passsword_close }}
    </button>
  </div>
</div>
