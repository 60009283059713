import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  MAT_OPTION_PARENT_COMPONENT,
  MatOptgroup,
  MatOption,
  MatOptionParentComponent,
  MatPseudoCheckboxState,
} from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-all-option',
  templateUrl: './select-all-option.component.html',
  styleUrls: ['./select-all-option.component.scss'],
})
export class SelectAllOptionComponent
  extends MatOption
  implements OnInit, OnDestroy
{
  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() values: any[] = [];
  @Input() valuetype: string = 'default'; // default or object for nested version with value and viewValue
  @Output('selectionChange') selectionChange = new EventEmitter<any>();
  @Input('translatedText') translatedText: any = [];

  @HostBinding('class') cssClass = 'mat-option';
  @HostListener('click') toggleSelection(): void {
    this._selectViaInteraction();
    if (this.valuetype == 'object') {
      this.control.setValue(
        this.selected && this.checkboxState == 'checked'
          ? []
          : this.values.map((item) => item.value),
      );
    } else {
      this.control.setValue(
        this.selected && this.checkboxState == 'checked'
          ? []
          : this.values.map((item) => item),
      );
    }
  }

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    elementRef: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_OPTION_PARENT_COMPONENT)
    parent: MatOptionParentComponent,
    @Optional() group: MatOptgroup,
  ) {
    super(elementRef, changeDetectorRef, parent, group);
  }

  ngOnInit(): void {
    this.title = this.translatedText?.select_all_option;
    this.refresh();
    this.control.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((selection) => {
        // Emit empty array on all to reset filter
        if (this.selectedAll) {
          this.selectionChange.emit({ value: [] });
        } else {
          this.selectionChange.emit({ value: selection });
        }
        this.refresh();
      });
  }

  get selectedItemsCount(): number {
    return this.control && Array.isArray(this.control.value)
      ? this.control.value.filter((el) => el !== null).length
      : 0;
  }

  get selectedAll(): boolean {
    return this.values == undefined
      ? false
      : this.selectedItemsCount === this.values.length;
  }

  get selectedPartially(): boolean {
    const selectedItemsCount = this.selectedItemsCount;
    return selectedItemsCount > 0 && selectedItemsCount < this.values.length;
  }

  get checkboxState(): MatPseudoCheckboxState {
    let state: MatPseudoCheckboxState = 'unchecked';

    if (this.selectedAll) {
      state = 'checked';
    } else if (this.selectedPartially) {
      state = 'indeterminate';
    }
    return state;
  }

  refresh(): void {
    if (this.selectedItemsCount > 0) {
      this.select();
    } else {
      this.deselect();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
