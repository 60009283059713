import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminNewSchoolYearComponent } from 'src/app/admin/admin-new-school-year/admin-new-school-year/admin-new-school-year.component';

@Component({
  selector: 'app-classes-update-banner',
  templateUrl: './classes-update-banner.component.html',
  styleUrls: ['./classes-update-banner.component.scss'],
})
export class ClassesUpdateBannerComponent {
  @Input() classesUpdateHeading: string;
  @Input() classesUpdateDescription: string;
  @Input() updateButton: string;
  constructor(public dialog: MatDialog) {}

  newSchoolYearOverlay() {
    let dialogRef = this.dialog.open(AdminNewSchoolYearComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
