<ng-container *ngIf="!isLoading">
  <!-- <mat-form-field class="group_result_chart" (click)="$event.stopPropagation()">
        <mat-select (valueChange)="selectResult($event)" value="last_month">
          <mat-option value="last_week">Letzte 7 Tage</mat-option>
          <mat-option value="last_month">Letzter Monat</mat-option>
          <mat-option value="half_year">Halbjahr</mat-option>
        </mat-select>
    </mat-form-field> -->
  <p class="paragraph chart-info-text">
    {{ translatedText.diagnostic_chart_title }}
    <mat-icon
      svgIcon="streamline_outlined:info-empty"
      matTooltip="{{ translatedText.diagnostic_chart_chartInfoText }}"
    ></mat-icon>
  </p>
  <ng-container *ngIf="chart_data?.mid_test.monthly?.average?.reading_speed_correct_words_per_minute == 0">
    <div class="group-value_tooltip">
      <h1 class="h1">{{ chart_data?.pre_test.monthly?.average?.reading_speed_correct_words_per_minute }}</h1>
    </div>
  </ng-container>
  <div
    class="line_chart_result_wrap line_chart_result_wrap--diagnostic"
    *ngIf="chart_data?.mid_test.monthly?.average?.reading_speed_correct_words_per_minute > 0"
  >
    <ngx-charts-line-chart
      [scheme]="colorSchemeResult"
      [legend]="legend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showGridLines]="showGridLines"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [results]="progressChartData"
    >
    </ngx-charts-line-chart>
  </div>
</ng-container>
