<svg:g
  ngx-charts-bar
  *ngFor="let bar of bars; trackBy: trackBy"
  [@animationState]="'active'"
  [width]="bar.width"
  [height]="bar.height"
  [x]="bar.x"
  [y]="bar.y"
  [fill]="bar.color"
  [stops]="bar.gradientStops"
  [data]="bar.data"
  [orientation]="'vertical'"
  [roundEdges]="bar.roundEdges"
  [gradient]="gradient"
  [isActive]="isActive(bar.data)"
  [animations]="animations"
  [noBarWhenZero]="noBarWhenZero"
  (select)="onClick($event)"
  (activate)="activate.emit($event)"
  (deactivate)="deactivate.emit($event)"
  ngx-tooltip
  [tooltipDisabled]="tooltipDisabled"
  [tooltipPlacement]="'top'"
  [tooltipType]="'tooltip'"
  [tooltipTitle]="bar.tooltipText"
></svg:g>
