import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { HelpdeskOverlayComponent } from './helpdesk-overlay.component';

@NgModule({
  declarations: [HelpdeskOverlayComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [HelpdeskOverlayComponent],
})
export class HelpdeskOverlayModule {}
