import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-student-progress-level',
  templateUrl: './student-progress-level.component.html',
  styleUrls: ['./student-progress-level.component.scss'],
})
export class StudentProgressLevelComponent implements OnInit, OnChanges {
  @Input('value') value: any;
  @Input('statusbarValue') statusbarValue: any;
  @Input('statusbarText') statusbarText: any;
  @Input('isNumbers') isNumbers: boolean;
  @Input('reading_combined_score') reading_combined_score: any;
  @Input('isRedingPoints') isRedingPoints: boolean;
  statusValue = 0;
  statusValueLegend = 0;
  color = '#ff0000';
  reading_point_color = '#ff0000';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.previousValue != changes.value?.currentValue) {
      setTimeout(() => {
        if (this.value == 1) {
          this.statusValue = 8;
          this.statusValueLegend = 0;
          this.color = '#DB1919';
        } else if (this.value == 2) {
          this.statusValue = 28;
          this.statusValueLegend = 20;
          this.color = '#efbc50';
        } else if (this.value == 3) {
          this.statusValue = 48;
          this.statusValueLegend = 40;
          this.color = '#03cc90';
        } else if (this.value == 4) {
          this.statusValue = 68;
          this.statusValueLegend = 60;
          this.color = '#03cc90';
        } else if (this.value == 5) {
          this.statusValue = 88;
          this.statusValueLegend = 80;
          this.color = '#03cc90';
        }
        // this.statusValue = this.value -2;
        console.log(this.value);
        // if(this.value > 15 && this.value < 29) {
        //     this.color = "#fecf2f"
        // }  else if (this.value >= 30 && this.value < 69) {
        //     this.color = "#03cc90"
        // } else if (this.value > 69 && this.value < 84) {
        //     this.color = "#fecf2f"
        // } else if (this.value > 84) {
        //     this.color = "#ff0000"
        // }
      }, 500);
    }

    if (
      changes.reading_combined_score?.previousValue !=
      changes.reading_combined_score?.currentValue
    ) {
      setTimeout(() => {
        console.log(this.isRedingPoints);
        if (this.isRedingPoints) {
          console.log(this.reading_combined_score);
          if (this.reading_combined_score <= 20) {
            this.reading_point_color = '#DB1919';
          } else if (this.reading_combined_score <= 40) {
            this.reading_point_color = '#EFBC50';
          } else {
            this.reading_point_color = '#03CC90';
          }
        }
      }, 500);
    }
  }
}
