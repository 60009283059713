<div class="record-rtc_result_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" (click)="closeOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <ng-container *ngIf="text_coverage <= 5">
    <div class="record-rtc_result_overlay_wrap norecords">
      <h3 class="h3 h3--bold">{{ translatedText.record_result_text_coverage_title }}</h3>
      <button mat-flat-button color="primary-student" (click)="closeOverlay()" type="button">
        {{ translatedText.record_result_ok }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="text_coverage > 5">
    <div class="record-rtc_result_overlay_wrap">
      <h3 class="h3 h3--bold record-rtc_result_headline">{{ translatedText.record_result_task_success }}</h3>
      <div class="row">
        <div class="col-md-6 record-rtc_result">
          <div class="record-rtc_result_card">
            <p class="paragraph paragraph--bold paragraph--l result_card_header">
              {{ translatedText.record_result_wcpm }}
            </p>
            <!-- <p class="paragraph paragraph--s">Deine Lesekompetenz ist gestiegen!</p> -->
            <div class="set_inline rating_wrap">
              <div class="bookmark_warp">
                <button mat-icon-button *ngFor="let levelId of levelArr; index as i" [id]="'star_' + i">
                  <mat-icon class="bookmark {{ showIcon(i) }}" svgIcon="streamline_filled:book-bookmark"></mat-icon>
                </button>
              </div>
              <div class="inline_text">
                <p class="paragraph paragraph--bold paragraph--l">
                  {{ reading_speed_correct_words_per_minute }}<span class="paragraph paragraph--l"></span>
                </p>
              </div>
            </div>
          </div>
          <div class="record-rtc_result_card">
            <div class="smiley_results_wrap" *ngIf="level">
              <ng-container *ngIf="level == '1'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="level == '2'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="level == '3'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="level == '4'">
                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
              </ng-container>
              <ng-container *ngIf="level == '5'">
                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
              </ng-container>
              <p class="paragraph">{{ getLevelNotes(level) }}</p>
            </div>
            <!-- <p class="paragraph paragraph--bold" *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)">Betonung</p>
                        <div class="inline_text record-rtc_result_info" *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)">
                            <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.performance == '2'">
                                <mat-icon svgIcon="streamline_filled:smiley_2"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.performance == '3'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.performance == '4'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.performance == '5'">
                                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingDynamicNote(task?._group?.grade, reading_dynamic_normalized)?.description}}</p>
                        </div>
                        <p class="paragraph paragraph--bold" *ngIf="getReadingErrorNote(task?._group?.grade, reading_errors_score)?.performance == '1'">Lesefehler</p>
                        <div class="inline_text record-rtc_result_info">
                            <ng-container *ngIf="getReadingErrorNote(task?._group?.grade, reading_errors_score)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingErrorNote(task?._group?.grade, reading_errors_score)?.description}}</p>
                        </div>
                        <p class="paragraph paragraph--bold" *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)">Geschwindigkeit</p>
                        <div class="inline_text record-rtc_result_info" *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)">
                            <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.performance == '2'">
                                <mat-icon svgIcon="streamline_filled:smiley_2"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.performance == '3'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.performance == '4'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.performance == '5'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingSpeedNote(task?._group?.grade, reading_speed_words)?.description}}</p>
                        </div>
                        <p class="paragraph paragraph--bold" *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)">Ersetzungen</p>
                        <div class="inline_text record-rtc_result_info" *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)">
                            <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)?.performance == '2'">
                                <mat-icon svgIcon="streamline_filled:smiley_2"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)?.performance == '3'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)?.performance == '4'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, num_substitutions)?.performance == '5'">
                                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getSubstitutionsNote(task?._group?.grade, num_substitutions)?.description}}</p>
                        </div>
                        <p class="paragraph paragraph--bold" *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)">Auslassungen</p>
                        <div class="inline_text record-rtc_result_info" *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)">
                            <ng-container *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)?.performance == '2'">
                                <mat-icon svgIcon="streamline_filled:smiley_2"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)?.performance == '3'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)?.performance == '4'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getDeletionsNote(task?._group?.grade, num_deletions)?.performance == '5'">
                                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getDeletionsNote(task?._group?.grade, num_deletions)?.description}}</p>
                        </div>
                        <p class="paragraph paragraph--bold" *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)">Hinzufügungen</p>
                        <div class="inline_text record-rtc_result_info" *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)">
                            <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)?.performance == '1'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)?.performance == '2'">
                                <mat-icon svgIcon="streamline_filled:smiley_2"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)?.performance == '3'">
                                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)?.performance == '4'">
                                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, num_insertions)?.performance == '5'">
                                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getInsertionsNotes(task?._group?.grade, num_insertions)?.description}}</p>
                        </div> -->
          </div>
        </div>
        <div class="col-md-6 record-rtc_result">
          <div class="record-rtc_result_card right">
            <div class="result_medal_wrap" *ngIf="earnedNewMedal">
              <img *ngIf="earnedMedal?.name" src="../../../assets/{{ earnedMedal?.name }}_medal.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--l">{{ earnedMedal?.description }}</p>
            </div>
            <div class="result_medal_wrap" *ngIf="!earnedNewMedal">
              <p class="paragraph paragraph--bold paragraph--l">{{ earnedMedal?.description }}</p>
            </div>

            <div class="result_all_medal_wrap set_inline">
              <p class="paragraph paragraph--l">{{ translatedText.record_result_medals }}</p>
              <div class="medals-wrap">
                <div class="bronze-medal">
                  <div class="bronze-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s">{{ medals?.bronze }}</p>
                  </div>
                </div>
                <div class="silver-medal">
                  <div class="silver-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s">{{ medals?.silver }}</p>
                  </div>
                </div>
                <div class="gold-medal">
                  <div class="gold-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s">{{ medals?.gold }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button mat-flat-button color="primary-student" (click)="closeOverlay()" type="button">
        {{ translatedText.record_result_ok }}
      </button>
    </div>
  </ng-container>
</div>
