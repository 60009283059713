<div class="container-fluid container-fluid--bg-gray school-detail-container container-fluid--full-height">
  <div class="row justify-content-center">
    <div class="col-md-12 col-sm-12 school-create-header-row">
      <div class="inline_text">
        <div class="report_backButton">
          <button mat-fab type="button" (click)="navigateBack()" class="backButton class_detail_button" matSuffix>
            <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
          </button>
        </div>
        <h3 class="h3 h3--bold backText">
          {{ translatedText.school_detail_title }} / <span class="school-name-text">{{ school?.name }}</span>
        </h3>
      </div>
      <button
        *ngIf="userType == 'admin'"
        (click)="openSchoolDeleteDialog()"
        mat-flat-button
        color="warning"
        type="button"
      >
        {{ translatedText.school_detail_delete }}
      </button>
    </div>
  </div>

  <div class="row school-info-row">
    <div class="col-md-8">
      <div class="admin-license-widget">
        <div class="admin-license-widget_header">
          <p class="paragraph paragraph--bold paragraph--m">{{ translatedText.school_detail_license }}</p>
          <!-- <button mat-flat-button color="primary"  class="mat-flat-button--full" (click)="openAddLicense()" aria-label="add License"><mat-icon svgIcon="streamline_outlined:license-plus"></mat-icon>Lizenzcode eingeben</button> -->
        </div>
        <mat-tab-group class="license_tabs">
          <mat-tab label="{{ translatedText.school_detail_diagnostic }} ({{ school?.totalAmountLeftDi }})">
            <div class="license_amount">
              <div class="admin-license-widget-total">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountDi">{{ school?.totalAmountDi }}</h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_license_total }}</p>
              </div>

              <div class="admin-license-widget-unused">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountLeftDi">
                  {{ school?.totalAmountLeftDi }}
                </h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_unused_licenses }}</p>
              </div>

              <div class="admin-license-widget-used">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountDiUsed">
                  {{ school?.totalAmountDiUsed }}
                </h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_used_licenses }}</p>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ translatedText.school_detail_minutes }} ({{ school?.totalAmountLeftFo }})">
            <div class="license_amount">
              <div class="admin-license-widget-total">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountFo">{{ school?.totalAmountFo }}</h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_minutes_total }}</p>
              </div>

              <div class="admin-license-widget-unused">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountLeftFo">
                  {{ school?.totalAmountLeftFo }}
                </h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_unused_minutes }}</p>
              </div>

              <div class="admin-license-widget-used">
                <h2 class="h2 h2--bold" [countUp]="school?.totalAmountFoUsed">
                  {{ school?.totalAmountFoUsed }}
                </h2>
                <p class="paragraph paragraph--s">{{ translatedText.school_detail_used_minutes }}</p>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <a class="link" (click)="openSchoolLicenseDetail()">{{ translatedText.school_detail_license_details }}</a>
      </div>
    </div>
    <div class="col-md-4">
      <div class="school-info-wrap">
        <button
          *ngIf="userType == 'admin'"
          class="school_edit-button"
          (click)="openSchoolEdit()"
          mat-icon-button
          color="filled"
          mat-button
          aria-label="edit"
        >
          <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
        </button>
        <p class="paragraph paragraph--bold">{{ translatedText.school_detail_school_info }}</p>
        <div class="school-info">
          <p class="paragraph paragraph--s">{{ translatedText.school_detail_school_type }}</p>
          <p class="paragraph paragraph--bold">{{ school?.school_type }}</p>
        </div>
        <div class="school-info">
          <p class="paragraph paragraph--s">{{ translatedText.school_detail_school_address }}</p>
          <p class="paragraph paragraph--bold">
            {{ school?.address }}, {{ school?.zip }}, {{ getFullStateName(school?.state) }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-tab-group
        [selectedIndex]="selectedIndex"
        (selectedTabChange)="tabChanged($event)"
        class="school_teachers-tabs"
      >
        <mat-tab label="{{ translatedText.school_detail_role_admins }} ({{ school?.schoolAdminsCount }})">
          <div class="school_filter school_filter-detail">
            <div class="col-md-3">
              <mat-form-field class="book_list_select task_type_select">
                <div class="task-label-wrap">
                  <mat-label>{{ translatedText.school_detail_school_status }}</mat-label>
                </div>
                <mat-select
                  [formControl]="filterFormGroup.get('status')"
                  floatLabel="never"
                  panelClass="dashboard-task-select"
                  disableOptionCentering
                  multiple
                >
                  <mat-select-trigger>
                    {{ generateSelectionText(statusArray, filterFormGroup.get('status').value) }}
                  </mat-select-trigger>
                  <app-select-all-option
                    [translatedText]="translatedText"
                    [control]="filterFormGroup.get('status')"
                    [values]="statusArray"
                    [valuetype]="'object'"
                    (selectionChange)="selectStatus($event)"
                    [translatedText]="translatedText"
                  ></app-select-all-option>
                  <mat-option *ngFor="let status of statusArray" [value]="status?.value">
                    {{ status.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3"></div>
            <div class="col-md-3">
              <mat-form-field class="full-width search-school" floatLabel="never">
                <mat-label>{{ translatedText.school_detail_search }}</mat-label>
                <input
                  matInput
                  type="text"
                  (input)="onSearchInputFilterChange($event)"
                  placeholder="{{ translatedText.school_detail_search }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <mat-table
              [dataSource]="dataSource?.data"
              matSort
              matSortDisableClear
              [matSortActive]="sortBy"
              [matSortDirection]="matSortDirection"
              (matSortChange)="onSortChange($event)"
            >
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  translatedText.school_detail_full_name
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="teacher_with_icon">
                    <button mat-mini-fab>
                      <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                    </button>
                    <p class="paragraph paragraph--s">
                      {{ element?.name }}
                    </p>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_email }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element?._user.email }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.schschool_detail_roleol_detail_role_admins
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="table_group">
                    <p class="paragraph paragraph--s status-chip">
                      {{ translatedText.school_detail_role_admin }}
                    </p>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_status }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.status == 'active'">
                    <p class="paragraph paragraph--s status-chip status-chip--active">
                      {{ translatedText.school_detail_active }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element.status == 'inactive'">
                    <p class="paragraph paragraph--s status-chip status-chip--inactive">
                      {{ translatedText.school_detail_inactive }}
                    </p>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_action }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="license-action-wrap">
                    <button
                      mat-icon-button
                      type="button"
                      class="license-accept"
                      (click)="impersonateUser(element?._user?._id)"
                      matSuffix
                    >
                      <mat-icon svgIcon="streamline_outlined:antenna-signal"></mat-icon>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
              <ng-container matColumnDef="noRecord">
                <mat-footer-cell *matFooterCellDef colspan="2">
                  <span [hidden]="dataSource?.data.length > 0">
                    {{ translatedText.school_detail_no_results_admin }}
                  </span>
                </mat-footer-cell>
              </ng-container>
            </mat-table>

            <mat-paginator
              [hidePageSize]="true"
              [length]="dataSource?.meta.totalItems"
              [pageSize]="
                dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
                  ? dataSource?.meta.itemsPerPage
                  : dataSource?.meta.totalItems
              "
              [pageSizeOptions]="[10, 25, 100]"
              (page)="pageEvent = $event; onPaginateChange($event)"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </mat-tab>
        <mat-tab label="{{ translatedText.school_detail_teachers }}  ({{ school?.teacherCount }})">
          <div class="school_filter school_filter-detail">
            <div class="col-md-3">
              <mat-form-field class="book_list_select task_type_select">
                <div class="task-label-wrap">
                  <mat-label>{{ translatedText.school_detail_school_status }}</mat-label>
                </div>
                <mat-select
                  [formControl]="filterFormGroup.get('status')"
                  floatLabel="never"
                  panelClass="dashboard-task-select"
                  disableOptionCentering
                  multiple
                >
                  <mat-select-trigger>
                    {{ generateSelectionText(statusArray, filterFormGroup.get('status').value) }}
                  </mat-select-trigger>
                  <app-select-all-option
                    [control]="filterFormGroup.get('status')"
                    [values]="statusArray"
                    [valuetype]="'object'"
                    (selectionChange)="selectStatus($event)"
                    [translatedText]="translatedText"
                  ></app-select-all-option>
                  <mat-option *ngFor="let status of statusArray" [value]="status?.value">
                    {{ status.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3"></div>
            <div class="col-md-3">
              <mat-form-field class="full-width search-school" floatLabel="never">
                <mat-label>{{ translatedText.school_detail_search }}</mat-label>
                <input
                  matInput
                  type="text"
                  (input)="onSearchInputFilterChange($event)"
                  placeholder="{{ translatedText.school_detail_search }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <mat-table
              [dataSource]="dataSource?.data"
              matSort
              matSortDisableClear
              [matSortActive]="sortBy"
              [matSortDirection]="matSortDirection"
              (matSortChange)="onSortChange($event)"
            >
              <mat-header-row *matHeaderRowDef="displayedColumnsTeacher"></mat-header-row>
              <mat-row *matRowDef="let element; columns: displayedColumnsTeacher"></mat-row>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                  translatedText.school_detail_full_name
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="teacher_with_icon">
                    <button mat-mini-fab>
                      <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                    </button>
                    <p class="paragraph paragraph--s">
                      {{ element?.name }}
                      <span *ngIf="element?._user.role == 'school_admin'" class="admin-chip">ADMIN</span>
                    </p>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_email }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element?._user.email }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_group }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="table_group">
                    <a *ngFor="let group of element?._group"
                      >{{ group?.name }} - {{ group?.school_year | slice: 2 }}<span>,</span></a
                    >
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_status_2 }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.status == 'active'">
                    <p class="paragraph paragraph--s status-chip status-chip--active">
                      {{ translatedText.school_detail_active }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element.status == 'inactive'">
                    <p class="paragraph paragraph--s status-chip status-chip--inactive">
                      {{ translatedText.school_detail_inactive }}
                    </p>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_detail_action }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="license-action-wrap">
                    <button
                      mat-icon-button
                      type="button"
                      class="license-accept"
                      (click)="impersonateUser(element?._user?._id)"
                      matSuffix
                    >
                      <mat-icon svgIcon="streamline_outlined:antenna-signal"></mat-icon>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
              <ng-container matColumnDef="noRecord">
                <mat-footer-cell *matFooterCellDef colspan="2">
                  <span [hidden]="dataSource?.data.length > 0">
                    {{ translatedText.school_detail_no_results_teacher }}
                  </span>
                </mat-footer-cell>
              </ng-container>
            </mat-table>

            <mat-paginator
              [hidePageSize]="true"
              [length]="dataSource?.meta.totalItems"
              [pageSize]="
                dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
                  ? dataSource?.meta.itemsPerPage
                  : dataSource?.meta.totalItems
              "
              [pageSizeOptions]="[10, 25, 100]"
              (page)="pageEvent = $event; onPaginateChange($event)"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
