<button
  mat-icon-button
  type="button"
  class="circle light task_close"
  [disabled]="isLoading"
  (click)="closeClassOverlay()"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<app-class-stepper [linear]="true" [stepValue]="selectedIndex" (selectionChange)="getActiveSelection($event)">
  <cdk-step [stepControl]="firstFormStep" #step1="cdkStep">
    <form [formGroup]="firstFormStep">
      <div class="create_class_form_header">
        <h2 class="h2">Name der Klasse</h2>
        <p class="paragraph paragraph--l">
          Bitte wählen Sie die Klassenstufe, den Klassenzug und das Schuljahr der anzulegenden Klasse aus.
        </p>
      </div>
      <div class="row first_step">
        <!-- <div class="col-8"> -->
        <!-- <mat-form-field>
            <input matInput formControlName="className"  maxlength="10" type="text" placeholder="Klassenname" required>
            <mat-error *ngIf="firstFormStep.get('className').hasError('required')">
              Bitte legen Sie einen Namen fest            
            </mat-error> 
            <mat-error *ngIf="firstFormStep.controls['className'].hasError('maxLength')">
              Die Anzahl der Lernenden darf nicht höher als 40 sein     
            </mat-error> 
          </mat-form-field> -->
        <!-- </div> -->
        <div class="col-12">
          <mat-form-field>
            <mat-label>Klassenstufe</mat-label>
            <mat-select formControlName="grade" type="role" disableOptionCentering panelClass="grade-select">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormStep.get('grade').hasError('required')">
              <!-- Please choose a class. -->
              Bitte Klassenstufe auswählen
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Klassenzug</mat-label>
            <mat-select formControlName="stream" type="role" disableOptionCentering panelClass="grade-select">
              <mat-option value="a">a</mat-option>
              <mat-option value="b">b</mat-option>
              <mat-option value="c">c</mat-option>
              <mat-option value="d">d</mat-option>
              <mat-option value="e">e</mat-option>
              <mat-option value="f">f</mat-option>
              <mat-option value="g">g</mat-option>
              <mat-option value="g">h</mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormStep.get('stream').hasError('required')">
              <!-- Please choose a class. -->
              Bitte Klassenzug auswählen
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Schuljahr</mat-label>
            <mat-select formControlName="school_year" type="role" disableOptionCentering panelClass="grade-select">
              <mat-option value="2023/24">2023/24</mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormStep.get('school_year').hasError('required')">
              <!-- Please choose a class. -->
              Bitte Schuljahr auswählen
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="print_note">
        <p class="paragraph paragraph--bold">
          <span class="home_warning">Wichtig: </span> Die Generierung der Klassenübersicht als PDF-Dokument im letzten
          Schritt kann aus Datenschutzgründen nur einmal erfolgen.
        </p>
      </div>
      <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="perpareNextStep()" cdkStepperNext>
        Nächster Schritt
      </button>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="secondFormStep" #step2="cdkStep">
    <div class="create_class_form_header">
      <h2 class="h2">Zusammensetzung der Klasse</h2>
      <p class="paragraph paragraph--l">Setzen Sie Ihre Klasse durch Angabe der Anzahl von Lernenden zusammen.</p>
    </div>
    <form [formGroup]="secondFormStep">
      <div class="row second_step">
        <div class="col-md-8">
          <div class="inline_text male_item">
            <mat-icon svgIcon="streamline_outlined:male"></mat-icon>
            <p class="paragraph paragraph--bold">Männlich</p>
          </div>
        </div>
        <div class="col-md-4">
          <mat-form-field class="mat-form-field--number-action">
            <button
              mat-button
              mat-icon-button
              matPrefix
              class="input_icon_button"
              aria-label="Remove"
              (click)="incrementValue(-_step, 'male')"
            >
              <mat-icon class="degrease-icon" svgIcon="streamline_outlined:minus"></mat-icon>
            </button>
            <input matInput [min]="0" type="number" formControlName="genderMale" [(ngModel)]="_maleValue" />
            <button
              mat-button
              mat-icon-button
              matSuffix
              aria-label="Add"
              class="input_icon_button"
              (click)="incrementValue(_step, 'male')"
            >
              <mat-icon svgIcon="streamline_outlined:plus"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-8">
          <div class="inline_text female_item">
            <mat-icon svgIcon="streamline_outlined:female"></mat-icon>
            <p class="paragraph paragraph--bold">Weiblich</p>
          </div>
        </div>
        <div class="col-md-4">
          <mat-form-field class="mat-form-field--number-action">
            <button
              mat-button
              mat-icon-button
              matPrefix
              class="input_icon_button"
              aria-label="Remove"
              (click)="incrementValue(-_step, 'female')"
            >
              <mat-icon class="degrease-icon" svgIcon="streamline_outlined:minus"></mat-icon>
            </button>
            <input matInput [min]="0" type="number" formControlName="genderFemale" [(ngModel)]="_femaleValue" />
            <button
              mat-button
              mat-icon-button
              matSuffix
              class="input_icon_button"
              aria-label="Add"
              (click)="incrementValue(_step, 'female')"
            >
              <mat-icon svgIcon="streamline_outlined:plus"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-8">
          <div class="inline_text divers_item">
            <mat-icon svgIcon="streamline_outlined:divers"></mat-icon>
            <p class="paragraph paragraph--bold">Divers</p>
          </div>
        </div>
        <div class="col-md-4">
          <mat-form-field class="mat-form-field--number-action">
            <button
              mat-button
              mat-icon-button
              matPrefix
              class="input_icon_button"
              aria-label="Remove"
              (click)="incrementValue(-_step, 'divers')"
            >
              <mat-icon class="degrease-icon" svgIcon="streamline_outlined:minus"></mat-icon>
            </button>
            <input matInput type="number" [min]="0" formControlName="genderDivers" [(ngModel)]="_diversValue" />
            <button
              mat-button
              mat-icon-button
              matSuffix
              class="input_icon_button"
              aria-label="Add"
              (click)="incrementValue(_step, 'divers')"
            >
              <mat-icon svgIcon="streamline_outlined:plus"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-error
            *ngIf="secondFormStep.hasError('studentCountValidator') && secondFormStep.get('genderMale').touched"
          >
            <!-- Your class should have at least one student. -->
            Die Anzahl der Lernenden darf nicht 0 sein
          </mat-error>
          <mat-error
            *ngIf="secondFormStep.hasError('studentMaxCountValidator') && secondFormStep.get('genderMale').touched"
          >
            <!-- Your class should have at least one student. -->
            Die Anzahl der Lernenden darf nicht höher als 40 sein
          </mat-error>
        </div>
      </div>
      <div class="print_note">
        <p class="paragraph paragraph--bold">
          <span class="home_warning">Wichtig: </span> Die Generierung der Klassenübersicht als PDF-Dokument im letzten
          Schritt kann aus Datenschutzgründen nur einmal erfolgen.
        </p>
      </div>
      <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="perpareNextStep()" cdkStepperNext>
        Nächster Schritt
      </button>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="thirdFormStep" #step3="cdkStep">
    <div class="thirdFormStep_header">
      <div class="create_class_form_header">
        <h2 class="h2">Klassenliste anlegen</h2>
        <p class="paragraph paragraph--l">Erstellen Sie Benutzerkonten für Ihre Klasse.</p>
      </div>
      <!-- <h2 class="h2">{{firstFormStep.get('className').value}}</h2> -->
      <div class="print_note">
        <p class="paragraph paragraph--bold">
          <span class="home_warning">Wichtig: </span> Die Generierung der Klassenübersicht als PDF-Dokument kann aus
          Datenschutzgründen nur einmal erfolgen.
        </p>
      </div>
      <div class="gender_header">
        <div class="inline_text">
          <mat-icon class="male-icon" svgIcon="streamline_outlined:male"></mat-icon>
          <p class="paragraph paragraph--l">Männlich - {{ secondFormStep.get('genderMale').value }}</p>
        </div>
        <div class="inline_text">
          <mat-icon class="female-icon" svgIcon="streamline_outlined:female"></mat-icon>
          <p class="paragraph paragraph--l">Weiblich - {{ secondFormStep.get('genderFemale').value }}</p>
        </div>
        <div class="inline_text">
          <mat-icon class="divers-icon" svgIcon="streamline_outlined:divers"></mat-icon>
          <p class="paragraph paragraph--l">Divers - {{ secondFormStep.get('genderDivers').value }}</p>
        </div>
      </div>
    </div>

    <form [formGroup]="thirdFormStep" #formDirective="ngForm">
      <div formArrayName="class" class="third_step">
        <div *ngFor="let class of getClassFormControls(); let i = index" [formGroupName]="i" class="children_row">
          <div class="row">
            <div class="col-12">
              <div class="inline_text">
                <p class="paragraph paragraph--bold paragraph--l">
                  <span class="gender-index">{{ i + 1 }}.</span>
                </p>
                <p class="paragraph paragraph--bold paragraph--l" [innerHtml]="getGenderHeadline(i)"></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <mat-form-field>
                <input
                  matInput
                  formControlName="name"
                  type="text"
                  placeholder="Vor- und Nachname (Wird nicht gespeichert)"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Geschlecht</mat-label>
                <mat-select formControlName="gender" type="role" [value]="class.gender" disabled="true">
                  <mat-option value="male">Männlich</mat-option>
                  <mat-option value="female">Weiblich</mat-option>
                  <mat-option value="divers">Divers</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <mat-form-field>
                <mat-label>Muttersprache</mat-label>
                <input
                  type="text"
                  placeholder="Muttersprache"
                  aria-label="Muttersprache"
                  matInput
                  formControlName="language"
                  [matAutocomplete]="auto"
                  required
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFn.bind(this)"
                  (optionSelected)="setLanguageOption($event?.option?.value, i)"
                >
                  <mat-option *ngFor="let language of filteredOptions[i] | async" [value]="language?.code">{{
                    getLanguageByCode(language?.code)
                  }}</mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="
                    class.get('language').hasError('incorrect') &&
                    class.get('language').touched &&
                    !class.get('language').hasError('required')
                  "
                >
                  Bitte wählen Sie eine Muttersprache aus der Liste
                </mat-error>
                <mat-error *ngIf="class.get('language').hasError('required')">
                  <!-- Please enter a class name. -->
                  Bitte wählen Sie die Muttersprache aus
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field>
                <mat-label>Geburtsmonat und -jahr*</mat-label>
                <input
                  matInput
                  [max]="maxBirthday"
                  formControlName="age"
                  type="text"
                  (click)="birthdaypicker.open()"
                  [matDatepicker]="birthdaypicker"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
                <mat-datepicker
                  #birthdaypicker
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, birthdaypicker, i)"
                ></mat-datepicker>
                <!-- <mat-error *ngIf="thirdFormStep.get('age').hasError('required')">Please choose a birth year and month.</mat-error>  -->
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="set_inline create_class_footer">
        <div class="create_class_footer_left">
          <div class="pdfPrintLayout">
            <mat-icon
              #pdfPrintLayouttooltip="matTooltip"
              matTooltipClass="pdfPrintLayout-tooltip"
              (mouseenter)="$event.stopImmediatePropagation()"
              (mouseleave)="$event.stopImmediatePropagation()"
              (click)="pdfPrintLayouttooltip.toggle()"
              class="icon-border group-question"
              matTooltip="{{ pdfPrintLayoutInfo }}"
              svgIcon="streamline_outlined:question"
            ></mat-icon>
            <mat-form-field appearance="fill" class="pdfPage">
              <mat-label>Drucklayout</mat-label>
              <mat-select [(value)]="pdfPrintLayout" [disabled]="isLoading">
                <mat-option value="1">Eine Seite pro Blatt - Normal</mat-option>
                <mat-option value="2">Zwei Seiten pro Blatt - Spart Papier</mat-option>
                <mat-option value="4">Vier Seiten pro Blatt - Spart mehr Papier</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button
            #grouptooltip="matTooltip"
            matTooltipClass="group-tooltip"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="$event.stopImmediatePropagation()"
            (click)="grouptooltip.toggle()"
            mat-flat-button
            color="unset"
            type="button"
            matTooltip="{{ infoText }}"
          >
            <mat-icon class="icon-border group-question" svgIcon="streamline_outlined:question"></mat-icon>Warum diese
            Informationen?
          </button>
        </div>

        <div class="button-info-wrap">
          <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="submitForm(formDirective)"
            class="create_class_button"
            aria-label="Registration"
            [disabled]="isLoading"
          >
            <span *ngIf="!isLoading">Speichern und PDF generieren</span>
            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          </button>
          <div class="loading-info" *ngIf="isLoading">
            <p class="paragraph paragraph--bold">
              Dieser Schritt kann einige Minuten dauern und sollte nicht abgebrochen werden.
            </p>
          </div>
        </div>
      </div>
    </form>
  </cdk-step>
</app-class-stepper>
