<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--left" matSuffix>
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      </button>

      <div class="school-license-detail-header">
        <h3 class="h3 h3--bold">{{ translatedText.school_license_detail_title }} {{ school_name }}</h3>
      </div>

      <div class="license-tabs-wrap" [@.disabled]="true">
        <mat-tab-group
          #tasksTabs
          (selectedTabChange)="tabChanged($event)"
          [(selectedIndex)]="selectedTabIndex"
          [disableRipple]="true"
        >
          <mat-tab
            label="{{ translatedText.school_license_detail_license_management }} {{ alllicenseAdministration?.length }}"
          >
            <div class="school-license-filter">
              <div class="toggle-buttons-wrap">
                <mat-button-toggle-group
                  #activeViewValue="matButtonToggleGroup"
                  [(ngModel)]="licenseManagmentFilter"
                  (change)="onSelectionChange()"
                >
                  <mat-button-toggle value="all">
                    {{ translatedText.school_license_detail_all }}
                  </mat-button-toggle>
                  <mat-button-toggle value="diagnostic">
                    {{ translatedText.school_license_detail_diagnostic }}
                  </mat-button-toggle>
                  <mat-button-toggle value="training">
                    {{ translatedText.school_license_detail_training }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <!-- <mat-form-field class="book_list_select task_type_select">
                            <div class="task-label-wrap">
                              <mat-label>Zeige:</mat-label>
                            </div>
                            <mat-select  floatLabel="never" [(ngModel)]="selectedValue" (ngModelChange)="onSelectionChange()" panelClass="dashboard-task-select" disableOptionCentering>
                              <mat-option value="all">{{translatedText.school_license_detail_all}}</mat-option>
                              <mat-option value="today">Heute</mat-option>
                              <mat-option value="week">Diese Woche</mat-option>
                              <mat-option value="month">Dieser Monat</mat-option>
                              <mat-option value="half_year">Dieses Quartal</mat-option>
                              <mat-option value="year">Dieses Jahr</mat-option>
                            </mat-select>
                        </mat-form-field> -->

              <mat-form-field appearance="fill" class="book_list_select task_status_select datepicker_select">
                <div>
                  <mat-label matTooltip="{{ translatedText.school_license_detail_tooltip }}">{{
                    translatedText.school_license_detail_time_range
                  }}</mat-label>
                </div>

                <mat-date-range-input [formGroup]="deadlineRange" [rangePicker]="picker">
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start"
                    (ngModelChange)="onSelectionChange()"
                  />
                  <input matEndDate formControlName="end" placeholder="Ende" (ngModelChange)="onSelectionChange()" />
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="deadlineRange.controls.start.hasError('matStartDateInvalid')">{{
                  translatedText.school_license_detail_start_date
                }}</mat-error>
                <mat-error *ngIf="deadlineRange.controls.end.hasError('matEndDateInvalid')">{{
                  translatedText.school_license_detail_end_date
                }}</mat-error>
              </mat-form-field>
            </div>

            <mat-table
              [dataSource]="licenseAdministration"
              matSort
              matSortDisableClear
              [matSortActive]="sortBy"
              [matSortDirection]="matSortDirection"
              (matSortChange)="onSortChange($event)"
            >
              <mat-header-row *matHeaderRowDef="displayedColumnsLicenseAdministration"></mat-header-row>
              <mat-row *matRowDef="let element; columns: displayedColumnsLicenseAdministration"></mat-row>

              <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_date }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.created | date: 'd. MMM y' }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_group }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.group }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="teacher">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_teacher }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.teachers.join(', ') }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_role }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ translatedText.school_license_detail_role_teacher }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="type_text">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_license_type
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.type_text }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_count }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.amount_left }}</p>
                </mat-cell>
              </ng-container>

              <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
              <ng-container matColumnDef="noRecord">
                <mat-footer-cell *matFooterCellDef colspan="2">
                  <div class="no_records" [hidden]="licenseAdministration?.length > 0">
                    <img src="../../../assets/empty.svg" alt="" />
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ translatedText.school_license_detail_no_license }}
                    </p>
                  </div>
                </mat-footer-cell>
              </ng-container>
            </mat-table>
          </mat-tab>

          <mat-tab
            label="{{ translatedText.school_license_detail_license_request }} {{ allrequestDataSource?.length }}"
          >
            <div class="school-license-filter">
              <div class="toggle-buttons-wrap">
                <mat-button-toggle-group
                  #activeRequestViewValue="matButtonToggleGroup"
                  [(ngModel)]="licenseRequestFilter"
                  (change)="onRequestSelectionChange()"
                >
                  <mat-button-toggle value="all">
                    {{ translatedText.school_license_detail_all }}
                  </mat-button-toggle>
                  <mat-button-toggle value="diagnostic">
                    {{ translatedText.school_license_detail_diagnostic }}
                  </mat-button-toggle>
                  <mat-button-toggle value="training">
                    {{ translatedText.school_license_detail_training }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <!-- <mat-form-field class="book_list_select task_type_select">
                            <div class="task-label-wrap">
                              <mat-label>Zeige:</mat-label>
                            </div>
                            <mat-select  floatLabel="never" [(ngModel)]="selectedRequestValue" (ngModelChange)="onRequestSelectionChange()" panelClass="dashboard-task-select" disableOptionCentering>
                              <mat-option value="all">{{translatedText.school_license_detail_all}}</mat-option>
                              <mat-option value="today">Heute</mat-option>
                              <mat-option value="week">Diese Woche</mat-option>
                              <mat-option value="month">Dieser Monat</mat-option>
                              <mat-option value="half_year">Dieses Quartal</mat-option>
                              <mat-option value="year">Dieses Jahr</mat-option>
                            </mat-select>
                        </mat-form-field> -->

              <mat-form-field appearance="fill" class="book_list_select task_status_select datepicker_select">
                <div>
                  <mat-label matTooltip="{{ translatedText.school_license_detail_tooltip }}">{{
                    translatedText.school_license_detail_time_range
                  }}</mat-label>
                </div>

                <mat-date-range-input [formGroup]="deadlineRange" [rangePicker]="requestpicker">
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start"
                    (ngModelChange)="onRequestSelectionChange()"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="Ende"
                    (ngModelChange)="onRequestSelectionChange()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="requestpicker"></mat-datepicker-toggle>
                <mat-date-range-picker #requestpicker></mat-date-range-picker>

                <mat-error *ngIf="deadlineRange.controls.start.hasError('matStartDateInvalid')">{{
                  translatedText.school_license_detail_start_date
                }}</mat-error>
                <mat-error *ngIf="deadlineRange.controls.end.hasError('matEndDateInvalid')">{{
                  translatedText.school_license_detail_end_date
                }}</mat-error>
              </mat-form-field>
            </div>
            <mat-table
              [dataSource]="requestDataSource"
              matSort
              matSortDisableClear
              [matSortActive]="sortBy"
              [matSortDirection]="matSortDirection"
              (matSortChange)="onSortChange($event)"
            >
              <mat-header-row *matHeaderRowDef="requestColumns"></mat-header-row>
              <mat-row *matRowDef="let element; columns: requestColumns"></mat-row>

              <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_date }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.created | date: 'd. MMM y' }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="teacher">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_teacher }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div class="teacher_with_icon">
                    <button mat-mini-fab>
                      <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                    </button>
                    <p class="paragraph paragraph--s">
                      {{ element?._teacher }}
                    </p>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="admin">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_admin }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.admin }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_status }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element?.status == 'requested'">
                    <p class="paragraph paragraph--s status-chip status-chip--inactive">
                      {{ translatedText.school_license_detail_pending }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element?.status == 'accept'">
                    <p class="paragraph paragraph--s status-chip status-chip--active">
                      {{ translatedText.school_license_detail_accepted }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element?.status == 'decline'">
                    <p class="paragraph paragraph--s status-chip status-chip--warn">
                      {{ translatedText.school_license_detail_denied }}
                    </p>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="type_key">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_license_type
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.type_key == 'fo'">
                    <p class="paragraph paragraph--s">
                      {{ translatedText.school_license_detail_minutes }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element.type_key == 'di'">
                    <p class="paragraph paragraph--s">
                      {{ translatedText.school_license_detail_diagnostic }}
                    </p>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_license_count
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.amount }}</p>
                </mat-cell>
              </ng-container>

              <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
              <ng-container matColumnDef="noRecord">
                <mat-footer-cell *matFooterCellDef colspan="2">
                  <div class="no_records" [hidden]="requestDataSource?.length > 0">
                    <img src="../../../assets/empty.svg" alt="" />
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ translatedText.school_license_detail_no_license_requests }}
                    </p>
                  </div>
                </mat-footer-cell>
              </ng-container>
            </mat-table>
          </mat-tab>

          <mat-tab label="{{ translatedText.school_license_detail_license_codes }} {{ licenseCodeSource?.length }}">
            <mat-table
              [dataSource]="licenseCodeSource"
              matSort
              matSortDisableClear
              [matSortActive]="sortBy"
              [matSortDirection]="matSortDirection"
              (matSortChange)="onSortChange($event)"
            >
              <mat-header-row *matHeaderRowDef="licenseCodeColumns"></mat-header-row>
              <mat-row *matRowDef="let element; columns: licenseCodeColumns"></mat-row>

              <ng-container matColumnDef="used_at">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_date }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">{{ element?.used_at | date: 'd. MMM y' }}</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="master_license_key">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_license_codes
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p class="paragraph paragraph--s">
                    {{ element?.master_license_key }}
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>{{ translatedText.school_license_detail_status }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element?.status === 'active'">
                    <p class="paragraph paragraph--s status-chip status-chip--active">
                      {{ translatedText.school_license_detail_available }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="element?.status === 'inactive'">
                    <p class="paragraph paragraph--s status-chip status-chip--warn">
                      {{ translatedText.school_license_detail_unavailable }}
                    </p>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount_left_di">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_diagnostic
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p *ngIf="element?.amount_left_di !== undefined" class="paragraph paragraph--s">
                    {{ element?.amount_di }}
                  </p>
                  <p *ngIf="element?.amount_left_di == undefined" class="paragraph paragraph--s">-</p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount_left_fo">
                <mat-header-cell *matHeaderCellDef>{{
                  translatedText.school_license_detail_minutes_total
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <p *ngIf="element?.amount_left_fo !== undefined" class="paragraph paragraph--s">
                    {{ element?.amount_fo / 60 }}
                  </p>
                  <p *ngIf="element?.amount_left_fo == undefined" class="paragraph paragraph--s">-</p>
                </mat-cell>
              </ng-container>

              <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
              <ng-container matColumnDef="noRecord">
                <mat-footer-cell *matFooterCellDef colspan="2">
                  <div class="no_records" [hidden]="licenseCodeSource?.length > 0">
                    <img src="../../../assets/empty.svg" alt="" />
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ translatedText.school_license_detail_no_license_codes }}
                    </p>
                  </div>
                </mat-footer-cell>
              </ng-container>
            </mat-table>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
