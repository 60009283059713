import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-task-error-overlay',
  templateUrl: './task-error-overlay.component.html',
  styleUrls: ['./task-error-overlay.component.scss'],
})
export class TaskErrorOverlayComponent implements OnInit {
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog, // TODO: unused?
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  errorTask() {}
}
