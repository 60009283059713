<div class="student-report-container teacher-list-container">
  <div class="row justify-content-center student-report-container-inside-height">
    <div class="teacher-content-container no-margin report_text col-lg-8 col-md-8 col-sm-12">
      <div class="class_detail_info">
        <div class="report_backButton">
          <div class="inline_text">
            <button mat-fab type="button" color="primary" (click)="navigateBack()" class="backButton" matSuffix>
              <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
            </button>
            <div class="inline_text">
              <img class="avatar_img" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
              <div class="username-with-avatar">
                <h3 class="h3--bold">{{ student?.username }}&nbsp;</h3>
                <h3 *ngIf="getGroupName(student?._group)?.name">({{ getGroupName(student?._group)?.name }})</h3>
              </div>
            </div>
            <!-- <h3 class="h3"><span class="h3--bold">Hase3</span> (2A - 22/23)</h3> -->
          </div>
        </div>

        <!-- <div class="book_reader_settings">
                    <button mat-fab type="button" class="backButton close_preview" #fonttooltip="matTooltip" matTooltip="Schriftgröße" matTooltipClass="font-tooltip" (click)="openBookReaderSettings()" matSuffix>
                        <mat-icon aria-hidden="false" fontIcon="tune"></mat-icon>
                    </button>
                </div> -->

        <div class="buttons-below">
          <button
            mat-flat-button
            color="tertiary"
            type="button"
            class="hidden_result_button"
            (click)="toggleResults()"
            [hidden]="list_of_operations < 1"
          >
            <mat-icon>{{ hideAnnotation ? 'assignment_turned_in' : 'assignment' }}</mat-icon>
            {{ hideAnnotation ? translatedText.student_report_show_result : translatedText.student_report_hide_result }}
          </button>
          <!-- <button mat-flat-button color="tertiary" type="button" class="warn-button" (click)="reportError()">
                        <mat-icon svgIcon="streamline_outlined:warning-circle"></mat-icon>Fehler melden
                    </button> -->
          <button mat-flat-button color="tertiary" type="button" (click)="openNotificationOverlay()">
            <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>{{ translatedText.student_report_comment }}
          </button>
          <!-- <button mat-flat-button color="warning" class="report_error_button" type="button" (click)="reportError()" [hidden]="list_of_operations < 1">
                        <mat-icon svgIcon="streamline_filled:alert"></mat-icon>Fehler melden
                    </button> -->
        </div>
      </div>

      <div class="report_content">
        <!-- <p class="book-reader-content" [innerHtml]="content"></p> -->
        <app-book-reader-view
          *ngIf="task?.book_isbn && annotated_text != null"
          [bookReaderType]="'getan'"
          [bookIsbn]="task?.book_isbn"
          [dsefVersion]="dsefVersion"
          [bookAnnotation]="getMarkup()"
          [FilteredDSEF]="FilteredDSEF"
          [bookRange]="taskRange"
          [taskType]="task?.type"
          [bookReaderClass]="{
            'book-reader-wrap--hide-annotation': hideAnnotation
          }"
          [userTaskUuid]="userTaskUuid"
          (reportErrorData)="clickedWordReportError($event)"
          fromComponent="student-report"
        >
        </app-book-reader-view>
        <div *ngIf="annotated_text == null">
          <h3>{{ translatedText.student_report_error_message }}</h3>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 report_info">
      <div class="set_inline" *ngIf="reading_duration">
        <h3 class="h3 h3--bold">{{ translatedText.student_report_results }}</h3>
        <h2 class="paragraph paragraph--s">{{ sessionHash }}</h2>
      </div>

      <div class="report_header">
        <div class="set_inline" *ngIf="reading_duration">
          <p class="paragraph paragraph--l">
            {{ translatedText.student_report_read_time }}
          </p>
          <p class="paragraph paragraph--bold paragraph--l">{{ reading_duration * 1000 | date : 'mm:ss' }} min</p>
        </div>
        <div class="set_inline" *ngIf="num_read_words">
          <p class="paragraph paragraph--l">
            {{ translatedText.student_report_read_words }}
          </p>
          <p class="paragraph paragraph--bold paragraph--l">
            {{ num_read_words }}
          </p>
        </div>
        <!-- <div class="set_inline" *ngIf="reading_speed_words">
                    <p class="paragraph paragraph--l">Wörter pro Minute</p>
                    <p class="paragraph paragraph--bold paragraph--l">{{reading_speed_words}}</p>
                </div> -->
        <div class="set_inline" *ngIf="text_coverage && !isDiagnosticTest">
          <p class="paragraph paragraph--l">
            {{ translatedText.student_report_text_coverage }}
          </p>
          <p class="paragraph paragraph--bold paragraph--l">
            <span>{{ text_coverage }}%</span>
          </p>
        </div>
        <div class="set_inline" *ngIf="correctly_read_words_percentage">
          <p class="paragraph paragraph--l">
            {{ translatedText.student_report_read_words_percentage }}
          </p>
          <p class="paragraph paragraph--bold paragraph--l">
            <span>{{ correctly_read_words_percentage }}%</span>
          </p>
        </div>
      </div>

      <div class="report_audio_footer">
        <div class="audio_wrap">
          <div>
            <audio
              class="audio_file"
              [src]="audioUrl"
              controls
              controlsList="nodownload"
              preload="none"
              *ngIf="audioUrl"
            ></audio>
          </div>
        </div>
      </div>

      <!-- <div class="report_card_tendency">
                <div class="inline_text">
                    <mat-icon color="warn" class="warn_icon" svgIcon="streamline_filled:flag"></mat-icon>
                    <p class="paragraph">Tendenz zu falscher Satzbetonung</p>
                </div>
            </div> -->

      <!-- <div class="report_card">
                <div class="row report_row_top" *ngIf="correctly_read_words_percentage != null">
                    <div class="col-md-4">
                        <ngx-charts-pie-chart
                            [view]="view"
                            [scheme]="colorScheme"
                            [results]="errorInfo"
                            [designatedTotal]="100"
                            [doughnut]="isDoughnut">
                        </ngx-charts-pie-chart>
                        <div class="chartValue">
                            <p class="paragraph paragraph--l ">{{this.error_percent}}%</p>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <p class="paragraph paragraph--bold paragraph--s diagram-header">Anteil der richtig gelesenen Wörter</p>
                        <p class="paragraph paragraph--s">Korrekte Wörter pro Minute: <span class="paragraph paragraph--bold paragraph--s">{{reading_speed_correct_words_per_minute}}</span></p>
                    </div>
                </div>
            </div> -->

      <div class="report_card">
        <div class="set_inline">
          <p class="paragraph paragraph--bold headline-info-tooltip">
            <span
              >{{ translatedText.student_report_read_points }}
              <ng-container *ngIf="reading_combined_score != undefined">
                {{ reading_combined_score }}
                <span class="disable-color">/100</span>
              </ng-container>
            </span>
            <mat-icon
              svgIcon="streamline_outlined:info-empty"
              matTooltip="{{ translatedText.student_report_readingPointsInfoText }}"
            ></mat-icon>
          </p>
        </div>
        <app-student-progress-level
          [reading_combined_score]="reading_combined_score || 0"
          [isRedingPoints]="true"
        ></app-student-progress-level>
      </div>

      <div class="report_card">
        <div class="row">
          <div class="col-md-12">
            <p class="paragraph paragraph--bold report_card__headline headline-info-tooltip">
              {{ translatedText.student_report_evaluetion }}
              <mat-icon
                svgIcon="streamline_outlined:info-empty"
                matTooltip="{{ translatedText.student_report_evaluationInfoText }}"
              ></mat-icon>
            </p>
            <div class="set_inline workload">
              <p class="paragraph paragraph--bold paragraph--s">
                {{ translatedText.student_report_deletion }}
              </p>
              <p class="paragraph paragraph--bold paragraph--s">
                {{ num_deletions }}
              </p>
            </div>
            <div class="set_inline replacement">
              <p class="paragraph paragraph--bold paragraph--s">
                {{ translatedText.student_report_substitution }}
              </p>
              <p class="paragraph paragraph--bold paragraph--s">
                {{ num_substitutions }}
              </p>
            </div>
            <div class="set_inline addition">
              <p class="paragraph paragraph--bold paragraph--s">
                {{ translatedText.student_report_insertion }}
              </p>
              <p class="paragraph paragraph--bold paragraph--s">
                {{ num_insertions }}
              </p>
            </div>
            <!-- <div class="set_inline long_breaks">
                            <p class="paragraph paragraph--bold paragraph--s">Zu lange Pause</p>
                            <p class="paragraph paragraph--bold paragraph--s">{{num_longpause}}</p>
                        </div> -->
          </div>
        </div>
      </div>

      <div class="report_card">
        <div class="set_inline">
          <p class="paragraph paragraph--bold headline-info-tooltip">
            {{ translatedText.student_report_reading_fluency }}
            <mat-icon
              svgIcon="streamline_outlined:info-empty"
              matTooltip="{{ translatedText.student_report_readingFluencyInfoText }}"
            ></mat-icon>
          </p>
          <!-- <div class="inline_text">
                        <p class="paragraph paragraph--s">15%</p>
                        <mat-icon class="warn_icon" svgIcon="streamline_filled:minus"></mat-icon>
                    </div> -->
        </div>
        <p class="paragraph paragraph--s">
          {{ translatedText.student_report_wcpm }}
        </p>

        <!-- <div class="set_inline line_bar_text">
                    <p class="caption">Zu langsam</p>
                    <p class="caption">Zu schnell</p>
                </div> -->
        <!-- <app-status-bar [value]='reading_speed_words_normalized'></app-status-bar>   -->
        <app-student-progress-level
          [value]="level_wcpm"
          [isNumbers]="true"
          [statusbarText]="'WCPM'"
          [statusbarValue]="reading_speed_correct_words_per_minute"
        ></app-student-progress-level>
        <!-- <div class="set_inline long_breaks">
                    <p class="paragraph paragraph--bold paragraph--s">Zu lange Pausen</p>
                    <p class="paragraph paragraph--bold paragraph--s">{{num_longpause}}</p>
                </div> -->
      </div>

      <div class="report_card">
        <div class="set_inline">
          <p class="paragraph paragraph--bold headline-info-tooltip">
            {{ translatedText.student_report_speed }}
            <mat-icon
              svgIcon="streamline_outlined:info-empty"
              matTooltip="{{ translatedText.student_report_readingSpeedInfoText }}"
            ></mat-icon>
          </p>
          <!-- <div class="inline_text">
                        <p class="paragraph paragraph--s">15%</p>
                        <mat-icon class="warn_icon" svgIcon="streamline_filled:minus"></mat-icon>
                    </div> -->
        </div>
        <p class="paragraph paragraph--s">
          {{ translatedText.student_report_wpm }}
        </p>

        <!-- <div class="set_inline line_bar_text">
                    <p class="caption">Zu langsam</p>
                    <p class="caption">Zu schnell</p>
                </div> -->
        <!-- <app-status-bar [value]='reading_speed_words_normalized'></app-status-bar>   -->
        <app-student-progress-level
          [value]="level_wpm"
          [isNumbers]="false"
          [statusbarText]="'WPM'"
          [statusbarValue]="reading_speed_words"
        ></app-student-progress-level>
        <!-- <div class="set_inline long_breaks">
                    <p class="paragraph paragraph--bold paragraph--s">Zu lange Pausen</p>
                    <p class="paragraph paragraph--bold paragraph--s">{{num_longpause}}</p>
                </div> -->
      </div>

      <div class="report_card">
        <div class="set_inline">
          <p class="paragraph paragraph--bold headline-info-tooltip">
            {{ translatedText.student_report_dynamics }}
            <mat-icon
              svgIcon="streamline_outlined:info-empty"
              matTooltip="{{ translatedText.student_report_readingDynamicInfoText }}"
            ></mat-icon>
          </p>
          <!-- <div class="inline_text">
                        <p class="paragraph paragraph--s">15%</p>
                        <mat-icon svgIcon="streamline_filled:minus"></mat-icon>
                    </div> -->
        </div>
        <p class="paragraph paragraph--s">
          {{ translatedText.student_report_dynamics_description }}
        </p>

        <div class="set_inline line_bar_text">
          <p class="caption">{{ translatedText.student_report_monoton }}</p>
          <p class="caption">{{ translatedText.student_report_overton }}</p>
        </div>
        <app-status-bar [value]="reading_dynamic_normalized"></app-status-bar>
      </div>
    </div>
  </div>
</div>
