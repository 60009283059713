<div class="teacher-list-container">
  <div class="">
    <div class="teacher-content-container">
      <div class="admin-teacher-header">
        <h3 class="h3 h3--bold">{{ translatedText.admin_list_headline }} ({{ dataSource?.meta?.totalItems }})</h3>
        <div class="admin-teacher-header_buttons">
          <!-- <button mat-flat-button color="primary" type="button" class="mat-flat-button--full" (click)="openNotificationOverlay()">Nachricht verschicken</button> -->
          <button mat-flat-button color="primary" type="button" [matMenuTriggerFor]="moreTeacherMenu">
            <mat-icon svgIcon="streamline_outlined:add-circle"></mat-icon>{{ translatedText.admin_list_add_admin }}
          </button>
          <mat-menu #moreTeacherMenu="matMenu">
            <button mat-menu-item color="filled" (click)="createTeacherOverlay()" type="button">
              <mat-icon svgIcon="streamline_outlined:add-user-alt"></mat-icon>
              <span>{{ translatedText.admin_list_create_new_admin }}</span>
            </button>
            <button mat-menu-item aria-label="view-detail" (click)="teacherUpgradeOverlay()">
              <mat-icon svgIcon="streamline_outlined:select-window"></mat-icon>
              <span>{{ translatedText.admin_list_select_teacher }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="teacher-list-filter-wrap">
        <!-- <div class="teacher-list-filter-selection">
          <mat-form-field>
            <mat-label>Klasse:</mat-label>
            <mat-select
              [formControl]="filterFormGroup.get('group')"
              panelClass="dashboard-task-select"
              disableOptionCentering
              multiple
              (selectionChange)="onSelectChange($event, 'groups')"
            >
              <mat-select-trigger>
                {{ generateSelectionText(groups, filterFormGroup.get('group').value) }}
              </mat-select-trigger>
              <app-select-all-option
                [control]="filterFormGroup.get('group')"
                [values]="groups"
                [valuetype]="'object'"
              ></app-select-all-option>
              <mat-option *ngFor="let group of groups" [value]="group.value">
                {{ group.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Status:</mat-label>
            <mat-select
              [formControl]="filterFormGroup.get('status')"
              panelClass="dashboard-task-select"
              disableOptionCentering
              multiple
              (selectionChange)="onSelectChange($event, 'status')"
            >
              <mat-select-trigger>
                {{ generateSelectionText(status, filterFormGroup.get('status').value) }}
              </mat-select-trigger>
              <app-select-all-option
                [control]="filterFormGroup.get('status')"
                [values]="status"
                [valuetype]="'object'"
              ></app-select-all-option>
              <mat-option *ngFor="let status of status" [value]="status.value">
                {{ status.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <!-- <mat-form-field class="full-width search-teacher" floatLabel="never">
          <mat-label>Suche</mat-label>
          <input
            matInput
            type="text"
            (input)="onSearchInputFilterChange($event)"
            placeholder="Suche nach Nachname oder Vorname"
          />
        </mat-form-field> -->
      </div>
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource?.data"
          matSort
          matSortDisableClear
          [matSortActive]="sortBy"
          [matSortDirection]="matSortDirection"
          (matSortChange)="onSortChange($event)"
        >
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ translatedText.admin_list_full_name }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="teacher_with_icon teacher_with_icon--blue">
                <button mat-mini-fab>
                  <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                </button>
                <p class="paragraph paragraph--s">
                  {{ element?.name }}
                  <span *ngIf="element?._user?._id == id" class="admin-chip">{{ translatedText.admin_list_you }}</span>
                </p>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_list_email }}</th>
            <td mat-cell *matCellDef="let element">{{ element?._user?.email }}</td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_list_role }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element._user?.role == 'school_admin'">
                {{ translatedText.admin_list_role_admin }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_list_status_placeholder }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.status == 'active'">
                <p class="paragraph paragraph--s status-chip status-chip--active">
                  {{ translatedText.admin_list_active }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.status == 'inactive'">
                <p class="paragraph paragraph--s status-chip status-chip--inactive">
                  {{ translatedText.admin_list_inactive }}
                </p>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="buttons" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
              {{ translatedText.admin_list_action }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="license-action-wrap">
                <!-- <button
                  mat-mini-fab
                  type="button"
                  matSuffix
                  (click)="editTeacher(element._id, element?._user.email)"
                >
                  <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                </button> -->
                <button
                  mat-mini-fab
                  type="button"
                  (click)="editSchoolAdminOverlay(element._id, false)"
                  matSuffix
                  matTooltip="{{ translatedText.admin_list_details_admin }}"
                >
                  <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                </button>
                <button
                  [matMenuTriggerFor]="moreTeacherMenu"
                  mat-mini-fab
                  type="button"
                  class="not-filled"
                  matSuffix
                >
                  <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                </button>
                <mat-menu #moreTeacherMenu="matMenu">
                  <button
                    mat-menu-item
                    aria-label="view-detail"
                    (click)="impersonateUser(element?._user?._id)"
                    *ngIf="userType == 'admin'"
                  >
                    <mat-icon svgIcon="streamline_outlined:verified-user"></mat-icon>
                    <span>{{ translatedText.admin_list_act_as_teacher }}</span>
                  </button>
                  <button mat-menu-item aria-label="view-detail" (click)="editSchoolAdminOverlay(element._id, true)">
                    <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                    <span>{{ translatedText.admin_list_edit }}</span>
                  </button>
                  <button mat-menu-item aria-label="view-detail" (click)="updateTeacherStatus(element)">
                    <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
                    <span>{{
                      element?.status == 'active'
                        ? translatedText.admin_list_set_inactive
                        : translatedText.admin_list_set_active
                    }}</span>
                  </button>
                  <button
                    *ngIf="element._id !== teacherId"
                    mat-menu-item
                    class="trash-menu-item"
                    color="tertiary"
                    type="button"
                    (click)="updateAdminRights(element)"
                  >
                    <mat-icon svgIcon="streamline_outlined:revoke-admin-rights"></mat-icon>
                    {{ translatedText.admin_list_revoke_admin }}
                  </button>
                  <button
                    class="trash-menu-item"
                    mat-menu-item
                    aria-label="view-detail"
                    (click)="openTeacherDeleteDialog(element)"
                    *ngIf="
                      element?._user?.role != 'school_admin' &&
                      element?._tasks.length == 0 &&
                      element?._group.length == 0
                    "
                  >
                    <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                    <span>{{ translatedText.admin_list_delete }}</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <tr mat-footer-row *matFooterRowDef="['noRecord']" [hidden]="dataSource?.data.length > 0"></tr>
          <ng-container matColumnDef="noRecord">
            <td mat-footer-cell *matFooterCellDef colspan="2">
              <span>
                {{ translatedText.admin_list_no_results }}
              </span>
            </td>
          </ng-container>
        </table>
      </div>
      <mat-paginator
        [hidePageSize]="true"
        [length]="dataSource?.meta.totalItems"
        [pageSize]="
          dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
            ? dataSource?.meta.itemsPerPage
            : dataSource?.meta.totalItems
        "
        [pageSizeOptions]="[10, 25, 100]"
        (page)="pageEvent = $event; onPaginateChange($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
