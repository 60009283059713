import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StudentProgressBarComponent } from './student-progress-bar.component';

@NgModule({
  declarations: [StudentProgressBarComponent],
  imports: [BrowserModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [StudentProgressBarComponent],
})
export class StudentProgressBarModule {}
