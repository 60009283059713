import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { UnassignedStudentToClassComponent } from './unassigned-student-to-class.component';

@NgModule({
  declarations: [UnassignedStudentToClassComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [UnassignedStudentToClassComponent],
})
export class UnassignedStudentToClassModule {}
