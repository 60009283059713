import { NgModule } from '@angular/core';
import { CountUpDirective } from './count-up/count-up.directive';
import { AutocompletePositionDirective } from './mat-autocomplete-position/mat-autocomplete-position.directive';
//import { ImagesLazyloadDirective } from './images-lazyload/images-lazyload.directive';

@NgModule({
  imports: [],
  declarations: [
    CountUpDirective,
    AutocompletePositionDirective,
    //ImagesLazyloadDirective
  ],
  exports: [
    CountUpDirective,
    AutocompletePositionDirective,
    //ImagesLazyloadDirective
  ],
})
export class DirectivesModule {}
