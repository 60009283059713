import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupService } from '../../providers/group.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-class-delete',
  templateUrl: './class-delete.component.html',
  styleUrls: ['./class-delete.component.scss'],
})
export class ClassDeleteComponent implements OnInit {
  environment = environment;
  groupId;
  groupName;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  translatedText;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private groupService: GroupService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  deleteGroup() {
    this.groupService
      .deleteGroup(this.groupId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.dialog.closeAll();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data:
              this.translatedText?.class_delete_text +
              ' ' +
              this.groupName +
              ' ' +
              this.translatedText?.class_delete_text_2,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        (error) => {
          let message;
          if (
            error.error.message ==
            'At least one Task exists, therefor Group can not be deleted!'
          ) {
            message = this.translatedText?.class_delete_exists;
          } else {
            message = this.translatedText?.class_delete_error;
          }
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }
}
