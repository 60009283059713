import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CsvImportTeacherComponent } from './csv-import-teacher.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';
@NgModule({
  declarations: [CsvImportTeacherComponent],
  imports: [BrowserModule, CommonModule, MaterialModule, LoadingSpinnerModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [CsvImportTeacherComponent],
})
export class CsvImportTeacherModule {}
