import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GuardsCheckEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { DiagnosticCreateComponent } from 'src/app/diagnostic/diagnostic-create/diagnostic-create.component';
import { VideoOverlayComponent } from 'src/app/helpers/video-overlay/video-overlay.component';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { TrainingTaskCreateComponent } from 'src/app/training-task/training-task-create/training-task-create.component';
import { environment } from 'src/environments/environment';

export interface Tasks {}

@Component({
  selector: 'app-teacher-mobile-menu',
  templateUrl: './teacher-mobile-menu.component.html',
  styleUrls: ['./teacher-mobile-menu.component.scss'],
})
export class TeacherMobileMenuComponent implements OnInit, OnDestroy {
  @Output('mobileOverlay') mobileOverlayChanged = new EventEmitter<boolean>();
  @Input() mobileOverlayClicked: boolean = false;
  support_url = environment.whitelabel.support_url;
  externalAssetsUrl = environment.externalAssets;
  userType;
  diagnosticLicenseStatusActive: boolean = false;
  trainingLicenseStatusActive: boolean = false;
  mobileOverlay: boolean = false;
  currentUrlSlug;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog, // TODO: private or public?
    public breakpointObserver: BreakpointObserver, // TODO: private or public?
    public router: Router, // TODO: private or public?
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        this.currentUrlSlug = event.url.split('/')[1];
      }
    });
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.userType = this.authService.getType();
    this.licenseService
      .getActiveLicensesUpdatedListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.diagnosticLicenseStatusActive =
          status.di === 'active' ? true : false;
        this.trainingLicenseStatusActive =
          status.fo === 'active' ? true : false;
      });
  }

  showVideo(filename, title) {
    let dialogRef = this.dialog.open(VideoOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'video-overlay-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'video-overlay-panel-backdrop',
      data: {
        title: title,
        url: this.externalAssetsUrl + '/assets/videos/' + filename,
      },
    });
  }

  mobileOverlayHandler(): void {
    this.mobileOverlayClicked = !this.mobileOverlayClicked;
    this.mobileOverlayChanged.emit(this.mobileOverlayClicked);
       
    if (this.mobileOverlayClicked){

      let html = document.getElementsByTagName('html')[0];
      html.style.overflow = 'hidden';
    }
  }

  openExternalUrl(url: string) {
    window.open(url, '_blank');
  }

  createTaskDialog(): void {
    let dialogRef = this.dialog.open(TrainingTaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      //animation: { to: 'left' },
      //position: { rowEnd: '10%' },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  createDiagnoseDialog() {
    let dialogRef = this.dialog.open(DiagnosticCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
