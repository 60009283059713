import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  throwError,
} from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface License {
  _id?: string;
}

export interface LicenseStatus {
  di: string;
  fo: string;
}

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  groups: any[] = [];
  studentLicensesUpdated = new Subject<any[]>();
  groupsUpdated = new Subject<any[]>();
  licensesUpdated = new Subject<any[]>();
  sublicensesUpdated = new Subject<any[]>();
  sublicenses;
  activeLicensesUpdated = new BehaviorSubject<LicenseStatus>({
    di: 'inactive',
    fo: 'inactive',
  });
  licenses;
  licenseInfo;
  requestsToTeacherUpdated = new Subject<any[]>();
  requestsToTeacher;
  requestsToSchoolUpdated = new Subject<any[]>();
  requestsToSchool;
  lastLicenseRequestTime: number | null = null;

  private restServerUrl;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  checkLicense() {
    // Mock license call
    const response = JSON.parse(`{"_id":"123456789"}`);

    return new Observable((subscriber) => {
      setTimeout(() => {
        subscriber.next(response);
        subscriber.complete();
      }, 1000);
    });

    // return this.http.get<License>(this.restServerUrl + '/api/license/checkLicense/')
    //   .pipe(map(data => data));
  }

  getActiveLicenses() {
    return this.activeLicensesUpdated.value;
  }

  setActiveLicensesUpdatedListener(status) {
    this.activeLicensesUpdated.next(status);
  }

  getActiveLicensesUpdatedListener() {
    return this.activeLicensesUpdated.asObservable();
  }

  getLicensesUpdateListener() {
    return this.licensesUpdated.asObservable();
  }

  getLicensesToSchool(school_id?) {
    const currentTime = Date.now();

    // Skip the request if it was made within the last second
    if (
      this.lastLicenseRequestTime &&
      currentTime - this.lastLicenseRequestTime < 1000
    ) {
      // console.log('License request skipped to avoid duplicate within 1 second.');
      return;
    }

    // Update the last request time
    this.lastLicenseRequestTime = currentTime;

    let request = this.restServerUrl + '/api/school/getLicensesToSchool';
    if (school_id !== undefined) {
      request += '?school_id=' + school_id;
    }
    this.http.get<License[]>(request).subscribe({
      next: (data) => {
        this.licenses = data;
        this.licensesUpdated.next([...this.licenses]);
      },
      error: (error) => {
        //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
      },
    });
  }

  getSubLicensesUpdateListener() {
    return this.sublicensesUpdated.asObservable();
  }

  sumLicensesByTypeKey(licensesToSchool, typeKey, amount_val) {
    let filter = licensesToSchool.reduce(
      (acc, obj) => {
        if (obj.type_key === typeKey) {
          if (
            new Date(obj.expiration_date) > new Date() &&
            obj.status == 'active'
          ) {
            acc.amount += obj.amount ? obj.amount : 0;
            acc.amount_left += obj.amount_left ? obj.amount_left : 0;
          }
        }
        return acc;
      },
      { type_key: typeKey, amount: 0, amount_left: 0 },
    );
    return filter[amount_val];
  }

  sumPendingLicenses(licensesToSchool) {
    let filter = licensesToSchool.filter(
      (license) => license.status === 'pending',
    );
    return filter.length;
  }

  checkForUnlimitedLicense(tag, licensesToSchool) {
    if (tag == 'di') {
      let amount = this.sumLicensesByTypeKey(licensesToSchool, tag, 'amount');
      return amount >= 100 ? true : false;
    } else {
      return false;
    }
  }

  getContentTypeByLicense(licenses: any[]): 'klett' | 'full' | 'trial' {
    const ranking = {
      klett: 1,
      trial: 2,
      full: 3,
    };
    return licenses
      .filter((el) => new Date(el.expiration_date) > new Date())
      .reduce(
        (max, item) =>
          ranking[item.content_type] > ranking[max] ? item.content_type : max,
        licenses[0].content_type,
      );
  }

  getContentTypeByLicenseKey(master_license_key, translatedText) {
    const master_type_key = master_license_key.substring(0, 2).toLowerCase();
    let contentType = translatedText.licenses_purchase_content_klett;
    switch (master_type_key) {
      case 'di':
      case 'dl':
        contentType = translatedText.licenses_purchase_content_diagnostic;
        break;
      case 'lf':
      case 'yy':
      case 'zz':
      case 'fo':
      case 'un':
      case 'ki':
        contentType = translatedText.licenses_purchase_content_full;
        break;
      case 'tl':
        contentType = translatedText.licenses_purchase_content_trial;
        break;
      default:
        break;
    }
    return contentType;
  }

  filterLicensesByExpirationDate(licensesToSchool, typeKey, amount_val) {
    const fourWeeksFromNow = new Date();
    fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
    let filter = licensesToSchool
      .filter(
        (obj) =>
          obj.status == 'active' &&
          obj.type_key === typeKey &&
          new Date(obj.expiration_date) <= fourWeeksFromNow &&
          new Date(obj.expiration_date) > new Date(),
      )
      .reduce(
        (acc, obj) => {
          if (obj.type_key === typeKey) {
            acc.amount += obj.amount ? obj.amount : 0;
            acc.amount_left += obj.amount_left ? obj.amount_left : 0;
          }
          return acc;
        },
        { type_key: typeKey, amount: 0, amount_left: 0 },
      );
    return filter[amount_val];
  }

  // getSubLicensesToSchool(schoolId) {
  //   console.log("AAAAAAAA")
  //   this.http.get<License[]>(this.restServerUrl + '/api/school/getSubLicensesToSchool/', schoolId )
  //   .pipe(map(data => data), takeUntil(this._unsubscribeAll))
  //       .subscribe(data => {
  //           this.sublicenses = data;
  //           this.sublicensesUpdated.next([...this.sublicenses]);
  //       }, (error) => {
  //       //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
  //   });
  // }

  getSubLicensesToSchool(schoolId) {
    console.log(schoolId);
    return this.http
      .get<any>(
        this.restServerUrl + '/api/school/getSubLicensesToSchool/' + schoolId,
      )
      .subscribe((data) => {
        //console.log(data)
        this.sublicenses = data;
        this.sublicensesUpdated.next(this.sublicenses);
      });
  }

  generateSubLicenses(license) {
    return this.http
      .post<any>(this.restServerUrl + '/api/school/getLicenseInformation', {
        master_license_key: license,
      })
      .pipe(
        concatMap((res) => {
          this.licenseInfo = res;
          console.log(res);

          // Check if the response array length is greater than 0
          if (res && res.length > 0) {
            // Proceed to generate license for school
            return this.http
              .post<any>(
                this.restServerUrl + '/api/school/generateLicenseForSchool',
                {
                  master_license_key: license,
                  readalizer_customer: environment.readalizer_customer,
                },
              )
              .pipe(
                tap((res) => console.log(res)),
                catchError((error) => {
                  console.error('Error generating license for school', error);
                  return throwError(error);
                }),
              );
          } else {
            // If the response array length is 0, throw an error
            console.log('No licenses to generate');
            return throwError('No licenses found to generate');
          }
        }),
      );
  }

  getLicenseInfo() {
    return this.licenseInfo;
  }

  checkLicenseAvailability(license) {
    return this.http
      .post<any>(
        this.restServerUrl + '/api/school/getLicenseInformationAndUsage',
        { master_license_key: license },
      )
      .pipe(tap((res) => console.log(res)));
  }

  checkMultipleLicenses(license) {
    return this.http
      .post<any>(this.restServerUrl + '/api/school/checkMultipleLicenses', {
        master_license_keys: license,
      })
      .pipe(tap((res) => console.log(res)));
  }

  getLicenseToStudentUpdateListener() {
    return this.studentLicensesUpdated.asObservable();
  }

  getLicenseToStudent(studentId) {
    this.http
      .get<
        License[]
      >(this.restServerUrl + '/api/student/getLicensesToStudent/' + studentId)
      .subscribe({
        next: (data) => {
          this.studentLicensesUpdated.next([...data]);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  distributeLicenseToStudents(data) {
    return this.http.put<any>(
      this.restServerUrl + '/api/student/distributeLicenseToStudents',
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  releaseLicensesFromStudent(data) {
    return this.http.post<any>(
      this.restServerUrl + '/api/student/releaseLicensesFromStudent',
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  requestStudentLicenses(data) {
    return this.http
      .post<any>(
        this.restServerUrl + '/api/school/requestStudentLicenses',
        data,
      )
      .pipe(tap((res) => console.log(res)));
  }

  getRequestsToSchoolUpdateListener() {
    return this.requestsToSchoolUpdated.asObservable();
  }

  getRequestsToTeacherUpdateListener() {
    return this.requestsToTeacherUpdated.asObservable();
  }

  getRequestsToSchool(school_id?) {
    console.log(school_id);
    let request = this.restServerUrl + '/api/school/getRequestsToSchool';
    if (school_id !== undefined) {
      request += '?school_id=' + school_id;
    }
    console.log('getRequestsToSchool');
    this.http.get<License[]>(request).subscribe({
      next: (data) => {
        this.requestsToSchool = data;
        this.requestsToSchoolUpdated.next([...this.requestsToSchool]);
      },
      error: (error) => {
        //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
      },
    });
  }

  getRequestsToTeacher() {
    this.http
      .get<License[]>(this.restServerUrl + '/api/teacher/getRequestsToTeacher')
      .subscribe({
        next: (data) => {
          this.requestsToTeacher = data;
          this.requestsToTeacherUpdated.next([...this.requestsToTeacher]);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  updateSingleRequest(data) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/school/updateSingleRequest',
      data,
    );
  }

  activateLicenses(master_license_key) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/school/activateLicenses',
      { master_license_key: master_license_key },
    );
  }
}
