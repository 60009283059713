<app-school-admin-create-no-school-stepper
  class="school_admin-stepper"
  #stepper
  [linear]="true"
  [translatedText]="translatedText"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
>
  <!-- STEP 1 -->
  <cdk-step [stepControl]="personalForm" #step1="cdkStep">
    <div class="school_admin-wrap">
      <div class="non_school_register_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_personal_information }}
        </h2>
      </div>
      <form [formGroup]="personalForm" #formDirective="ngForm">
        <div class="form-wrapper">
          <div class="input-flex">
            <div class="input-flex-item">
              <p>{{ translatedText.school_admin_create_firstname }}</p>
              <mat-form-field floatLabel="always">
                <input
                  matInput
                  formControlName="firstname"
                  type="text"
                  required
                  placeholder="{{ translatedText.school_admin_create_firstname }}"
                />
                <mat-error *ngIf="personalForm.get('firstname').hasError('required')">
                  {{ translatedText.school_admin_create_firstname_error }}
                </mat-error>
                <mat-error *ngIf="personalForm.get('firstname').hasError('hasDigits')">
                  {{ translatedText.school_admin_create_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-flex-item">
              <p>{{ translatedText.school_admin_create_lastname }}</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="lastname"
                  type="text"
                  required
                  placeholder="{{ translatedText.school_admin_create_lastname }}"
                />
                <mat-error *ngIf="personalForm.get('lastname').hasError('required')">
                  {{ translatedText.school_admin_create_lastname_error }}
                </mat-error>
                <mat-error *ngIf="personalForm.get('lastname').hasError('hasDigits')">
                  {{ translatedText.school_admin_create_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <p>{{ translatedText.school_admin_create_salutation }}</p>
            <mat-form-field>
              <input
                matInput
                aria-label="Anrede"
                [matAutocomplete]="auto"
                formControlName="salutation"
                required
                placeholder="{{ translatedText.school_admin_create_salutation }}"
              />
              <mat-icon
                class="register-icon"
                matTooltipClass="salutation-tooltip"
                matTooltip="{{ translatedText.admin_teacher_create_salutation_tooltip }}"
                svgIcon="streamline_outlined:hint"
              ></mat-icon>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  <span>{{ item }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="personalForm.get('salutation').hasError('required')">
                {{ translatedText.school_admin_create_salutation_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-width">
            <p>{{ translatedText.school_admin_create_email }}</p>
            <mat-form-field>
              <input
                matInput
                formControlName="email"
                type="text"
                required
                placeholder="{{ translatedText.school_admin_create_email }}"
              />
              <mat-error *ngIf="personalForm.get('email').hasError('required')">
                <!-- Please enter email address. -->
                {{ translatedText.school_admin_create_email_error }}
              </mat-error>
              <mat-error *ngIf="personalForm.get('email').hasError('invalidEmail')">
                <!-- Please enter valid email address. -->
                {{ translatedText.school_admin_create_email_error_valid }}
              </mat-error>
              <mat-error *ngIf="personalForm.get('email').hasError('emailTaken')">
                <!-- Email is taken -->
                {{ translatedText.school_admin_create_email_taken }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="non_school_register_select_button step_1">
        <button [disabled]="personalForm.invalid" mat-flat-button color="primary" (click)="EmailAvailabilty()">
          {{ translatedText.school_admin_create_next }}
        </button>
        <div class="footer-wrap">
          <p class="paragraph">
            {{ translatedText.school_admin_create_already_account }}
          <a class="links" [routerLink]="['/login/teacher']">{{ translatedText.school_admin_create_login }}</a>
          </p>
        </div>
        <img [src]="'../../../assets/' + environment.whitelabel.logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
  <!-- STEP 3 -->
  <cdk-step [stepControl]="confirmStep">
    <div class="school_admin-wrap school_admin-wrap--full-height wider">
      <div class="non_school_register_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_check_data }}
        </h2>
      </div>

      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_admin_information }}
        </p>
        <div class="confirm_info-box">
          <div class="row first_row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_salutation }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('salutation').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_firstname }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('firstname').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_lastname }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('lastname').value }}
              </p>
            </div>
            <button
              mat-icon-button
              class="confirm_info-edit-button"
              color="filled"
              aria-label="edit"
              (click)="navigateToPersonalInfo()"
            >
              <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
            </button>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_email }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('email').value }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_license_information_trial }}
        </p>
        <div class="confirm_info-box">
          <div class="row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_diagnostic_license }}
              </p>
              <p class="paragraph paragraph--bold">10</p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_training_license }}
              </p>
              <p class="paragraph paragraph--bold">10</p>
            </div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>

      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_school_information }}
        </p>
        <div class="confirm_info-box">
          <button
            mat-icon-button
            class="confirm_info-edit-button"
            color="filled"
            aria-label="edit"
            (click)="navigateToSchoolSelection()"
          >
            <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
          </button>
          <div class="row first_row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_name }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ schoolForm.get('name').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_create_school_state }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ selectedState }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_city }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ schoolForm.get('zip').value }} {{ schoolForm.get('city').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_address }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ schoolForm.get('address').value }}
              </p>
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="privacy-checkbox-wrap">
        <mat-checkbox #privacyCheckbox class="example-margin"></mat-checkbox>
        <p [innerHTML]="legalMessage"></p>
      </div>
      <div class="non_school_register_select_button confirm-step">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isLoading || !privacyCheckbox.checked"
          mat-flat-button
          (click)="submitForm(formDirective)"
        >
          <span>{{ translatedText.school_admin_create_finish_registration }}</span></button
        ><img [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
  <!-- STEP 5 -->
  <cdk-step [stepControl]="lastStep">
    <div class="school_admin-wrap last-step">
      <img src="../../../../assets/sign-up.svg" alt="sign-up" />
      <h2 class="h2 h2--bold">
        {{ translatedText.school_admin_create_send_email_firstpart }}
        {{ maskEmail(personalForm.get('email').value) }}
        {{ translatedText.school_admin_create_send_email_secondpart }}
      </h2>
      <p class="paragraph paragraph--xl">
        {{ translatedText.school_admin_create_send_email_info }}
      </p>
      <div class="non_school_register_select_button resend-email">
        <button mat-flat-button color="primary" (click)="resendRegistrationEmail()">
          {{ translatedText.school_admin_create_send_email_resend }}
        </button>
        <img  [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
</app-school-admin-create-no-school-stepper>
