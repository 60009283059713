<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="video-overlay-container">
        <button
          mat-mini-fab
          type="button"
          [mat-dialog-close]="'close'"
          class="close-button close-button--right"
          matSuffix
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
        </button>
        <h1 class="h3 h3--bold">{{ title }}</h1>
        <div class="video-overlay-wrap">
          <video controls>
            <source [src]="url" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</div>
