<div>
  <div class="teacher-content-container teacher-content-container--diff-margin">
    <app-license-activation-banner></app-license-activation-banner>
    
    <h3 class="h3 h3--bold">{{ translatedText.dashboard_tasks_title }}</h3>
    <div class="teacher_tasks_container">
      <div class="teacher_tasks_wrap" [@.disabled]="true">
        <app-teacher-task-list
          [selectedView]="selectedTaskView"
          [status]="'active'"
          (totalCounts)="setTotalCounts($event)"
        ></app-teacher-task-list>
      </div>
    </div>
  </div>
</div>
