// dymmydata
export let lineChart = [
  {
    name: 'Leseflüssigkeit (Förderung)',
    series: [
      {
        name: 'Jul 23',
        value: 16.1875,
      },
      {
        name: 'Jun 23',
        value: 0,
      },
      {
        name: 'Mai 23',
        value: 0,
      },
      {
        name: 'Apr 23',
        value: 0,
      },
      {
        name: 'Mär 23',
        value: 0,
      },
      {
        name: 'Feb 23',
        value: 0,
      },
      {
        name: 'Jan 23',
        value: 0,
      },
      {
        name: 'Dez 22',
        value: 0,
      },
      {
        name: 'Nov 22',
        value: 0,
      },
      {
        name: 'Okt 22',
        value: 0,
      },
      {
        name: 'Sep 22',
        value: 0,
      },
      {
        name: 'Aug 22',
        value: 0,
      },
    ],
  },
];

export let barChart: any = [
  {
    name: 'Dez 22',
    value: 0,
  },
  {
    name: 'Jan 23',
    value: 0,
  },
  {
    name: 'Feb 23',
    value: 0,
  },
  {
    name: 'Mär 23',
    value: 0,
  },
  {
    name: 'Mai 23',
    value: 0,
  },
  {
    name: 'Apr 23',
    value: 0,
  },
];

export let barChartGroup: any = [
  {
    name: 'Mär 23',
    series: [
      {
        name: 'Diagnose 1',
        value: 50,
      },
      {
        name: 'Diagnose 2',
        value: 100,
      },
    ],
  },
  {
    name: 'Apr 23',
    series: [
      {
        name: 'Diagnose 1',
        value: 80,
      },
      {
        name: 'Diagnose 1',
        value: 90,
      },
    ],
  },
  {
    name: 'Mai 23',
    series: [],
  },
  {
    name: 'Jun 23',
    series: [],
  },
  {
    name: 'Jul 23',
    series: [],
  },
  {
    name: 'Aug 23',
    series: [],
  },
  {
    name: 'Sep 23',
    series: [],
  },
  {
    name: 'Okt 23',
    series: [],
  },
  {
    name: 'Nov 23',
    series: [],
  },
  {
    name: 'Dez 23',
    series: [],
  },
  {
    name: 'Jan 24',
    series: [],
  },
];
