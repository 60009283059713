import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { ForgotPasswordOverlayComponent } from './forgot-password-overlay.component';

@NgModule({
  declarations: [ForgotPasswordOverlayComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [ForgotPasswordOverlayComponent],
})
export class ForgotPasswordOverlayModule {}
