import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from '../../material.module';
import { StudentPdfOverlayInfoComponent } from './student-pdf-overlay-info.component';
@NgModule({
  declarations: [StudentPdfOverlayInfoComponent],
  imports: [BrowserModule, CommonModule, MaterialModule, LoadingSpinnerModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [StudentPdfOverlayInfoComponent],
})
export class StudentPdfOverlayInfoModule {}
