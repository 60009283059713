<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="row">
      <div class="col-md-12">
        <div class="training-task_container">
          <div class="step_content">
            <div class="training-task_wrap" [style.display]="selected ? 'block' : 'none'">
              <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
