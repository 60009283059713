import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SessionUploadOverlayComponent } from './session-upload-overlay.component';
import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        SessionUploadOverlayComponent
    ],
    imports: [
        BrowserModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [],
    schemas: [],
    bootstrap: [],
    exports: [SessionUploadOverlayComponent]
})
export class SessionUploadOverlayModule {}
