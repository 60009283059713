<div class="student-delete_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="student-delete_wrap">
    <h2 class="h2 student-delete_header">
      {{ translatedText.t('student_delete_dialog', { studentname: studentName }) }}
    </h2>
    <p class="paragraph paragraph--xl">{{ translatedText.student_delete_desc }}</p>
    <div class="button_wrap">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
        {{ translatedText.student_delete_cancel }}
      </button>
      <button mat-flat-button color="warning" type="button" class="student-delete_button" (click)="deleteStudent()">
        {{ translatedText.student_delete_accept }}
      </button>
    </div>
  </div>
</div>
