import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { BookListModule } from 'src/app/books/book-list/book-list.module';
import { BookPreviewModule } from 'src/app/books/book-preview/book-preview.module';
import { BookViewModule } from 'src/app/books/book-view/book-view.module';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from 'src/app/material.module';
import { TrainingTaskStepperComponent } from '../training-task-stepper/training-task-stepper.component';
import { TrainingTaskCreateComponent } from './training-task-create.component';
import { TrialBannerModule } from 'src/app/trial-phase/trial-banner/trial-banner.module';

@NgModule({
  declarations: [TrainingTaskCreateComponent, TrainingTaskStepperComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    BookListModule,
    BookPreviewModule,
    BookViewModule,
    TrialBannerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TrainingTaskCreateComponent],
})
export class TrainingTaskCreateModule {}
