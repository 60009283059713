import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { StudentOverviewComponent } from './student-overview.component';
import { TaskListComponent } from '../../task/task-list/task-list.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DirectivesModule } from '../../directives/directives.module';
import { ComboChartModule } from '../../helpers/combo-chart/combo-chart.module';

@NgModule({
  declarations: [StudentOverviewComponent, TaskListComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    DirectivesModule,
    ComboChartModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [StudentOverviewComponent],
})
export class StudentOverviewModule {}
