<button
  mat-icon-button
  type="button"
  class="circle light"
  (click)="closeOverlay()"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<div class="task-repeat_overlay_container">
  <div class="task-repeat__overlay_wrap">
    <img src="../../../assets/task_repeat.svg" alt="" />
    <h2 class="h2 h2--bold">{{ translatedText.task_repeat_title }}</h2>
    <p class="paragraph paragraph--l">
      {{ translatedText.task_repeat_description }}
    </p>
    <form [formGroup]="rForm" #formDirective="ngForm">
      <mat-form-field>
        <mat-label>TT/MM/JJJJ</mat-label>
        <input
          matInput
          [min]="todayDate"
          onkeypress="return false"
          formControlName="deadline"
          type="text"
          (click)="deadlinepicker.open()"
          [matDatepicker]="deadlinepicker"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="deadlinepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #deadlinepicker></mat-datepicker>
        <mat-error *ngIf="rForm.get('deadline').hasError('required')">
          <!-- Please choose a deadline. -->
          {{ translatedText.task_repeat_deadline_error }}
        </mat-error>
      </mat-form-field>
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.task_repeat_next }}
      </button>
    </form>
  </div>
</div>
