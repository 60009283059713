<div class="archivment-overlay_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="true" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <h2 class="h2 h2--bold">{{ translatedText.archivments_overlay_how }}</h2>
  <div class="row">
    <div class="col-md-12">
      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_bookworm }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_bookworm_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/bookworm-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_500_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_500_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_1000_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_3000_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_6000_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_10000_words }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/bookworm-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_15000_words }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_prof }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_prof_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/professor-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_1_book }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_1_book }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_3_books }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_5_books }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_10_books }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_15_books }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/professor-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_20_books }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_genie }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_genie_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/perfectionist-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_3 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_5 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_10 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_15 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/perfectionist-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_90_points_20 }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_montain }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_montain_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/montaineer-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_3 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_5 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_10 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_15 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/montaineer-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_test_20 }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_timer }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_timer_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/ontime-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description short-text">
                {{ translatedText.archivments_overlay_task_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_5 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_10 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_15 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_20 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/ontime-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_task_50 }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="archivment">
                <div class="archivment-header">
                    <h3 class="h3 h3--bold">Überflieger</h3>
                    <p class="paragraph paragraph--l">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                </div>
                <div class="archivment-body">
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_none}}</p>
                        <div class="award_img disabled"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-0.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">Länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_starter}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-20.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">Länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_kupfer}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-40.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">3 Mal länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_bronze}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-60.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">5 Mal länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_gold}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-80.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">10 Mal länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_platin}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-90.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">15 Mal länger lesen</p>
                        </div>
                    </div>
                    <div class="student-award">
                        <p class="paragraph paragraph--bold paragraph--s award-header">{{translatedText.archivments_overlay_diamant}}</p>
                        <div class="award_img"> 
                            <img class="award_earned" src="../../../assets/awards/ontime-100.svg" alt="">
                            <p class="paragraph paragraph--bold paragraph--s award-description">20 Mal länger lesen</p>
                        </div>
                    </div>
                </div>
            </div> -->

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_emphasis }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_emphasis_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_3 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_5 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_10 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_15 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/withEmphasis-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_emphasis_20 }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="archivment">
        <div class="archivment-header">
          <h3 class="h3 h3--bold">{{ translatedText.archivments_overlay_tempo }}</h3>
          <p class="paragraph paragraph--l">{{ translatedText.archivments_overlay_tempo_desc }}</p>
        </div>

        <div class="archivment-body">
          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header">
              {{ translatedText.archivments_overlay_none }}
            </p>
            <div class="award_img disabled">
              <img class="award_earned" src="../../../assets/awards/inTempo-0.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_starter }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-20.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_1 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_kupfer }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-40.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_3 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_bronze }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-60.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_5 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_gold }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-80.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_10 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_platin }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-90.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_15 }}
              </p>
            </div>
          </div>

          <div class="student-award">
            <p class="paragraph paragraph--bold paragraph--s award-header short-text">
              {{ translatedText.archivments_overlay_diamant }}
            </p>
            <div class="award_img">
              <img class="award_earned" src="../../../assets/awards/inTempo-100.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--s award-description">
                {{ translatedText.archivments_overlay_tempo_20 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
