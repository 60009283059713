import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { BookPreviewStudentComponent } from './book-preview-student.component';

@NgModule({
  declarations: [BookPreviewStudentComponent],
  imports: [BrowserModule, CommonModule, MaterialModule, AppRoutingModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [BookPreviewStudentComponent],
})
export class BookPreviewStudentModule {}
