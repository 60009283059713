import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
  ) {
    // Register icon sets
    this._matIconRegistry.addSvgIconSetInNamespace(
      'streamline_outlined',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/streamline-outlined.svg',
      ),
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'streamline_filled',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/streamline-filled.svg',
      ),
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'flags',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/flags.svg',
      ),
    );
  }
}
