import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { GroupService } from '../../providers/group.service';
import { StudentService } from '../../providers/student.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TaskCreateComponent } from '../../task/task-create/task-create.component';
import { ClassDeleteComponent } from '../class-delete/class-delete.component';
import { ClassPdfPrintComponent } from '../class-pdf-print/class-pdf-print.component';
import { DiagnosticCreateComponent } from '../../diagnostic/diagnostic-create/diagnostic-create.component';
import * as shape from 'd3-shape';
import { barChart, lineChart } from './chartData';
import { StudentsEditComponent } from 'src/app/student/students-edit/students-edit.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export interface Group {}

export interface Students {
  _id: string;
  username: string;
  progress: number;
  readingPoints: number;
  readingError: number;
  speed: string;
  tendency: boolean;
  complete: boolean;
  gender: string;
}

@Component({
  selector: 'app-class-detail',
  templateUrl: './class-detail.component.html',
  styleUrls: ['./class-detail.component.scss'],
})
export class ClassDetailComponent implements OnInit {
  restServerUrl;
  class;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  displayedColumns: string[] = [
    'username',
    'reading_combined_score',
    'reading_errors_score',
    'reading_speed_words',
    'reading_speed_correct_words_per_minute',
    'diagnostic',
    'numberOfTasks',
    /*'tendency' ,*/ 'button',
  ];
  displayedColumnsDiagnostic: string[] = [
    'username',
    'reading_combined_score',
    'reading_errors_score',
    'reading_speed_words',
    'reading_speed_correct_words_per_minute',
    'diagnostic',
    'numberOfTasks',
    /*'tendency' ,*/ 'button',
  ];
  dataSource: any;
  dataSourceDiagnostic: any;
  @ViewChild('sortTraining') sortTraining: MatSort;
  @ViewChild('sortDiagnostic') sortDiagnostic: MatSort;
  sortedData: Students[];
  id;
  teacherId;
  students: Students[] = [];
  groupTasks = [];
  groupname: string;
  school_year: string;
  totalReadingScore: number = 0;
  studentCount: number = 0;
  activeTab = 'isActive';
  // options CHART
  view: any[] = [1000, 260];
  legend: boolean = false;
  legendTitle: string = '';
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  //showYAxisLabel: boolean = false;
  //showXAxisLabel: boolean = false;
  timeline: boolean = true;
  legendPosition: string = 'below';
  linearCurve = shape.curveBumpX;
  colorScheme = {
    domain: ['#03cc90'],
  };
  chartData;
  showProgressChart: boolean = false;
  progressChartData = [
    {
      name: 'korrekte Wörter pro Minute',
      series: [],
    },
  ];
  comboChartData;
  showComboChart: boolean = false;
  showLineChart: boolean = false;
  lineChartData = [
    {
      name: 'Leseflüssigkeit (Förderung)',
      series: [],
    },
  ];
  selectedGroupResult;
  currentValue;
  currentTablePeriod: string = 'daily';
  currentChartPeriod: string = 'last_week';
  currentChartSettings: string = 'reading_speed_correct_words_per_minute';
  group;
  groupGrade;
  traningArray;
  isMobile: boolean = false;
  // combo chart
  // Chart configuration
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  yAxisLabel = 'Leseflüssigkeit (Diagnose)';
  showGridLines = true;
  innerPadding = '10%';
  barChart: any;
  lineChartSeries: any[] = lineChart;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#59D7AF'],
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#59D7AF'],
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';
  selectedLicenseView: string = 'license-2';
  readingPointsInfoText: string =
    'Lesepunkte sind der kumulierte Wert von Lesegeschwindigkeit, Anzahl der Fehler, und der Stimmdynamik. Die Lesepunkte können einen Wert von 0 bis 100 annehmen und bilden im '+ environment.whitelabel.name +' somit eine vereinfachte, übergeordnete Kennzahl, um die Leseflüssigkeit in einer einzigen Zahl abzubilden. \n\n Diese Zahl wird zukünftig mit Parametern angepasst, die wir zusätzlich einführen werden.';
  readingErrorInfoText: string =
    'Wir berechnen die Anzahl der Fehler, indem wir die Audioanalyse mit dem tatsächlichen Text vergleichen und Abweichungen identifizieren. \n\n Auslassungen: Wenn Leser ein Wort überspringen. \n\n Ersetzungen: Wenn Leser ein Wort durch ein anderes Wort ersetzten. \n\n Hinzufügungen: Wenn Leser ein Wort hinzufügen, das nicht im Originaltext enthalten ist. \n\n Die Werte werden weiterhin aktualisiert.';
  readingSpeedInfoText: string =
    'Die Lesegeschwindigkeit wird berechnet durch Wörter pro Minute (WPM) geteilt durch die Lesezeit. \n\n Die Werte werden weiterhin aktualisiert.';
  readingWordsCorrectPerMinuteInfoText: string =
    'Die Leseflüssigkeit wird berechnet durch "korrekte Wörter pro Minute" (WCPM).';
  readingLevelInfoText: string =
    'Der Parameter für die Lesekompetenz "korrekte Wörter pro Minute" (WCPM) ist in fünf Perzentile aufgeteilt und stellt die fünf Lesekompetenzlevels dar. Diese Lesekompetenzlevels gibt es für jede Klassenstufe. Ihnen werden die einzelnen Ergebnisse der Lernende zugeordnet.';
  translatedText;
  constructor(
    private authService: AuthService,
    private location: Location,
    private groupService: GroupService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private studentService: StudentService,
    public router: Router,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.lineChartData[0].name =
          this.translatedText?.class_detail_chart_training;
        this.yAxisLabel = this.translatedText?.class_detail_chart_diagnostic;
        this.progressChartData[0].name =
          this.translatedText?.class_detail_chart_progress;
        this.cdr.detectChanges();
      });
    this.teacherId = this.authService.getTeacherId();
    this.id = this.route.snapshot.params['id'];
    this.groupService.getGroupWithInfo(this.id, this.teacherId);
    this.groupService
      .getGroupWithUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.group = res;
        this.groupname = res['name'];
        this.groupGrade = res['grade'];
        this.school_year = res['school_year'];
        this.totalReadingScore = res['totalReadingScore'];
        this.students = res['students'];
        this.students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
        this.studentCount = this.students.length;

        if (res['bar_chart_data']) {
          this.comboChartData = res['bar_chart_data'];
          if (
            this.comboChartData?.pre_test?.monthly?.reading_combined_score
              .length > 0
          ) {
            this.barChart =
              this.comboChartData?.pre_test?.monthly?.reading_combined_score;
            this.lineChartData[0].series =
              this.comboChartData?.studying?.monthly?.reading_combined_score;
            this.showComboChart = true;
            this.showLineChart = true;
          }
        }

        if (res['chart_data']) {
          this.chartData = res['chart_data'];
          if (this.chartData?.daily?.reading_combined_score.length > 0) {
            this.progressChartData[0].series =
              this.chartData?.daily[this.currentChartSettings]; // last 7 days
            this.progressChartData = [...this.progressChartData];
            this.showProgressChart = true;
          }
        }
        this.dataSource = new MatTableDataSource<Students>(this.students);
        this.dataSource.sort = this.sortTraining;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'username':
              return item.username;
            case 'reading_combined_score':
              return item.reading_score[this.currentTablePeriod]
                ?.reading_combined_score;
            case 'reading_errors_score':
              return item.reading_score[this.currentTablePeriod]
                ?.reading_errors_score;
            case 'reading_speed_words':
              return item.reading_score[this.currentTablePeriod]
                ?.reading_speed_words;
            case 'reading_speed_correct_words_per_minute':
              return item.reading_score[this.currentTablePeriod]
                ?.reading_speed_correct_words_per_minute;
            case 'numberOfTasks':
              return item.numberOfTasks.training[this.currentTablePeriod];
            default:
              return item[property];
          }
        };
        this.dataSourceDiagnostic = new MatTableDataSource<Students>(
          this.students,
        );
        this.dataSourceDiagnostic.sort = this.sortDiagnostic;
        this.dataSourceDiagnostic.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'username':
              return item.username;
            case 'reading_combined_score':
              return this.combineDiagnosticScore(
                item.numberOfTasks.diagnostic['monthly'],
                item?.diagnose_score,
                'reading_combined_score',
              );
            case 'reading_errors_score':
              return this.combineDiagnosticScore(
                item.numberOfTasks.diagnostic['monthly'],
                item?.diagnose_score,
                'reading_errors_score',
              );
            case 'reading_speed_words':
              return this.combineDiagnosticScore(
                item.numberOfTasks.diagnostic['monthly'],
                item?.diagnose_score,
                'reading_speed_words',
              );
            case 'reading_speed_correct_words_per_minute':
              return this.combineDiagnosticScore(
                item.numberOfTasks.diagnostic['monthly'],
                item?.diagnose_score,
                'reading_speed_correct_words_per_minute',
              );
            case 'numberOfTasks':
              return item.numberOfTasks.diagnostic['monthly'];
            default:
              return item[property];
          }
        };
      });

    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  combineDiagnosticScore(numberOfTasks, diagnose_score, type) {
    if (
      diagnose_score['pre_test'][type] != '-' ||
      diagnose_score['mid_test'][type] != '-' ||
      diagnose_score['post_test'][type] != '-'
    ) {
      let preScore =
        diagnose_score['pre_test'][type] == '-'
          ? 0
          : diagnose_score['pre_test'][type];
      let midScore =
        diagnose_score['mid_test'][type] == '-'
          ? 0
          : diagnose_score['mid_test'][type];
      let postScore =
        diagnose_score['post_test'][type] == '-'
          ? 0
          : diagnose_score['post_test'][type];
      let totalScore = preScore + midScore + postScore;
      return totalScore > 0 && numberOfTasks > 0
        ? Math.round(totalScore / numberOfTasks)
        : 0;
    } else {
      return '-';
    }
  }

  chechStudentsGender() {
    return this.students.filter((item) => item.gender == '').length;
  }

  editStudentsOverlay() {
    let dialogRef = this.dialog.open(StudentsEditComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        groupName:
          this.group?.grade +
          this.group?.stream +
          '-' +
          this.group?.school_year,
        students: this.students.filter((item) => item.gender == ''),
        groupGrade: this.group?.grade,
        groupId: this.group?._id,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.groupService.getGroupWithInfo(this.id, this.teacherId);
      });
  }

  selectedResult(event) {
    this.currentChartPeriod = event;
    if (this.currentChartPeriod == 'last_week') {
      this.progressChartData[0].series =
        this.chartData?.daily[this.currentChartSettings]; // last 7 days
      this.progressChartData = [...this.progressChartData];
      // Update table
      this.currentTablePeriod = 'daily';
      //this.showProgressChart = false;
    }
    if (this.currentChartPeriod == 'last_month') {
      this.progressChartData[0].series = this.chartData?.weekly[
        this.currentChartSettings
      ].slice(8, this.chartData?.weekly[this.currentChartSettings].length); // last 4 weeks
      console.log(this.progressChartData[0].series);
      if (environment.language == 'en') {
        for (let i = 0; i < this.progressChartData[0].series.length; i++) {
          this.progressChartData[0].series[i].name =
            this.progressChartData[0].series[i].name.replace('KW', 'Week');
        }
      }
      this.progressChartData = [...this.progressChartData];
      //this.showProgressChart = true;
      // Update table
      this.currentTablePeriod = 'weekly';
    }
    if (this.currentChartPeriod == 'half_year') {
      console.log(this.currentChartSettings);
      this.progressChartData[0].series = this.chartData?.monthly[
        this.currentChartSettings
      ].slice(6, this.chartData?.monthly[this.currentChartSettings].length); // last 6 month
      this.progressChartData = [...this.progressChartData];
      console.log(this.progressChartData);
      if (environment.language == 'en') {
        this.progressChartData[0].series.forEach((item) => {
          switch (item.name) {
            case 'Aug 23':
              item.name = 'Aug 23';
              break;
            case 'Sep 23':
              item.name = 'Sep 23';
              break;
            case 'Okt 23':
              item.name = 'Oct 23';
              break;
            case 'Nov 23':
              item.name = 'Nov 23';
              break;
            case 'Dez 23':
              item.name = 'Dec 23';
              break;
            case 'Jan 24':
              item.name = 'Jan 24';
              break;
            case 'Feb 24':
              item.name = 'Feb 24';
              break;
            case 'Mär 24':
              item.name = 'Mar 24';
              break;
            case 'Apr 24':
              item.name = 'Apr 24';
              break;
            case 'Mai 24':
              item.name = 'May 24';
              break;
            case 'Jun 24':
              item.name = 'Jun 24';
              break;
            case 'Jul 24':
              item.name = 'Jul 24';
              break;
            case 'Aug 24':
              item.name = 'Aug 24';
              break;
            case 'Sep 24':
              item.name = 'Sep 24';
              break;
            case 'Okt 24':
              item.name = 'Oct 24';
              break;
            case 'Nov 24':
              item.name = 'Nov 24';
              break;
            case 'Dez 24':
              item.name = 'Dec 24';
              break;
          }
        });
      }
      //this.showProgressChart = true;
      // Update table
      this.currentTablePeriod = 'monthly';
    }
  }

  onSelectedLicenseViewChange(value) {
    this.selectedLicenseView = value;
  }

  selectedResultTable(event) {
    this.currentTablePeriod = event;
    // Update chart
    if (this.currentTablePeriod == 'daily') {
      this.selectedResult('last_week');
    }
    if (this.currentTablePeriod == 'weekly') {
      this.selectedResult('last_month');
    }
    if (this.currentTablePeriod == 'monthly') {
      this.selectedResult('half_year');
    }
  }

  selectedChartSettings(event) {
    this.currentChartSettings = event;
    switch (event) {
      case 'reading_speed_correct_words_per_minute':
        this.progressChartData[0].name =
          this.translatedText?.class_detail_chart_progress;
        break;
      case 'reading_combined_score':
        this.progressChartData[0].name =
          this.translatedText?.class_detail_chart_reading_points;
        break;
      case 'reading_errors_score':
        this.progressChartData[0].name =
          this.translatedText?.class_detail_chart_reading_errors;
        break;
      case 'reading_speed_words':
        this.progressChartData[0].name =
          this.translatedText?.class_detail_chart_wpm;
        break;
      default:
        break;
    }
    this.selectedResult(this.currentChartPeriod);
  }

  navigateBack(): void {
    this.location.back();
  }

  createTaskDialog(): void {
    let dialogRef = this.dialog.open(TaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-class-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-class-overlay-panel-backdrop',
      data: {
        students: this.students,
        singleGroup: true,
        group: this.group,
        groupGrade: this.groupGrade,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  openGroupDeleteDialog(): void {
    let dialogRef = this.dialog.open(ClassDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        groupId: this.id,
        groupName: this.groupname,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res != 'close') {
          this.location.back();
        }
      });
  }

  openStudentOverview(studentId): void {
    this.router.navigate(['/student-overview/' + studentId], {
      queryParams: { groupId: this.id },
    });
    console.log('function works AAAAAA');
  }

  openGroupRegeneratePDFDialog(): void {
    let dialogRef = this.dialog.open(ClassPdfPrintComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        groupId: this.id,
        groupName: this.groupname,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res != 'close') {
          // this.location.back()
        }
      });
  }

  createDiagnostic(type) {
    let dialogRef = this.dialog.open(DiagnosticCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-class-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-class-overlay-panel-backdrop',
      data: {
        type: type,
        students: this.students,
        singleGroup: true,
        group: this.group,
        groupGrade: this.groupGrade,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log(tabChangeEvent);
    if (tabChangeEvent.index === 0) {
      this.activeTab = 'isActive';
    } else if (tabChangeEvent.index === 1) {
      this.activeTab = 'isFinished';
    }
  }

  generateDiagnosticScore(diagnostic) {
    return this.studentService.generateDiagnosticScore(diagnostic);
  }

  chartToggleChange(value) {
    console.log(value.currentTarget.checked);
    // var line_chart = document.getElementsByClassName('line-chart');
    // if (value.currentTarget.checked == true) {
    //   line_chart[0].classList.add('line_chart_active');
    // } else {
    //   line_chart[0].classList.remove('line_chart_active');
    // }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
