import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { CreateClassComponent } from './create-class.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClassStepperComponent } from './class-stepper/class-stepper.component';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  declarations: [CreateClassComponent, ClassStepperComponent],
  exports: [CreateClassComponent],
})
export class CreateClassModule {}
