import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  forkJoin,
  from,
  map,
  Observable,
  of,
  takeUntil,
} from 'rxjs';
import { DSEF, DSEFContentWrapper } from 'digi-sapiens-reader';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { hyphenationColors } from '../book-reader/book-reader-view/book-reader-view.component';

@Injectable({
  providedIn: 'root',
})
export class BookDataService {
  private restServerUrl = environment.evolutionAPI;
  private bookReaderApiUrl = environment.BOOKREADERAPI_SERVER; // TODO: unused?
  private tableOfContents = [];
  private playOrder = [];
  private fullTextContent: string = '';
  private imageCount: number = 0;
  private currentPageAttributes;
  private contentLanguage: string = 'de';
  private startObj = { pageId: '', selectionContainerId: '', wordIndex: 0 };
  private endObj = { pageId: '', selectionContainerId: '', wordIndex: 0 };
  private bookmarkStartObj = {
    pageId: '',
    selectionContainerId: '',
    wordIndex: 0,
  };
  private dsefContent = [];
  private skipStarObj = false; // TODO: unused?
  private firstLoadedContent: string;
  private FontFamilyUpdated = new Subject<string>();
  private FontSizeUpdated = new Subject<string>();
  private LineHeightUpdated = new Subject<string>();
  private LetterSpacingUpdated = new Subject<string>();
  private ShowTableOfContentsUpdated = new BehaviorSubject<boolean>(false);
  private HyphenationModeUpdated = new Subject<string>();
  private wordCountUpdated = new BehaviorSubject<number>(0);
  private syllablesUpdated = new Subject<any>();
  private jumpToContentIdUpdated = new Subject<any>();
  private loadedhyphenationData = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  public fetchAllConvertedDSEFs(isbns, dsef_version?): Observable<DSEF[]> {
    const requests: Observable<DSEF | null>[] = [];
    isbns.forEach((isbn: string) => {
      const request = this.fetchDsef(isbn, dsef_version).pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          } else {
            throw error;
          }
        }),
      );
      requests.push(request);
    });

    return forkJoin(requests).pipe(
      map(
        (results: (DSEF | null)[]) =>
          results.filter((result) => result !== null) as DSEF[],
      ),
    );
  }

  public fetchDsef(isbn: string, dsef_version: number): Observable<DSEF> {
    return this.http.get<DSEF>(
      `${this.restServerUrl}/api/student/bookReaderByIsbn/${isbn}/?dsef_version=${dsef_version}`,
    );
  }

  public fetchContentOfDSEF(
    isbn: string,
    dsef_version: number,
    contentId: string,
    showHyphenation: boolean,
    fromComponent: string,
  ): Observable<DSEFContentWrapper> {
    if (
      showHyphenation ||
      fromComponent === 'bookview-student' ||
      fromComponent === 'record-rtc'
    ) {
      return this.http.get<DSEFContentWrapper>(
        `${this.restServerUrl}/api/student/bookReaderByIsbn/${isbn}/?contentId=${contentId}&syllables=true&dsef_version=${dsef_version}`,
      );
    } else {
      return this.http.get<DSEFContentWrapper>(
        `${this.restServerUrl}/api/student/bookReaderByIsbn/${isbn}/?contentId=${contentId}&dsef_version=${dsef_version}`,
      );
    }
  }

  public fetchHyphenRangeOfDSEF(isbn: string, dsef_version, range: string) {
    return this.http
      .get<any>(
        `${
          this.restServerUrl
        }/api/student/getSyllablesByRange/${isbn}/?range=${JSON.stringify(
          range,
        )}&dsef_version=${dsef_version}`,
      )
      .subscribe((data) => {
        this.loadedhyphenationData.push(...data);
        this.syllablesUpdated.next(this.loadedhyphenationData);
      });
  }

  public clearHyphenContent() {
    this.loadedhyphenationData = [];
    this.syllablesUpdated.next([]);
  }

  public fetchRangeContentOfDSEF(
    isbn,
    dsef_version,
    range,
    showHyphenation,
    markup,
  ) {
    // Trigger hyphen cntent
    if (showHyphenation) {
      this.fetchHyphenRangeOfDSEF(isbn, dsef_version, range);
    }
    const data = {
      isbn: isbn,
      range: JSON.stringify(range),
      syllables: showHyphenation,
      markup_json: JSON.stringify(markup),
    };
    return this.http.post<any>(
      `${this.restServerUrl}/api/student/bookReaderByIsbnRange/${isbn}?dsef_version=${dsef_version}`,
      data,
    );
  }

  public getSyllablesUpdated() {
    return this.syllablesUpdated.asObservable();
  }

  public getJumpToContentIdUpdated() {
    return this.jumpToContentIdUpdated.asObservable();
  }

  public setJumpToContentIdUpdated(contentId) {
    return this.jumpToContentIdUpdated.next(contentId);
  }

  public addDomainNameToSrc(contentWrapper: any, domain: string): any {
    if (contentWrapper?.content) {
      contentWrapper.content.forEach((item: any) => {
        if (item?.content) {
          this.addDomainNameToSrc(item, domain);
        }

        if (item?.type === 'img' && item?.src) {
          item.src = domain + '/' + item.src;
          // Calculate images
          this.increaseImageCount();
        }
        if (item?.type === 'svg' && item?.image?.src) {
          item.image.src = domain + '/' + item.image.src;
          if (item.image.x === undefined || item.image.x === '') {
            delete item.image.x;
          }
          if (item.image.y === undefined || item.image.y === '') {
            delete item.image.y;
          }
          // Calculate images
          this.increaseImageCount();
        }
      });
    }
    // fonts
    if (contentWrapper?.font_face) {
      contentWrapper.font_face.forEach((item: any) => {
        if (item?.src) {
          item.src = domain + '/' + item.src;
        }
      });
    }
    return contentWrapper;
  }

  getFullTextContent() {
    return this.fullTextContent;
  }

  public addTextContent(contentWrapper: any): DSEFContentWrapper {
    if (contentWrapper?.content) {
      contentWrapper.content.forEach((item: any) => {
        if (item?.content) {
          this.addTextContent(item);
        }

        if (item?.type === 'text' && item?.text) {
          this.fullTextContent += ' ' + item.text;
        }
      });
    }
    return contentWrapper;
  }

  public setLoadedDSEF(dsef) {
    this.dsefContent[dsef.content_detail.content_id] = dsef.content;
  }

  public resetLoadedDSEF() {
    this.dsefContent = [];
  }

  public resetTextContent() {
    // reset text
    this.fullTextContent = '';
    console.log(this.fullTextContent);
    // reset images
    this.imageCount = 0;
  }

  setCurrentPageAttributes(content_id) {
    if (content_id) {
      this.currentPageAttributes = {
        content_id: content_id,
      };
    }
  }

  getCurrentPageAttributes() {
    console.log(this.currentPageAttributes);
    return this.currentPageAttributes;
  }

  getImageCount() {
    return this.imageCount;
  }

  getFontFamilyUpdateListener() {
    return this.FontFamilyUpdated.asObservable();
  }

  setFontFamilyUpdateListener(value) {
    this.FontFamilyUpdated.next(this.getFontFamily(value));
  }

  getFontFamily(family) {
    let font_family = '';
    switch (family) {
      case 'abeezee':
        font_family = 'Abeezee';
        break;
      case 'poppins':
        font_family = 'Poppins';
        break;
      default:
        break;
    }
    return font_family;
  }

  getFontSizeUpdateListener() {
    return this.FontSizeUpdated.asObservable();
  }

  setFontSizeUpdateListener(value) {
    this.FontSizeUpdated.next(this.getFontSize(value));
  }

  getFontSize(size) {
    let font_size = '19px';
    switch (size) {
      case 'small':
        font_size = '19px';
        break;
      case 'normal':
        font_size = '21px';
        break;
      case 'big':
        font_size = '24px';
        break;
      default:
        break;
    }
    return font_size;
  }

  getLineHeightUpdateListener() {
    return this.LineHeightUpdated.asObservable();
  }

  setLineHeightUpdateListener(value) {
    this.LineHeightUpdated.next(this.getLineHeight(value));
  }

  getLineHeight(size) {
    let line_height = '160%';
    switch (size) {
      case 'small':
        line_height = '115%;';
        break;
      case 'normal':
        line_height = '160%';
        break;
      case 'big':
        line_height = '200%';
        break;
      default:
        break;
    }
    return line_height;
  }

  getLetterSpacingUpdateListener() {
    return this.LetterSpacingUpdated.asObservable();
  }

  setLetterSpacingUpdateListener(value) {
    this.LetterSpacingUpdated.next(this.getLetterSpacing(value));
  }

  getLetterSpacing(size) {
    let letter_spacing = '0px';
    switch (size) {
      case 'small':
        letter_spacing = '0px';
        break;
      case 'normal':
        letter_spacing = '1.5px';
        break;
      case 'big':
        letter_spacing = '2px';
        break;
      default:
        break;
    }
    return letter_spacing;
  }

  getHyphenationModeUpdateListener() {
    return this.HyphenationModeUpdated.asObservable();
  }

  setHyphenationModeUpdateListener(value: string) {
    this.waitForElm('#ds-reader')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((reader: HTMLElement) => {
        const index = value.slice(-1);
        const colors = hyphenationColors[index];
        reader?.style.setProperty('--s1-color', colors.s1);
        reader?.style.setProperty('--s2-color', colors.s2);
        this.HyphenationModeUpdated.next(value);
      });
  }

  private waitForElm(selector) {
    const elemObserver = new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });

    return from(elemObserver);
  }
  getShowTableOfContentsUpdateListener() {
    return this.ShowTableOfContentsUpdated.asObservable();
  }

  public getShowTableOfContents() {
    return this.ShowTableOfContentsUpdated.value;
  }

  setShowTableOfContentsUpdateListener(value) {
    this.ShowTableOfContentsUpdated.next(value);
  }

  public increaseImageCount() {
    this.imageCount++;
  }

  public setSkipStartObj(val) {
    this.skipStarObj = val;
  }

  public setFixedRangeForTask(start, end) {
    this.startObj = start;
    this.endObj = end;
    console.log({
      start: this.startObj,
      end: this.endObj,
    });
  }

  public setBookmarkStartObj(val) {
    this.bookmarkStartObj = val;
  }

  public checkForFurtherThenStart(start, end) {
    const oldIndex = this.findDsefContentIndexById(
      this.dsefContent[start.pageId],
      start.selectionContainerId,
    );
    // console.log(oldIndex)
    const newIndex = this.findDsefContentIndexById(
      this.dsefContent[end.pageId],
      end.selectionContainerId,
    );
    // Find the indexes of oldContentId and newContentId in the contentArray
    const startChapterIndex = this.playOrder.findIndex(
      (item) => item.content_id === start.pageId,
    );
    const endChapterIndex = this.playOrder.findIndex(
      (item) => item.content_id === end.pageId,
    );
    // Check if newContentId is further in the array than oldContentId
    if (
      this.compareDeeperDsefContent(oldIndex, newIndex) &&
      startChapterIndex <= endChapterIndex
    ) {
      console.log('---------NEW---------' + startChapterIndex + ' ---- ' + endChapterIndex)
      return true;
    }
    return false;
  }

  public checkForNewStart(start) {
    if (this.startObj.pageId === '') {
      return true;
    } else {
      return (
        this.isContainerBefore(start, this.endObj) &&
        this.isContainerBefore(start, this.startObj)
      );
    }
  }

  public isContainerBefore(a, b) {
    let oldLastContainerIdParts = b.selectionContainerId.split('_');
    let newLastContainerIdParts = a.selectionContainerId.split('_');

    let oldLastContainerId = Number(
      oldLastContainerIdParts.length > 1
        ? oldLastContainerIdParts[1]
        : oldLastContainerIdParts[0],
    );

    let newLastContainerId = Number(
      newLastContainerIdParts.length > 1
        ? newLastContainerIdParts[1]
        : newLastContainerIdParts[0],
    );

    // Find the indexes of oldContentId and newContentId in the contentArray
    const oldIndex = this.playOrder.findIndex(
      (item) => item.content_id === b.pageId,
    );
    const newIndex = this.playOrder.findIndex(
      (item) => item.content_id === a.pageId,
    );
    // Check if newContentId is further in the array than oldContentId
    return (
      newIndex < oldIndex ||
      (newIndex === oldIndex && newLastContainerId < oldLastContainerId) ||
      (newIndex === oldIndex &&
        newLastContainerId === oldLastContainerId &&
        a.wordIndex <= b.wordIndex)
    );
  }

  public checkForNewEnd(end) {
    if (this.endObj.pageId === '') {
      return true;
    } else {
      return (
        this.isContainerBefore(this.startObj, end) &&
        this.isContainerBefore(this.endObj, end)
      );
    }
  }

  public checkForFurtherThenEnd(oldEnd, newEnd) {
    if (Object.entries(oldEnd).length === 0) {
      return true;
    } else {
      const oldIndex = this.findDsefContentIndexById(
        this.dsefContent[oldEnd.pageId],
        oldEnd.selectionContainerId,
      );
      // console.log(oldIndex)
      const newIndex = this.findDsefContentIndexById(
        this.dsefContent[newEnd.pageId],
        newEnd.selectionContainerId,
      );
      // console.log(newIndex)
      // Find the indexes of oldContentId and newContentId in the contentArray
      const oldChapterIndex = this.playOrder.findIndex(
        (item) => item.content_id === oldEnd.pageId,
      );
      const newChapterIndex = this.playOrder.findIndex(
        (item) => item.content_id === newEnd.pageId,
      );
      if (
        this.compareDeeperDsefContent(oldIndex, newIndex) &&
        oldChapterIndex <= newChapterIndex
      ) {
        console.log('---------NEW---------' + oldChapterIndex + ' ---- ' + newChapterIndex)
        return true;
      }
      return false;
    }
  }

  findDsefContentIndexById(obj, idToFind) {
    function findRecursively(arr, idToFind, currentIndex) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.id === idToFind) {
          return currentIndex;
        }
        if (item.content && Array.isArray(item.content)) {
          const result = findRecursively(
            item.content,
            idToFind,
            currentIndex.concat(i),
          );
          if (result) {
            return result;
          }
        }
      }
      return null;
    }
    const rootIndex = findRecursively(obj, idToFind, []);
    return rootIndex || null; // Return null if not found
  }

  compareDeeperDsefContent(id1, id2) {
    const minLength = Math.min(id1.length, id2.length);

    for (let i = 0; i < minLength; i++) {
      if (id1[i] < id2[i]) {
        // id2 has higher numbers
        return true;
      } else if (id1[i] > id2[i]) {
        // id1 has higher numbers
        return false;
      }
    }

    if (id1.length < id2.length) {
      // id2 is deeper
      return true;
    } else if (id1.length > id2.length) {
      // id1 is deeper
      return false;
    } else {
      // ids are at the same level with the same numbers
      return false;
    }
  }

  public setAutoRangeForTask(start, end) {
    if (this.checkForNewStart(start) && start.pageId !== '') {
      this.startObj = start;
    }

    if (this.checkForNewEnd(end) && end.pageID !== '') {
      this.endObj = end;
    }
    console.log('-----------current range -----------');
    console.log({
      start: this.startObj,
      end: this.endObj,
    });
  }

  public resetRangeForTask() {
    this.startObj = { pageId: '', selectionContainerId: '', wordIndex: 0 };
    this.endObj = { pageId: '', selectionContainerId: '', wordIndex: 0 };
    this.skipStarObj = false;
  }

  public getRangeForTask() {
    return {
      start: this.startObj,
      end: this.endObj,
    };
  }

  public resetBookmark() {
    this.bookmarkStartObj = {
      pageId: '',
      selectionContainerId: '',
      wordIndex: 0,
    };
  }

  public getBookmarkStartObj() {
    return this.bookmarkStartObj;
  }

  public setFirstLoadedContent(contentId) {
    this.firstLoadedContent = contentId;
  }

  public getFirstLoadedContent() {
    return this.firstLoadedContent;
  }

  public getWordCountForTask() {
    return this.wordCountUpdated.value;
  }

  public setWordCount(count) {
    this.wordCountUpdated.next(count);
  }

  public getWordCountUpdateListener() {
    return this.wordCountUpdated.asObservable();
  }

  public setContentLanguage(language) {
    this.contentLanguage = language;
  }

  public getContentLanguage() {
    return this.contentLanguage;
  }

  public setPlayOrder(playOrder) {
    this.playOrder = playOrder;
  }

  public getPlayOrder(playOrder) {
    return this.playOrder;
  }

  public setTableOfContents(toc) {
    this.tableOfContents = toc;
  }

  public getTableOfContents() {
    return this.tableOfContents;
  }
}
