import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  environment = environment;
  rForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  resetToken;
  headline: string = '';
  passwordMinLength = 8;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _formBuilder: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any) => {
        this.translatedText = translatedText;
        let currentLocation = this.location.path();
        if (currentLocation.includes('/create-password')) {
          this.headline = this.translatedText?.change_student_password_title;
        } else if (currentLocation.includes('/reset-password')) {
          this.headline = this.translatedText?.change_student_password_reset;
        }
      });

    this.resetToken = this.route.snapshot.params['resetToken'];
    // Form init
    this.rForm = this._formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            //Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
            Validators.pattern(
              '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-_+={}\\[\\]|;:\'",.<>?]).{8,30})',
            ),
          ],
        ],
        confirmPassword: [''],
      },
      { validators: this.checkPassword },
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  navigateToLogin(): void {
    this.router.navigate(['/login/teacher']);
  }

  checkPassword: ValidatorFn = (
    group: AbstractControl,
  ): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    if (group.get('password').valid && confirmPass !== '') {
      return pass === confirmPass ? null : { notSame: true };
    } else {
      return null;
    }
  };

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }

    this.authService.createPassword(this.resetToken, form.value.password);
  }
}
