import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router) {}

  public initializeGA() {
    // dynamically add analytics scripts to document head
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.setAttribute('data-cookieconsent', 'statistics');
      gTagScript.type = 'text/plain';
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.gaTrackId}`;
      document.head.appendChild(gTagScript);
      console.log('GTAG init');
      const dataLayerScript = document.createElement('script');
      dataLayerScript.setAttribute('data-cookieconsent', 'statistics');
      dataLayerScript.type = 'text/javascript';
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.gaTrackId}');`;
      document.head.appendChild(dataLayerScript);
      // listen to route change
      this.onRouteChange();
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  // track visited routes
  private onRouteChange() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('GTAG route page view ' + event.urlAfterRedirects);
        this.gaPageView(event.urlAfterRedirects);
      });
  }

  private gaPageView(page_path) {
    const dataGtagScript = document.createElement('script');
    dataGtagScript.setAttribute('data-cookieconsent', 'statistics');
    dataGtagScript.type = 'text/javascript';
    dataGtagScript.innerHTML = `
      gtag('event', 'page_view', {
        page_path: '${page_path}'
      });`;
    document.head.appendChild(dataGtagScript);
  }

  // use gtag.js to send Google Analytics Events
  public event(
    action: string,
    eventCategory?: string,
    eventLabel?: string,
    value?: string,
  ) {
    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(eventLabel && { event_label: eventLabel }),
      ...(value && { value: value }),
    });
  }

  initializeCookieBot() {
    if (environment.sentryEnvironment != 'localhost') {
      // Create a new script element
      var scriptElement = document.createElement('script');

      // Set the attributes for the script element
      scriptElement.id = 'Cookiebot';
      scriptElement.src = 'https://consent.cookiebot.com/uc.js';
      scriptElement.setAttribute(
        'data-cbid',
        '3c8f6b50-dc6f-4d36-b715-541eabb689c4',
      );
      //scriptElement.setAttribute('data-framework', 'TCFv2.2'); // necessary for google ads
      //scriptElement.setAttribute('data-blockingmode', 'auto');
      scriptElement.type = 'text/javascript';

      // Append the script element to the document head
      document.head.appendChild(scriptElement);
    }
    // Initialize analytics
    this.initializeGA();
  }
}
