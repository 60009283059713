import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookReaderService {
  bookUpdated = new Subject<any>();
  bookCurrentPageUpdated = new BehaviorSubject<any>(1);
  bookTotalPagesUpdated = new BehaviorSubject<any>(1);
  bookTurnPage = new Subject<any>();
  wordCountUpdated = new BehaviorSubject<number>(0);
  tocOpenState = new BehaviorSubject<boolean>(false);
  bookObj: string = '';
  totalPages: number;
  currentPage: number;
  imageCount: number = 0;
  formdata;
  selected;
  currentPageAttributes;
  previousTextContent: string = '';
  fullTextContent: string = '';
  fromObj = {};
  toObj = {};
  currentPositionObj = {};
  lastPositionObj = {};
  maxCharcount = 1000;
  maxCharcountUpdated = new Subject<any>();
  ParagraphClass = 'book-reader-viewer--paragraph';
  ParagraphClassUpdated = new Subject<any>();
  isPageLoad: boolean = false;
  pageLoadUpdated = new Subject<any>();

  constructor() {}

  getBookContentByPage(page) {
    this.formdata.page = page;
  }

  setSelected(selected) {
    this.selected = selected;
    // if (this.selected.selectedwords !== undefined) {
    //   this.wordCountUpdated.next(this.selected.selectedwords);
    // }
  }

  setWordCount(count) {
    this.wordCountUpdated.next(count);
  }

  addTextContent(content) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = content;
    // Strip title tag from string
    let titleTag = tmp.getElementsByTagName('title');
    if (titleTag.length > 0) {
      titleTag[0].remove();
    }
    // Save plain text for readAlizer
    this.previousTextContent = tmp.textContent || tmp.innerText || '';
    //console.log(this.previousTextContent.trim())
    this.fullTextContent += this.previousTextContent.trim();
  }

  resetTextContent() {
    // reset text
    this.fullTextContent = '';
    console.log(this.fullTextContent);
    // reset images
    this.imageCount = 0;
  }

  getPreviousTextContent() {
    return this.previousTextContent;
  }

  getFullTextContent() {
    return this.fullTextContent.replace(/<\/?[^>]+(>|$)/g, '');
  }

  setRangeForTask(from, to) {
    this.fromObj = from;
    this.toObj = to;
  }

  getRangeForTask() {
    return {
      from: this.fromObj,
      to: this.toObj,
      // from: {
      //     chapter: this.selected[0].chapter,
      //     parentindex: this.selected[0].parentindex,
      //     index: this.selected[0].index,
      //     parent: this.selected[0].parent
      // },
      // to: {
      //     chapter: this.selected[1].chapter,
      //     parentindex: this.selected[1].parentindex,
      //     index: this.selected[1].index,
      //     parent: this.selected[1].parent
      // }
    };
  }

  setCurrentPageAttributes(selectedpage, selectedchapter) {
    this.currentPageAttributes = {
      chapter: selectedchapter,
      index: selectedpage,
    };
  }

  getCurrentPageAttributes() {
    console.log(this.currentPageAttributes);
    return this.currentPageAttributes;
  }

  setCurrentPosition(obj) {
    this.currentPositionObj = obj;
    console.log(obj);
  }

  setLastPosition(obj) {
    this.lastPositionObj = obj;
  }

  getCurrentPosition() {
    return this.currentPositionObj;
  }

  getLastPosition() {
    return this.lastPositionObj;
  }

  turnPage(direction) {
    this.bookTurnPage.next(direction);
    // Inform pagination
    this.currentPage =
      direction === 1 ? this.currentPage + 1 : this.currentPage - 1;
    this.bookCurrentPageUpdated.next({
      currentPage: this.currentPage,
    });
  }

  getWordCountForTask() {
    return this.wordCountUpdated.value;
  }

  getBookTurnPageUpdateListener() {
    return this.bookTurnPage.asObservable();
  }

  getBookContentUpdateListener() {
    return this.bookUpdated.asObservable();
  }

  getTocOpenStateUpdateListener() {
    return this.tocOpenState.asObservable();
  }

  getBookNavCurrentPageUpdateListener() {
    return this.bookCurrentPageUpdated.asObservable();
  }

  setBookCurrentPage(number) {
    //console.log(number)
    this.currentPage = number;
    this.bookCurrentPageUpdated.next({
      currentPage: this.currentPage,
    });
  }

  getBookNavTotalPagesUpdateListener() {
    return this.bookTotalPagesUpdated.asObservable();
  }

  setTocOpenState(state) {
    this.tocOpenState.next(state);
  }

  getTocOpenState() {
    return this.tocOpenState.value;
  }

  setBookTotalPages(number) {
    this.totalPages = number;
    this.bookTotalPagesUpdated.next({
      totalPages: this.totalPages,
    });
  }

  increaseImageCount(number) {
    this.imageCount += number;
  }

  getImageCount() {
    return this.imageCount;
  }

  getWordCountUpdateListener() {
    return this.wordCountUpdated.asObservable();
  }

  setMaxCharcount(char) {
    this.maxCharcount = char;
    this.maxCharcountUpdated.next(char);
  }

  getMaxCharcount() {
    return this.maxCharcount;
  }

  getMaxCharcountUpdateListener() {
    return this.maxCharcountUpdated.asObservable();
  }

  setParagraphClass(size) {
    this.ParagraphClass = size;
    this.ParagraphClassUpdated.next(size);
  }

  getParagraphClass() {
    return this.ParagraphClass;
  }

  getParagraphClassUpdateListener() {
    return this.ParagraphClassUpdated.asObservable();
  }

  setPageLoad(value) {
    this.isPageLoad = value;
    this.pageLoadUpdated.next(value);
  }

  getPageLoad() {
    return this.isPageLoad;
  }

  getPageLoadUpdateListener() {
    return this.pageLoadUpdated.asObservable();
  }

  removeMarkup(clickedWordData) {
    const removedWord = document.getElementById(clickedWordData.targetWordID);
    if (removedWord) {
      if (clickedWordData.name !== 'ins') {
        removedWord.classList.remove(`m-${clickedWordData.name}`);
        removedWord.style.paddingRight = '5px';
        removedWord.style.paddingLeft = '5px';
        if (clickedWordData.name === 'sub') {
          // We do that to remove the event handlers
          removedWord.replaceWith(removedWord.cloneNode(true));
        }
      } else {
        // If it's an insertion we need to remove the class and change
        // the content to a space
        removedWord.classList.remove(`m-${clickedWordData.name}`);
        removedWord.innerHTML = ' ';
      }
    }
  }
}
