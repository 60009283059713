<button mat-icon-button type="button" class="circle light task_close" (click)="closeAll()" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<app-task-create-stepper
  #stepper
  linear
  [bookSelected]="bookSelected"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
>
  <cdk-step [stepControl]="firstFormStep">
    <form [formGroup]="firstFormStep">
      <div class="task_select_container">
        <div class="task_create_headline">
          <h1 class="h1">Art der Aufgabe</h1>
          <p class="paragraph paragraph--l">Wählen Sie die Art der Aufgabe, die die Lernenden bearbeiten sollen.</p>
        </div>

        <button class="task_selector" [ngClass]="isRead ? 'active' : ''" (click)="addStyle('read')">
          <div class="task_selector_wrap">
            <img src="../../../assets/clock.svg" alt="" />
            <div class="task_selector_content">
              <h3 class="h3--bold">Bestimmte Dauer lesen</h3>
              <p class="paragraph paragraph--l">
                Legen Sie eine Lesezeit fest und überlassen Sie dem Lernenden die Wahl des Buches.
              </p>
            </div>
          </div>
        </button>

        <div class="task_divider">
          <h3 class="h1">oder</h3>
        </div>

        <button class="task_selector" [ngClass]="isBook ? 'active' : ''" (click)="addStyle('book')">
          <div class="task_selector_wrap">
            <img src="../../../assets/letter.svg" alt="" />
            <div class="task_selector_content">
              <h3 class="h3--bold">Bestimmten Text lesen</h3>
              <p class="paragraph paragraph--l">
                Wählen Sie ein Buch und den konkreten Text, der gelesen werden soll. Lernende sind bzgl. der Lesezeit
                nicht eingeschränkt.
              </p>
            </div>
          </div>
        </button>
        <div class="task_select_button">
          <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="selectedType()">Weiter</button>
        </div>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="secondFormStep">
    <form [formGroup]="secondFormStep">
      <div class="timeStep">
        <img src="../../../assets/clock.svg" alt="" />
        <h2 class="h2 h2--bold">Wie lange soll gelesen werden?</h2>
        <!-- <p class="paragraph paragraph--l">Wie lange soll gelesen werden?</p> -->
      </div>
      <div class="timeStepForm">
        <p class="paragraph paragraph--bold paragraph--l">Lesezeit</p>
        <mat-form-field>
          <input matInput formControlName="readingTime" type="number" placeholder="Minuten" required />
          <mat-error *ngIf="secondFormStep.get('readingTime').hasError('min')">
            <!-- Please enter a minimum of 1 minute. -->
            Bitte legen Sie mindesten 1 Minute fest
          </mat-error>
          <mat-error *ngIf="secondFormStep.get('readingTime').hasError('required')">
            <!-- Please choose a readingTime. -->
            Bitte Lesezeit festlegen
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" class="mat-flat-button--full" cdkStepperNext>Weiter</button>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="thirdFormStep">
    <form [formGroup]="thirdFormStep" #formDirective="ngForm" class="third_step_accorddion">
      <div class="accordion_header">
        <h2 class="h2 h2--bold">Wer soll die Aufgabe erhalten?</h2>
        <p class="paragraph paragraph--l">Wählen Sie die Klasse aus, der die Aufgabe zugewiesen werden soll.</p>
      </div>

      <div class="accordion_wrap">
        <mat-accordion multi>
          <mat-expansion-panel *ngFor="let groups of groups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-checkbox
                  (change)="toggleSelectAll(students, $event.checked)"
                  [checked]="students.selectedOptions.selected.length > 0"
                  class="example-margin"
                  (click)="$event.stopPropagation()"
                ></mat-checkbox>
                <p class="paragraph paragraph--bold paragraph--l">{{ groups.group.name }}</p>
              </mat-panel-title>
              <mat-panel-description>
                <p class="paragraph">
                  Ausgewählt
                  <span class="paragraph paragraph--bold"
                    >{{ students.selectedOptions.selected.length }}/{{ groups?.group?._students?.length }}
                  </span>
                </p>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list
              #students
              (selectionChange)="handleSelection($event, thirdFormStep.value)"
              [name]="groups.group._students"
              formControlName="students"
            >
              <mat-list-option
                checkboxPosition="before"
                *ngFor="let student of groups.group._students; index as i"
                [value]="student"
                [selected]="checkForSelection(student, i)"
              >
                <div class="inline_text">
                  <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
                  <p class="paragraph username-with-avatar">
                    {{ student?.username }}
                  </p>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- {{students}} -->
    </form>
    <div class="accordion_footer">
      <button mat-flat-button color="primary" class="mat-flat-button--full right_botton" cdkStepperNext>Weiter</button>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="fourthFormStep">
    <form [formGroup]="fourthFormStep">
      <div class="timeStep">
        <img src="../../../assets/clock.svg" alt="" />
        <h2 class="h2 h2--bold">Bis wann soll die Aufgabe erledigt werden?</h2>
        <p class="paragraph paragraph--l">Setzen Sie eine Frist für die Erledigung der Aufgabe.</p>
      </div>
      <div class="timeStepForm">
        <mat-form-field>
          <mat-label>TT.MM.JJJJ</mat-label>
          <input
            matInput
            [min]="todayDate"
            onkeypress="return false"
            formControlName="deadline"
            type="text"
            (click)="deadlinepicker.open()"
            [matDatepicker]="deadlinepicker"
          />
          <mat-datepicker-toggle matSuffix [for]="deadlinepicker"></mat-datepicker-toggle>
          <mat-datepicker #deadlinepicker></mat-datepicker>
          <mat-error *ngIf="fourthFormStep.get('deadline').hasError('required')">
            <!-- Please choose a deadline. -->
            Bitte setzen Sie eine Frist
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="setTaskData()" cdkStepperNext>
        Weiter
      </button>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="lastFormStep">
    <form [formGroup]="lastFormStep">
      <div class="timeStep">
        <h2 class="h2 h2--bold">Aufgabenübersicht</h2>
        <p class="paragraph paragraph--l">
          Hier können Sie die erstellte Aufgabe noch einmal kontrollieren. Bei Bedarf können Sie zurückspringen und die
          Aufgabe anpassen.
        </p>
      </div>

      <div class="task_summary_wrap">
        <div class="row" *ngFor="let group of groupOverview">
          <div class="col-md-12">
            <div class="group-overview-header">
              <p class="paragraph paragraph--bold paragraph--l">{{ group.name }}</p>
              <mat-divider></mat-divider>
            </div>
            <div class="row">
              <div class="col-md-6" *ngFor="let task of getStundentsByGroup(group.id)">
                <div class="task_summary">
                  <div class="set_inline task_summary_header">
                    <div class="inline_text">
                      <img class="avatar_img--small" *ngIf="task?.avatarUrl" src="{{ task?.avatarUrl }}" />
                      <p class="paragraph username-with-avatar">
                        {{ task?.username }}
                      </p>
                    </div>
                    <!-- <a class="card_link">Bearbeiten</a> -->
                  </div>

                  <div>
                    <div *ngIf="isReadingTime">
                      <div class="inline_text">
                        <p class="paragraph paragraph--s">
                          Dauer:
                          <span class="paragraph paragraph--bold paragraph--s"
                            >{{ secondFormStep.get('readingTime')?.value }} min</span
                          >
                        </p>
                      </div>
                    </div>
                    <div *ngIf="!isReadingTime">
                      <div class="inline_text">
                        <p class="paragraph paragraph--s task_summary-book">
                          Werk:
                          <span class="paragraph paragraph--bold paragraph--s task_summary-bookTitle">{{
                            bookTitle
                          }}</span>
                        </p>
                      </div>
                      <div class="inline_text">
                        <p class="paragraph paragraph--s">
                          Wörter: <span class="paragraph paragraph--bold paragraph--s">{{ wordcount }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="inline_text">
                      <p class="paragraph paragraph--s">
                        Frist:
                        <span class="paragraph paragraph--bold paragraph--s">{{
                          fourthFormStep.get('deadline')?.value | date: 'dd.MM.yyyy'
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <button mat-flat-button color="primary" type="button" (click)="submitForm(formDirective)" class="create_class_button" aria-label="Registration">
        Weiter
      </button> -->

      <div class="accordion_footer">
        <button
          mat-flat-button
          color="primary"
          class="mat-flat-button--full right_botton"
          (click)="submitForm(formDirective)"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading">Aufgabe anlegen</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </form>
  </cdk-step>
</app-task-create-stepper>
