import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { AdminService } from 'src/app/providers/admin.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
  teacher_first_name?: string;
  teacher_last_name?: string;
}

@Component({
  selector: 'app-admin-managing-overlay',
  templateUrl: './admin-managing-overlay.component.html',
  styleUrls: ['./admin-managing-overlay.component.scss'],
})
export class AdminManagingOverlayComponent implements OnInit, OnDestroy {
  blobUrl: string = '';
  rForm: FormGroup;
  isLoading: boolean = false;
  admin;
  groupName;
  managingType;
  page = 1;
  limit = -1;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  teachers;
  filterValues = {
    search: '',
    groups: [],
    status: [],
    role: ['school_admin'],
  };
  translatedText: any;
  isSuccess: boolean = false;
  teacherActive: boolean;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public resDialog: MatDialogRef<AdminManagingOverlayComponent>,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['admin'] !== 'undefined') {
        this.admin = this.injectedData['admin'];
      }
      if (typeof injectedData['managingType'] !== 'undefined') {
        this.managingType = this.injectedData['managingType'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.getTeachers();

    console.log('teacher', this.admin);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (teacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data;
        },
      });
  }

  removeAdminRights() {
    let formData = {
      teacherId: this.admin._id,
      role: 'teacher',
    };
    if (this.teachers.length > 1) {
      this.adminService.demoteSchoolAdminToTeacher(formData).subscribe({
        next: (res) => {
          this.isSuccess = true;
          // this.dialog.closeAll();
          // this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          //   panelClass: 'snack-success',
          //   data:
          //     this.translatedText?.admin_managing_text +
          //     ' ' +
          //     this.admin.firstname +
          //     ' ' +
          //     this.admin.lastname +
          //     ' ' +
          //     this.translatedText?.admin_managing_text_2,
          //   duration: 3000,
          //   horizontalPosition: 'left',
          // });
          // Update teacher list
        },
        error: (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.admin_managing_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
    } else {
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        panelClass: 'snack-error',
        data: this.translatedText?.admin_managing_admin_error,
        duration: 3000,
        horizontalPosition: 'left',
      });
    }
  }

  assignAdminRights() {
    const teacherIds = [this.admin._id];

    this.adminService.upgradeTeachersToSchoolAdmin(teacherIds).subscribe({
      next: (res) => {
        this.isSuccess = true;
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.admin_managing_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  deleteSchoolAdmin() {
    this.teacherService.deleteTeacher(this.admin._id).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.admin_managing_delete_teacher,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        let message = this.translatedText?.admin_managing_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
