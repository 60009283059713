<button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<div class="reading-level_container">
  <div class="divider"></div>

  <div class="reading-level_img-wrap">
    <div
      class="star-badge-wrap"
      *ngFor="let level of [0, 1, 2, 3, 4, 5]"
      [ngClass]="{ 'active-level': level === readingLevel }"
      class="star-badge-wrap--level{{ level }}"
    >
      <div class="avatar-wrap">
        <img
          class="img-top"
          *ngIf="readingLevelUpgrade == false && level === readingLevel"
          src="../../../assets/black-lines.png"
          alt=""
        />
        <img
          class="img-left"
          *ngIf="readingLevelUpgrade == false && level === readingLevel"
          src="../../../assets/red-arrow.png"
          alt=""
        />
        <img
          class="img-right"
          *ngIf="readingLevelUpgrade == false && level === readingLevel"
          src="../../../assets/ligthning-downgrade.svg"
          alt=""
        />
        <img
          class="img-left-upgrade"
          *ngIf="readingLevelUpgrade == true && level === readingLevel"
          src="../../../assets/confetti-upgrade.png"
          alt=""
        />
        <img
          class="img-right-upgrade"
          *ngIf="readingLevelUpgrade == true && level === readingLevel"
          src="../../../assets/lightning-upgrade.png"
          alt=""
        />
        <img
          class="img-bottom-upgrade"
          *ngIf="readingLevelUpgrade == true && level === readingLevel"
          src="../../../assets/star-upgrade.png"
          alt=""
        />
        <div class="star-badge-wrap__icon">
          <mat-icon color="primary" [svgIcon]="'streamline_filled:star-badge-' + level"></mat-icon>
        </div>
        <img class="avatar_img" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
      </div>
    </div>
  </div>

  <div class="reading-level-content">
    <ng-container *ngIf="readingLevelUpgrade == true">
      <h3 class="h3 h3--bold">{{ translatedText.reading_level_overlay_super_title }}</h3>
      <p class="paragraph paragraph--xl">{{ translatedText.reading_level_overlay_super_desc }}</p>
    </ng-container>
    <ng-container *ngIf="readingLevelUpgrade == false">
      <h3 class="h3 h3--bold">{{ translatedText.reading_level_overlay_mad_title }}</h3>
      <p class="paragraph paragraph--xl">{{ translatedText.reading_level_overlay_mad_desc }}</p>
    </ng-container>

    <button mat-flat-button color="primary-student" type="button" [mat-dialog-close]="'close'">
      {{ translatedText.reading_level_overlay_close }}
    </button>
  </div>
</div>
