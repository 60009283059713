<app-students-add-stepper #stepper
  ><section class="container-fluid overlay_container">
    <!-- first step -->
    <cdk-step [stepControl]="studentAmountForm">
      <div class="header-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" matSuffix [mat-dialog-close]="true">
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <!-- <button mat-flat-button color="unset" disabled [mat-dialog-close]="true">
            {{ translatedText.students_add_back }}
          </button> -->
          <button
            mat-flat-button
            color="primary"
            class="submit-button"
            (click)="prepareNextStep()"
            [disabled]="
              studentAmountForm.get('studentAmount')?.value < 1 || studentAmountForm.get('studentAmount')?.value > 40
            "
            cdkStepperNext
          >
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>
      <div class="student-add-container">
        <h1>{{ translatedText.students_add_students_count }}</h1>

        <div class="content-wrapper">
          <div class="rectangle-container rectangle-container--gray">
            <h3 class="h3 h3--bold">{{ translatedText.students_add_how_many_students }}</h3>
            <form [formGroup]="studentAmountForm" class="student-amount-form">
              <div class="second_step select_students_amount">
                <div class="col-12">
                  <mat-form-field>
                    <div class="number-wrap">
                      <button (click)="decreaseValue()">
                        <mat-icon svgIcon="streamline_filled:license-minus"></mat-icon>
                      </button>
                      <input
                        matInput
                        type="number"
                        formControlName="studentAmount"
                        [min]="0"
                        [max]="40"
                        (input)="onStudentAmountInput()"
                      />
                      <button (click)="increaseValue()">
                        <mat-icon svgIcon="streamline_filled:license-plus"></mat-icon>
                      </button>
                    </div>
                  </mat-form-field>
                  <mat-error *ngIf="studentAmountForm.hasError('min')">
                    {{ translatedText.students_add_students_count_min }}
                  </mat-error>
                  <mat-error *ngIf="studentAmountForm.hasError('max')">
                    {{ translatedText.students_add_students_count_max }}
                  </mat-error>
                </div>
              </div>
            </form>
          </div>
          <div class="admin-class-list-licenses-count">
            <div class="licenses-wrap diagnosis">
              <p>
                <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
                {{ translatedText.students_add_total_diagnostic }}
              </p>
              <p>
                <strong>
                  <span [countUp]="totalCountLeftDiagnostic" *ngIf="totalCountDiagnostic < 100">{{
                    totalCountLeftDiagnostic
                  }}</span>
                  <span *ngIf="totalCountDiagnostic >= 100"> ∞ </span>
                </strong>
              </p>
            </div>

            <div class="licenses-wrap training">
              <p>
                <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
                {{ translatedText.students_add_total_training }}
              </p>
              <p>
                <strong>
                  <span>{{ totalCountLeftTraining }}</span>
                </strong>
              </p>
            </div>
          </div>
          <div class="rectangle-container rectangle-container--blue" *ngIf="displayEnoughLicensesNote()">
            <span>
              <mat-icon class="blue-icon" svgIcon="streamline_outlined:info-empty"></mat-icon>
              <h3>{{ translatedText.students_add_not_enough_licenses }}</h3>
            </span>

            <p>
              {{ translatedText.students_add_not_enough_licenses_text }}
            </p>
          </div>
        </div>
      </div>
    </cdk-step>

    <!-- second step -->
    <cdk-step [stepControl]="secondFormStep">
      <div class="header-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" (click)="openCancelDialog()" matSuffix>
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <!-- <button mat-flat-button color="unset" cdkStepperPrevious disabled>
            {{ translatedText.students_add_back }}
          </button> -->
          <button
            mat-flat-button
            color="primary"
            class="submit-button"
            [disabled]="isFormInvalid"
            cdkStepperNext
            (click)="finishedSecondStep()"
          >
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>

      <div class="student-add-container wider">
        <app-create-learners
          [totalStudents]="totalStudents"
          [title]="translatedText.students_add_class_profile_info"
          [diagnosticTotalCount]="totalCountDiagnostic"
          [trainingTotalCount]="totalCountTraining"
          [diagnosticLeftCount]="totalCountLeftDiagnostic"
          [trainingLeftCount]="totalCountLeftTraining"
        ></app-create-learners>
      </div>
    </cdk-step>

    <!-- third step STEP COMMENTED UNTIL ENDPOINT TO SELECT LICENSES IS DONE -->
    <!-- <cdk-step [stepControl]="licenseStep">
      <div class="header-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" (click)="openCancelDialog()" matSuffix>
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon> {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button mat-flat-button color="unset" cdkStepperPrevious>
            {{ translatedText.students_add_back }}
          </button>
          <button mat-flat-button color="primary" class="submit-button" (click)="openPDFGenerate()">
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>

      <div class="student-add-container">
        <div class="license-step">
          <h1>{{ translatedText.students_add_assign_licenses }}</h1>
          <p>
            {{ translatedText.students_add_assign_licenses_text }}
          </p>
          <button mat-flat-button color="unset" class="add-another-teacher student" (click)="openPDFGenerate()">
            <mat-icon svgIcon="streamline_outlined:skip-next"></mat-icon>{{ translatedText.students_add_skip }}
          </button>
          <div class="table-container">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef>
                  {{ translatedText.task_detail_students }} <span class="table-counter blue">{{ totalStudents }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullname }}
                </td>
              </ng-container>

              <ng-container matColumnDef="diLicense">
                <th mat-header-cell *matHeaderCellDef>
                  {{ translatedText.student_diagnostic_license }}
                  <span class="table-counter purple"
                    ><span *ngIf="totalCountLeftDiagnostic < 100"> {{ diLicenseLeft }} </span>
                    <span *ngIf="totalCountLeftDiagnostic >= 100"> ∞ </span>
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" class="round-checkbox">
                  <mat-checkbox #licenseCheckbox (click)="updateTotalDiagnosticLicense($event)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="foLicense">
                <th mat-header-cell *matHeaderCellDef class="header-with-button">
                  <span>
                    {{ translatedText.student_training_license }}
                    <span class="table-counter green">{{ foLicenseLeft }}</span>
                  </span>
                  <span>
                    <button
                      mat-flat-button
                      color="unset"
                      [(ngModel)]="selectedAll"
                      (click)="selectAllCheckboxes($event)"
                    >
                      {{ translatedText.students_add_select_all }}
                    </button></span
                  >
                </th>
                <td mat-cell *matCellDef="let element" class="round-checkbox">
                  {{ element.foLicense
                  }}<mat-checkbox #licenseCheckbox (click)="updateTotalTrainingLicense($event)"> </mat-checkbox>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </cdk-step> -->

    <cdk-step>
      <div class="header-wrapper">
        <button
          mat-flat-button
          class="close-button-with-text"
          type="button"
          (click)="openCancelDialog()"
          matSuffix
          [ngClass]="{ 'hidden-btn': blobUrl }"
          [disabled]="blobUrl"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">
              {{ translatedText.students_add_title_chip }}
            </div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button
            mat-flat-button
            color="unset"
            cdkStepperPrevious
            [ngClass]="{ 'hidden-btn': isLoading || blobUrl }"
            [disabled]="isLoading || blobUrl"
          >
            {{ translatedText.students_add_back }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="submit-button"
            cdkStepperNext
            [disabled]="blobUrl === null"
            [mat-dialog-close]="true"
          >
            {{ translatedText.admin_group_close_btn }}
          </button>
        </div>
      </div>
      <div class="step_padding">
        <div class="training-task_create_headline">
          <h3 class="h3 h3--bold">{{ translatedText.admin_group_class_pdf_generate }}</h3>
        </div>
      </div>
      <div class="generate-pdf-section">
        <div class="pdf-notification">
          <div class="notification-box open-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:shield-check-v2"></mat-icon>
                  <div class="tn-heading">{{ translatedText.generate_pdf_data_protection }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.generate_pdf_data_protection_text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-container">
          <mat-icon svgIcon="streamline_filled:pdf-doc" class="icon-56" *ngIf="blobUrl === null"></mat-icon>
          <mat-icon svgIcon="streamline_filled:download-boxed" class="icon-56" *ngIf="blobUrl"></mat-icon>
          <p class="paragraph paragraph--bold paragraph--xl">
            {{ blobUrl === null ? translatedText.generate_class_pdf : translatedText.generate_class_pdf_ready }}
          </p>
          <p class="paragraph paragraph--m">
            {{ blobUrl === null ? translatedText.generate_pdf_attention_text : translatedText.generate_pdf_ready_text }}
          </p>
          <button
            mat-flat-button
            color="primary"
            class="mt-4"
            *ngIf="!isLoading && blobUrl === null"
            (click)="submitForm()"
          >
            {{ translatedText.generate_pdf_generate_PDF }}
          </button>
          <button mat-flat-button class="mt-4 button-light-green" *ngIf="isLoading">
            <mat-icon svgIcon="streamline_outlined:pdf-loader" class="mr-2"></mat-icon>
            {{ translatedText.generate_pdf_PDF_being_generated }}
          </button>
          <button mat-flat-button color="primary" class="mt-4" *ngIf="blobUrl" (click)="downloadPDF()">
            <mat-icon svgIcon="streamline_outlined:download-pdf" class="mr-2"></mat-icon>
            {{ translatedText.admin_group_list_download_pdf }}
          </button>
        </div>

        <div class="pdf-notification" *ngIf="!clickedDownload">
          <div class="notification-box warn-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

                  <div class="tn-heading">{{ translatedText.generate_pdf_save_where }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.generate_pdf_save_where_text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-notification" *ngIf="clickedDownload">
          <div class="notification-box success-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:checkmark"></mat-icon>

                  <div class="tn-heading">{{ translatedText.admin_group_class_generate_pdf_successfully }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.admin_group_class_generate_pdf_successfully_desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </cdk-step>
  </section>
</app-students-add-stepper>

<!-- UNSUSED AFTER REDESIGN -->

<!--  <div class="col-12">
              <div class="avatar-selection">
                <h3 class="h3 h3--bold">
                  {{ translatedText.students_add_avatar_headline }}
                </h3>
                <p class="paragraph paragraph--m">
                  {{ translatedText.students_add_avatar_info }}
                </p>
                <mat-radio-group formControlName="avatar" aria-label="Select an option">
                  <mat-radio-button value="animal" [checked]="recommendedAvatar === 'animal'">
                    <div class="avatar_desc">
                      <p class="paragraph paragraph--bold">
                        {{ translatedText.students_add_avatar_animal }}
                        <span *ngIf="recommendedAvatar == 'animal'" class="avatar_recommended">{{
                          translatedText.students_add_avatar_recomended
                        }}</span>
                      </p>
                      <p class="paragraph paragraph">
                        {{ translatedText.students_add_animal_grades }}
                      </p>
                    </div>
                    <img src="../../../../assets/animal_package.svg" alt="" />
                  </mat-radio-button>
                  <mat-radio-button value="planet" [checked]="recommendedAvatar === 'planet'">
                    <div class="avatar_desc">
                      <p class="paragraph paragraph--bold">
                        {{ translatedText.students_add_avatar_planet }}
                        <span *ngIf="recommendedAvatar == 'planet'" class="avatar_recommended">{{
                          translatedText.students_add_avatar_recomended
                        }}</span>
                      </p>
                      <p class="paragraph paragraph">
                        {{ translatedText.students_add_planat_grades }}
                      </p>
                    </div>
                    <img src="../../../../assets/planet_package.svg" alt="" />
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div> -->
