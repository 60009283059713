import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { BookService } from '../../providers/book.service';
import { BookViewComponent } from '../book-view/book-view.component';

export interface DialogData {}

export interface Book {
  title?: string;
  _id: string;
}

@Component({
  selector: 'app-book-preview',
  templateUrl: './book-preview.component.html',
  styleUrls: ['./book-preview.component.scss'],
})
export class BookPreviewComponent implements OnInit {
  @Input('bookData') bookData: any;
  @Input('isSpecific_book') isSpecific_book: boolean;
  @Output() backToLibrary = new EventEmitter<boolean>();
  @Output() bookView = new EventEmitter<any>();

  bookIsbn: string;
  book: any;
  bookUrl: string;
  bookImgUrl;
  bookTitle;
  bookDescription;
  bookAuthor;
  taskType;
  isTask: boolean = false;
  imageLoadFailed: boolean = false;
  readingTime;
  taskId;
  userTaskUuid;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public resDialog: MatDialogRef<BookPreviewComponent>,
    private route: ActivatedRoute,
    private bookService: BookService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['isbn'] !== 'undefined') {
        this.bookIsbn = this.injectedData['isbn'];
      } else {
        this.bookIsbn = this.route.snapshot.params['id'];
      }
      if (typeof injectedData['title'] !== 'undefined') {
        this.bookTitle = this.injectedData['title'];
      }
      if (typeof injectedData['description'] !== 'undefined') {
        this.bookDescription = this.injectedData['description'];
      }
      if (typeof injectedData['author'] !== 'undefined') {
        this.bookAuthor = this.injectedData['author'];
      }
      if (typeof injectedData['type'] !== 'undefined') {
        this.taskType = this.injectedData['type'];
      }
      if (typeof injectedData['time'] !== 'undefined') {
        this.readingTime = this.injectedData['time'];
      }
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['userTaskUuid'] !== 'undefined') {
        this.userTaskUuid = this.injectedData['userTaskUuid'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    console.log(this.bookData);
    console.log(this.isSpecific_book);
    if (this.bookData !== undefined) {
      this.bookIsbn = this.bookData.isbn;
      this.bookTitle = this.bookData.title;
      this.bookDescription = this.bookData.description;
      this.bookAuthor = this.bookData.author;
    }

    if (this.taskType === 'time' || this.taskType === 'self') {
      this.isTask = true;
    }
    // Get book image by url
    this.getBookImageUrl(this.bookIsbn);
  }

  getNextStepButtonText() {
    return !this.isSpecific_book
      ? this.translatedText?.book_preview_select
      : this.translatedText?.book_preview_next;
  }

  getBookImageUrl(isbn) {
    this.bookService.getBookimage(isbn).subscribe((response) => {
      if (response) {
        // blob
        // let dataType = response.type;
        // let binaryData = [];
        //     binaryData.push(response);

        // let blob = new Blob(binaryData, {type: dataType});
        // let blobUrl = window.URL.createObjectURL(blob);
        // //window.open(blobUrl, '_blank');
        // this.bookImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

        // just url
        this.bookImgUrl = response.link;
      }
    });
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(event: any) {
    // just try once
    if (!this.imageLoadFailed) {
      this.imageLoadFailed = true;
      this.bookService.getBookimage(this.bookIsbn).subscribe((response) => {
        if (response) {
          // just url
          event.target.src = response.link;
        }
      });
    }
  }

  // openBookView(): void {
  // //	let dialogRef;
  // 	const  dialogRef = this.dialog.open(BookViewComponent, {
  // 		width: '100%',
  // 		autoFocus: false,
  // 		panelClass: 'books-overlay-panel',
  // 	  hasBackdrop: false,
  // 		disableClose: true,
  // 		backdropClass: 'books-overlay-panel-backdrop',
  //     data: {
  //       isbn : this.bookIsbn,
  //       title: this.bookTitle,
  //       author: this.bookAuthor
  //     }
  // 	  });
  // 	// dialogRef.disableClose = true;
  // 	dialogRef.afterClosed().subscribe(res => {
  //     console.log(res);
  //     if (res !== true) {
  //       let data = {
  //         res: res,
  //         path: "book-preview"
  //       }
  //       this.resDialog.close(data);
  //     }
  // 	});
  // }

  openBookPartSelection(): void {
    let taskData = {
      isbn: this.bookIsbn,
      title: this.bookTitle,
      author: this.bookAuthor,
      type: this.taskType,
      taskId: this.taskId,
      userTaskUuid: this.userTaskUuid,
    };
    if (this.taskType === 'time') {
      taskData['time'] = this.readingTime;
    }

    let dialogRef = this.dialog.open(BookViewComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel-backdrop',
      data: taskData,
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res !== true) {
        let data = {
          res: res,
          path: 'book-preview',
        };
        this.resDialog.close(data);
      }
      if (res === 'taskStarted') {
        this.resDialog.close('taskStarted');
      }
    });
  }

  navigateBackToLibrary() {
    this.backToLibrary.emit(true);
  }

  openBookView() {
    this.bookView.emit(true);
  }

  closeAll() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
