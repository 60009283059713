<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">{{ translatedText.privacy_policy_main_title }}</h1>
          <p>{{ translatedText.privacy_policy_paragraph_1 }}</p>
          <h2>{{ translatedText.privacy_policy_title_1 }}</h2>
          <p>{{ translatedText.privacy_policy_paragraph_2 }}</p>

          <h2>{{ translatedText.privacy_policy_title_2 }}</h2>
          <h3>{{ translatedText.privacy_policy_subtitle }}</h3>
          <p>
            {{ translatedText.privacy_policy_paragraph_3 }} <br /><br />
            Digi Sapiens – Digital Learning GmbH<br />
            Opernplatz 14<br />
            60313 Frankfurt am Main
            <br /><br />
          </p>

          <h3>{{ translatedText.privacy_policy_title_3 }}</h3>
          <p>Stephan Krischke, datenschutz@digi-sapiens.de<br /><br /></p>

          <h2>{{ translatedText.privacy_policy_title_4 }}</h2>
          <p>
            {{ translatedText.privacy_policy_paragraph_4 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_5 }}
          </p>

          <h2>{{ translatedText.privacy_policy_title_5 }}</h2>
          <p>
            {{ translatedText.privacy_policy_paragraph_6 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_6_1 }}
          </p>
          <ul>
            <li>{{ translatedText.privacy_policy_list_1 }}</li>
            <li>{{ translatedText.privacy_policy_list_2 }}</li>
            <li>{{ translatedText.privacy_policy_list_3 }}</li>
            <li>{{ translatedText.privacy_policy_list_4 }}</li>
          </ul>
          <p>
            {{ translatedText.privacy_policy_paragraph_7 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_8 }}
          </p>

          <h2>{{ translatedText.privacy_policy_title_6 }}</h2>
          <p>
            {{ translatedText.privacy_policy_paragraph_9 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_10 }}
          </p>

          <h2>{{ translatedText.privacy_policy_title_7 }}</h2>
          <p>{{ translatedText.privacy_policy_paragraph_11 }}</p>
          <ul>
            <li>
              <strong>{{ translatedText.privacy_policy_list_information }}</strong> {{
              translatedText.privacy_policy_list_5 }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_correction }}</strong> {{
              translatedText.privacy_policy_list_6 }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_deletion }}</strong>{{ translatedText.privacy_policy_list_20
              }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_restriction }}</strong>{{
              translatedText.privacy_policy_list_7 }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_portability }}</strong> {{
              translatedText.privacy_policy_list_8 }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_objection }}</strong>{{ translatedText.privacy_policy_list_9
              }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_revocation }}</strong> {{
              translatedText.privacy_policy_list_10 }}
            </li>
            <li>
              <strong>{{ translatedText.privacy_policy_list_complaints }}</strong> {{
              translatedText.privacy_policy_list_11 }}
            </li>
          </ul>

          <h2>{{ translatedText.privacy_policy_title_8 }}</h2>
          <p>{{ translatedText.privacy_policy_paragraph_12 }}</p>

          <h3>{{ translatedText.privacy_policy_title_9 }}</h3>
          <h3>{{ translatedText.privacy_policy_title_10 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_13 }}</p>
          <ul>
            <li>{{ translatedText.privacy_policy_list_12 }}</li>
            <li>{{ translatedText.privacy_policy_list_13 }}</li>
            <li>{{ translatedText.privacy_policy_list_14 }}</li>
            <li>{{ translatedText.privacy_policy_list_15 }}</li>
            <li>{{ translatedText.privacy_policy_list_16 }}</li>
          </ul>
          <p>{{ translatedText.privacy_policy_paragraph_14 }}</p>

          <h3>{{ translatedText.privacy_policy_title_11 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_15 }}</p>

          <h3>{{ translatedText.privacy_policy_title_12 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_16 }}</p>

          <h3>{{ translatedText.privacy_policy_title_13 }}</h3>
          <h3>{{ translatedText.privacy_policy_title_14 }}</h3>
          <p>
            {{ translatedText.privacy_policy_paragraph_17 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_18 }}
          </p>

          <h3>{{ translatedText.privacy_policy_title_15 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_19 }}</p>

          <h3>{{ translatedText.privacy_policy_title_16 }}</h3>
          <p>
            {{ translatedText.privacy_policy_paragraph_20 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_20_2 }}
          </p>

          <h3>{{ translatedText.privacy_policy_title_17 }}</h3>
          <h3>{{ translatedText.privacy_policy_title_18 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_21 }}</p>
          <ul>
            <li>{{ translatedText.privacy_policy_list_17 }}</li>
            <li>{{ translatedText.privacy_policy_list_18 }}</li>
            <li>{{ translatedText.privacy_policy_list_19 }}</li>
          </ul>

          <h3>{{ translatedText.privacy_policy_title_19 }}</h3>
          <p>
            {{ translatedText.privacy_policy_paragraph_22 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_23 }}
          </p>

          <h3>{{ translatedText.privacy_policy_title_20 }}</h3>
          <p>
            {{ translatedText.privacy_policy_paragraph_24 }}
            <br /><br />
            {{ translatedText.privacy_policy_paragraph_25 }}
          </p>

          <h2>{{ translatedText.privacy_policy_title_21 }}</h2>
          <h3>{{ translatedText.privacy_policy_title_22 }}</h3>
          <p>{{ translatedText.privacy_policy_paragraph_26 }}</p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
