import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { BookReaderModule } from '../../book-reader/book-reader.module';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { MaterialModule } from '../../material.module';
import { PipeModule } from '../../pipes/pipe.module';
import { BookViewStudentComponent } from '../book-view-student/book-view-student.component';

@NgModule({
  declarations: [BookViewStudentComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BookReaderModule,
    PipeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [BookViewStudentComponent],
})
export class BookViewStudentModule {}
