import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { TaskCreateComponent } from 'src/app/task/task-create/task-create.component';

export interface Tasks {}

@Component({
  selector: 'app-dashboard-tasks',
  templateUrl: './dashboard-tasks.component.html',
  styleUrls: ['./dashboard-tasks.component.scss'],
})
export class DashboardTasksComponent implements OnInit, OnDestroy {
  teacherId;
  tasks: Tasks[] = [];
  activeTab = 'isActive';
  selectedTabIndex = 0;
  activeTasks;
  finishedTasks;
  expiredTasks;
  filterGroupsName;
  totalItemsActive: number = 0;
  totalItemsFinished: number = 0;
  totalItemsExpired: number = 0;
  selectedTaskView: string = 'table';
  selectedGroup = [];
  taskTypeOptions: any[] = [
    { value: 'specific_text', viewValue: 'Diagnose' },
    { value: 'specific_book', viewValue: 'Förderung' },
  ];
  taskAreaOptions: any[] = [
    { value: 'reading_fluency', viewValue: 'Leseflüssigkeit' },
    { value: 'understand_text', viewValue: 'Den Text verstehen' },
    { value: 'listening_comprehension', viewValue: 'Hörverständnis' },
  ];
  dateAreaOptions: any[] = [
    { value: 'current_week', viewValue: 'Aktuelle Woche' },
    { value: 'current_month', viewValue: 'Aktueller Monat' },
    { value: 'upcoming_three_month', viewValue: 'Nächste 3 Monate' },
    { value: 'upcoming_six_month', viewValue: 'Nächste 6 Monate' },
    { value: 'current_school_year', viewValue: 'Schuljahr 22-23' },
  ];
  selectedTaskArea = ['all'];
  filterFormGroup: FormGroup;
  translatedText: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private taskService: TaskService,
    private location: Location,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    // Define filter group
    this.filterFormGroup = new FormGroup({
      group: new FormControl(),
      taskType: new FormControl(),
      taskArea: new FormControl(),
    });
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
    // Get active tab from url
    let activeTabLocation = this.route.snapshot.fragment;
    if (activeTabLocation) {
      // Set active tab on page load
      this.selectedTabIndex = Number(activeTabLocation);
      this.setActiveTabByIndex(this.selectedTabIndex);
    }
  }

  createTaskDialog(): void {
    let dialogRef = this.dialog.open(TaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-class-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-class-overlay-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.setActiveTabByIndex(tabChangeEvent.index);
  }

  setTotalCounts(data) {
    this.totalItemsActive = data?.totalItemsActive;
    this.totalItemsFinished = data?.totalItemsFinished;
    this.totalItemsExpired = data?.totalItemsExpired;
  }

  setActiveTabByIndex(index) {
    if (index === 0) {
      this.activeTab = 'isActive';
    } else if (index === 1) {
      this.activeTab = 'isFinished';
    } else if (index === 2) {
      this.activeTab = 'isExpired';
    } else if (index === 3) {
      this.activeTab = 'isSelfReading';
    }
    this.location.replaceState(this.location.path() + '#' + index);
  }

  onSelectedViewChange(value) {
    console.log(value);
    this.selectedTaskView = value;
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection !== undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value === selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
