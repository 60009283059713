import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from '../../providers/task.service';
import { BookService } from '../../providers/book.service';
import { BookDataService } from '../../providers/book-data.service';
import { TaskStartOverlayComponent } from '../../helpers/task-start-overlay/task-start-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import { LicenseService } from '../../providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-task-preview',
  templateUrl: './task-preview.component.html',
  styleUrls: ['./task-preview.component.scss'],
})
export class TaskPreviewComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  task;
  userId;
  taskId;
  book;
  bookReaderSettings;
  isTaskStarted: boolean = false;
  isBookViewStudent: boolean = false;
  isbn;
  startFrom;
  readerSettings;
  licenseActive: boolean = false;
  translatedText;
  startWord = {};
  @Output() backToTaskPreview = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    public route: ActivatedRoute,
    private taskService: TaskService,
    private bookService: BookService,
    public dialog: MatDialog,
    private licenseService: LicenseService,
    private bookDataService: BookDataService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userId = this.authService.getUserId();
    this.taskId = this.route.snapshot.params['id'];
    console.log(this.taskId);
    this.taskService.getSingleTask(this.taskId);
    this.taskService
      .getSingleTaskUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.task = res;
        this.bookReaderSettings = res['reader_settings'];
        // if book isbn isset in task
        if (this.task.book_isbn) {
          this.bookService.getBookById(this.task.book_isbn);
        } else {
          // Otherwise route to book library
          this.selectBook(this.task._id);
        }
      });

    this.bookService
      .getBookUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.book = data;
        console.log(this.book);
      });
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  beforeUnloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = this.translatedText.task_preview_dont_close_text;
  }

  selectedStartWord(evt) {
    console.log("CHE", evt)
    this.startWord = evt.start;
  }

  startTaskOverlay(task) {
    console.log(task);
    if (
      task.type == 'diagnostic-pre' ||
      task.type == 'diagnostic-mid' ||
      task.type == 'diagnostic-post' ||
      task.type == 'text' ||
      task.type == 'autonomous-placement'
    ) {
      let startTaskDialog;
      startTaskDialog = this.dialog.open(TaskStartOverlayComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'task-start-overlay-task-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'task-start-overlay-task-panel-backdrop',
        data: {
          task: task,
        },
      });
      // dialogRef.disableClose = true;
      startTaskDialog
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          if (res == 'start') {
            // Check license before starting task
            this.licenseService
              .checkLicense()
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((data) => {
                console.log(data);
                this.licenseActive = true;
                this.isTaskStarted = true;
              });
          }
        });
    } else {
      this.isbn = task.book_isbn;
      this.isBookViewStudent = true;
      this.bookDataService.setHyphenationModeUpdateListener(
        task.reader_settings.hyphenation_mode,
      );
    }
  }

  bookViewClosed(event) {
    console.log(event);
    if (event.type == 'close') {
      this.isBookViewStudent = false;
    } else if (event.type == 'start') {
      this.startFrom = event.data;
      this.bookReaderSettings = event.bookReaderSettings;
      setTimeout(() => {
        this.isBookViewStudent = false;
        this.isTaskStarted = true;
      }, 0);
    }
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  selectBook(taskId) {
    this.router.navigate(['/book-list-student/' + taskId]);
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(event: any, book) {
    // just try once
    if (!book.imageLoadFailed) {
      book.imageLoadFailed = true;
      event.target.src = book.imageUrl;
    }
  }

  ngOnDestroy(): void {
    this.bookDataService.resetRangeForTask();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
