import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-pdf-overlay',
  templateUrl: './pdf-overlay.component.html',
  styleUrls: ['./pdf-overlay.component.scss'],
})
export class PdfOverlayComponent implements OnInit, OnDestroy {
  blobUrl: string = '';
  groupName: string = '';
  formattedDate: string;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    this.blobUrl = this.injectedData['url'];
    this.groupName = this.injectedData['groupName'];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    this.formattedDate = this.datePipe.transform(
      currentDate,
      `${year}${month}${day}_`,
    );
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  downloadPDF() {
    var element = document.createElement('a');
    element.setAttribute('href', this.blobUrl);
    element.setAttribute(
      'download',
      this.formattedDate + this.groupName + '.pdf',
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    this.closePdfOverlay();
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
