import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslationService } from 'src/app/providers/translation.service';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';
  lastPageLabel = 'Letzte Seite';
  firstPageLabel = 'Erste Seite';

  constructor(private translationService: TranslationService) {
    super();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return (
        `0 ` +
        this.translationService.getTranslatedText('pagination_from') +
        ` ${length}`
      );
    }
    const startIndex = page * pageSize + 1;
    const endIndex = startIndex + pageSize - 1;
    return (
      `${startIndex} - ${endIndex}` +
      this.translationService.getTranslatedText('pagination_from') +
      `${length}`
    );
  };
}
