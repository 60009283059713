import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { SelectAllOptionComponent } from './select-all-option.component';

@NgModule({
  declarations: [SelectAllOptionComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [SelectAllOptionComponent],
})
export class SelectAllOptionModule {}
