<section>
  <button mat-mini-fab type="button" class="close-button close-button--right" (click)="closeOverlay()" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div>
    <h3 class="h3 h3--bold training-task_create_headline">
      {{ translatedText.admin_teacher_edit_teacher_edit }}
    </h3>
    <form [formGroup]="rForm" #formDirective="ngForm">
      <div>
        <div class="personal-info">
          <div class="row">
            <div class="col-md-12 form_header-headline">
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText.admin_teacher_edit_personal_information }}
              </p>
            </div>
            <div class="col-md-4">
              <mat-label>{{ translatedText.admin_teacher_edit_salutation }}*</mat-label>
              <mat-form-field class="teacher-edit-input" floatLabel="never">
                <input matInput aria-label="Anrede" [matAutocomplete]="auto" formControlName="salutation" required />
                <mat-icon>keyboard_arrow_down</mat-icon>
                <!-- <mat-icon *ngIf="openedMenu">keyboard_arrow_up</mat-icon> -->
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                    <span>{{ item }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="rForm.get('salutation').hasError('required')">
                  <!-- Please enter a salutation. -->
                  {{ translatedText.admin_teacher_edit_salutation_error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-label>{{ translatedText.admin_teacher_edit_firstname }}</mat-label>
              <mat-form-field floatLabel="never" class="teacher-edit-input">
                <input
                  matInput
                  formControlName="firstname"
                  type="text"
                  placeholder="{{ translatedText.admin_teacher_edit_firstname }}"
                  required
                />
                <mat-error *ngIf="rForm.get('firstname').hasError('required')">
                  {{ translatedText.admin_teacher_edit_firstname_error }}
                </mat-error>
                <mat-error *ngIf="rForm.get('firstname').hasError('hasDigits')">
                  {{ translatedText.admin_teacher_edit_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-label>{{ translatedText.admin_teacher_edit_lastname }}</mat-label>
              <mat-form-field floatLabel="never" class="teacher-edit-input">
                <input
                  matInput
                  formControlName="lastname"
                  type="text"
                  placeholder="{{ translatedText.admin_teacher_edit_lastname }}"
                  required
                />
                <mat-error *ngIf="rForm.get('lastname').hasError('required')">
                  {{ translatedText.admin_teacher_edit_lastname_error }}
                </mat-error>
                <mat-error *ngIf="rForm.get('lastname').hasError('hasDigits')">
                  {{ translatedText.admin_teacher_edit_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-label>{{ translatedText.admin_teacher_edit_email }}</mat-label>
              <mat-form-field class="teacher-edit-input">
                <input matInput placeholder="{{ teacherEmail }}" disabled />

                <!-- <mat-form-field floatLabel="never">
               <input matInput formControlName="email" type="text" placeholder="{{translatedText.admin_teacher_edit_email}}" required readonly="readonly"><mat-error>{{translatedText.admin_teacher_edit_email_error}}</mat-error>
                <mat-error *ngIf="rForm.get('email').hasError('email')">
               {{translatedText.admin_teacher_edit_email_error_valid}}
                </mat-error>  </mat-form-field> -->
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-label>{{ translatedText?.admin_teacher_create_position }}*</mat-label>
              <mat-form-field floatLabel="never" matInput type="text" required aria-label="teacher role">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-select [(value)]="teacherPosition" formControlName="position" panelClass="admin-group-select">
                  <mat-option value="school_admin">{{
                    translatedText?.admin_teacher_create_position_school_management
                  }}</mat-option>
                  <mat-option value="teacher" selected>{{
                    translatedText?.admin_teacher_create_position_teacher_form
                  }}</mat-option>
                </mat-select>
                <!--  <mat-error *ngIf="rForm.get('position').hasError('required')">
                  {{ translatedText?.admin_teacher_create_position_error }}
                </mat-error> -->
              </mat-form-field>
            </div>
            <!-- select classes -->
            <!-- <div class="col-md-12 organize-classes">
              <p class="paragraph paragraph--bold paragraph--m">{{ translatedText.admin_teacher_edit_assign_title }}</p>
              <div class="input-label-wrap">
                <mat-label>{{ translatedText.admin_teacher_edit_assign_classes }}</mat-label>
                <p>{{ translatedText.admin_teacher_edit_max_3 }}</p>
              </div>
              <mat-form-field
                ><mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-select
                  formControlName="groups"
                  [max]="3"
                  panelClass="admin-group-select"
                  disableOptionCentering
                  multiple
                  ><mat-select-trigger>
                    <div class="selected-group-container">
                      <ng-container *ngFor="let selectedText of getSelectedText(); let i = index">
                        <div class="selected-group">
                          <p class="paragraph paragraph--bold paragraph--xs">
                            {{ selectedText }}
                          </p>
                          <mat-icon
                            matSuffix
                            svgIcon="streamline_outlined:close"
                            (click)="removeSelectedGroup(i, $event)"
                          ></mat-icon>
                        </div>
                      </ng-container>
                    </div>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let group of groups"
                    [value]="group._id"
                    [disabled]="group?._teacher?.length === 3"
                  >
                    {{ group.name }} - {{ group.school_year | slice: 2 }}
                    <ng-container *ngIf="group?._teacher.length > 0">
                      <div class="group-select_teachers">
                        <ng-container *ngFor="let teacher of group?._teacher">
                          <div class="teacher_with_icon teacher_with_icon--gray option">
                            <button mat-mini-fab>
                              <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                            </button>
                            <p class="paragraph paragraph--s">{{ teacher.firstname }} {{ teacher.lastname }}</p>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>
        </div>
      </div>
      <div class="edit-teacher_button">
        <button mat-flat-button color="secondary" (click)="closeOverlay()" [disabled]="isLoading">
          {{ translatedText.admin_teacher_edit_cancel }}
        </button>
        <button
          [disabled]="rForm.invalid || isLoading"
          mat-flat-button
          color="primary"
          (click)="submitForm(formDirective)"
        >
          <span *ngIf="!isLoading"> {{ translatedText.admin_teacher_edit_save }} </span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </form>
  </div>
</section>
