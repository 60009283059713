<button
  mat-mini-fab
  type="button"
  [mat-dialog-close]="'close'"
  class="close-button close-button--right"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>

<div class="expert_group_wrap">
  <h3 class="h3 h3--bold expertgroup_title">
    {{ translatedText.expert_group_headline }}
    <img
      class="expert_group-logo"
      src="../../../assets/laletu-logo.svg"
      alt=""
    />
    {{ translatedText.expert_group_headline_1 }}
  </h3>
  <h3 class="h3 h3--bold expertgroup_title">
    {{ translatedText.expert_group_headline_2 }}
  </h3>

  <div class="row exert_group_info">
    <div class="col-md-3">
      <mat-icon class="material-icons_list">redeem</mat-icon>
      <p class="paragraph paragraph--xl paragraph--bold">
        {{ translatedText.expert_group_advantages }}
      </p>
      <p class="paragraph">
        {{ translatedText.expert_group_advantages_text }}
      </p>
    </div>
    <div class="col-md-3">
      <mat-icon class="material-icons_list">checklist</mat-icon>
      <p class="paragraph paragraph--xl paragraph--bold">
        {{ translatedText.expert_group_active }}
      </p>
      <p class="paragraph">{{ translatedText.expert_group_active_text }}</p>
    </div>
    <div class="col-md-3">
      <mat-icon class="material-icons_list">forum</mat-icon>
      <p class="paragraph paragraph--xl paragraph--bold">
        {{ translatedText.expert_group_opinion }}
      </p>
      <p class="paragraph">{{ translatedText.expert_group_opinion_text }}</p>
    </div>
    <div class="col-md-3">
      <mat-icon class="material-icons_list">groups</mat-icon>
      <p class="paragraph paragraph--xl paragraph--bold">
        {{ translatedText.expert_group_exchange }}
      </p>
      <p class="para graph">{{ translatedText.expert_group_exchange_text }}</p>
    </div>
    <form [formGroup]="rForm" #formDirective="ngForm">
      <div class="agreement_box">
        <mat-checkbox formControlName="isExpert" required>
          <p
            class="paragraph paragraph paragraph--s"
            [ngClass]="{ text_error: rForm.get('isExpert').value === false }"
          >
            {{ translatedText.expert_group_acceptance }}
          </p>
        </mat-checkbox>
      </div>

      <button
        mat-flat-button
        color="primary"
        class="teacher_button_primary"
        [disabled]="rForm.invalid"
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.expert_group_save }}
      </button>
    </form>

    <div class="expertgroup_footer">
      <h3 class="h3 h3--bold expertgroup_title">
        <img
          class="expert_group-logo"
          src="../../../assets/laletu-logo.svg"
          alt=""
        />
        {{ translatedText.expert_group_title }}
      </h3>
    </div>
  </div>
</div>
