import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {}

/**
 * Teacher edit form
 */
@Component({
  selector: 'app-school-edit',
  templateUrl: './school-edit.component.html',
  styleUrls: ['./school-edit.component.scss'],
})
export class SchoolEditComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  userTypes: string[];
  isLoading: boolean = false;
  school_id;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private schoolService: SchoolService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['school_id'] !== 'undefined') {
        this.school_id = this.injectedData['school_id'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    // Get user types
    this.userTypes = this.authService.getHierachicalRoles();

    this.rForm = this._formBuilder.group({
      name: ['', Validators.required],
      school_type: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', [Validators.required, this.onlyDigitsValidator()]],
      address: ['', Validators.required],
      latitude: [
        '',
        [Validators.required, this.onlyNumbersAndDecimalValidator()],
      ],
      longitude: [
        '',
        [Validators.required, this.onlyNumbersAndDecimalValidator()],
      ],
    });

    this.schoolService.getSchool(this.school_id);
    this.schoolService
      .getSchoolUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.rForm.get('name').setValue(res['name']);
        this.rForm.get('school_type').setValue(res['school_type']);
        this.rForm.get('state').setValue(res['state']);
        this.rForm.get('city').setValue(res['city']);
        this.rForm.get('zip').setValue(res['zip']);
        this.rForm.get('address').setValue(res['address']);
        this.rForm.get('latitude').setValue(res['location'].coordinates[0]);
        this.rForm.get('longitude').setValue(res['location'].coordinates[1]);
        console.log(res);
      });
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  onlyNumbersAndDecimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\d*\.?\d*$/.test(value)) {
        return { onlyNumbersAndDecimal: true };
      }
      return null;
    };
  }

  onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\d*$/.test(value)) {
        return { onlyDigits: true };
      }
      return null;
    };
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  markFormAsUntouched() {
    Object.keys(this.rForm.controls).forEach((key) => {
      this.rForm.get(key).markAsUntouched();
    });
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      console.log('Form invalid');
      return;
    }

    let school = {
      schoolId: this.school_id,
      name: this.rForm.value.name,
      school_type: this.rForm.value.school_type,
      state: this.rForm.value.state,
      city: this.rForm.value.city,
      zip: this.rForm.value.zip,
      address: this.rForm.value.address,
    };

    let location = {
      type: 'Point',
      coordinates: [this.rForm.value.latitude, this.rForm.value.longitude],
    };

    school['location'] = location;

    this.schoolService.updateSchool(school).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.school_edit_success,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error: any) => {
        this.isLoading = false;
        console.log(error);
        this.snackBar.open(this.translatedText?.technical_error, '', {
          panelClass: 'snack-error',
          duration: 3000,
          horizontalPosition: 'right',
        });
      },
    });
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
