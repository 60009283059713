import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HintBoxModule } from 'src/app/helpers/hint-box/hint-box.module';
import { UpgradeDialogModule } from 'src/app/trial-phase/upgrade-banner/upgrade-banner.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { LoadingSpinnerModule } from '../../../helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from '../../../material.module';
import { AdminGroupCreateStepperComponent } from './admin-group-create-stepper/admin-group-create-stepper.component';
import { AdminGroupCreateComponent } from './admin-group-create.component';
import { CreateLearnersModule } from 'src/app/helpers/create-learners/create-learners.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    DirectivesModule,
    HintBoxModule,
    UpgradeDialogModule,
    CreateLearnersModule,
  ],
  declarations: [AdminGroupCreateComponent, AdminGroupCreateStepperComponent],
  exports: [AdminGroupCreateComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AdminGroupCreateModule {}
