import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { TimemodeOverlayComponent } from './timemode-overlay.component';

@NgModule({
  declarations: [TimemodeOverlayComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [TimemodeOverlayComponent],
})
export class TimemodeOverlayModule {}
