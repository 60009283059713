import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { CustomPaginatorIntl } from 'src/app/directives/custom-paginator-intl/custom-paginator-intl.derective';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { ProgressBarModule } from 'src/app/helpers/progress-bar/progress-bar.module';
import { SelectAllOptionModule } from 'src/app/helpers/select-all-option/select-all-option.module';
import { MaterialModule } from 'src/app/material.module';
import { SchoolDetailComponent } from './school-detail.component';

@NgModule({
  declarations: [SchoolDetailComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    LoadingSpinnerModule,
    DirectivesModule,
    SelectAllOptionModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [SchoolDetailComponent],
})
export class SchoolDetailModule {}
