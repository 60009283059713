import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { BookComplexityRatingComponent } from './book-complexity-rating.component';

@NgModule({
  declarations: [BookComplexityRatingComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [BookComplexityRatingComponent],
})
export class BookComplexityRatingModule {}
