import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-loaded',
  templateUrl: './not-loaded-component.html',
  styleUrls: ['./not-loaded.component.scss'],
})
export class NotLoadedComponent implements OnInit {
  isOffline: boolean = false;
  constructor() {}

  refreshPage() {
    window.location.reload();
  }
  ngOnInit(): void {
    if (!window.navigator.onLine) {
      this.isOffline = true;
    }
  }
}
