import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'parents-form',
  templateUrl: './parents-form.component.html',
  styleUrls: ['./parents-form.component.scss'],
})
export class ParentsFormComponent implements OnInit, OnDestroy {
  personalForm: FormGroup;
  checked = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.initForm();
  }

  initForm() {
    this.personalForm = this._formBuilder.group({
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator()]],
      phoneNum: ['', this.onlyDigitsValidator()],
      kidsAge: ['', this.onlyDigitsValidator()],
      state: [''],
      school: [''],
      reasonOfInterest: [''],
      honey: [''],
    });
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (control.value && !emailRegex.test(control.value)) {
        return { invalidEmail: true };
      }
      return null;
    };
  }

  onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      let notNumRegex = /^\d+$/;
      if (value && !notNumRegex.test(value)) {
        return { invalidPhone: true };
      }
      return null;
    };
  }

  submitForm() {
    if (this.personalForm.value.honey != '') {
      // report as bot submission
      return;
    }
    this.authService
      .createParentRequestLead({
        firstname: this.personalForm.value.firstname,
        lastname: this.personalForm.value.lastname,
        email: this.personalForm.value.email,
        phoneNum: this.personalForm.value.phoneNum,
        kidsAge: this.personalForm.value.kidsAge,
        state: this.personalForm.value.state,
        school: this.personalForm.value.school,
        reasonOfInterest: this.personalForm.value.reasonOfInterest,
      })
      .subscribe((res) => {
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: 'Vielen Dank für Ihr Interesse.',
          duration: 3000,
          horizontalPosition: 'right',
        });
        this.checked = false;
        this.personalForm.reset();
        this.router.navigate(['/home']);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
