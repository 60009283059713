<button
  mat-icon-button
  type="button"
  [mat-dialog-close]="'close'"
  class="close-button close-button--right mdc-fab mdc-fab--mini mat-mdc-mini-fab mat-accent mat-mdc-button-base"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>

<div class="pin-code_wrap" *ngIf="overlayView == ''">
  <h3 class="h3 h3--bold">{{ translatedText.pin_code_title }}</h3>
  <p class="paragraph paragraph--xl text-center">{{ translatedText.pin_code_desc }}</p>

  <form [formGroup]="pinForm" #pinFormElement="ngForm">
    <div formArrayName="digits" class="pin-code_inputs">
      <input
        *ngFor="let field of pinForm.get('digits')?.controls; let i = index"
        #inputs
        [maxLength]="1"
        [formControlName]="i"
        (keydown)="check(i, field, $event)"
        required
      />
    </div>
    <div class="instructionVideo_wrap">
      <button
        mat-flat-button
        color="secondary"
        type="button"
        class="mat-flat-button--full"
        aria-label="cancel"
        [mat-dialog-close]="'close'"
      >
        {{ translatedText.pin_code_close }}
      </button>

      <button
        mat-flat-button
        color="primary"
        (click)="submitForm(pinFormElement)"
        type="submit"
        class="mat-flat-button--full"
        aria-label="Connect"
        [disabled]="pinForm.invalid"
        #submitButton
      >
        {{ translatedText.pin_code_connect }}
      </button>
    </div>
  </form>
</div>

<div class="pin-code_wrap" *ngIf="overlayView == 'teacherView'">
  <img class="pin-img" src="../../../assets/pin-code.svg" alt="" />
  <h3 class="h3 h3--bold">{{ translatedText.pin_code_help_desk }}</h3>
  <p class="paragraph paragraph--xl text-center">{{ translatedText.pin_code_help_desk_desc }}</p>

  <form [formGroup]="pinForm" #pinFormElement="ngForm">
    <div formArrayName="digits" class="pin-code_inputs">
      <input
        *ngFor="let field of pinForm.get('digits')?.controls; let i = index"
        #inputs
        [maxLength]="1"
        [formControlName]="i"
        (keydown)="check(i, field, $event)"
        required
        [readOnly]="readonly"
      />
    </div>
    <div class="instructionVideo_wrap">
      <button
        mat-flat-button
        color="primary"
        type="button"
        class="mat-flat-button--full"
        aria-label="cancel"
        (click)="copyToClipboard()"
      >
        <mat-icon svgIcon="streamline_outlined:copy"></mat-icon>{{ translatedText.pin_code_copy }}
      </button>
    </div>
  </form>
</div>
