import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';
export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-archivments-overlay',
  templateUrl: './archivments-overlay.component.html',
  styleUrls: ['./archivments-overlay.component.scss'],
})
export class ArchivmentsOverlayComponent implements OnInit {
  award; // TODO: unused?
  userId: string;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['award'] !== 'undefined') {
        this.award = this.injectedData['award'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userId = this.authService.getUserId();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
