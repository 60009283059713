import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { LicenseAddComponent } from 'src/app/license/license-add/license-add.component';
import { LicenseDeclineComponent } from 'src/app/license/license-decline/license-decline.component';
import { LicenseTrialExtensionComponent } from 'src/app/license/license-trial-extension/license-trial-extension.component';
import { GroupData, GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ExpertGroupComponent } from '../../helpers/expert-group/expert-group.component';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
//import { AdminTeacherCreateComponent } from '../admin-teacher/admin-teacher-create/admin-teacher-create.component';
import { AdminTeacherCreateComponent } from '../admin-teacher/admin-teacher-create/admin-teacher-create.component';
import { ClassesYearUpdateComponent } from 'src/app/helpers/classes-year-update/classes-year-update.component';
import { AdminService } from 'src/app/providers/admin.service';
//import { LicenseTrialExtensionComponent } from 'src/app/license/license-trial-extension/license-trial-extension.component';
//import { ActivatedRoute } from '@angular/router';

export interface License {
  _id?: string;
  teacher?: string;
  student?: any;
  group?: string;
  license_type?: string;
  requested?: Date;
  action?: boolean;
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'teacher',
    'amount',
    'group',
    'type_key',
    'created',
    'action',
  ];
  page = 1;
  limit = 10;
  sortBy = 'student';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValue: string = '';
  filterValues = {
    search: '',
  };
  pageEvent: PageEvent;
  LicenseRequestArray = [];
  licensesToSchool: any = [];
  groupCount = 0;
  studentCount = 0;
  teacherCount = 0;
  teacherId: string;
  isLoading: boolean = true;
  isOffline: boolean = false;
  licensesToSchoolLoading: boolean = true;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  expiryDate: any = moment('2024-06-18').format('YYYY-MM-DD');
  nextSchoolYear: string | null = null;
  updateRequiredGroupCount: number = 0;
  translatedText: any;
  trial_expiry: boolean = false;
  isSchoolYearUpdateRequired: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private decimalPipe: DecimalPipe,
    private authService: AuthService,
    private translationService: TranslationService,
    private groupService: GroupService,
    private licenseService: LicenseService,
    private teacherService: TeacherService,
    private adminService: AdminService,
  ) {}

  /**
   * API call on licenseService to retrieve requested licnese with sorted direction
   */
  onSortChange(event: MatSort) {
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
  }

  ngOnInit() {
    this.getAdminDashboardOverview();
    this.getLicenses();

    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();

    this.trialStatus = this.authService.getUserTrialStatus();
    this.authService.getCurrentTrialStatus().subscribe({
      next: (trialStatus) => {
        this.trialStatus = trialStatus;
        console.log('Admin dashboard status', this.trialStatus);
      },
    });

    if (!window.navigator.onLine) {
      this.isOffline = true;
    }
    this.getSchoolYearUpdateInfo();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  updateTeacher() {
    this.isLoading = true;
    let formData = {
      teacherId: this.teacherId,
      report: 'expert-info',
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {},
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
        this.isLoading = false;
      },
    });
  }

  openExpertGroupOverlay() {
    let dialogRef = this.dialog.open(ExpertGroupComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'expert-group-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe();
  }

  openLicensetrialExtension() {
    let dialogRef = this.dialog.open(LicenseTrialExtensionComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe();
  }

  classesYearUpdate() {
    let dialogRef = this.dialog.open(ClassesYearUpdateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'classes-year-updated-popover',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {},
    });
  }
  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        console.log(this.licensesToSchool);
        this.licensesToSchoolLoading = false;
      });
  }

  getLicenseAmount(tag: string, type: string) {
    let amount = 0;
    if (this.licensesToSchool.length > 0) {
      this.licensesToSchool
        .filter(
          (el) =>
            el.status == 'active' && new Date(el.expiration_date) > new Date(),
        )
        .map((el) => {
          if (el.type_key == tag) {
            if (type == 'total') {
              amount += el.amount;
            } else {
              amount += el.amount_left;
            }
          }
        });
      if (tag == 'fo') {
        amount = amount > 0 ? amount : amount;
      }
    }
    return amount;
  }

  checkForUnlimitedLicense(tag) {
    return this.licenseService.checkForUnlimitedLicense(
      tag,
      this.licensesToSchool,
    );
  }

  getFormattedLicenseAmount(tag: string, type: string) {
    const amount = this.getLicenseAmount(tag, type);
    return this.decimalPipe.transform(amount, '1.0-0');
  }

  openAddLicense(): void {
    let dialogRef = this.dialog.open(LicenseAddComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        overlayView: 'teacherView',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.licenseService.getLicensesToSchool();
      },
    });
  }

  getAdminDashboardOverview() {
    this.groupService.getDashboardSummary().subscribe((data) => {
      console.log(data);
      this.isLoading = false;
      this.groupCount = data?.groupCount;
      this.teacherCount = data?.teacherCount;
      this.studentCount = data?.studentCount;
    });
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      this.isSchoolYearUpdateRequired = data.yearChange;
      this.nextSchoolYear = data.nextYear;
      if (data && data.yearChange) {
        this.getGroups();
      }
    });
  }
  getGroups() {
    this.adminService
      .getGroupList(1, 100, 'name', 1, this.filterValues)
      .pipe(
        map((groupData: GroupData) => {
          // Filter Archieved classes
          const allGroups = groupData.data.filter((item: any) => {
            return !item.archived && item.school_year !== this.nextSchoolYear;
          });
          this.updateRequiredGroupCount = allGroups.length;
          if (this.updateRequiredGroupCount >= 1) {
            this.classesYearUpdate();
          }
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  updateLicenseRequest(requestId, status, teacher) {
    let data = {
      requestId: requestId,
      status: status,
    };
    this.licenseService.updateSingleRequest(data).subscribe({
      next: (res) => {
        this.licenseService.getRequestsToSchool();
        let statusText = status == 'accept' ? 'akzeptiert' : 'abgelehnt';
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: 'Lizenze Anfrage von ' + teacher + ' wurde ' + statusText,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  createTeacherOverlay() {
    let dialogRef = this.dialog.open(AdminTeacherCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        // Refresh groups on dashboard
        //this.getGroupsByTeacher();
      },
    });
  }

  licenseDeclineOverlay(license) {
    let dialogRef = this.dialog.open(LicenseDeclineComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        license: license,
      },
    });
    dialogRef.afterClosed().subscribe();
  }
}
