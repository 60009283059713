import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';
import { BookReaderService } from '../book-reader-service';

export class Book {
  label: string;
  file: string;
}

@Component({
  selector: 'app-book-reader-settings',
  templateUrl: './book-reader-settings.component.html',
  styleUrls: ['./book-reader-settings.component.scss'],
})
export class BookReaderSettingsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input('bookReaderType') bookReaderType: string = 'get';
  readerContent: string = '';
  maxCharcount;
  paragraphClass;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private bookReaderService: BookReaderService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bookReaderSettingsRef: MatBottomSheetRef<BookReaderSettingsComponent>,
  ) {}

  ngOnInit() {
    this.maxCharcount = this.data.maxCharcount;
    this.paragraphClass = this.data.paragraphClass;
  }

  updateMaxCharcount(maxCharcount) {
    this.maxCharcount = maxCharcount.value;
    console.log(maxCharcount);
  }

  updateParagraphClass(paragraphClass) {
    this.paragraphClass = paragraphClass.value;
  }

  updateSetting() {
    // this.bookReaderService.setMaxCharcount(this.maxCharcount);
    this.bookReaderService.setParagraphClass(this.paragraphClass);
    this.bookReaderSettingsRef.dismiss();
  }

  /**
   * ngOnChanges / render the selected Epub
   */
  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
