<app-school-admin-create-stepper class="school_admin-stepper" #stepper [linear]="true" [translatedText]="translatedText"
  [stepValue]="selectedIndex" (selectionChange)="getActiveSelection($event)">
  <!-- STEP 1 -->
  <cdk-step [stepControl]="personalForm" #step1="cdkStep">
    <div class="school_admin-wrap">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_personal_information }}
        </h2>
      </div>
      <form [formGroup]="personalForm" #formDirective="ngForm">
        <div class="form-wrapper">
          <div class="input-flex">
            <div class="input-flex-item">
              <p>{{ translatedText.school_admin_create_firstname }}</p>
              <mat-form-field floatLabel="always">
                <input matInput formControlName="firstname" type="text" required
                  placeholder="{{ translatedText.school_admin_create_firstname }}" />
                <mat-error *ngIf="personalForm.get('firstname').hasError('required')">
                  {{ translatedText.school_admin_create_firstname_error }}
                </mat-error>
                <mat-error *ngIf="personalForm.get('firstname').hasError('hasDigits')">
                  {{ translatedText.school_admin_create_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-flex-item">
              <p>{{ translatedText.school_admin_create_lastname }}</p>
              <mat-form-field>
                <input matInput formControlName="lastname" type="text" required
                  placeholder="{{ translatedText.school_admin_create_lastname }}" />
                <mat-error *ngIf="personalForm.get('lastname').hasError('required')">
                  {{ translatedText.school_admin_create_lastname_error }}
                </mat-error>
                <mat-error *ngIf="personalForm.get('lastname').hasError('hasDigits')">
                  {{ translatedText.school_admin_create_hasDigits_error }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <p>{{ translatedText.school_admin_create_salutation }}</p>
            <mat-form-field>
              <input matInput aria-label="Anrede" [matAutocomplete]="auto" formControlName="salutation" required
                placeholder="{{ translatedText.school_admin_create_salutation }}" />
              <mat-icon class="register-icon" matTooltipClass="salutation-tooltip"
                matTooltip="{{ translatedText.admin_teacher_create_salutation_tooltip }}"
                svgIcon="streamline_outlined:hint"></mat-icon>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  <span>{{ item }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="personalForm.get('salutation').hasError('required')">
                {{ translatedText.school_admin_create_salutation_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-width">
            <p>{{ translatedText.school_admin_create_email }}</p>
            <mat-form-field>
              <input matInput formControlName="email" type="text" required
                placeholder="{{ translatedText.school_admin_create_email }}" />
              <mat-error *ngIf="personalForm.get('email').hasError('required')">
                <!-- Please enter email address. -->
                {{ translatedText.school_admin_create_email_error }}
              </mat-error>
              <mat-error *ngIf="personalForm.get('email').hasError('invalidEmail')">
                <!-- Please enter valid email address. -->
                {{ translatedText.school_admin_create_email_error_valid }}
              </mat-error>
              <mat-error *ngIf="personalForm.get('email').hasError('emailTaken')">
                <!-- Email is taken -->
                {{ translatedText.school_admin_create_email_taken }}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="personal-info">
                  <div class="row">
                    <div class="col-md-12 form_header-headline">
                      <p class="paragraph paragraph--bold paragraph--m">License Informationen</p>
                    </div>
                    <div>
                      <mat-label>License code</mat-label>
                      <mat-form-field>
                        <input matInput formControlName="license" type="text" placeholder="License" required>
                        <mat-error *ngIf="personalForm.get('firstname').hasError('required')">
                          Bitte tragen Sie einen Vornamen ein
                        </mat-error>
                        <mat-error *ngIf="personalForm.get('firstname').hasError('email')">
                          Bitte tragen Sie eine E-Mail-Adresse ein
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div> -->
        </div>
      </form>
      <div class="training-task_select_button step_1">
        <button [disabled]="personalForm.invalid" mat-flat-button color="primary" (click)="EmailAvailabilty()">
          {{ translatedText.school_admin_create_next }}
        </button>
        <div class="footer-wrap">
          <p class="paragraph">
            {{ translatedText.school_admin_create_already_account }}
          </p>
          <a class="links" [routerLink]="['/login/teacher']">{{ translatedText.school_admin_create_login }}</a>
        </div>
        <img [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
  <!-- STEP 2 -->
  <cdk-step [stepControl]="licenseForm">
    <div class="school_admin-wrap">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_license_code_enter }}
        </h2>
        <div class="license-info">
          <mat-icon svgIcon="streamline_outlined:info-empty"></mat-icon>
          <p class="paragraph">
            {{ translatedText.school_admin_create_license_code_enter_text }}
          </p>
        </div>
      </div>
      <form [formGroup]="licenseForm" #formDirective="ngForm">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field [ngClass]="{
                'mat-form-field-invalid': licenseError,
                'ng-valid': !licenseError
              }">
              <input matInput formControlName="license" type="text"
                placeholder="{{ translatedText.school_admin_create_license_code }}" required />
              <mat-error *ngIf="licenseForm.get('license').hasError('required')">
                {{ translatedText.school_admin_create_license_code_enter_error }}
              </mat-error>
            </mat-form-field>
            <p class="paragraph paragraph--s error" *ngIf="licenseError">
              {{ translatedText.school_admin_create_license_code_error }}
            </p>
          </div>
        </div>
      </form>
      <div class="training-task_select_button">
        <button [disabled]="licenseForm.invalid" mat-flat-button color="primary" class="mat-flat-button--full"
          (click)="checkLicense(licenseForm.get('license').value)">
          {{ translatedText.school_admin_create_next }}
        </button>
        <img  [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
  <!-- STEP 3 -->
  <cdk-step [stepControl]="licenseForm">
    <div class="school_admin-wrap">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_choose_school }}
        </h2>
      </div>
      <div class="col-md-12">
        <mat-label>{{ translatedText.school_admin_create_school }}</mat-label>
        <mat-form-field>
          <input type="text" matInput id="schoolName" #schoolNameInput [formControl]="schoolName"
            (input)="onSearchInputFilterChange($event)" [matAutocomplete]="schoolAutoComplete"
            placeholder="{{ translatedText.school_admin_create_search_school }}" />
          <mat-autocomplete autoActiveFirstOption #schoolAutoComplete="matAutocomplete">
            <mat-option *ngFor="let school of filteredSchools | async" [value]="getSchoolSelectionName(school)"
              (click)="getSelectedSchool(school)">
              <span class="name">{{ school.zip }} {{ school.city
                }}<ng-container *ngIf="school.name"> | {{ school.name }}</ng-container> |
                {{ school.school_type }}</span>
              | <span class="address">{{ school.address }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="training-task_select_button">
        <button [disabled]="schoolId == undefined || schoolName.value == '' || isHepldesk"
          (click)="schoolSelection('selected')" mat-flat-button color="primary" class="mat-flat-button--full">
          {{ translatedText.school_admin_create_next }}
        </button>
        <div class="footer-wrap">
          <p class="paragraph">
            {{ translatedText.school_admin_create_not_found_school }}
          </p>
          <a class="links" (click)="schoolSelection('create')">{{
            translatedText.school_admin_create_create_new_school
          }}</a>
        </div>
        <img [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="createSchool"> </cdk-step>
  <!-- STEP 4 -->
  <cdk-step [stepControl]="confirmStep">
    <div class="school_admin-wrap school_admin-wrap--full-height wider">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText.school_admin_create_check_data }}
        </h2>
      </div>

      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_admin_information }}
        </p>
        <div class="confirm_info-box">
          <div class="row first_row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_salutation }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('salutation').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_firstname }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('firstname').value }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_lastname }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('lastname').value }}
              </p>
            </div>
            <button mat-icon-button class="confirm_info-edit-button" color="filled" aria-label="edit"
              (click)="navigateToPersonalInfo()">
              <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
            </button>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_email }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ personalForm.get('email').value }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_license_information }}
        </p>
        <div class="confirm_info-box">
          <div class="row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_diagnostic_license }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ licenseInfo[0]?.amount >= 100 ? '∞' : licenseInfo[0]?.amount }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_training_license }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ licenseInfo[1]?.amount }}
              </p>
            </div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>

      <div class="confirm_info">
        <p class="paragraph paragraph--bold paragraph--s">
          {{ translatedText.school_admin_create_school_information }}
        </p>
        <div class="confirm_info-box">
          <button mat-icon-button class="confirm_info-edit-button" color="filled" aria-label="edit"
            (click)="navigateToSchoolSelection()">
            <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
          </button>
          <div class="row first_row">
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_name }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ selectedSchool?.name }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_type }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ selectedSchool?.school_type }}
              </p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_state }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ getStateFullName(selectedSchool?.state) }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_city }}
              </p>
              <p class="paragraph paragraph--bold">{{ selectedSchool?.zip }} {{ selectedSchool?.city }}</p>
            </div>
            <div class="col-4">
              <p class="paragraph paragraph--s">
                {{ translatedText.school_admin_create_school_address }}
              </p>
              <p class="paragraph paragraph--bold">
                {{ selectedSchool?.address }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="privacy-checkbox-wrap">
        <mat-checkbox #privacyCheckbox class="example-margin"></mat-checkbox>
        <p [innerHTML]="legalMessage"></p>
      </div>
      <div class="training-task_select_button confirm-step">
        <button mat-flat-button color="primary" [disabled]="isLoading || !privacyCheckbox.checked" mat-flat-button
          (click)="submitForm(formDirective)">
          <span>{{ translatedText.school_admin_create_finish_registration }}</span></button><img
          [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
  <!-- STEP 5 -->
  <cdk-step [stepControl]="lastStep">
    <div class="school_admin-wrap last-step">
      <img src="../../../../assets/sign-up.svg" alt="sign-up" />
      <h2 class="h2 h2--bold">
        {{ translatedText.school_admin_create_send_email_firstpart }}
        {{ maskEmail(personalForm.get('email').value) }}
        {{ translatedText.school_admin_create_send_email_secondpart }}
      </h2>
      <p class="paragraph paragraph--xl">
        {{ translatedText.school_admin_create_send_email_info }}
      </p>
      <div class="training-task_select_button resend-email">
        <button mat-flat-button color="primary" (click)="resendRegistrationEmail()">
          {{ translatedText.school_admin_create_send_email_resend }}
        </button>
        <img [src]="'/assets/' + environment.whitelabel.generic_logo" class="logo" alt="" />
      </div>
    </div>
  </cdk-step>
</app-school-admin-create-stepper>
