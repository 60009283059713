import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import moment from 'moment';
import { StudentService } from 'src/app/providers/student.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { CdkStepper } from '@angular/cdk/stepper';
import { PasswordsWarnComponent } from 'src/app/helpers/passwords-warn/passwords-warn.component';
import { DatePipe } from '@angular/common';
import { GroupService } from 'src/app/providers/group.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { ActivatedRoute } from '@angular/router';

export interface DialogData {}
export interface Languages {
  name: string;
  code: string;
}

@Component({
  selector: 'app-admin-managing',
  templateUrl: './admin-group-managing.component.html',
  styleUrls: ['./admin-group-managing.component.scss'],
})
export class AdminGroupManagingComponent implements OnInit, OnDestroy {
  group;
  selectedIndex: number;
  firstStep;
  secondStep;
  lastFormStep;
  rForm: FormGroup;
  withNames: boolean = false;
  withoutNames: boolean = false;
  onlyGroupOverview: boolean = false;
  completeGroupOverview: boolean = false;
  isClosedDialog: boolean = false;
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  userType;
  maxBirthday;
  languages;
  students;
  filteredOptions: Observable<Languages[]>[] = [];
  isLoading: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('passwordSelection') passwordSelection: boolean;
  blobUrl;
  teacherId;
  translatedText: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private authService: AuthService,
    private groupService: GroupService,
    private studentService: StudentService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['group'] !== 'undefined') {
        this.group = this.injectedData['group'];
      }
    }
  }

  ngOnInit() {
    this.userType = this.authService.getType();
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]).format('YYYY-MM-DD');
    this.teacherId = this.authService.getTeacherId();
    this.students = this.group?._students;
    console.log(this.students);
    this.maleCount = this.students.filter(
      (item) => item.gender == 'male',
    ).length;
    this.femaleCount = this.students.filter(
      (item) => item.gender == 'female',
    ).length;
    this.diversCount = this.students.filter(
      (item) => item.gender == 'divers',
    ).length;

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.studentService.getLanguages().subscribe((res) => {
      this.languages = res;
      for (let index = 0; index < this.students.length; index++) {
        this.setLanguages(this.students, index);
      }
    });
    this.initForm();

    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  chechStudentsGender() {
    return this.students.filter((item) => item.gender == '').length;
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code == code)[0]?.code
        ]
      : '';
  }

  validadeStudentField(index: number) {
    return (this.rForm.get('students') as FormArray).at(index) as FormGroup;
  }

  initForm() {
    this.rForm = this._formBuilder.group({
      students: this._formBuilder.array([
        //this.createStudentArray('')
      ]),
    });
    this.addStudentsToForm();
  }

  createStudentArray(student): FormGroup {
    return this._formBuilder.group({
      id: student._id,
      groupId: this.group?._id,
      teacher: student._id,
      name: '',
      gender: [student.gender, Validators.required],
      language: [
        student.language,
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [student.age],
    });
  }

  getStudentInfo(studentId) {
    return this.students.filter((student) => student._id == studentId)[0];
  }

  addStudentsToForm() {
    const formArray = new FormArray([]);

    this.students.forEach((student) => {
      console.log(student);
      formArray.push(this.createStudentArray(student));
    });

    this.rForm.setControl('students', formArray);

    for (let index = 0; index < this.students.length; index++) {
      this.setLanguages(this.students, index);
    }
  }

  getStudentsFormControls(): AbstractControl[] {
    return (<FormArray>this.rForm?.get('students'))?.controls;
  }

  setLanguages(students, index) {
    this.filteredOptions[index] = (
      (this.rForm.get('students') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages?.slice()
              : [];
        }),
      );
    this.patchLanguage(students, index);
  }

  patchLanguage(students, index) {
    ((this.rForm.get('students') as FormArray).at(index) as FormGroup)
      .get('language')
      .setValue(students[index]['language']);
  }

  setLanguageOption(value, index) {
    ((this.rForm.get('students') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  genderSelection() {
    setTimeout(() => {
      this.maleCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'male').length;
      this.femaleCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'female').length;
      this.diversCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'divers').length;
    }, 0);
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.stepper.selectedIndex = 3;

    this.regeneratePdfForGroup();
    // this.groupService.createGroupWithPDF().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe((response:any) => {
    //   if (response) {
    //     let dataType = response.type;
    //     let binaryData = [];
    //         binaryData.push(response);
    //     let blob = new Blob(binaryData, { type: 'application/pdf' });
    //     let blobUrl = window.URL.createObjectURL(blob);
    //     this.blobUrl = blobUrl;
    //     //this.groupService.openPdfOverlay(blobUrl, 'multiple');
    //     this.isLoading = false;
    //   }
    // },(error: any) => {
    //   let message = this.translatedText?.technical_error
    //   this.snackBar.openFromComponent(ErrorSnackbarComponent, {
    //     panelClass: 'snack-error',
    //     data: message,
    //     duration: 3000,
    //     horizontalPosition: 'left'
    //   });
    // });
  }

  downloadPDF() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const formattedDate = this.datePipe.transform(
      currentDate,
      `${year}${month}${day}_`,
    );
    var element = document.createElement('a');
    element.setAttribute('href', this.blobUrl);
    element.setAttribute('download', formattedDate + this.group?.name + '.pdf');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.dialog.closeAll();
  }

  removeValidations() {
    const formArray = this.rForm.get('students') as FormArray;
    let count = this.students.length;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;

      if (formGroup != undefined) {
        formGroup.get('gender').clearValidators();
        formGroup.get('language').clearValidators();
        formGroup.get('age').clearValidators();

        formGroup.get('gender').updateValueAndValidity();
        formGroup.get('language').updateValueAndValidity();
        formGroup.get('age').updateValueAndValidity();
      }
    }
  }

  setValidation() {
    const formArray = this.rForm.get('students') as FormArray;
    let count = this.students.length;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      if (formGroup != undefined) {
        formGroup.get('gender').setValidators([Validators.required]);
        formGroup
          .get('language')
          .setValidators([
            Validators.required,
            this.validatorRequireMatch.bind(this),
          ]);
        formGroup.get('age').setValidators([Validators.required]);

        formGroup.get('gender').updateValueAndValidity();
        formGroup.get('language').updateValueAndValidity();
        formGroup.get('age').updateValueAndValidity();
      }
    }
  }

  checkStep() {
    if (this.onlyGroupOverview) {
      this.passwordSelection['_checked'] = false;
    }
    this.stepper.selectedIndex = 1;
  }

  pdfstep() {
    if (this.passwordSelection['_checked'] == true) {
      this.passwordsWarnOverlay();
    } else {
      if (this.withNames) {
        this.stepper.selectedIndex = 2;
        this.setValidation();
      } else {
        this.removeValidations();
        this.stepper.selectedIndex = 2;
        this.stepper.selectedIndex = 3;
        this.isLoading = true;
        this.regeneratePdfForGroup();
      }
    }
  }

  passwordsWarnOverlay() {
    let dialogRef = this.dialog.open(PasswordsWarnComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'paswords-warn-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'paswords-warn-panel-backdrop',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'accept') {
        this.passwordSelection['_checked'] = true;
        if (this.withNames) {
          this.stepper.selectedIndex = 2;
          this.setValidation();
        } else {
          this.removeValidations();
          this.stepper.selectedIndex = 2;
          this.stepper.selectedIndex = 3;
          this.isLoading = true;
          this.regeneratePdfForGroup();
        }
      }
    });
  }

  setTaskData(value) {
    //step 0
    if (value == 'onlyGroupOverview') {
      this.onlyGroupOverview = true;
      this.completeGroupOverview = false;
    }

    if (value == 'completeGroupOverview') {
      this.onlyGroupOverview = false;
      this.completeGroupOverview = true;
    }

    if (value == 'withNames') {
      this.withNames = true;
      this.withoutNames = false;
    }

    if (value == 'withoutNames') {
      this.withNames = false;
      this.withoutNames = true;
    }
  }

  regeneratePdfForGroup() {
    this.isLoading = true;
    this.groupService
      .regeneratePdfForGroup(
        this.group?._id,
        this.teacherId,
        this.passwordSelection['_checked'],
        this.withNames,
        this.onlyGroupOverview,
        this.rForm.value.students,
        this.translatedText,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            this.blobUrl = window.URL.createObjectURL(blob);
            this.isLoading = false;
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }
}
