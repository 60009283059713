import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormGroupDirective,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { GroupService } from '../../providers/group.service';
import { StudentService } from '../../providers/student.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { CdkStep } from '@angular/cdk/stepper';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class CreateClassComponent implements OnInit {
  isLoading: boolean = false;
  firstFormStep: FormGroup;
  secondFormStep: FormGroup;
  thirdFormStep: FormGroup;
  thirdFormStepGroupSubs: Subscription;
  numberOfChildren: number;
  selectedIndex: number;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  _maleValue: number = 0;
  _femaleValue: number = 0;
  _diversValue: number = 0;
  _step: number = 1;
  _min: number = 0;
  _max: number = Infinity;
  color: string = 'default';
  date = new FormControl(moment());
  maxBirthday;
  translatedText;
  languages: any = [];
  objectKeys = Object.keys;
  pdfPrintLayout = '1';
  infoText: string =
    'Wenn Sie auf "Speichern und PDF generieren" klicken, erstellen wir automatisch für alle Lernenden Benutzerkonten mit zufälligen Benutzernamen und Passwörtern. Für Sie als Lehrkraft erstellen wir dabei eine Klassenübersicht. Alle Lernenden erhalten zusätzlich ein eigenes Blatt mit ihren Zugangsdaten. \n\n  Vor- und Nachname: \n Werden NICHT in unserer Datenbank gespeichert, sondern  lediglich einmalig in der PDF mit der Klassenübersicht aufgeführt. Das soll Ihnen während der Nutzung die Zuordnung zu Ihren einzelnen Lernenden erleichtern. \n\n Muttersprache: \n Wird gespeichert und dient zur besseren Spracherkennung bei Akzentfärbungen. \n\n Geburtsmonat: \n Wird gespeichert und hilft uns bei der altersgerechten Ausspielung von Lesestoff und bei der Verbesserung der Spracherkennungsergebnisse. \n\n Geschlecht: \n Wird gespeichert und hilft uns bei der Verbesserung der Spracherkennungsergebnisse (bspw. bei Stimmbruch).';
  pdfPrintLayoutInfo: string =
    'Im nächsten Schritt generieren wir ein PDF-Dokument mit der Klassenübersicht für Sie als Lehrkraft und mit den Benutzerprofilen für die Lernenden. Diese können Sie ausdrucken und austeilen. Über dieses Dropdown-Menü entscheiden Sie, wie viele Benutzerprofile auf einer DIN-A4-Seite ausgegeben werden sollen. Die Einstellung lässt sich nachträglich nicht mehr ändern.';
  filteredOptions: Observable<Languages[]>[] = new Array();

  @ViewChild('step1') step1: CdkStep;
  @ViewChild('step2') step2: CdkStep;
  @ViewChild('step3') step3: CdkStep;
  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private groupService: GroupService,
    private studentService: StudentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {}

  getGenderHeadline(index) {
    const gender = this.thirdFormStep.get('class').value[index].gender;
    if (gender) {
      let genderText;
      switch (gender) {
        case 'male':
          genderText = 'Männlich';
          break;
        case 'female':
          genderText = 'Weiblich';
          break;
        case 'divers':
          genderText = 'Divers';
          break;
      }
      return (
        '<div class="gender-headline"><span>' +
        genderText +
        '</span><img src="assets/gender-' +
        gender +
        '.svg" /></div>'
      );
    } else {
      return '';
    }
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    index,
  ) {
    const element = this.thirdFormStep.get('class');
    if (element != undefined) {
      const ctrlValue = element.value[index].age;
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      ((this.thirdFormStep.get('class') as FormArray).at(index) as FormGroup)
        .get('age')
        .patchValue(ctrlValue);
      datepicker.close();
    }
  }

  ngOnInit(): void {
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);
    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.languages = res;
      });

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.firstFormStep = this._formBuilder.group({
      // className   : ['', [Validators.required, Validators.maxLength(10)]],
      grade: ['', Validators.required],
      stream: ['', Validators.required],
      school_year: ['', Validators.required],
    });

    this.secondFormStep = this._formBuilder.group(
      {
        genderMale: ['', Validators.required],
        genderFemale: ['', Validators.required],
        genderDivers: ['', Validators.required],
      },
      {
        // Set custom student count validator
        validators: this.studentCountValidator(),
      },
    );

    this.thirdFormStep = this._formBuilder.group({
      class: this._formBuilder.array([this.createClassArray('')]),
    });
  }

  optionSelected(event) {
    console.log(event);
    return event.option.value;
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.languages.filter((obj) => obj.code == code)[0]?.name
      : '';
  }

  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.thirdFormStep.get('class') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages?.slice()
              : [];
        }),
      );
  }

  setLanguageOption(value, index) {
    ((this.thirdFormStep.get('class') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  closeClassOverlay() {
    this.dialog.closeAll();
  }

  perpareNextStep() {
    let currentStep = this.selectedIndex;
    this.thirdFormStep = this._formBuilder.group({
      class: this._formBuilder.array([this.createClassArray('')]),
    });
    if (currentStep == 2) {
      let male = this.secondFormStep.get('genderMale').value;
      let female = this.secondFormStep.get('genderFemale').value;
      let divers = this.secondFormStep.get('genderDivers').value;
      this.removeClass(0);
      this.addClass(male, female, divers);
    }
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  createClassArray(gender): FormGroup {
    let schoolClassAge = parseInt(this.firstFormStep.value.grade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );

    return this._formBuilder.group({
      name: '',
      gender: gender,
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [moment(birthyearEstimation), Validators.required],
    });
  }

  addClass(male, female, divers) {
    const currentClass = this.thirdFormStep.get('class') as FormArray;
    for (let index = 0; index < male; index++) {
      currentClass.push(this.createClassArray('male'));
    }
    for (let index = 0; index < female; index++) {
      currentClass.push(this.createClassArray('female'));
    }
    for (let index = 0; index < divers; index++) {
      currentClass.push(this.createClassArray('divers'));
    }
    // Create language observables
    let count = male + female + divers;
    for (let index = 0; index < count; index++) {
      this.setLanguages(index);
    }
  }

  removeClass(i: number) {
    const currentClass = this.thirdFormStep.get('class') as FormArray;
    currentClass.removeAt(i);
  }

  getClassFormControls(): AbstractControl[] {
    return (<FormArray>this.thirdFormStep.get('class')).controls;
  }

  studentCountValidator(): ValidatorFn {
    return (formGroup: FormGroup) => {
      const maleControl = formGroup.get('genderMale');
      const femaleControl = formGroup.get('genderFemale');
      const diversControl = formGroup.get('genderDivers');

      // if (!maleControl || !femaleControl || !diversControl) {
      //   return null;
      // }

      const maleValue = maleControl.value;
      const femaleValue = femaleControl.value;
      const diversValue = diversControl.value;

      console.log(maleValue);

      const studentCountValidator =
        maleValue + femaleValue + diversValue < 1 ? true : false;
      const studentMaxCountValidator =
        maleValue + femaleValue + diversValue > 40 ? true : false;
      if (studentCountValidator) {
        return studentCountValidator ? { studentCountValidator: true } : null;
      } else if (studentMaxCountValidator) {
        return studentMaxCountValidator
          ? { studentMaxCountValidator: true }
          : null;
      }
    };
  }

  incrementValue(step: number = 1, gender?): void {
    let inputValue;
    if (gender == 'male') {
      inputValue = this._maleValue + step;
    } else if (gender == 'female') {
      inputValue = this._femaleValue + step;
    } else if (gender == 'divers') {
      inputValue = this._diversValue + step;
    }

    inputValue = this.wrappedValue(inputValue);

    if (gender == 'male') {
      this._maleValue = inputValue;
    } else if (gender == 'female') {
      this._femaleValue = inputValue;
    } else if (gender == 'divers') {
      this._diversValue = inputValue;
    }
  }

  private wrappedValue(inputValue): number {
    if (inputValue > this._max) {
      return this._min + inputValue - this._max;
    }

    if (inputValue < this._min) {
      if (this._max === Infinity) {
        return 0;
      }

      return this._max + inputValue;
    }

    return inputValue;
  }

  submitForm(form: FormGroupDirective) {
    if (
      this.firstFormStep.invalid ||
      this.secondFormStep.invalid ||
      this.thirdFormStep.invalid
    ) {
      this.thirdFormStep.markAllAsTouched();
      let message = 'Bitte überprüfen Sie Ihre Angaben (ggf. hochscrollen).';
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        panelClass: 'snack-error',
        data: message,
        duration: 3000,
        horizontalPosition: 'left',
      });
      return;
    }
    this.step1.editable = false;
    this.step2.editable = false;
    this.step3.editable = false;
    this.firstFormStep.disable();
    this.secondFormStep.disable();
    this.thirdFormStep.disable();
    let groupArray = {
      teacherId: this.authService.getTeacherId(),
      stream: this.firstFormStep.value.stream,
      grade: this.firstFormStep.value.grade,
      school_year: this.firstFormStep.value.school_year,
      male: this.secondFormStep.value.genderMale,
      female: this.secondFormStep.value.genderFemale,
      divers: this.secondFormStep.value.genderDivers,
      pdfPrintLayout: this.pdfPrintLayout,
      translatedText: this.translatedText,
      namesPackageType: this.firstFormStep.value.avatar,
    };
    this.isLoading = true;
    this.groupService
      .createGroupWithPDF(
        groupArray,
        this.thirdFormStep.value.class,
        this.translatedText,
        this.firstFormStep.value.avatar,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response: any) => {
          if (response) {
            let groupName =
              groupArray.grade +
              groupArray.stream +
              '-' +
              groupArray.school_year;
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.groupService.openPdfOverlay(blobUrl, groupName, 'multiple');
            this.isLoading = false;
          }
        },
        (error: any) => {
          let message = 'Es ist ein technischer Fehler aufgetreten';
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }
}
