<div class="container-fluid container-fluid--bg-gray container-fluid--full-inside-height">
  <div class="justify-content-center tree_row">
    <!-- <div class="col-md-6 col-sm-12 col-lg-6">
            <div class="tree_container">
                <div class="set_inline">
                    <p class="paragraph paragraph--bold">Deine Klasse hätte fast einen Baum gepflanzt</p>
                    <mat-icon class="orange_icon_tip" svgIcon="streamline_filled:tips"></mat-icon>
                </div>
                <div class="tree_wrap">
                    <div class="row" *ngIf="groupReadingTime >= 1800">
                        <div class="col-md-9">
                            <div class="forest-wrap" >
                                <img class="tree_img" src="../../../assets/cherry-640 1.svg" alt="">
                                <img class="tree_img" src="../../../assets/forest-2.svg" alt="">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="tree_counter">
                                <h2 class="h2 h2--bold tree_count">{{(groupReadingTime / 60) / 5 | number:'1.0-0'}}</h2>
                                <p class="paragraph paragraph--xs">Bäume</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="groupReadingTime < 1800">
                        <div class="col">
                            <img *ngIf="groupReadingTime >= 300" class="young-tree" src="../../../assets/biro-young-tree.svg" alt="">
                        </div>
                        <div class="col">
                            <img *ngIf="groupReadingTime >= 600" class="young-tree" src="../../../assets/biro-young-tree.svg" alt="">
                        </div>
                        <div class="col">
                            <img *ngIf="groupReadingTime >= 900" class="young-tree" src="../../../assets/biro-young-tree.svg" alt="">
                        </div>
                        <div class="col">
                            <img *ngIf="groupReadingTime >= 1200" class="young-tree" src="../../../assets/biro-young-tree.svg" alt="">
                        </div>
                        <div class="col">
                            <img *ngIf="groupReadingTime >= 1500" class="young-tree" src="../../../assets/biro-young-tree.svg" alt="">
                        </div>
                    </div>
                </div>
    
                <div class="tree_description">
                    <div class="tree_progress">
                        <img *ngIf="groupReadingTime >= 1800" class="tree_description_img" src="../../../assets/biro-young-tree-with-leaves copy 4.svg" alt="">
                        <img *ngIf="groupReadingTime < 1800" class="tree_description_img little" src="../../../assets/little-plant.svg" alt="">
                        <app-student-progress-bar  [progress]="(grownTime() * 20)"></app-student-progress-bar>
                    </div>
                   
                    <div class="tree_description_text" *ngIf="groupReadingTime >= 1800">
                        <p class="paragraph paragraph--bold paragraph--xs">Gut gemacht!</p>
                        <p class="paragraph paragraph--xs">Ihr habt <span>{{groupReadingTime / 60 | number:'1.0-1'}} Stunden</span> gelesen und <span>{{(groupReadingTime / 60) / 5 | number:'1.0-0'}} Bäume</span> gepflanzt.</p>
                    </div>
                    <div class="tree_description_text" *ngIf="groupReadingTime < 1800">
                        <p class="paragraph paragraph--xs">Ihr habt schon <span>{{groupReadingTime / 60 | number:'1.0-1'}} Stunden</span> gelesen. Ihr müßt noch <span>{{5 - grownTime() | number:'1.0-1'}} Stunden</span> lesen, um einen Baum wachsen zu lassen.</p>
                    </div> 
                </div>
            </div>
        </div> -->
    <div class="reading_results">
      <div class="inline_text">
        <p class="paragraph paragraph--bold">
          {{ translatedText.student_results_title }}
        </p>
      </div>

      <div class="dashboard_result_row">
        <div class="dashboard_result">
          <div class="dashboard_result_student dashboard_result_student--reading-points">
            <p class="result_number reading_points paragraph--bold paragraph paragraph--xs" [countUp]="readingPoints">
              {{ readingPoints }}
            </p>
            <p class="paragraph paragraph--s">
              {{ translatedText.student_results_reading_points }}
            </p>
          </div>
          <div class="dashboard_result_student dashboard_result_student--medals">
            <div class="set_inline">
              <p
                class="result_number paragraph paragraph--xs paragraph--bold"
                [countUp]="medals?.bronze + medals?.silver + medals?.gold"
              >
                {{ medals?.bronze + medals?.silver + medals?.gold }}
              </p>
              <div class="medals-wrap">
                <div class="bronze-medal">
                  <div class="bronze-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.bronze">
                      {{ medals?.bronze }}
                    </p>
                  </div>
                </div>
                <div class="silver-medal">
                  <div class="silver-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.silver">
                      {{ medals?.silver }}
                    </p>
                  </div>
                </div>
                <div class="gold-medal">
                  <div class="gold-medal-border">
                    <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.gold">
                      {{ medals?.gold }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="paragraph paragraph--s">
              {{ translatedText.student_results_medals }}
            </p>
          </div>
        </div>
        <div class="dashboard_result">
          <div class="dashboard_result_student dashboard_result_student--reading-words">
            <p class="result_number words_read paragraph--bold paragraph paragraph--xs" [countUp]="wordsRead">
              {{ wordsRead }}
            </p>
            <p class="paragraph paragraph--s">
              {{ translatedText.student_results_read_words }}
            </p>
          </div>
          <div class="dashboard_result_student dashboard_result_student--performed-tasks">
            <p class="result_number paragraph paragraph--bold paragraph--xs" [countUp]="tasksPerformed">
              {{ tasksPerformed }}
            </p>
            <p class="paragraph paragraph--s">
              {{ translatedText.student_results_completed_tasks }}
            </p>
          </div>
        </div>
        <!-- <div  class="col-md-4 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--speak">
                            <div class="set_inline">
                                <p class="result_number paragraph paragraph--xs paragraph--bold" [countUp]="0">0</p>
                              
                                <div class="medals-wrap">
                                    <div class="bronze-medal">
                                        <div class="bronze-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                    <div class="silver-medal">
                                        <div class="silver-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                    <div class="gold-medal">
                                        <div class="gold-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="paragraph paragraph--s">Sprechen</p>
                       </div>
                    </div> -->
        <!-- <div  class="col-md-4 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--listen">
                            <div class="set_inline">
                                <p class="result_number paragraph paragraph--xs paragraph--bold" [countUp]="0">0</p>
                              
                                <div class="medals-wrap">
                                    <div class="bronze-medal">
                                        <div class="bronze-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                    <div class="silver-medal">
                                        <div class="silver-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                    <div class="gold-medal">
                                        <div class="gold-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="0">0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="paragraph paragraph--s">Anhören</p>
                       </div>
                    </div> -->
      </div>
    </div>
  </div>
  <div class="student_awards_wrap">
    <div class="set_inline student_awards_header">
      <p class="paragraph paragraph--bold">
        {{ translatedText.student_results_awards }}
      </p>
      <mat-icon
        class="orange_icon_tip"
        svgIcon="streamline_filled:student-tips"
        matTooltipClass="salutation-tooltip"
        (click)="openNotificationList()"
      ></mat-icon>
    </div>
    <div class="student_awards_flex">
      <div
        class="student_awards"
        *ngFor="let award of awards"
        (mouseenter)="award.hovered = true"
        (mouseleave)="award.hovered = false"
      >
        <div class="award_img" [ngClass]="award?.earned ? '' : 'disabled'">
          <img class="award_earned" src="{{ award?.imageUrl }}" alt="" />
          <p class="paragraph paragraph--bold paragraph--s award-description">
            {{ translationService.getTranslatedText(award?.description) }}
          </p>
        </div>
        <p class="paragraph paragraph--bold paragraph--l">
          {{ translationService.getTranslatedText(award?.title) }}
        </p>
        <ng-container *ngIf="award?.earned === true">
          <app-student-progress-bar [progress]="award?.value"></app-student-progress-bar>
        </ng-container>
        <ng-container *ngIf="award?.earned === false">
          <app-student-progress-bar [progress]="0"></app-student-progress-bar>
        </ng-container>
        <div class="awards-hover-box" *ngIf="award.hovered">
          <p class="paragraph paragraph--bold">{{ translationService.getTranslatedText(award?.title) }}</p>
          <p class="paragraph">{{ translationService.getTranslatedText(award?.hoverText) }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
