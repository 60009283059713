<div class="verification_container">
  <div class="verification_wrap" *ngIf="isRegisterTeacher">
    <img class="head_verification_img" src="../../../assets/verification.svg" alt="" />
    <h2 class="h2 verification_header">{{ translatedText.verification_title }}</h2>
    <p class="paragraph paragraph--l">{{ translatedText.verification_desc }}</p>
    <p class="paragraph">
      <br />
      <span class="home_warning">{{ translatedText.verification_attention }} </span>
      {{ translatedText.verification_attention_1 }}
      <span class="home_warning">{{ translatedText.verification_attention_2 }}</span>
      {{ translatedText.verification_attention_3 }}
      <span class="home_warning">{{ translatedText.verification_attention_4 }}</span>
      {{ translatedText.verification_attention_5 }}
      <span class="home_warning">{{ translatedText.verification_attention_6 }}</span>
      {{ translatedText.verification_attention_7 }}
    </p>
    <button mat-flat-button color="primary" type="button" class="verification_button" (click)="navToLogin()">
      {{ translatedText.verification_close }}
    </button>
    <img class="footer_verification_img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
  </div>

  <div class="verification_wrap" *ngIf="isForgotPassword">
    <img class="head_verification_img" src="../../../assets/verification.svg" alt="" />
    <h2 class="h2 verification_header">{{ translatedText.verification_reset_password }}</h2>
    <p class="paragraph paragraph--l">{{ translatedText.verification_reset_password_text }}</p>
    <button mat-flat-button color="primary" type="button" class="verification_button" (click)="navToLogin()">
      {{ translatedText.verification_close }}
    </button>
    <img class="footer_verification_img"  [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
  </div>

  <div class="verification_wrap" *ngIf="isWebmail">
    <img class="head_verification_img" src="../../../assets/verification.svg" alt="" />
    <h2 class="h2 verification_header">{{ translatedText.verification_register }}</h2>
    <p class="paragraph paragraph--l">{{ translatedText.verification_register_text }}</p>
    <button mat-flat-button color="primary" type="button" class="verification_button" (click)="navToLogin()">
      {{ translatedText.verification_close }}
    </button>
    <img class="footer_verification_img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
  </div>
</div>
