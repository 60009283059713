import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';

export interface DialogData {}

export interface ErrorData {
  // _id: string;
  // word: string;
  // number: number;
  // readingPoints: number;
  // readingError: number;
  // comment: string;
}

// TODO: unused?
@Component({
  selector: 'app-report-error-repeat',
  templateUrl: './report-error.component.html',
  styleUrls: ['./report-error.component.scss'],
})
export class ReportErrorComponent implements OnInit, OnDestroy {
  @Input() showInput: any;
  restServerUrl;
  displayedColumns: string[] = [
    'number',
    'word',
    'operation',
    'comment',
    'button',
  ];
  dataSource: any;
  rForm: FormGroup;
  id;
  errorData: ErrorData[];
  commentField;
  isHidden: boolean = true;
  rowNumber;
  isButton: boolean = true;
  list_of_operations: ErrorData[];
  sessionUuid;
  translatedText;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['list_of_operations'] !== 'undefined') {
        this.list_of_operations = this.injectedData['list_of_operations'];
      }
      if (typeof injectedData['session_uuid'] !== 'undefined') {
        this.sessionUuid = this.injectedData['session_uuid'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    // Fallback
    //this.list_of_operations = [{operation: 'sub', word: 'sass|saß'}];
    this.errorData = [];
    this.dataSource = new MatTableDataSource<ErrorData>(this.errorData);
    if (this.list_of_operations != undefined) {
      this.errorData = eval('(' + this.list_of_operations + ')');
      this.dataSource.data = this.errorData;
    }
    console.log(this.list_of_operations);

    this.id = this.injectedData['id'];
    this.rForm = this._formBuilder.group({
      comment: [''],
    });
  }

  getTextToOperation(operation) {
    let text = '';
    switch (operation) {
      case 'del':
        text = 'Auslassung';
        break;
      case 'sub':
        text = 'Ersetzung';
        break;
      case 'ins':
        text = 'Hinzufügung';
        break;
      default:
        break;
    }
    return text;
  }

  showComment(element, index) {
    this.rowNumber = index;
    this.isButton = false;
    this.rForm.get('comment').setValue(element.comment);
  }

  addComment(element, index) {
    element.comment = this.rForm.value.comment;
    console.log(element);
    this.rowNumber = -1;
    this.isButton = true;
    this.rForm.reset();
  }

  closeComment() {
    this.rowNumber = -1;
    this.isButton = true;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  submitForm(formDirective: FormGroupDirective) {
    this.taskService
      .reportResultFault({
        session_uuid: this.sessionUuid,
        issue_list_of_operations: JSON.stringify(this.errorData),
        teacher_id: this.authService.getTeacherId(),
      })
      .subscribe({
        next: (res) => {
          this.dialog.closeAll();
        },
        error: (error) => {
          this.dialog.closeAll();
        },
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
