import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-success-overlay',
  templateUrl: './success-overlay.component.html',
  styleUrls: ['./success-overlay.component.scss'],
})
export class SuccessOverlayComponent {
  successMsg: string = '';
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: any,
  ) {
    if (injectedData && injectedData['message']) {
      this.successMsg = injectedData['message'];
    }
  }

  closeOverlay() {
    this.dialog.closeAll();
  }
}
