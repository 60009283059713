import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-helpdesk-overlay',
  templateUrl: './helpdesk-overlay.component.html',
  styleUrls: ['./helpdesk-overlay.component.scss'],
})
export class HelpdeskOverlayComponent implements OnInit {
  blobUrl: string = '';
  rForm: FormGroup;
  request;
  isLoading: boolean = false;
  translatedText: any;
  email: string = '';
  phoneNum: number;
  address: string = '';
  zipCode: number;
  city: string = '';
  school: string = '';
  fullName: string = '';

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['email'] !== 'undefined') {
        this.email = this.injectedData['email'];
      }
      if (typeof injectedData['request'] !== 'undefined') {
        this.request = this.injectedData['request'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.rForm = this._formBuilder.group({
      email: [this.email, [Validators.required, this.customEmailValidator]],
      school: ['', [Validators.required]],
      address: ['', [Validators.required]],
      zipCode: ['', [Validators.required, this.onlyDigitsValidator]],
      city: ['', [Validators.required, this.noDigitsValidator]],
      fullName: ['', [Validators.required, this.noDigitsValidator]],
      phoneNum: ['', [Validators.required, this.onlyDigitsValidator]],
    });
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onlyDigitsValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    let notNumRegex = /^\d+$/;
    if (value && !notNumRegex.test(value)) {
      return { hasLetters: true };
    }
    return null;
  }

  noDigitsValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && !/^\D*$/.test(value)) {
      return { hasDigits: true };
    }
    return null;
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.authService
      .sendEmailToHelpdesk(
        this.rForm.value.email,
        this.rForm.value.school,
        this.rForm.value.address,
        this.rForm.value.zipCode,
        this.rForm.value.city,
        this.rForm.value.phoneNum,
        this.rForm.value.fullName,
      )
      .subscribe({
        next: (res) => {
          console.log(res);
          this.isLoading = false;
          this.dialog.closeAll();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: 'Helpdesk wurde kontaktiert, wir werden uns bei ihnen melden.',
            duration: 3000,
            horizontalPosition: 'left',
          });
          this.router.navigate(['/home']);
        },
        error: (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: 'Es ist ein technischer Fehler aufgetreten.',
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
