<div class="task-delete_container">
  <button
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="task-delete_wrap">
    <h2 class="h2 task-delete_header">
      {{ translatedText.task_delete_title }}
    </h2>
    <button
      mat-flat-button
      color="primary"
      type="button"
      class="task-delete_button"
      (click)="deleteTask()"
    >
      {{ translatedText.task_delete_apply }}
    </button>
    <img
      class="footer_task-delete_img"
      [src]="'/assets/' + environment.whitelabel.logo"
      alt=""
    />
  </div>
</div>
