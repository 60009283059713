import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { PasswordChangedOverlayComponent } from './password-changed-overlay.component';

@NgModule({
  declarations: [PasswordChangedOverlayComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [PasswordChangedOverlayComponent],
})
export class PasswordChangedOverlayModule {}
