<section class="container-fluid bookview-student-container">
  <div class="row">
    <div class="col-md-2 bookview-student-col-setting">
      <div class="col-md-2 bookview-student-col-time--topbar hidden-tablet">
        <div class="bookview-student-time-wrap">
          <p class="paragraph">
            {{ translatedText.book_view_student_time }}:
            <strong
              ><span class="reading-countdown">{{ 180 - time | readingTimeFormat | timeformat : 'min' }}</span></strong
            >
          </p>
          <!-- <app-book-reader-pagination></app-book-reader-pagination>
                    <app-book-reader-nav [bookReaderType]="'get'"></app-book-reader-nav> -->
        </div>
      </div>
      <div class="book_reader_settings" *ngIf="bookReaderSettings?.editable">
        <!-- <button mat-flat-button [ngClass]="{'reader-menu-open':fontFamilyClass.menuOpen === true}"  type="button" class="readerButton" (click)="menuTrigger.openMenu()"  matSuffix>
                    <mat-icon svgIcon="streamline_filled:fontFamily"></mat-icon>
                    Schriftart
                    <div #fontFamilyClass="matMenuTrigger"  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="fontFamily"></div>
                </button> -->
        <!-- <mat-menu #fontFamily="matMenu">
                    <mat-button-toggle-group name="fontFamily" [value]="task.reader_settings.font_family" (change)="updateReaderSettings($event, 'font_family')" aria-label="Font Family">
                        <mat-button-toggle class="fontFamily fontFamily__abeezee" value="abeezee">ABeeZee</mat-button-toggle>
                        <mat-button-toggle class="fontFamily fontFamily__poppins" value="poppins">Poppins</mat-button-toggle>
                        <mat-button-toggle class="" value="none">{{translatedText.book_view_student_none}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </mat-menu> -->

        <button
          mat-flat-button
          type="button"
          [ngClass]="{ 'reader-menu-open': fontSizeClass.menuOpen === true }"
          class="readerButton"
          (click)="menuFontSize.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:fontSize"></mat-icon>
          <span class="hidden-mobile">
            {{ translatedText.book_view_student_size }}
          </span>
          <div #fontSizeClass="matMenuTrigger" #menuFontSize="matMenuTrigger" [matMenuTriggerFor]="fontSize"></div>
        </button>
        <mat-menu #fontSize="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="fontSize"
            [value]="task.reader_settings.font_size"
            (change)="updateReaderSettings($event, 'font_size')"
            aria-label="Font Size"
          >
            <mat-button-toggle class="fontSize fontSize__small" value="small">T</mat-button-toggle>
            <mat-button-toggle class="fontSize fontSize__normal" value="normal">T</mat-button-toggle>
            <mat-button-toggle class="fontSize fontSize__big" value="big">T</mat-button-toggle>
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{ 'reader-menu-open': lineHeightClass.menuOpen === true }"
          class="readerButton"
          (click)="menuLine_height.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:lineHeight"></mat-icon>
          <span class="hidden-mobile">{{ translatedText.book_view_student_lines }}</span>
          <div
            #lineHeightClass="matMenuTrigger"
            #menuLine_height="matMenuTrigger"
            [matMenuTriggerFor]="line_height"
          ></div>
        </button>
        <mat-menu #line_height="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="line_height"
            [value]="task.reader_settings.line_height"
            (change)="updateReaderSettings($event, 'line_height')"
            aria-label="Line Height"
          >
            <mat-button-toggle class="line_height line_height__small" value="small"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
            <mat-button-toggle class="line_height line_height__normal" value="normal"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
            <mat-button-toggle class="line_height line_height__big" value="big"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{
            'reader-menu-open': spaceBetweenClass.menuOpen === true
          }"
          class="readerButton"
          (click)="menuLetter_spacing.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:spaceBetween"></mat-icon>
          {{ translatedText.book_view_student_space }}
          <div
            #spaceBetweenClass="matMenuTrigger"
            #menuLetter_spacing="matMenuTrigger"
            [matMenuTriggerFor]="letter_spacing"
          ></div>
        </button>
        <mat-menu #letter_spacing="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="letter_spacing"
            [value]="task.reader_settings.letter_spacing"
            (change)="updateReaderSettings($event, 'letter_spacing')"
            aria-label="Letter Spacing"
          >
            <mat-button-toggle class="letter_spacing letter_spacing__small" value="small"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
            <mat-button-toggle class="letter_spacing letter_spacing__normal" value="normal"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
            <mat-button-toggle class="letter_spacing letter_spacing__big" value="big"
              >{{ translatedText.book_view_student_good }} <br />
              {{ translatedText.book_view_student_world }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{
            'reader-menu-open': hyphenation_modeClass.menuOpen === true
          }"
          class="readerButton"
          (click)="menuHyphenation_mode.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:hyphenation_mode"></mat-icon>
          <span class="hidden-mobile">{{ translatedText.book_view_student_split }}</span>
          <div
            #hyphenation_modeClass="matMenuTrigger"
            #menuHyphenation_mode="matMenuTrigger"
            [matMenuTriggerFor]="hyphenation_mode"
          ></div>
        </button>
        <mat-menu #hyphenation_mode="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="hyphenation_mode"
            [value]="task.reader_settings.hyphenation_mode"
            (change)="updateReaderSettings($event, 'hyphenation_mode')"
            aria-label="Hyphenation Mode"
          >
            <mat-button-toggle class="hyphenation_mode" value="option0"
              >{{ translatedText.book_view_student_none }} <br />
              {{ translatedText.book_view_student_split }}</mat-button-toggle
            >
            <mat-button-toggle class="hyphenation_mode" value="option1">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/blue_circle.svg" alt="blue circle" />
                <img src="./assets/red_circle.svg" alt="Red circle" />
              </div>
            </mat-button-toggle>
            <mat-button-toggle class="hyphenation_mode" value="option2">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/teal_circle.svg" alt="teal circle" />
                <img src="./assets/orange_circle.svg" alt="orange circle" />
              </div>
            </mat-button-toggle>
            <mat-button-toggle class="hyphenation_mode" value="option3">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/gray_circle.svg" alt="gray circle" />
                <img src="./assets/black_circle.svg" alt="black circle" />
              </div>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-menu>
      </div>
      <div class="book_reader_settings_toc">
        <button mat-flat-button type="button" class="readerButton toc" (click)="openBookReaderToc()" matSuffix>
          <mat-icon svgIcon="streamline_outlined:toc"></mat-icon>
          <span class="hidden-mobile">{{ translatedText.book_view_student_content }}</span>
        </button>
      </div>
    </div>
    <div class="col-md-8 book_reader-col">
      <app-book-reader-view
        [userTaskUuid]="userTaskUuid"
        [resetSelection]="resetSelection"
        [openFromBookmark]="openFromBookmark"
        [bookIsbn]="bookIsbn"
        [dsefVersion]="dsefVersion"
        [bookReaderType]="'set'"
        [bookReaderSettings]="bookReaderSettings"
        (wordClickedEvent)="clickedWord($event)"
        [taskType]="task?.type"
        [pickWord]="true"
        fromComponent="bookview-student"
      ></app-book-reader-view>
      <div class="book-preview-buttons">
        <div class="boox-preview_text">
          <p class="paragraph paragraph--bold">
            {{ translatedText.book_view_student_start }}
          </p>
          <p class="paragraph">
            {{ translatedText.book_view_student_select_start_word }}
          </p>
        </div>
        <div class="buttons">
          <button mat-flat-button color="secondary-student" (click)="backToPreview()">
            {{ translatedText.book_view_student_back }}
          </button>
          <button
            mat-flat-button
            [disabled]="!selectedStart"
            color="primary-student"
            (click)="startTaskOverlay()"
            *ngIf="book?.title"
          >
            {{ translatedText.book_view_student_next }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-2 bookview-student-col-time hidden-mobile">
      <div class="bookview-student-time-wrap">
        <p class="paragraph">{{ translatedText.book_view_student_time }}</p>
        <p class="paragraph paragraph--l">
          <strong
            ><span class="reading-countdown">{{ 180 - time | readingTimeFormat | timeformat : 'min' }}</span></strong
          >
        </p>
        <!-- <app-book-reader-pagination></app-book-reader-pagination>
                <app-book-reader-nav [bookReaderType]="'get'"></app-book-reader-nav> -->
      </div>
    </div>
  </div>
</section>
