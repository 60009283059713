<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">Besondere Bedingungen zur Auftragsverarbeitung (im Weiteren: „AV“)</h1>
          <p>
            Die AV konkretisiert die datenschutzrechtlichen Rechte und Pflichten der Parteien, die sich einem zwischen
            den Parteien abgeschlossenen Nutzungsvertrag über die Plattform (gemäß Teil I der Nutzungsbedingungen und
            der von Digi Sapiens dem Kunden im Rahmen des Nutzungsvertrages gestellten Dokumentation) (im Weiteren: der
            „Hauptvertrag“) ergeben.
          </p>

          <h3>1. Gegenstand und Dauer der AV, Rangfolge bei Widersprüchen</h3>

          <p>
            <strong>1.1. Anwendungsbereich der AV:</strong> Die AV findet Anwendung auf alle Tätigkeiten, bei denen
            Beschäftigte von Digi Sapiens oder durch Digi Sapiens Beauftragte personenbezogene Daten im Sinne von Art. 4
            Nr. 1 DSGVO (im Weiteren: „Daten“) des Kunden gemäß Art. 28 DSGVO verarbeiten (im Weiteren:
            „Auftragsverarbeitung“). Sofern in dieser AV der Begriff „Datenverarbeitung“ oder „Verarbeitung“ (von Daten)
            benutzt wird, wird die Definition der „Verarbeitung“ i.S.d. Art. 4 Nr. 2 DSGVO zugrunde gelegt.<br /><br />
          </p>

          <p>
            <strong>1.2. Dauer der Vereinbarung, Kündigung:</strong> Die AV wird mit Abschluss des Hauptvertrages gültig
            und gilt für die Dauer des Hauptvertrages. Soweit durch Digi Sapiens faktisch über die Laufzeit der AV
            hinaus personenbezogene Daten des Kunden verarbeitet werden (z.B. bei Speicherung von Daten aufgrund
            gesetzlicher Aufbewahrungspflichten, denen Digi Sapiens unterliegt), gelten die vertraglichen Vereinbarungen
            zur Zweckbindung und Einhaltung der technischen und organisatorischen Maßnahmen fort.<br /><br />
          </p>

          <p>
            <strong>1.3. Einzelheiten zur Datenverarbeitung:</strong> Art der Daten, Art und Zweck der Datenverarbeitung
            sowie Kategorien der betroffenen Personen werden im Hauptvertrag und der dazugehörigen Leistungsbeschreibung
            sowie der Anlage B konkretisiert.<br /><br />
          </p>

          <p>
            <strong>1.4. Rangfolge:</strong> Bei Widersprüchen zwischen Inhalten dieser AV und Bestimmungen des
            Hauptvertrags, insbesondere Teil I der Nutzungsbedingungen hinsichtlich datenschutzrechtlicher Themen, gilt
            die AV vorrangig. Im Übrigen bleiben die Bestimmungen des Hauptvertrags unberührt und gelten für diese AV
            entsprechend.<br /><br />
          </p>

          <h3>2. Verantwortlichkeit</h3>

          <p>
            <strong>2.1. Zulässigkeit der Datenverarbeitung:</strong> Dem Kunden ist bewusst, dass er im Rahmen des
            Hauptvertrages als verantwortliche Stelle („Verantwortlicher“ im Sinne des Art. 4 Nr. 7 DSGVO) alleine die
            Verantwortung für die Einhaltung der gesetzlichen Bestimmungen der geltenden Datenschutzgesetze trägt. Dies
            umfasst insbesondere die Verantwortung für die Rechtmäßigkeit der Datenweitergabe an Digi Sapiens sowie die
            Rechtmäßigkeit der Datenverarbeitung. Der Kunde wird in seinem Verantwortungsbereich die Voraussetzungen
            schaffen, dass Digi Sapiens die vereinbarten Leistungen rechtsverletzungsfrei erbringen kann.<br /><br />
          </p>

          <p>
            <strong>2.2. Weisungen:</strong> Dem Kunden steht die Weisungsbefugnis aus dem Hauptvertrag zu. Die
            Weisungen werden durch den Hauptvertrag festgelegt und können vom Kunden in schriftlicher Form durch
            einzelne Weisungen geändert, ergänzt oder ersetzt werden („Einzelanweisung“). Mündliche Weisungen sind vom
            Kunden unverzüglich mindestens in Textform (E-Mail ist ausreichend) zu bestätigen.<br /><br />
          </p>

          <h3>3. Pflichten Digi Sapiens</h3>

          <p>
            <strong>3.1. Durchführung der Datenverarbeitung:</strong> Digi Sapiens darf Daten nur im Rahmen des im
            Hauptvertrag genannten Zwecks und gemäß der Weisungen des Kunden verarbeiten, sofern nicht ein Ausnahmefall
            nach Art. 28 Abs. 3 lit. a DSGVO vorliegt. Ist Digi Sapiens aufgrund ihrer Branchen- bzw. Fachkenntnis der
            Ansicht, dass eine Weisung des Kunden gegen anwendbare Vorschriften über den Datenschutz verstößt, hat sie
            den Kunden unverzüglich darauf hinzuweisen. Digi Sapiens ist berechtigt, die Durchführung der entsprechenden
            Weisung solange auszusetzen, bis sie durch den Kunden schriftlich bestätigt oder geändert wird. Sofern Digi
            Sapiens der Auffassung ist, dass eine weisungsgerechte Verarbeitung zu einer Haftung von Digi Sapiens (z.B.
            nach Art. 82 DSGVO) führen kann, ist Digi Sapiens berechtigt, die weitere Verarbeitung bis zu einer Klärung
            der Haftung zwischen den Parteien auszusetzen. Zu einer materiell-rechtlichen Prüfung von Weisungen auf ihre
            Rechtmäßigkeit hin ist Digi Sapiens jedoch nicht verpflichtet.<br /><br />
          </p>

          <p>
            <strong>3.2. Unterstützung bei Pflichten des Verantwortlichen:</strong> Digi Sapiens wird unter
            Berücksichtigung der Art der Verarbeitung und der ihr zur Verfügung stehenden Informationen den Kunden im
            erforderlichen Umfang und im Rahmen ihrer Möglichkeiten bei der Erfüllung der Anfragen und Ansprüche
            betroffener Personen gemäß Kapitel III der DSGVO sowie bei der Einhaltung der in Art. 32 bis Art. 36 DSGVO
            genannten Pflichten des Verantwortlichen unterstützen, insbesondere bei: <br />a) Zurverfügungstellung von
            Informationen für das Verzeichnis der Verarbeitungstätigkeiten des Verantwortlichen hinsichtlich der vom
            Hauptvertrag umfassten Datenverarbeitungsprozesse. <br />b) Durchführung einer etwaig erforderlichen
            Datenschutzfolgenabschätzung hinsichtlich der vom Hauptvertrag umfassten Datenverarbeitungsprozesse.
            <br />c) vorheriger Konsultation einer Datenschutzaufsichtsbehörde. <br />d) Zurverfügungstellung von
            Informationen an die zuständige Aufsichtsbehörde, wenn der Verantwortliche schriftlich dazu auffordert wird
            oder dies durch anwendbares Recht verlangt wird. <br />e) Audits oder Durchsuchungen die die zuständige
            Aufsichtsbehörde oder andere Behörden (bspw. Untersuchungsbehörden) beim Auftraggeber durchführen<br /><br />
          </p>

          <p>
            <strong>3.3. Zuverlässigkeit der Mitarbeiter:</strong>
            DievonDigiSapiensmitderVerarbeitungderDatendesKundenbefassten Mitarbeiter und andere für Digi Sapiens
            tätigen Personen werden von Digi Sapiens mit den für sie maßgeblichen Vorschriften des Datenschutzes
            vertraut gemacht und in geeigneter Weise zu Verschwiegenheit verpflichtet. Den Mitarbeitern von Digi Sapiens
            wird dabei untersagt, Daten des Kunden außerhalb der Weisung zu verarbeiten und Daten des Kunden vertraulich
            zu behandeln. Diese Vertraulichkeitspflicht besteht auch nach Beendigung des Auftrages fort.<br /><br />
          </p>

          <p>
            <strong>3.4. Ansprechpartner auf Seiten Digi Sapiens:</strong> Digi Sapiens benennt dem Kunden auf Anfrage
            einen Ansprechpartner für die im Rahmen dieser AV anfallenden Datenschutzfragen. Ein Wechsel des
            Ansprechpartners wird Digi Sapiens dem Kunden unverzüglich mitteilen.<br /><br />
          </p>

          <p>
            <strong>3.5. Vorgehen bei Datenschutzverletzungen:</strong> Digi Sapiens unterrichtet den Kunden
            unverzüglich, wenn ihm Verletzungen des Schutzes personenbezogener Daten des Kunden bekannt werden. Dies
            gilt insbesondere im Hinblick auf die Meldepflicht nach Art. 33 Abs. 2 DSGVO sowie auf korrespondierende
            Pflichten des Kunden nach Art. 33 und Art. 34 DSGVO. Digi Sapiens trifft die erforderlichen Maßnahmen zur
            Sicherung der Daten und zur Minderung möglicher nachteiliger Folgen der betroffenen Personen und spricht
            sich hierzu unverzüglich mit dem Kunden ab. Digi Sapiens wird den Kunden erforderlichenfalls bei dessen
            Pflichten nach Art. 33 und 34 DSGVO angemessen unterstützen. Meldungen nach Art. 33 und Art. 34 DSGVO sind
            ausschließlich dem Kunden vorbehalten, soweit seine Daten betroffen sind.<br /><br />
          </p>

          <h3>4. Pflichten des Kunden</h3>

          <p>
            <strong>4.1. Unterstützung durch den Kunden:</strong>
            DerKundehatDigiSapiensunverzüglichundvollständigzuinformieren, wenn er in den Auftragsergebnissen Fehler
            oder Unregelmäßigkeiten bzgl. datenschutzrechtlicher Bestimmungen feststellt.<br /><br />
          </p>

          <p>
            <strong>4.2. Ansprechpartner auf Seiten des Kunden:</strong> Der Kunde nennt Digi Sapiens auf Anfrage einen
            ausreichend bevollmächtigten Ansprechpartner für sämtliche im Rahmen der AV anfallende Datenschutzfragen.
            Einen Wechsel des Ansprechpartners wird der Kunde Digi Sapiens unverzüglich mitteilen.<br /><br />
          </p>

          <h3>5. Technische und organisatorische Maßnahmen zum Datenschutz (Art. 32 DSGVO)</h3>

          <p>
            <strong>5.1. Technische/Organisatorische Maßnahmen:</strong> Digi Sapiens wird seine innerbetriebliche
            Organisation so gestalten, dass sie den Anforderungen des Datenschutzes im erforderlichen Umfang gerecht
            wird. Hierfür wird Digi Sapiens technische und organisatorische Maßnahmen zum angemessenen Schutz der im
            Auftrag des Kunden verarbeiteten Daten treffen, die den Anforderungen des Art. 32 DSGVO genügen. Die von
            Digi Sapiens zum Zeitpunkt des Abschlusses dieser AV getroffenen technischen und organisatorischen Maßnahmen
            sind in Anlage A beigefügt. Der Kunde hat diese Maßnahmen vor dem Hintergrund der konkret vereinbarten
            Datenverarbeitungsvorgänge im Hinblick auf ein angemessenes Schutzniveau bewertet und für ausreichend
            befunden.<br /><br />
          </p>

          <p>
            <strong>5.2. Anpassungen:</strong> Digi Sapiens ist berechtigt, die getroffenen Maßnahmen jederzeit zu
            ändern, sofern sichergestellt ist, dass das vereinbarte Schutzniveau nicht unterschritten wird. Digi Sapiens
            wird den Kunden über wesentliche Überarbeitungen seiner technischen und organisatorischen Maßnahmen
            informieren.<br /><br />
          </p>

          <h3>6. Berichtigung, Löschung und Sperrung von Daten</h3>

          <p>
            <strong>6.1. Unterstützung bei Berichtigung, Löschung, Sperrung:</strong>
            Digi Sapiens hat nach Weisung des Kunden die im Auftrag verarbeiteten Daten jederzeit zu berichtigen, zu
            löschen oder zu sperren, sofern berechtigte Interessen Digi Sapiens nicht entgegenstehen.<br /><br />
          </p>

          <p>
            <strong>6.2. Abschluss vertraglicher Arbeiten, Rückgabe oder Löschung:</strong> Für die Beendigung der
            Auftragsverarbeitung beauftragt Digi Sapiens den Kunden zur Löschung der noch bei Digi Sapiens vorhandenen
            Daten des Kunden.<br /><br />
          </p>

          <p>
            <strong>6.3. Aufbewahrung durch Digi Sapiens:</strong> Aufbewahrungspflichten, denen Digi Sapiens
            unterliegt, bleiben von den vorstehenden Regelungen unberührt. Digi Sapiens ist berechtigt, Dokumentationen
            und andere Daten, die dem Nachweis der auftrags- und ordnungsgemäßen Datenverarbeitung dienen, entsprechend
            der jeweiligen Aufbewahrungsfristen über das Ende der Auftragsverarbeitung hinaus aufzubewahren. Soweit
            solche berechtigte Interessen Digi Sapiens einer Löschung entgegensteht, werden die betroffenen Daten des
            Kunden erst nach Wegfall des Interesses gelöscht.<br /><br />
          </p>

          <h3>7. Begründung von Unterauftragsverhältnissen / Datenverarbeitungen in einem unsicheren Drittland</h3>

          <p>
            <strong>7.1. Unterauftragsverarbeiter:</strong> „Unterauftragsverarbeiter“ im Sinne dieser AV sind solche
            von Digi Sapiens eingesetzte Drittunternehmer, die Leistungen erbringen, die direkt auf die Erbringung der
            Hauptleistung des Hauptvertrages bezogen sind, die im Kernbereich auf eine Verarbeitung von
            personenbezogenen Daten ausgerichtet sind und der Drittunternehmer dabei Zugriff auf die Daten des Kunden
            erhält (z.B. Rechenzentren). Leistungen, die Digi Sapiens bei Drittunternehmern als reine Nebenleistung in
            Anspruch nimmt, um ihre geschäftliche Tätigkeit auszuüben und die nicht im Kernbereich auf eine Verarbeitung
            von personenbezogenen Daten des Kunden ausgerichtet sind, sind hiervor ausgenommen (beispielsweise
            Reinigungsleistungen, reine Telekommunikationsleistungen ohne konkreten Bezug zu Leistungen, die der
            Auftragnehmer für den Auftraggeber erbringt, Post- und Kurierdienste, Transportleistungen,
            Bewachungsdienste). Digi Sapiens ist gleichwohl verpflichtet, auch bei Nebenleistungen, die von
            Drittunternehmen erbracht werden, Sorge dafür zu tragen, dass angemessene Vorkehrungen und technische und
            organisatorische Maßnahmen getroffen wurden, um den Schutz personenbezogener Daten des Kunden zu
            gewährleisten.<br /><br />
          </p>

          <p>
            <strong>7.2. Allgemeine schriftliche Genehmigung:</strong> Die Beauftragung von Unterauftragsverarbeitern
            wird Digi Sapiens hiermit allgemein genehmigt. Dies steht unter dem Vorbehalt, dass Digi Sapiens den Kunden
            über die beabsichtigte Beauftragung des Unterauftragsverarbeiters vorab informiert, so dass der Kunde bei
            Vorliegen wichtiger Gründe die Beauftragung durch Einspruch untersagen kann. Liegt ein Einspruch des Kunden
            nicht binnen 2 Wochen nach Information durch Digi Sapiens vor, gilt die Zustimmung des Kunden als erteilt.
            Digi Sapiens hat sicherzustellen, dass (a) der Unterauftragsverarbeiter in der Lage ist, den gleichen
            datenschutzrechtlichen Anforderungen zu genügen wie die zwischen den Parteien vereinbarten, sowie (b) die
            vertragliche Gestaltung mit dem Unterauftragsverarbeiter so gestaltet ist, dass sie den Regelungen dieser
            AV-Vereinbarung gemessen an der vom Unterauftragsverarbeiter vorgenommenen Datenverarbeitung entspricht, (c)
            der Kunde in angemessener Weise Auskunft über den wesentlichen Vertragsinhalt mit dem
            Unterauftragsverarbeiter und dessen Umsetzung der datenschutzrechtlichen Verpflichtungen erhält
            (erforderlichenfalls durch Einsicht in die relevanten Vertrags- oder Prüfungsunterlagen), um seinen
            gesetzlichen Rechenschaftspflichten nachkommen zu können. Die von Digi Sapiens eingesetzten
            Unterauftragsverarbeiter werden in Anlage B aufgeführt. Für diese Unterauftragsverarbeiter gilt die
            Zustimmung des Kunden mit Abschluss des Hauptvertrages als erteilt.<br /><br />
          </p>

          <p>
            <strong>7.3. Datenverarbeitung in unsicheren Drittländern:</strong> Eine Verarbeitung der Daten durch den
            Auftragnehmer ausschließlich oder auch in einem unsicheren Drittland, darf nur erfolgen, wenn die besonderen
            Voraussetzungen der Art. 44 ff. DSGVO erfüllt sind. Wenn die Verarbeitung der Daten durch Digi Sapiens oder
            einen von Digi Sapiens beauftragten Unterauftragsverarbeiter oder Drittunternehmer in einem unsicheren
            Drittland erfolgt, gelten die mit dem Durchführungsbeschluss (EU) 2021/914 der Kommission vom 4. Juni 2021
            erlassenen EU-Standardvertragsklauseln („EU-Standardvertragsklauseln“) bezüglich dieser Verarbeitung, soweit
            die Datenübermittlung nicht auf eine andere Grundlage gemäß Art. 44 ff. DSGVO gestützt werden kann.
            Verarbeiten Unterauftragnehmer im Sinne von Ziff. 7.1 der AV oder Drittunternehmer Daten in einem unsicheren
            Drittland, stellt der Digi Sapiens in Ergänzung zu den in Ziff. 7.3 der AV genannten Voraussetzungen vor
            Beginn der Verarbeitung durch diese Unterauftragnehmer den Abschluss der EU-Standardvertragsklauseln sicher,
            soweit die Datenübermittlung nicht auf eine andere Grundlage gemäß Art. 44 ff. DSGVO gestützt werden kann.
            Auf Verlangen des Auftraggebers stellt der Auftragnehmer diesem eine Kopie der mit den Unterauftragnehmern
            abgeschlossenen EU-Standardvertragsklauseln zur Verfügung oder benennt die ansonsten in Frage kommende
            Rechtsgrundlage für den Datentransfer und stellt dem Kunden die erforderlichen Angaben und Informationen zum
            Nachweis der Einhaltung der gesetzlichen Vorschriften für Datenexporte in Drittländer zur Verfügung. Zum
            Zeitpunkt des Abschlusses dieser AV bestehende Verarbeitungen in bzw. Übermittlungen an ein Drittland
            ergeben sich aus Anlage B.<br /><br />
          </p>

          <p>
            <strong>7.4. Sub-Unterauftragsverarbeiter:</strong> Für den Einsatz von Sub-Unterauftragsverarbeitern gilt
            diese Ziff. 7 entsprechend.<br /><br />
          </p>

          <h3>8. Nachweismöglichkeiten & Kontrollrechte</h3>

          <p>
            <strong>8.1. Überprüfungen, Nachweis durch Digi Sapiens:</strong> Der Kunde kann auf eigene Kosten die
            Einhaltung der datenschutzrechtlichen Vorschriften und Pflichten dieser AV durch Digi Sapiens durch
            Einholung von Auskünften oder Nachweisen im Hinblick auf die betroffenen Datenverarbeitungsvorgänge
            kontrollieren. Digi Sapiens wird dem Kunden auf dessen Verlangen innerhalb angemessener Frist die Einhaltung
            seiner Pflichten nach dieser AV mit geeigneten Mitteln seiner Wahl nachweisen (beispielsweise durch
            Durchführung eines Selbstaudits, Vorlage eines aktuellen Testats oder einer Selbstauskunft, durch Berichten
            oder Berichtsauszügen unabhängiger Instanzen (z.B. Wirtschaftsprüfer, Revision, Datenschutzbeauftragter, IT-
            Sicherheitsabteilung, Datenschutzauditoren, Qualitätsauditoren) Zertifikate zum Datenschutz und/oder
            Informationssicherheit (z.B. nach BSI-Grundschutz oder ISO 270001) oder Zertifikate nach Art. 42 DSGVO.<br /><br />
          </p>

          <p>
            <strong>8.2. Inspektionen:</strong>
            DerKundewirdvorrangigprüfen,obdieinZiff.8.1.eingeräumtenÜberprüfungsmöglichkeiten ausreichen. Sollten
            darüber hinaus in zu begründenden Ausnahmefällen (beispielsweise bei berechtigten Zweifeln, dass Nachweise
            i.S.v. 8.1 unzureichend oder unzutreffend sind, oder nicht vorgelegt werden, oder bei besonderen Vorfällen
            nach Art. 33 Abs. 1 DSGVO) Kontrollen des Kunden oder durch einen von diesem auf seine Kosten beauftragten
            Prüfers im Einzelfall erforderlich sein, werden diese zu den üblichen Geschäftszeiten Digi Sapiens, ohne
            Störung dessen Betriebsablaufs nach Anmeldung durchgeführt. Digi Sapiens darf Kontrollmaßnahmen des Kunden
            von der vorherigen schriftlichen Anmeldung mit angemessener Vorlaufzeit (mindestens 14 Tage) und Benennung
            mindestens dreier alternativer Termine sowie von der Unterzeichnung einer Verschwiegenheitserklärung
            hinsichtlich der Daten anderer Kunden und der eingerichteten technischen und organisatorischen Maßnahmen
            abhängig machen, sofern nicht besondere Vorfälle eine davon abweichende Kontrolle rechtfertigen. Sollte der
            durch den Kunden beauftragte Prüfer in einem Wettbewerbsverhältnis zu Digi Sapiens stehen, hat Digi Sapiens
            gegen diesen ein Einspruchsrecht. Kontrollen des Kunden vor Ort, sind außer bei Vorliegen wichtiger
            datenschutzrechtlicher Gründe, grundsätzlich als Stichprobenkontrollen der für die Durchführung der
            Auftragsverarbeitung relevanten Bereiche auszugestalten und auf maximal auf einen Tag pro Kalenderjahr zu
            begrenzen.<br /><br />
          </p>

          <h3>9. Anfragen betroffener Personen</h3>

          <p>
            <strong>9.1. Meldung von Anfragen:</strong> Soweit ein Betroffener sich unmittelbar an Digi Sapiens zwecks
            Berichtigung, Löschung seiner Daten oder Auskunft wenden sollte, wird Digi Sapiens die betroffene Person an
            den Kunden verweisen, sofern eine Zuordnung mit den Angaben der betroffenen Person möglich ist. Digi Sapiens
            wird das Ersuchen des Betroffenen unverzüglich an den Kunden weiterleiten.<br /><br />
          </p>

          <p>
            <strong>9.2. Unterstützung bei Betroffenenrechten:</strong> Digi Sapiens unterstützt den Kunden auf dessen
            Weisung mit Informationen die der Kunde für die Erfüllung von Betroffenenanfragen benötigt.<br /><br />
          </p>

          <p>
            <strong>9.3. Verantwortlichkeit für Betroffenenrechten:</strong> Digi Sapiens haftet nicht, wenn das
            Ersuchen der betroffenen Person vom Kunden nicht, nicht richtig oder nicht fristgerecht beantwortet wird.<br /><br />
          </p>

          <h3>10. Maßnahmen Dritter oder Rechte Dritte im Hinblick auf Daten</h3>

          <p>
            Sollten Gegenstände, die Daten enthalten, durch Maßnahmen Dritter (etwa Pfändungen oder Beschlagnahmungen)
            oder von Rechten Dritter (Sicherungsübereignung) betroffen sein, so hat Digi Sapiens den Kunden unverzüglich
            zu informieren. Digi Sapiens wird alle in diesem Zusammenhang Verantwortlichen unverzüglich darüber
            informieren, dass die Hoheit und das Eigentum an den Daten ausschließlich beim Kunden als »Verantwortlicher«
            im Sinne der Datenschutz-Grundverordnung liegen.
            <br /><br />
          </p>

          <h3>11. Haftung</h3>

          <p>
            <strong>11.1. Haftung:</strong> Die zwischen den Parteien im Hauptvertrag getroffene Haftungsregelung gilt
            auch für die zwischen den Parteien vorliegende Auftragsverarbeitung, sofern nicht ausdrücklich eine
            abweichende Vereinbarung getroffen wurde.<br /><br />
          </p>

          <p>
            <strong>11.2. Gegenseitige Unterstützung:</strong> Im Falle einer Inanspruchnahme einer der Parteien durch
            eine betroffene Person hinsichtlich etwaiger Ansprüche nach Art. 82 DSGVO oder einer Aufsichtsbehörde
            aufgrund oder im Zusammenhang mit diese AV, verpflichtet sich die jeweils andere Partei, die in Anspruch
            genommene Partei bei der Abwehr der Ansprüche angemessen zu unterstützen.<br /><br />
          </p>

          <p>
            <strong>11.3. Verantwortungsbereich Auftraggeber, Freistellung:</strong> Soweit durch eine unzulässige oder
            unrichtige Datenverarbeitung im Rahmen dieses Auftragsdatenverarbeitungsverhältnisses ein Schaden entsteht
            und dieser Schaden durch die korrekte Umsetzung der beauftragten Dienstleistung oder einer vom Kunden
            erteilten Weisung entstanden ist, haftet hierfür alleine der Auftraggeber. Der Kunde stellt Digi Sapiens von
            allen Ansprüchen frei, die im Zusammenhang mit der konkreten Umsetzung der beauftragten Dienstleistung oder
            der vom Kunden erteilten Weisung gegen Digi Sapiens erhoben werden. Unter diesen Voraussetzungen ersetzt der
            Kunde Digi Sapiens ebenfalls sämtliche entstandenen Kosten der Rechtsverteidigung.<br /><br />
          </p>

          <p>
            <strong>11.4.</strong> Ziff. 11 gilt entsprechend im Falle von Schäden, die Mitarbeiter von Digi Sapiens
            oder sonstige Erfüllungsgehilfen, einschließlich Unterauftragsverarbeiter, im Zusammenhang mit der
            Erbringung der geschuldeten Verarbeitung von Daten des Kunden schuldhaft verursachen.<br /><br />
          </p>

          <h3>12. Anlagen</h3>

          <p>Die nachfolgend aufgezählten Anlagen werden zum Bestandteil dieser AV:</p>

          <ul>
            <li>Anlage A: Technische und Organisatorische Maßnahmen nach Art. 32 DSGVO</li>
            <li>Anlage B: Einzelheiten zur Auftragsverarbeitung</li>
          </ul>

          <h3>Anlage A: Technisch-organisatorische Maßnahmen (TOMs)</h3>

          <p>
            Diese Anlage A beschreibt die von Digi Sapiens oder deren Unterauftragsverarbeitern im Rahmen ihrer
            Leistungserbringung und der damit von Digi Sapiens für den Kunden veranlassten Verarbeitung von
            personenbezogenen Daten des Kunden ergriffenen Maßnahmen zur Datensicherheit im Sinne von Art. 32 DSGVO.
            Dazu werden insbesondere die Schutzziele der Vertraulichkeit, Integrität und Verfügbarkeit der Systeme und
            Dienste sowie deren Belastbarkeit in Bezug auf Art, Umfang, Umstände und Zweck derart berücksichtigt, dass
            durch geeignete technische und organisatorische Abhilfemaßnahmen das Risiko auf Dauer eingedämmt wird.<br /><br />
          </p>

          <h4><strong>Organisatorische Maßnahmen</strong></h4>

          <ul>
            <li>
              a) Digi Sapiens hat ein angemessenes Datenschutzmanagementsystem, bzw. ein Datenschutzkonzept
              implementiert und gewährleistet dessen Umsetzung.
            </li>
            <li>
              b) Eine geeignete Organisationsstruktur für die Datensicherheit und Datenschutz ist vorhanden und die
              Informationssicherheit ist in unternehmensweite Prozesse und Verfahren integriert.
            </li>
            <li>
              c) Es werden regelmäßig und auch anlasslos System- und Sicherheitstests, wie z. B. Code-Scan,
              Schwachstellenscans und Penetrationstests, durchgeführt.
            </li>
            <li>d) Regelmäßige anlasslose Auswertung der Log-Dateien zur Erkennung von ungewöhnlichen Einträgen.</li>
            <li>
              e) Digi Sapiens führt bei gegebenem Anlass, mindestens aber jährlich, eine Überprüfung, Bewertung und
              Evaluation der Wirksamkeit der technischen und organisatorischen Maßnahmen zur Gewährleistung der
              Sicherheit der Verarbeitung durch. Das Verfahren ist entsprechend dem PDCA-Zyklus aufgebaut und besteht
              aus einer kontinuierlichen Beobachtung der technischen und organisatorischen Maßnahme sowie Festlegung des
              Istzustandes, als auch des zu erreichenden Soll-Zustandes mit folgender Umsetzungs- und sich daran
              anschließenden Überprüfungsphase sowie Evaluierung der Umsetzung und Ableitung der gewonnenen Erfahrungen
              für künftige Optimierungen und Vorgehen im Hinblick auf die Sicherheitsstandards.
            </li>
            <li>
              f) Die Entwicklung des Standes der Technik und sowie der Entwicklungen, Bedrohungen und
              Sicherheitsmaßnahmen werden fortlaufend beobachtet und in geeigneter Art und Weise auf das eigene
              Sicherheitskonzept abgeleitet.
            </li>
            <li>
              g) Es besteht ein Konzept, das die Wahrung der Betroffenenrechte durch den Kunden gewährleistet
              (insbesondere im Hinblick auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung,
              Datentransfer, Widerrufe & Widersprüche). Zu dem Konzept gehört die Unterrichtung der Mitarbeiter über die
              Informationspflichten gegenüber dem Kunden, Einrichtung von Umsetzungsverfahren und die Benennung
              zuständiger Personen sowie regelmäßige Kontrolle und Evaluierung der ergriffenen Maßnahmen.
            </li>
            <li>
              h) Es besteht ein Konzept, das eine unverzügliche und den gesetzlichen Anforderungen entsprechende
              Reaktion auf Gefährdungen und Verletzungen des Schutzes personenbezogener Daten gewährleistet. Zu dem
              Konzept gehört die Unterrichtung der Mitarbeiter über die Informationspflichten gegenüber dem Kunden,
              Einrichtung von Umsetzungsverfahren und die Benennung zuständiger Personen sowie regelmäßige Kontrolle und
              Evaluierung der ergriffenen Maßnahmen.
            </li>
            <li>
              i) Sicherheitsvorkommnisse werden konsequent dokumentiert, auch wenn sie nicht zu einer externen Meldung
              (z. B. an die Aufsichtsbehörde, betroffene Personen) führen (sogenanntes "Security Reporting").
            </li>
            <li>
              j) Konsultation und Einbindung des Datenschutzbeauftragten bei Sicherheitsfragen und in
              Sicherheitsverfahren, die den Schutz personenbetroffener Daten betreffen.
            </li>
            <li>
              k) Ausreichende fachliche Qualifikation des Datenschutzbeauftragten für sicherheits-relevante
              Fragestellungen und Möglichkeiten zur Fortbildung in diesem Fachbereich.
            </li>
            <li>
              l) Drittunternehmer, die zur Erfüllung nebengeschäftlicher Aufgaben herangezogen werden (Projekt- und
              Kollaborationsdienste, freie Mitarbeiter, etc.), werden sorgfältig ausgesucht und es wird sichergestellt,
              dass sie den Schutz personenbezogener Daten beachten. Sofern Drittunternehmer im Rahmen ihrer Tätigkeit
              Zugang zu personenbezogenen Daten des Kunden erhalten oder sonst das Risiko eines Zugriffs auf die
              personenbezogenen Daten des Kunden besteht, werden sie speziell auf Verschwiegenheit und Vertraulichkeit
              verpflichtet.
            </li>
            <li>
              m) Der Schutz von personenbezogenen Daten wird unter Berücksichtigung des Stands der Technik, der
              Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
              unterschiedlichen Eintrittswahrscheinlichkeit und Schwere der mit der Verarbeitung verbundenen Risiken für
              die Rechte und Freiheiten natürlicher Personen bereits bei der Entwicklung, bzw. Auswahl von Hardware,
              Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen berücksichtigt.
            </li>
            <li>
              n) Eingesetzte Software und Hardware wird stets auf dem aktuell verfügbaren Stand gehalten und
              Softwareaktualisierungen werden ohne Verzug innerhalb einer angesichts des Risikogrades und eines
              eventuellen Prüfnotwendigkeit angemessenen Frist ausgeführt. Es wird keine Software und Hardware
              eingesetzt, die von den Anbietern im Hinblick auf Belange des Datenschutzes- und Datensicherheit nicht
              mehr aktualisiert wird (z.B. abgelaufene Betriebssysteme).
            </li>
            <li>o) Standardsoftware und entsprechende Updates werden nur aus vertrauenswürdigen Quellen bezogen.</li>
            <li>
              p) Es liegt ein den Datenschutzanforderungen der Auftragsverarbeitung und dem Stand der Technik
              entsprechendes Löschkonzept vor. Bei Digi Sapiens werden Daten ausschließlich digital verarbeitet.
              Mitarbeiter wurden über Löschfristen und soweit zuständig, über Vorgaben für die Datenlöschung oder
              Gerätevernichtung durch Dienstleister unterrichtet.
            </li>
            <li>
              q) Die Verarbeitung der Daten des Kunden, die nicht entsprechend den Vereinbarungen der AV gelöscht wurden
              (z.B. in Folge der gesetzlichen Archivierungspflichten), wird im erforderlichen Umfang durch Sperrvermerke
              und/oder Aussonderung eingeschränkt.
            </li>
          </ul>

          <h4>Datenschutz auf Mitarbeiterebene</h4>

          <p>
            Es sind Maßnahmen ergriffen worden, die gewährleisten, dass die mit personenbezogenen Daten beschäftigten
            Mitarbeiter, über die datenschutzrechtlich nötige Sachkenntnis und Zuverlässigkeit verfügen.
          </p>

          <ul>
            <li>a) Mitarbeiter werden auf Vertraulichkeit und Verschwiegenheit (Datengeheimnis) verpflichtet.</li>
            <li>
              b) Mitarbeiter werden im Hinblick auf den Datenschutz entsprechend den Anforderungen ihrer Funktion
              sensibilisiert und unterrichtet. Die Schulung und Sensibilisierung werden, wenn es die Umstände erfordern,
              wiederholt.
            </li>
            <li>
              c) Sofern Mitarbeiter außerhalb betriebsinterner Räumlichkeiten tätig werden (Home- und Mobileoffice),
              werden Mitarbeiter über die speziellen Sicherheitsanforderungen sowie Schutzpflichten in diesen
              Konstellationen unterrichtet, sowie auf deren Einhaltung unter Vorbehalt von Kontroll- und Zugriffsrechten
              verpflichtet.
            </li>
            <li>
              d) Die an Mitarbeiter ausgegebene Schlüssel, Zugangskarten oder Codes sowie im Hinblick auf die
              Verarbeitung personenbezogener Daten erteilte Berechtigungen, werden nach deren Ausscheiden aus den
              Diensten des Auftragsverarbeiters, bzw. Wechsel der Zuständigkeiten eingezogen, bzw. entzogen.
            </li>
            <li>
              e) Mitarbeiter werden verpflichtet, ihre Arbeitsumgebung aufgeräumt zu hinterlassen und so insbesondere
              den Zugang zu Unterlagen oder Datenträgern mit personenbezogenen Daten zu verhindern (Clean Desk Policy).
            </li>
          </ul>

          <h4>Zutrittskontrolle</h4>

          <p>
            Es sind Maßnahmen zur physischen Zutrittskontrolle ergriffen worden, die es Unbefugten verwehren, sich den
            Systemen, Datenanlagen oder Verfahren physisch zu nähern:
          </p>

          <ul>
            <li>
              a) Es werden, bis auf die Arbeitsplatzrechner und mobile Geräte, keine Datenverarbeitungsanlagen in den
              eigenen Geschäftsräumlichkeiten unterhalten. Die Daten des Kunden werden bei externen Server-Anbietern
              unter Beachtung der Vorgaben für Auftragsverarbeitung gespeichert. Auf Anfrage hin kann die Beschreibung
              der Maßnahmen des jeweiligen Unterauftragsverarbeiters zur Verfügung gestellt werden.
            </li>
            <li>
              b) Der Zutritt zu Datenverarbeitungsanlagen ist zusätzlich gesichert und nur befugten Mitarbeitern
              möglich.
            </li>
            <li>
              c) Es findet eine Personenkontrolle beim Pförtner oder am Empfang des Rechenzentrumsanbieters statt.
            </li>
          </ul>

          <h4>Zugangskontrolle</h4>

          <p>
            Es sind Maßnahmen zur elektronischen Zugangskontrolle ergriffen worden, die gewährleisten, dass ein Zugang
            (d. h. bereits die Möglichkeit der Nutzung, Verwendung oder Beobachtung) durch Unbefugte zur Plattform
            verhindert wird:
          </p>

          <ul>
            <li>
              a) Ein Passwortkonzept legt fest, dass Passwörter eine dem Stand der Technik und den Anforderungen an
              Sicherheit entsprechende Mindestlänge und Komplexität haben müssen.
            </li>
            <li>b) Sämtliche Datenverarbeitungsanlagen sind passwortgeschützt.</li>
            <li>
              c) Passwörter werden grundsätzlich nicht im Klartext gespeichert und nur gehasht oder verschlüsselt
              (Zertifikate, Tokens) übertragen.
            </li>
            <li>d) Es wird eine Passwort-Management-Software eingesetzt.</li>
            <li>
              e) Für den Zugang zu Daten des Kunden wird im administrativen Bereich eine Zwei-Faktor-Authentifizierung
              verwendet.
            </li>
            <li>f) Es wird auf dem aktuellen Stand gehaltene Anti-Viren-Software eingesetzt.</li>
            <li>g) Einsatz von Software-Firewalls.</li>
          </ul>

          <h4>
            Interne Zugriffskontrolle und Eingabekontrolle (Berechtigungen für Benutzerrechte auf Zugang zu und Änderung
            von Daten)
          </h4>

          <p>
            Es sind Maßnahmen zur Zugriffskontrolle ergriffen worden, die gewährleisten, dass die zur Benutzung der
            IT-Umgebung von Digi Sapiens Berechtigten ausschließlich auf die ihrer Zugriffsberechtigung unterliegenden
            Daten zugreifen können und dass Daten nicht unbefugt gelesen, kopiert, verändert oder entfernt werden
            können. Ferner sind Maßnahmen zur Eingabekontrolle ergriffen worden, die gewährleisten, dass nachträglich
            überprüft und festgestellt werden kann, ob und von wem Daten eingegeben, verändert oder entfernt worden
            sind:
          </p>

          <ul>
            <li>
              a. Ein Rechte- und Rollenkonzept (Berechtigungskonzept) sorgt dafür, dass der Zugriff auf personenbezogene
              Daten nur für einen nach Erforderlichkeitsmaßstäben ausgewählten Personenkreis und nur in dem
              erforderlichen Umfang möglich ist.
            </li>
            <li>
              b. Das Rechte- und Rollenkonzept (Berechtigungskonzept) wird regelmäßig, innerhalb einer angemessenen
              zeitlichen Frequenz sowie wenn ein Anlass es erfordert (z. B. Verstöße gegen die Zugriffsbeschränkungen),
              evaluiert und bei Bedarf aktualisiert.
            </li>
            <li>c. Die Zugriffe auf einzelne Dateien des Kunden werden protokolliert.</li>
            <li>d. Die Eingabe, Veränderung und Löschung einzelner Daten des Kunden wird protokolliert.</li>
            <li>e. Anmeldungen in den Verarbeitungssystemen werden protokolliert.</li>
            <li>
              f. Die Protokoll-, bzw. Logdateien werden vor Veränderung sowie vor Verlust und gegen unberechtigten
              Zugriff geschützt.
            </li>
            <li>
              g. Die Tätigkeiten der Administratoren werden im Rahmen rechtlich zulässiger Möglichkeiten und im Rahmen
              technisch vertretbaren Aufwandes angemessen überwacht und protokolliert.
            </li>
            <li>
              h. Es wird sichergestellt, dass nachvollziehbar ist, welche Beschäftigten oder Beauftragten auf welche
              Daten wann Zugriff hatten (z.B. durch Protokollierung der Softwarenutzung oder Rückschluss aus den
              Zugriffszeiten und dem Berechtigungskonzept).
            </li>
          </ul>

          <h4>Weitergabekontrolle</h4>

          <p>
            Es sind Maßnahmen zur Weitergabekontrolle ergriffen worden, die gewährleisten, dass bei der elektronischen
            Übertragung von Daten oder während ihres Transports oder ihrer Speicherung auf Datenträgern die Daten nicht
            unbefugt gelesen, kopiert, verändert oder entfernt werden können und dass überprüft und festgestellt werden
            kann, an welche Stellen eine Übermittlung personenbezogener Daten durch Einrichtungen zur Datenübertragung
            vorgesehen ist:
          </p>

          <ul>
            <li>
              a. Beim Zugriff auf betriebsinterne Systeme von außen, werden verschlüsselte Übertragungstechnologien
              verwendet (z.B. VPN).
            </li>
            <li>b. Mobile Datenträger werden verschlüsselt.</li>
            <li>
              c. E-Mails werden während der Übertragung verschlüsselt, was bedeutet, dass die E-Mails auf dem Weg vom
              Absender zum Empfänger davor geschützt sind, von jemandem gelesen zu werden, der Zugang zu den Netzwerken
              hat, durch die die E-Mail gesendet wird.
            </li>
            <li>
              d. Die Übermittlung und Verarbeitung von personenbezogenen Daten des Kunden über laletu.de, erfolgt
              geschützt mittels einer TLS oder einer gleichwertig sicheren Verschlüsselung.
            </li>
          </ul>

          <h4>Auftragskontrolle, Zweckbindung und Trennungskontrolle</h4>

          <ul>
            <li>
              a. Es sind Maßnahmen zur Auftragskontrolle ergriffen worden, die sicherstellen, dass die Datenverarbeitung
              im Auftrag nur entsprechend den Weisungen des Kunden verarbeitet werden. Die Maßnahmen gewährleisten, dass
              zu unterschiedlichen Zwecken erhobene Daten des Kunden getrennt werden und keine Vermengung, Verschnitt
              oder sonstige dem Auftrag widersprechende gemeinsame Nutzung dieser Daten erfolgt.
            </li>
            <li>
              b. Die für den Kunden durchgeführten Verarbeitungsprozesse werden in einem angemessenen Umfang, in einem
              Verzeichnis von Verarbeitungstätigkeiten gesondert dokumentiert.
            </li>
            <li>c. Sorgfältige Auswahl von Unterauftragsverarbeitern und sonstigen Dienstleistern.</li>
            <li>
              d. Die Einhaltung von Weisungen des Kunden und des zulässigen Rahmens der Verarbeitung der
              personenbezogenen Daten durch Mitarbeiter und Beauftragte wird in angemessenen Abständen überprüft.
            </li>
            <li>
              e. Die für die Verarbeitung der personenbezogenen Daten des Kunden geltenden Löschfristen werden innerhalb
              des Löschkonzepts von Digi Sapiens - sofern erforderlich, gesondert - dokumentiert:
              <ol type="i">
                <li>Audiodaten der Schüler werden automatisiert nach 12 Monaten gelöscht.</li>
                <li>
                  Sofern der Kunde Nutzerkonten deaktiviert und von der Plattform entfernt, werden die Daten noch 30
                  Tage vorgehalten, bevor diese endgültig gelöscht werden.
                </li>
              </ol>
            </li>
            <li>
              f. Erforderliche Auswertungen und Analysen der Verarbeitung der personenbezogenen Daten des Kunden werden,
              soweit möglich und zumutbar, anonymisiert verarbeitet (d. h. ohne jeglichen Personenbezug) oder zumindest
              entsprechend Art. 4 Nr. 5 DSGVO pseudonymisiert verarbeitet (d. h. in einer Weise, dass die
              personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen
              betroffenen Person zugeordnet werden können, wobei diese zusätzlichen Informationen gesondert aufbewahrt
              werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
              personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen
              werden).
            </li>
            <li>
              g. Die personenbezogenen Daten des Kunden werden von Daten anderer Verarbeitungsverfahren von Digi Sapiens
              logisch getrennt verarbeitetet und vor unberechtigtem Zugriff oder Verbindung oder Verschneidung mit
              anderen Daten geschützt (z.B. in unterschiedlichen Datenbanken, Cluster oder durch angemessene Attribute).
            </li>
            <li>
              h. Produktiv- und Testdaten werden streng getrennt voneinander in unterschiedlichen Systemen gespeichert.
              Die Produktivsysteme werden getrennt und unabhängig von den Entwicklungs- und Testsystemen betrieben.
            </li>
          </ul>

          <h4>
            Sicherung der Integrität und Verfügbarkeit von Daten sowie der Belastbarkeit von Verarbeitungssystemen
          </h4>

          <p>
            Es sind Maßnahmen ergriffen worden, die gewährleisten, dass die Daten des Kunden gegen zufällige Zerstörung
            oder Verlust geschützt sind und in Notfällen zügig wiederhergestellt werden können:
          </p>

          <ul>
            <li>
              a. Es werden ausfallsichere Serversysteme und Dienste eingesetzt, die doppelt, bzw. mehrfach ausgelegt
              sind.
            </li>
            <li>
              b. Die Verfügbarkeit der Datenverarbeitungssysteme wird permanent, insbesondere auf Verfügbarkeit, Fehler
              sowie Sicherheitsvorfälle überwacht und kontrolliert.
            </li>
            <li>
              c. Die personenbezogenen Daten des Kunden werden bei externen Hosting-Anbietern gespeichert. Die
              Hosting-Anbieter werden sorgfältig ausgewählt und erfüllen die Vorgaben an den Stand der Technik, im
              Hinblick den Schutz vor Schäden durch Brand, Feuchtigkeit, Stromausfälle, Katastrophen, unerlaubte
              Zugriffe sowie an Datensicherung und Patchmanagement, als auch an die Gebäudesicherung. Hinweis:
              Entsprechende Unterlagen können dem Kunden auf Anfrage hin zur Verfügung gestellt werden.
            </li>
            <li>
              d. Die Verarbeitung von personenbezogenen Daten erfolgt auf Datenverarbeitungssystemen, die einem
              regelmäßigen und dokumentierten Patch-Management unterliegen, d. h. insbesondere regelmäßig aktualisiert
              werden.
            </li>
            <li>
              e. Die zur Verarbeitung eingesetzten Serversysteme und Dienste werden in angemessenen Abständen
              Belastbarkeitstests und Hardwaretests unterzogen.
            </li>
            <li>
              f. Die Datensätze des Kunden werden systemseitig vor versehentlicher Änderung oder Löschung geschützt (z.
              B. durch Zugriffsbeschränkungen, Sicherheitsabfragen und Backups).
            </li>
            <li>
              g. Es werden Serversysteme und Dienste eingesetzt, die über ein angemessenes, zuverlässiges und
              kontrolliertes Backup- & Wiederherstellungskonzept verfügen.
            </li>
            <li>
              h. Es werden regelmäßig in einem angemessenen Zeitabstand Wiederherstellungstests zur Überprüfung
              durchgeführt, dass die Datensicherungen tatsächlich wieder eingespielt werden können (Datenintegrität der
              Backups).
            </li>
          </ul>

          <h3>Anlage B: Einzelheiten zur Auftragsverarbeitung</h3>

          <p>
            Diese Anlage B regelt in Verbindung mit der AV ergänzend zum Hauptvertrag die Modalitäten zur Verarbeitung
            von Daten im Auftrag des Kunden durch Digi Sapiens.
          </p>

          <h4>Genehmigte Unterauftragsverhältnisse im Zeitpunkt des Abschlusses der Vereinbarung</h4>

          <p>Digi Sapiens setzt im Rahmen der Auftragsverarbeitung folgende Unterauftragsverarbeiter ein:</p>

          <table>
            <thead>
              <tr>
                <th>Unterauftragnehmer (Firma, Adresse)</th>
                <th>Verarbeitete Datenkategorien</th>
                <th>Art der Teilleistung (Verarbeitungsschritte / Zweck der Unterauftragsverarbeitung)</th>
                <th>Ort der Leistungserbringung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg</td>
                <td>alle</td>
                <td>Cloud-Service-Provider: Betrieb der Plattform laletu.de auf Servern von AWS</td>
                <td>EU (Frankfurt)</td>
              </tr>
              <tr>
                <td>GitLab Inc., 268 Bush Street #350, San Francisco, CA 94104-3503, USA</td>
                <td>Internetnutzungsdaten</td>
                <td>Issue-Tracking-System und Sicherstellung der Verfügbarkeit</td>
                <td>
                  USA<br />
                  Ein angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten wird gewährleistet durch:
                  EU- U.S Data Privacy Framework
                </td>
              </tr>
              <tr>
                <td>Userback Pty Ltd., Rochedale, 4123 Queensland Australien</td>
                <td>Internetnutzungsdaten<br />Kontaktdaten<br />Protokolldaten</td>
                <td>Ticketsystem</td>
                <td>
                  Australien<br />
                  Ein angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten wird gewährleistet durch:
                  EU-Standardvertragsklauseln
                </td>
              </tr>
              <tr>
                <td>Zendesk, Inc., 989 Market St, San Francisco, CA 94103, USA</td>
                <td>Internetnutzungsdaten<br />Kontaktdaten<br />Protokolldaten</td>
                <td>CRM- und Ticketsystem</td>
                <td>
                  USA<br />
                  Ein angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten wird gewährleistet durch:
                  EU- U.S Data Privacy Framework
                </td>
              </tr>
              <tr>
                <td>AnyDesk Software GmbH, Türlenstr. 2, 70191 Stuttgart, Deutschland</td>
                <td>Protokolldaten</td>
                <td>Fernwartung</td>
                <td>EU</td>
              </tr>
            </tbody>
          </table>

          <h3>Beschreibung der Auftragsverarbeitung</h3>

          <ol>
            <li>
              <strong>Gegenstand der Datenverarbeitung</strong><br /><br />
              Der Zweck der Datenverarbeitung ist die Bereitstellung, Unterstützung und Betrieb der internetbasierten
              Lernsoftware laletu.de zur Unterstützung der Sprach- und Leseförderung. Diese Software wird
              Bildungseinrichtungen mit dem Ziel bereitgestellt, dass Lehrkräfte mit digitaler Unterstützung der
              Software im Klassenverbund die Lesekompetenz der Schüler verbessern bzw. fördern.<br /><br />
              Weitere Einzelheiten hierzu sowie zum Umfang, Gegenstand und Art der Datenverarbeitung ergeben sich aus
              dem Hauptvertrag und der dazugehörigen Leistungsbeschreibung.<br /><br />
            </li>
            <li>
              <strong>Art der Daten und Kategorien der Betroffenen:</strong><br /><br />
              Betroffen von der Datenverarbeitung sind folgende Datenarten/Datenkategorien:
              <ul>
                <li>Internetnutzungsdaten (IP-Adresse, Besuchszeit und Datum)</li>
                <li>
                  Kontakt- und Stammdaten (Name, Benutzername, Schule, E-Mail, User ID, Passwort) der Softwarenutzer
                </li>
                <li>Protokolldaten (Logfiles über Nutzungsvorgänge)</li>
                <li>Verhaltensdaten der Schüler: Alter, Fremdsprachenbezug, Lesefähigkeit, Audioaufnahmen</li>
              </ul>
              <br />
              Betroffen von der Datenverarbeitung sind folgende Kategorien von Personen (Mehrfachbenennungen möglich):
              <ul>
                <li>Besucher</li>
                <li>Softwarenutzer (Administratoren des Kunden, Lehrkräfte, Lernende, Erziehungsberechtigte)</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
