import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, concatMap, takeUntil, catchError, tap } from 'rxjs/operators';
import { Subject, throwError, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PdfOverlayComponent } from '../helpers/pdf-overlay/pdf-overlay.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../helpers/snackbar/success-snackbar/success-snackbar.component';

export interface Group {
  _id?: string;
  name?: string;
  school_year?: string;
  _students?: any;
  _teacher?: any;
  level?: string;
  teacherId?: string;
  male: number;
  female: number;
  divers: number;
}

export interface GroupData {
  data: Group[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface Student {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
}

export interface Teacher {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
}

export interface TeacherData {
  data: Teacher[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  groupUpdated = new Subject<Group[]>();
  group: Group[] = [];

  private restServerUrl;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  getGroupbyId(id) {
    console.log(id);
    return this.http.get<Group[]>(this.restServerUrl + '/api/group/' + id);
  }

  getGroupsToTeacher(loadChartData?: boolean) {
    return this.http
      .get<Group[]>(this.restServerUrl + '/api/group/getGroupsToTeacher', {
        params: { loadChartData: loadChartData },
      })
      .pipe(tap((data) => console.log(data)));
  }

  getTeacherOverview(teacherId) {
    return this.http.get<any>(
      this.restServerUrl + '/api/group/getTeacherOverview/' + teacherId,
    );
  }

  getGroupUpdateListener() {
    return this.groupUpdated.asObservable();
  }

  getGroupWithUpdateListener() {
    return this.groupUpdated.asObservable();
  }

  getGroup(groupId, teacherId) {
    return this.http
      .get<any>(this.restServerUrl + '/api/group/getGroup/', {
        params: { groupId: groupId, teacherId: teacherId },
      })
      .subscribe((data) => {
        //console.log(data)
        this.group = data;
        this.groupUpdated.next(this.group);
      });
  }

  getGroupWithInfo(groupId, teacherId) {
    return this.http
      .get<any>(this.restServerUrl + '/api/group/getGroupWithInfo/', {
        params: { groupId: groupId, teacherId: teacherId },
      })
      .subscribe((data) => {
        //console.log(data)
        this.group = data;
        this.groupUpdated.next(this.group);
      });
  }

  openPdfOverlay(url, groupName, type): void {
    let dialogRef = this.dialog.open(PdfOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'pdf-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'pdf-overlay',
      data: {
        url: url,
        groupName: groupName,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      //TODO: TRANSLATIONS TO THESE TEXTS BELOW
      let message = 'Sie haben erfolgreich eine Klasse erstellt';
      if (type == 'single') {
        message = 'Sie haben der Klasse einen Schüler hinzugefügt';
      }
      if (type == 'rebuild') {
        message = 'Sie haben die PDF erfolgreich generiert';
      }
      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        panelClass: 'snack-success',
        data: message,
        duration: 3000,
        horizontalPosition: 'left',
      });
    });
  }

  // createGroup(data) {
  //   return this.http
  //       .post<any>(
  //           this.restServerUrl + "/api/group/createNewGroup",
  //           data
  //       ).pipe(map(res => {
  //           return <Group>res;
  //       }));;
  // }

  createGroupWithPDF(group, students, translatedText, namesPackageType) {
    return this.http
      .post<any>(this.restServerUrl + '/api/group/createNewGroup', group)
      .pipe(
        concatMap((data) => {
          console.log(data);
          let groupData = {
            groupId: data._id,
            students: students,
            pdfPrintLayout: group.pdfPrintLayout,
            genaratePDF: group.genaratePDF,
            teacher: group._teacher,
            translatedText: translatedText,
            namesPackageType: namesPackageType,
          };
          if (data._teacher) {
            groupData['teacher'] = data._teacher;
          }
          console.log(groupData);
          return this.http
            .post<any>(
              this.restServerUrl + '/api/student/createStudentsToGroupWithPDF',
              groupData,
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
                responseType: 'blob' as 'json',
              },
            )
            .pipe(tap((res) => console.log(res)));
        }),
      );
  }

  createGroup(group, students, translatedText, namesPackageType) {
    return this.http
      .post<any>(this.restServerUrl + '/api/group/createNewGroup', group)
      .pipe(
        concatMap((data) => {
          console.log(data);
          let groupData = {
            groupId: data._id,
            students: students,
            pdfPrintLayout: group.pdfPrintLayout,
            genaratePDF: group.genaratePDF,
            teacher: group._teacher,
            translatedText: translatedText,
            namesPackageType: namesPackageType,
          };
          if (data._teacher) {
            groupData['teacher'] = data._teacher;
          }
          return this.http
            .post<any>(
              this.restServerUrl + '/api/student/createStudentsToGroup',
              groupData,
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
                responseType: 'blob' as 'json',
              },
            )
            .pipe(tap((res) => console.log(res)));
        }),
      );
  }

  regeneratePdfForGroup(
    groupId,
    teacherId,
    generateNewPasswords,
    studentsData,
    studentListOnly,
    studentsArray,
    translatedText,
  ) {
    let groupData = {
      groupId: groupId,
      teacherId: teacherId,
      generateNewPasswords: generateNewPasswords,
      pdfPrintLayout: '1',
      studentsData: studentsData,
      studentListOnly: studentListOnly,
      studentsArray: studentsArray,
      translatedText: translatedText,
    };
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/regeneratePdfForGroup',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  getGroupsAndLicensesToTeacher(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
  ): Observable<GroupData> {
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection));

    return this.http
      .get<GroupData>(
        this.restServerUrl + '/api/group/getGroupsAndLicensesToTeacher',
        {
          params,
        },
      )
      .pipe(catchError((err) => throwError(err)));
  }

  deleteGroup(groupId) {
    return this.http.delete<Group>(
      this.restServerUrl + '/api/group/deleteGroup/' + groupId,
    );
  }

  getDashboardSummary() {
    return this.http.get<any>(
      this.restServerUrl + '/api/school/a/getDashboardSummary',
    );
  }

  generatePdfForGroup(groupData) {
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/generatePdfForGroup',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  addStudentsToGroupAndGeneratePDF(groupData: any) {
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/createStudentsToGroupWithPDF',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  getUnarchivedGroupsToTeacher() {
    return this.http
      .get<any>(
        this.restServerUrl + '/api/group/getUnarchivedGroupsToTeacher',
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        },
      )
      .pipe(catchError((err) => throwError(err)));
  }
}
