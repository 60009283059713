import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-cancel-add-student',
  templateUrl: './cancel-add-student.component.html',
  styleUrls: ['./cancel-add-student.component.scss'],
})
export class CancelAddStudentComponent implements OnInit {
  translatedText: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<CancelAddStudentComponent>,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  cancel() {
    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
