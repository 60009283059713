<app-admin-group-managing-stepper
  #stepper
  linear
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <!-- STEP 1 -->
  <cdk-step [stepControl]="firstStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <h3 class="h3 h3--bold">
          {{ translatedText.admin_group_managing_create_group_list }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.admin_group_managing_group_overview_headline }}
        </p>
      </div>
      <div class="training-task_selector_wrap">
        <button
          class="training-task_selector"
          [ngClass]="onlyGroupOverview ? 'active' : ''"
          (click)="setTaskData('onlyGroupOverview')"
        >
          <div class="training-task_button_wrap">
            <mat-icon
              svgIcon="streamline_outlined:only-group-overview"
            ></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.admin_group_managing_group_overview }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{
                  translatedText.admin_group_managing_group_overview_only_list
                }}
              </p>
            </div>
          </div>
        </button>
        <button
          class="training-task_selector"
          [ngClass]="completeGroupOverview ? 'active' : ''"
          (click)="setTaskData('completeGroupOverview')"
        >
          <div class="training-task_button_wrap">
            <mat-icon
              svgIcon="streamline_outlined:complete-group-overview"
            ></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{
                  translatedText.admin_group_managing_group_overview_and_pages
                }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{
                  translatedText.admin_group_managing_group_overview_and_pages_access
                }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="training-task_select_button">
      <button mat-flat-button color="secondary" (click)="closeAll($event)">
        {{ translatedText.admin_group_managing_cancel }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!onlyGroupOverview && !completeGroupOverview"
        (click)="checkStep()"
      >
        {{ translatedText.admin_group_managing_next }}
      </button>
    </div>
  </cdk-step>
  <!-- STEP 2 -->
  <cdk-step [stepControl]="secondStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <h3 class="h3 h3--bold">
          {{ translatedText.admin_group_managing_set_names_in_list }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.admin_group_managing_set_names_in_list_question }}
        </p>
      </div>
      <div class="training-task_selector_wrap">
        <button
          class="training-task_selector"
          [ngClass]="withNames ? 'active' : ''"
          (click)="setTaskData('withNames')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:with-names"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.admin_group_managing_set_names }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.admin_group_managing_admin_set_names }}
              </p>
            </div>
          </div>
        </button>
        <button
          class="training-task_selector"
          [ngClass]="withoutNames ? 'active' : ''"
          (click)="setTaskData('withoutNames')"
          *ngIf="chechStudentsGender() == 0"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:without-names"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.admin_group_managing_not_enter_name }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.admin_group_managing_not_enter_name_text }}
              </p>
            </div>
          </div>
        </button>
      </div>
      <div class="managing-create-passwords" [hidden]="onlyGroupOverview">
        <mat-checkbox #passwordSelection class="example-margin">{{
          translatedText.admin_group_managing_create_new_password
        }}</mat-checkbox>
      </div>
    </div>
    <div class="training-task_select_button">
      <button mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.admin_group_managing_back }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!withNames && !withoutNames"
        (click)="pdfstep()"
      >
        {{ translatedText.admin_group_managing_next }}
      </button>
    </div>
  </cdk-step>
  <!-- STEP 3 -->
  <cdk-step [stepControl]="rForm">
    <div class="training-task_select_container edit-group">
      <div class="group-info">
        <h3 class="h3 h3--bold">
          {{ group?.name }} - {{ group?.school_year | slice: 2 }} ({{
            group?._students?.length
          }})
        </h3>
        <div class="gender_header">
          <div class="inline_text">
            <mat-icon
              class="male-icon"
              svgIcon="streamline_outlined:male"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.admin_group_managing_male }} - {{ maleCount }}
            </p>
          </div>
          <div class="inline_text">
            <mat-icon
              class="female-icon"
              svgIcon="streamline_outlined:female"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.admin_group_managing_female }} -
              {{ femaleCount }}
            </p>
          </div>
          <div class="inline_text">
            <mat-icon
              class="divers-icon"
              svgIcon="streamline_outlined:divers"
            ></mat-icon>
            <p class="paragraph">
              {{ translatedText.admin_group_managing_divers }} -
              {{ diversCount }}
            </p>
          </div>
        </div>
      </div>
      <form [formGroup]="rForm" #formDirective="ngForm">
        <div formArrayName="students" class="third_step">
          <div
            *ngFor="let student of getStudentsFormControls(); let i = index"
            [formGroupName]="i"
            class="children_row"
          >
            <p class="paragraph paragraph--bold paragraph--l group_row_number">
              <span class="gender-index">{{ i + 1 }}</span>
            </p>
            <div class="row group_row">
              <div class="col-md-12">
                <span
                  class="paragraph paragraph--bold paragraph--l username-with-avatar username-edit"
                >
                  <span
                    ><img
                      *ngIf="getStudentInfo(student.value.id).avatarUrl"
                      src="{{
                        getStudentInfo(student.value.id).avatarUrl
                      }}" /></span
                  >{{ getStudentInfo(student.value.id).username }}
                </span>
                <mat-label>{{
                  translatedText.admin_group_managing_full_name
                }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="{{
                      translatedText.admin_group_managing_full_name
                    }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-label>{{
                  translatedText.admin_group_managing_language
                }}</mat-label>
                <mat-form-field>
                  <input
                    type="text"
                    placeholder="{{
                      translatedText.admin_group_managing_language
                    }}"
                    aria-label="Muttersprache"
                    matInput
                    formControlName="language"
                    [matAutocomplete]="auto"
                    appAutocompletePosition="trigger"
                    required
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn.bind(this)"
                    (optionSelected)="
                      setLanguageOption($event?.option?.value, i)
                    "
                  >
                    <mat-option
                      *ngFor="let language of filteredOptions[i] | async"
                      [value]="language?.code"
                      >{{ getLanguageByCode(language?.code) }}</mat-option
                    >
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="
                      validadeStudentField(i)
                        .get('language')
                        .hasError('incorrect') &&
                      validadeStudentField(i).get('language').touched &&
                      !validadeStudentField(i)
                        .get('language')
                        .hasError('required')
                    "
                  >
                    {{
                      translatedText.admin_group_managing_please_select_language
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      validadeStudentField(i)
                        .get('language')
                        .hasError('required')
                    "
                  >
                    <!-- Please enter a class name. -->
                    {{ translatedText.admin_group_managing_language_error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-label>{{
                  translatedText.admin_group_managing_birthdate
                }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    [max]="maxBirthday"
                    formControlName="age"
                    type="text"
                    (click)="birthdaypicker.open()"
                    [matDatepicker]="birthdaypicker"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="birthdaypicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #birthdaypicker
                    startView="multi-year"
                  ></mat-datepicker>
                  <!-- <mat-error *ngIf="thirdFormStep.get('age').hasError('required')">Please choose a birth year and month.</mat-error>  -->
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <div class="gender-selection">
                  <mat-label>{{
                    translatedText.admin_group_managing_gender
                  }}</mat-label>
                  <mat-radio-group
                    formControlName="gender"
                    aria-label="Select an option"
                    [value]="student.gender"
                  >
                    <mat-radio-button (change)="genderSelection()" value="male"
                      ><mat-icon
                        class="male-icon"
                        svgIcon="streamline_outlined:male"
                      ></mat-icon
                      >{{
                        translatedText.admin_group_managing_male
                      }}</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="genderSelection()"
                      value="female"
                      ><mat-icon
                        class="female-icon"
                        svgIcon="streamline_outlined:female"
                      ></mat-icon
                      >{{
                        translatedText.admin_group_managing_female
                      }}</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="genderSelection()"
                      value="divers"
                      ><mat-icon
                        class="divers-icon"
                        svgIcon="streamline_outlined:divers"
                      ></mat-icon
                      >{{
                        translatedText.admin_group_managing_divers
                      }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="training-task_select_button">
      <button mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.admin_group_managing_back }}
      </button>
      <button
        [disabled]="rForm.invalid"
        mat-flat-button
        color="primary"
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.admin_group_managing_save }}
      </button>
    </div>
  </cdk-step>
  <!-- STEP 4 -->
  <cdk-step [stepControl]="lastFormStep" #step3="cdkStep">
    <div class="loading-step" *ngIf="isLoading">
      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      <h3 class="h3 h3--bold">
        {{ translatedText.admin_group_managing_pdf_creation }}
      </h3>
      <p class="paragraph">
        <strong>{{ translatedText.admin_group_managing_attention }} </strong
        >{{ translatedText.admin_group_managing_attention_text }}
      </p>
    </div>
    <div class="finished-step edit-group" *ngIf="!isLoading">
      <div class="pdf-info">
        <img
          class="last-step-img-key"
          src="../../../../assets/key.svg"
          alt=""
        />
        <h3 class="h3 h3--bold">
          {{ translatedText.admin_group_managing_pdf_created }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.admin_group_managing_pdf_download_text }}
        </p>
        <button
          mat-flat-button
          color="primary"
          type="button"
          class="pdf_overlay_button"
          (click)="downloadPDF()"
          [hidden]="blobUrl == ''"
        >
          <mat-icon svgIcon="streamline_outlined:download"></mat-icon
          >{{ translatedText.admin_group_managing_pdf_download }}
        </button>
      </div>
    </div>
  </cdk-step>
</app-admin-group-managing-stepper>
