import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { LicenseCheckComponent } from 'src/app/license/license-check/license-check.component';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { SchoolCreateComponent } from 'src/app/school/school-create/school-create.component';

// TODO: Empty interface?
export interface Group {}

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-dashboard-help-desk',
  templateUrl: './dashboard-help-desk.component.html',
  styleUrls: ['./dashboard-help-desk.component.scss'],
})
export class DashboardHelpDeskComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  limit = 10;
  page = 1;
  sortBy = '';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    school_type: [],
    state: [],
    role: [],
    groups: [],
    status: [],
    city: [],
  };
  teachers;
  school_admins;
  schoolsValue;
  dataSource: TeacherData = null;
  pageEvent: PageEvent;
  filterFormGroup: FormGroup;
  isLoading: boolean = false;
  firstView: boolean = false;
  setupRequired: boolean = false;
  private searchInputEvent = new Subject<any>();
  school_types: any[] = [];
  statesArray: any[] = [
    { value: 'BW', viewValue: 'Baden-Württemberg' },
    { value: 'BY', viewValue: 'Bayern' },
    { value: 'BE', viewValue: 'Berlin' },
    { value: 'BB', viewValue: 'Brandenburg' },
    { value: 'HB', viewValue: 'Bremen' },
    { value: 'HH', viewValue: 'Hamburg' },
    { value: 'HE', viewValue: 'Hessen' },
    { value: 'MV', viewValue: 'Mecklenburg-Vorpommern' },
    { value: 'NI', viewValue: 'Niedersachsen' },
    { value: 'NRW', viewValue: 'Nordrhein-Westfalen' },
    { value: 'RP', viewValue: 'Rheinland-Pfalz' },
    { value: 'SL', viewValue: 'Saarland' },
    { value: 'SN', viewValue: 'Sachsen' },
    { value: 'ST', viewValue: 'Sachsen-Anhalt' },
    { value: 'SH', viewValue: 'Schleswig-Holstein' },
    { value: 'TH', viewValue: 'Thüringen' },
  ];
  cityArray: any[] = [];
  states = {
    BW: 'Baden-Württemberg',
    BY: 'Bayern',
    BE: 'Berlin',
    BB: 'Brandenburg',
    HB: 'Bremen',
    HH: 'Hamburg',
    HE: 'Hessen',
    MV: 'Mecklenburg-Vorpommern',
    NI: 'Niedersachsen',
    NRW: 'Nordrhein-Westfalen',
    RP: 'Rheinland-Pfalz',
    SL: 'Saarland',
    SN: 'Sachsen',
    ST: 'Sachsen-Anhalt',
    SH: 'Schleswig-Holstein',
    TH: 'Thüringen',
  };
  paragraphStyles: { [key: string]: string };
  displayedColumns: string[] = [
    'name',
    'school_type',
    'address',
    'city',
    'state',
    'totalAmountLeftDi',
    'totalAmountLeftFo',
    'schoolAdminsCount',
    'teacherCount',
    'buttons',
  ];
  tableSelection = 'wide';
  userType;
  translatedText: any;
  isSchoolsLoaded: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private adminService: AdminService,
    private schoolService: SchoolService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    // Check if otp setup is required
    this.authService.checkSetupRequired();
    this.userType = this.authService.getType();

    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.getTeachers();
    this.getSchoolAdmins();
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length === 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        this.paginator.pageIndex = 0;
        // Check for search results
        if (this.userType === 'support') {
          this.getSchoolsforHelpDesk();
        } else if (this.userType === 'admin') {
          this.getSchoolsForAdmin();
        }
      });

    this.filterFormGroup = new FormGroup({
      school_type: new FormControl(),
      state: new FormControl(),
      city: new FormControl(),
    });
  }

  openCreateSchool() {
    let dialogRef = this.dialog.open(SchoolCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      // Refresh schools on dashboard
      if (this.userType === 'support') {
        this.getSchoolsforHelpDesk();
      } else if (this.userType === 'admin') {
        this.getSchoolsForAdmin();
      }
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        // Refresh schools on dashboard
        if (this.userType == 'support') {
          this.getSchoolsforHelpDesk();
        } else if (this.userType == 'admin') {
          this.getSchoolsForAdmin();
        }
      });
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value === selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  selectSchoolType(school_type) {
    console.log(school_type.value);
    this.filterValues.school_type = school_type.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';
    this.isLoading = true;
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
  }

  selectSchoolCity(city) {
    console.log(city.value);
    this.filterValues.city = city.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';
    this.isLoading = true;
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
  }

  selectSchoolState(state) {
    console.log(state.value);
    this.filterValues.state = state.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';
    this.isLoading = true;
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
  }

  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  openCheckLicense(): void {
    let dialogRef = this.dialog.open(LicenseCheckComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-check-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        overlayView: 'teacherView',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        -1,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (teacherData) => {
          console.log('teacherData', teacherData);
          this.teachers = teacherData.meta.totalItems;
        },
      });
  }

  getSchoolAdmins() {
    this.filterValues.role = ['school_admin'];
    this.adminService
      .getTeacherList(
        this.page,
        -1,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (schooladminsData) => {
          console.log('schooladminsData', schooladminsData);
          this.school_admins = schooladminsData.meta.totalItems;
        },
      });
  }

  getSchoolsforHelpDesk() {
    this.schoolService
      .getSchoolList(
        this.page,
        10,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (schoolData) => {
          console.log(schoolData);
          this.dataSource = schoolData;
          if (!this.isSchoolsLoaded) {
            this.schoolsValue = schoolData.meta.totalItems;
            this.isSchoolsLoaded = true;
          }

          if (!this.firstView) {
            this.cityArray = schoolData.meta.city;
            this.cityArray.sort((a, b) =>
              a.viewValue.localeCompare(b.viewValue),
            );
            this.school_types = schoolData.meta.school_type;
            this.school_types.sort((a, b) =>
              a.viewValue.localeCompare(b.viewValue),
            );
            this.firstView = true;
          }
          this.isLoading = false;
        },
      });
  }

  getSchoolsForAdmin() {
    this.schoolService
      .getCompleteSchoolList(
        this.page,
        10,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (schoolData) => {
          console.log(schoolData);
          this.dataSource = schoolData;
          if (!this.isSchoolsLoaded) {
            this.schoolsValue = schoolData.meta.totalItems;
            this.isSchoolsLoaded = true;
          }
          if (!this.firstView) {
            this.cityArray = schoolData.meta.city;
            this.cityArray.sort((a, b) =>
              a.viewValue.localeCompare(b.viewValue),
            );
            this.school_types = schoolData.meta.school_type;
            this.school_types.sort((a, b) =>
              a.viewValue.localeCompare(b.viewValue),
            );
            console.log(this.school_types);
            this.firstView = true;
          }
          this.isLoading = false;
        },
      });
  }

  getFullStateName(stateCode: string): string {
    return this.states[stateCode] || 'Unbekanntes Bundesland';
  }

  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction === 'asc' ? 1 : -1;
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
  }

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    if (this.userType === 'support') {
      this.getSchoolsforHelpDesk();
    } else if (this.userType === 'admin') {
      this.getSchoolsForAdmin();
    }
  }

  resetFilter() {
    // this.status = "all"
    // this.filterFormGroup.get('type').setValue([]);
    // this.filterFormGroup.get('groups').setValue([]);
    // this.deadlineRange.reset();
    // this.filterValues = {
    //   search: '',
    //   type: [],
    //   deadline: {},
    //   groups: []
    // };
    // this.getSubTasks();
  }

  onSelectionChange(value) {
    console.log(value);
    this.tableSelection = value;
    if (value === 'compact') {
      this.paragraphStyles = {
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
      };
    } else {
      this.paragraphStyles = {};
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
