import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { TaskEditTrainingComponent } from './task-edit-training.component';
import { TaskEditTrainingStepperComponent } from './task-edit-training-stepper/task-edit-training-stepper.component';

@NgModule({
  declarations: [TaskEditTrainingComponent, TaskEditTrainingStepperComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TaskEditTrainingComponent],
})
export class TaskEditTrainingModule {}
