import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AdminService } from 'src/app/providers/admin.service';
import { GroupData, GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-dashboard-license',
  templateUrl: './dashboard-license.component.html',
  styleUrls: ['./dashboard-license.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class DashboardLicenseComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  activeTab = 'isAvailable';
  translatedText: any;
  selectedTabIndex = 0;
  page = 1;
  limit = -1;
  sortBy = 'created';
  sortDirection = 1;
  filterValue = '';
  dataSource: GroupData = null;
  matSortDirection = 'desc';
  filterValues = {
    search: '',
  };
  expandedElement: GroupData | null;
  displayedColumns: string[] = [
    'name',
    'teacher',
    'test_license',
    'training_license',
    /*'type3_license',*/ 'action',
  ];
  nestedColumns: string[] = [
    'placeholder',
    'username',
    'teacher',
    'test_license',
    'training_license',
    /*'type3_license',*/ 'action',
  ];
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];

  requestColumns: string[] = [
    'group',
    'amount',
    'type',
    'created',
    'status',
    'admin',
  ];
  pageEvent: PageEvent;
  requestDataSource = null;
  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private groupService: GroupService,
    private licenseService: LicenseService,
    private route: ActivatedRoute,
    private location: Location,
    private translationService: TranslationService,
    private adminService: AdminService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 0,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        this.paginator.pageIndex = 0;
        // Check for search results
        this.getGroupsList();
      });
    this.getGroupsList();
    this.getRequestedGroups();
    // Get active tab from url
    let activeTabLocation = this.route.snapshot.fragment;
    if (activeTabLocation) {
      // Set active tab on page load
      this.selectedTabIndex = Number(activeTabLocation);
      this.setActiveTabByIndex(this.selectedTabIndex);
    }
  }

  getGroupsList() {
    this.groupService
      .getGroupsAndLicensesToTeacher(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .subscribe({
        next: (groupData) => {
          console.log(groupData);
          this.dataSource = groupData;
        },
      });
  }

  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
    this.getGroupsList();
  }

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getGroupsList();
  }

  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  generateLicenseInformation(licenses, tag, blockedAmount) {
    return (
      this.getLicenseAmount(licenses, tag, 'left') +
      ' ' +
      this.translatedText.license_transfer_available +
      ', ' +
      (this.getLicenseAmount(licenses, tag, 'total') -
        this.getLicenseAmount(licenses, tag, 'left')) +
      ' ' +
      this.translatedText.admin_group_used +
      ', ' +
      blockedAmount +
      ' ' +
      this.translatedText.admin_group_reserved +
      '.'
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.setActiveTabByIndex(tabChangeEvent.index);
  }

  setActiveTabByIndex(index) {
    if (index === 0) {
      this.activeTab = 'isAvailable';
    } else if (index === 1) {
      this.activeTab = 'isRequested';
    }
    this.location.replaceState(this.location.path() + '#' + index);
  }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key == tag) {
          if (type == 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag == 'fo') {
        amount = amount > 0 ? amount / 60 : amount;
      }
    }
    return Math.floor(amount);
  }

  generateLicenseAmountLeft(licenses, tag, type, blockedAmount) {
    let amount = this.getLicenseAmount(licenses, tag, type) - blockedAmount;
    return amount < 0 ? 0 : amount;
  }

  getRequestedGroups() {
    this.licenseService.getRequestsToTeacher();
    this.licenseService
      .getRequestsToTeacherUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any[]) => {
        this.requestDataSource = res;
        // create new array for table
        console.log(res);
      });
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
