<app-teacher-create-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeTeacherOverlay()"
>
  <cdk-step [stepControl]="rForm" #step1="cdkStep">
    <ng-container *ngIf="!createAdmin">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText?.admin_teacher_create_create_teacher }}
        </h2>
        <p class="paragraph paragraph--l">
          {{ translatedText?.admin_teacher_create_create_teacher_text }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="createAdmin">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">
          {{ translatedText?.admin_teacher_create_create_admin }}
        </h2>
        <p class="paragraph paragraph--l">
          {{ translatedText?.admin_teacher_create_create_admin_text }}
        </p>
      </div>
    </ng-container>
    <form [formGroup]="rForm" #formDirective="ngForm">
      <div class="personal-info">
        <div class="row">
          <div class="col-md-12 form_header-headline" *ngIf="!createAdmin">
            <p class="paragraph paragraph--bold paragraph--m">
              {{ translatedText?.admin_teacher_create_personal_information }}
            </p>
          </div>
          <div class="col-md-6">
            <mat-label>{{ translatedText?.admin_teacher_create_firstname }}</mat-label>
            <mat-form-field>
              <input
                matInput
                formControlName="firstname"
                type="text"
                placeholder="{{ translatedText?.admin_teacher_create_firstname }}"
                required
              />
              <mat-error *ngIf="rForm.get('firstname').hasError('required')">
                {{ translatedText?.admin_teacher_create_firstname_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('firstname').hasError('email')">
                {{ translatedText?.admin_teacher_create_email_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('firstname').hasError('hasDigits')">
                {{ translatedText?.admin_teacher_create_hasDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-label>{{ translatedText?.admin_teacher_create_lastname }}</mat-label>
            <mat-form-field>
              <input
                matInput
                formControlName="lastname"
                type="text"
                placeholder="{{ translatedText?.admin_teacher_create_lastname }}"
                required
              />
              <mat-error *ngIf="rForm.get('lastname').hasError('required')">
                {{ translatedText?.admin_teacher_create_lastname_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('lastname').hasError('hasDigits')">
                {{ translatedText?.admin_teacher_create_hasDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-label>{{ translatedText?.admin_teacher_create_email }}</mat-label>
            <mat-form-field>
              <input
                matInput
                formControlName="email"
                type="text"
                placeholder="{{ translatedText?.admin_teacher_create_email }}"
                required
              />
              <mat-error *ngIf="rForm.get('email').hasError('required')">
                <!-- Please enter email address. -->
                {{ translatedText?.admin_teacher_create_email_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('email').hasError('email')">
                <!-- Please enter valid email address. -->
                {{ translatedText?.admin_teacher_create_email_error_valid }}
              </mat-error>
              <mat-error *ngIf="rForm.get('email').hasError('invalidEmail')">
                <!-- Please enter valid email address. -->
                {{ translatedText?.admin_teacher_create_email_error_invalid }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-label>{{ translatedText?.admin_teacher_create_salutation }}</mat-label>
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="{{ translatedText?.admin_teacher_create_salutation }}"
                aria-label="Anrede"
                [matAutocomplete]="auto"
                formControlName="salutation"
                required
              />
              <mat-icon
                class="register-icon"
                matTooltip="{{ translatedText?.admin_teacher_create_salutation_tooltip }}"
                svgIcon="streamline_outlined:hint"
              ></mat-icon>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  <span>{{ item }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="rForm.get('salutation').hasError('required')">
                <!-- Please enter a salutation. -->
                {{ translatedText?.admin_teacher_create_salutation_error }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="access_rights" *ngIf="!createAdmin">
        <div class="row">
          <div class="col-md-12 form_header-headline">
            <p class="paragraph paragraph--bold paragraph--m">
              {{ translatedText?.admin_teacher_create_additional_information }}
            </p>
          </div>
          <div class="col-md-6">
            <mat-label>{{ translatedText?.admin_teacher_create_position }}</mat-label>
            <mat-form-field>
              <mat-select formControlName="position">
                <mat-option value="school_admin">{{
                  translatedText?.admin_teacher_create_position_school_management
                }}</mat-option>
                <mat-option value="teacher">{{
                  translatedText?.admin_teacher_create_position_teacher_form
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="rForm.get('position').hasError('required')">
                {{ translatedText?.admin_teacher_create_position_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <div class="label-wrap">
              <mat-label>{{ translatedText?.admin_teacher_create_group }}</mat-label>
              <p class="paragraph paragraph--s"></p>
            </div>
            <mat-form-field class="group-select">
              <mat-select
                formControlName="groups"
                [max]="3"
                panelClass="admin-group-select"
                disableOptionCentering
                multiple
              >
                <mat-select-trigger>
                  <div class="selected-group-container">
                    <ng-container *ngFor="let selectedText of getSelectedText(); let i = index">
                      <div class="selected-group">
                        <p class="paragraph paragraph--bold paragraph--xs">
                          {{ selectedText }}
                        </p>
                        <mat-icon
                          svgIcon="streamline_outlined:close"
                          (click)="removeSelectedGroup(i, $event)"
                        ></mat-icon>
                      </div>
                    </ng-container>
                  </div>
                </mat-select-trigger>
                <mat-option *ngFor="let group of groups" [value]="group._id" [disabled]="group?._teacher?.length === 3">
                  <!-- Gruppenname und Schuljahr anzeigen -->
                  {{ group.name }} - {{ group.school_year | slice: 2 }}
                  <ng-container *ngIf="group?._teacher.length > 0">
                    <div class="group-select_teachers">
                      <ng-container *ngFor="let teacher of group?._teacher">
                        <div class="teacher_with_icon">
                          <div class="teacher_icon">
                            <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                          </div>
                          <p class="paragraph paragraph--s">{{ teacher.firstname }} {{ teacher.lastname }}</p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="group?._teacher.length < 1">
                    <div>-</div>
                  </ng-container>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isPhone">
        <mat-form-field>
          <input
            matInput
            formControlName="phone"
            type="tel"
            placeholder="{{ translatedText?.admin_teacher_create_tel }}"
          />
          <mat-icon svgIcon="streamline_outlined:hint"></mat-icon>
          <mat-error *ngIf="rForm.get('phone').hasError('required')">
            {{ translatedText?.admin_teacher_create_tel_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('phone').hasError('pattern')">
            {{ translatedText?.admin_teacher_create_tel_error_valid }}
          </mat-error>
        </mat-form-field>
      </ng-container>
    </form>
    <div class="training-task_select_button">
      <button
        mat-flat-button
        color="secondary"
        class="mat-flat-button--full"
        (click)="closeTeacherOverlay()"
        [disabled]="isLoading"
      >
        {{ translatedText?.admin_teacher_create_cancel }}
      </button>
      <button
        [disabled]="rForm.invalid || isLoading"
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="submitForm(formDirective)"
      >
        <span *ngIf="!isLoading">
          <span *ngIf="!createAdmin">{{ translatedText?.admin_teacher_create_create_teacher }}</span>
          <span *ngIf="createAdmin">{{ translatedText?.admin_teacher_create_add }}</span>
        </span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <div class="last_step_container teacher">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <ng-container *ngIf="!createAdmin">
        <h3 class="h3 h3--bold">
          <!-- {{ translatedText?.admin_teacher_create_position_teacher_form }} hahahah -->
          {{ translatedText?.admin_teacher_create_teacher_success }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText?.admin_teacher_create_send_email }}
          <!-- {{ translatedText?.admin_teacher_create_send }} -->
        </p>
        <div class="button_wrap-last-step">
          <button mat-flat-button color="secondary" class="mat-flat-button--full" (click)="closeTeacherOverlay()">
            {{ translatedText?.admin_teacher_create_finish }}
          </button>
          <button mat-flat-button color="primary" class="mat-flat-button--full" type="button" (click)="resetForm()">
            {{ translatedText?.admin_teacher_create_add_more_teachers }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="createAdmin">
        <h3 class="h3 h3--bold">Admin {{ translatedText?.admin_teacher_create_teacher_success }}</h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText?.admin_teacher_create_send_email_admin }}
        </p>
        <div class="button_wrap-last-step">
          <button
            mat-flat-button
            color="secondary"
            class="mat-flat-button--full"
            type="button"
            (click)="closeTeacherOverlay()"
          >
            {{ translatedText?.admin_teacher_create_ok }}
          </button>
          <button mat-flat-button color="primary" class="mat-flat-button--full" type="button" (click)="resetForm()">
            {{ translatedText?.admin_teacher_create_add_more_admin }}
          </button>
        </div>
      </ng-container>
    </div>
  </cdk-step>
</app-teacher-create-stepper>
