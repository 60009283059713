import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { AdminManagementComponent } from './admin-management.component';
import { ProgressBarModule } from '../../helpers/progress-bar/progress-bar.module';
import { AdminManagingStepperComponent } from './admin-managing-stepper/admin-managing-stepper.component';

@NgModule({
  declarations: [AdminManagementComponent, AdminManagingStepperComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [AdminManagementComponent],
})
export class AdminManagementModule {}
