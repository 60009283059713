import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AdminService } from 'src/app/providers/admin.service';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {
  message: string;
}

export class CSV {
  _id: string;
  file: File;
  name: string;
  type?: string;
  size: string;
  originalname?: string;
  path?: string;
}

@Component({
  selector: 'app-csv-import-teacher',
  templateUrl: './csv-import-teacher.component.html',
  styleUrls: ['./csv-import-teacher.component.scss'],
})
export class CsvImportTeacherComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  fileSended: boolean = false;
  isLoading: boolean = false;
  teachers;
  isError: boolean = false;
  isWrongFormat: boolean = false;
  selectedFile;
  @ViewChild('fileInput') fileInput: ElementRef;
  translatedText;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public snackBar: MatSnackBar,
    private adminService: AdminService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  selectFile() {
    console.log('11111');
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    console.log('222222');
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      console.log(this.selectedFile);
      const fileExtension = this.selectedFile.name
        .split('.')
        .pop()
        .toLowerCase();
      if (fileExtension !== 'csv') {
        this.isWrongFormat = true;
        return;
      }

      this.isLoading = true;
      this.adminService
        .teacherCsvImport(this.selectedFile, this.translatedText)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            if (res['status'] == 'finished') {
              this.fileSended = true;
              this.isLoading = false;
              this.teachers = res.data?.user?.length;
            }
          },
          (error) => {
            this.isLoading = false;
            this.isError = true;
            this.selectedFile = null;
            console.error(error);
          },
        );
    }
  }

  selectNewFile() {
    this.isWrongFormat = false;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
