import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

export interface RecordData {}

@Component({
  selector: 'app-record-overlay',
  templateUrl: './record-overlay.component.html',
  styleUrls: ['./record-overlay.component.scss'],
})
export class RecordOverlayComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isCanceled: boolean = false;
  isRestart: boolean = false;
  isFinished: boolean = false;
  status;
  taskType;
  isDiagnosticTest: boolean = false;
  translatedText: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: RecordData, // TODO: private or public?
    public dialogRef: MatDialogRef<RecordOverlayComponent>, // TODO: unused? private or public?
    public dialog: MatDialog, // TODO: unused? private or public?
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      console.log(injectedData);
      if (typeof injectedData['status'] !== 'undefined') {
        this.status = this.injectedData['status'];
      }
      if (typeof injectedData['taskType'] !== 'undefined') {
        this.taskType = this.injectedData['taskType'];
        if (
          this.taskType === 'diagnostic-pre' ||
          this.taskType === 'diagnostic-mid' ||
          this.taskType === 'diagnostic-post'
        ) {
          this.isDiagnosticTest = true;
        }
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    switch (this.status) {
      case 'cancel':
        this.isCanceled = true;
        break;
      case 'restart':
        this.isRestart = true;
        break;

      case 'finished':
        this.isFinished = true;
        break;

      default:
        break;
    }
    console.log(this.status);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
