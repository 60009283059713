<div
  class="student-progress-level-container student-progress-level-container--multiple-colors"
  *ngIf="!isRedingPoints"
  [ngClass]="isNumbers ? 'student-progress-level-container--numbers' : ''"
>
  <div class="status-bar-indicator"></div>
  <div class="numbers-wrap" *ngIf="isNumbers">
    <p class="paragraph--xs">1</p>
    <p class="paragraph--xs">2</p>
    <p class="paragraph--xs">3</p>
    <p class="paragraph--xs">4</p>
    <p class="paragraph--xs">5</p>
  </div>
  <div class="status-bar" [ngClass]="isNumbers ? 'status-numbers' : 'only-status-bar'">
    <p class="statusbar-text paragraph paragraph--s paragraph--bold" [style.left.%]="statusValueLegend">
      {{ statusbarValue }} {{ statusbarText }}
    </p>
    <div class="statusbar-selector" [style.left.%]="statusValue" [style.background]="color"></div>
  </div>
</div>

<div class="student-progress-level-container student-progress-level-container--reading-points" *ngIf="isRedingPoints">
  <div class="status-bar-indicator"></div>
  <div
    class="statusbar-selector"
    [style.width.%]="reading_combined_score"
    [style.background]="reading_point_color"
  ></div>
</div>
