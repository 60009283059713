<div class="group-delete_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap automode-wrap">
    <div class="group_headline">
      <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
      <p class="paragraph paragraph--bold paragraph--xl">{{ groupName }}</p>
    </div>

    <h2 class="h2 group-delete_header">{{ translatedText.automode_overlay_title }}</h2>
    <p class="paragraph paragraph--xl">{{ translatedText.automode_overlay_description }}</p>

    <div class="button-wrap">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
        {{ translatedText.automode_overlay_cancel }}
      </button>
      <button mat-flat-button color="warning" type="button" (click)="disableAutoMode()">
        {{ translatedText.automode_overlay_stop }}
      </button>
    </div>

    <!-- <img class="footer_group-delete_img"  src="../../../assets/laletu-logo.svg" alt=""> -->
  </div>
</div>
