import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  Optional,
  OnDestroy,
} from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { GroupService } from '../../../providers/group.service';
import { TaskService } from '../../../providers/task.service';
import { GroupData, AdminService } from '../../../providers/admin.service';
import { CdkStepper } from '@angular/cdk/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSelectionList } from '@angular/material/list';
import { DatePipe } from '@angular/common';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ActivatedRoute } from '@angular/router';

export interface Group {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface DialogData {}

class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'app-admin-add-existing-students',
  templateUrl: './admin-add-existing-students.component.html',
  styleUrls: ['./admin-add-existing-students.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class AdminAddExistingStudentsComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('diagnosticDateChanger') diagnosticDateChanger: CdkStepper;
  @ViewChild('students') students: MatSelectionList;
  secondFormStep: FormGroup;
  thirdFormStep: FormGroup;
  fourthFormStep: FormGroup;
  lastFormStep: FormGroup;
  previewStep;
  viewStep;
  id;
  firstStep;
  groups: any = [];
  selectedStudents = [];
  isReadingTime: boolean = true;
  bookIsbn;
  selectedIndex: number;
  isNavStep: boolean = false;
  isClosedDialog: boolean = false;
  tasks;
  bookSelected: boolean = false;
  allSelected = false;
  isRead: boolean = false;
  isBook: boolean = false;
  studentsArray = [];
  groupArray = [];
  groupOverview = [];
  teacherId;
  bookTitle = 'Niko 3';
  avatarArray = [];
  taskOverview = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  singleGroupStudents = [];
  singleStudent = [];
  studentId;
  userType;
  todayDate: Date = new Date();
  isLoading: boolean = false;
  taskDetail = [];
  wordcount = 100;
  groupGrade;
  blobUrl;
  minDate;
  maxDate;
  referenceValue;
  lastStepText;
  groupName;
  groupId;
  groupStudents;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private authService: AuthService,
    private adminService: AdminService,
    private studentService: StudentService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupStudents'] !== 'undefined') {
        this.groupStudents = this.injectedData['groupStudents'];
      }
    }
  }

  ngOnInit() {
    this.id = this.authService.getUserId();
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.adminService
      .getGroupList(1, 1000, 'name', 1, { search: '' })
      .pipe(
        map((groupData: GroupData) => {
          this.groups = groupData.data.filter(
            (group) => group._id !== this.groupId,
          );
          console.log(this.groups);
          this.groups.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
          this.groups.forEach((element) => {
            element._students.forEach((element) => {
              // to sjow and hide buttons in accordion
              element['isSelectedReferenceValue'] = false;
              // dummydata for every student
              element['referenceValue'] = 76;
            });
            element._students.sort(function (a, b) {
              return a['username'].localeCompare(b['username']);
            });
          });
        }),
      )
      .subscribe();

    this.thirdFormStep = this._formBuilder.group({
      students: [''],
      referenceValue: [''],
    });

    this.lastFormStep = this._formBuilder.group({});

    if (this.isSingleGroup === true) {
      this.singleGroupStudents.forEach((element) => {
        this.studentsArray.push(element);
        this.thirdFormStep.get('students').setValidators(null);
      });
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }

    if (this.isSingleStudent === true) {
      this.studentsArray.push(this.singleStudent);
      this.thirdFormStep.get('students').setValidators(null);
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }
  }

  getNextMonday(date = new Date()) {
    const dateCopy = new Date(date);
    const nextMonday = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
      ),
    );
    return nextMonday;
  }

  isOptionDisabled(group: any, selectedOptions: any[], student: any): boolean {
    const foundItem = this.groupStudents.find(
      (item) => item._id === student._id,
    );
    if (foundItem) {
      return true;
    } else {
      return false;
    }
  }

  disableSelectAll(students) {
    for (const student of students) {
      const foundItem = this.groupStudents.some(
        (groupStudent) => groupStudent._id === student._id,
      );
      if (foundItem) {
        return true;
      }
    }
    return false;
  }

  // to fix error in class detail
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // add style to step 0 and set task type for selectedType()
  addStyle(value) {
    if (value === 'read') {
      this.isRead = !this.isRead;
      this.isBook = false;
    }

    if (value === 'book') {
      this.isRead = false;
      this.isBook = !this.isBook;
    }
  }

  filterGroupsWithoutPersonalData(groups) {
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasEmptyGender = element._students.some(
        (student) => !student.gender,
      );
      return !hasEmptyGender;
    });
  }

  removeFromStudentsArray(index: number, id): void {
    // this.studentsArray.splice(index, 1);
    this.studentsArray = this.studentsArray.filter((item) => item._id !== id);
  }

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select === true) {
      students.options.forEach((element) => {
        if (element?._value?.status === 'active') {
          element.selected = true;
          this.studentsArray.push(element._value);
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  checkForItemSelection(array, value) {
    return array.filter((item) => item.value === value).length > 0
      ? true
      : false;
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    console.log(event);
    if (this.isSingleGroup === false) {
      if (event.options[0].selected === true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected === false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup === true || this.isSingleStudent === true) {
      if (
        event.options[0].selected === false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected === true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }

    this.studentsArray.forEach((element) => {
      element.groupName = this.groups.filter(
        (group) => group?.group?._id === element?._group[0],
      )[0]?.group?.name;
      element.groupSchoolYear = this.groups.filter(
        (group) => group?.group?._id === element?._group[0],
      )[0]?.group?.school_year;
    });
    console.log(this.studentsArray);
  }

  downloadPDF() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const formattedDate = this.datePipe.transform(
      currentDate,
      `${year}${month}${day}_`,
    );
    var element = document.createElement('a');
    element.setAttribute('href', this.blobUrl);
    element.setAttribute('download', formattedDate + this.groupName + '.pdf');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.dialog.closeAll();
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  checkForSelection(student, index, id?) {
    return this.studentsArray.some((el) => {
      return el._id === student._id;
    });
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  getStundentsByGroup(id) {
    return this.tasks.filter((task) => task._group === id);
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.thirdFormStep.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    this.stepper.selectedIndex = 1;
    const students = this.studentsArray.map((students) => students._id);
    console.log(students);

    this.studentService
      .addExistingStudentsToGroup(this.groupId, students, this.translatedText)
      .subscribe({
        next: (response: any) => {
          if (response) {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            this.blobUrl = window.URL.createObjectURL(blob);
            this.isLoading = false;
            console.log('BLOOOOOB --->', blob);
            // this.blobUrl = blobUrl;
            // this.isLoading = false;
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'pre':
        title = 'Diagnose 1';
        break;
      case 'mid':
        title = 'Diagnose 2';
        break;
      case 'post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  selectReferenceValue(student) {
    student.isSelectedReferenceValue = true;
    this.thirdFormStep.get('referenceValue').setValue(student?.referenceValue);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
