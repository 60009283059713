import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GenerateClassPDFComponent } from './generate-class-pdf.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [GenerateClassPDFComponent],
  imports: [BrowserModule, CommonModule, MaterialModule, LoadingSpinnerModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [GenerateClassPDFComponent],
})
export class GenerateClassPDFModule {}
