<div class="home-subscribe-dialog">
  <button type="button" class="close-button close-button--right" mat-mini-fab [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="col-lg-6 home-subscribe-dialog--woman-img"></div>
  <div class="col-lg-6 home-subscribe-dialog--right-col">
    <div>
      <div class="home-subscribe-dialog--right-col-header">
        <h1>{{ translatedText.parents_subscribe_dialog_title }}</h1>
        <span>
          <img class="--laletu-logo" src="../../assets/laletu-logo-nobook.svg" />
        </span>
      </div>
      <p>
        <strong>{{ translatedText.parents_subscribe_dialog_text_1 }} </strong>
        {{ translatedText.parents_subscribe_dialog_text_2 }}
        <a href="https://www.klett.de/inhalt/272087" target="_blank">{{
          translatedText.parents_subscribe_dialog_text_3
        }}</a
        >{{ translatedText.parents_subscribe_dialog_text_4 }}
      </p>
      <p>
        <strong>{{ translatedText.parents_subscribe_dialog_license_code }}</strong>
        {{ translatedText.parents_subscribe_dialog_license_code_2 }}
      </p>
    </div>
    <div class="home-subscribe-dialog--right-col-btn-wrap">
      <a href="https://www.klett.de/inhalt/272087" target="_blank">
        <button mat-flat-button class="home-primary_inverted">
          {{ translatedText.parents_subscribe_dialog_shop }}
        </button>
      </a>
      <button
        mat-flat-button
        class="home-primary_student"
        (click)="closeDialog()"
        routerLinkActive="active-link"
        [routerLink]="['/registrierung']"
      >
        {{ translatedText.parents_subscribe_dialog_button }}
      </button>
    </div>
    <!-- <div class="home-subscribe-dialog--right-col-footer">
      <p> Sie sind KEINE Schule? </p>
      <a>Registrieren Sie sich hier</a>
    </div> -->
  </div>
</div>
