import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { AutomodekOverlayComponent } from './automode-overlay.component';

@NgModule({
  declarations: [AutomodekOverlayComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [AutomodekOverlayComponent],
})
export class AutomodeOverlayModule {}
