import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { MicrophoneCheckComponent } from './microphone-check.component';

@NgModule({
  declarations: [MicrophoneCheckComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [MicrophoneCheckComponent],
})
export class MicrophoneCheckModule {}
