//import { Directionality } from '@angular/cdk/bidi';
import {
  // ChangeDetectorRef,
  Component,
  Input,
  //QueryList,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { /* CdkStep, */ CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-teacher-delete-stepper',
  templateUrl: './teacher-delete-stepper.component.html',
  styleUrls: ['./teacher-delete-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: TeacherDeleteStepperComponent },
  ],
})
export class TeacherDeleteStepperComponent extends CdkStepper {
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Input('stepValue') stepValue: number;
  @Output('isClosedDialog') closeButton = new EventEmitter<boolean>();

  //backButton: boolean = true;

  onClick(index: number): void {
    console.log(index);
    this.steps;
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    /* if (this.selectedIndex > 0) {
      this.backButton = false;
    } else {
      this.backButton = true;
    } */
  }

  nextStep(index: number): void {
    console.log(index);
    this.informParentSelectedStep.emit(index);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.stepValue);
    if (changes.stepValue != undefined) {
        this.onClick(changes.stepValue.currentValue)
    }
  }
}
