import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/providers/notification.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  message: string;
}

export interface Notification {
  message?: string;
  type?: string;
  _id?: string;
}

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit, OnDestroy {
  notificationBadgeCount;
  notifications: Notification[] = [];
  currentIndex: number = 0;
  userType;
  task;
  view;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['notifications'] !== 'undefined') {
        this.notifications = this.injectedData['notifications'];
      }
      if (typeof injectedData['task'] !== 'undefined') {
        this.task = this.injectedData['task'];
      }
      if (typeof injectedData['view'] !== 'undefined') {
        this.view = this.injectedData['view'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.userType = this.authService.getType();
    console.log(this.notifications);
    if (this.currentIndex === 0) {
      this.notificationService
        .updateNotification(this.notifications[0]?._id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          console.log(res);
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  nextNotification(): void {
    if (this.currentIndex < this.notifications.length - 1) {
      this.currentIndex++;
      this.notificationService
        .updateNotification(this.notifications[this.currentIndex]['_id'])
        .subscribe((res) => {
          console.log(res);
        });
    }
  }

  closeNotificationList() {
    this.dialog.closeAll();
  }
}
