import { Location } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TeacherData } from 'src/app/providers/admin.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

// TODO: empty interface?
export interface DialogData {}

// TODO: empty interface?
export interface Group {}

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-school-license-detail',
  templateUrl: './school-license-detail.component.html',
  styleUrls: ['./school-license-detail.component.scss'],
})
export class SchoolLicenseDetailComponent implements OnInit, OnDestroy {
  limit = 10;
  page = 1;
  sortBy = '';
  sortDirection = 1;
  matSortDirection = 'asc';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filterValues = {
    search: '',
    school_type: [],
    state: [],
    role: [],
    groups: [],
    status: [],
    city: [],
    schoolId: '',
  };
  teachers;
  school_admins;
  schoolsValue;
  dataSource: TeacherData = null;
  pageEvent: PageEvent;
  licenseManagmentFilter = 'all';
  licenseRequestFilter = 'all';
  alllicenseAdministration;
  filterFormGroup: FormGroup;
  isLoading: boolean = false;
  firstView: boolean = false;
  selectedIndex;
  displayedColumnsLicenseAdministration: string[] = [
    'created',
    'group',
    'teacher',
    'role',
    'type_text',
    'amount',
  ];
  requestColumns: string[] = [
    'created',
    'teacher',
    'status',
    'type_key',
    'amount',
  ];
  licenseCodeColumns: string[] = [
    'used_at',
    'master_license_key',
    'status',
    'amount_left_di',
    'amount_left_fo',
  ];
  requestDataSource = null;
  allrequestDataSource;
  licenseCodeSource = null;
  statusArray: any[] = [
    { value: 'active', viewValue: 'Aktiv' },
    { value: 'inactive', viewValue: 'Inaktiv' },
  ];
  school_id;
  school_name;
  selectedTabIndex = 0;
  licenseAdministration;
  userType;
  licensesToSchool;
  transformedArray = [];
  selectedValue: string;
  selectedRequestValue: string;
  deadlineRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  translatedText: any;

  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public router: Router, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public location: Location, // TODO: private or public?
    private route: ActivatedRoute,
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['school_id'] !== 'undefined') {
        this.school_id = this.injectedData['school_id'];
      }
      if (typeof injectedData['school_name'] !== 'undefined') {
        this.school_name = this.injectedData['school_name'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    this.getRequestedGroups();
    this.getLicensesToSchool();
    this.licenseService.getSubLicensesToSchool(this.school_id);
    this.licenseService
      .getSubLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licenseAdministration = licenses;
        this.alllicenseAdministration = licenses;
        console.log(licenses);
      });
    this.filterFormGroup = new FormGroup({
      status: new FormControl(),
    });
  }

  selectStatus(status) {
    this.filterValues.status = status.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';
    this.isLoading = true;
    //this.getTeachers();
  }

  navigateBack(): void {
    this.location.back();
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value === selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction === 'asc' ? 1 : -1;
    //this.getTeachers();
  }

  tabChanged(event: any) {
    this.selectedIndex = event.index;
    //this.getTeachers();
  }

  onSelectionChange() {
    this.licenseAdministration = this.alllicenseAdministration;
    if (
      this.deadlineRange.value.start != null &&
      this.deadlineRange.value.end != null
    ) {
      const start = new Date(this.deadlineRange.value.start);
      const end = new Date(this.deadlineRange.value.end);

      this.licenseAdministration = this.licenseAdministration.filter((item) => {
        const createdDate = new Date(item.created);
        return createdDate >= start && createdDate <= end;
      });
    } else {
      return (this.licenseAdministration = this.alllicenseAdministration);
    }

    if (this.licenseManagmentFilter === 'all') {
      if (
        this.deadlineRange.value.start == null &&
        this.deadlineRange.value.end == null
      ) {
        this.licenseAdministration = this.alllicenseAdministration;
      }
    } else if (this.licenseManagmentFilter === 'diagnostic') {
      this.licenseAdministration = this.licenseAdministration.filter(
        (item) => item.type_key === 'di',
      );
    } else if (this.licenseManagmentFilter === 'training') {
      this.licenseAdministration = this.licenseAdministration.filter(
        (item) => item.type_key === 'fo',
      );
    }

    // if (this.selectedValue === 'today') {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);

    //   this.licenseAdministration = this.licenseAdministration.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate.toDateString() === today.toDateString();
    //   });
    // } else if (this.selectedValue === 'week') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const dayOfWeek = currentDate.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    //   const startOfWeek = new Date(currentDate);
    //   startOfWeek.setDate(currentDate.getDate() - dayOfWeek); // Beginn der Woche
    //   const endOfWeek = new Date(startOfWeek);
    //   endOfWeek.setDate(startOfWeek.getDate() + 6); // Ende der Woche

    //   this.licenseAdministration = this.licenseAdministration.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfWeek && createdDate <= endOfWeek;
    //   });
    // } else if (this.selectedValue === 'month') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    //   const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    //   this.licenseAdministration = this.licenseAdministration.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfMonth && createdDate <= endOfMonth;
    //   });
    // } else if (this.selectedValue === 'half_year') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfHalfYear = new Date(currentDate);
    //   startOfHalfYear.setMonth(currentDate.getMonth() - 6);

    //   this.licenseAdministration = this.licenseAdministration.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfHalfYear && createdDate <= currentDate;
    //   });
    // } else if (this.selectedValue === 'year') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

    //   this.licenseAdministration = this.licenseAdministration.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfYear && createdDate <= currentDate;
    //   });
    // }  else if (this.selectedValue === 'all') {
    //   this.licenseAdministration = this.alllicenseAdministration;
    // }
  }

  onRequestSelectionChange() {
    this.requestDataSource = this.allrequestDataSource;
    if (
      this.deadlineRange.value.start != null &&
      this.deadlineRange.value.end != null
    ) {
      const start = new Date(this.deadlineRange.value.start);
      const end = new Date(this.deadlineRange.value.end);

      this.requestDataSource = this.requestDataSource.filter((item) => {
        const createdDate = new Date(item.created);
        return createdDate >= start && createdDate <= end;
      });
    } else {
      return (this.requestDataSource = this.allrequestDataSource);
    }

    if (this.licenseRequestFilter === 'all') {
      if (
        this.deadlineRange.value.start == null &&
        this.deadlineRange.value.end == null
      ) {
        this.requestDataSource = this.allrequestDataSource;
      }
    } else if (this.licenseRequestFilter === 'diagnostic') {
      this.requestDataSource = this.requestDataSource.filter(
        (item) => item.type_key === 'di',
      );
    } else if (this.licenseRequestFilter === 'training') {
      this.requestDataSource = this.requestDataSource.filter(
        (item) => item.type_key === 'fo',
      );
    }

    // if (this.selectedRequestValue === 'today') {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);

    //   this.requestDataSource = this.requestDataSource.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate.toDateString() === today.toDateString();
    //   });
    // } else if (this.selectedRequestValue === 'week') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const dayOfWeek = currentDate.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    //   const startOfWeek = new Date(currentDate);
    //   startOfWeek.setDate(currentDate.getDate() - dayOfWeek); // Beginn der Woche
    //   const endOfWeek = new Date(startOfWeek);
    //   endOfWeek.setDate(startOfWeek.getDate() + 6); // Ende der Woche

    //   this.requestDataSource = this.requestDataSource.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfWeek && createdDate <= endOfWeek;
    //   });
    // } else if (this.selectedRequestValue === 'month') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    //   const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    //   this.requestDataSource = this.requestDataSource.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfMonth && createdDate <= endOfMonth;
    //   });
    // } else if (this.selectedRequestValue === 'half_year') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfHalfYear = new Date(currentDate);
    //   startOfHalfYear.setMonth(currentDate.getMonth() - 6);

    //   this.requestDataSource = this.requestDataSource.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfHalfYear && createdDate <= currentDate;
    //   });
    // } else if (this.selectedRequestValue === 'year') {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0);
    //   const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

    //   this.requestDataSource = this.requestDataSource.filter((item) => {
    //     const createdDate = new Date(item.created);
    //     return createdDate >= startOfYear && createdDate <= currentDate;
    //   });
    // }  else if (this.selectedValue === 'all') {
    //   this.requestDataSource = this.allrequestDataSource;
    // }
  }

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    //this.getTeachers();
  }

  resetFilter() {
    // this.status = "all"
    // this.filterFormGroup.get('type').setValue([]);
    // this.filterFormGroup.get('groups').setValue([]);
    // this.deadlineRange.reset();
    // this.filterValues = {
    //   search: '',
    //   type: [],
    //   deadline: {},
    //   groups: []
    // };
    // this.getSubTasks();
  }

  getRequestedGroups() {
    this.licenseService.getRequestsToSchool(this.school_id);
    this.licenseService
      .getRequestsToSchoolUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any[]) => {
        // create new array for table
        console.log(res);
        this.requestDataSource = res;
        this.allrequestDataSource = res;
      });
  }

  getLicensesToSchool() {
    this.licenseService.getLicensesToSchool(this.school_id);
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;

        this.licensesToSchool.forEach((item) => {
          const existingItem = this.transformedArray.find(
            (e) => e.master_license_key === item.master_license_key,
          );

          if (existingItem) {
            if (item.type_key === 'fo') {
              existingItem.amount_fo = item.amount;
              existingItem.amount_left_fo = item.amount_left;
            } else if (item.type_key === 'di') {
              existingItem.amount_di = item.amount;
              existingItem.amount_left_di = item.amount_left;
            }
          } else {
            const newItem = { ...item };

            if (item.type_key === 'fo') {
              newItem.amount_fo = item.amount;
              newItem.amount_left_fo = item.amount_left;
              delete newItem.amount;
              delete newItem.amount_left;
            } else if (item.type_key === 'di') {
              newItem.amount_di = item.amount;
              newItem.amount_left_di = item.amount_left;
              delete newItem.amount;
              delete newItem.amount_left;
            }

            this.transformedArray.push(newItem);
          }
        });
        this.licenseCodeSource = this.transformedArray;
        console.log(this.transformedArray);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
