import { DecimalPipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { PinCodeComponent } from 'src/app/helpers/pin-code/pin-code.component';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';
import { SchoolDeleteComponent } from '../school-delete/school-delete.component';
import { SchoolEditComponent } from '../school-edit/school-edit.component';
import { SchoolLicenseDetailComponent } from '../school-license-detail/school-license-detail.component';

export interface Group {}

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-school-detail',
  templateUrl: './school-detail.component.html',
  styleUrls: ['./school-detail.component.scss'],
})
export class SchoolDetailComponent implements OnInit, OnDestroy {
  limit = 10;
  page = 1;
  sortBy = '';
  sortDirection = 1;
  matSortDirection = 'asc';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filterValues = {
    search: '',
    school_type: [],
    state: [],
    role: [],
    groups: [],
    status: [],
    city: [],
    schoolId: '',
  };
  teachers;
  school_admins;
  schoolsValue;
  dataSource: TeacherData = null;
  pageEvent: PageEvent;
  filterFormGroup: FormGroup;
  isLoading: boolean = false;
  firstView: boolean = false;
  private searchInputEvent = new Subject<any>();
  schoolId;
  school;
  selectedIndex: number = 0;
  states = {
    BW: 'Baden-Württemberg',
    BY: 'Bayern',
    BE: 'Berlin',
    BB: 'Brandenburg',
    HB: 'Bremen',
    HH: 'Hamburg',
    HE: 'Hessen',
    MV: 'Mecklenburg-Vorpommern',
    NI: 'Niedersachsen',
    NRW: 'Nordrhein-Westfalen',
    RP: 'Rheinland-Pfalz',
    SL: 'Saarland',
    SN: 'Sachsen',
    ST: 'Sachsen-Anhalt',
    SH: 'Schleswig-Holstein',
    TH: 'Thüringen',
  };
  displayedColumnsTeacher: string[] = [
    'name',
    'email',
    'group',
    'status',
    'buttons',
  ];
  displayedColumns: string[] = ['name', 'email', 'role', 'status', 'buttons'];
  statusArray: any[] = [
    { value: 'active', viewValue: 'Aktiv' },
    { value: 'inactive', viewValue: 'Inaktiv' },
  ];
  school_id;
  userType;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public location: Location,
    public route: ActivatedRoute, // TODO: private or public?
    public router: Router, // TODO: private or public?
    private decimalPipe: DecimalPipe,
    private adminService: AdminService,
    private authService: AuthService,
    private schoolService: SchoolService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    // this.getSchoolAdmins();
    // this.getSchools();
    this.setTranslation(this.route.snapshot.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });

    this.userType = this.authService.getType();
    this.schoolId = this.route.snapshot.params['id'];
    this.getTeachers();
    console.log(this.schoolId);

    this.schoolService.getSchool(this.schoolId);
    this.schoolService
      .getSchoolUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.school = res;
      });

    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        this.paginator.pageIndex = 0;
        // Check for search results
        this.getTeachers();
      });

    this.filterFormGroup = new FormGroup({
      status: new FormControl(),
    });
  }

  setTranslation(translatedText: any) {
    this.translatedText = translatedText;
    this.statusArray = [
      {
        value: 'active',
        viewValue: this.translatedText?.school_detail_active,
      },
      {
        value: 'inactive',
        viewValue: this.translatedText?.school_detail_inactive,
      },
    ];
  }

  impersonateUser(userId) {
    if (this.userType == 'admin') {
      this.authService.impersonateUser(userId, '123');
    } else {
      this.openPinCode(userId);
    }
  }

  openPinCode(userId): void {
    console.log(userId);
    let dialogRef = this.dialog.open(PinCodeComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'pin-code-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'pin-code-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res?.data?.length > 0) {
          this.authService.impersonateUser(userId, res.data);
        }
      });
  }

  openSchoolEdit(): void {
    let dialogRef = this.dialog.open(SchoolEditComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        school_id: this.schoolId,
        school_name: this.school?.name,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  openSchoolLicenseDetail(): void {
    let dialogRef = this.dialog.open(SchoolLicenseDetailComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'school-license-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        school_id: this.schoolId,
        school_name: this.school?.name,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  openSchoolDeleteDialog(): void {
    let deleteDialog = this.dialog.open(SchoolDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'student-delete-overlay',
      data: {
        schoolId: this.school._id,
        schoolUuid: this.school.uuid,
      },
    });
    // dialogRef.disableClose = true;
    deleteDialog.afterClosed().subscribe((res) => {
      deleteDialog.close();
      console.log(res);
      // Refresh schools
      if (res == 'deleted') {
        this.router.navigate(['/dashboard-help-desk']);
      }
    });
    // dialogRef.disableClose = true;
    deleteDialog
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        deleteDialog.close();
        console.log(res);
        // Refresh schools
        if (res == 'deleted') {
          this.router.navigate(['/dashboard-help-desk']);
        }
      });
  }

  selectStatus(status) {
    this.filterValues.status = status.value;
    this.page = 1;
    this.limit = 10;
    this.matSortDirection = 'asc';
    this.isLoading = true;
    this.getTeachers();
  }
  getFullStateName(stateCode: string): string {
    return this.states[stateCode] || 'Unbekanntes Bundesland';
  }

  navigateBack(): void {
    this.location.back();
  }

  getFormattedLicenseAmount(amount: number) {
    return this.decimalPipe.transform(amount, '1.0-0');
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value == selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.school_detail_more_single
            : this.translatedText?.school_detail_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.school_detail_all;
      }
    }
    return returnText;
  }

  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  getTeachers() {
    this.filterValues.schoolId = this.schoolId;
    if (this.selectedIndex == 0) {
      this.filterValues.role = ['school_admin'];
    } else if (this.selectedIndex == 1) {
      this.filterValues.role = ['teacher'];
    }
    this.adminService
      .getTeacherList(
        this.page,
        10,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.dataSource = teacherData;
          console.log(this.dataSource);
        },
      });
  }

  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
    this.getTeachers();
  }

  tabChanged(event: any) {
    this.selectedIndex = event.index;
    this.getTeachers();
  }

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getTeachers();
  }

  resetFilter() {
    // this.status = "all"
    // this.filterFormGroup.get('type').setValue([]);
    // this.filterFormGroup.get('groups').setValue([]);
    // this.deadlineRange.reset();
    // this.filterValues = {
    //   search: '',
    //   type: [],
    //   deadline: {},
    //   groups: []
    // };
    // this.getSubTasks();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
