<!-- active -->
<div class="trial-banner-wrapper" *ngIf="trialStatus.isActive && source === 'trial' && !isTrialExpired">
  <div class="trial-banner-section">
    <mat-icon svgIcon="streamline_filled:trial_mode_icon"></mat-icon>
  </div>
  <button
    mat-mini-fab
    type="button"
    [mat-dialog-close]="'trial-welcome'"
    class="close-button close-button--right"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="trail-mode-wrap">
    <h3 class="h3 h3--bold">{{ translatedText.trial_banner_welcome }}</h3>
    <p class="paragraph paragraph--xl">
      {{ translatedText.trial_banner_active_msg }}
      <strong>{{ trialStatus.expiryDate | date : 'dd/MM/yyyy' }}.</strong>
    </p>
    <div class="trial-info">
      <div class="trial-info-item">
        <div>
          <mat-icon svgIcon="streamline_outlined:info_empty_gray"></mat-icon>
        </div>
        <p class="paragraph">
          {{ translatedText.trial_banner_limit_class }}
          <span class="paragraph--bold">{{ translatedText.trial_banner_number_of_class }}</span>
        </p>
      </div>
      <div class="trial-info-item">
        <div>
          <mat-icon svgIcon="streamline_outlined:info_empty_gray"></mat-icon>
        </div>
        <p class="paragraph">
          {{ translatedText.trial_banner_limit_books }}
          <span class="paragraph--bold">{{ translatedText.trial_banner_number_of_books }}</span>
        </p>
      </div>
      <div class="trial-info-item">
        <div><mat-icon svgIcon="streamline_outlined:info_empty_gray"></mat-icon></div>
        <p class="paragraph">
          {{ translatedText.trial_banner_limit_number_of_tasks_text }}
          <span class="paragraph--bold">{{ translatedText.trial_banner_limit_number_of_tasks }}</span>
          {{ translatedText.trial_banner_limit_reading_mins }}
          <span class="paragraph--bold">{{ translatedText.trial_banner_limit_number_of_mins }}</span>
        </p>
      </div>
    </div>
    <div class="button_wrap">
      <button mat-flat-button color="primary" type="button" [mat-dialog-close]="'trial-welcome'">
        {{ translatedText.trial_banner_start_use }}
      </button>
      <button
        *ngIf="!environment.whitelabel.hide_klett_shop"
        mat-flat-button
        color="secondary"
        type="button"
        (click)="buyKlett()"
      >
        {{ translatedText.trial_banner_expired_buy_license }}
      </button>
    </div>
  </div>
</div>
<!-- expired -->
<div class="trial-banner-wrapper" *ngIf="isTrialExpired">
  <div class="trial-banner-section">
    <mat-icon svgIcon="streamline_filled:trial_banner_expired"></mat-icon>
  </div>
  <button mat-mini-fab type="button" [mat-dialog-close]="true" class="close-button close-button--right" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div class="trail-mode-wrap">
    <h3 class="h3 h3--bold">{{ translatedText.trial_banner_expired_msg }}</h3>
    <p class="paragraph paragraph--xl">
      {{ translatedText.trial_banner_expired_info }}
    </p>
    <div class="trial-info">
      <div class="trial-info-item">
        <mat-icon svgIcon="streamline_outlined:trial_banner_prohibition" class="prohibition-icon"></mat-icon>
        <p class="paragraph">
          {{ translatedText.trial_banner_expired_class_info }}
        </p>
      </div>
      <div class="trial-info-item">
        <mat-icon svgIcon="streamline_outlined:trial_banner_prohibition" class="prohibition-icon"></mat-icon>
        <p class="paragraph">
          {{ translatedText.trial_banner_expired_student_login_info }}
        </p>
      </div>
    </div>
    <div class="button_wrap">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="true">
        {{ translatedText.trial_banner_expired_continue_limited_features }}
      </button>
      <button
        *ngIf="!environment.whitelabel.hide_klett_shop"
        mat-flat-button
        color="primary"
        type="button"
        (click)="buyKlett()"
      >
        {{ translatedText.trial_banner_expired_buy_license }}
      </button>
      <!--  <p>
        <a href="" class="delete_account_text">{{ translatedText.trial_banner_delete_account }}</a>
      </p> -->
    </div>
  </div>
</div>

<div class="trial-banner-wrapper" *ngIf="trialStatus.isActive && source === 'upgrade' && !isTrialExpired">
  <div class="trial-banner-section">
    <mat-icon svgIcon="streamline_filled:upgrade-license"></mat-icon>
  </div>
  <button mat-mini-fab type="button" [mat-dialog-close]="true" class="close-button close-button--right" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div class="trail-mode-wrap">
    <h3 class="h3 h3--bold">{{ translatedText.trial_banner_upgrade_title }}</h3>
    <p class="paragraph mt-3" [innerHTML]="translatedText.trial_banner_upgrade_text"></p>
    <div class="button_wrap_row">
      <button mat-flat-button color="secondary" type="button" (click)="closeDialog()">
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <button
        *ngIf="!environment.whitelabel.hide_klett_shop"
        mat-flat-button
        color="primary"
        type="button"
        (click)="buyKlett()"
      >
        {{ translatedText.license_trial_shop }}
      </button>
    </div>
  </div>
</div>
