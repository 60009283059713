<div class="container-fluid container-fluid--bg-gray container-fluid--full-height">
  <div class="row justify-content-center">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div class="student_tabs" [@.disabled]="true">
        <ng-container
          *ngIf="
            activetasks.length === 0 &&
            expiredtasks.length === 0 &&
            finishedtasks.length === 0 &&
            diagnosticTasks.length === 0
          "
        >
          <div class="zero_tasks">
            <img src="../../../assets/red-check-mark.png" alt="" />
            <p class="paragraph paragraph--l">{{ translatedText.dashboard_student_no_new_tasks }}</p>
          </div>
        </ng-container>
        <mat-tab-group
          #tasksTabs
          (selectedTabChange)="tabChanged($event)"
          *ngIf="
            activetasks.length > 0 || expiredtasks.length > 0 || finishedtasks.length > 0 || diagnosticTasks.length > 0
          "
          class="rounded_tabs rounded_tabs--student"
        >
          <mat-tab label="{{ translatedText.dashboard_student_active_tasks }}  {{ activeTabCount }}">
            <ng-container *ngIf="activetasks.length === 0 && diagnosticTasks.length === 0">
              <div class="zero_tasks_tab">
                <img src="../../../assets/red-check-mark.png" alt="" />
                <p class="paragraph paragraph--l">{{ translatedText.dashboard_student_no_new_tasks }}</p>
              </div>
            </ng-container>

            <ng-container *ngIf="activetasks.length > 0 || diagnosticTasks.length > 0">
              <app-task-diagnostic [tasks]="tasks" [diagnosticPhases]="diagnosticPhases"></app-task-diagnostic>
              <app-student-task-list [activeTab]="activeTab" [tasks]="tasks"></app-student-task-list>
            </ng-container>
          </mat-tab>
          <mat-tab label="{{ translatedText.dashboard_student_finished_tasks }} {{ finishedtasks.length }}">
            <ng-container *ngIf="finishedtasks.length === 0">
              <div class="zero_tasks_tab">
                <img src="../../../assets/red-check-mark.png" alt="" />
                <p class="paragraph paragraph--l">{{ translatedText.dashboard_student_no_finished_tasks }}</p>
              </div>
            </ng-container>

            <app-student-task-list
              [activeTab]="activeTab"
              [tasks]="tasks"
              [notifications]="notifications"
            ></app-student-task-list>
          </mat-tab>
          <mat-tab label="{{ translatedText?.dashboard_student_expired_tasks }} {{ expiredtasks.length }}">
            <ng-container *ngIf="expiredtasks.length === 0">
              <div class="zero_tasks_tab">
                <img src="../../../assets/red-check-mark.png" alt="" />
                <p class="paragraph paragraph--l">{{ translatedText.dashboard_student_no_expired_tasks }}</p>
              </div>
            </ng-container>

            <app-student-task-list
              [activeTab]="activeTab"
              [tasks]="tasks"
              [notifications]="notifications"
            ></app-student-task-list>
          </mat-tab>
          <!-- <mat-tab label="Freiwillig gelesen">
                        <app-student-task-list [activeTab]="activeTab" [tasks]="tasks"></app-student-task-list>
                    </mat-tab> -->
        </mat-tab-group>
      </div>
      <!-- <div class="student_self" [hidden]="activeTab != 'isActive'" *ngIf="selfReadingActive">
                <div class="row">
                    <div class="col-md-6">
                        <div class="student_task">
                            <div class="student_task_inner">
                                <div class="view_button">
                                    <button mat-flat-button color="gradient" type="button" (click)="startSelfReading()">Freiwillig lesen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
    <!-- <div class="col-md-12 col-sm-12 col-lg-4">
            <div class="student_sidebar">
                <div class="inline_text">
                    <p class="paragraph paragraph--bold paragraph--l">Meine Ergebnisse</p>
                
                </div>

                <div class="row dashboard_result_row">
                    <div  class="col-md-6 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--performed-tasks">
                            <p class="result_number" [countUp]="tasksPerformed">{{tasksPerformed}}</p>
                            <p class="paragraph paragraph--l">Durchgeführte 
                                Aufgaben</p>
                       </div>
                    </div>
                    <div  class="col-md-6 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--reading-words">
                            <p class="result_number words_read" [countUp]="wordsRead">{{wordsRead}}</p>
                            <p class="paragraph paragraph--l">Gelesene Wörter</p>
                       </div>
                    </div>
                    <div  class="col-md-6 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--reading-points">
                            <p class="result_number reading_points" [countUp]="readingPoints">{{readingPoints}}</p>
                            <p class="paragraph paragraph--l">Lesepunkte</p>
                       </div>
                    </div>
                    <div  class="col-md-6 col-sm-12 dashboard_result">
                        <div class="dashboard_result_student dashboard_result_student--medals">
                            <div class="set_inline">
                                <p class="result_number" [countUp]="medals?.bronze + medals?.silver + medals?.gold">{{medals?.bronze + medals?.silver + medals?.gold}}</p>
                              
                                <div class="medals-wrap">
                                    <div class="bronze-medal">
                                        <div class="bronze-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.bronze">{{medals?.bronze}}</p>
                                        </div>
                                    </div>
                                    <div class="silver-medal">
                                        <div class="silver-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.silver">{{medals?.silver}}</p>
                                        </div>
                                    </div>
                                    <div class="gold-medal">
                                        <div class="gold-medal-border">
                                            <p class="paragraph paragraph--bold paragraph--s" [countUp]="medals?.gold">{{medals?.gold}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="paragraph paragraph--l">Meine Medaillen</p>
                       </div>
                    </div>
                </div>
                <div class="row student_awards_wrap">
                    <div class="col-md-12 col-sm-12">
                        <div class="inline_text student_awards_header ">
                            <p class="paragraph paragraph--bold paragraph--l">Meine Auszeichnungen</p>
                           
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-4 col-sm-12 student_awards" *ngFor="let award of awards">
                        <ng-container *ngIf="award?.earned === true">
                            <img class="award_earned" src="{{award?.imageUrl}}" alt="">
                        </ng-container>
                        <ng-container *ngIf="award?.earned === false">
                            <img src="{{award?.imageUrl}}" alt="">
                        </ng-container>
                     
                        <p class="caption">{{award?.description}}</p>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
  <div class="floating_help">
    <ng-container>
      <button mat-flat-button color="help" type="button" (click)="openExternalUrl(support_url)">
        <!-- [matMenuTriggerFor]="help" -->
        <mat-icon svgIcon="streamline_outlined:tips"></mat-icon>{{ translatedText.dashboard_student_help }}
      </button>
      <!-- <mat-menu #help="matMenu" yPosition="above">
        <button
          mat-menu-item
          (click)="showVideo('2_Lernende-Dashboard.mp4', translatedText.dashboard_student_dashboard)"
        >
          {{ translatedText.dashboard_student_dashboard }}
        </button>
        <button mat-menu-item (click)="showVideo('3_Lernende-Menuepunkte.mp4', translatedText.dashboard_student_menu)">
          {{ translatedText.dashboard_student_menu }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('4_Lernende-Aufgabenarten.mp4', translatedText.dashboard_student_tasks_type)"
        >
          {{ translatedText.dashboard_student_tasks_type }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('5_Lernende-Leseaufgabe-durchfuehren.mp4', translatedText.dashboard_student_tasks_action)"
        >
          {{ translatedText.dashboard_student_tasks_action }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('6_Lernende-waehrend-lesen.mp4', translatedText.dashboard_student_read)"
        >
          {{ translatedText.dashboard_student_read }}
        </button>
        <button mat-menu-item (click)="openExternalUrl(support_url)">
          {{ translatedText.dashboard_student_helpdesk }}
        </button>
      </mat-menu> -->
    </ng-container>
  </div>
</div>
