<div class="unassigned-container">
  <!-- <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button> -->
  <ng-container *ngIf="!studentAssigned">
    <mat-icon svgIcon="streamline_outlined:add-user-large" class="icon-large"></mat-icon>
    <h3 class="h3 h3--bold">{{ translatedText.admin_group_list_unassigned_overlay_title }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.admin_group_list_unassigned_overlay_text }}</p>
    <div class="middle-section">
      <div class="username-and-avatar">
        <img src="{{ evolutionApiUrl + '/files/images/avatar/' + student.avatar + '.png' }}" alt="student avatar" />
        <h3 class="h3 h3--bold">{{ student.username }}</h3>
      </div>
      <div class="input-field">
        <mat-label>{{ translatedText.admin_teacher_list_group }}</mat-label>
        <mat-form-field
          ><mat-icon matSuffix>expand_more</mat-icon>
          <mat-select [formControl]="groupSelect" required (selectionChange)="groupSelection($event)">
            <mat-option *ngFor="let group of groups" [value]="group"> {{ group.name }} - {{ formatYear(group.school_year) }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="button-wrap">
      <button mat-flat-button type="button" color="secondary" mat-dialog-close>
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <button
        mat-flat-button
        type="button"
        color="primary"
        [disabled]="groupSelect.value === ''"
        (click)="studentAssigned = true"
      >
        {{ translatedText.admin_group_list_unassigned_to_class }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="studentAssigned">
    <div class="successfully-assigned">
      <mat-icon svgIcon="streamline_outlined:check_circle" class="icon-large"></mat-icon>
      <h3 class="h3 h3--bold title">{{ translatedText.admin_group_list_unassigned_successfully_added }}</h3>
      <div class="middle-section">
        <div class="username-and-avatar">
          <img
            src="{{ evolutionApiUrl + '/files/images/avatar/' + student.avatar + '.png' }}"
            alt="student avatar"
            class="icon-80"
          />
          <h3 class="h3 h3--bold">{{ student.username }}</h3>
        </div>
        <mat-icon svgIcon="streamline_outlined:arrow_green" class="green-arrow"></mat-icon>
        <div class="username-and-avatar">
          <mat-icon svgIcon="streamline_filled:class-hat-icon" class="icon-80"></mat-icon>
          <h3 class="h3 h3--bold">{{ selectedGroup.text }}</h3>
        </div>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="addStudentToCurrentClass(selectedGroup.value)"
        mat-dialog-close
      >
        OK
      </button>
    </div>
  </ng-container>
</div>
