import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-non-school-register-stepper',
  templateUrl: './non-school-register-stepper.component.html',
  styleUrls: ['./non-school-register-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: NonSchoolRegisterStepperComponent },
  ],
})
export class NonSchoolRegisterStepperComponent
  extends CdkStepper
  implements OnChanges
{
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Input('stepValue') stepValue: number;
  @Input('translatedText') translatedText: any;
  isBackButton: boolean = true;
  onClick(index: number): void {
    console.log(index);
    this.steps;
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    if (this.selectedIndex > 0) {
      this.isBackButton = false;
    } else {
      this.isBackButton = true;
    }
  }

  nextStep(index: number): void {
    console.log(index);
    this.informParentSelectedStep.emit(index);
  }

  // moveBack() {
  //   console.log(this.selectedIndex )
  //   if (this.selectedIndex == 1) {
  //     this.isBackButton = true;
  //     this.selectedIndex = 0;
  //     console.log(this.selectedIndex)
  //   }
  //   if (this.selectedIndex == 2) {
  //     this.selectedIndex = 1;
  //     this.isBackButton = true;
  //     console.log(this.selectedIndex)
  //   }
  //   if (this.selectedIndex == 3) {
  //     this.selectedIndex = 2;
  //     console.log(this.selectedIndex)
  //   }
  //   if (this.selectedIndex == 4) {
  //     this.selectedIndex = this.selectedIndex - 1;
  //   } else {
  //     this.selectedIndex = this.selectedIndex - 1;
  //   }

  // }

  moveBack() {
    if (this.selectedIndex == 1) {
      console.log('---------1-----------');
      this.selectedIndex = this.selectedIndex - 1;
      console.log(this.selectedIndex);
      this.isBackButton = true;
    }
    if (this.selectedIndex == 2) {
      console.log('---------2-----------');
      console.log(this.selectedIndex);
      this.selectedIndex = this.selectedIndex - 1;
      console.log(this.selectedIndex);
      this.isBackButton = true;
    }
    if (this.selectedIndex == 3) {
      console.log('---------3-----------');
      console.log(this.selectedIndex);
      this.selectedIndex = this.selectedIndex - 1;
      console.log(this.selectedIndex);
      this.isBackButton = true;
    }
    if (this.selectedIndex == 4) {
      console.log('---------4-----------');
      console.log(this.selectedIndex);
      this.selectedIndex = this.selectedIndex - 1;
      console.log(this.selectedIndex);
      this.isBackButton = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.stepValue);
    if (changes.stepValue != undefined) {
      if (changes.stepValue.currentValue == 5) {
        this.isBackButton = false;
      }
      if (
        changes.stepValue.currentValue > 0 &&
        changes.stepValue.currentValue < 5
      ) {
        this.isBackButton = false;
      } else {
        this.isBackButton = true;
      }
    }
  }
}
