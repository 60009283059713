import { Component, OnInit, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormGroupDirective,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { TaskService } from '../../providers/task.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {}

@Component({
  selector: 'app-task-repeat',
  templateUrl: './task-repeat.component.html',
  styleUrls: ['./task-repeat.component.scss'],
})
export class TaskRepeatComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  rForm: FormGroup;
  masterTaskId;
  teacherId;
  todayDate: Date = new Date();
  translatedText;
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    public taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['id'] !== 'undefined') {
        this.masterTaskId = this.injectedData['id'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
    this.rForm = new FormGroup({
      deadline: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  submitForm(formDirective: FormGroupDirective) {
    this.taskService
      .duplicateMasterTask({
        masterTaskId: this.masterTaskId,
        deadline: this.rForm.value.deadline,
        teacherId: this.teacherId,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.dialog.closeAll();
          let message = this.translatedText?.task_repeat_success;
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          // update tasks
          this.taskService.getMasterTasksFromTeacher();
        },
        (error: any) => {
          console.log(error);
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
