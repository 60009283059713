import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-timemode-overlay',
  templateUrl: './timemode-overlay.component.html',
  styleUrls: ['./timemode-overlay.component.scss'],
})
export class TimemodeOverlayComponent implements OnInit, OnDestroy {
  masterTaskId: string;
  groupName: string;
  translatedText: any;
  timeOver: boolean = false;
  readingTime: number;
  currentLanguage: string;

  @Input() url: string;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public resDialog: MatDialogRef<TimemodeOverlayComponent>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.url = data.url;
    if (injectedData) {
      if (typeof injectedData['timeOver'] !== 'undefined') {
        this.timeOver = this.injectedData['timeOver'];
      }
      console.log('reading time', this.timeOver);
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.currentLanguage = this.translationService.getCurrentLanguage();
  }

  dontShowAgain() {
    if (this.timeOver) {
      localStorage.setItem('dialogEnd', 'dontShow');
    } else {
      localStorage.setItem('dialogStart', 'dontShow');
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
