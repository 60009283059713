import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit {
  @Input('value') value: any;
  statusValue = 0;
  color = '#ff0000';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.previousValue != changes.value.currentValue) {
      setTimeout(() => {
        this.statusValue = this.value - 2;
        console.log(this.value);
        if (this.value > 15 && this.value < 29) {
          this.color = '#fecf2f';
        } else if (this.value >= 30 && this.value < 69) {
          this.color = '#03cc90';
        } else if (this.value > 69 && this.value < 84) {
          this.color = '#fecf2f';
        } else if (this.value > 84) {
          this.color = '#ff0000';
        }
      }, 500);
    }
  }
}
