<div class="timemode-container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="timemode-content">
    <h3 class="h3 h3--bold" *ngIf="!timeOver">{{ translatedText.timemode_overlay_first_select_title }}</h3>
    <h3 class="h3 h3--bold" *ngIf="timeOver">{{ translatedText.timemode_overlay_last_select_title }}</h3>
    <div class="white-block">
      <video autoplay loop>
        <source
          *ngIf="!timeOver && currentLanguage.startsWith('de')"
          src="../../../assets/start-point-DE.mp4"
          type="video/mp4"
        />
        <source
          *ngIf="!timeOver && currentLanguage === 'en'"
          src="../../../assets/start-point-EN.mp4"
          type="video/mp4"
        />
        <source
          *ngIf="timeOver && currentLanguage.startsWith('de')"
          src="../../../assets/end-point-DE.mp4"
          type="video/mp4"
        />
        <source
          *ngIf="timeOver && currentLanguage === 'en'"
          src="../../../assets/end-point-EN.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    <button mat-flat-button [mat-dialog-close]="true" color="primary-student">OK</button>
    <mat-checkbox class="timemode-overlay-checkbox" (click)="dontShowAgain()">
      {{ translatedText.timemode_overlay_first_select_checkbox_text }}
    </mat-checkbox>
  </div>
</div>
