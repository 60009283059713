import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { ForgotPasswordStepperComponent } from './forgot-password-stepper/forgot-password-stepper.component';
import { ForgotPasswordComponent } from './forgot-password.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ForgotPasswordComponent, ForgotPasswordStepperComponent],
  exports: [ForgotPasswordComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ForgotPasswordModule {}
