import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../../app-routing.module';
import { MaterialModule } from '../../../material.module';
import { AuthInterceptor } from '../../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../../error/error-interceptor';
import { AdminGroupManagingComponent } from './admin-group-managing.component';
import { AdminGroupManagingStepperComponent } from './admin-group-managing-stepper/admin-group-managing-stepper.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LoadingSpinnerModule } from '../../../helpers/loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [
    AdminGroupManagingComponent,
    AdminGroupManagingStepperComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [AdminGroupManagingComponent],
})
export class AdminGroupManagingModule {}
