<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="header-wrapper">
      <button
        mat-flat-button
        class="close-button-with-text"
        type="button"
        matSuffix
        (click)="openCancelDialog()"
        [disabled]="blobUrl"
        [ngClass]="{ 'hidden-btn': blobUrl }"
      >
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.students_add_class_title_chip }}</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button
          mat-flat-button
          color="unset"
          (click)="backBtn()"
          [disabled]="currentStep === 1 || isLoading || blobUrl"
          [ngClass]="{ 'hidden-btn': isLoading || blobUrl }"
        >
          {{ translatedText.students_add_back }}
        </button>
        <button
          mat-flat-button
          color="primary"
          class="submit-button"
          (click)="nextBtn()"
          [disabled]="isFormInvalid"
          *ngIf="currentStep !== 4"
        >
          {{ translatedText.students_add_next }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [mat-dialog-close]="true"
          (click)="closeButton.emit(true)"
          *ngIf="currentStep === 4"
          [disabled]="blobUrl === null"
          class="wider-button"
        >
          {{ translatedText.admin_group_close_btn }}
        </button>
      </div>
    </div>
    <div class="training-task_container">
      <div class="step_content">
        <div class="training-task_wrap" [style.display]="selected ? 'block' : 'none'">
          <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
