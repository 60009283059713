import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readingTimeFormat',
})
export class ReadingTimeFormatPipe implements PipeTransform {
  transform(val: number): number {
    return Math.abs(val);
  }
}
