<div class="container-fluid">
  <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="changed-password_wrap">
    <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
    <h3 class="h3 h3--bold">{{ translatedText.password_changed_overlay_tilte }}</h3>
    <button mat-flat-button color="primary" [mat-dialog-close]="'close'">
      {{ translatedText.password_changed_overlay_ok }}
    </button>
  </div>
</div>
