import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { TaskService } from '../../providers/task.service';
import { StudentService } from '../../providers/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NotificationOverlayComponent } from '../../helpers/notification-overlay/notification-overlay.component';
import { DiagnosticRepeatComponent } from 'src/app/diagnostic/diagnostic-repeat/diagnostic-repeat.component';
import { MatSort } from '@angular/material/sort';
import { TranslationService } from 'src/app/providers/translation.service';
import { AdminService } from 'src/app/providers/admin.service';
export interface Tasks {}
export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: Group[];
}
export interface Group {
  name?: string;
  grade?: string;
  stream?: string;
  school_year?: string;
}

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  displayedColumns: string[] = [
    'username',
    'type',
    'deadline',
    'description',
    'book_name',
    'button',
  ];
  finishedColumns: string[] = [
    'username',
    'type',
    'completed_at',
    'description',
    'book_name',
    'reading_speed_correct_words_per_minute',
    'button',
  ];
  finishedNestedColumns: string[] = [
    'placeholder',
    'username',
    'type',
    'description',
    /*'book_name',*/ 'completed_at',
    'reading_points',
    'button',
  ];
  nestedColumns: string[] = [
    'placeholder',
    'username',
    'type',
    'deadline',
    'description',
    'book_name',
    'button',
  ];
  dataSource: any;
  @Input('activeTab') activeTab: string;
  @Input('tasks') tasks: any;
  @Input('notifications') notifications: any;
  isActive: boolean = false;
  isFinished: boolean = false;
  isExpired: boolean = false;
  isselfing: boolean = false;
  finishedTasks = [];
  activeTasks = [];
  expiredTasks = [];
  id;
  student: Student;
  studentId;
  @Input('selectedView') selectedView: any;
  isCardView: boolean = true;
  isTableView: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  translatedText;
  constructor(
    private authService: AuthService,
    private taskService: TaskService,
    private studentService: StudentService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    private translationService: TranslationService,
    private adminService: AdminService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.id = this.route.snapshot.params['id'];
    this.studentService
      .getStudentbyId(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.student = res;
        this.studentId = res._id;
      });
    // this.taskService.getTasksToStudents(this.id).subscribe(res => {
    //   //get tasks array with status
    //   this.activeTasks = res.filter((item) =>{
    //       return item["status"] == "active"
    //   } );
    //   this.finishedTasks = res.filter((item) =>{
    //       return item["status"] == "finished";
    //   } );
    //   this.expiredTasks = res.filter((item) =>{
    //       return item["status"] == "expired";
    //   } );
    //   console.log(this.activeTasks)
    // });
  }

  getTasksByStatus(status) {
    let tasks = this.tasks.filter((item) => {
      return item['status'] == status && item['type'] != 'self';
    });

    switch (status) {
      case 'finished':
        if (tasks.length > 0) {
          const updatedTask = tasks.map((taskItem) => {
            const matchingNotification = this.notifications?.find(
              (notification) => notification._task === taskItem._id,
            );
            if (matchingNotification) {
              taskItem['notification'] = matchingNotification;
            }
            return taskItem;
          });
          updatedTask.sort((a, b) => {
            if (a?.completed_at > b?.completed_at) {
              return -1;
            }
          });
        }
        break;
      case 'active':
        if (tasks.length > 0) {
          tasks.sort((a, b) => {
            if (a?.deadline < b?.deadline) {
              return -1;
            }
          });
        }
        break;
      case 'expired':
        if (tasks.length > 0) {
          tasks.sort((a, b) => {
            if (a?.deadline > b?.deadline) {
              return -1;
            }
          });
        }
        break;
      default:
        break;
    }
    return tasks;
  }

  getselfingTasks() {
    let tasks = this.tasks.filter((item) => {
      return item['status'] == 'finished' && item['type'] == 'self';
    });
    return tasks.sort((a, b) => {
      if (a?.completed_at > b?.completed_at) {
        return -1;
      }
    });
  }

  onSortChange(event: MatSort) {
    console.log(event);
    this.dataSource.sort = this.sort;
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'diagnostic-pre':
        title = 'Diagnose 1';
        break;
      case 'diagnostic-mid':
        title = 'Diagnose 2';
        break;
      case 'diagnostic-post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab?.currentValue == 'isActive') {
      this.isActive = true;
      this.isExpired = false;
      this.isFinished = false;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isFinished') {
      this.isActive = false;
      this.isExpired = false;
      this.isFinished = true;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isExpired') {
      this.isActive = false;
      this.isExpired = true;
      this.isFinished = false;
      this.isselfing = false;
    } else if (changes.activeTab?.currentValue == 'isselfing') {
      this.isActive = false;
      this.isExpired = false;
      this.isFinished = false;
      this.isselfing = true;
    }

    if (changes.selectedView?.currentValue == 'card') {
      this.isCardView = true;
      this.isTableView = false;
    } else if (changes.selectedView?.currentValue == 'table') {
      this.isCardView = false;
      this.isTableView = true;
    }
  }

  generateTaskTitle(type: string, mode: string) {
    let title = '';

    switch (type) {
      case 'diagnostic-pre':
        title = '1 Diagnose';
        break;
      case 'diagnostic-mid':
        title = '2 Diagnose';
        break;
      case 'diagnostic-post':
        title = '3 Diagnose';
        break;
      case 'self':
        title = 'Freiwillig';
        break;
      default:
        title = this.translationService.getTranslatedText('single_mode');
    }
    switch (mode) {
      case 'autonomous':
        title = this.translationService.getTranslatedText('auto_mode');
        break;
      case 'multiple':
        title = this.translationService.getTranslatedText('multiple_mode');
        break;
      default:
        break;
    }
    return title;
  }

  getTableData(status) {
    this.dataSource = new MatTableDataSource<Tasks>(
      this.tasks.filter((item) => {
        return item['status'] == status;
      }),
    );

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'reading_speed_correct_words_per_minute':
          return item?.reading_score?.reading_speed_correct_words_per_minute;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.data.sort((a, b) => {
      const titleA = this.generateTaskTitle(a.type, a.mode);
      const titleB = this.generateTaskTitle(b.type, b.mode);
      return titleA.localeCompare(titleB);
    });
    return this.dataSource;
  }

  generateTaskTypeTitle(type, mode) {
    return this.taskService.generateTaskTypeTitle(type, mode);
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  openNotificationOverlay(taskId, notification, task?): void {
    console.log(task);
    let dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        taskId: taskId,
        recipient: this.student['_user'],
        student: this.student,
        notification: notification,
        task: task,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  repeatDiagnostic(masterTaskId, type) {
    let taskType;
    let diagnosticType;
    if (
      type == 'diagnostic-pre' ||
      type == 'diagnostic-mid' ||
      type == 'diagnostic-post'
    ) {
      taskType = 'diagnostic';
      diagnosticType = type;
    } else {
      taskType = 'training';
    }
    let dialogRef = this.dialog.open(DiagnosticRepeatComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        taskId: masterTaskId,
        diagnosticType: diagnosticType,
        studentId: this.id,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.taskService.getTasksToStudents(this.id);
      });
  }

  openStudentReport(taskId): void {
    this.router.navigate(['/student-report/' + taskId], {
      queryParams: { studentId: this.id },
    });
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  showAlert() {
    alert('Under Construction');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
