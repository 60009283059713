import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-forgot-password-stepper',
  templateUrl: './forgot-password-stepper.component.html',
  styleUrls: ['./forgot-password-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: ForgotPasswordStepperComponent },
  ],
})
export class ForgotPasswordStepperComponent extends CdkStepper {
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();

  onClick(index: number): void {
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
  }

  navigateBack(): void {
    //this.location.back()
  }
}
