<app-admin-add-existing-students-stepper
  #stepper
  linear
  [bookSelected]="bookSelected"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="thirdFormStep">
    <div class="training-task_select_container full-height">
      <form [formGroup]="thirdFormStep" #formDirective="ngForm" class="third_step_accorddion">
        <div class="training-task_create_headline">
          <div class="group_headline">
            <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
            <p class="paragraph paragraph--bold paragraph--xl">{{ groupName }}</p>
          </div>
          <h3 class="h3 h3--bold">{{ translatedText.admin_add_existing_students_select_student }}</h3>
        </div>
        <div
          *ngIf="groups?.length == 0 || (groups.length > 0 && filterGroupsWithoutPersonalData(groups)?.length == 0)"
          class="empty_tasks_container"
        >
          <img src="../../../assets/empty.svg" alt="" />
          <p class="paragraph">
            <span *ngIf="userType == 'school_admin'">{{ translatedText.admin_add_existing_students_no_group }}</span>
            <span *ngIf="userType == 'teacher'">{{
              translatedText.admin_add_existing_students_check_information
            }}</span>
          </p>
        </div>
        <div class="task_overview-chip-wrap">
          <div *ngFor="let student of studentsArray; let i = index" class="task_overview-chip with-shadow">
            <div class="user-img">
              <img *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
              <p class="paragraph paragraph--s paragraph--bold">
                {{ student?.username }}
                <span>({{ student?.groupName }} - {{ student?.groupSchoolYear | slice: 2 }})</span>
              </p>
            </div>
            <mat-icon svgIcon="streamline_outlined:close" (click)="removeFromStudentsArray(i, student?._id)"></mat-icon>
          </div>
        </div>
        <div class="accordion_wrap">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let group of filterGroupsWithoutPersonalData(groups)">
              <mat-expansion-panel-header>
                <mat-panel-title class="round-checkbox">
                  <mat-checkbox
                    (change)="toggleSelectAll(students, $event.checked)"
                    [checked]="students.selectedOptions.selected.length > 0"
                    (click)="$event.stopPropagation()"
                    [disabled]="disableSelectAll(group?._students)"
                  ></mat-checkbox>
                  <p class="paragraph paragraph--bold paragraph--xl">
                    {{ group?.name }} - {{ group?.school_year | slice: 2 }}
                  </p>
                </mat-panel-title>
                <mat-panel-description>
                  <p class="paragraph">
                    {{ translatedText.admin_add_existing_students_selected }}
                    <span class="paragraph paragraph--bold"
                      >{{ students.selectedOptions.selected.length }}/{{ group?._students?.length }}
                    </span>
                  </p>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list
                #students
                (selectionChange)="handleSelection($event, thirdFormStep.value)"
                [name]="group._students"
                formControlName="students"
              >
                <mat-list-option
                  checkboxPosition="before"
                  *ngFor="let student of group._students; index as i"
                  [value]="student"
                  [selected]="checkForSelection(student, i)"
                  [disabled]="isOptionDisabled(group, students.selectedOptions.selected, student)"
                  class="round-checkbox small"
                >
                  <div class="accordion_list_text">
                    <div class="inline_text">
                      <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
                      <p class="paragraph username-with-avatar">
                        {{ student?.username }}
                      </p>
                    </div>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- {{students}} -->
      </form>
    </div>
    <div class="training-task_select_button">
      <button mat-flat-button color="secondary" (click)="closeAll(true)" [disabled]="isLoading">
        {{ translatedText.admin_add_existing_students_back }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="submitForm(formDirective)"
        [disabled]="isLoading || studentsArray.length == 0"
      >
        <span *ngIf="!isLoading">{{ translatedText.admin_add_existing_students_next }}</span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="lastFormStep" #step3="cdkStep">
    <div class="loading-step" *ngIf="isLoading">
      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      <h3 class="h3 h3--bold">{{ translatedText.admin_add_existing_students_pdf_creation }}</h3>
      <p class="paragraph">
        <strong>{{ translatedText.admin_add_existing_students_attention }} </strong
        >{{ translatedText.admin_add_existing_students_attention_text }}
      </p>
    </div>
    <div class="finished-step edit-group" *ngIf="!isLoading">
      <div class="pdf-info">
        <img class="last-step-img-key" src="../../../../assets/key.svg" alt="" />
        <h3 class="h3 h3--bold">{{ translatedText.admin_add_existing_students_pdf_created }}</h3>
        <p class="paragraph paragraph--xl">{{ translatedText.admin_add_existing_students_pdf_download_text }}</p>
        <button
          mat-flat-button
          color="primary"
          type="button"
          class="pdf_overlay_button"
          (click)="downloadPDF()"
          [hidden]="blobUrl == ''"
        >
          <mat-icon svgIcon="streamline_outlined:download"></mat-icon
          >{{ translatedText.admin_add_existing_students_pdf_download }}
        </button>
      </div>
    </div>
  </cdk-step>
</app-admin-add-existing-students-stepper>
