import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { ParentsFormComponent } from '../parents-form/parents-form.component';
import { ParentsLandingPageComponent } from './parents-landing-page.component';

@NgModule({
  declarations: [ParentsLandingPageComponent, ParentsFormComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [ParentsLandingPageComponent],
})
export class ParentsLandingPageModule {}
