import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from '../../providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-beta-info',
  templateUrl: './beta-info.component.html',
  styleUrls: ['./beta-info.component.scss'],
})
export class BetaInfoComponent implements OnInit, OnDestroy {
  betaLeaveInfo: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public betoInfoOverlay: MatDialogRef<BetaInfoComponent>,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['betaLeaveInfo'] !== 'undefined') {
        this.betaLeaveInfo = this.injectedData['betaLeaveInfo'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closeOverlay() {
    this.betoInfoOverlay.close('seen');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
