import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';
import { TaskStartOverlayComponent } from '../../helpers/task-start-overlay/task-start-overlay.component';
import { BookService } from '../../providers/book.service';
import { TaskService } from '../../providers/task.service';

// TODO: unused?
@Component({
  selector: 'app-book-preview-student',
  templateUrl: './book-preview-student.component.html',
  styleUrls: ['./book-preview-student.component.scss'],
})
export class BookPreviewStudentComponent implements OnInit, OnDestroy {
  task;
  userId;
  taskId;
  book;
  isbn;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private bookService: BookService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userId = this.authService.getUserId();
    this.taskId = this.route.snapshot.params['id'];
    this.isbn = this.route.snapshot.queryParams.isbn;
    this.bookService.getBookById(this.isbn);
    this.bookService
      .getBookUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.book = data;
        console.log(this.book);
      });
  }

  startTaskOverlay(task) {
    let dialogRef = this.dialog.open(TaskStartOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-start-overlay-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-start-overlay-task-panel-backdrop',
      data: {
        task: task,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  navigateToBookList() {
    this.router.navigate(['/book-list-student/' + this.taskId]);
  }

  viewBook() {
    this.router.navigate(['/book-view-student/' + this.taskId], {
      queryParams: { isbn: this.isbn },
    });
  }

  updateTask() {
    this.taskService
      .updateSingleTaskBook({
        _id: this.taskId,
        book_name: this.book?.title,
        book_isbn: this.book?.isbn,
      })
      .subscribe((res) => {
        this.router.navigate(['/task-preview/' + this.taskId]);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
