import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { ProgressBarModule } from 'src/app/helpers/progress-bar/progress-bar.module';
import { VideoOverlayModule } from 'src/app/helpers/video-overlay/video-overlay.module';
import { MaterialModule } from 'src/app/material.module';
import { TeacherSidebarComponent } from './teacher-sidebar.component';
import { HintBoxModule } from 'src/app/helpers/hint-box/hint-box.module';

@NgModule({
  declarations: [TeacherSidebarComponent],
  imports: [
    LayoutModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    VideoOverlayModule,
    HintBoxModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TeacherSidebarComponent],
})
export class TeacherSidebarModule {}
