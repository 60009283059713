import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CreateLearnersComponent } from './create-learners.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CreateLearnersComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    LoadingSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [CreateLearnersComponent],
})
export class CreateLearnersModule {}
