import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let requestUrl = req.url;
    const authToken = this.authService.getToken();
    let authRequest = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });
    // readalizerAPI
    if (requestUrl.indexOf(environment.CLOUDAPI_SERVER) !== -1) {
      authRequest = req.clone({});
    }
    // bookReaderAPI
    if (requestUrl.indexOf(environment.BOOKREADERAPI_SERVER) !== -1) {
      authRequest = req.clone({});
    }
    return next.handle(authRequest);
  }
}
