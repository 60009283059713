import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DiagnosticProgressComponent } from './diagnostic-progress.component';

@NgModule({
  declarations: [DiagnosticProgressComponent],
  imports: [BrowserModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [DiagnosticProgressComponent],
})
export class DiagnosticProgressModule {}
