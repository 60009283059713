import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { MaterialModule } from 'src/app/material.module';
import { NonSchoolRegisterStepperComponent } from './non-school-register-stepper/non-school-register-stepper.component';
import { NonSchoolregisterComponent } from './non-school-register.component';

@NgModule({
  declarations: [NonSchoolregisterComponent, NonSchoolRegisterStepperComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [NonSchoolregisterComponent],
})
export class NonSchoolregisterModule {}
