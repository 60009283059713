<div class="group-delete_container">
  <button
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap">
    <h2 class="h2 group-delete_header">
      {{ translatedText.class_delete_question }} "{{ groupName }}"
      {{ translatedText.class_delete_question_text }}
    </h2>
    <button
      mat-flat-button
      color="warning"
      type="button"
      class="group-delete_button"
      (click)="deleteGroup()"
    >
      {{ translatedText.class_delete_delete }}
    </button>
    <img
      class="footer_group-delete_img"
      [src]="'/assets/' + environment.whitelabel.generic_logo"
      alt=""
    />
  </div>
</div>
