import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

// TODO: unused?
@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  generateShortHash(originalString: string): string {
    // Hash the original string using SHA-256
    const hashedString = CryptoJS.SHA256(originalString).toString(
      CryptoJS.enc.Hex,
    );
    // Convert the hexadecimal hash to Base36
    const base36Hash = parseInt(hashedString, 16).toString(36).toUpperCase();
    // Take the first 6 characters
    const shortHash = base36Hash.substring(0, 6);
    return shortHash;
  }
}
