import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TaskService } from '../../providers/task.service';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-task-diagnostic',
  templateUrl: './task-diagnostic.component.html',
  styleUrls: ['./task-diagnostic.component.scss'],
})
export class TaskDiagnosticComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  subscription: Subscription;
  @Input('tasks') tasks: any;
  @Input('diagnosticPhases') diagnosticPhases: any;
  preDiagnostic;
  midDiagnostic;
  postDiagnostic;
  today = new Date();
  showPreDiagnostic: boolean = false;
  showMidDiagnostic: boolean = false;
  showPostDiagnostic: boolean = false;
  translatedText;
  constructor(
    private taskService: TaskService,
    private router: Router,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    console.log(this.today.toISOString());
    // this.taskService.getPhaseDates();
    // this.taskService.getPhaseDatesUpdateListener().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(res => {
    //   this.diagnosticPhases = res;
    //   console.log(res)
    // })
  }

  openTaskPreview(taskId) {
    this.router.navigate(['/task-preview/' + taskId]);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.tasks);
    // get tasks and check for type
    if (changes.tasks != undefined) {
      this.preDiagnostic = changes.tasks.currentValue.filter((item) => {
        if (
          item.type == 'diagnostic-pre' &&
          item.deadline > this.today.toISOString() &&
          !item.completed_at
        ) {
          //show diagnostic task only in seven days
          const deadlineDate = new Date(item.deadline);
          const today = new Date();
          const fourWeeksFromNow = new Date(today);
          fourWeeksFromNow.setDate(today.getDate() + 28);

          if (deadlineDate.getTime() <= fourWeeksFromNow.getTime()) {
            return item;
          }
          //return item
        }
      });
      if (this.preDiagnostic?.length > 0) {
        this.showPreDiagnostic = true;
      }
      this.preDiagnostic = this.preDiagnostic[0];

      this.midDiagnostic = changes.tasks.currentValue.filter((item) => {
        if (
          item.type == 'diagnostic-mid' &&
          item.deadline > this.today.toISOString() &&
          !item.completed_at
        ) {
          //show diagnostic task only in seven days
          const deadlineDate = new Date(item.deadline);
          const today = new Date();
          const fourWeeksFromNow = new Date(today);
          fourWeeksFromNow.setDate(today.getDate() + 28);

          if (deadlineDate.getTime() <= fourWeeksFromNow.getTime()) {
            return item;
          }
          // return item
        }
      });
      console.log(this.midDiagnostic);
      if (
        (this.preDiagnostic?.length < 1 || this.preDiagnostic == undefined) &&
        this.midDiagnostic?.length > 0
      ) {
        this.showMidDiagnostic = true;
      } else {
        this.showMidDiagnostic = false;
      }
      this.midDiagnostic = this.midDiagnostic[0];

      this.postDiagnostic = changes.tasks.currentValue.filter((item) => {
        if (
          item.type == 'diagnostic-post' &&
          item.deadline > this.today.toISOString() &&
          !item.completed_at
        ) {
          //show diagnostic task only in seven days
          const deadlineDate = new Date(item.deadline);
          const today = new Date();
          const fourWeeksFromNow = new Date(today);
          fourWeeksFromNow.setDate(today.getDate() + 28);

          if (deadlineDate.getTime() <= fourWeeksFromNow.getTime()) {
            return item;
          }
          //return item
        }
      });

      if (
        (this.preDiagnostic?.length < 1 || this.preDiagnostic == undefined) &&
        (this.midDiagnostic?.length < 1 || this.midDiagnostic == undefined) &&
        this.postDiagnostic?.length > 0
      ) {
        this.showPostDiagnostic = true;
      }
      this.postDiagnostic = this.postDiagnostic[0];
      console.log(this.preDiagnostic);
    }
  }

  getSalutation(teacher) {
    if (teacher.salutation == 'divers' || teacher.salutation == 'Divers') {
      return teacher.firstname;
    } else {
      return teacher.salutation;
    }
  }

  calculateDaysLeft(date) {
    let daysLeft = this.taskService.calculateDaysLeft(date);
    let text = '';
    if (daysLeft == 1) {
      text =
        this.translatedText?.task_diagnostic_next +
        ' ' +
        daysLeft +
        ' ' +
        this.translatedText?.task_diagnostic_day;
    } else {
      text =
        this.translatedText?.task_diagnostic_next +
        ' ' +
        daysLeft +
        ' ' +
        this.translatedText?.task_diagnostic_days;
    }
    return text;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
