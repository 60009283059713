import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { LicenseData } from 'src/app/providers/admin.service';
import { LicenseService } from 'src/app/providers/license.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';

export interface licenseData {
  quota: string;
  Bought: string;
  Redeemed: string;
  ExpiryDate: string;
  Buyedby: string;
  Activate: string;
}

@Component({
  selector: 'app-admin-profile-management',
  templateUrl: './admin-profile-management.component.html',
  styleUrls: ['./admin-profile-management.component.scss'],
})
export class AdminProfileManagementComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;
  purchaseLicenseNotification: boolean = true;
  licensesToSchool: any = [];
  licenseDataSource: LicenseData = null;
  dataSource = [];
  licensesToSchoolLoading: boolean = true;
  totalCountDiagnostic: number = 0;
  totalCountTraining: number = 0;
  totalCountLeftDiagnostic: number = 0;
  totalCountLeftTraining: number = 0;
  totalCountLeftDiagnosticExp: number = 0;
  totalCountLeftTrainingExp: number = 0;
  hasExpiringLicenses: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  route: any;
  pageEvent: PageEvent;
  displayedColumns: string[] = [
    'Content',
    'quota',
    'Activated on',
    'Expiry Date',
    'Activate',
  ];
  matSortDirection: string;
  filterValues: any;
  page = 1;
  limit = 10;
  sortBy = 'name';
  constructor(
    private licenseService: LicenseService,
    private translationService: TranslationService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.getLicenses();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  /**
   * API call on adminService to retrieve requested teachers by pagination
   */
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        let processedKeys = new Set();
        let allLicense = [];
        let fourWeeksFromNow = new Date();
        fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
        let expireLicenseCount = 0;
        // Sort for showing the right expiration date in list
        licenses.sort((a, b) => {
          const isATypeFo = a.type_key === 'fo';
          const isBTypeFo = b.type_key === 'fo';
          const isAMasterKeyInvalid =
            a.master_license_key.startsWith('DI') ||
            a.master_license_key.startsWith('DL');
          const isBMasterKeyInvalid =
            b.master_license_key.startsWith('DI') ||
            b.master_license_key.startsWith('DL');

          // 1. Check if both are 'fo' with invalid master keys
          if (
            isATypeFo &&
            isAMasterKeyInvalid &&
            isBTypeFo &&
            isBMasterKeyInvalid
          ) {
            return 0; // keep original order among invalid 'fo' licenses
          }

          // 2. Push 'fo' with invalid master keys to the end
          if (isATypeFo && isAMasterKeyInvalid) {
            return 1; // push a to the end
          }
          if (isBTypeFo && isBMasterKeyInvalid) {
            return -1; // push b to the end
          }

          // 3. Bring valid 'fo' licenses to the front
          if (isATypeFo && !isAMasterKeyInvalid && !isBTypeFo) {
            return -1; // a comes before b
          }
          if (isBTypeFo && !isBMasterKeyInvalid && !isATypeFo) {
            return 1; // b comes before a
          }

          // 4. Keep the rest in their original order
          return 0;
        });
        // Create showable license array by type_key separation
        for (const item of licenses) {
          if (!processedKeys.has(item.master_license_key)) {
            processedKeys.add(item.master_license_key);
            if (item.expiration_date <= fourWeeksFromNow) {
              expireLicenseCount++;
            }
            let licenseData = {
              content: this.licenseService.getContentTypeByLicenseKey(
                item.master_license_key,
                this.translatedText,
              ),
              quota:
                item.amount == 1
                  ? item.amount +
                    ' ' +
                    this.translatedText.licenses_purchase_type_single
                  : item.amount +
                    ' ' +
                    (item.amount >= '100'
                      ? this.translatedText.licenses_purchase_type_school_plural
                      : this.translatedText
                          .licenses_purchase_type_single_plural),
              //Bought: item.used_at,
              activated_on: item.used_at,
              expiry_Date: item.expiration_date,
              _id: item._id,
              //Buyedby: 'Friedrich Meyer',
              status: item.status,
              master_license_key: item.master_license_key,
            };
            allLicense.push(licenseData);
          }
        }
        // Sort all licenses by expiration date and status
        allLicense.sort((a, b) => {
          // First, compare by status ('active' comes before others)
          if (a.status === 'active' && b.status !== 'active') {
            return -1; // a comes before b
          } else if (a.status !== 'active' && b.status === 'active') {
            return 1; // b comes before a
          }

          // If both have the same status, sort by expiration date
          const dateA = new Date(a.expiration_date);
          const dateB = new Date(b.expiration_date);

          return dateA.getTime() - dateB.getTime();
        });
        if (expireLicenseCount > 0) {
          this.hasExpiringLicenses = true;
        }
        this.dataSource = allLicense;
        this.totalCountDiagnostic = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'di',
          'amount',
        );
        this.totalCountLeftDiagnostic =
          this.licenseService.sumLicensesByTypeKey(
            this.licensesToSchool,
            'di',
            'amount_left',
          );
        // this.totalCountLeftDiagnosticExp = 45;
        this.totalCountLeftDiagnosticExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'di',
            'amount_left',
          );
        console.log(
          'this.totalCountLeftDiagnosticExp',
          this.totalCountLeftDiagnosticExp,
        );
        this.totalCountTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount',
        );
        this.totalCountLeftTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount_left',
        );
        this.totalCountLeftTrainingExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'fo',
            'amount_left',
          );
        this.licensesToSchoolLoading = false;
      });
  }

  closePurchase() {
    this.purchaseLicenseNotification = false;
  }
  refreshLicense(event: any) {
    this.getLicenses();
  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }
}
