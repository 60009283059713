import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { TranslationService } from './translation.service';
import { AdminService } from './admin.service';

export interface Task {
  // _id: string;
  // username: string;
  // progress: number;
  // readingPoints: number;
  // readingError: number;
  // speed: string;
}

export interface TaskData {
  data: Task[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
    totalCounts?: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  studentTask: Task[] = [];
  studentTaskUpdated = new Subject<Task[]>();
  masterTaskUpdated = new Subject<Task[]>();
  masterTaskFromTeacherUpdated = new Subject<TaskData>();
  getSubTasksFromTeacherTrigger$ = new Subject<void>();
  singleTask: Task[] = [];
  singleTaskUpdated = new Subject<Task[]>();
  status = 'active';
  page = 1;
  limit = 10;
  sortBy = 'deadline';
  sortDirection = 1;
  filterValues = {
    search: '',
  };
  phaseDates;
  phaseDatesUpdated = new Subject<any>();

  readingSpeedBoundaries = {
    1: [27, 35, 49, 68, 100], // grade 1 levels
    2: [37, 50, 68, 88, 114], // grade 2 levels
    3: [52, 70, 89, 106, 124], // grade 3 levels
    4: [56, 74, 93, 111, 128], // grade 4 levels
    5: [62, 77, 98, 114, 132], // grade 5 levels
    6: [64, 76, 102, 129, 145], // grade 6 levels
    7: [64, 76, 102, 129, 145], // grade 7 levels
    8: [64, 76, 102, 129, 145], // grade 8 levels
  };

  syllablesPerMinuteBoundaries = {
    1: [51, 68, 91, 119, 167], // grade 1 levels
    2: [71, 92, 122, 157, 194], // grade 2 levels
    3: [100, 128, 158, 186, 212], // grade 3 levels
    4: [105, 140, 169, 195, 218], // grade 4 levels
    5: [118, 141, 176, 208, 236], // grade 5 levels
    6: [121, 147, 176, 208, 255], // grade 6 levels
    7: [121, 147, 176, 208, 255], // grade 7 levels
    8: [121, 147, 176, 208, 255], // grade 8 levels
  };

  readingErrorBoundaries = {
    1: [78, 52, 34, 20, 12], // grade 1 levels
    2: [68, 43, 27, 16, 10], // grade 2 levels
    3: [56, 33, 21, 14, 9], // grade 3 levels
    4: [58, 35, 22, 15, 10], // grade 4 levels
    5: [73, 44, 25, 16, 8], // grade 5 levels
    6: [48, 27, 18, 11, 7], // grade 6 levels
    7: [48, 27, 18, 11, 7], // grade 7 levels
    8: [48, 27, 18, 11, 7], // grade 8 levels
  };

  readingDynamicBoundaries = {
    1: [16.67, 33.33, 50, 66.67, 83.33], // grade 1 levels
    2: [16.67, 33.33, 50, 66.67, 83.33], // grade 2 levels
    3: [16.67, 33.33, 50, 66.67, 83.33], // grade 3 levels
    4: [16.67, 33.33, 50, 66.67, 83.33], // grade 4 levels
    5: [16.67, 33.33, 50, 66.67, 83.33], // grade 5 levels
    6: [16.67, 33.33, 50, 66.67, 83.33], // grade 6 levels
    7: [16.67, 33.33, 50, 66.67, 83.33], // grade 7 levels
    8: [16.67, 33.33, 50, 66.67, 83.33], // grade 8 levels
  };

  private restServerUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translationService: TranslationService,
    private adminService: AdminService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  getSingleTask(id) {
    return this.http
      .get<Task[]>(this.restServerUrl + '/api/task/getSingleTask/' + id)
      .subscribe((data) => {
        this.singleTask = data;
        this.singleTaskUpdated.next(this.singleTask);
      });
  }

  getSingleTaskUpdateListener() {
    return this.singleTaskUpdated.asObservable();
  }

  getTasksToStudents(id) {
    return this.http
      .get<Task[]>(this.restServerUrl + '/api/task/getTasksToStudent/' + id)
      .subscribe((data) => {
        this.studentTask = data;
        if (data['_tasks']) {
          let tasks = [...data['_tasks']];
          this.studentTaskUpdated.next(tasks);
        }
      });
  }

  getTasksToStudentsUpdateListener() {
    return this.studentTaskUpdated.asObservable();
  }

  getMasterTasksFromTeacherUpdateListener() {
    return this.masterTaskFromTeacherUpdated.asObservable();
  }

  getMasterTasksFromTeacher(
    status?: string,
    page?: number,
    limit?: number,
    sortBy?: string,
    sortDirection?: number,
    filterValues?: any,
  ) {
    // Save values for external call withous params
    if (status && page && limit && sortBy && sortDirection && filterValues) {
      this.status = status;
      this.page = page;
      this.limit = limit;
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.filterValues = filterValues;
    }
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(this.filterValues))
      .set('page', String(this.page))
      .set('limit', String(this.limit))
      .set('sortBy', this.sortBy)
      .set('sortDirection', String(this.sortDirection));

    return this.http
      .get<TaskData>(
        this.restServerUrl +
          '/api/student/getMasterTasksAndStudentsFromTeacher/' +
          this.status,
        { params },
      )
      .subscribe((taskData: TaskData) => {
        this.masterTaskFromTeacherUpdated.next(taskData);
      });
  }

  getSubTasksFromTeacher(
    status?: string,
    page?: number,
    limit?: number,
    sortBy?: string,
    sortDirection?: number,
    filterValues?: any,
  ) {
    // Save values for external call withous params
    if (status && page && limit && sortBy && sortDirection && filterValues) {
      this.status = status;
      this.page = page;
      this.limit = limit;
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.filterValues = filterValues;
    }
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(this.filterValues))
      .set('page', String(this.page))
      .set('limit', String(this.limit))
      .set('sortBy', this.sortBy)
      .set('sortDirection', String(this.sortDirection));

    return this.http
      .get<TaskData>(
        this.restServerUrl +
          '/api/student/getSubTasksAndStudentsFromTeacher/' +
          this.status,
        { params },
      )
      .pipe(catchError((err) => throwError(err)));
  }

  // Too many api calls version
  // getMasterTasksFromTeacher(teacherId) {
  //   return this.http.get<any>(this.restServerUrl + '/api/task/getMasterTasksFromTeacher/' + teacherId).pipe(concatMap(async tasks => {
  //     const authToken = this.authService.getToken();
  //     await Promise.all(tasks.map(async (task) => {
  //         const promise = await fetch(this.restServerUrl + '/api/student/getStudentsToMasterTask/?masterTaskId='+task._id+'&teacherId='+teacherId, {
  //           headers: {'Authorization': 'Bearer ' + authToken }
  //         });
  //         let mastertask = promise.json();
  //         //console.log(mastertask)
  //         mastertask.then((data) => {
  //             //console.log(data)
  //             //if (data.statusCode == 200) {
  //               task["students"] = data["students"];
  //               task["groupName"] = data["groupName"];
  //               task["tasks"] = data["tasks"];
  //               task["finishedTasks"] = data["tasks"].filter((item) =>{ return item["status"] == "finished"; }).length;
  //             //}
  //             return task;
  //         })
  //     }));
  //     if (tasks.length > 0) {
  //       tasks.sort((a, b) => {
  //         let aFinished = a?.finishedTasks == a?.tasks?.length ? 1 : -1;
  //         let bFinished = b?.finishedTasks == b?.tasks?.length ? 1 : -1;
  //         return bFinished - aFinished;
  //       })
  //     }
  //     return tasks;
  //   }))
  //   .pipe(map(data => data), takeUntil(this._unsubscribeAll))
  //   .subscribe(data => {
  //       this.masterTaskUpdated.next([...data]);
  //   });
  // }

  updateSingleTask(data) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/task/updateSingleTask/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  updateSingleTaskBook(data) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/task/updateSingleTaskBook/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  editMasterAndSubTasks(data) {
    return this.http
      .put(
        this.restServerUrl + '/api/task/editMasterAndSubTasks/' + data._id,
        JSON.stringify(data),
        { headers: new HttpHeaders().set('Content-Type', 'application/json') },
      )
      .pipe(
        concatMap((res) => {
          const createStudents = [];
          for (const newStudent of data.newStudentsArray) {
            const found = data.studentsArray.some(
              (student) => student._id === newStudent._id,
            );
            if (!found) {
              createStudents.push(newStudent);
            }
          }
          if (createStudents.length > 0) {
            let taskForStudents = {
              masterTaskId: data['_id'],
              teacherId: data['teacherId'],
              students: createStudents.map((obj) => {
                return {
                  studentId: obj._id,
                  groupId: this.getGroupName(obj._group)._id,
                };
              }),
              createSubTasks: true,
            };
            return this.createTaskForStudents(taskForStudents);
          } else {
            return new Observable((subscriber) => {
              subscriber.next(res);
              subscriber.complete();
            });
          }
        }),
      );
  }

  createMasterTask(task, teacherId, students, isTrialPhase?: boolean) {
    console.log(task);
    let masterEndpoint =
      task.mode == 'autonomous'
        ? '/api/task/createMasterTaskForAutonomousMode'
        : '/api/task/createMasterTask';
    return this.http.post<any>(this.restServerUrl + masterEndpoint, task).pipe(
      concatMap((data) => {
        const masterTaskId = data.masterTaskId;
        let taskForStudents = {
          masterTaskId: masterTaskId,
          teacherId: teacherId,
          students: students.map((obj) => {
            return {
              studentId: obj._id,
              groupId: this.getGroupName(obj._group)._id,
            };
          }),
          createSubTasks: true,
          isTrialPhase,
        };
        // create subtask for students
        if (task.mode == 'autonomous') {
          return this.createTaskAutonomousModeForStudents(taskForStudents);
        } else {
          return this.createTaskForStudents(taskForStudents);
        }
      }),
    );
  }

  createMasterTaskForDiagnostic(
    tasks,
    teacherId,
    students,
    isTrialPhase?: boolean,
  ) {
    const uniqueGrades = [];
    students.forEach((item) => {
      if (Array.isArray(item._group)) {
        // TODO: set the selected grade and not just array on position 0 - logic missing
        const grade = this.getGroupName(item._group).grade.toString();
        if (!uniqueGrades.includes(grade)) {
          uniqueGrades.push(grade);
        }
      }
    });
    let observables = [];

    tasks.forEach((task) => {
      uniqueGrades.map((grade) => {
        let taskData = {
          type: task.type,
          deadline: task.deadline,
          teacherId: teacherId,
          grade: grade,
          custom_deadline: task.custom_deadline,
          students: students
            .filter(
              (student) => this.getGroupName(student._group).grade == grade,
            )
            .map((obj) => {
              return {
                studentId: obj._id,
                groupId: this.getGroupName(obj._group)._id,
              };
            }),
        };

        const tasksToCreate = students
          .filter((student) => this.getGroupName(student._group).grade == grade)
          .filter((item) => {
            if (item.diagnostic?.length == 0) {
              return true;
            } else {
              const tasks = item.diagnostic.filter(
                (el) => el.kind != task.type.replace(/diagnostic-/g, ''),
              );
              return tasks.length > 0 ? true : false;
            }
          });

        // Create master and sub if not exist yet
        if (tasksToCreate.length > 0) {
          let createMasterTaskObs = this.http.post<any>(
            this.restServerUrl + '/api/task/createMasterTaskForDiagnostic',
            taskData,
          );

          let createTaskForStudentsObs = createMasterTaskObs.pipe(
            concatMap((data) => {
              console.log(data);
              const masterTaskId = data.masterTaskId;

              let taskForStudents = {
                masterTaskId: masterTaskId,
                teacherId: teacherId,
                createSubTasks: data.createSubTasks,
                students: students
                  .filter(
                    (student) =>
                      this.getGroupName(student._group).grade == grade,
                  )
                  .map((obj) => {
                    return {
                      studentId: obj._id,
                      groupId: this.getGroupName(obj._group)._id,
                    };
                  }),
                isTrialPhase,
              };
              console.log(taskForStudents);
              // create subtask for students
              console.log('CREATE_TASK', taskForStudents);
              return this.createTaskForStudents(taskForStudents);
            }),
            map((res) => {
              console.log(res);
              return res;
            }),
          );

          observables.push(createTaskForStudentsObs);
        } else {
          // Resolve
          observables.push(of(null));
        }
      });
    });

    return forkJoin(observables);
  }

  createTaskForStudents(taskForStudents) {
    console.log('CREATE_TASK_FOR_STUDENTS', taskForStudents);
    return this.http
      .post<any>(
        this.restServerUrl + '/api/task/createTaskForStudents',
        taskForStudents,
      )
      .pipe(
        tap((res) => {
          console.log(res);
          // Update task list
          this.getSubTasksFromTeacherTrigger$.next();
        }),
      );
  }

  mockDevalueLicense(readingResults) {
    this.http
      .post<any>(
        this.restServerUrl + '/api/task/mockDevalueLicense',
        readingResults,
      )
      .subscribe({
        next: (response) => {
          console.log('MOCK LICENSE DEVALUED');
          console.log(response);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  createTaskAutonomousModeForStudents(taskForStudents) {
    return this.http
      .post<any>(
        this.restServerUrl + '/api/task/createTaskAutonomousModeForStudents',
        taskForStudents,
      )
      .pipe(tap((res) => console.log(res)));
  }

  createTaskForSelfReading(studentId) {
    return this.http.post<any>(
      this.restServerUrl + '/api/task/createTaskForSelfReading',
      {
        studentId: studentId,
      },
    );
  }

  duplicateMasterTask(task) {
    return this.http
      .post<any>(this.restServerUrl + '/api/task/duplicateMasterTask', task)
      .pipe(
        concatMap((data) => {
          const masterTaskId = data.newMasterTaskId;
          const students = data.students;
          let taskForStudents = {
            masterTaskId: masterTaskId,
            teacherId: task.teacherId,
            students: students,
          };
          // create subtask for students
          return this.createTaskForStudents(taskForStudents);
        }),
      );
  }

  transferTasksToTeacher(giver_teacher_id, taker_teacher_id, group_id) {
    let transferData = {
      giver_teacher_id: giver_teacher_id,
      taker_teacher_id: taker_teacher_id,
      group_id: group_id,
    };
    return this.http
      .put<any>(
        this.restServerUrl + '/api/task/transferTasksToTeacher',
        transferData,
      )
      .pipe(tap((res) => console.log(res)));
  }

  repeatTask(data) {
    return data;
    // return this.http
    //     .post<any>(
    //         this.restServerUrl + "/api/task/repeatTask",
    //         data
    //     ).pipe(map(res => {
    //         return <Task>res;
    //     }));
  }

  repeatDiagnosticTask(data) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/task/repeatDiagnosticTask/' + data.taskId,
      data,
    );
  }

  reportResultFault(data) {
    return this.http.put<Task>(
      this.restServerUrl + '/api/task/reportResultFault',
      data,
    );
  }

  deleteTask(masterTaskId, isTrialPhase?: boolean) {
    const body = { isTrialPhase: isTrialPhase };
    return this.http.delete<Task>(
      this.restServerUrl + '/api/task/deleteMasterAndSubTasks/' + masterTaskId,
      { body },
    );
  }

  generateTaskTypeTitle(type: string, mode: string) {
    let title = '';
    let hasIcon = false;
    let iconName = '';

    switch (type) {
      case 'diagnostic-pre':
        title = 'Diagnose 1';
        break;
      case 'diagnostic-mid':
        title = 'Diagnose 2';
        break;
      case 'diagnostic-post':
        title = 'Diagnose 3';
        break;
      case 'self':
        title = 'Freiwillig';
        break;
      default:
        title = this.translationService.getTranslatedText('single_mode');
        break;
    }

    switch (mode) {
      case 'autonomous':
        title = this.translationService.getTranslatedText('auto_mode');
        hasIcon = true;
        iconName = 'streamline_filled:auto_task';
        break;
      case 'multiple':
        title = this.translationService.getTranslatedText('multiple_mode');
        hasIcon = true;
        iconName = 'streamline_filled:ongoing_task';
        break;
      default:
        break;
    }

    return { title, hasIcon, iconName };
  }

  generateTaskTypeClasses(prefix, type, mode) {
    let classes = prefix + '--' + type;
    return mode == 'autonomous'
      ? classes + ' ' + prefix + '--' + mode
      : classes;
  }

  generateTaskEvaluationTitle(evaluation) {
    let title = '';
    switch (evaluation) {
      case 'reading-loud':
        title = 'Flüssigkeit';
        break;
      case 'reading-silent':
        title = 'Leseverstehen';
        break;
      case 'listening':
        title = 'Hörverstehen';
        break;
      default:
        break;
    }
    return title;
  }

  formatHTMLContent(content) {
    if (content) {
      const htmlEntities = [
        { entity: '&amp;amp;#8211;', character: '–' },
        { entity: '&amp;', character: '&' },
        { entity: '&lt;', character: '<' },
        { entity: '&gt;', character: '>' },
        { entity: '&quot;', character: '"' },
        { entity: '&apos;', character: "'" },
        { entity: '&#8211;', character: '–' },
        { entity: '&#8212;', character: '—' },
        { entity: '&#8230;', character: '…' },
        { entity: '&#8364;', character: '€' },
        { entity: '&#8249;', character: '‹' },
        { entity: '&#8250;', character: '›' },
        { entity: '&#x27;', character: "'" },
      ];

      let formattedContent = content;
      let hasReplacements = true;
      while (hasReplacements) {
        hasReplacements = false;
        for (const entity of htmlEntities) {
          const { entity: htmlEntity, character } = entity;
          const regex = new RegExp(htmlEntity, 'g');
          if (formattedContent.match(regex)) {
            hasReplacements = true;
            formattedContent = formattedContent.replace(regex, character);
          }
        }
      }
      return formattedContent;
    } else {
      return content;
    }
  }

  generatePercentForContinousTask(tasks: any[]) {
    let totalSum = [];
    if (tasks != undefined) {
      const taskTime = tasks[0].time;
      totalSum = tasks.map((task) => {
        let timeUsed = 0;
        if (task?._split_task && task?._split_task.length > 0) {
          timeUsed = task._split_task.reduce((sum, splitTask) => {
            return sum + splitTask.time_used;
          }, 0);
        }
        return Math.min((timeUsed / taskTime) * 100, 100);
      });
      //console.log(totalSum)
    }
    // Sum up progress of all tasks and devide by tasks length for average
    let average = totalSum.reduce((a, b) => a + b, 0) / totalSum.length;
    return Math.min(Math.round(average), 100);
  }

  getPhaseDates() {
    const currentYear = new Date().getFullYear();
    this.http
      .get<any>(this.restServerUrl + '/api/school/getPhaseDates')
      .subscribe({
        next: (data) => {
          console.log(data);
          if (data) {
            this.phaseDates = data;
            this.phaseDatesUpdated?.next(data);
          }
        },
        error: (error) => {
          // this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  getEstimatedReadingTime(wordCount) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const params = new HttpParams().set('wordCount', wordCount);

    return this.http.get<TaskData>(
      this.restServerUrl + '/api/group/getEstimatedReadingTime',
      { headers: headers, responseType: 'json', params },
    );
  }

  getPhaseDatesUpdateListener() {
    return this.phaseDatesUpdated.asObservable();
  }

  calculateSplitTaskTotal(task) {
    let timeUsed = 0;
    if (task?._split_task && task?._split_task.length > 0) {
      timeUsed = task._split_task.reduce((sum, splitTask) => {
        return sum + splitTask.time_used;
      }, 0);
    }
    return timeUsed.toFixed(1);
  }

  calculateDaysLeft(targetDate: Date): number {
    const currentDate = moment();
    const endDate = moment(targetDate);
    const daysLeft = endDate.diff(currentDate, 'days');
    return daysLeft;
  }

  getReadingSpeedNote(grade, reading_speed_words) {
    if (grade > 8) {
      grade = 8;
    }
    let readingSpeedNotes = [
      {
        description:
          this.translationService.getTranslatedText('readingSpeedNote_1'),
        received: true,
        performance: '1',
      },
      {
        description:
          this.translationService.getTranslatedText('readingSpeedNote_2'),
        received: true,
        performance: '5',
      },
      {
        description:
          this.translationService.getTranslatedText('readingSpeedNote_3'),
        received: true,
        performance: '3',
      },
      {
        description:
          this.translationService.getTranslatedText('readingSpeedNote_4'),
        received: true,
        performance: '3',
      },
      {
        description:
          this.translationService.getTranslatedText('readingSpeedNote_5'),
        received: true,
        performance: '5',
      },
    ];
    let speedNotes = {};
    //notes for reading speed logic
    // if (reading_speed_words <= 10) {
    //   speedNotes = readingSpeedNotes[4];
    // } else if (reading_speed_words <= 25) {
    //   speedNotes = readingSpeedNotes[2];
    // } else if (reading_speed_words >= 26 && reading_speed_words <= 74) {
    //   speedNotes = readingSpeedNotes[0];
    // } else if (reading_speed_words >= 75) {
    //   speedNotes = readingSpeedNotes[1];
    // } else if (reading_speed_words >= 90) {
    //   speedNotes = readingSpeedNotes[3];
    // }

    if (reading_speed_words <= this.readingSpeedBoundaries[grade][0]) {
      speedNotes = readingSpeedNotes[3];
    } else if (reading_speed_words <= this.readingSpeedBoundaries[grade][1]) {
      speedNotes = readingSpeedNotes[1];
    } else if (
      reading_speed_words >= this.readingSpeedBoundaries[grade][1] + 1 &&
      reading_speed_words <= this.readingSpeedBoundaries[grade][3] - 1
    ) {
      speedNotes = readingSpeedNotes[0];
    } else if (
      reading_speed_words >= this.readingSpeedBoundaries[grade][3] &&
      reading_speed_words < this.readingSpeedBoundaries[grade][4]
    ) {
      speedNotes = readingSpeedNotes[2];
    } else if (reading_speed_words >= this.readingSpeedBoundaries[grade][4]) {
      speedNotes = readingSpeedNotes[4];
    }
    return speedNotes;
  }

  getReadingDynamicNote(grade, reading_dynamic) {
    if (grade > 8) {
      grade = 8;
    }
    let readingDynamicNotes = [
      {
        description: this.translationService.getTranslatedText(
          'readingDynamicNote_1',
        ),
        received: true,
        performance: '1',
      },
      {
        description: this.translationService.getTranslatedText(
          'readingDynamicNote_2',
        ),
        received: true,
        performance: '3',
      },
      {
        description: this.translationService.getTranslatedText(
          'readingDynamicNote_3',
        ),
        received: true,
        performance: '3',
      },
      {
        description: this.translationService.getTranslatedText(
          'readingDynamicNote_4',
        ),
        received: true,
        performance: '5',
      },
      {
        description: this.translationService.getTranslatedText(
          'readingDynamicNote_5',
        ),
        received: true,
        performance: '5',
      },
    ];
    let dynamicNotes = {};
    //notes for reading dynamic logic
    // if (reading_dynamic <= 10) {
    //   dynamicNotes = readingDynamicNotes[4];
    // } else if (reading_dynamic <= 25) {
    //   dynamicNotes = readingDynamicNotes[2];
    // } else if (reading_dynamic >= 26 && reading_dynamic <= 74) {
    //   dynamicNotes = readingDynamicNotes[0];
    // } else if (reading_dynamic >= 75) {
    //   dynamicNotes = readingDynamicNotes[1];
    // } else if (reading_dynamic >= 90) {
    //   dynamicNotes = readingDynamicNotes[3];
    // }
    if (reading_dynamic <= this.readingDynamicBoundaries[grade][0]) {
      dynamicNotes = readingDynamicNotes[3];
    } else if (reading_dynamic <= this.readingDynamicBoundaries[grade][1]) {
      dynamicNotes = readingDynamicNotes[1];
    } else if (
      reading_dynamic >= this.readingDynamicBoundaries[grade][1] + 1 &&
      reading_dynamic <= this.readingDynamicBoundaries[grade][3] - 1
    ) {
      dynamicNotes = readingDynamicNotes[0];
    } else if (
      reading_dynamic >= this.readingDynamicBoundaries[grade][3] &&
      reading_dynamic < this.readingDynamicBoundaries[grade][4]
    ) {
      dynamicNotes = readingDynamicNotes[2];
    } else if (reading_dynamic >= this.readingDynamicBoundaries[grade][4]) {
      dynamicNotes = readingDynamicNotes[4];
    }
    return dynamicNotes;
  }

  getInsertionsNotes(grade, num_insertions) {
    if (grade > 8) {
      grade = 8;
    }
    let numInsertionsNotes = [
      {
        description:
          this.translationService.getTranslatedText('InsertionsNotes_1'),
        received: true,
        performance: '1',
      },
      {
        description:
          this.translationService.getTranslatedText('InsertionsNotes_2'),
        received: true,
        performance: '2',
      },
      {
        description:
          this.translationService.getTranslatedText('InsertionsNotes_3'),
        received: true,
        performance: '3',
      },
      {
        description:
          this.translationService.getTranslatedText('InsertionsNotes_4'),
        received: true,
        performance: '4',
      },
      {
        description:
          this.translationService.getTranslatedText('InsertionsNotes_5'),
        received: true,
        performance: '5',
      },
    ];
    let insertionsNotes = {};
    //notes for reading dynamic logic
    if (num_insertions == 0) {
      insertionsNotes = undefined;
    } else if (num_insertions <= 5) {
      insertionsNotes = numInsertionsNotes[0];
    } else if (num_insertions <= 10) {
      insertionsNotes = numInsertionsNotes[1];
    } else if (num_insertions <= 20) {
      insertionsNotes = numInsertionsNotes[2];
    } else if (num_insertions <= 30) {
      insertionsNotes = numInsertionsNotes[3];
    } else if (num_insertions > 31) {
      insertionsNotes = numInsertionsNotes[4];
    }
    return insertionsNotes;
  }

  getDeletionsNote(grade, num_deletions) {
    if (grade > 8) {
      grade = 8;
    }
    let numDeletionsNotes = [
      {
        description:
          this.translationService.getTranslatedText('DeletionsNote_1'),
        received: true,
        performance: '1',
      },
      {
        description:
          this.translationService.getTranslatedText('DeletionsNote_2'),
        received: true,
        performance: '2',
      },
      {
        description:
          this.translationService.getTranslatedText('DeletionsNote_3'),
        received: true,
        performance: '3',
      },
      {
        description:
          this.translationService.getTranslatedText('DeletionsNote_4'),
        received: true,
        performance: '4',
      },
      {
        description:
          this.translationService.getTranslatedText('DeletionsNote_5'),
        received: true,
        performance: '5',
      },
    ];
    let deletionsNotes = {};
    //notes for reading dynamic logic
    if (num_deletions == 0) {
      deletionsNotes = undefined;
    }
    if (num_deletions <= 5) {
      deletionsNotes = numDeletionsNotes[0];
    } else if (num_deletions <= 10) {
      deletionsNotes = numDeletionsNotes[1];
    } else if (num_deletions <= 20) {
      deletionsNotes = numDeletionsNotes[2];
    } else if (num_deletions <= 30) {
      deletionsNotes = numDeletionsNotes[3];
    } else if (num_deletions > 31) {
      deletionsNotes = numDeletionsNotes[4];
    }
    return deletionsNotes;
  }

  getSubstitutionsNote(grade, num_substitutions) {
    if (grade > 8) {
      grade = 8;
    }
    let numSubstitutionsNotes = [
      {
        description: this.translationService.getTranslatedText(
          'SubstitutionsNote_1',
        ),
        received: true,
        performance: '1',
      },
      {
        description: this.translationService.getTranslatedText(
          'SubstitutionsNote_2',
        ),
        received: true,
        performance: '2',
      },
      {
        description: this.translationService.getTranslatedText(
          'SubstitutionsNote_3',
        ),
        received: true,
        performance: '3',
      },
      {
        description: this.translationService.getTranslatedText(
          'SubstitutionsNote_4',
        ),
        received: true,
        performance: '4',
      },
      {
        description: this.translationService.getTranslatedText(
          'SubstitutionsNote_5',
        ),
        received: true,
        performance: '5',
      },
    ];
    let substitutionsNotes = {};
    //notes for reading dynamic logic
    if (num_substitutions == 0) {
      substitutionsNotes = undefined;
    } else if (num_substitutions <= 5) {
      substitutionsNotes = numSubstitutionsNotes[0];
    } else if (num_substitutions <= 10) {
      substitutionsNotes = numSubstitutionsNotes[1];
    } else if (num_substitutions <= 20) {
      substitutionsNotes = numSubstitutionsNotes[2];
    } else if (num_substitutions <= 30) {
      substitutionsNotes = numSubstitutionsNotes[3];
    } else if (num_substitutions > 31) {
      substitutionsNotes = numSubstitutionsNotes[4];
    }
    return substitutionsNotes;
  }

  getReadingErrorNote(grade, reading_errors_score) {
    if (grade > 8) {
      grade = 8;
    }
    let readingErrorsNotes = [
      {
        description:
          this.translationService.getTranslatedText('ReadingErrorNote_1'),
        received: true,
        performance: '1',
      },
      // {
      //   description: "Toll, Du hast kaum Lesefehler gemacht",
      //   received: true,
      //   performance: "good"
      // },
      // {
      //   description: "Du hast nur wenige Lesefehler gemacht",
      //   received: true,
      //   performance: "standard"
      // },
      // {
      //   description: "Versuche etwas weniger Lesefehler zu machen",
      //   received: true,
      //   performance: "standard"
      // },
      // {
      //   description: "Lies etwas häufiger und Du wirst weniger Lesefehler machen",
      //   received: true,
      //   performance: "sad"
      // },
    ];
    let errorNotes = {};
    //notes for reading errors logic
    if (reading_errors_score === 0) {
      errorNotes = readingErrorsNotes[0];
    }
    // else if (reading_errors_score >= 1 && reading_errors_score <= 10) {
    //   errorNotes = this.readingErrorsNotes[1];
    // } else if (reading_errors_score >= 11 && reading_errors_score <= 30) {
    //   errorNotes = this.readingErrorsNotes[2];
    // } else if (reading_errors_score >= 31 && reading_errors_score <= 50) {
    //   errorNotes = this.readingErrorsNotes[3];
    // } else if (reading_errors_score >= 51) {
    //   errorNotes = this.readingErrorsNotes[4];
    // }
    return errorNotes;
  }

  getLevelFromScore(score: number, levelArray: [number]) {
    for (let i = 0; i < levelArray.length; i++) {
      if (score <= levelArray[i]) {
        return i + 1; // Adding 1 to match the 1-based level numbering
      }
    }
    return levelArray.length; // If score is greater than the highest level
  }

  getLevelNotes(level) {
    switch (level) {
      case 1:
        return this.translationService.getTranslatedText('levelNotes_1');
      case 2:
        return this.translationService.getTranslatedText('levelNotes_2');
      case 3:
        return this.translationService.getTranslatedText('levelNotes_3');
      case 4:
        return this.translationService.getTranslatedText('levelNotes_4');
      case 5:
        return this.translationService.getTranslatedText('levelNotes_5');
      default:
        return '';
    }
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }
}
