import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ExpertGroupComponent } from 'src/app/helpers/expert-group/expert-group.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { GroupService } from 'src/app/providers/group.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';

// TODO: Type decration
export interface Group {}

export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-dashboard-teacher',
  templateUrl: './dashboard-teacher.component.html',
  styleUrls: ['./dashboard-teacher.component.scss'],
})
export class DashboardTeacherComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  showError: boolean = false;
  hideWidgets: boolean = true; // TODO: always true?
  progress: 50;
  groups: Group[] = [];
  averageReadingScore = 0;
  groupsCount = 0;
  studentsCount = 0;
  uncheckedTasks = 0;
  emptyGroups: boolean = false;
  teacherId;
  userType;
  finishedTasks = [];
  activeTasks = [];
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authService: AuthService,
    private groupService: GroupService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.getTeacherOverview();
    this.getGroupsToTeacher();

    this.authService.getCurrentTrialStatus().subscribe({});
  }

  updateTeacher() {
    this.isLoading = true;
    let formData = {
      teacherId: this.teacherId,
      report: 'expert-info',
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {},
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
        this.isLoading = false;
      },
    });
  }

  openExpertGroupOverlay() {
    let dialogRef = this.dialog.open(ExpertGroupComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'expert-group-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  getGroupsToTeacher() {
    this.groupService.getGroupsToTeacher(true).subscribe({
      next: (data) => {
        console.log(data);
        this.groups = data
          .map((obj) => {
            obj['selectedLicenseView'] = 'license-2';
            return obj;
          })
          .sort(function (a, b) {
            return a['group'].name.localeCompare(b['group'].name);
          });
        if (this.groups.length === 0) {
          this.emptyGroups = true;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.showError = true;
      },
    });
  }

  getTeacherOverview() {
    if (!this.hideWidgets) {
      this.groupService.getTeacherOverview(this.teacherId).subscribe((data) => {
        console.log(data);
        this.averageReadingScore = data.averageReadingScore;
        this.groupsCount = data.groupsCount;
        this.studentsCount = data.studentsCount;
        this.uncheckedTasks = data.uncheckedTasks;
        this.isLoading = false;
      });
    }
  }

  // createClassDialog(): void {
  // 	let dialogRef;
  // 	  dialogRef = this.dialog.open(CreateClassComponent, {
  // 		width: '100%',
  // 		autoFocus: false,
  // 		panelClass: 'create-class-overlay-panel',
  // 	   // hasBackdrop: false,
  // 		disableClose: true,
  // 		backdropClass: 'create-class-overlay-panel-backdrop',
  // 	  });
  // 	// dialogRef.disableClose = true;
  // 	dialogRef.afterClosed().pipe(
  // 	  takeUntil(this._unsubscribeAll)
  // 	).subscribe(res => {
  //     // Refresh groups on dashboard
  //     this.getGroupsByTeacher();
  //     this.getTeacherOverview();
  // 	});
  // }

  //remove later
  // repeatTaskDialog(id): void {
  // 	let dialogRef;
  // 	  dialogRef = this.dialog.open(TaskRepeatComponent, {
  // 		width: '100%',
  // 		autoFocus: false,
  // 		panelClass: 'task-repeat-overlay-panel',
  // 	   // hasBackdrop: false,
  // 		disableClose: true,
  // 		backdropClass: 'task-repeat-overlay-panel-backdrop',
  //     data: {
  //       id : id,
  //     }
  // 	  });
  // 	// dialogRef.disableClose = true;
  // 	dialogRef.afterClosed().pipe(
  // 	  takeUntil(this._unsubscribeAll)
  // 	).subscribe(res => {

  // 	});
  // }

  roundValue(value) {
    return Math.round(value);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
