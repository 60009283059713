import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-school-admin-create-no-school-stepper',
  templateUrl: './school-admin-create-no-school-stepper.component.html',
  styleUrls: ['./school-admin-create-no-school-stepper.component.scss'],
  providers: [
    {
      provide: CdkStepper,
      useExisting: SchoolAdminCreateNoSchoolStepperComponent,
    },
  ],
})
export class SchoolAdminCreateNoSchoolStepperComponent
  extends CdkStepper
  implements OnChanges
{
  environment = environment;
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Input('stepValue') stepValue: number;
  @Input('translatedText') translatedText: any;

  isBackButton: boolean = true;
  onClick(index: number): void {
    console.log(index);
    this.steps;
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    if (this.selectedIndex > 0) {
      this.isBackButton = false;
    } else {
      this.isBackButton = true;
    }
  }

  nextStep(index: number): void {
    console.log(index);
    this.informParentSelectedStep.emit(index);
  }

  moveBack() {
    if (this.selectedIndex == 0) {
      history.back();
    } else if (this.selectedIndex === 3) {
      this.isBackButton = false;
      // this.lastStep;
    } else {
      this.selectedIndex = this.selectedIndex - 1;
      this.isBackButton = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.stepValue);
    if (changes.stepValue != undefined) {
      if (changes.stepValue.currentValue == 5) {
        this.isBackButton = false;
      }
      if (
        changes.stepValue.currentValue > 0 &&
        changes.stepValue.currentValue < 5
      ) {
        this.isBackButton = false;
      } else {
        this.isBackButton = true;
      }
    }
  }
}
