<ng-container *ngIf="!showError">
  <div class="group-widget-container" *ngFor="let group of groups">
    <div class="row">
      <div class="col-md-6 info-col">
        <div class="group-widget-headline">
          <div class="group-widget-group-name flex-column">
            <mat-icon
              *ngIf="checkStudentsGender(group.group?._students)"
              matTooltip="{{ translatedText.group_widget_personal_data }}"
              svgIcon="streamline_outlined:hint"
            >
            </mat-icon>
            <h4
              class="h4 h4--bold router-link"
              [routerLink]="['/class-detail/' + group.group._id]"
              matTooltip="{{ translatedText.group_widget_group_info }}"
            >
              {{ group.group.name }}
              {{ group.group.school_year | slice : 2 }} ({{ group.group._students?.length }}
              )
            </h4>
            <div *ngIf="showUpdateButton(group.group)">
              <button
                mat-flat-button
                color="primary"
                type="button"
                class="w-330"
                (click)="newSchoolYearOverlay(group.group); $event.stopPropagation()"
              >
                {{ translatedText.admin_newyear_school_group_list_btn }}
              </button>
            </div>
          </div>

          <div class="group-widget-teacher-wrap">
            <div class="table_teacher" *ngFor="let teacher of group?.group?._teacher">
              <div
                class="teacher_with_icon teacher_with_icon--blue"
                matTooltip="{{ translatedText.group_widget_assigned_teacher }}"
              >
                <button mat-mini-fab>
                  <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                </button>
                <p class="paragraph paragraph--s">{{ teacher?.firstname }} {{ teacher?.lastname }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="group-widget-info-container">
          <p class="paragraph paragraph--bold" matTooltip="{{ translatedText.group_widget_current_week_tooltip }}">
            {{ translatedText.group_widget_current_week }}
          </p>
          <div class="group-widget-info-wrap">
            <div class="group-widget-info group-widget-info--active">
              <p class="paragraph paragraph--s" matTooltip="{{ translatedText.group_widget_active_tasks }}">
                {{ translatedText.group_widget_active_tasks_title }}
              </p>
              <div
                class="number router-link"
                [routerLink]="['/dashboard-tasks']"
                [queryParams]="{
                  status: 'active',
                  groupId: group?.group?._id,
                  date: 'current_week'
                }"
              >
                <a class="link">{{
                  group?.taskOverview?.child_tasks_active +
                    group?.taskOverview?.diagnose_pre_tasks_active +
                    group?.taskOverview?.diagnose_mid_tasks_active +
                    group?.taskOverview?.diagnose_post_tasks_active
                }}</a>
                <mat-icon svgIcon="streamline_outlined:arrow-tr"></mat-icon>
              </div>
            </div>
            <div class="group-widget-info group-widget-info--finished">
              <p class="paragraph paragraph--s" matTooltip="{{ translatedText.group_widget_finished_tasks }}">
                {{ translatedText.group_widget_finished_tasks_title }}
              </p>
              <div
                class="number router-link"
                [routerLink]="['/dashboard-tasks']"
                [queryParams]="{
                  status: 'finished',
                  groupId: group?.group?._id,
                  date: 'current_week'
                }"
              >
                <a class="link">{{
                  group?.taskOverview?.child_tasks_finished +
                    group?.taskOverview?.diagnose_pre_tasks_finished +
                    group?.taskOverview?.diagnose_mid_tasks_finished +
                    group?.taskOverview?.diagnose_post_tasks_finished
                }}</a>
                <mat-icon svgIcon="streamline_outlined:arrow-tr"></mat-icon>
              </div>
            </div>
            <div class="group-widget-info group-widget-info--expired">
              <p class="paragraph paragraph--s" matTooltip="{{ translatedText.group_widget_expired_tasks }}">
                {{ translatedText.group_widget_expired_tasks_title }}
              </p>
              <div
                class="number router-link"
                [routerLink]="['/dashboard-tasks']"
                [queryParams]="{
                  status: 'expired',
                  groupId: group?.group?._id,
                  date: 'current_week'
                }"
              >
                <a class="link">{{
                  group?.taskOverview?.child_tasks_expired +
                    group?.taskOverview?.diagnose_pre_tasks_expired +
                    group?.taskOverview?.diagnose_mid_tasks_expired +
                    group?.taskOverview?.diagnose_post_tasks_expired
                }}</a>
                <mat-icon svgIcon="streamline_outlined:arrow-tr"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 statistic-col">
        <div class="teacher_tabs_header_buttons">
          <mat-button-toggle-group
            #activeLicenseViewValue="matButtonToggleGroup"
            [value]="group?.selectedLicenseView"
            (change)="onSelectedLicenseViewChange(group, activeLicenseViewValue.value)"
            [hidden]="licenseCount < 2"
          >
            <mat-button-toggle
              value="license-2"
              [hidden]="!trainingLicenseStatusActive"
              matTooltip="{{ translatedText.group_widget_training_results }}"
            >
              <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>
              {{ translatedText.group_widget_training }}
            </mat-button-toggle>

            <mat-button-toggle
              value="license-1"
              [hidden]="!diagnosticLicenseStatusActive"
              matTooltip="{{ translatedText.group_widget_diagnostic_results }}"
            >
              <mat-icon svgIcon="streamline_outlined:archery"></mat-icon>
              {{ translatedText.group_widget_diagnostic }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div [ngClass]="{ teacher_tabs_charts: licenseCount > 1 }">
          <div [hidden]="group?.selectedLicenseView !== 'license-1'">
            <app-diagnostic-chart
              [chart_data]="group?.group?.chart_data?.diagnostic"
              *ngIf="group?.group?.chart_data?.diagnostic"
            ></app-diagnostic-chart>
            <app-diagnostic-progress></app-diagnostic-progress>
          </div>
          <div [hidden]="group?.selectedLicenseView !== 'license-2'">
            <app-training-chart
              [chart_data]="group?.group?.chart_data?.training"
              *ngIf="group?.group?.chart_data?.training"
            ></app-training-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showError">
  <div class="group-widget-container">
    <div class="row">
      <div class="col-md-6 info-col">
        <p class="paragraph color-red-500">
          {{ translatedText.group_widget_connection_error }}
        </p>
      </div>
      <div class="col-md-6 statistic-col"></div>
    </div>
  </div>
</ng-container>
