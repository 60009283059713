import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { MaterialModule } from 'src/app/material.module';
import { TaskDiagnosticModule } from 'src/app/task/task-diagnostic/task-diagnostic.module';
import { DiagnosticChartComponent } from './diagnostic-chart.component';

@NgModule({
  declarations: [DiagnosticChartComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgxChartsModule,
    TaskDiagnosticModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [DiagnosticChartComponent],
})
export class DiagnosticChartModule {}
