import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-automode-overlay',
  templateUrl: './automode-overlay.component.html',
  styleUrls: ['./automode-overlay.component.scss'],
})
export class AutomodekOverlayComponent implements OnInit, OnDestroy {
  blobUrl: string = '';
  rForm: FormGroup;
  isLoading: boolean = false;
  masterTaskId: string;
  groupName: string;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public resDialog: MatDialogRef<AutomodekOverlayComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['masterTaskId'] !== 'undefined') {
        this.masterTaskId = this.injectedData['masterTaskId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  disableAutoMode() {
    this.taskService
      .updateSingleTask({
        _id: this.masterTaskId,
        status: 'cancelled',
      })
      .subscribe(() => {
        let message = 'Auto Modus erfolgreich abgeschaltet.';
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
        this.resDialog.close('disable');
      });
  }

  closePdfOverlay() {
    this.dialog.closeAll();
  }
}
