import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ClassesUpdateBannerComponent } from './classes-update-banner.component';

@NgModule({
  declarations: [ClassesUpdateBannerComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [ClassesUpdateBannerComponent],
})
export class ClassesUpdateBannerModule {}
