import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { GroupWidgetModule } from 'src/app/group/group-widget/group-widget.module';
import { AppRoutingModule } from '../../app-routing.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { DirectivesModule } from '../../directives/directives.module';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { DiagnosticProgressModule } from '../../helpers/diagnostic-progress/diagnostic-progress.module';
import { LoadingSpinnerModule } from '../../helpers/loading-spinner/loading-spinner.module';
import { ProgressBarModule } from '../../helpers/progress-bar/progress-bar.module';
import { MaterialModule } from '../../material.module';
import { TeacherTaskListModule } from '../../teacher/teacher-task-list/teacher-task-list.module';
import { DiagnosticChartModule } from '../../widget/diagnostic-chart/diagnostic-chart.module';
import { TrainingChartModule } from '../../widget/training-chart/training-chart.module';
import { DashboardTeacherComponent } from './dashboard-teacher.component';
import { LicenseActivationBannerModule } from 'src/app/license/license-activation-banner/license-activation-banner.module';

@NgModule({
  declarations: [DashboardTeacherComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    TeacherTaskListModule,
    LoadingSpinnerModule,
    DirectivesModule,
    DiagnosticProgressModule,
    GroupWidgetModule,
    TrainingChartModule,
    DiagnosticChartModule,
    LicenseActivationBannerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [DashboardTeacherComponent],
})
export class DashboardTeacherModule {}
