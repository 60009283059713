<div class="student-delete_container school-delete_container">
  <button mat-icon-button type="button" class="circle light" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="student-delete_wrap" *ngIf="schoolUuid == undefined">
    <h2 class="h2 student-delete_header">{{ translatedText.school_delete_title }}</h2>
    <p class="paragraph paragraph--xl">{{ translatedText.school_delete_description }}</p>
    <div class="button_wrap">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
        {{ translatedText.school_delete_cancel }}
      </button>
      <button mat-flat-button color="warning" type="button" class="student-delete_button" (click)="deleteSchool()">
        {{ translatedText.school_delete_word }}
      </button>
    </div>
  </div>
  <div class="student-delete_wrap" *ngIf="schoolUuid !== undefined">
    <h2 class="h2 student-delete_header">{{ translatedText.school_delete_warning }}</h2>
    <p class="paragraph paragraph--xl">{{ translatedText.school_delete_warning_text }}.</p>
    <div class="button_wrap">
      <button mat-flat-button color="primary" type="button" [mat-dialog-close]="'close'">OK</button>
    </div>
  </div>
</div>
