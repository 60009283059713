import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from 'src/app/material.module';
import { LicenseActivationBannerComponent } from './license-activation-banner.component';

@NgModule({
  declarations: [LicenseActivationBannerComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    MatIconModule,
    MaterialModule,
    AppRoutingModule,
    LoadingSpinnerModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [LicenseActivationBannerComponent],
})
export class LicenseActivationBannerModule {}
