<div class="diagnostic-progress-container">
  <div class="diagnostic-progress-dot_wrap left">
    <div class="diagnostic-progress-dot"></div>
    <p class="paragraph paragraph--bold paragraph--xs">{{ translatedText.diagnostic_progress_diagnostic_1 }}</p>
  </div>

  <div class="diagnostic-progress-line"></div>

  <div class="diagnostic-progress-dot_wrap center">
    <div class="diagnostic-progress-dot"></div>
    <p class="paragraph paragraph--bold paragraph--xs">{{ translatedText.diagnostic_progress_diagnostic_2 }}</p>
  </div>

  <div class="diagnostic-progress-line"></div>

  <div class="diagnostic-progress-dot_wrap right">
    <div class="diagnostic-progress-dot"></div>
    <p class="paragraph paragraph--bold paragraph--xs">{{ translatedText.diagnostic_progress_diagnostic_3 }}</p>
  </div>
</div>
