<div class="task-start-overlay_container">
  <button
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <img src="../../../assets/start-task.svg" alt="" />
  <h3 class="h3 h3--bold">{{ translatedText.task_start_overlay_title }}</h3>
  <p class="paragraph paragraph--xl">
    {{ translatedText.task_start_overlay_desc }}
  </p>
  <div class="view_button">
    <button
      mat-flat-button
      color="primary-student"
      type="button"
      [mat-dialog-close]="'start'"
    >
      {{ translatedText.task_start_overlay_close }}
    </button>
  </div>
</div>
