import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-teacher-managing-stepper',
  templateUrl: './teacher-managing-stepper.component.html',
  styleUrls: ['./teacher-managing-stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: TeacherManagingStepperComponent },
  ],
})
export class TeacherManagingStepperComponent
  extends CdkStepper
  implements OnChanges
{
  @Output('selectedIndex') informParentSelectedIndex =
    new EventEmitter<number>();
  @Output('step') informParentSelectedStep = new EventEmitter<number>();
  @Input('stepValue') stepValue: number;

  backButton: boolean = true;
  onClick(index: number): void {
    console.log(index);
    this.steps;
    this.selectedIndex = index;
    this.informParentSelectedIndex.emit(this.selectedIndex);
    if (this.selectedIndex > 0) {
      this.backButton = false;
    } else {
      this.backButton = true;
    }
  }

  nextStep(index: number): void {
    console.log(index);
    this.informParentSelectedStep.emit(index);
  }

  moveBack() {
    if (this.selectedIndex == 1) {
      this.backButton = true;
    }
    if (this.selectedIndex == 2) {
      this.selectedIndex = this.selectedIndex - 1;
      this.backButton = true;
    } else {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.stepValue);
    if (changes.stepValue != undefined) {
      if (changes.stepValue.currentValue > 0) {
        this.backButton = false;
      }
      if (changes.stepValue.currentValue > 0) {
        this.backButton = false;
      }
    }
  }
}
