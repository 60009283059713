import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TaskPreviewComponent } from './task-preview.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { RecordRTCModule } from '../../record-rtc/record-rtc.module';
import { BookViewStudentModule } from 'src/app/books/book-view-student/book-view-student.module';
@NgModule({
  declarations: [TaskPreviewComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    RecordRTCModule,
    BookViewStudentModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [TaskPreviewComponent],
})
export class TaskPreviewModule {}
