<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="row">
      <div class="col-md-4 taskCreate_step">
        <div class="taskCreat_step_wrap-left">
          <button
            mat-fab
            type="button"
            [hidden]="backButton"
            (click)="moveBack()"
            class="backButton close_class"
            matSuffix
          >
            <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
            Zurück
          </button>
          <div class="create_class_img">
            <img src="../../../../assets/task_create.svg" alt="" />
          </div>
          <header class="step_header">
            <div class="step" *ngFor="let step of _steps; let i = index" [ngClass]="{ active: selectedIndex === i }">
              <div *ngIf="isBookSelected">
                <div class="step_header_wrap" *ngIf="i === 0" (click)="onClick(i)">
                  <div class="class_step_divider"></div>
                  <div class="row">
                    <div class="col-md-2">
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="!step.completed && selectedIndex != 0"
                        svgIcon="streamline_outlined:state_focused"
                      ></mat-icon>
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="(!step.completed && selectedIndex === 0) || (step.completed && selectedIndex === 0)"
                        svgIcon="streamline_filled:step_point"
                      ></mat-icon>
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="step.completed && selectedIndex != 0"
                        svgIcon="streamline_filled:step_finished"
                      ></mat-icon>
                    </div>
                    <div class="col-md-10">
                      <div class="step_header_content">
                        <p class="paragraph paragraph--bold paragraph--l">Aufgabenart wählen</p>
                        <!-- <p class="paragraph">Weisen Sie die Aufgabe je nach ausgewähltem Aufgabentyp zu</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!isBookSelected">
                <div class="step_header_wrap" *ngIf="i === 1" (click)="onClick(i)">
                  <div class="class_step_divider"></div>
                  <div class="row">
                    <div class="col-md-2">
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="!step.completed && selectedIndex != 1"
                        svgIcon="streamline_outlined:state_focused"
                      ></mat-icon>
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="(!step.completed && selectedIndex === 1) || (step.completed && selectedIndex === 1)"
                        svgIcon="streamline_filled:step_point"
                      ></mat-icon>
                      <mat-icon
                        class="stepper-icon"
                        *ngIf="step.completed && selectedIndex != 1"
                        svgIcon="streamline_filled:step_finished"
                      ></mat-icon>
                    </div>
                    <div class="col-md-10">
                      <div class="step_header_content">
                        <p class="paragraph paragraph--bold paragraph--l">Aufgabenart wählen</p>
                        <!-- <p class="paragraph">Weisen Sie die Aufgabe je nach ausgewähltem Aufgabentyp zu</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="step_header_wrap" *ngIf="i === 2" (click)="onClick(i)">
                <div class="class_step_divider"></div>
                <div class="row">
                  <div class="col-md-2">
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="!step.completed && selectedIndex != 2"
                      svgIcon="streamline_outlined:state_focused"
                    ></mat-icon>
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="selectedIndex == 2 || (step.completed && selectedIndex === 2)"
                      svgIcon="streamline_filled:step_point"
                    ></mat-icon>
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="step.completed && selectedIndex != 2"
                      svgIcon="streamline_filled:step_finished"
                    ></mat-icon>
                  </div>
                  <div class="col-md-10">
                    <div class="step_header_content">
                      <p class="paragraph paragraph--bold paragraph--l">An Lernende verteilen</p>
                      <!-- <p class="paragraph">Wählen Sie die Klassen und Lernenden aus, die die Aufgabe erhalten sollen</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="step_header_wrap" *ngIf="i === 3" (click)="onClick(i)">
                <div class="row">
                  <div class="col-md-2">
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="!step.completed && selectedIndex != 3"
                      svgIcon="streamline_outlined:state_focused"
                    >
                    </mat-icon>
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="selectedIndex == 3 || (step.completed && selectedIndex === 3)"
                      svgIcon="streamline_filled:step_point"
                    ></mat-icon>
                    <mat-icon
                      class="stepper-icon"
                      *ngIf="step.completed && selectedIndex != 3"
                      svgIcon="streamline_filled:step_finished"
                    ></mat-icon>
                  </div>
                  <div class="col-md-10">
                    <div class="step_header_content">
                      <p class="paragraph paragraph--bold paragraph--l">Frist festlegen</p>
                      <!-- <p class="paragraph">Setzen Sie eine Frist für Ihre Aufgabe</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="step_header_wrap" *ngIf="i === 4" (click)="onClick(i)"></div>
            </div>
          </header>
        </div>
      </div>
      <div class="col-md-8 create_class_content create_task_content">
        <div class="create_class_container create_task_container">
          <div class="step_content">
            <div class="create_task_wrap" [style.display]="selected ? 'block' : 'none'">
              <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
