<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">
            Allgemeine Geschäftsbedingungen der <br />Digi Sapiens - Digital Learning GmbH für SaaS und Professional
            Services
          </h1>

          <p>
            Die Firma Digi Sapiens – Digital Learning GmbH, Opernplatz 14, 60313 Frankfurt am Main („Digi Sapiens“)
            gewährt ausschließlich Bildungseinrichtungen, getragen von Unternehmen im Sinne von § 14 BGB oder von
            nicht-rechtsfähigen Anstalten des öffentlichen Rechts, juristischen Personen des öffentlichen Rechts,
            öffentlich-rechtlichen Sondervermögen oder sonstigen öffentlichen Stellen (im Folgenden „Kunde“) aufgrund
            der nachstehenden Allgemeinen Geschäftsbedingungen (im Folgenden: "AGB") die Möglichkeit der Inanspruchnahme
            der nachfolgenden Leistungen, soweit der Anbieter und der Kunde im Einzelfall aufgrund eines Angebots und
            dessen Annahme (im Folgenden: "Einzelvertrag") nicht abweichendes in Textform nach § 126b BGB (im Folgenden:
            "Textform") vereinbaren:
          </p>
          <ul>
            <li>
              Nutzung der von Digi Sapiens entwickelten und betriebenen Plattform „LaLeTu“ (Lautlesetutor), abrufbar auf
              der Website www.laletu.de (im Weiteren: „Plattform“) sowie hierfür von Digi Sapiens oder
              Partnerunternehmen von Digi Sapiens zur Verfügung gestellten bzw. eingebetteten Texte, eBooks und sonstige
              Inhalte (im Weiteren gemeinschaftlich: „Inhalte“) im Rahmen eines Software-as-a-Service (im Folgenden
              „SaaS“) durch den Kunden bzw. durch dessen berechtigte Nutzer zum Zwecke der Unterstützung der
              Lehrtätigkeit. Die Plattform kann je nach Angebot von Digi Sapiens entweder browserbasiert über die
              Website www.laletu.de („Website“) als Webapplikation oder als mobile Applikation („App") genutzt werden.
            </li>
            <li>
              Erbringung von (optionalen) Zusatzdiensten im Zusammenhang mit dem SaaS (wie z.B. Beratungsservices,
              Schnittstellenintegration, Installations- oder Konfigurationsservices) durch den Anbieter (im Folgenden
              „Professional Services“).
            </li>
          </ul>

          <p>SaaS und Professional Services werden nachfolgend insgesamt als „Services“ bezeichnet.</p>
          <h3>1. Definitionen</h3>
          <p>Ergänzend zu den sonstigen Definitionen in diesen AGB gelten folgende Definitionen:</p>
          <ul>
            <li>
              „Drittservices“ bezeichnet diejenigen Professional Services, die laut Dokumentation insgesamt oder in
              Teilen von Sublieferanten der Digi Sapiens erbracht werden.
            </li>
            <li>
              “Dokumentation” bezeichnet die jeweils aktuelle von Digi Sapiens auf der Webseite, als Begleitmaterial
              oder sonst in Textform verfügbar gemachten Beschreibungen der Beschaffenheit, der Eigenschaften und
              Leistungen der Services (wie z.B. Datenblätter, Release-Notes, Handbücher, Online-Dokumentation etc.).
              Umfang, Beschaffenheit und Eigenschaften des SaaS ergeben sich abschließend aus der jeweils aktuellen
              Dokumentation.
            </li>
            <li>
              „Eigensoftware“ umfasst diejenige Software im Objekt Code, die Digi Sapiens selbst entwickelt hat oder
              unter einer eigenen Marke (wie z.B. LaLeTu) als SaaS verfügbar macht inklusive der dazugehörigen
              Dokumentation, jeweils in der aktuellen Version.
            </li>
            <li>
              „Nutzer“ bezeichnet die der Organisation des Kunden zugehörigen und vom Kunden nach Maßgabe des
              Einzelvertrages lizenzierte Anzahl von gemäß den jeweils vorhandenen Nutzungsbedingungen berechtigten
              Nutzer der Services eingeteilt nach Nutzerrolle: Schülerinnen und Schüler, Lehrkräfte und sonstige
              Betreuer (im Weiteren gemeinschaftlich „Lehrkräfte“) sowie den vom Kunden als zentralen Administrator
              eingesetzten Administrator („Administrator“), der die Lehrkräfte und Nutzer verwalten kann.
            </li>
            <li>
              „Kostenfreies Angebot“ bezeichnet entgeltfrei angebotene und dem Kunden zur Verfügung gestellte Services
              (i) im Rahmen einer Testphase, oder (ii) als noch nicht allgemein zugängliche Versionen des SaaS, welche
              Neuerungen, Erweiterungen und sonstige Änderungen enthält, die sich noch im Entwicklungsprozess befindet
              und/oder nicht alle von dem Anbieter vorgesehenen Testprozesse durchlaufen hat ("Beta-Angebot").
              Vorbehaltlich einer abweichenden Regelung durch Digi Sapiens beträgt die Dauer einer dem Kunden
              eingeräumten Testphase 4 Wochen.
            </li>
            <li>
              „Organisation“: bezeichnet die Bildungseinrichtung, die der Kunde vertritt (Schulen und ähnliche
              Einrichtungen).
            </li>
          </ul>

          <h3>2. Abschluss von Einzelverträgen</h3>
          <p>
            <strong>2.1</strong> Allgemeine Geschäfts- oder Einkaufsbedingungen des Kunden gelten gegenüber Digi Sapiens
            nur, soweit Digi Sapiens ihnen ausdrücklich schriftlich zugestimmt hat. Diese AGB gelten auch dann
            ausschließlich, wenn Digi Sapiens die Nutzung in Kenntnis entgegenstehender allgemeiner Geschäfts- oder
            Einkaufsbedingungen des Kunden vorbehaltlos gewährt. Im Fall von Widersprüchen zwischen diesen AGB und in
            den Einzelvertrag mit dem Kunden einbezogenen Nutzungsbedingungen von Digi Sapiens haben diese AGB Vorrang,
            soweit in diesen AGB nichts abweichendes geregelt wird.<br /><br />
          </p>
          <p>
            <strong>2.2</strong> Angebote von Digi Sapiens sind unverbindlich, es sei denn, das Angebot sieht
            ausdrücklich anderes vor.<br /><br />
          </p>
          <p>
            <strong>2.3</strong> Angebote und Annahmen bedürfen zu ihrer Wirksamkeit der Textform. Auch Neben- und
            Zusatzabreden zu einem Einzelvertrag, Beschaffenheits- und Eigenschaftsangaben und Vereinbarungen, die
            jeweils vor, bei oder nach Abschluss eines Einzelvertrages abgegeben bzw. getroffen werden, bedürfen zu
            ihrer Wirksamkeit der Textform sowie einer ausdrücklichen Bezugnahme auf den betreffenden
            Einzelvertrag.<br /><br />
          </p>
          <p>
            <strong>2.4</strong> Bietet Digi Sapiens dem Kunden in einem Angebot mehrere/unterschiedliche Leistungen
            (z.B. SaaS, Beratungsservices etc.) sowie Preise an, welche den jeweiligen Leistungen zugeordnet werden
            können („Einzelpreise“), liegt für jede dieser Leistungen ein rechtlich selbständiger individueller
            Einzelvertrag vor, es sei denn, dem Angebot bzw. Einzelvertrag ist ausdrücklich zu entnehmen, dass Digi
            Sapiens einen Einzelvertrag über die Gesamtheit aller Leistungen anbieten will. Wird im Angebot von Digi
            Sapiens bzw. im Einzelvertrag neben Einzelpreisen ein Gesamtpreis für mehrere Leistungen ausgewiesen, genügt
            dies alleine nicht für die Annahme eines Einzelvertrages über die Gesamtheit aller Leistungen.<br /><br />
          </p>

          <h3>3. Umfang und Gegenstand der Services</h3>
          <p>
            <strong>3.1</strong> Umfang, Beschaffenheit und Eigenschaften der Services ergeben sich abschließend aus
            diesen AGB und der jeweils aktuellen Dokumentation, soweit nichts Abweichendes oder Zusätzliches im
            Einzelvertrag in Textform vereinbart ist. Sämtliche Services werden am Standort von Digi Sapiens (remote)
            erbracht.<br /><br />
          </p>
          <p>
            <strong>3.2</strong> Beschaffenheits- und Eigenschaftsbeschreibungen der Services sind nicht als Zusicherung
            bestimmter Eigenschaften oder als Garantie zu verstehen. Zusätzliche Vereinbarungen zu den Services im
            Einzelvertrag sind nur dann als Eigenschaftszusicherungen oder Garantien von Digi Sapiens zu verstehen, wenn
            diese in Textform durch die Geschäftsleitung von Digi Sapiens erfolgen und ausdrücklich als „Zusicherung“
            bzw. „Garantie“ gekennzeichnet sind.<br /><br />
          </p>
          <p>
            <strong>3.3</strong> Der Kunde wird sich vor Abschluss des Einzelvertrages über die Eignung der Services für
            die von ihm konkret geplante Nutzung und ihre technischen Anforderungen (z.B. in Bezug auf
            Datenbankanbindung, Hardware, Integrationsfähigkeit in seine IT-Umgebung) entweder selbst informieren oder
            wird bei Digi Sapiens Professional Services in Form von Beratungsservices in Anspruch nehmen.<br /><br />
          </p>
          <p>
            <strong>3.4</strong> 3.4 Der Kunde erkennt an, dass die Verfügbarkeit des SaaS – vorbehaltlich ausreichenden
            Guthabens des Kunden im Sinne von Ziff. 7.1 - beschränkt ist auf die in der jeweiligen Dokumentation
            angegebene oder im Einzelvertrag vereinbarte Service-Verfügbarkeit. Fehlt eine solche Angabe oder
            Vereinbarung beträgt die Service-Verfügbarkeit höchsten 97,0 % im Schuljahresmittel (190 Tage / 12h zwischen
            07.00 Uhr und 19.00 Uhr) ab erstmaliger Nutzbarkeit des Service. Die Verfügbarkeit bezieht sich
            ausschließlich auf diejenige am Übergabepunkt des Digi Sapiens-Servers zum Internet. Beeinträchtigungen im
            Bereich der Datenübertragung von diesem Übergabepunkt zum Kunden und/oder im Bereich der IT-Anlage des
            Kunden oder von Dritten (Internet, etc.) selbst liegen nicht im Verantwortungsbereich von Digi Sapiens.
            Ausgenommen von dieser Service-Verfügbarkeit sind (a) geplante Wartungsfenster zum Zweck der Wartung, Pflege
            oder der Datensicherung, sofern diese dem Kunden zumindest in Textform mindestens 24 Stunden zuvor
            angekündigt wurden sowie (b) Nicht-Verfügbarkeiten, die der Kunde im Rahmen des Supports nach Ziff. 8 nicht
            als Ticket platziert hat, sowie (c) Nichtverfügbarkeit der Plattform aus Gründen der höheren Gewalt. Unter
            höherer Gewalt fallen insbesondere Verbindungsprobleme mit dem Internet, die außerhalb der Kontrolle bzw.
            dem Verantwortungsbereich von Digi Sapiens liegen, Denial of Service Attacken, sowie u.a. Streiks,
            Bürgerkriege, Hackerattacken oder Ausfälle von Internetserviceprovidern. Digi Sapiens wird sich bemühen,
            Wartungsarbeiten außerhalb der üblichen Geschäftszeiten in Deutschland (Montag bis Freitag zwischen 07:00
            Uhr und 20:00 Uhr, ausgenommen gesetzliche Feiertage) durchzuführen. <br /><br />
          </p>
          <p>
            <strong>3.5</strong> Bei Nutzung des SaaS ohne Guthaben wird das SaaS dem Kunden so zur Verfügung gestellt,
            wie es im Internet abrufbar ist. Digi Sapiens übernimmt bei Nutzung des SaaS ohne Guthaben keine Gewähr für
            eine bestimmte Beschaffenheit oder Verfügbarkeit des SaaS. Bei Nutzung des SaaS ohne Guthaben ist Digi
            Sapiens berechtigt, die Funktionalitäten des SaaS nach eigenem Ermessen einzuschränken.<br /><br />
          </p>
          <p>
            <strong>3.6</strong> Soweit Digi Sapiens die Lieferung von Software schuldet (wie z.B. einer für das SaaS
            erforderlichen Client-Software oder Apps für mobile Endgeräte), erfolgt nur die Lieferung eines
            maschinenlesbaren Objektcodes. Digi Sapiens schuldet im Zusammenhang mit den Services unter keinen Umständen
            die Lieferung oder Herausgabe von Software im Quellcode.<br /><br />
          </p>
          <p>
            <strong>3.7</strong> (Optionale) Professional Services schuldet Digi Sapiens nur, soweit diese im
            Einzelvertrag vom Kunden ausdrücklich beauftragt wurden. Professional Services sind Dienstleistungen, bei
            denen Digi Sapiens die Erbringung einer vereinbarten Leistung schuldet, nicht hingegen die Herbeiführung
            eines bestimmten Erfolgs oder Ergebnisses. Digi Sapiens wird diese Professional Services nach Ziff. 4 mit
            der Sorgfalt eines ordentlichen Kaufmanns unter Verwendung von Technologien und Erkenntnissen, die den
            allgemein anerkannten Regeln der Technik entsprechen, erbringen.<br /><br />
          </p>
          <p>
            <strong>3.8</strong> Im Einzelvertrag genannte Fristen oder Leistungstermine sind für Digi Sapiens reine
            Plantermine und unverbindlich, es sei denn, sie werden im Einzelvertrag ausdrücklich und in Textform als
            verbindliche Termine vereinbart. Die Einhaltung von festen Fristen und Leistungsterminen durch Digi Sapiens
            setzt die rechtzeitige Vornahme aller vereinbarten oder von Digi Sapiens angeforderten Mitwirkungshandlungen
            des Kunden voraus. Werden diese Voraussetzungen vom Kunden nicht rechtzeitig erfüllt, so verschieben sich
            die festen Leistungstermine entsprechend. Im Falle leichter Fahrlässigkeit ist ein Anspruch des Kunden gegen
            Digi Sapiens auf Schadenersatz wegen Leistungsverzuges bzw. Nichtleistung begrenzt auf die Höhe des
            vorhersehbaren Schadens, maximal jedoch auf 10 % der vom Verzug bzw. der Nichtleistung betroffenen
            Service-Gebühr.<br /><br />
          </p>
          <p>
            <strong>3.9</strong> Digi Sapiens ist berechtigt, im Rahmen der Services Drittunternehmen (z.B. im Rahmen
            des SaaS einen Rechenzentrumsbetreiber, im Rahmen der Professional Services Sublieferanten) einzubinden,
            sofern Digi Sapiens sicherstellt, dass diese den Regelungen der Ziff. 12 entsprechende Pflichten gegenüber
            Digi Sapiens erfüllen. Digi Sapiens steht für diese Dritten in dem gleichen Umfang ein wie für eigenes
            Verhalten nach Maßgabe dieser AGB bzw. des Einzelvertrages. Ein Wechsel des Drittunternehmens bedarf der
            vorherigen Zustimmung des Kunden in Textform, die der Kunde nicht treuwidrig verweigern wird, insbesondere
            wenn Digi Sapiens die Verpflichtung des Dritten entsprechend Ziff. 12 nachweist. Die Zustimmung gilt als
            erteilt, wenn der Kunde sich nicht innerhalb von vier Wochen nach Erhalt einer entsprechenden Aufforderung
            durch Digi Sapiens erklärt.<br /><br />
          </p>

          <h3>4. Durchführung von Professional Services</h3>
          <p>
            <strong>4.1</strong> Einzelverträge über Professional Services sind Dienst¬verträge gemäß §§ 611 ff. BGB,
            sofern nicht in der Leistungsbeschreibung oder im Einzelvertrag ausdrücklich etwas anderes, insbesondere die
            Geltung werkvertraglicher Bestimmungen (wie z.B. eine Abnahme durch den Kunden oder eine Gewährleistung
            seitens Digi Sapiens) vereinbart ist. Gegenstand eines Einzelvertrags über Professional Services ist daher
            grundsätzlich die Erbringung der vereinbarten Leistung durch Digi Sapiens, nicht hingegen die Herbeiführung
            eines bestimmten Erfolgs, nicht die Lieferung einer bestimmten technischen Lösung oder eines
            funktionstüchtigen Werks.<br /><br />
          </p>
          <p>
            <strong>4.2</strong> Vereinbaren die Parteien im Einzelvertrag ausdrücklich eine von Digi Sapiens zu
            erbringende Werkleistung, werden die Parteien sowohl die Kriterien für die Abnahme durch den Kunden (wie
            z.B. Lastenheft, Spezifikation etc.) als auch die im Rahmen der Abnahme einzusetzenden Tests und Verfahren
            im Einzelvertrag jeweils abschließend vereinbaren. Fehlen im Einzelvertrag ganz oder teilweise solche
            Angaben zu den Abnahmekriterien, Tests oder Testverfahren oder sind diese unzureichend, ist Digi Sapiens
            berechtigt, den Kunden entweder in Textform aufzufordern, diese unverzüglich beizubringen bzw. zu
            vervollständigen, oder diese nach billigem Ermessen selbst festzulegen und den Kunden hierüber in Textform
            zu unterrichten. Stellt der Kunde die Abnahmekriterien, Tests oder Verfahren bei, behält sich Digi Sapiens
            das Recht vor, zumutbare Änderungen an diesen vorzunehmen, insbesondere solche Änderungen, die gesetzlich
            erforderlich sind oder erforderlich sind, um die Qualität oder Leistungsfähigkeit der weiteren
            einzelvertraglich beauftragten Leistungen von Digi Sapiens aufrechtzuerhalten. Auch hierüber wird Digi
            Sapiens den Kunden in Textform unterrichten.<br /><br />
          </p>
          <p>
            <strong>4.3</strong> Der Kunde wird die von Digi Sapiens erbrachten Werkleistungen auf Kosten des Kunden
            unverzüglich, jedenfalls innerhalb von fünf (5) Werktagen, nach Lieferung bzw. Anzeige der
            Abnahmebereitschaft einer Abnahme unterziehen. Der Kunde wird die Abnahme erklären, wenn die nach Ziff. 4.2
            relevanten Abnahmekriterien unter Einsatz der nach Ziff. 4.2 geltenden Tests und Testverfahren im
            Wesentlichen erfüllt sind. Der Kunde wird Digi Sapiens während der Abnahme auftretende Mängel unverzüglich
            mitteilen. Zeigt der Kunde abnahmehindernde Mängel nicht oder nicht unverzüglich an, gilt die von Digi
            Sapiens erbrachte Werkleistung als abgenommen. Dieselbe Rechtsfolge ergibt sich auch im Falle einer
            produktiven Nutzung der Werkleistung oder von Bestandteilen derselben durch den Kunden. Vom Kunden
            ordnungsgemäß angezeigte abnahmehindernde Mängel wird Digi Sapiens innerhalb angemessener Frist beseitigen
            und dem Kunden zur nochmaligen Abnahme vorlegen. Digi Sapiens hat pro Mangel Anspruch auf mindestens zwei
            weitere Abnahmen durch den Kunden auf dessen Kosten. Scheitert die Abnahme endgültig, steht dem Kunden ein
            Recht zur außerordentlichen Kündigung zu. Das Recht des Kunden zum Rücktritt ist ausgeschlossen, es sei
            denn, Digi Sapiens handelt grob fahrlässig oder vorsätzlich.<br /><br />
          </p>
          <p>
            <strong>4.4</strong> Digi Sapiens bleibt Inhaber an allen Ergebnissen, die bei der Durchführung der
            Professional Services durch Digi Sapiens geschaffen werden (z.B. Softwareentwicklung oder –customizing).
            Nach vollständiger Zahlung der Professional Services erwirbt der Kunde nur das nicht-ausschließliche, nicht
            übertragbare und nicht unterlizenzierbare Recht, diese Ergebnisse für die eigenen internen Zwecke des Kunden
            zusammen mit den anderen einzelvertraglich bei Digi Sapiens beauftragten Leistungen zu
            verwenden.<br /><br />
          </p>
          <p>
            <strong>4.5</strong> Im Rahmen von Professional Services wird der Kunde die im Einzelvertrag, in der
            Leistungsbeschreibung sowie die in dieser Ziffn. 4.1 bis. 4.4 beschriebene Leistung und Mitwirkung mit der
            notwendigen Qualität, vollständig und termingerecht erbringen.<br /><br />
          </p>
          <p>
            <strong>4.6</strong> Soweit dies für die Erbringung der Professional Services erforderlich ist, wird der
            Kunde Digi Sapiens (i) Zugang gewähren zu Kunden-Systemen, entweder in Form eines Zugangs vor Ort oder eines
            Remote-Zugangs, sowie (ii) alle notwendigen Informationen und Daten zur Verfügung stellen (bei
            Werkleistungen insbesondere auch die Abnahmekriterien, Tests und Testverfahren).<br /><br />
          </p>
          <p>
            <strong>4.7</strong> Der Kunde wird auf eigene Kosten und in eigener Verantwortung alle für die Erbringung
            der Professional Services notwendigen Einrichtungen (z.B. erforderliche Hard- und Software) zur Verfügung
            stellen und diese so betreiben, dass die Erbringung der Professional Services nicht beeinträchtigt oder
            behindert wird. Über geplante Änderungen an seinen Einrichtungen und Systemen, die sich auf die Professional
            Services auswirken oder auswirken könnten, wird der Kunde Digi Sapiens vorab in Textform
            informieren.<br /><br />
          </p>
          <p>
            <strong>4.8</strong> Der Kunde wird etwaige Leistungen und Mitwirkungspflichten nach dieser Ziff. 4
            unaufgefordert erbringen, sobald diese zur Erbringung der Professional Services erforderlich sind.
            Jedenfalls wird der Kunde diese Leistungen und Mitwirkungspflichten zu den in der Leistungsbeschreibung und
            im Einzelvertrag genannten Zeitpunkten oder unverzüglich nach Anforderung durch Digi Sapiens erbringen,
            wobei eine solche Anforderung in Textform oder mündlich erfolgen kann. Kommt der Kunde mit seinen Leistungen
            und Mitwirkungspflichten in Verzug, wird die für Professional Services vereinbarter Vergütung – abweichend
            von Ziff. 7 - sofort fällig und verschieben sich Fristen und Leistungstermine, für deren Einhaltung Digi
            Sapiens verantwortlich ist, entsprechend um die Dauer der Verzögerung.<br /><br />
          </p>
          <p>
            <strong>4.9</strong> Verschiebt oder storniert der Kunde vereinbarte Termine aus Gründen, die der Kunde zu
            vertreten hat, ist Digi Sapiens berechtigt, den folgenden Pauschalen in Rechnung zu stellen: Bei einer vom
            Kunden veranlassten Verschiebung oder Stornierung
          </p>
          <ul>
            <li>
              10 bis 6 Arbeitstage vor dem vereinbarten Termin in Höhe von 33 % der für diesen Termin veranschlagten
              Vergütung;
            </li>
            <li>
              5 bis 2 Arbeitstage vor dem vereinbarten Termin in Höhe von 66 % der für diesen Termin veranschlagten
              Vergütung; und
            </li>
            <li>
              1 Arbeitstag vor oder am vereinbarten Termin in Höhe von 90 % der für diesen Termin veranschlagten
              Vergütung.
            </li>
          </ul>

          <p>
            <strong>4.10</strong> Das Nichterscheinen des Kunden zum Termin gilt als Stornierung des Termins.
            Weitergehende Rechte und Ansprüche von Digi Sapiens bleiben unberührt.<br /><br />
          </p>
          <p>
            <strong>4.11</strong> Der Kunde hat Digi Sapiens diejenige Arbeits- und Vorhaltezeit nach Maßgabe der
            Regelung der Ziff. 7.1 zu vergüten, die von Digi Sapiens infolge einer Verletzung einer Leistungs- oder
            Mitwirkungspflicht des Kunden aufwendet, es sei denn, den Kunden trifft kein Verschulden oder Digi Sapiens
            ist es zumutbar, die Vorhaltezeit als Arbeitszeit für die Durchführung von Leistungen für andere Kunden
            einzusetzen. Weitergehende Rechte und Ansprüche von Digi Sapiens bleiben unberührt.<br /><br />
          </p>

          <h3>5. Nutzungsrechte des Kunden</h3>
          <p>
            <strong>5.1</strong> Die nicht ausschließlichen Nutzungsrechte des Kunden an der Plattform ergeben sich aus
            den anwendbaren Nutzungs-/Lizenzbedingungen oder End Use License Agreement ("EULA"), die dem Kunden bei
            Abschluss des Einzelvertrages als Bestandteil der Leistungsbeschreibung oder spätestens im Rahmen der
            Registrierung und Einrichtung des Benutzeraccounts zugänglich gemacht wird. Für auf der Plattform ggfs.
            eingebetteten Inhalten von Dritten gelten die von diesen vorgegebenen Nutzungs-/Lizenzbedingungen oder
            EULAs.<br /><br />
          </p>
          <p>
            <strong>5.2</strong> Bei Abwesenheit solcher Nutzungsbedingungen oder eines solchen EULA erwirbt der Kunde -
            vorbehaltlich des Abschlusses des entsprechenden Einzelvertrages sowie der Einhaltung dieser AGB durch den
            Kunden und gegen Zahlung der einzelvertraglich vereinbarten Service-Gebühren - ein zeitlich auf die Laufzeit
            des Einzelvertrages befristetes, eingeschränktes, nicht-ausschließliches, nicht übertragbares Recht zur
            Nutzung der Plattform (d.h. der als Service verfügbar gemachten Eigensoftware von Digi Sapiens) durch die
            bezahlte Anzahl von Nutzern für die spezifische im Einzelvertrag identifizierte Organisation, soweit im
            Einzelvertrag nichts Abweichendes geregelt wurde. Dem Kunden ist es entsprechend der Anzahl seiner Lizenzen
            gestattet, für die Nutzer Nutzeraccounts (für Lernende, Lernendenaccounts und für Lehrkräfte Lehreraccounts)
            zu erstellen und den Lernenden dadurch Zugang auf die Plattform zu gewähren. Die Nutzung der Plattform und
            der Inhalte nach dieser Ziff. 4.2 ist beschränkt auf die Unterstützung des Lehrbetriebs der Organisation des
            Kunden bzw. der Teilnahme am Lehrbetrieb durch die Nutzungsberechtigten. Der Kunde darf die Plattform nur im
            Zusammenhang mit der schulischen Förderung der Lernenden, denen der Kunde einen Lernendenaccount erstellt
            hat, nutzen. Lernenden darf die Nutzung der Plattform wiederum nur gewährt werden, um am Lehrbetrieb des
            Kunden teilzunehmen, insbesondere um die von der Lehrkraft aufgetragenen Aufgabenstellungen in der Plattform
            zu erledigen. Jede Nutzung zum Zwecke der Unterstützung des Lehr- oder Geschäftsbetriebs von Dritten oder zu
            anderen Zwecken bedarf einer gesonderten einzelvertraglichen Vereinbarung. Das eingeschränkte Nutzungsrecht
            gilt auch für die von Digi Sapiens verfügbar gemachten Inhalte und die Dokumentation. Der Kunde darf ohne
            vorherige Zustimmung seitens Digi Sapiens in Textform weder Unterlizenzen erteilen, soweit nicht
            ausdrücklich durch diese Ziff. 4 eingeräumt, noch die Plattform, die Inhalte oder die
            Dokumentation<br /><br />
          </p>
          <ul>
            <li>manipulieren oder verändern;</li>
            <li>
              an Dritte untervermieten, verleihen oder im Rahmen von Dienstleistungen oder in sonstiger Weise zum
              vorübergehenden Gebrauch überlassen oder für Zwecke Dritter benutzen oder Dritte benutzen lassen;
            </li>
            <li>
              vervielfältigen, verbreiten, zurückentwickeln, disassemblieren, dekompilieren (sog. reverse engineering),
              den Source-Code der Plattform ermitteln (soweit zwingendes Recht nicht Abweichendes gestattet, §§ 69d Abs.
              2, Abs. 3 und 69e UrhG bleiben unberührt);
            </li>
            <li>kopieren, es sei denn, dies ist zu angemessenen Backup Zwecken erforderlich.</li>
          </ul>
          <p>
            Das eingeschränkte Nutzungsrecht nach den vorstehenden Bestimmungen gilt auch für die Inhalte, Dokumentation
            (insbesondere Online-Dokumentation, Handbücher) und Releases, die Digi Sapiens unter dem betreffenden
            Einzelauftrag verfügbar macht.<br /><br />
          </p>
          <p>
            <strong>5.3</strong> Die Einhaltung der Nutzungsbedingungen kann von Digi Sapiens durch technische
            Vorrichtungen (z.B. Autorisierungs- und Freischaltcodes) und Berechtigungsvergaben abgesichert bzw.
            überwacht werden.<br /><br />
          </p>
          <p>
            <strong>5.4</strong> Neben den von Digi Sapiens nach dieser Ziffer 5 eingeräumten nicht-exklusiven
            Nutzungsrechten erhält oder erwirbt der Kunde keine weiteren Rechte an den Services, auch wenn der Kunde
            oder seine berechtigten Nutzer zeitweise technisch auf zusätzliche Funktionen zugreifen können. Sämtliche
            die Services betreffenden Immaterialgüterrechte (wie z.B. Urheberrechte, Patentrechte) stehen ausschließlich
            Digi Sapiens und/oder deren Lizenzgebern zu. Dem Kunden ist bekannt, dass die Eigensoftware über den
            vorstehenden Schutz hinaus auch vertrauliche Informationen von Digi Sapiens im Sinne von Ziff. 12 enthält,
            die vom Kunden und den Nutzern nach Maßgabe dieser Ziff. 12 zu behandeln sind.<br /><br />
          </p>

          <h3>6. Pflichten des Kunden</h3>
          <p>
            <strong>6.1</strong> Die an die Kundenumgebung gestellten Hardware- und Systemanforderungen für die Nutzung
            der Services sind in der Dokumentation beschrieben. Die für die Services notwendige Kundenumgebung ist durch
            den Kunden auf eigene Kosten rechtzeitig zu beschaffen, vor Beginn des SaaS zu installieren, für die Dauer
            des SaaS zu betreiben und zu pflegen. Digi Sapiens unterstützt den Kunden auf dessen Wunsch im Rahmen der
            Herstellung der Kundenumgebung mit Beratungsservices auf Grundlage eines Einzelvertrages über Professional
            Services nach Maßgabe dieser AGB.<br /><br />
          </p>
          <p>
            <strong>6.2</strong> Der Kunde hat dafür Sorge zu tragen, dass die Nutzer die jeweils geltenden
            Nutzungsbedingungen einhalten, und alle gegebenenfalls erforderlichen datenschutzrechtlichen Einwilligungen
            erteilen. Der Kunde wird ferner sicherstellen, dass die von ihm zu Nutzung berechtigten Lehrkräfte den
            Lernenden bei Mitteilung der Zugangsdaten zum Lernendenaccount die zulässige Nutzung der Plattform
            erläutern. Ebenso wird der Kunde sicherstellen, dass die gesetzlichen Vertreter der Lernenden über die
            jeweils geltenden Nutzungsbedingungen, insbesondere den Umfang des Nutzungsrechts sowie der entsprechend
            geltenden Nutzungsbeschränkungen aufzuklären und über die Datenschutzhinweise informiert werden. Sollten
            gesetzliche Vertreter eines Lernenden der Nutzung der Plattform durch den Lernenden in zulässiger Weise
            widersprechen, hat der Kunde dafür zu sorgen, dass der Zugang des betroffenen Lernenden zur Plattform
            beendet und der Lernendenaccount gelöscht wird. Sollte Digi Sapiens infolge einer vertragswidrigen Nutzung
            des Kunden oder seiner Nutzer von Dritten in Anspruch genommen werden, wird der Kunde Digi Sapiens von
            diesen Ansprüchen auf erstes Anfordern freistellen. Der Kunde haftet für die Einhaltung von
            Nutzungsbedingungen durch seine Nutzer.<br /><br />
          </p>
          <p><strong>6.3</strong> Der Kunde stellt sicher, dass:</p>
          <ul>
            <li>
              er Digi Sapiens Fernzugriff und Zugriff auf die Kundenumgebung und sonstige Systeme des Kunden vor Ort
              gewährt, soweit dies für die Nutzung der Services und für Zwecke der Gewährleistung und Supports notwendig
              ist;
            </li>
            <li>
              er nur qualifiziertes und geschultes Personal einsetzt, das ausreichend Erfahrung für die Nutzung und
              Entgegennahme von Services besitzt;
            </li>
            <li>er den von ihm berechtigten Nutzer in die Nutzung und Bedienung der Plattform einweist;</li>
            <li>
              Digi Sapiens vorab in Textform über jede für die Nutzung der Services relevante Änderung der
              Kundenumgebung informiert wird;
            </li>
            <li>
              der Administrator sowie die zur Nutzung berechtigten Lehrkräfte die Vertraulichkeit des Lehreraccounts und
              des Passworts und die Beschränkung des Zugangs zum Endgerät, über das die Plattform genutzt wird,
              sicherstellen;
            </li>
            <li>
              die jeweiligen Lehrkräfte alle erforderlichen Schritte unternehmen, um sicherzustellen, dass ihre
              jeweiligen Passwörter geheim gehalten und sicher aufbewahrt werden;
            </li>
            <li>
              Digi Sapiens unverzüglich informiert wird, wenn Anlass zur Besorgnis besteht, dass (i) ein unbefugter
              Dritter Kenntnis von einem Passwort des Administratoraccounts oder eines Lehreraccounts erlangt hat oder
              ein Passwort bzw. der Administratoraccount oder ein Lehreraccount unautorisiert genutzt wird oder dies
              wahrscheinlich ist, oder dass (ii) ein Dritter Kenntnis von dem Passwort des Administratoraccounts oder
              eines Lernendenaccounts erlangt hat oder das Passwort bzw. Administratoraccount oder ein Lernendenaccount
              unautorisiert genutzt wird oder dies wahrscheinlich ist;
            </li>
            <li>
              er alle für die Durchführung der Services erforderlichen und von Digi Sapiens angeforderten Informationen
              und Daten bereitstellt;
            </li>
            <li>
              er alle notwendigen Freigaben und Abnahmen innerhalb der vereinbarten Fristen bzw. unverzüglich erklärt;
            </li>
            <li>er alle anwendbaren Gesetze und Regelungen im Rahmen der Durchführung dieses Vertrages einhält.</li>
          </ul>
          <p>
            <strong>6.4</strong> Der Kunde trägt Nachteile und Mehrkosten aus einer Verletzung seiner Pflichten,
            insbesondere auch etwaige Vorhaltekosten von Digi Sapiens (Kosten für das Vorhalten von Personal zu den in
            Ziff. 7.1 aufgeführten Stundensätzen).<br /><br />
          </p>

          <h3>7. Service-Gebühren</h3>

          <p>
            <strong>7.1</strong> Die vom Kunden für SaaS zu zahlenden Gebühren werden in Form von durch den Kunden zu
            erwerbenden Zeitguthaben zur Nutzung des SaaS und bestimmter Funktionalitäten nach Maßgabe des vom Kunden
            gewählten Lizenzpakets („Guthaben“) im Voraus erhoben und ergeben sich aus dem jeweils vom Kunden im
            betreffenden Einzelvertrag gewählten Lizenzpaket. Bei jeder Beendigung des betreffenden Einzelvertrages
            nicht eingelöstes bzw. verbrauchtes Guthaben des Kunden verfällt. Während der Laufzeit des Einzelvertrages
            nicht eingelöstes bzw. verbrauchtes Guthaben verfällt binnen 36 Monaten nach Kauf.<br /><br />
          </p>

          <p>
            <strong>7.2</strong> Die vom Kunden für Professional Services wie Beratungsservices und sonstige
            Aufwandsleistungen zu zahlenden Stundensätze ergeben sich aus dem betreffenden Einzelvertrag. Fehlt im
            Einzelvertrag eine Stundensatzvereinbarung, werden Professional Services wie folgt abgerechnet:
          </p>
          <ul>
            <li>Stundensatz: 160,00 Euro</li>
            <li>Reisezeit gilt zu 50 % als Arbeitszeit</li>
            <li>zzgl. angefallenen Spesen und Reisekosten.</li>
          </ul>

          <p>
            <strong>7.3</strong> Sämtliche Service-Gebühren (Guthaben für SaaS, Stundensätze für Professional Services)
            sind ohne Abzug auf das folgende Konto von Digi Sapiens zu überweisen, es sei denn, die Parteien vereinbaren
            im Einzelvertrag eine Zahlung per SEPA-Firmenlastschriftverfahren:<br /><br />
            Empfänger: Digi Sapiens – Digital Learning GmbH<br />
            Bankverbindung: Frankfurter Volksbank eG<br />
            IBAN: DE54 5019 0000 6500<br /><br />
          </p>

          <p>
            <strong>7.4</strong> Sofern nichts anderweitig im Einzelvertrag in Textform vereinbart, sind Professional
            Service-Gebühren monatlich im Nachgang entsprechend ihrer Nutzung bzw. Inanspruchnahme im Abrechnungsmonat
            zu zahlen. Fälligkeit tritt stets 14 Tage nach Datum einer ordnungsgemäßen Rechnung ein. Der
            Rechnungsversand erfolgt ausschließlich online an die vom Kunden dafür angegebene E-Mail-Adresse, auf Zugang
            einer Rechnung in Papierform verzichtet der Kunde.<br /><br />
          </p>

          <p>
            <strong>7.5</strong> Alle Angaben in diesen AGB oder in Einzelverträgen zu Service-Gebühren verstehen sich
            exklusive Steuern und sonstigen Abgaben (netto).<br /><br />
          </p>

          <p>
            <strong>7.6</strong> Der Kunde sorgt für rechtzeitige Zahlung ohne Abzüge auf die von Digi Sapiens hierfür
            benannte Konto. Rechtzeitig bedeutet den vollständigen Eingang der fälligen Zahlung auf von Digi Sapiens
            benanntem Konto.<br /><br />
          </p>

          <p>
            <strong>7.7</strong> Der Kunde kann nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen
            aufrechnen und ein Zurückbehaltungsrecht nur auf unbestrittene oder rechtskräftig festgestellte Ansprüche
            stützen.<br /><br />
          </p>

          <p>
            <strong>7.8</strong> Digi Sapiens ist berechtigt, die Services einzuschränken und/oder einzustellen, sollte
            der Kunde trotz einer in Textform und nach Fälligkeit erfolgenden Mahnung, in der ein Zahlungsziel von
            mindestens weiteren 10 Tagen gesetzt wird, keine Zahlung leisten. Digi Sapiens wird in der Mahnung auf die
            drohenden Folgen einer Säumnis hinweisen.<br /><br />
          </p>

          <h3>8. Gewährleistung und Support</h3>

          <p>
            <strong>8.1</strong> Digi Sapiens gewährleistet im Rahmen der Service-Verfügbarkeit nach Ziff. 3.4 bei
            Nutzung des SaaS mit vorhandenem Guthaben ausschließlich, dass der jeweils aktuell nutzbare SaaS, den im
            Einzelvertrag vereinbarten und den in der jeweils aktuellen Dokumentation angegebenen Beschaffenheits- und
            Eigenschaftenangaben entspricht. Digi Sapiens ist berechtigt, den SaaS fortzuentwickeln und den künftigen
            Marktanforderungen durch entsprechende Releases anzupassen. Sind im Einzelvertrag eine spezifische
            Beschaffenheit und spezifische Eigenschaften des SaaS vereinbart, sind Abweichungen hiervon nur zulässig,
            sofern diese entweder unwesentlich sind (z.B. Anpassung des Oberflächendesigns) oder technisch oder
            rechtlich zwingend vorgegeben werden (z.B. neues Datenformat oder Gesetzesänderung wie z.B. zum
            Datenschutz). Für Professional Services und kostenfreie Angebote wird keine Gewährleistung
            gewährt.<br /><br />
          </p>

          <p>
            <strong>8.2</strong> Digi Sapiens ist bei Nutzung der Plattform mit vorhandenem Guthaben verpflichtet,
            bestehende Mängel des SaaS auf Kosten von Digi Sapiens im Wege des nachfolgend beschriebenen Supports zu
            beseitigen. Weitergehende gesetzliche Ansprüche des Kunden bestehen nur bei
          </p>
          <ul>
            <li>erheblichen Mängeln und sofern Digi Sapiens einen solchen erheblichen Mangel grob fahrlässig oder
              vorsätzlich verursacht hat, oder die Beseitigung eines solchen erheblichen Mangels im Wege des Supports
              mindestens dreimal fehlgeschlagen ist;</li>
            <li>arglistigem Verschweigen des Mangels durch Digi Sapiens oder</li>
            <li>bei Personenschäden infolge des Mangels.</li>
          </ul>
          <p>Bei Verletzung von Schutzrechten Dritter durch die Services gilt vorrangig und wiederum abschließend Ziff.
            9.<br /><br /></p>

          <p><strong>8.3</strong> Der Kunde wird vor Inanspruchnahme des Supports von Digi Sapiens versuchen, den
            aufgetretenen Mangel des SaaS einzugrenzen und zu spezifizieren und mit Hilfe der Dokumentation zunächst
            selbst zu lösen (im Folgenden „Eigenlösung“). Der Kunde wird den Support von Digi Sapiens nach Maßgabe
            dieser Ziff. 8 nur in Anspruch nehmen, wenn entweder die verfügbare Dokumentation für den aufgetretenen
            Mangel keine Lösungshinweise gibt oder die Eigenlösung durch den Kunden gescheitert ist.<br /><br />
          </p>

          <p><strong>8.4</strong> Der Kunde kann jederzeit einen Mangel per Telefon unter <a
              href="tel:+4969380299333">069 / 380 299 333</a> oder via E-Mail unter <a
              href="mailto:helpdesk@digi-sapiens.de">helpdesk@digi-sapiens.de</a> („Ticket“) melden. Digi Sapiens
            bearbeitet Tickets in folgenden Zeiträumen: Montag bis Freitag von 09.00 Uhr bis 15.00 Uhr, jeweils unter
            Ausnahme von Tagen, die am Standort Frankfurt als Feiertage gelten (im Folgenden „Standard-Support-Zeiten“).
            Die Meldung muss in einer nachvollziehbaren Art und Weise unter detaillierter Beschreibung der zum Mangel
            führenden Arbeitsschritte sowie etwaiger vorausgegangen Eigenlösungsversuchen des Kunden erfolgen. Der Kunde
            stellt auf Verlangen von Digi Sapiens im Anschluss an ein vom Kunden platziertes Ticket auf eigene Kosten
            weitere Informationen zur Verfügung, die für eine Reproduktion oder Lösung eines Mangels notwendig oder
            hilfreich sind. Supportverpflichtungen von Digi Sapiens entfallen, falls und solange (a) der Kunde die
            Plattform nicht ordnungsgemäß nutzt, (b) die Mängel nicht maschinell reproduzierbar sind oder (c) der Mangel
            aus einer Fehlfunktion von Dritt-Geräten, Dritt-Software oder den Daten des Kunden resultiert.
            <br /><br />
          </p>

          <p><strong>8.5</strong> Digi Sapiens liefert innerhalb angemessener Frist Workarounds, Patch, Hot- oder Bugfix
            oder sonstiges Release zur Beseitigung eines Mangels des SaaS („Mangelbeseitigung“). Digi Sapiens
            entscheidet über die Art und Weise der Mangelbeseitigung nach billigem Ermessen unter angemessener
            Berücksichtigung der Interessen des Kunden. Für die Beseitigung von Mängeln in der Eigensoftware gelten die
            im Folgenden angegebenen (unverbindlichen) Ziel-Reaktionszeiten, in der Digi Sapiens auf Tickets des Kunden
            in Abhängigkeit auf die im Folgenden beschriebenen Mangel-Kategorie via Telefon oder E-Mail reagiert (z.B.
            durch Kategorisierung des Mangels oder einen ersten Vorschlag zur Mangelbeseitigung). Es werden nur
            Zeiträume innerhalb der in Ziff. 8.4 genannten Standard-Support-Zeiten berücksichtigt.</p>
          <ul>
            <li>“Mangel-Kategorie” meint die folgende Einstufung eines Mangels der Eigensoftware durch Digi
              Sapiens:<br /><br />
              <table>
                <thead>
                  <tr>
                    <th>Mängelklassen</th>
                    <th>Mangelfolgen</th>
                    <th>Auswirkungen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Severity-Klasse 1</td>
                    <td>Betriebsstillstand</td>
                    <td>Ein Mangel der Eigensoftware führt zum Ausfall oder einer Nichtverfügbarkeit des SaaS insgesamt
                    </td>
                  </tr>
                  <tr>
                    <td>Severity-Klasse 2</td>
                    <td>Starke Behinderung</td>
                    <td>Ein Mangel der Eigensoftware führt zum Ausfall eines wesentlichen Teiles oder einzelner
                      wesentlicher Funktionen des SaaS und kann nicht durch ein Workaround behoben werden</td>
                  </tr>
                  <tr>
                    <td>Severity-Klasse 3</td>
                    <td>Im Wesentlichen fortbestehende Betriebsbereitschaft</td>
                    <td>Der Kunde kann den SaaS mit eingeschränkten Funktionen verwenden, so dass es für den gesamten
                      Ablauf nicht bedenklich ist</td>
                  </tr>
                  <tr>
                    <td>Severity-Klasse 4</td>
                    <td>Unwesentliche Beeinträchtigung</td>
                    <td>Es besteht ein Workaround oder der Kunde kann Maßnahmen ergreifen, den Mangel zu umgehen</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>“Ziel-Reaktionszeit” meint die unten angegebene Zeit, in der Digi Sapiens auf Tickets des Kunden via
              Telefon oder E-Mail in Abhängigkeit von der Mangel-Kategorie reagiert (z.B. durch Kategorisierung des
              Mangels oder einen ersten Vorschlag zur Mangelbeseitigung).</li>
            <li>„Ziel-Mangelbeseitigungszeit“ meint die Zeit, in der Digi Sapiens eine Mangelbeseitigung in Abhängigkeit
              von der Mangel-Kategorie durchführt.<br /><br />
              <table>
                <thead>
                  <tr>
                    <th>Mangel-Kategorie</th>
                    <th>Ziel-Reaktionszeit</th>
                    <th>Ziel-Mangelbeseitigungszeit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>5 Stunden</td>
                    <td>Unverzüglich</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>1 Arbeitstag</td>
                    <td>5 Arbeitstage</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>1 Arbeitstag</td>
                    <td>2 – 4 Wochen</td>
                  </tr>
                </tbody>
              </table>
              Zeiten außerhalb von Digi Sapiens' Standard Support Zeiten werden bei der Kalkulation der vorstehenden
              Zielfristen nicht berücksichtigt.
            </li>
          </ul>

          <p><strong>8.6</strong> Im Rahmen des Supports und während den Standard-Support-Zeiten ist der Kunde
            berechtigt, neben Tickets, die Mängel des SaaS betreffen, auch generelle Anfragen von Nutzern zur Anwendung
            des SaaS oder (Anwender-) Problemen, die nicht auf einen Mangel des SaaS zurückgehen („Hilfeanfragen“) über
            einen Hilfebereich des SaaS zu platzieren. Bestimmte Reaktionszeiten oder eine bestimmte Lösung der
            Hilfeanfrage werden von Digi Sapiens nicht geschuldet. Digi Sapiens bemüht sich, innerhalb von 1 Arbeitstag
            auf Hilfeanfragen per E-Mail an den Nutzer zu antworten.<br /><br /></p>

          <p><strong>8.7</strong> Die Supportverpflichtung von Digi Sapiens unter dieser Ziff. 8 entfällt, falls und
            solange</p>
          <ul>
            <li>der Kunde Fernzugriff per Remotezugang nicht innerhalb eines angemessenen Zeitraums ermöglicht,</li>
            <li>der Kunde den SaaS nicht vertragsgemäß nutzt;</li>
            <li>der SaaS verändert wird, ohne dass dies mit vorheriger Zustimmung seitens Digi Sapiens erfolgt ist;</li>
            <li>Mängel nicht maschinell reproduzierbar sind;</li>
            <li>der Mangel aus einer Fehlfunktion von Dritt-Geräten, Dritt-Software oder den Daten des Kunden
              resultiert;</li>
            <li>der SaaS mit Drittprodukten oder Komponenten benutzt wird, welche nicht seitens Digi Sapiens freigegeben
              worden sind oder empfohlen wurden.</li>
          </ul>

          <h3>9. Freistellung von Ansprüchen wegen Schutzrechtsverletzungen</h3>
          <p>
            <strong>9.1</strong> Digi Sapiens stellt den Kunden während der Laufzeit des Einzelvertrages von Zahlungs-
            und Ersatzansprüchen Dritter frei, die von diesen gegenüber dem Kunden wegen der Verletzung von
            Schutzrechten durch die Nutzung der Services in diesem Zeitraum geltend gemacht werden (inklusive
            angemessener Anwaltskosten des Dritten und des Kunden). Dies gilt nicht, sofern eine solche Verletzung
            (i) durch die Kombination der Services mit nicht von Digi Sapiens gelieferter Software oder Leistungen oder
            (ii) durch die Modifikation oder Integration der Services ohne Zustimmung oder Mitwirkung von Digi Sapiens
            verursacht wurde. Digi Sapiens ist verpflichtet, innerhalb angemessener Frist nach Wahl von Digi Sapiens
            entweder
            (i) das für den Kunden notwendige Nutzungsrecht von dem Dritten zu erwerben, oder
            (ii) die Services so zu modifizieren, dass sie die Schutzrechte des Dritten nicht mehr verletzen.
            Sofern dies für Digi Sapiens in Anbetracht des Entgelts mit einem angemessenen Aufwand nicht möglich ist,
            wird Digi Sapiens den Kunden hierüber in Textform informieren. Unter diesen Gegebenheiten ist jede der
            Parteien berechtigt, den betroffenen Einzelvertrag außerordentlich aus wichtigem Grund gemäß Ziff. 11.2
            schriftlich zu kündigen.<br /><br />
          </p>
          <p>
            <strong>9.2</strong> Bedingung für die Freistellung nach Ziff. 9.1 ist, dass der Kunde Digi Sapiens
            (i) unverzüglich in Textform über den Anspruch und die gesamte im Zusammenhang mit dem Verletzungsfall
            stehende Kommunikation informiert hat;
            (ii) den Anspruch des Dritten selbst nicht anerkannt hat,
            (iii) Digi Sapiens die alleinige Befugnis gewährt, sich gegen den Anspruch zu verteidigen; und
            (iv) Digi Sapiens im Rahmen des Zumutbaren bei der Verteidigung oder Abwehr solcher Ansprüche
            unterstützt.<br /><br />
          </p>
          <p>
            <strong>9.3</strong> Diese Ziff. 9 regelt abschließend und vollumfänglich die Haftung von Digi Sapiens im
            Falle einer Verletzung von Schutzrechten im Zusammenhang mit den Services. Weitergehende gesetzliche Rechte
            stehen dem Kunden nur zu bei grober Fahrlässigkeit oder Vorsatz seitens Digi Sapiens oder im Falle eines
            arglistigen Verschweigens der Schutzrechtsverletzung.<br /><br />
          </p>

          <h3>10. Haftungsbeschränkung und Verjährung</h3>
          <p>
            <strong>10.1</strong> 10.1 Digi Sapiens haftet dem Kunden stets (i) für die von Digi Sapiens sowie seinen
            gesetzlichen Vertretern oder Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursachten Schäden, (ii)
            nach dem Produkthaftungsgesetz und (iii) für Schäden aus der Verletzung des Lebens, des Körpers oder der
            Gesundheit,
            die Digi Sapiens, seine gesetzlichen Vertreter oder Erfüllungsgehilfen zu vertreten haben. <br /><br />
          </p>

          <p>
            <strong>10.2</strong> Digi Sapiens haftet bei leichter Fahrlässigkeit nicht, es sei denn, Digi Sapiens
            selbst hat eine wesentliche Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des
            Einzelvertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut
            und vertrauen darf („Kardinalpflicht“), verletzt. Diese Haftung für die Verletzung von Kardinalspflichten
            ist bei Sach- und Vermögensschäden auf den vertragstypischen und vorhersehbaren Schaden beschränkt. Die
            Haftung für entgangenen Gewinn, ausgebliebene Einsparungen, Betriebsunterbrechungen und für sonstige
            entfernte Mangelfolgeschäden ist ausgeschlossen. Die Haftung für die Verletzung von Kardinalspflichten ist
            zusätzlich pro Vertragsjahr des Einzelvertrages auf den vereinbarten Jahres-Netto-Wert des betroffenen
            Einzelvertrags begrenzt. Die Parteien können bei Abschluss eines Einzelvertrages eine weitergehende Haftung
            pro Schadenfalls oder Vertragsjahr gegen gesonderte Vergütung vereinbaren. Die Haftung gemäß vorstehender
            Ziff. 10.1 bleibt von diesem Absatz unberührt.<br /><br />
          </p>
          <p>
            <strong>10.3</strong> Aus einer Garantieerklärung haftet Digi Sapiens nur auf Schadensersatz, wenn dies in
            der Garantie ausdrücklich übernommen wurde. Diese Haftung unterliegt bei leichter Fahrlässigkeit den
            Beschränkungen gemäß Ziff. 10.2.<br /><br />
          </p>
          <p>
            <strong>10.4</strong> Bei Verlust von Daten haftet Digi Sapiens nur für denjenigen Aufwand, der für die
            Wiederherstellung der Daten, Nachrichten und Informationen bei ordnungsgemäßer Sicherung durch den Kunden
            erforderlich ist. Bei leichter Fahrlässigkeit von Digi Sapiens tritt diese Haftung nur ein, wenn der Kunde
            unmittelbar vor der zum Datenverlust führenden Maßnahme eine ordnungsgemäße Datensicherung durchgeführt
            hat.<br /><br />
          </p>
          <p>
            <strong>10.5</strong> Schadensersatzansprüche verjähren innerhalb eines Jahres ab dem gesetzlichen
            Verjährungsbeginn. Die gesetzlichen Fristen bleiben im Falle einer vorsätzlichen oder grob fahrlässigen
            Pflichtverletzung durch Digi Sapiens, sowie in den Fällen der Verletzung des Lebens, des Körpers oder der
            Gesundheit, unberührt.<br /><br />
          </p>
          <p>
            <strong>10.6</strong> Für Aufwendungsersatzansprüche und sonstige Haftungsansprüche des Kunden gegen Digi
            Sapiens gelten vorstehende Ziffern 10.1 bzw. 10.5 entsprechend.<br /><br />
          </p>
          <p>
            <strong>10.7</strong> Soweit die Haftung von Digi Sapiens beschränkt ist, gilt dies auch für die persönliche
            Haftung ihrer Angestellten, Arbeitnehmer und sonstige Mitarbeiter und Erfüllungsgehilfen.<br /><br />
          </p>

          <h3>11. Laufzeit und Beendigung von Einzelverträgen</h3>
          <p>
            <strong>11.1</strong> Einzelverträge werden mit Unterschrift beider Parteien abgeschlossen. Abgeschlossene
            Einzelaufträge über Services haben die im Einzelvertrag angegebene Laufzeit. Ist im Einzelvertrag keine bzw.
            keine abweichende Laufzeit angegeben, haben Einzelverträge eine unbegrenzte Laufzeit und können durch Digi
            Sapiens oder den Kunden mit einer Frist von drei (3) Monaten zum Ende des jeweiligen Vertragsjahres
            gekündigt werden. Abweichend von der vorstehenden Kündigungsregelung ist Digi Sapiens berechtigt, inaktive
            Nutzerkonten oder Nutzerkonten ohne ausreichendes Guthaben nach Verstreichen von 24 Monaten nach der letzten
            Aktivität mit mindestens zwei (2) Wochen Vorankündigung zu löschen. Endet der Einzelvertrag zwischen Digi
            Sapiens und dem Kunden, so endet die Möglichkeit der Nutzung der Plattform. Dies gilt auch für sämtliche
            Nutzer, die der Organisation des Kunden angehören oder durch diese vertreten werden. Mit Beendigung eines
            Einzelvertrages über SaaS werden sämtliche gespeicherten Nutzungsdaten der betroffenen Nutzer automatisch
            gelöscht. Der Kunde hat sicherzustellen, dass etwaige noch benötigte Daten, z.B. Diagnose-Daten, außerhalb
            der Plattform ausreichend gesichert werden.<br /><br />
          </p>
          <p>
            <strong>11.2</strong> Das Recht der Parteien zur außerordentlichen Kündigung eines Einzelvertrages aus
            wichtigem Grund bleibt von Ziff. 11.1 unberührt. Ein wichtiger Grund besteht insbesondere dann, wenn eine
            der Parteien eine ihrer wesentlichen Pflichten aus dem Einzelvertrag verletzt und eine solche Verletzung
            auch nach ausdrücklicher schriftlicher Abmahnung nicht innerhalb eines angemessenen Zeitraums ab dem
            Zeitpunkt des Zugangs der Abmahnung von dieser Partei beseitigt wird.<br /><br />
          </p>
          <p>
            <strong>11.3</strong> Etwaige gesetzliche Rücktrittsrechte des Kunden sind ausgeschlossen. Das
            Kündigungsrecht des Kunden aus dieser Ziffer 11 ist jeweils auf den vom Kündigungsgrund betroffenen
            Einzelvertrag beschränkt. Nur soweit dem Kunden infolge einer von Digi Sapiens verschuldeten Kündigung eines
            Einzelvertrages die Fortsetzung anderer Einzelaufträge unzumutbar oder technisch unmöglich wird, ist der
            Kunde zur außerordentlichen Kündigung auch dieser Einzelverträge berechtigt. Der Kunde hat die
            Unzumutbarkeit oder Unmöglichkeit auf Anforderung von Digi Sapiens schriftlich zu begründen und
            nachzuweisen. Eine jedwede Kündigungserklärung bedarf mindestens der Textform (E-Mail ist
            ausreichend).<br /><br />
          </p>
          <p>
            <strong>11.4</strong> Bei jeder Beendigung eines Einzelvertrages hat der Kunde die bis dahin erbrachten
            Services und erfolgten Nutzungen zu zahlen, unbeschadet weitergehender Ansprüche von Digi Sapiens. Bei einer
            Beendigung eines Einzelvertrages betreffend SaaS hat der Kunde die Nutzung des SaaS einzustellen und wird
            der Zugang des Kunden zu Support-Leistungen gesperrt. Der Kunde ist verpflichtet, innerhalb von drei (3)
            Werktagen nach Beendigung des Einzelvertrags die Originale sowie alle Kopien und Teilkopien der Plattform,
            alle Bestandteile der Dokumentation und alle sonstigen überlassenen Unterlagen und Informationen an Digi
            Sapiens herauszugeben oder unwiderruflich zu löschen und auf Anforderung von Digi Sapiens diese Löschung, an
            Eides Statt zu versichern. Sofern für den Kunden aufgrund zwingender gesetzlicher Vorschriften erforderlich,
            kann er die Aufbewahrung bestimmter Unterlagen zu reinen Archivierungszwecken verlangen.<br /><br />
          </p>
          <p>
            <strong>11.5</strong> Die Kündigung eines Einzelvertrages hat keine Auswirkungen auf etwaige Rechte und
            Verpflichtungen der Parteien aus den Ziffern 10., 12., 13.5 bis 13.7 dieser AGB.<br /><br />
          </p>

          <h3>12. Vertraulichkeit und Datenschutz</h3>
          <p>
            <strong>12.1</strong> “Vertrauliche Informationen" sind alle technischen und/oder geschäftlichen
            Informationen, die eine der Parteien in gleich welcher Form mit oder während der Durchführung eines
            darunterfallenden Einzelvertrages von der anderen Partei durch mündliche oder Textform- Mitteilung,
            Vorführungen, Geräte, Modelle, jegliche Art von Muster und visuelle Erfassung des Besagten erhält,
            einschließlich Informationen in Bezug auf die jeweiligen Geschäftstätigkeiten oder Abläufe. Alle Bedingungen
            dieser AGB oder eines Einzelvertrags gelten als Vertrauliche Information jeder Partei. Die Dokumentation,
            die von der Software abgebildeten Funktionen und Prozesse sowie die von Digi Sapiens verwendeten Passwörter
            und Codes und Hardware-Einstellungen gelten als Vertrauliche Informationen von Digi Sapiens. Die durch die
            Software verarbeiteten Daten gelten als Vertrauliche Information des Kunden. Nicht als vertrauliche
            Informationen gelten solche Informationen,</p>
          <ul>
            <li>(i) die frei zugänglich sind, ohne dass dies auf einem Versäumnis der anderen Partei ihrer vertraglichen
              Verpflichtungen nachzukommen beruht,</li>
            <li>(ii) der anderen Partei bereits bekannt oder nachweislich bereits im Besitz der anderen Partei waren,
            </li>
            <li>(iii) die nachweislich bereits unabhängig von einer Partei durch die andere Partei entwickelt wurden,
              oder
            </li>
            <li>(iv) die die andere Partei ohne Einschränkungen in Bezug auf die Offenlegung von Informationen erhalten
              hat.
            </li>
          </ul>

          <p>
            <strong>12.2</strong> 12.2 Beide Parteien verpflichten sich übereinstimmend (a) die vertraulichen
            Informationen der anderen Partei geheim zu halten und dabei denselben Sorgfaltsmaßstab an den Tag zu legen,
            mit der sie gewöhnlich ihre eigenen Informationen zu schützen pflegt, zumindest aber zumutbare Sorgfalt
            walten lässt, (b) die von der anderen Partei offengelegten vertraulichen Informationen nur für den Abschluss
            oder die Durchführung dieses Vertrages oder einem darunter fallenden Einzelvertrags zu verwenden, (c) die
            vertraulichen Informationen der anderen Partei nur restriktiv zu verwenden oder ihren Angestellten oder den
            Angestellten eines ihrer verbundenen Unternehmen oder an andere Auftragnehmer oder Berater die sie
            beauftragt, oder Kunde bzw. Angestellten der Kunde nur dann offenzulegen, sofern diese zwingend davon
            Kenntnis erhalten müssen, und die vertraulichen Informationen darüber hinaus nicht Dritten offenzulegen,
            vorausgesetzt, dass die jeweiligen Auftragnehmer, Berater oder Kunde sich schriftlich an die vorliegenden
            Geheimhaltungsverpflichtungen binden (d) Mitarbeiter, Auftragnehmer, Berater und Kunde auf ihre
            Verpflichtungen hinsichtlich der vertraulichen Informationen der anderen Partei hinzuweisen und (e)
            vertrauliche Informationen der anderen Partei weder zurückzuentwickeln (insbesondere in einen
            menschenlesbare Quelltext „Source-Code“) noch zu de-kompilieren (ausgenommen dies ist nach geltendem Recht
            erforderlich).
          </p>

          <p>
            <strong>12.3</strong> Digi Sapiens ist berechtigt, den Kunden öffentlich als Referenzkunden auf üblichen
            Marketingmitteln (Website, Prospekten) zu benennen. Soweit eine Verwendung von Marken oder Logos des Kunden
            erfolgt, bedarf dies einer vorherigen Freigabe durch den Kunden.<br /><br />
          </p>
          <p>
            <strong>12.4</strong> Jede der Parteien, verpflichtet sich, das anwendbare Datenschutzrecht zu beachten. Zu
            diesem Zweck gelten zwischen den Parteien im Anwendungsbereich von Art. 28 DSGVO die „Besonderen Bedingungen
            zur Auftragsverarbeitung“ abrufbar unter <a
              (click)="navigateToOrderProcessingAgreement()">https://www.laletu.de/avv</a>.
            Drittunternehmer im Sinne von Ziff. 3.8, die als Unterauftragnehmer von Digi Sapiens im Sinne von Art. 28
            Abs. 4 DSGVO personenbezogene Daten des Kunden verarbeiten, werden in den Besonderen Bedingungen zur
            Auftragsverarbeitung im Einzelnen von Digi Sapiens ausgewiesen.<br /><br />
          </p>

          <h3>13. Verschiedenes</h3>
          <p>
            <strong>13.1</strong> Auf diese AGB bzw. den Einzelvertrag und dessen Zustandekommen oder Beendigung findet
            deutsches Recht Anwendung unter Ausschluss seiner Regelungen zum internationalen Privatrecht.<br /><br />
          </p>
          <p>
            <strong>13.2</strong> Gerichtsstand für jede Streitigkeit aus und im Zusammenhang mit diesen AGB und einem
            Einzelvertrag, - auch in Bezug auf dessen Zustandekommen und dessen Beendigung - mit einem Kaufmann, einer
            juristischen Person des öffentlichen Rechts oder einem öffentlich-rechtlichen Sondervermögen ist der Sitz
            von Digi Sapiens. Die vorstehende Wahl dieses Gerichtsstands ist nur für den Kunden
            ausschließlich.<br /><br />
          </p>
          <p>
            <strong>13.3</strong> Sofern sich eine der Regelungen dieser AGB oder eines Einzelvertrages nach geltendem
            Recht als unwirksam, undurchführbar oder unrechtmäßig erweisen sollte, bleibt die Wirksamkeit der
            verbleibenden Regelungen davon unberührt. Die Parteien werden alle zumutbaren Anstrengungen unternehmen, die
            unwirksame Regelung bzw. den unwirksamen Teil hiervon durch eine neue Regelung zu ersetzen, die so weit wie
            möglich das wirtschaftlich von den Parteien beabsichtigte Ergebnis abbildet.<br /><br />
          </p>
          <p>
            <strong>13.4</strong> Der Kunde stimmt bereits jetzt jeder von Digi Sapiens vorgenommenen künftigen
            Übertragung von Einzelverträgen auf einen Dritten zu, der den Betrieb von Digi Sapiens bzw. alle
            wesentlichen Vermögensgegenstände dieses Betriebs erwirbt. Digi Sapiens wird den Kunden über eine solche
            Abtretung möglichst vorab in Textform informieren.<br /><br />
          </p>
          <p>
            <strong>13.5</strong> Eine Abtretung von Rechten und Ansprüchen aus einem Einzelvertrag durch den Kunden an
            einen Dritten ist ohne die vorherige Zustimmung seitens Digi Sapiens ausgeschlossen. Diese Zustimmung bedarf
            der Textform.<br /><br />
          </p>
          <p>
            <strong>13.6</strong> Änderungen und Ergänzungen eines Einzelvertrages bedürfen der Textform. Auch die
            Abbedingung dieses Textformerfordernisses bedarf der Textform.<br /><br />
          </p>
          <p>
            <strong>13.7</strong> Der Inhalt eines Einzelvertrages und alle dazugehörigen Anhänge, stellen die gesamten
            Vereinbarungen zwischen den Parteien in Bezug auf den darin geregelten Vertragsgegenstand dar und gehen
            jeglicher vorher oder zeitgleich erfolgten schriftlichen oder mündlichen Kommunikation, Darstellung oder
            Vereinbarung vor.<br /><br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
