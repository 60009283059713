import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface Book {
  _id?: string;
  title?: string;
  filename: string;
  size: string;
  originalname: string;
  path: string;
}

export interface BookData {
  data: Book[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface Blob {
  readonly size: number;
  readonly type: string;
  slice(start?: number, end?: number, contentType?: string): Blob;
}

export interface Image {
  readonly link: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookService {
  private restServerUrl = environment.evolutionAPI;
  //private bookReaderApiUrl = environment.BOOKREADERAPI_SERVER; TODO: unused?
  private oldBookReaderApiUrl = environment.BOOKREADERAPI_SERVER;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  books: Book[] = [];
  book: Book[] = [];
  booksUpdated = new Subject<any>();
  bookUpdated = new Subject<any>();
  categories = [];
  categoriesUpdated = new Subject<any>();
  constructor(private http: HttpClient) {}

  getBooksUpdateListener() {
    return this.booksUpdated.asObservable();
  }

  getCategoriesUpdateListener() {
    return this.categoriesUpdated.asObservable();
  }

  getBookUpdateListener() {
    return this.bookUpdated.asObservable();
  }

  public getBookCoverByIsbn(isbn, size) {
    return (
      this.oldBookReaderApiUrl +
      '/assets/' +
      isbn +
      '/images/' +
      isbn +
      '_img_cover_normal'
    );
  }

  getBookList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
    taskId: string,
    contentType: 'klett' | 'full' | 'trial',
  ): Observable<BookData> {
    let params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection))
      .set('licenseType', contentType);

    if (taskId != null) {
      params = params.append('taskId', taskId);
    }

    return this.http
      .get<BookData>(this.restServerUrl + '/api/student/getBooks', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getBookById(id: string) {
    return this.http
      .get<Book[]>(this.restServerUrl + '/api/student/getBookByIsbn/' + id)
      .subscribe((data) => {
        this.book = data;
        this.bookUpdated.next(this.book);
      });
  }

  getBook(isbn: string) {
    const params = new HttpParams().append('isbn', isbn);
    return this.http.get<Blob>(
      this.restServerUrl + '/api/student/getDownloadLink/' + isbn + '/epub',
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'blob' as 'json',
        params,
      },
    );
  }

  getBookimage(isbn: string) {
    const params = new HttpParams().append('isbn', isbn);
    return this.http.get<Image>(
      this.restServerUrl + '/api/student/getDownloadLink/' + isbn + '/jpg',
      { params },
    );
  }

  // TODO: Book Rating dummy data
  setBookRating(data) {
    return new Observable((subscriber) => {
      subscriber.next(data);
      subscriber.complete();
    });
    // return this.http.put(this.restServerUrl + '/api/student/setBookRating',
    //   JSON.stringify(data),
    //   { headers: new HttpHeaders().set('Content-Type', 'application/json') })
    //     .pipe(map(res => {
    //       return <Task>res;
    //     }));
  }
}
