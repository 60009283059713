<section class="container-fluid bookview-student-container">
  <div class="loading-screen-container" *ngIf="isLoading">
    <div class="loading-screen">
      <div class="loading-wrap">
        <!-- <app-loading-spinner></app-loading-spinner> -->
        <img src="{{ getBookCoverByIsbn(book?.isbn, 'normal') }}" alt="{{ replaceHtmlContent(book?.title) }}" />
        <p class="paragraph paragraph--xl">{{ translatedText?.record_rtc_waiting }} {{ loadingStatusText }}</p>
        <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [(value)]="uploadprogress">
        </mat-progress-bar>
      </div>
    </div>
  </div>

  <div class="countDown" *ngIf="isCountdown">
    <h1 class="h1 counter">{{ counter }}</h1>
  </div>
  <div class="row record-row" *ngIf="!isLoading">
    <div class="col-md-2 bookview-student-col-setting">
      <div class="book_reader_settings" *ngIf="task?.reader_settings?.editable">
        <button
          mat-flat-button
          type="button"
          [ngClass]="{ 'reader-menu-open': fontSizeClass.menuOpen === true }"
          class="readerButton"
          (click)="menuFontSize.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:fontSize"></mat-icon>
          {{ translatedText?.record_rtc_font_size }}
          <div #fontSizeClass="matMenuTrigger" #menuFontSize="matMenuTrigger" [matMenuTriggerFor]="fontSize"></div>
        </button>
        <mat-menu #fontSize="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="fontSize"
            [value]="task.reader_settings.font_size"
            (change)="updateReaderSettings($event, 'font_size')"
            aria-label="Font Size"
          >
            <mat-button-toggle class="fontSize fontSize__small" value="small">T</mat-button-toggle>
            <mat-button-toggle class="fontSize fontSize__normal" value="normal">T</mat-button-toggle>
            <mat-button-toggle class="fontSize fontSize__big" value="big">T</mat-button-toggle>
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{ 'reader-menu-open': lineHeightClass.menuOpen === true }"
          class="readerButton"
          (click)="menuLine_height.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:lineHeight"></mat-icon>
          {{ translatedText?.record_rtc_lines }}
          <div
            #lineHeightClass="matMenuTrigger"
            #menuLine_height="matMenuTrigger"
            [matMenuTriggerFor]="line_height"
          ></div>
        </button>
        <mat-menu #line_height="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="line_height"
            [value]="task.reader_settings.line_height"
            (change)="updateReaderSettings($event, 'line_height')"
            aria-label="Line Height"
          >
            <mat-button-toggle class="line_height line_height__small" value="small"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
            <mat-button-toggle class="line_height line_height__normal" value="normal"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
            <mat-button-toggle class="line_height line_height__big" value="big"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{
            'reader-menu-open': spaceBetweenClass.menuOpen === true
          }"
          class="readerButton"
          (click)="menuLetter_spacing.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:spaceBetween"></mat-icon>
          {{ translatedText?.record_rtc_space }}
          <div
            #spaceBetweenClass="matMenuTrigger"
            #menuLetter_spacing="matMenuTrigger"
            [matMenuTriggerFor]="letter_spacing"
          ></div>
        </button>
        <mat-menu #letter_spacing="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="letter_spacing"
            [value]="task.reader_settings.letter_spacing"
            (change)="updateReaderSettings($event, 'letter_spacing')"
            aria-label="Letter Spacing"
          >
            <mat-button-toggle class="letter_spacing letter_spacing__small" value="small"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
            <mat-button-toggle class="letter_spacing letter_spacing__normal" value="normal"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
            <mat-button-toggle class="letter_spacing letter_spacing__big" value="big"
              >{{ translatedText?.record_rtc_good }} <br />
              {{ translatedText?.record_rtc_world }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </mat-menu>
        <button
          mat-flat-button
          type="button"
          [ngClass]="{
            'reader-menu-open': hyphenation_modeClass.menuOpen === true
          }"
          class="readerButton"
          (click)="menuHyphenation_mode.openMenu()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_filled:hyphenation_mode"></mat-icon>
          {{ translatedText?.record_rtc_syllable }}
          <div
            #hyphenation_modeClass="matMenuTrigger"
            #menuHyphenation_mode="matMenuTrigger"
            [matMenuTriggerFor]="hyphenation_mode"
          ></div>
        </button>
        <mat-menu #hyphenation_mode="matMenu" class="book-settings-menu">
          <mat-button-toggle-group
            name="hyphenation_mode"
            [value]="task.reader_settings.hyphenation_mode"
            (change)="updateReaderSettings($event, 'hyphenation_mode')"
            aria-label="Hyphenation Mode"
          >
            <mat-button-toggle class="hyphenation_mode" value="option0"
              >{{ translatedText?.record_rtc_none }} <br />
              {{ translatedText?.record_rtc_syllable }}</mat-button-toggle
            >
            <mat-button-toggle class="hyphenation_mode" value="option1">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/blue_circle.svg" alt="blue circle" />
                <img src="./assets/red_circle.svg" alt="Red circle" />
              </div>
            </mat-button-toggle>
            <mat-button-toggle class="hyphenation_mode" value="option2">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/teal_circle.svg" alt="teal circle" />
                <img src="./assets/orange_circle.svg" alt="orange circle" />
              </div>
            </mat-button-toggle>
            <mat-button-toggle class="hyphenation_mode" value="option3">
              <div class="hyphenation_mode_wrap">
                <img src="./assets/gray_circle.svg" alt="gray circle" />
                <img src="./assets/black_circle.svg" alt="black circle" />
              </div>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-menu>
      </div>
      <!-- <div class="book_reader_settings_toc">
                <button mat-flat-button type="button" class="readerButton toc" (click)="openBookReaderToc()" matSuffix>
                    <mat-icon svgIcon="streamline_outlined:toc"></mat-icon>
                    Inhaltsverzeichnis
                </button>
            </div> -->
    </div>
    <div class="col-md-8 book_reader-col" [ngClass]="!clickLastWord && recordingPaused ? 'pause-col' : ''">
      <ng-container *ngIf="!resetTask">
        <div class="full-width" [hidden]="(!clickLastWord && recordingPaused) || !recordingStarted">
          <app-book-reader-view
            *ngIf="task?.book_isbn"
            [userTaskUuid]="userTaskUuid"
            [bookIsbn]="task?.book_isbn"
            [dsefVersion]="dsefVersion"
            [taskStartFrom]="taskStartFrom"
            [startWord]="startWord"
            [taskType]="task?.type"
            [bookRange]="task?.range"
            [bookReaderSettings]="bookReaderSettings"
            [readMode]="readMode"
            [bookReaderType]="bookReaderType"
            fromComponent="record-rtc"
          ></app-book-reader-view>
          <div class="book-preview-buttons" *ngIf="selectLastWord">
            <div class="boox-preview_text">
              <p class="paragraph paragraph--bold">
                {{ translatedText.book_view_student_select_last_word }}
              </p>
            </div>
            <button mat-flat-button color="primary-student" (click)="saveTask()" [disabled]="!hasSelectedRange()">
              OK
            </button>
          </div>
        </div>
        <div class="book_info_wrap-cover" *ngIf="!clickLastWord && recordingPaused">
          <div class="book_info" [ngClass]="{ 'book_info--countdown': isCountdown }">
            <div class="book_image_wrap">
              <img src="{{ getBookCoverByIsbn(book?.isbn, 'normal') }}" alt="{{ replaceHtmlContent(book?.title) }}" />
            </div>
            <h3 class="h3--bold">{{ replaceHtmlContent(book?.title) }}</h3>
            <p class="paragraph">{{ book?.author }}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-md-2 bookview-student-col-time">
      <div class="record_buttons_wrap" *ngIf="isCountdown">
        <button *ngIf="!isDiagnosticTest">
          <mat-fab
            type="button"
            color="done"
            class="record_button"
            *ngIf="!isDiagnosticTest && !selectLastWord"
            matSuffix
          >
            <mat-icon class="circle record" svgIcon="streamline_outlined:check"></mat-icon>
          </mat-fab>
          {{ translatedText?.record_rtc_finish }}
        </button>
        <button *ngIf="!isDiagnosticTest && !selectLastWord">
          <mat-fab type="button" color="restart" class="record_button" matSuffix>
            <mat-icon class="circle record" svgIcon="streamline_outlined:refresh"></mat-icon>
          </mat-fab>
          {{ translatedText.record_overlay_reset }}
        </button>
        <button *ngIf="!isDiagnosticTest">
          <mat-fab type="button" color="pause" class="record_button" *ngIf="!isDiagnosticTest" matSuffix>
            <mat-icon class="circle record" svgIcon="streamline_outlined:pause"></mat-icon
          ></mat-fab>
          {{ translatedText?.record_rtc_pause }}
        </button>

        <button *ngIf="!isDiagnosticTest">
          <mat-fab type="button" color="cancel" class="record_button" matSuffix>
            <mat-icon class="circle record" svgIcon="streamline_outlined:close"></mat-icon>
          </mat-fab>
          {{ translatedText?.record_rtc_cancel }}
        </button>
      </div>

      <div class="record_buttons_wrap" *ngIf="recordingStarted">
        <button *ngIf="!selectLastWord">
          <mat-fab
            type="button"
            color="done"
            class="record_button finish"
            *ngIf="!isDiagnosticTest"
            (click)="openRecordOverlay('finished')"
            matSuffix
          >
            <mat-icon class="circle record" svgIcon="streamline_outlined:check"></mat-icon>
          </mat-fab>
          <span *ngIf="!isDiagnosticTest">
            {{ translatedText?.record_rtc_finish }}
          </span>
        </button>
        <button *ngIf="!selectLastWord">
          <mat-fab
            type="button"
            color="restart"
            class="record_button refresh"
            *ngIf="!isDiagnosticTest"
            (click)="openRecordOverlay('restart')"
            matSuffix
          >
            <mat-icon class="circle record" svgIcon="streamline_outlined:refresh"></mat-icon>
          </mat-fab>
          <span *ngIf="!isDiagnosticTest">{{ translatedText?.record_rtc_reset }}</span>
        </button>
        <button *ngIf="!recordingPaused && !isDiagnosticTest && !selectLastWord">
          <mat-fab type="button" color="pause" class="record_button pause" (click)="pauseRecording()" matSuffix>
            <mat-icon class="circle record" svgIcon="streamline_outlined:pause"></mat-icon>
            {{ translatedText?.record_rtc_pause }}
          </mat-fab>
        </button>

        <button *ngIf="recordingPaused && !isDiagnosticTest && !selectLastWord">
          <mat-fab type="button" color="resume" class="record_button play" (click)="resumeRecording()" matSuffix>
            <mat-icon class="circle record" svgIcon="streamline_filled:play"></mat-icon>
          </mat-fab>
          {{ translatedText?.record_rtc_continue }}
        </button>

        <button *ngIf="!selectLastWord">
          <mat-fab
            type="button"
            color="stop"
            class="record_button cancel"
            (click)="openRecordOverlay('cancel')"
            matSuffix
          >
            <mat-icon class="circle record" svgIcon="streamline_outlined:close"></mat-icon>
          </mat-fab>
          {{ translatedText?.record_rtc_cancel }}
        </button>
      </div>
      <div class="bookview-student-time-wrap">
        <div *ngIf="time > 0" class="time_wrap">
          <p class="paragraph">{{ translatedText?.record_rtc_reading_time }}:</p>
          <p class="paragraph paragraph--l" *ngIf="!isReadingTimeTask && time < 3601">
            {{ time | timeformat : 'min' }}
          </p>
          <p class="paragraph paragraph--l" *ngIf="!isReadingTimeTask && time > 3600">
            {{ time | timeformat : 'hours' }}
          </p>
          <p class="paragraph paragraph--l" *ngIf="isReadingTimeTask && readingTime < 3601">
            <span *ngIf="readingTime - time < 0">+</span
            >{{ readingTime - time | readingTimeFormat | timeformat : 'min' }}
          </p>
          <p class="paragraph paragraph--l" *ngIf="isReadingTimeTask && readingTime > 3600">
            <span *ngIf="readingTime - time < 0">+</span
            >{{ readingTime - time | readingTimeFormat | timeformat : 'hours' }}
          </p>
        </div>
        <!-- <app-book-reader-pagination></app-book-reader-pagination> -->
        <!-- <app-book-reader-nav [bookReaderType]="'get'"></app-book-reader-nav> -->
      </div>
    </div>
  </div>
</section>
