import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { StudentResultsComponent } from './student-results.component';
import { DiagnosticResultsModule } from '../../diagnostic/diagnostic-results/diagnostic-results.module';
import { DirectivesModule } from '../../directives/directives.module';
import { StudentProgressBarModule } from '../../helpers/student-progress-bar/student-progress-bar.module';

@NgModule({
  declarations: [StudentResultsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    DiagnosticResultsModule,
    DirectivesModule,
    StudentProgressBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [StudentResultsComponent],
})
export class StudentResultsModule {}
