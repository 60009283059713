import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ClassDeleteComponent } from './class-delete.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [ClassDeleteComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [ClassDeleteComponent],
})
export class ClassDeleteModule {}
