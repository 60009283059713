import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {}
@Component({
  selector: 'app-diagnostic-repeat',
  templateUrl: './diagnostic-repeat.component.html',
  styleUrls: ['./diagnostic-repeat.component.scss'],
})
export class DiagnosticRepeatComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('diagnosticDateChanger') diagnosticDateChanger: boolean = false;

  isLoading: boolean = false;
  selectedIndex: number;
  deadlineStep: FormGroup;
  todayDate: Date = new Date();
  taskId;
  groupSelectStep: FormGroup;
  groups: any = [];
  teacherId;
  userType;
  studentsArray = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  checkSelection: boolean = false;
  isClosedDialog: boolean = false;
  lastStep;
  taskType;
  taskStudents = [];
  diagnostic: any = [];
  diagnosticType;
  DiagnosticTypesArray;
  deadline;
  diagnosticSelected;
  studentId;
  diagnosticValue;
  task;
  dateToday = new Date();
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['taskType'] !== 'undefined') {
        this.taskType = this.injectedData['taskType'];
      }
      if (typeof injectedData['diagnosticType'] !== 'undefined') {
        this.diagnosticType = this.injectedData['diagnosticType'];
      }
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    this.taskService.getSingleTask(this.taskId);
    this.taskService
      .getSingleTaskUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((task) => {
        console.log(task);
        this.task = task;
      });

    this.taskService.getPhaseDates();
    this.taskService
      .getPhaseDatesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        switch (this.diagnosticType) {
          case 'diagnostic-pre':
            this.diagnostic = res?.filter(
              (item) => item?.value === 'diagnostic-pre',
            );
            this.diagnostic = this.diagnostic[0];
            break;
          case 'diagnostic-mid':
            this.diagnostic = res?.filter(
              (item) => item?.value === 'diagnostic-mid',
            );
            this.diagnostic = this.diagnostic[0];
            break;
          case 'diagnostic-post':
            this.diagnostic = res?.filter(
              (item) => item?.value === 'diagnostic-post',
            );
            this.diagnostic = this.diagnostic[0];
            break;

          default:
            break;
        }
      });

    this.deadlineStep = this._formBuilder.group({
      diagnostic: ['', [Validators.required]],
      diagnosticDeadline: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  checkForItemSelection(array, value) {
    return array.filter((item) => item.value === value).length > 0
      ? true
      : false;
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  closeOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  submitTask(formDirective: FormGroupDirective) {
    if (this.deadlineStep.invalid) {
      console.log('Form invalid');
      return;
    }
    console.log(this.deadlineStep.value.diagnosticDeadline);
    this.stepper.selectedIndex = 1;
    this.isLoading = true;
    const diagnosticDeadlineValue = new Date(
      this.deadlineStep.value.diagnosticDeadline,
    );
    const endOfDayDeadline = new Date(diagnosticDeadlineValue);
    this.taskService
      .repeatDiagnosticTask({
        taskId: this.taskId,
        deadline: endOfDayDeadline.setHours(23, 59, 59, 999),
        studentId: this.studentId,
      })
      .subscribe((res) => {
        this.isLoading = false;
      });
  }

  /**
   *  get active step from stepper
   */
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }
}
