import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
export interface DialogData {}

// TODO: unused?
@Component({
  selector: 'app-book-preferences',
  templateUrl: './book-preferences.component.html',
  styleUrls: ['./book-preferences.component.scss'],
})
export class BookPreferencesComponent implements OnInit {
  userType;
  isPreferenceSelection: boolean = false;
  isStartScreen: boolean = true;
  isEndScreen: boolean = false;
  selectedChips: string[] = [];
  possibleCategories = [
    'Grusel',
    'Freundschaft',
    'Fantasy',
    'Abenteuer',
    'Detektiv',
    'Familie',
    'Selbstfindung',
    'Geschwister',
    'Lustiges',
    'Mut',
    'Übungsbücher',
    'Natur',
    'Spannung',
    'Rätsel',
    'Magie',
    'Jugendliche',
    'Märchen',
    'Tiere',
    'Geschichte',
    'Weihnachten',
    'Geheimnisvolles',
    'Liebe',
    'Sport',
  ];
  studentId;
  step;
  translatedText: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authService: AuthService,
    private studentService: StudentService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['step'] !== 'undefined') {
        this.step = this.injectedData['step'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.userType = this.authService.getType();
    this.studentId = this.authService.getStudentId();
    if (this.step === 'selection') {
      this.isStartScreen = false;
      this.isPreferenceSelection = true;
    }

    this.studentService
      .getStudentbyId(this.studentId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        let preferences = res['categories'];
        this.selectedChips = preferences.filter((category) =>
          this.possibleCategories.includes(category),
        );
      });
  }

  startPreferenzSelection() {
    this.isPreferenceSelection = true;
    this.isStartScreen = false;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  toggleChipSelection(chip: string): void {
    const index = this.selectedChips.indexOf(chip);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
    } else {
      if (this.selectedChips.length < 5) {
        this.selectedChips.push(chip);
      }
    }
  }

  submitSelection() {
    let formData = {
      _id: this.studentId,
      categories: this.selectedChips,
    };
    console.log(formData);
    this.studentService
      .updateStudentPreferences(formData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          if (this.step === 'selection') {
            this.closeOverlay();
          } else {
            this.isPreferenceSelection = false;
            this.isEndScreen = true;
          }
        },
        (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.technical_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
