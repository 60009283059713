import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from '../../auth/auth.service';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-task-start-overlay',
  templateUrl: './task-start-overlay.component.html',
  styleUrls: ['./task-start-overlay.component.scss'],
})
export class TaskStartOverlayComponent implements OnInit, OnDestroy {
  task;
  userId;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: unused? private or public?
    public snackBar: MatSnackBar, // TODO: unused? private or public?
    private route: ActivatedRoute,
    private router: Router, // TODO: unused?
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['task'] !== 'undefined') {
        this.task = this.injectedData['task'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userId = this.authService.getUserId();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
