import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss'],
})
export class PinCodeComponent implements OnInit, OnDestroy {
  pinForm: FormGroup;
  numOfDigits = 6;
  @ViewChildren('inputs') inputs: QueryList<any>;
  overlayView: string = '';
  readonly: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PinCodeComponent>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    this.pinForm = this.formBuilder.group({
      digits: this.formBuilder.array([]),
    });
    if (injectedData) {
      if (typeof injectedData['overlayView'] !== 'undefined') {
        this.overlayView = this.injectedData['overlayView'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    if (this.overlayView == 'teacherView') {
      this.readonly = true;
      this.authService.getImpersonationCode();
      this.authService
        .getImpersonationCodeListener()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          const impersonationCode = Array.from(res.impersonationCode);
          this.pinForm.get('digits').setValue(impersonationCode);
        });
    }

    for (let i = 0; i < this.numOfDigits; i++) {
      (this.pinForm.get('digits') as FormArray).push(
        this.formBuilder.control(null),
      );
    }

    if (!this.readonly) {
      // Trigger change detection manually
      this.cd.detectChanges();
      setTimeout(() => {
        if (this.inputs && this.inputs.first) {
          this.inputs.first.nativeElement.focus();
        }
      }, 0);
    }
  }

  check(index, field, event) {
    if (!this.readonly) {
      if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
        event.preventDefault();
      }
      if (field.value && event.key !== 'Backspace') {
        if (index < this.inputs.toArray().length - 1) {
          this.inputs.toArray()[index + 1].nativeElement.focus();
        }
      } else if (event.key === 'Backspace') {
        if (index > 0) {
          field.setValue(null);
          this.inputs.toArray()[index - 1].nativeElement.focus();
        } else {
          console.log('first field');
        }
      }
    }
  }

  copyToClipboard() {
    const code = this.pinForm.get('digits').value.join(''); // Get the pin code as a string
    navigator.clipboard
      .writeText(code)
      .then(() => {
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: 'PIN-Code erfolgreich in die Zwischenablage kopiert.',
          duration: 3000,
          horizontalPosition: 'left',
        });
      })
      .catch((error) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: 'Fehler beim Kopieren des PIN-Codes in die Zwischenablage',
          duration: 3000,
          horizontalPosition: 'left',
        });
      });
  }

  submitForm(form: FormGroupDirective) {
    if (this.pinForm.invalid) {
      return;
    }
    const pinCode = this.pinForm.get('digits').value.join('');

    console.log(pinCode);
    this.dialogRef.close({ data: pinCode });

    // Reset the form
    this.pinForm.reset();
  }

  closePdfOverlay() {
    // Reset the form
    this.pinForm.reset();
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
