<div class="helpdesk_wrap beta-info-wrap">
  <h1 class="h1 h1--bold warning-color">
    {{ translatedText.beta_info_warning }}
  </h1>

  <ng-container *ngIf="!betaLeaveInfo">
    <p class="paragraph paragraph--l">
      {{ translatedText.beta_info_part_1 }}
      <strong>{{ translatedText.beta_info_part_2 }}</strong>
      {{ translatedText.beta_info_part_3 }}
      <strong>{{ translatedText.beta_info_part_4 }}</strong>
      {{ translatedText.beta_info_part_5 }}
      <br />
      <br />
      {{ translatedText.beta_info_list_title }}
    </p>

    <ul>
      <li>
        <p class="paragraph paragraph--l">
          {{ translatedText.beta_info_list_item_1 }}
        </p>
      </li>
      <li>
        <p class="paragraph paragraph--l">
          {{ translatedText.beta_info_list_item_2 }}
        </p>
      </li>
      <li>
        <p class="paragraph paragraph--l">
          {{ translatedText.beta_info_list_item_5 }}
        </p>
      </li>
      <li>
        <p class="paragraph paragraph--l">
          {{ translatedText.beta_info_list_item_3 }}
        </p>
      </li>
    </ul>

    <p class="paragraph paragraph--l">
      {{ translatedText.beta_info_list_item_4 }}
      <br />
      <br />
      {{ translatedText.beta_info_footer }}
    </p>
  </ng-container>

  <ng-container *ngIf="betaLeaveInfo">
    <p class="paragraph paragraph--l">
      {{ translatedText.beta_info_leave_part_1 }}
      <strong>{{ translatedText.beta_info_leave_part_2 }}</strong>
      {{ translatedText.beta_info_leave_part_3 }}
    </p>
  </ng-container>

  <button
    mat-flat-button
    color="primary"
    (click)="closeOverlay()"
    type="button"
  >
    {{ translatedText.beta_info_close }}
  </button>
</div>
