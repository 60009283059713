import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { NewLicenseOverlayComponent } from './new-license-overlay.component';

@NgModule({
  declarations: [NewLicenseOverlayComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [NewLicenseOverlayComponent],
})
export class NewLicenseOverlayModule {}
