import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { StudentService } from '../../providers/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../../providers/book.service';
import { takeUntil } from 'rxjs/operators';
import { TaskDeleteComponent } from '../task-delete/task-delete.component';
import { TaskService } from '../../providers/task.service';
import { MatDialog } from '@angular/material/dialog';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NotificationOverlayComponent } from '../../helpers/notification-overlay/notification-overlay.component';
import { Location } from '@angular/common';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiagnosticRepeatComponent } from 'src/app/diagnostic/diagnostic-repeat/diagnostic-repeat.component';
import { AutomodekOverlayComponent } from 'src/app/helpers/automode-overlay/automode-overlay.component';
import { TaskRepeatComponent } from '../../task/task-repeat/task-repeat.component';
import { TaskEditDiagnosticComponent } from '../../task/task-edit-diagnostic/task-edit-diagnostic.component';
import { TaskEditTrainingComponent } from '../task-edit-training/task-edit-training.component';
import { TranslationService } from 'src/app/providers/translation.service';
export interface Student {
  _id: string;
  username: string;
  progress: number;
  reading_combined_score: number;
  reading_errors_score: number;
  speed: string;
  tendency: boolean;
  complete: boolean;
}

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class TaskDetailComponent implements OnInit {
  restServerUrl;
  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  displayedColumns: string[] = [
    'username',
    'reading_combined_score',
    'reading_errors_score',
    'reading_speed_words',
    'reading_speed_correct_words_per_minute',
    /*'tendency',*/ 'complete',
    'button',
  ];
  nestedColumns: string[] = [
    'placeholder',
    'username',
    'reading_combined_score',
    'reading_errors_score',
    'reading_speed_words',
    'reading_speed_correct_words_per_minute',
    'complete',
    'button',
  ];
  dataSource: any;
  pageEvent: PageEvent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
  students;
  masterTaskId;
  teacherId;
  groupname: string;
  group_year: string;
  taskComplete: number;
  taskSplitTask: number;
  taskTotal: number;
  tasks;
  task;
  masterTask;
  taskLoaded: boolean = false;
  isDiagnosticTest: boolean = false;
  isAutomodeTest: boolean = false;
  book: any;
  page = 1;
  limit = 10;
  sortBy = 'username';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValue: string = '';
  //filterFormGroup: FormGroup;
  expandedElement: Student | null;
  expandedAutoElement: Student | null;
  translatedText;
  readingPointsInfoText: string =
    'Lesepunkte sind der kumulierte Wert von Lesegeschwindigkeit, Anzahl der Fehler, und der Stimmdynamik. Die Lesepunkte können einen Wert von 0 bis 100 annehmen und bilden im ' +
    environment.whitelabel.name +
    ' somit eine vereinfachte, übergeordnete Kennzahl, um die Leseflüssigkeit in einer einzigen Zahl abzubilden. \n\n Diese Zahl wird zukünftig mit Parametern angepasst, die wir zusätzlich einführen werden.';
  readingErrorInfoText: string =
    'Wir berechnen die Anzahl der Fehler, indem wir die Audioanalyse mit dem tatsächlichen Text vergleichen und Abweichungen identifizieren. \n\n Auslassungen: Wenn Leser ein Wort überspringen. \n\n Ersetzungen: Wenn Leser ein Wort durch ein anderes Wort ersetzten. \n\n Hinzufügungen: Wenn Leser ein Wort hinzufügen, das nicht im Originaltext enthalten ist. \n\n Die Werte werden weiterhin aktualisiert.';
  readingSpeedInfoText: string =
    'Die Lesegeschwindigkeit wird berechnet durch Wörter pro Minute (WPM) geteilt durch die Lesezeit. \n\n Die Werte werden weiterhin aktualisiert.';
  readingWordsCorrectPerMinuteInfoText: string =
    'Die Leseflüssigkeit wird berechnet durch "korrekte Wörter pro Minute" (WCPM).';
  readingLevelInfoText: string =
    'Der Parameter für die Lesekompetenz "korrekte Wörter pro Minute" (WCPM) ist in fünf Perzentile aufgeteilt und stellt die fünf Lesekompetenzlevels dar. Diese Lesekompetenzlevels gibt es für jede Klassenstufe. Ihnen werden die einzelnen Ergebnisse der Lernende zugeordnet.';
  levelInfoText: string =
    'Der Parameter für die Lesekompetenz "korrekte Wörter pro Minute" (WCPM) ist in fünf Perzentile aufgeteilt und stellt die fünf Lesekompetenzlevels dar. Diese Lesekompetenzlevels gibt es für jede Klassenstufe. Ihnen werden die einzelnen Ergebnisse der Lernende zugeordnet.';

  constructor(
    private authService: AuthService,
    private studentService: StudentService,
    private route: ActivatedRoute,
    public router: Router,
    private bookService: BookService,
    private taskService: TaskService,
    public dialog: MatDialog,
    private location: Location,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.masterTaskId = this.route.snapshot.params['id'];
    this.teacherId = this.authService.getTeacherId();
    this.studentService
      .getStudentsToMasterTask(this.masterTaskId, this.teacherId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.groupname = res.groupName;
        this.group_year =
          res.tasks.length > 0 ? res.tasks[0]._group.school_year : '';
        this.students = res.students ? res.students : [];
        this.students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
        this.tasks = res.tasks ? res.tasks : [];
        this.dataSource = new MatTableDataSource<any>(this.tasks);
        this.dataSource.sort = this.sort;
        // this.dataSource.sortingDataAccessor = (item, property) => {
        //   switch(property) {
        //     case 'completed_at': return item.complete;
        //     default: return item[property];
        //   }
        // };
        this.masterTask = res.master;

        this.task = this.tasks[0];
        this.taskComplete = this.students.filter(
          (obj) => obj._task.filter((item) => item.completed_at).length > 0,
        ).length;
        console.log(this.taskComplete);
        this.taskSplitTask = this.students.filter(
          (obj) =>
            obj._task.filter((item) => item._split_task.length > 0).length > 0,
        ).length;
        this.taskLoaded = true;
        this.taskTotal = this.students.length;
        if (
          this.task?.type == 'diagnostic-pre' ||
          this.task?.type == 'diagnostic-mid' ||
          this.task?.type == 'diagnostic-post' ||
          this.task?.type == 'autonomous-placement'
        ) {
          this.displayedColumns = [
            'username',
            'reading_combined_score',
            'reading_errors_score',
            'reading_speed_words',
            'reading_speed_correct_words_per_minute',
            'level',
            /*'tendency',*/ 'complete',
            'button',
          ];
          this.columnsToDisplayWithExpand = [
            'expand',
            ...this.displayedColumns,
          ];
          this.isDiagnosticTest = true;
        }
        if (this.task?.mode == 'autonomous') {
          this.displayedColumns = [
            'username',
            'reading_combined_score',
            'reading_errors_score',
            'reading_speed_words',
            'reading_speed_correct_words_per_minute',
            'level',
            /*'tendency',*/ 'complete',
            'button',
          ];
          this.columnsToDisplayWithExpand = [
            'expand',
            ...this.displayedColumns,
          ];
          this.isAutomodeTest = true;
        }
        // Get book
        // this.bookService.getBookById(this.task?.book_uuid).pipe(
        //   takeUntil(this._unsubscribeAll)
        // ).subscribe(data => {
        //   this.book = data;
        //   console.log(data)
        // });
      });
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(event: any, book) {
    // just try once
    if (!book.imageLoadFailed) {
      book.imageLoadFailed = true;
      event.target.src = book.imageUrl;
    }
  }

  getSubTaskId(id) {
    return this.tasks.filter((obj) => obj._student._id == id)[0]._id;
  }

  getSubTaskStatus(id) {
    return this.tasks.filter((obj) => obj._student._id == id)[0].status;
  }

  getSubTaskByStudentId(studentId) {
    return this.tasks.filter((task) => task._student._id === studentId)[0];
  }

  getScoreByTaskUuid(task, type) {
    if (task && this.students) {
      // Iterate through each task in the tasks array
      // Find the corresponding score object in the scores array based on the session_uuid
      let matchingScore = null;

      for (const student of this.students) {
        const matchingType = student[type].find(
          (score) => score.session_uuid === task.session_uuid,
        );
        if (matchingType) {
          matchingScore = matchingType.score;
          break; // Exit the loop when a match is found
        }
      }
      // If a matching score is found, return its score value
      return matchingScore;
    }
  }

  sumSplitTaskScore(studentId, type) {
    const student = this.students.find((s) => s._id === studentId);

    if (!student) {
      console.error(`Student with ID ${studentId} not found.`);
      return 0;
    }

    const scoresOfType = student._split_score.filter(
      (score) => score[type] !== undefined,
    );

    const totalScore = scoresOfType.reduce(
      (sum, score) => sum + score[type],
      0,
    );
    const averageScore =
      scoresOfType.length > 0 ? totalScore / scoresOfType.length : 0;

    return averageScore;
  }

  repeatTaskDialog(id): void {
    let dialogRef = this.dialog.open(TaskRepeatComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-repeat-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-repeat-overlay-panel-backdrop',
      data: {
        id: id,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  disableAutoMode() {
    this.taskService
      .updateSingleTask({
        _id: this.masterTaskId,
        status: 'cancelled',
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.masterTask.status = 'cancelled';
        let message = 'Auto Modus erfolgreich abgeschaltet.';
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      });
  }

  openNotificationOverlay(taskId, student, groupName, school_year): void {
    let dialogRef = this.dialog.open(NotificationOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        taskId: taskId,
        student: student,
        groupName: groupName + ' - ' + school_year.slice(2),
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  repeatDiagnostic(masterTaskId, type, studentId) {
    let taskType;
    let diagnosticType;
    if (
      type == 'diagnostic-pre' ||
      type == 'diagnostic-mid' ||
      type == 'diagnostic-post'
    ) {
      taskType = 'diagnostic';
      diagnosticType = type;
    } else {
      taskType = 'training';
    }
    let dialogRef = this.dialog.open(DiagnosticRepeatComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        taskId: masterTaskId,
        diagnosticType: diagnosticType,
        studentId: studentId,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        //this.getMasterTasks();
      });
  }

  openTaskDeleteDialog(): void {
    let dialogRef = this.dialog.open(TaskDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-delete-overlay',
      data: {
        masterTaskId: this.masterTaskId,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  openDisableAutoModeOverlay(): void {
    let dialogRef = this.dialog.open(AutomodekOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        masterTaskId: this.masterTaskId,
        groupName: this.groupname + ' - ' + this.group_year.slice(2),
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res == 'disable') {
          this.masterTask.status = 'cancelled';
        }
      });
  }

  generateTaskTitle(type) {
    let title = '';
    switch (type) {
      case 'diagnostic-pre':
        title =
          this.translatedText?.task_detail_detail_pre +
          '(' +
          this.groupname +
          ' - ' +
          this.group_year.slice(2) +
          ')';
        break;
      case 'diagnostic-mid':
        title =
          this.translatedText?.task_detail_detail_mid +
          '(' +
          this.groupname +
          ' - ' +
          this.group_year.slice(2) +
          ')';
        break;
      case 'diagnostic-post':
        title =
          this.translatedText?.task_detail_detail_post +
          '(' +
          this.groupname +
          ' - ' +
          this.group_year.slice(2) +
          ')';
        break;
      default:
        title =
          this.translatedText?.task_detail_details_task +
          '(' +
          this.groupname +
          ' - ' +
          this.group_year.slice(2) +
          ')';
        break;
    }
    return title;
  }

  generateTaskTypeTitle(type, mode) {
    return this.taskService.generateTaskTypeTitle(type, mode);
  }

  generateDiagnosticScore(diagnostic, task) {
    let score = diagnostic.filter((item) => (item._task = task._id));
    console.log(score);
    return score.length > 0 ? score[0].level : '-';
  }

  /**
   * Route to student detail component
   */
  navigateToStudentDetail(id) {
    this.router.navigate(['student-overview/' + id]);
  }

  /**
   * API call on adminService to retrieve requested teachers by pagination
   */
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    // TODO CALL GET STUDENTS
  }

  generatePercentForContinousTask(tasks: any[]) {
    return this.taskService.generatePercentForContinousTask(tasks);
  }

  /**
   * Send new input value to subscription
   */
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  getMultiTaskModeScore(session_uuid, studentId, value) {
    const student = this.students.find((s) => s._id === studentId);

    if (student) {
      const scores = student._split_score.filter(
        (score) => score.session_uuid === session_uuid,
      );
      return scores[0][value];
    }

    return '-';
  }

  /**
   * API call on adminService to retrieve requested teachers with sorted direction
   */
  onSortChange(event: MatSort) {
    console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
    // th″is.getTeachers();
  }

  navigateBack(): void {
    this.location.back();
  }

  editTask(taskId, type) {
    let taskType;
    let diagnosticType;
    if (
      type == 'diagnostic-pre' ||
      type == 'diagnostic-mid' ||
      type == 'diagnostic-post'
    ) {
      taskType = 'diagnostic';
      diagnosticType = type;
    } else {
      taskType = 'training';
    }
    if (taskType == 'training') {
      let dialogRef = this.dialog.open(TaskEditTrainingComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'create-training-task-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {
          taskId: taskId,
          taskType: taskType,
          diagnosticType: diagnosticType,
        },
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {});
    } else if (taskType == 'diagnostic') {
      let dialogRef = this.dialog.open(TaskEditDiagnosticComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'create-training-task-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'create-training-task-panel-backdrop',
        data: {
          taskId: taskId,
          taskType: taskType,
          diagnosticType: diagnosticType,
        },
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {});
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
