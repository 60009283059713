import { Component, OnDestroy, Inject, Optional, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
//import { AuthService } from 'src/app/auth/auth.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {}
@Component({
  selector: 'app-new-license-overlay.',
  templateUrl: './new-license-overlay.component.html',
  styleUrls: [
    './new-license-overlay.component.scss',
    '../trial-banners/trial-banners.component.scss',
  ],
})
export class NewLicenseOverlayComponent implements OnInit, OnDestroy {
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<NewLicenseOverlayComponent>,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closeDialog() {
    this.dialogRef.close('license-mapping-info');
  }
  openExternalUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
