import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import confetti from 'canvas-confetti';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { StudentService } from 'src/app/providers/student.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
export interface RecordData {}
@Component({
  selector: 'app-record-result',
  templateUrl: './record-result.component.html',
  styleUrls: ['./record-result.component.scss'],
})
export class RecordResultComponent implements OnInit, OnDestroy {
  isCanceled: boolean = false;
  isRepeat: boolean = false;
  isFinished: boolean = false;
  studentId;
  medals = {
    gold: 0,
    silver: 0,
    bronze: 0,
  };
  readingPoints = 0;
  earnedMedal = {
    name: '',
    description: '',
  };
  notes;
  levelArr = [];
  level;
  starCount = 5;
  medalImg;
  taskResult;
  task;
  readingSpeed;
  readingNumdeletions;
  readingNumdeletionsNote;
  earnedNewMedal;
  reading_dynamic_normalized;
  reading_errors_score;
  reading_speed_words;
  reading_speed_correct_words_per_minute;
  num_insertions;
  num_deletions;
  num_substitutions;
  translatedText: any;
  text_coverage;

  private studentResultSub: Subscription; // TODO: unused?
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: RecordData, // TODO: private or public
    public dialogRef: MatDialogRef<RecordResultComponent>, // TODO: unused? private or public?
    public dialog: MatDialog, // TODO: private or public?
    private route: ActivatedRoute,
    private authService: AuthService,
    private studentService: StudentService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      console.log(injectedData);
      if (typeof injectedData['result'] !== 'undefined') {
        this.taskResult = this.injectedData['result'];
      }
      if (typeof injectedData['task'] !== 'undefined') {
        this.task = this.injectedData['task'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    const hasDebugParam = this.route.snapshot.queryParamMap.has('debug');
    // if debug mode is enabled show fake data
    if (hasDebugParam) {
      this.text_coverage = Math.ceil(20);
      this.readingPoints = 54;
      this.reading_dynamic_normalized = 100;
      this.reading_errors_score = 2;
      this.reading_speed_words = 91;
      this.reading_speed_correct_words_per_minute = 84;
      this.num_insertions = 0;
      this.num_deletions = 1;
      this.num_substitutions = 0;
      this.level = this.route.snapshot.params['level'];
      console.log(this.level);
    } else {
      console.log(this.taskResult);
      this.text_coverage = Math.ceil(this.taskResult.text_coverage);
      this.readingPoints = this.taskResult.reading_combined_score;
      this.reading_dynamic_normalized =
        this.taskResult.reading_dynamic_normalized;
      this.reading_errors_score = this.taskResult.reading_errors_score;
      this.reading_speed_words = this.taskResult.reading_speed_words;
      this.reading_speed_correct_words_per_minute =
        this.taskResult.reading_speed_correct_words_per_minute;
      this.num_insertions = this.taskResult.num_insertions;
      this.num_deletions = this.taskResult.num_deletions;
      this.num_substitutions = this.taskResult.num_substitutions;

      //this.readingNumdeletions = this.taskResult.num_deletions;
      //raiting & readingPoints logic
      // if (this.readingPoints <= 19) {
      //   this.level = 0;
      // } else if (this.readingPoints >= 20 && this.readingPoints <= 39) {
      //   this.level = 1;
      // } else if (this.readingPoints >= 40 && this.readingPoints <= 59) {
      //   this.level = 2;
      // } else if (this.readingPoints >= 60 && this.readingPoints <= 79) {
      //   this.level = 3;
      // } else if (this.readingPoints >= 80 && this.readingPoints <= 99) {
      //   this.level = 4;
      // } else if (this.readingPoints == 100) {
      //   this.level = 5;
      // }
      this.level = this.taskResult.level_wcpm;

      // //notes & readingSpeed logic
      // if (this.readingNumdeletions == 0) {
      //   this.readingNumdeletionsNote = this.readingNotes[2];
      // } else {
      //   this.readingNumdeletionsNote = this.readingNotes[3];
      // }
    }

    for (let index = 0; index < this.starCount; index++) {
      this.levelArr.push(index);
    }

    //earned Medal logic
    if (this.level == 3) {
      this.earnedNewMedal = true;
      this.earnedMedal.name = 'bronze';
      this.earnedMedal.description =
        this.translatedText?.student_results_earned_medal_bronze;
    } else if (this.level == 4) {
      this.earnedNewMedal = true;
      this.earnedMedal.name = 'silver';
      this.earnedMedal.description =
        this.translatedText?.student_results_earned_medal_silver;
    } else if (this.level == 5) {
      this.earnedNewMedal = true;
      this.earnedMedal.name = 'gold';
      this.earnedMedal.description =
        this.translatedText?.student_results_earned_medal_gold;
      // confetti rain
      this.confettiRain();
    } else {
      this.earnedNewMedal = true;
      this.earnedMedal.name = 'none';
      this.earnedMedal.description =
        this.translatedText?.student_results_earned_medal_none;
    }

    this.studentId = this.authService.getStudentId();
    this.studentService.getStudentResults(this.studentId);
    this.studentResultSub = this.studentService
      .getStudentsResultUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        if (res?.studentWidgetData.medals != undefined) {
          this.medals.bronze = res?.studentWidgetData.medals.bronze;
          this.medals.silver = res?.studentWidgetData.medals.silver;
          this.medals.gold = res?.studentWidgetData.medals.gold;
        }
      });
  }

  getReadingDynamicNote(grade, reading_dynamic_normalized) {
    return this.taskService.getReadingDynamicNote(
      grade,
      Math.floor(reading_dynamic_normalized),
    );
  }

  getInsertionsNotes(grade, num_insertions) {
    return this.taskService.getInsertionsNotes(
      grade,
      Math.floor(num_insertions),
    );
  }

  getDeletionsNote(grade, num_deletions) {
    return this.taskService.getDeletionsNote(grade, Math.floor(num_deletions));
  }

  getSubstitutionsNote(grade, num_substitutions) {
    return this.taskService.getSubstitutionsNote(
      grade,
      Math.floor(num_substitutions),
    );
  }

  getReadingErrorNote(grade, reading_errors_score) {
    return this.taskService.getReadingErrorNote(
      grade,
      Math.floor(reading_errors_score),
    );
  }

  getReadingSpeedNote(grade, reading_speed_words) {
    return this.taskService.getReadingSpeedNote(
      grade,
      Math.floor(reading_speed_words),
    );
  }

  confettiRain() {
    try {
      confetti({
        angle: this.randomInRange(55, 125),
        spread: this.randomInRange(50, 70),
        particleCount: this.randomInRange(50, 100),
        origin: { y: 0.6 },
        zIndex: 9000,
      });
    } catch (e) {
      // noop, confettijs may not be loaded yet
    }
  }

  getLevelNotes(level) {
    return this.taskService.getLevelNotes(level);
  }

  private randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  showIcon(index: number) {
    if (this.level >= index + 1) {
      return 'active';
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
