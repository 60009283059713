import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-school-delete',
  templateUrl: './school-delete.component.html',
  styleUrls: ['./school-delete.component.scss'],
})
export class SchoolDeleteComponent implements OnInit {
  schoolId;
  schoolUuid;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: unused? private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    public resDialog: MatDialogRef<SchoolDeleteComponent>, // TODO: private or public?
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['schoolId'] !== 'undefined') {
        this.schoolId = this.injectedData['schoolId'];
      }
      if (typeof injectedData['schoolUuid'] !== 'undefined') {
        this.schoolUuid = this.injectedData['schoolUuid'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    console.log(this.schoolId);
    console.log(this.schoolUuid);
  }

  deleteSchool() {
    this.schoolService.deleteSchool(this.schoolId).subscribe({
      next: (res) => {
        this.resDialog.close('deleted');
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.school_delete_success,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        let message = this.translatedText?.technical_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }
}
