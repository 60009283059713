import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface School {
  _id?: string;
  name?: string;
  address?: number;
  zip?: number;
  city?: number;
  state?: string;
  school_type?: string;
}

export interface SchoolData {
  data: School[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
    city: [];
    school_type: [];
  };
}

export interface Teacher {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
  _group?: any;
}

export interface TeacherData {
  data: Teacher[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  schoolUpdated = new Subject<School[]>();
  school: School[] = [];

  private evolutionApiUrl: string;

  constructor(private http: HttpClient) {
    this.evolutionApiUrl = environment.evolutionAPI;
  }

  createSchool(school) {
    return this.http.post<any>(
      this.evolutionApiUrl + '/api/school/create',
      school,
    );
  }

  updateSchool(data) {
    console.log(data);
    return this.http.post<School>(
      this.evolutionApiUrl + '/api/school/updateSchool/' + data.schoolId,
      data,
    );
  }

  deleteSchool(schoolId) {
    return this.http.delete<School>(
      this.evolutionApiUrl + '/api/school/deleteSchool/' + schoolId,
    );
  }

  getSchoolList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
  ): Observable<SchoolData> {
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection));

    return this.http
      .get<SchoolData>(this.evolutionApiUrl + '/api/school/listSchools', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getCompleteSchoolList(
    page: number,
    limit: number,
    sortBy: string,
    sortDirection: number,
    filterValues: any,
  ): Observable<SchoolData> {
    const params = new HttpParams()
      .set('filterValues', JSON.stringify(filterValues))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('sortBy', sortBy)
      .set('sortDirection', String(sortDirection));

    return this.http
      .get<SchoolData>(this.evolutionApiUrl + '/api/school/list', {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getSchoolUpdateListener() {
    return this.schoolUpdated.asObservable();
  }

  getSchool(schoolId) {
    return this.http
      .get<any>(this.evolutionApiUrl + '/api/school/getSchool/' + schoolId)
      .subscribe((data) => {
        //console.log(data)
        this.school = data;
        this.schoolUpdated.next(this.school);
      });
  }
  currentYear() {
    return this.http
      .get<any>(this.evolutionApiUrl + '/api/school/currentYear/')
      .pipe(tap((res) => console.log(res)));
  }

  schoolYearChanged() {
    return this.http
      .get<any>(this.evolutionApiUrl + '/api/school/schoolYearChanged/')
      .pipe(tap((res) => console.log(res)));
  }
}
