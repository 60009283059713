import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeformatPipe } from './timeformat.pipe';
import { MinuteSecondsPipe } from './minuteseconds.pipe';
import { ReadingTimeFormatPipe } from './readingtimeformat.pipe';
import { RoundPipe } from './round.pipe';
import { ReplacePipe } from './replace.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TimeformatPipe,
    MinuteSecondsPipe,
    ReadingTimeFormatPipe,
    RoundPipe,
    ReplacePipe,
  ],
  exports: [
    TimeformatPipe,
    MinuteSecondsPipe,
    ReadingTimeFormatPipe,
    RoundPipe,
    ReplacePipe,
  ],
})
export class PipeModule {}
