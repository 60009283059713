import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { StudentEditComponent } from '../student-edit/student-edit.component';
import { takeUntil } from 'rxjs/operators';
import { StudentService } from '../../providers/student.service';
import { TaskService } from '../../providers/task.service';
import { Subscription, Subject } from 'rxjs';
import { TaskCreateComponent } from '../../task/task-create/task-create.component';
import { StudentDeleteComponent } from '../student-delete/student-delete.component';
import * as shape from 'd3-shape';
import {
  barChart,
  barChartGroup,
  lineChart,
} from '../../class/class-detail/chartData';
import { NotificationService } from '../../providers/notification.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { AdminService } from 'src/app/providers/admin.service';
export interface Tasks {
  completed_at?: Date;
  deadline?: Date;
}

export interface Group {
  name?: string;
  grade?: string;
  stream?: string;
  school_year?: string;
}
export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: Group[];
  notes?: any;
  _id?: string;
  _school?: any;
  licenseInfo?: [];
}
@Component({
  selector: 'app-student-overview',
  templateUrl: './student-overview.component.html',
  styleUrls: ['./student-overview.component.scss'],
})
export class StudentOverviewComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  studentId;
  teacherId;
  userId;
  activeTab = 'isActive';
  selectedTabIndex = 0;
  tasksTabs;
  student: Student;
  finishedTasks = 0;
  readWords = 0;
  generalPerformance = 0;
  medalsCount = 0;
  medals = {
    gold: 0,
    silver: 0,
    bronze: 0,
  };
  sourceData = [];
  public data = [];
  tasks: Tasks[] = [];
  view: any[] = [800, 300];
  chartData;
  barChartGroup;
  showProgressChart: boolean = false;
  linearCurve = shape.curveBumpX;
  currentChartPeriod: string = 'half_year';
  selectedTaskView: string = 'table';
  selectedLicenseView: string = 'license-2';
  progressChartData = [
    {
      name: 'Lesepunkte',
      series: [],
    },
    {
      name: 'Lesefehler',
      series: [],
    },
    {
      name: 'Geschwindigkeit',
      series: [],
    },
    {
      name: 'Stimmdynamik',
      series: [],
    },
    {
      name: 'Klasse Lesepunkte',
      series: [],
    },
    {
      name: 'Klasse Lesefehler',
      series: [],
    },
    {
      name: 'Klasse Geschwindigkeit',
      series: [],
    },
    {
      name: 'Klasse Stimmdynamik',
      series: [],
    },
  ];
  comboChartData;
  showComboChart: boolean = false;
  showLineChart: boolean = false;
  lineChartData = [
    {
      name: 'Leseflüssigkeit (Förderung)',
      series: [],
    },
  ];

  // options
  legend: boolean = true;
  legendTitle: string = '';
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  timeline: boolean = true;
  legendPosition: string = 'below';
  colorScheme = {
    domain: ['#03CC90', '#7FB2FF', '#FECF2F', '#FB9CB3'],
  };
  private studentTasksSub: Subscription;
  groupGrade;
  studentName;
  studentTasks;
  tasksLoaded: boolean = false;
  // dummy
  todayDate: Date = new Date();
  notes = [
    {
      content:
        'Consectetur adipiscing elit duis tristique sollicitudin nibh sit',
      _created: this.todayDate,
    },
    {
      content:
        'Consectetur adipiscing elit duis tristique sollicitudin nibh sit',
      _created: this.todayDate,
    },
    {
      content:
        'Consectetur adipiscing elit duis tristique sollicitudin nibh sit',
      _created: this.todayDate,
    },
    {
      content:
        'Consectetur adipiscing elit duis tristique sollicitudin nibh sit',
      _created: this.todayDate,
    },
  ];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  yAxisLabel = 'Leseflüssigkeit (Diagnose)';
  showGridLines = true;
  innerPadding = '10%';
  barChart: any;
  lineChartSeries: any[] = lineChart;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#59D7AF'],
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#59D7AF'],
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';
  finishedTasksArray;
  activeTasksArray;
  expiredTasksArray;
  private notificationSub: Subscription;
  diagnoseLicense;
  trainingLicense;
  trainingMinutesLeft;
  trainingMinutesTotal;
  trainingMinutesProzent;
  groupId;
  group;
  translatedText;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private studentService: StudentService,
    private taskService: TaskService,
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private adminService: AdminService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        console.log(this.translatedText);
        console.log(this.progressChartData);
        this.progressChartData[0].name =
          this.translatedText?.student_overview_reading_points;
        this.progressChartData[1].name =
          this.translatedText?.student_overview_reading_errors;
        this.progressChartData[2].name =
          this.translatedText?.student_overview_reading_speed;
        this.progressChartData[3].name =
          this.translatedText?.student_overview_reading_dynamic;
        this.progressChartData[4].name =
          this.translatedText?.student_overview_group_reading_points;
        this.progressChartData[5].name =
          this.translatedText?.student_overview_group_reading_errors;
        this.progressChartData[6].name =
          this.translatedText?.student_overview_group_reading_speed;
        this.progressChartData[7].name =
          this.translatedText?.student_overview_group_reading_dynamic;
        this.lineChartData[0].name =
          this.translatedText?.student_overview_reading_fluency_training;
        this.yAxisLabel =
          this.translatedText?.student_overview_reading_fluency_diagnose;
        this.cdr.detectChanges();
      });
    this.studentId = this.route.snapshot.params['id'];
    this.groupId = this.route.snapshot.queryParams.groupId;
    console.log(this.groupId);
    this.teacherId = this.authService.getTeacherId();
    this.studentService
      .getStudentbyId(this.studentId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        this.student = res;
        this.group = res?._group.filter((item) => item['_id'] === this.groupId);
        console.log(this.group);
        //dummy
        // this.student = res.map(obj => {
        //   obj["notes"] = this.notes;
        //   return obj;
        // })

        this.groupGrade = this.getGroupName(res._group)['grade'];
        this.studentName = res['username'];
        this.studentTasks = res['_tasks'];
        this.userId = res['_user'];
        this.tasksLoaded = true;
      });
    this.studentService
      .getStudentOverview(this.teacherId, this.studentId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.medals.bronze = res.medals.bronze;
        this.medals.silver = res.medals.silver;
        this.medals.gold = res.medals.gold;
        this.medalsCount =
          this.medals.bronze + this.medals.silver + this.medals.gold;
        this.finishedTasks = res.finishedTasks;
        this.readWords = res.readWords;
        this.generalPerformance = res.generalPerformance;

        if (res['bar_chart_data']) {
          this.comboChartData = res['bar_chart_data'];
          if (
            this.comboChartData?.pre_test?.monthly?.reading_combined_score
              .length > 0
          ) {
            this.barChart =
              this.comboChartData?.pre_test?.monthly?.reading_combined_score;
            // dummy group
            this.barChartGroup = barChartGroup;
            this.lineChartData[0].series =
              this.comboChartData?.studying?.monthly?.reading_combined_score;
            this.showComboChart = true;
            this.showLineChart = true;
          }
        }
        // Chart data
        if (res['chart_data']) {
          this.chartData = res['chart_data'];
          if (this.chartData?.self?.monthly?.reading_combined_score) {
            this.progressChartData[0].series =
              this.chartData?.self?.monthly?.reading_combined_score.slice(
                6,
                this.chartData?.self?.monthly?.reading_combined_score.length,
              ); // last 6 month
          }
          if (res?.chart_data?.self?.monthly?.reading_errors_score) {
            this.progressChartData[1].series =
              this.chartData?.self?.monthly?.reading_errors_score.slice(
                6,
                this.chartData?.self?.monthly?.reading_errors_score.length,
              ); // last 6 month
          }
          if (res?.chart_data?.self?.monthly?.reading_speed_words_normalized) {
            this.progressChartData[2].series =
              this.chartData?.self?.monthly?.reading_speed_words_normalized.slice(
                6,
                this.chartData?.self?.monthly?.reading_speed_words_normalized
                  .length,
              ); // last 6 month
          }
          if (res?.chart_data?.self?.monthly?.reading_dynamic_normalized) {
            this.progressChartData[3].series =
              this.chartData?.self?.monthly?.reading_dynamic_normalized.slice(
                6,
                this.chartData?.self?.monthly?.reading_dynamic_normalized
                  .length,
              ); // last 6 month
          }
          if (
            res?.chart_data?.self?.monthly?.reading_combined_score.length > 0 ||
            res?.chart_data?.self?.monthly?.reading_errors_score.length.length >
              0 ||
            res?.chart_data?.self?.monthly?.reading_speed_words_normalized
              .length > 0 ||
            res?.chart_data?.self?.monthly?.reading_dynamic_normalized.length >
              0
          ) {
            this.progressChartData = [...this.progressChartData];
            this.showProgressChart = true;
            this.updateChartLegend('initial');
          }
        }
      });
    this.taskService.getTasksToStudents(this.studentId);
    this.studentTasksSub = this.taskService
      .getTasksToStudentsUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.tasks = res;
        console.log(this.tasks);
        this.activeTasksArray = res.filter(
          (item) => item['status'] === 'active',
        );
        this.finishedTasksArray = res.filter(
          (item) => item['status'] === 'finished',
        );
        this.expiredTasksArray = res.filter(
          (item) => item['status'] === 'expired',
        );
        // Get active tab from url
        let activeTabLocation = this.route.snapshot.fragment;
        if (activeTabLocation) {
          // Set active tab on page load
          this.selectedTabIndex = Number(activeTabLocation);
          this.setActiveTabByIndex(this.selectedTabIndex);
        }
      });

    // this.licenseService.getLicenseToStudent(this.studentId);
    // this.licenseService.getLicenseToStudentUpdateListener().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe((res:any) => {
    //   console.log(res);
    //   this.diagnoseLicense = res.filter(item => item?.type_key == "di");
    //   this.trainingLicense = res.filter(item => item?.type_key == "fo");
    //   this.trainingMinutesLeft = this.trainingLicense.reduce((sum, item) => sum + item.minutes_left, 0);
    //   this.trainingMinutesTotal = this.trainingLicense.reduce((sum, item) => sum + item.minutes_total, 0);

    //   this.trainingMinutesProzent = (this.trainingMinutesTotal - this.trainingMinutesLeft) / this.trainingMinutesTotal * 100;
    //   console.log(this.trainingMinutesProzent)
    // });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.setActiveTabByIndex(tabChangeEvent.index);
  }

  setActiveTabByIndex(index) {
    if (index === 0) {
      this.activeTab = 'isActive';
    } else if (index === 1) {
      this.activeTab = 'isFinished';
    } else if (index === 2) {
      this.activeTab = 'isExpired';
    } else if (index === 3) {
      this.activeTab = 'isselfing';
    }
    this.location.replaceState(this.location.path() + '#' + index);
  }

  createTaskOverlay(): void {
    let dialogRef = this.dialog.open(TaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-class-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-class-overlay-panel-backdrop',
      data: {
        student: this.student,
        isSingleStudent: true,
        studentId: this.studentId,
        groupGrade: this.groupGrade,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  editStudentOverlay() {
    let dialogRef = this.dialog.open(StudentEditComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student_edit-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'student_edit-overlay-panel-backdrop',
      data: {
        id: this.studentId,
        // editMode: editMode,
        studentLicenses: this.student.licenseInfo,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  // openStudentDeleteDialog(): void {
  // 	let dialogRef;
  // 	  dialogRef = this.dialog.open(StudentDeleteComponent, {
  // 		width: '100%',
  // 		autoFocus: false,
  // 		panelClass: 'student-delete-overlay-panel',
  // 	   // hasBackdrop: false,
  // 		disableClose: true,
  // 		backdropClass: 'student-delete-overlay',
  // 		data: {
  // 			studentId: this.studentId,
  //       studentName: this.studentName
  // 		}
  // 	  });
  // 	// dialogRef.disableClose = true;
  // 	dialogRef.afterClosed().pipe(
  // 	  takeUntil(this._unsubscribeAll)
  // 	).subscribe(res => {
  //     console.log(res)
  //     if(res != 'close') {
  //       this.location.back()
  //     }
  // 	});
  // }

  selectedResult(event) {
    this.currentChartPeriod = event;
    if (this.currentChartPeriod == 'last_month') {
      if (this.progressChartData[0].series.length > 0) {
        this.progressChartData[0].series = this.chartData?.self?.weekly[
          'reading_combined_score'
        ].slice(
          8,
          this.chartData?.self?.weekly['reading_combined_score'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[0].series = [];
      }

      if (this.progressChartData[1].series.length > 0) {
        this.progressChartData[1].series = this.chartData?.self?.weekly[
          'reading_errors_score'
        ].slice(8, this.chartData?.self?.weekly['reading_errors_score'].length); // last 4 weeks
      } else {
        this.progressChartData[1].series = [];
      }

      if (this.progressChartData[2].series.length > 0) {
        this.progressChartData[2].series = this.chartData?.self?.weekly[
          'reading_speed_words_normalized'
        ].slice(
          8,
          this.chartData?.self?.weekly['reading_speed_words_normalized'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[2].series = [];
      }

      if (this.progressChartData[3].series.length > 0) {
        this.progressChartData[3].series = this.chartData?.self?.weekly[
          'reading_dynamic_normalized'
        ].slice(
          8,
          this.chartData?.self?.weekly['reading_dynamic_normalized'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[3].series = [];
      }

      // reset group data
      if (this.progressChartData[4].series.length > 0) {
        this.progressChartData[4].series = this.chartData?.group[
          this.groupId
        ]?.weekly['reading_combined_score'].slice(
          8,
          this.chartData?.group[this.groupId]?.weekly['reading_combined_score']
            .length,
        ); // last 4 weeks
      } else {
        this.progressChartData[4].series = [];
      }

      if (this.progressChartData[5].series.length > 0) {
        this.progressChartData[5].series = this.chartData?.group[
          this.groupId
        ]?.weekly['reading_errors_score'].slice(
          8,
          this.chartData?.group[this.groupId]?.weekly['reading_errors_score']
            .length,
        ); // last 4 weeks
      } else {
        this.progressChartData[5].series = [];
      }

      if (this.progressChartData[6].series.length > 0) {
        this.progressChartData[6].series = this.chartData?.group[
          this.groupId
        ]?.weekly['reading_speed_words_normalized'].slice(
          8,
          this.chartData?.group[this.groupId]?.weekly[
            'reading_speed_words_normalized'
          ].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[6].series = [];
      }

      if (this.progressChartData[7].series.length > 0) {
        this.progressChartData[7].series = this.chartData?.group[
          this.groupId
        ]?.weekly['reading_dynamic_normalized'].slice(
          8,
          this.chartData?.group[this.groupId]?.weekly[
            'reading_dynamic_normalized'
          ].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[7].series = [];
      }
    }

    if (this.currentChartPeriod == 'half_year') {
      if (this.progressChartData[0].series.length > 0) {
        this.progressChartData[0].series = this.chartData?.self?.monthly[
          'reading_combined_score'
        ].slice(
          6,
          this.chartData?.self?.monthly['reading_combined_score'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[0].series = [];
      }

      if (this.progressChartData[1].series.length > 0) {
        this.progressChartData[1].series = this.chartData?.self?.monthly[
          'reading_errors_score'
        ].slice(
          6,
          this.chartData?.self?.monthly['reading_errors_score'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[1].series = [];
      }

      if (this.progressChartData[2].series.length > 0) {
        this.progressChartData[2].series = this.chartData?.self?.monthly[
          'reading_speed_words_normalized'
        ].slice(
          6,
          this.chartData?.self?.monthly['reading_speed_words_normalized']
            .length,
        ); // last 4 weeks
      } else {
        this.progressChartData[2].series = [];
      }

      if (this.progressChartData[3].series.length > 0) {
        this.progressChartData[3].series = this.chartData?.self?.monthly[
          'reading_dynamic_normalized'
        ].slice(
          6,
          this.chartData?.self?.monthly['reading_dynamic_normalized'].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[3].series = [];
      }

      // reset group data
      if (this.progressChartData[4].series.length > 0) {
        this.progressChartData[4].series = this.chartData?.group[
          this.groupId
        ]?.monthly['reading_combined_score'].slice(
          6,
          this.chartData?.group[this.groupId]?.monthly['reading_combined_score']
            .length,
        ); // last 4 weeks
      } else {
        this.progressChartData[4].series = [];
      }

      if (this.progressChartData[5].series.length > 0) {
        this.progressChartData[5].series = this.chartData?.group[
          this.groupId
        ]?.monthly['reading_errors_score'].slice(
          6,
          this.chartData?.group[this.groupId]?.monthly['reading_errors_score']
            .length,
        ); // last 4 weeks
      } else {
        this.progressChartData[5].series = [];
      }

      if (this.progressChartData[6].series.length > 0) {
        this.progressChartData[6].series = this.chartData?.group[
          this.groupId
        ]?.monthly['reading_speed_words_normalized'].slice(
          6,
          this.chartData?.group[this.groupId]?.monthly[
            'reading_speed_words_normalized'
          ].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[6].series = [];
      }

      if (this.progressChartData[7].series.length > 0) {
        this.progressChartData[7].series = this.chartData?.group[
          this.groupId
        ]?.monthly['reading_dynamic_normalized'].slice(
          6,
          this.chartData?.group[this.groupId]?.monthly[
            'reading_dynamic_normalized'
          ].length,
        ); // last 4 weeks
      } else {
        this.progressChartData[7].series = [];
      }
    }

    this.progressChartData = [...this.progressChartData];
    this.updateChartLegend('update');
  }

  onSelect(event) {
    console.log(event);
    if (event == 'Lesepunkte' || event == 'Reading points') {
      if (this.progressChartData[0].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[0].series =
            this.chartData?.self?.weekly?.reading_combined_score.slice(
              8,
              this.chartData?.self?.weekly?.reading_combined_score.length,
            ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[0].series =
            this.chartData?.self?.monthly?.reading_combined_score.slice(
              6,
              this.chartData?.self?.monthly?.reading_combined_score.length,
            ); // last 6 month
        }
      } else {
        this.progressChartData[0].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Lesefehler' || event == 'Reading errors') {
      if (this.progressChartData[1].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[1].series =
            this.chartData?.self?.weekly?.reading_errors_score.slice(
              8,
              this.chartData?.self?.weekly?.reading_errors_score.length,
            ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[1].series =
            this.chartData?.self?.monthly?.reading_errors_score.slice(
              6,
              this.chartData?.self?.monthly?.reading_errors_score.length,
            ); // last 6 month
        }
        this.showProgressChart = true;
      } else {
        this.progressChartData[1].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Geschwindigkeit' || event == 'Reading speed') {
      if (this.progressChartData[2].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[2].series =
            this.chartData?.self?.weekly?.reading_speed_words_normalized.slice(
              8,
              this.chartData?.self?.weekly?.reading_speed_words_normalized
                .length,
            ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[2].series =
            this.chartData?.self?.monthly?.reading_speed_words_normalized.slice(
              6,
              this.chartData?.self?.monthly?.reading_speed_words_normalized
                .length,
            ); // last 6 month
        }
        this.showProgressChart = true;
      } else {
        this.progressChartData[2].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Stimmdynamik' || event == 'Voice dynamics') {
      if (this.progressChartData[3].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[3].series =
            this.chartData?.self?.weekly?.reading_dynamic_normalized.slice(
              8,
              this.chartData?.self?.weekly?.reading_dynamic_normalized.length,
            ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[3].series =
            this.chartData?.self?.monthly?.reading_dynamic_normalized.slice(
              6,
              this.chartData?.self?.monthly?.reading_dynamic_normalized.length,
            ); // last 6 month
        }
        this.showProgressChart = true;
      } else {
        this.progressChartData[3].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Klasse Lesepunkte' || event == 'Class reading points') {
      if (this.progressChartData[4].series.length === 0) {
        console.log(this.chartData);
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[4].series = this.chartData?.group[
            this.groupId
          ]?.weekly?.reading_combined_score.slice(
            8,
            this.chartData?.group[this.groupId]?.weekly?.reading_combined_score
              .length,
          ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[4].series = this.chartData?.group[
            this.groupId
          ]?.monthly?.reading_combined_score.slice(
            this.chartData?.group[this.groupId]?.monthly?.reading_combined_score
              .length / 2,
            this.chartData?.group[this.groupId]?.monthly?.reading_combined_score
              .length,
          ); // last 6 month
        }
      } else {
        this.progressChartData[4].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Klasse Lesefehler' || event == 'Class reading errors') {
      if (this.progressChartData[5].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[5].series = this.chartData?.group[
            this.groupId
          ]?.weekly?.reading_errors_score.slice(
            8,
            this.chartData?.group[this.groupId]?.weekly?.reading_errors_score
              .length,
          ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[5].series = this.chartData?.group[
            this.groupId
          ]?.monthly?.reading_errors_score.slice(
            this.chartData?.group[this.groupId]?.monthly?.reading_errors_score
              .length / 2,
            this.chartData?.group[this.groupId]?.monthly?.reading_errors_score
              .length,
          ); // last 6 month
        }
      } else {
        this.progressChartData[5].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Klasse Geschwindigkeit' || event == 'Class reading speed') {
      if (this.progressChartData[6].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[6].series = this.chartData?.group[
            this.groupId
          ]?.weekly?.reading_speed_words_normalized.slice(
            8,
            this.chartData?.group[this.groupId]?.weekly
              ?.reading_speed_words_normalized.length,
          ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[6].series = this.chartData?.group[
            this.groupId
          ]?.monthly?.reading_speed_words_normalized.slice(
            this.chartData?.group[this.groupId]?.monthly
              ?.reading_speed_words_normalized.length / 2,
            this.chartData?.group[this.groupId]?.monthly?.reading_errors_score
              .length,
          ); // last 6 month
        }
      } else {
        this.progressChartData[6].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }

    if (event == 'Klasse Stimmdynamik' || event == 'Class voice dynamics') {
      if (this.progressChartData[7].series.length === 0) {
        if (this.currentChartPeriod == 'last_month') {
          this.progressChartData[7].series = this.chartData?.group[
            this.groupId
          ]?.weekly?.reading_dynamic_normalized.slice(
            8,
            this.chartData?.group[this.groupId]?.weekly
              ?.reading_dynamic_normalized.length,
          ); // last 4 weeks
        } else if (this.currentChartPeriod == 'half_year') {
          this.progressChartData[7].series = this.chartData?.group[
            this.groupId
          ]?.monthly?.reading_dynamic_normalized.slice(
            this.chartData?.group[this.groupId]?.monthly
              ?.reading_dynamic_normalized.length / 2,
            this.chartData?.group[this.groupId]?.monthly?.reading_errors_score
              .length,
          ); // last 6 month
        }
      } else {
        this.progressChartData[7].series = [];
      }
      this.progressChartData = [...this.progressChartData];
    }
  }

  navigateBack(): void {
    this.location.back();
  }

  updateChartLegend(status) {
    // elements = null;
    var elements = document.getElementsByClassName('legend-label');
    setTimeout(() => {
      if (elements != null) {
        for (let index = 0; index < elements.length; index++) {
          // console.log(elements[index].classList);
          // remove classes for dropdown
          elements[index].classList.remove('legend-label--checkbox');
          // elements[2].classList.remove('primary', 'checkbox_dashed', 'legend-label--active');
          // elements[3].classList.remove('secondary', 'checkbox_dashed', 'legend-label--active');
          // elements[4].classList.remove('main_yellow', 'checkbox_dashed', 'legend-label--active');
          // elements[5].classList.remove('main_pink', 'checkbox_dashed', 'legend-label--active');

          // add classes
          elements[index].classList.add('legend-label--checkbox');
          // normal checkbox
          if (status == 'initial') {
            // normal checkbox
            elements[2] &&
              elements[2].classList.add(
                'label_icon_white',
                'legend-label--active',
              );
            elements[3] &&
              elements[3].classList.add(
                'label_icon_white',
                'legend-label--active',
              );
            elements[4] &&
              elements[4].classList.add(
                'label_icon_white',
                'legend-label--active',
              );
            elements[5] &&
              elements[5].classList.add(
                'label_icon_white',
                'legend-label--active',
              );
          }
          //dashed checkbox
          elements[6] &&
            elements[6].classList.add('primary', 'checkbox_dashed');
          elements[7] &&
            elements[7].classList.add('secondary', 'checkbox_dashed');
          elements[8] &&
            elements[8].classList.add('main_yellow', 'checkbox_dashed');
          elements[9] &&
            elements[9].classList.add('main_pink', 'checkbox_dashed');
          elements[index].removeAllListeners();
          // Click listener
          elements[index].addEventListener('click', function onClick() {
            console.log(elements[index]);
            if (elements[index].classList.contains('legend-label--active')) {
              elements[index].classList.remove('legend-label--active');
            } else {
              elements[index].classList.add('legend-label--active');
            }
          });
        }
      }
    }, 0);
  }

  countTasksByStatus(status) {
    let tasks = this.tasks.filter((item) => {
      return item['status'] == status && item['type'] != 'self';
    });
    switch (status) {
      case 'finished':
        if (tasks.length > 0) {
          tasks.sort((a, b) => {
            if (a?.completed_at > b?.completed_at) {
              return -1;
            }
          });
        }
        break;
      case 'active':
        if (tasks.length > 0) {
          tasks.sort((a, b) => {
            if (a?.deadline < b?.deadline) {
              return -1;
            }
          });
        }
        break;
      case 'expired':
        if (tasks.length > 0) {
          tasks.sort((a, b) => {
            if (a?.deadline > b?.deadline) {
              return -1;
            }
          });
        }
        break;
      default:
        break;
    }
    return tasks.length;
  }

  addNoteToStudent() {}

  autoModeToggleChange(value) {
    console.log(value.currentTarget.checked);
  }

  chartToggleChange(value) {
    console.log(value.currentTarget.checked);
    var line_chart = document.getElementsByClassName('line-chart');
    if (value.currentTarget.checked == true) {
      line_chart[0].classList.add('line_chart_active');
    } else {
      line_chart[0].classList.remove('line_chart_active');
    }
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  onSelectedViewChange(value) {
    this.selectedTaskView = value;
  }

  onSelectedLicenseViewChange(value) {
    this.selectedLicenseView = value;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
