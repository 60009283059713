<app-task-edit-training-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeOverlay($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="deadlineStep">
    <div class="training-task_select_container">
      <form [formGroup]="deadlineStep">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text">{{
            translatedText.task_edit_training_training
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.task_edit_training_title }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.task_edit_training_description }}
          </p>
        </div>
        <div class="timeStepForm">
          <mat-form-field>
            <mat-label>{{ translatedText.date_format }}</mat-label>
            <input
              matInput
              [min]="todayDate"
              onkeypress="return false"
              formControlName="deadline"
              type="text"
              (click)="deadlinepicker.open()"
              [matDatepicker]="deadlinepicker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="deadlinepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #deadlinepicker></mat-datepicker>
            <mat-error
              *ngIf="deadlineStep.get('deadline').hasError('required')"
            >
              <!-- Please choose a deadline. -->
              {{ translatedText.task_edit_training_deadline_error }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="training-task_select_button">
          <button
            mat-flat-button
            color="unset"
            class="mat-flat-button--full"
            (click)="closeOverlay(true)"
          >
            {{ translatedText.task_edit_training_back }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="mat-flat-button--full"
            cdkStepperNext
          >
            {{ translatedText.task_edit_training_next }}
          </button>
        </div>
      </form>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="groupSelectStep">
    <div class="training-task_select_container">
      <form
        [formGroup]="groupSelectStep"
        #formDirective="ngForm"
        class="third_step_accorddion"
      >
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text">{{
            translatedText.task_edit_training_training
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.task_edit_training_title }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.task_edit_training_info }}
          </p>
        </div>
        <div
          *ngIf="
            groups?.length == 0 ||
            (groups.length > 0 &&
              filterGroupsWithoutPersonalData(groups)?.length == 0)
          "
          class="empty_tasks_container"
        >
          <img src="../../../assets/empty.svg" alt="" />
          <p class="paragraph">
            <span *ngIf="userType == 'school_admin'"
              >{{ translatedText.task_edit_training_no_group }}.</span
            >
            <span *ngIf="userType == 'teacher'">{{
              translatedText.task_edit_training_check_information
            }}</span>
          </p>
        </div>
        <div class="accordion_wrap">
          <mat-accordion [togglePosition]="'before'" multi>
            <mat-expansion-panel
              *ngFor="let groups of filterGroupsWithoutPersonalData(groups)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-checkbox
                    (change)="toggleSelectAll(students, $event.checked)"
                    [checked]="students.selectedOptions.selected.length > 0"
                    class="example-margin"
                    (click)="$event.stopPropagation()"
                    [disabled]="
                      disableSelectAll(
                        groups.group?._students?.length,
                        getLicenseAmount([], 'fo', 'left'),
                        groups?.taskOverview?.child_tasks_blocked_minutes,
                        groups.group
                      )
                    "
                  >
                  </mat-checkbox>
                  <div class="group-select">
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ groups.group.name }} -
                      {{ groups.group?.school_year | slice: 2 }}
                    </p>
                    <p class="paragraph">
                      {{ translatedText.task_edit_training_selected }}
                      <span class="paragraph paragraph--bold"
                        >{{ students.selectedOptions.selected.length }}/{{
                          groups?.group?._students?.length
                        }}
                      </span>
                    </p>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  <div class="license-amount"></div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list
                #students
                (selectionChange)="
                  handleSelection($event, groupSelectStep.value)
                "
                [name]="groups.group._students"
                formControlName="students"
              >
                <mat-list-option
                  checkboxPosition="before"
                  *ngFor="let student of groups.group._students; index as i"
                  [value]="student"
                  [selected]="checkForSelection(student, i)"
                  [disabled]="
                    isOptionDisabled(
                      groups.group,
                      groups?.taskOverview?.child_tasks_blocked_minutes,
                      students.selectedOptions.selected,
                      student
                    ) || student?.status == 'inactive'
                  "
                >
                  <div class="list-item-element">
                    <div class="inline_text">
                      <img
                        class="avatar_img--small"
                        *ngIf="student?.avatarUrl"
                        src="{{ student?.avatarUrl }}"
                      />
                      <p class="paragraph username-with-avatar">
                        {{ student?.username }}
                      </p>
                    </div>
                    <p
                      *ngIf="
                        isOptionDisabled(
                          groups?.group,
                          groups?.taskOverview?.child_tasks_blocked_minutes,
                          students.selectedOptions.selected,
                          student
                        )
                      "
                      class="paragraph license-time"
                    >
                      {{
                        translatedText.task_edit_training_no_assigned_license
                      }}
                    </p>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- {{students}} -->
      </form>
      <div class="training-task_select_button">
        <button
          mat-flat-button
          color="unset"
          class="mat-flat-button--full"
          cdkStepperPrevious
        >
          {{ translatedText.task_edit_training_back }}
        </button>
        <button
          mat-flat-button
          color="primary"
          class="mat-flat-button--full"
          (click)="submitTask(formDirective)"
          [disabled]="studentsArray.length == 0 || isLoading"
        >
          {{ translatedText.task_edit_training_save }}
        </button>
      </div>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <div class="last_step_container edit">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h3 class="h3 h3--bold">
        {{ translatedText.task_edit_training_success }}
      </h3>
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="closeOverlay(true)"
      >
        {{ translatedText.task_edit_training_close }}
      </button>
    </div>
  </cdk-step>
</app-task-edit-training-stepper>
