import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { TeacherService } from '../../providers/teacher.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { AdminManagingOverlayComponent } from 'src/app/helpers/admin-managing-overlay/admin-managing-overlay.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { default as _rollupMoment } from 'moment';
import { ActivatedRoute } from '@angular/router';

export interface DialogData {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class AdminEditComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  id;
  admin;
  subscription: Subscription;
  status: string = 'Aktiv';
  editMode: boolean = false;
  userType;
  isLoading: boolean = false;
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation: string[] = ['Herr', 'Frau', 'Divers'];
  showAddButton: boolean = false;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  adminStatus;
  teacherId;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private translationService: TranslationService,
    private teacherService: TeacherService,
  ) {
    if (injectedData) {
      if (typeof injectedData['id'] !== 'undefined') {
        this.id = this.injectedData['id'];
      }
      if (typeof injectedData['editMode'] !== 'undefined') {
        this.editMode = this.injectedData['editMode'];
      }
    }
  }

  ngOnInit() {
    this.userType = this.authService.getType();
    this.teacherId = this.authService.getTeacherId();
    this.teacherService
      .readSingleTeacher(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.admin = res;
        this.adminStatus = res['status'];
        console.log(res);
      });

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any) => {
        this.translatedText = translatedText;
        this.salutation = [
          this.translatedText.salutation_male,
          this.translatedText.salutation_female,
          this.translatedText.salutation_divers,
        ];
      });

    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.initForm();
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  initForm() {
    this.rForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
    });

    this.teacherService.readSingleTeacher(this.id).subscribe((res) => {
      this.admin = res;
      console.log(res);
      this.rForm.get('salutation').setValue(res['salutation']);
      this.rForm.get('firstname').setValue(res['firstname']);
      this.rForm.get('lastname').setValue(res['lastname']);
    });
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  updateAdminRights(): void {
    let dialogRef = this.dialog.open(AdminManagingOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        admin: this.admin,
        managingType: 'demote',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  deleteAdminOverlay(): void {
    let dialogRef = this.dialog.open(AdminManagingOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'group-delete-overlay',
      data: {
        admin: this.admin,
        managingType: 'delete',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  updateAdminStatus() {
    let newStatus = this.adminStatus === 'active' ? 'inactive' : 'active';
    let formData = {
      teacherId: this.admin._id,
      status: newStatus,
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        this.adminStatus = res['status'];
        // Update teacher list
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    let formData = {
      teacherId: this.id,
      salutation: this.rForm.value.salutation,
      firstname: this.rForm.value.firstname,
      lastname: this.rForm.value.lastname,
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.admin_edit_success,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  editPersonalInfo() {
    this.editMode = true;
  }

  backToPersonalInfo() {
    this.editMode = false;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
