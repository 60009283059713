import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GroupService } from '../../providers/group.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-class-pdf-print',
  templateUrl: './class-pdf-print.component.html',
  styleUrls: ['./class-pdf-print.component.scss'],
})
export class ClassPdfPrintComponent implements OnInit {
  environment = environment;
  teacherId;
  groupId;
  groupName;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading: boolean = false;
  translatedText;
  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private groupService: GroupService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
    }
  }

  ngOnInit() {
    this.teacherId = this.authService.getTeacherId();
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  regeneratePdfForGroup() {
    this.isLoading = true;
    this.groupService
      .regeneratePdfForGroup(
        this.groupId,
        this.teacherId,
        false,
        true,
        false,
        [],
        this.translatedText,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response: any) => {
          if (response) {
            this.dialog.closeAll();
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.groupService.openPdfOverlay(
              blobUrl,
              this.groupName,
              'rebuild',
            );
            //this.isLoading = false;
          }
        },
        (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }
}
