import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { TaskErrorOverlayComponent } from './task-error-overlay.component';
@NgModule({
  declarations: [TaskErrorOverlayComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [TaskErrorOverlayComponent],
})
export class TaskErrorOverlayModule {}
