<div class="student_task_wrap student_task_wrap--action" *ngIf="isActive">
  <ng-container>
    <div class="row">
      <div class="col-md-12" *ngFor="let task of getTasksByStatus('active')">
        <div class="student_task active_tab" [attr.task-id]="task?._id">
          <div class="student_task_inner">
            <div class="set_inline student_task_header">
              <div class="student_task_chip_header hidden-mobile">
                <div class="student_chip student_chip__deadline" *ngIf="currentDate != taskDeadline(task?.deadline)">
                  <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                  <p class="paragraph">
                    {{ calculateDaysLeft(task?.deadline) }}
                  </p>
                </div>
                <!-- <div class="student_chip student_chip__minimum" *ngIf="task?.minimum != null">
                                    <span class="minimum">MIN</span>
                                    <p class="paragraph">Minuten pro Woche</p>
                                </div> -->
                <div class="student_chip student_chip__minimum" *ngIf="task?.mode == 'multiple'">
                  <p class="paragraph paragraph--bold">
                    {{ calculateReadTime(task) }}
                    {{ translatedText.student_task_list_out_of }}
                    {{ task?.time }}
                    {{
                      task?.time === 1
                        ? translatedText.student_task_list_minute
                        : translatedText.student_task_list_minutes_multiple
                    }}
                    {{ translatedText.student_task_list_read }}
                  </p>
                  <app-student-progress-bar
                    [progress]="generatePercentForContinousTask([task])"
                  ></app-student-progress-bar>
                </div>
                <div
                  class="student_chip student_chip__deadline student_chip__deadline--reached"
                  *ngIf="currentDate == taskDeadline(task?.deadline)"
                >
                  <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                  <p class="paragraph deadline_task">
                    {{ translatedText.student_task_list_today_expired }}
                  </p>
                </div>
              </div>
              <div class="student_chip student_chip__teacher no_bg">
                <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
                <p class="paragraph">
                  <span *ngFor="let teacher of task?._teacher; let i = index">
                    <ng-container *ngIf="teacher?.status == undefined || teacher?.status == 'active'">
                      <span [hidden]="i == 0"> / </span
                      ><!-- || task?._teacher[i - 1]?.status == 'inactive' -->
                      {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                    </ng-container>
                  </span>
                </p>
              </div>
            </div>
            <div class="student_task_info_wrap">
              <div class="student_task_info">
                <mat-icon svgIcon="streamline_filled:lightning" class="hidden-mobile"></mat-icon>
                <p class="paragraph paragraph--l">
                  <ng-container *ngIf="task?.type == 'text'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    ><span *ngIf="task?.book_name">
                      {{ translatedText.student_task_list_in }}
                      <strong>{{ replaceHtmlContent(task?.book_name) }}</strong></span
                    >.
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time-choice'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    >
                    {{ translatedText.student_task_list_read_book }}
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'autonomous-placement'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                </p>
              </div>
              <div class="student_task_chip_header hidden-tablet">
                <div class="student_chip student_chip__deadline" *ngIf="currentDate != taskDeadline(task?.deadline)">
                  <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                  <p class="paragraph">
                    {{ calculateDaysLeft(task?.deadline) }}
                  </p>
                </div>
                <!-- <div class="student_chip student_chip__minimum" *ngIf="task?.minimum != null">
                                    <span class="minimum">MIN</span>
                                    <p class="paragraph">Minuten pro Woche</p>
                                </div> -->
                <div class="student_chip student_chip__minimum" *ngIf="task?.mode == 'multiple'">
                  <p class="paragraph paragraph--bold">
                    {{ calculateReadTime(task) }}
                    {{ translatedText.student_task_list_out_of }}
                    {{ task?.time }}
                    {{
                      task?.time === 1
                        ? translatedText.student_task_list_minute
                        : translatedText.student_task_list_minutes_multiple
                    }}
                    {{ translatedText.student_task_list_read }}
                  </p>
                  <app-student-progress-bar
                    [progress]="generatePercentForContinousTask([task])"
                  ></app-student-progress-bar>
                </div>
                <div
                  class="student_chip student_chip__deadline student_chip__deadline--reached"
                  *ngIf="currentDate == taskDeadline(task?.deadline)"
                >
                  <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                  <p class="paragraph deadline_task">
                    {{ translatedText.student_task_list_today_expired }}
                  </p>
                </div>
              </div>
              <div class="view_button">
                <button mat-flat-button color="primary-student" type="button" (click)="openTaskPreview(task?._id)">
                  {{ translatedText.student_task_list_start }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="student_task_wrap" *ngIf="isFinished">
  <ng-container>
    <div class="row">
      <div class="col-md-12" *ngFor="let task of getTasksByStatus('finished')">
        <div class="student_task student_task_finished" [attr.task-id]="task?._id">
          <div class="student_task_inner">
            <div class="set_inline student_task_header">
              <div class="student_chip student_chip__teacher no_bg hidden-tablet">
                <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
                <p class="paragraph">
                  <span *ngFor="let teacher of task?._teacher; let i = index">
                    <span [hidden]="i == 0"> / </span>
                    {{ getSalutation(teacher) }}
                    {{ teacher?.lastname }}
                  </span>
                </p>
              </div>
              <div class="inline_text done_description">
                <mat-icon svgIcon="streamline_filled:done" class="hidden-mobile"></mat-icon>
                <p class="paragraph paragraph--l">
                  <ng-container *ngIf="task?.type == 'text'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    ><span *ngIf="task?.book_name">
                      {{ translatedText.student_task_list_in }}
                      <strong>{{ replaceHtmlContent(task?.book_name) }}</strong></span
                    >.
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time-choice'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    >
                    {{ translatedText.student_task_list_read_book }}
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'autonomous-placement'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                </p>
              </div>
              <div class="inline_text teacher_header">
                <p class="paragraph">
                  {{ translatedText.student_task_list_completed_at }}&nbsp;
                  <strong>{{ task?.completed_at | date: 'd. MMM y' }}</strong>
                </p>
                <!--<div>
                                     <mat-icon svgIcon="streamline_filled:clock"></mat-icon> 
                                </div>-->
                <div class="student_chip student_chip__teacher hidden-mobile">
                  <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
                  <p class="paragraph">
                    <span *ngFor="let teacher of task?._teacher; let i = index">
                      <span [hidden]="i == 0"> / </span>
                      {{ getSalutation(teacher) }}
                      {{ teacher?.lastname }}
                    </span>
                  </p>
                </div>
                <button
                  mat-icon-button
                  color="filled"
                  type="button"
                  class="task_note"
                  (click)="openNotificationList(task?.notification, task)"
                  *ngIf="task?.notification != undefined && task?.notification?.recipient[0]?.readAt == null"
                >
                  <mat-icon class="header_notification" svgIcon="streamline_outlined:send-mail"></mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="filled"
                  type="button"
                  class="task_note read"
                  (click)="openNotificationList(task?.notification, task)"
                  *ngIf="task?.notification != undefined && task?.notification?.recipient[0]?.readAt != null"
                >
                  <mat-icon class="header_notification" svgIcon="streamline_outlined:mail"></mat-icon>
                </button>
              </div>
            </div>
            <!-- <div class="set_inline student_task_book_name">
                            <p class="paragraph">Buch: {{task?.book_name}}</p>
                        </div> -->

            <div class="student_task_footer">
              <!-- <div class="inline_text record-rtc_result_info" *ngIf="getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance == '1'">
                                <ng-container *ngIf="getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingErrorNote(task?.reading_score?.reading_errors_score)?.performance == 'standard'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingErrorNote(task?.reading_score?.reading_errors_score)?.performance == 'sad'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.description}}</p>
                            </div>
                            <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.reading_speed_words">
                                <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '2'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '3'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '4'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '5'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.description}}</p>
                            </div>
                            <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.reading_dynamic_normalized">
                                <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '2'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '3'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '4'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '5'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.description}}</p>
                            </div>
                            <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.num_deletions">
                                <ng-container *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '2'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '3'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '4'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '5'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.description}}</p>
                            </div>
                            <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.num_substitutions">
                                <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '2'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '3'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '4'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '5'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.description}}</p>
                            </div>
                            <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.num_insertions">
                                <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '1'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '2'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '3'">
                                    <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '4'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '5'">
                                    <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                                </ng-container>
                                <p class="paragraph">{{getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.description}}</p>
                            </div> -->
            </div>
            <div class="set_inline">
              <div class="smiley_results">
                <div class="smiley_results_wrap" *ngIf="task?.split_sessions">
                  <ng-container *ngIf="task?.split_sessions[0]?.level_wcpm == '1'">
                    <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="task?.split_sessions[0]?.level_wcpm == '2'">
                    <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="task?.split_sessions[0]?.level_wcpm == '3'">
                    <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="task?.split_sessions[0]?.level_wcpm == '4'">
                    <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="task?.split_sessions[0]?.level_wcpm == '5'">
                    <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
                  </ng-container>
                  <p class="paragraph">
                    {{ getLevelNotes(task?.split_sessions[0]?.level_wcpm) }}
                  </p>
                </div>
                <!-- <div class="smiley_results_wrap" *ngIf="task?.reading_score?.reading_speed_words"
                                [ngClass]="(getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '1' || getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '2') ? 'smiley_results_wrap__smile' : (getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '5' ? 'smiley_results_wrap__sad' : (getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '3' || getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '4'  ? 'smiley_results_wrap__mad' : ''))">
                                    <mat-icon *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '2'" svgIcon="streamline_filled:smiley_2"></mat-icon>
                                    <mat-icon *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '3'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '4'" svgIcon="streamline_filled:smiley_4"></mat-icon>
                                    <mat-icon *ngIf="getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance == '5'" svgIcon="streamline_filled:smiley_5"></mat-icon>
                                    <p class="paragraph">Geschwindigkeit</p>
                                </div>
                                <div class="smiley_results_wrap" *ngIf="getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance == '1'"
                                        [ngClass]="getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance == '1' ? 'smiley_results_wrap__smile' : ''">
                                    <mat-icon *ngIf="getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <p class="paragraph">Lesefehler</p>
                                </div>
                                <div class="smiley_results_wrap" *ngIf="task?.reading_score?.reading_dynamic_normalized"
                                        [ngClass]="(getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '2' || getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '1') ? 'smiley_results_wrap__smile' : (getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '5' ? 'smiley_results_wrap__sad' : (getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '4' || getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '3' ? 'smiley_results_wrap__mad' : ''))">
                                    <mat-icon *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '2'" svgIcon="streamline_filled:smiley_2"></mat-icon>
                                    <mat-icon *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '3'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '4'" svgIcon="streamline_filled:smiley_4"></mat-icon>
                                    <mat-icon *ngIf="getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '5'" svgIcon="streamline_filled:smiley_5"></mat-icon>
                                    <p class="paragraph">Betonung</p>
                                </div>
                                <div class="smiley_results_wrap" *ngIf="task?.reading_score?.num_deletions"
                                        [ngClass]="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '1' ? 'smiley_results_wrap__smile' : (getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '5' || getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '4' ? 'smiley_results_wrap__sad' : (getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '2' || getDeletionsNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '3' ? 'smiley_results_wrap__mad' : ''))">
                                    <mat-icon *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '2'" svgIcon="streamline_filled:smiley_2"></mat-icon>
                                    <mat-icon *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '3'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '4'" svgIcon="streamline_filled:smiley_4"></mat-icon>
                                    <mat-icon *ngIf="getDeletionsNote(task?._group?.grade, task?.reading_score?.num_deletions)?.performance == '5'" svgIcon="streamline_filled:smiley_5"></mat-icon>
                                    <p class="paragraph">Auslassungen</p>
                                </div>
                                <div class="smiley_results_wrap" *ngIf="task?.reading_score?.num_substitutions"
                                        [ngClass]="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '1' ? 'smiley_results_wrap__smile' : (getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '5' || getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '4' ? 'smiley_results_wrap__sad' : (getDeletionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '2' || getSubstitutionsNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)?.performance == '3' ? 'smiley_results_wrap__mad' : ''))">
                                    <mat-icon *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '2'" svgIcon="streamline_filled:smiley_2"></mat-icon>
                                    <mat-icon *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '3'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '4'" svgIcon="streamline_filled:smiley_4"></mat-icon>
                                    <mat-icon *ngIf="getSubstitutionsNote(task?._group?.grade, task?.reading_score?.num_substitutions)?.performance == '5'" svgIcon="streamline_filled:smiley_5"></mat-icon>
                                    <p class="paragraph">Ersetzungen</p>
                                </div>
                                <div class="smiley_results_wrap" *ngIf="task?.reading_score?.num_insertions"
                                        [ngClass]="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '1' ? 'smiley_results_wrap__smile' : (getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '5' || getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '4' ? 'smiley_results_wrap__sad' : (getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '2' || getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '3' ? 'smiley_results_wrap__mad' : ''))">
                                    <mat-icon *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '1'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '2'" svgIcon="streamline_filled:smiley_2"></mat-icon>
                                    <mat-icon *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '3'" svgIcon="streamline_filled:smiley_3"></mat-icon>
                                    <mat-icon *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '4'" svgIcon="streamline_filled:smiley_4"></mat-icon>
                                    <mat-icon *ngIf="getInsertionsNotes(task?._group?.grade, task?.reading_score?.num_insertions)?.performance == '5'" svgIcon="streamline_filled:smiley_5"></mat-icon>
                                    <p class="paragraph">Hinzufügungen</p>
                                </div> -->
              </div>
              <div
                class="student_chip student_chip__readingpoints_info"
                *ngIf="task?.reading_score?.reading_combined_score"
              >
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  WCPM:
                  {{ task?.reading_score?.reading_speed_correct_words_per_minute | round: 0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="student_task_wrap student_task_wrap_expired" *ngIf="isExpired">
  <ng-container>
    <div class="row">
      <div class="col-md-12" *ngFor="let task of getTasksByStatus('expired')">
        <div class="student_task_inner">
          <div class="student_task student_task_expired" [attr.task-id]="task?._id">
            <div class="set_inline student_task_header">
              <div class="expired-header-chips">
                <div class="student_chip student_chip__expired">
                  <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                  <p class="paragraph">
                    {{ translatedText.student_task_list_expired }}
                  </p>
                </div>
                <!-- <div class="student_chip student_chip__deadline">
                                    <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
                                    <p class="paragraph">{{getFormattedDate(task?.deadline)}}</p>
                                </div> -->
              </div>

              <div class="student_chip student_chip__teacher no_bg">
                <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
                <p class="paragraph">
                  <span *ngFor="let teacher of task?._teacher; let i = index">
                    <span [hidden]="i == 0"> / </span>
                    {{ getSalutation(teacher) }}
                    {{ teacher?.lastname }}
                  </span>
                </p>
              </div>
            </div>
            <div class="student_task_expired_footer">
              <div class="student_task_info">
                <mat-icon svgIcon="streamline_filled:lightning hidden-mobile"></mat-icon>
                <p class="paragraph paragraph--l">
                  <ng-container *ngIf="task?.type == 'text'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    ><span *ngIf="task?.book_name">
                      {{ translatedText.student_task_list_in }}
                      <strong>{{ replaceHtmlContent(task?.book_name) }}</strong></span
                    >.
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'time-choice'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong
                      >{{ task?.time }}
                      {{
                        task?.time === 1
                          ? translatedText.student_task_list_minute
                          : translatedText.student_task_list_minutes
                      }}</strong
                    >
                    {{ translatedText.student_task_list_read_book }}
                    <span *ngIf="task?.mode == 'multiple'">{{ translatedText.student_task_list_multiple_steps }}</span>
                  </ng-container>
                  <ng-container *ngIf="task?.type == 'autonomous-placement'">
                    {{ translatedText.student_task_list_read_loud }}
                    <strong>{{ task?.wordcount }} {{ translatedText.student_task_list_words }}</strong>
                    {{ translatedText.student_task_list_in }}
                    <strong>{{ replaceHtmlContent(task?.book_name) }}</strong
                    >.
                  </ng-container>
                </p>
              </div>
              <!-- <div class="view_button">
                                <button mat-flat-button color="primary" type="button" [disabled]="true">{{translatedText.student_task_list_start}}</button>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="student_task_wrap" *ngIf="isselfing">
  <ng-container>
    <div class="row">
      <div class="col-md-6" *ngFor="let task of getselfingTasks()">
        <div class="student_task">
          <div class="student_task_inner">
            <div class="set_inline student_readingpoints_info">
              <div class="inline_text">
                <mat-icon svgIcon="streamline_filled:gold-medal"></mat-icon>
                <p class="paragraph paragraph--l">
                  {{ translatedText.student_task_list_reading_points }}
                  <span class="paragraph paragraph--bold paragraph--l">{{
                    task?.reading_score?.reading_combined_score
                  }}</span>
                </p>
              </div>
              <!-- <p class="paragraph paragraph--s">15%</p> -->
            </div>
            <div class="student_task_info">
              <div class="inline_text">
                <ng-container *ngIf="task?.type == 'self'">
                  <p class="paragraph paragraph--s">
                    {{ translatedText.student_task_list_book }}
                    <span class="paragraph paragraph--bold paragraph--s">{{
                      replaceHtmlContent(task?.book_name)
                    }}</span>
                  </p>
                </ng-container>
              </div>
              <!-- <div class="inline_text">
                                <ng-container *ngIf="task?.type == 'self'">
                                    <p class="paragraph paragraph--s">Minuten: <span class="paragraph paragraph--bold paragraph--s">{{task?.time}}</span></p>
                                </ng-container>
                            </div> -->
              <div class="inline_text">
                <p class="paragraph paragraph--s">
                  {{ translatedText.student_task_list_completed_at }}
                  <span class="paragraph paragraph--bold paragraph--s">{{
                    task?.completed_at | date: 'dd.MM.yyyy'
                  }}</span>
                </p>
              </div>
            </div>
            <div class="student_task_footer">
              <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.reading_dynamic_normalized">
                <ng-container
                  *ngIf="
                    getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)
                      ?.performance == 'good'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <ng-container
                  *ngIf="
                    getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)
                      ?.performance == 'standard'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <p class="paragraph notes_description">
                  {{
                    getReadingDynamicNote(task?._group?.grade, task?.reading_score?.reading_dynamic_normalized)
                      ?.description
                  }}
                </p>
              </div>
              <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.reading_errors_score">
                <ng-container
                  *ngIf="
                    getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance ==
                    'good'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <ng-container
                  *ngIf="
                    getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.performance ==
                    'standard'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <p class="paragraph notes_description">
                  {{ getReadingErrorNote(task?._group?.grade, task?.reading_score?.reading_errors_score)?.description }}
                </p>
              </div>
              <div class="inline_text record-rtc_result_info" *ngIf="task?.reading_score?.reading_speed_words">
                <ng-container
                  *ngIf="
                    getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance ==
                    'good'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <ng-container
                  *ngIf="
                    getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.performance ==
                    'standard'
                  "
                >
                  <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                </ng-container>
                <p class="paragraph notes_description">
                  {{ getReadingSpeedNote(task?._group?.grade, task?.reading_score?.reading_speed_words)?.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
