import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as SentryAngular from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';

if (
  environment.hasOwnProperty('sentryDSN') &&
  environment.hasOwnProperty('sentryEnvironment')
) {
  if (environment.sentryEnvironment !== 'localhost') {
    const sentryOptions = {
      dsn: environment.sentryDSN,
      release: 'genesis-frontend@' + require('../package.json').version,
      environment: environment.sentryEnvironment,
      dist: require('../package.json').hotfix,
      tracesSampleRate: 1.0,
    };
    // Web frontend
    sentryOptions['integrations'] = [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: SentryAngular.routingInstrumentation,
      }),
    ];
    SentryAngular.init(sentryOptions);
  }
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
