
<div class="notification-list_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <img src="../../../assets/notification-list.svg" alt="" />
  <ng-container *ngFor="let notification of notifications; let i = index">
    <ng-container *ngIf="currentIndex === i">
      <ng-container *ngIf="userType == 'student'">
        <h2 class="h2 h2--bold">{{ translatedText.notification_list_from }} {{ notification.fullName }}.</h2>
        <p class="paragraph">
          <ng-container *ngIf="view == 'list'"> {{ translatedText.notification_list_no_new }}: </ng-container>
          <ng-container *ngIf="view != 'list'"> {{ translatedText.notification_list_task }}: </ng-container>
          <ng-container
            *ngIf="
              notification?._task?.type == 'diagnostic-pre' ||
              notification?._task?.type == 'diagnostic-mid' ||
              notification?._task?.type == 'diagnostic-post' ||
              notification?._task?.type == 'autonomous-placement'
            "
          >
            {{ translatedText.notification_list_complete }}.
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'text'">
            {{ translatedText.notification_list_read_loud }}
            <strong>{{ notification?._task?.wordcount }} {{ translatedText.notification_list_words }}</strong>
            {{ translatedText.notification_list_in }} <strong [innerHTML]="notification?._task?.book_name"></strong>.
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'time'">
            {{ translatedText.notification_list_read_loud }}
            <strong
              >{{ notification?._task?.time }}
              {{
                notification?._task?.time === 1
                  ? translatedText.notification_list_minute
                  : translatedText.notification_list_minutes
              }}</strong
            ><span *ngIf="notification?._task?.book_name">
              {{ translatedText.notification_list_in }}
              <strong [innerHTML]="notification?._task?.book_name"></strong></span
            >.
            <span *ngIf="notification?._task?.mode == 'multiple'">{{
              translatedText.notification_list_multiple
            }}</span>
          </ng-container>
          <ng-container *ngIf="notification?._task?.type == 'time-choice'">
            {{ translatedText.notification_list_read_loud }}
            <strong
              >{{ notification?._task?.time }}
              {{
                notification?._task?.time === 1
                  ? translatedText.notification_list_minute
                  : translatedText.notification_list_minutes
              }}</strong
            >
            {{ translatedText.notification_list_selection }}
            <span *ngIf="notification?._task?.mode == 'multiple'">{{
              translatedText.notification_list_multiple
            }}</span>
          </ng-container>
        </p>
      </ng-container>
      <div class="notification-list-content">
        <mat-icon class="semicolon_top" svgIcon="streamline_filled:semicolon-top"></mat-icon>
        <div class="notification-list-message">
          <p class="h4">{{ notification.message }}</p>
        </div>

        <mat-icon class="semicolon_bottom" svgIcon="streamline_filled:semicolon-bottom"></mat-icon>
      </div>
      <div class="view_button">
        <button
          mat-flat-button
          color="primary-student"
          type="button"
          *ngIf="currentIndex !== notifications.length - 1"
          (click)="nextNotification()"
        >
          {{ translatedText.notification_list_next }}
        </button>
        <button
          mat-flat-button
          color="primary-student"
          type="button"
          *ngIf="currentIndex === notifications.length - 1"
          (click)="closeNotificationList()"
        >
          {{ translatedText.notification_list_ok }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
