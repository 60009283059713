<div class="cancel-add-container">
  <button mat-mini-fab class="close-button close-button--right" [mat-dialog-close]="true">
    <mat-icon svgIcon="streamline_outlined:close"> </mat-icon>
  </button>
  <mat-icon class="large-icon" svgIcon="streamline_outlined:warning-circle-2"></mat-icon>
  <h3 class="h3 h3--bold">{{ translatedText.cancel_add_students }}</h3>
  <p class="pararaph paragraph--l">{{ translatedText.cancel_add_students_text }}</p>
  <div class="cancel-add-buttons">
    <button mat-flat-button color="secondary" (click)="cancel()">
      {{ translatedText.admin_group_create_cancel }}
    </button>
    <button mat-flat-button color="primary" (click)="closeAll()">
      {{ translatedText.admin_manage_teacher_confirm }}
    </button>
  </div>
</div>
