<div class="group-delete_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap admin-managing-wrap">
    <ng-container *ngIf="managingType == 'demote' && !isSuccess">
      <mat-icon svgIcon="streamline_outlined:warning-circle-3"></mat-icon>

      <h2 class="h2 h2--bold">{{ translatedText.teacher_Revoke_admin }}</h2>
      <p class="paragraph paragraph--xl">
        {{ translatedText.teacher_Revoke_admin_desc }}
      </p>

      <mat-icon svgIcon="streamline_filled:teacher_icon_v2" class="mt-4 teacher-icon"></mat-icon>

      <h2 class="h3 h3--bold">
        {{ admin.firstname }} {{ admin.lastname }}

        <!-- {{
          translatedText.t("admin_managing_overlay_remove_admin_rights", {
            firstname: admin?.firstname,
            lastname: admin?.lastname
          })
        }} -->
      </h2>

      <div class="button-wrap">
        <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
          {{ translatedText.admin_managing_overlay_cancel }}
        </button>
        <button mat-flat-button color="primary" type="button" (click)="removeAdminRights()">
          {{ translatedText.teacher_assign_admin_confirm }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="managingType == 'promote' && !isSuccess">
      <mat-icon svgIcon="streamline_outlined:info_empty_circle-3"></mat-icon>
      <h2 class="h2 h2--bold">
        {{ translatedText.teacher_assign_admin_rights }}
      </h2>
      <p class="paragraph paragraph--xl">
        {{ translatedText.teacher_assign_admin_rights_desc }}
      </p>

      <mat-icon svgIcon="streamline_filled:teacher_icon_v2" class="mt-4 teacher-icon"></mat-icon>

      <h2 class="h3 h3--bold">{{ admin.firstname }} {{ admin.lastname }}</h2>
      <div class="button-wrap">
        <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
          {{ translatedText.admin_managing_overlay_cancel }}
        </button>
        <button mat-flat-button color="primary" type="button" (click)="assignAdminRights()">
          {{ translatedText.teacher_Revoke_admin_confirm }}
        </button>
      </div>
    </ng-container>

    <div *ngIf="isSuccess">
      <mat-icon svgIcon="streamline_outlined:success_tick_v2"></mat-icon>
      <h2 class="h2 h2--bold" *ngIf="managingType == 'promote'">
        {{ translatedText.teacher_assign_admin_successfully }}
      </h2>
      <h2 class="h2 h2--bold" *ngIf="managingType == 'demote'">
        {{ translatedText.teacher_revoke_admin_successfully }}
      </h2>

      <div class="single-button-wrap">
        <button mat-flat-button color="primary" type="button" [mat-dialog-close]="'close'">
          {{ translatedText.teacher_assign_admin_rights_success }}
        </button>
      </div>
    </div>

    <!-- <img class="footer_group-delete_img"  src="../../../assets/laletu-logo.svg" alt=""> -->
  </div>
</div>
