import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { ClassDetailComponent } from './class-detail.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProgressBarModule } from '../../helpers/progress-bar/progress-bar.module';
import { TeacherTaskListModule } from '../../teacher/teacher-task-list/teacher-task-list.module';
import { DirectivesModule } from '../../directives/directives.module';
import { ComboChartModule } from '../../helpers/combo-chart/combo-chart.module';

@NgModule({
  declarations: [ClassDetailComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    ProgressBarModule,
    TeacherTaskListModule,
    DirectivesModule,
    ComboChartModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [ClassDetailComponent],
})
export class ClassDetailModule {}
