import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { AdminNewSchoolYearComponent } from "src/app/admin/admin-new-school-year/admin-new-school-year/admin-new-school-year.component";
import { TranslationService } from "src/app/providers/translation.service";

@Component({
  selector: "app-classes-year-update",
  templateUrl: "./classes-year-update.component.html",
  styleUrls: ["./classes-year-update.component.scss"],
})
export class ClassesYearUpdateComponent {
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<ClassesYearUpdateComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    // this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  updateClassOverLay() {
    this.closeDialog();
    let dialogRef = this.dialog.open(AdminNewSchoolYearComponent, {
      width: "100%",
      autoFocus: false,
      panelClass: "full-page-panel",
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: "create-training-task-panel-backdrop",
      data: {},
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
