import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-license-trial-extension',
  templateUrl: './license-trial-extension.component.html',
  styleUrls: ['./license-trial-extension.component.scss'],
})
export class LicenseTrialExtensionComponent implements OnInit {
  translatedText: any;
  route: any;
  trialReason: string = '';
  isTrialRequest: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public Data: any, // TODO: private or public?
    private dialog: MatDialog,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  sendRequset() {
    console.log(this.trialReason);
    this.isTrialRequest = true;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }
}
