import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrialBannerModule } from 'src/app/trial-phase/trial-banner/trial-banner.module';
import { AppRoutingModule } from '../../../app-routing.module';
import { AuthInterceptor } from '../../../auth/auth-interceptor';
import { DirectivesModule } from '../../../directives/directives.module';
import { ErrorInterceptor } from '../../../error/error-interceptor';
import { ProgressBarModule } from '../../../helpers/progress-bar/progress-bar.module';
import { MaterialModule } from '../../../material.module';
import { AdminGroupListComponent } from './admin-group-list.component';
import { ClassesUpdateBannerModule } from 'src/app/helpers/classes-year-update/classes-update-banner/classes-update-banner-module';
import { LicenseActivationBannerModule } from 'src/app/license/license-activation-banner/license-activation-banner.module';

@NgModule({
  declarations: [AdminGroupListComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    DirectivesModule,
    TrialBannerModule,
    ClassesUpdateBannerModule,
    LicenseActivationBannerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [AdminGroupListComponent],
})
export class AdminGroupListModule {}
