import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from 'src/app/material.module';
import { LicenseTrialExtensionComponent } from './license-trial-extension.component';

@NgModule({
  declarations: [LicenseTrialExtensionComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
  ],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [LicenseTrialExtensionComponent],
})
export class LicenseTrialExtensionModule {}
