import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { TaskService } from '../../providers/task.service';

export interface Tasks {
  type: string;
  deadline: string;
  completed: boolean;
}
@Component({
  selector: 'app-dashboard-diagnostics',
  templateUrl: './dashboard-diagnostics.component.html',
  styleUrls: ['./dashboard-diagnostics.component.scss'],
})
export class DashboardDiagnosticsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  studentId;
  tasks: Tasks[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private taskService: TaskService,
  ) {}

  ngOnInit() {
    this.studentId = this.authService.getStudentId();

    this.taskService.getTasksToStudents(this.studentId);
    this.taskService
      .getTasksToStudentsUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tasks: Tasks[]) => {
        this.tasks = tasks;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
