import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { MaterialModule } from '../../material.module';
import { DiagnosticResultsComponent } from './diagnostic-results.component';

@NgModule({
  declarations: [DiagnosticResultsComponent],
  imports: [BrowserModule, AppRoutingModule, MaterialModule, HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [DiagnosticResultsComponent],
})
export class DiagnosticResultsModule {}
