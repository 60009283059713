import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { StudentReportComponent } from './student-report.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StatusBarModule } from '../../helpers/status-bar/status-bar.module';
import { BookReaderModule } from '../../book-reader/book-reader.module';
import { StudentProgressLevelModule } from 'src/app/helpers/student-progress-level/student-progress-level.module';

@NgModule({
  declarations: [StudentReportComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    StatusBarModule,
    BookReaderModule,
    StudentProgressLevelModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [StudentReportComponent],
})
export class StudentReportModule {}
