import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { AwardOverlayComponent } from './award-overlay.component';

@NgModule({
  declarations: [AwardOverlayComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [AwardOverlayComponent],
})
export class AwardOverlayModule {}
