import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

// TODO: unused?
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit, OnDestroy {
  environment = environment;
  isForgotPassword: boolean = false;
  isRegisterTeacher: boolean = false;
  isWebmail: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    if (this.injectedData['params'] === 'registerTeacher') {
      this.isRegisterTeacher = true;
    } else if (this.injectedData['params'] === 'forgotPassword') {
      this.isForgotPassword = true;
    } else if (this.injectedData['params'] === 'webmail') {
      this.isWebmail = true;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  navToLogin() {
    this.router.navigate(['/login/teacher']);
    this.dialog.closeAll();
  }
}
