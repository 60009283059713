<div class="container-fluid login-container">
  <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
  <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
  <div class="login row">
    <div class="col-md-12">
      <div class="login_wrap">
        <h2 class="h2 h2--bold">{{ headline }}</h2>
        <p class="paragraph paragraph--l text-center">
          {{ translatedText.create_password_title }}
        </p>
        <form [formGroup]="rForm" #formDirective="ngForm">
          <div class="forgot-password">
            <mat-label>{{ translatedText.create_password_new_password }}</mat-label>
            <mat-form-field>
              <input
                #passwordField
                matInput
                formControlName="password"
                type="password"
                placeholder="{{ translatedText.create_password_placeholder }}"
                required
              />
              <button
                mat-fab
                type="button"
                class="hide-password"
                (click)="
                  passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
                "
                matSuffix
              >
                <mat-icon *ngIf="passwordField.type === 'password'" svgIcon="streamline_outlined:closed_eye"></mat-icon>
                <mat-icon *ngIf="passwordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
              </button>

              <mat-error *ngIf="rForm.get('password').hasError('required')">
                <!-- Please enter your new password -->
                {{ translatedText.create_password_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('password').hasError('pattern')">
                <!-- Password not strong enough -->
                {{ translatedText.create_password_strong }}
              </mat-error>
            </mat-form-field>
            <ul class="password-description">
              <li>{{ translatedText.create_password_length }}</li>
              <li>{{ translatedText.create_password_upper_lower }}</li>
              <li>{{ translatedText.create_password_numbers }}</li>
              <li>{{ translatedText.create_password_special }}</li>
            </ul>
          </div>

          <div class="forgot-password">
            <mat-label>{{ translatedText.create_password_repeat }}</mat-label>
            <mat-form-field>
              <input
                #passwordFieldRepeat
                matInput
                formControlName="confirmPassword"
                type="password"
                placeholder="{{ translatedText.create_password_repeat_placeholder }}"
                [errorStateMatcher]="matcher"
                required
              />
              <button
                mat-fab
                type="button"
                class="hide-password"
                (click)="
                  passwordFieldRepeat.type === 'password'
                    ? (passwordFieldRepeat.type = 'text')
                    : (passwordFieldRepeat.type = 'password')
                "
                matSuffix
              >
                <mat-icon
                  *ngIf="passwordFieldRepeat.type === 'password'"
                  svgIcon="streamline_outlined:closed_eye"
                ></mat-icon>
                <mat-icon
                  *ngIf="passwordFieldRepeat.type === 'text'"
                  svgIcon="streamline_outlined:opened_eye"
                ></mat-icon>
              </button>
              <mat-error *ngIf="!rForm.get('password').hasError('pattern') && rForm.get('confirmPassword').touched">
                <!-- Passwords do not match -->
                {{ translatedText.create_password_repeat_error }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="instructionVideo_wrap">
            <button
              [disabled]="rForm.invalid"
              mat-flat-button
              color="primary"
              (click)="submitForm(formDirective)"
              type="submit"
              class="mat-flat-button--full"
              aria-label="LOG IN"
            >
              {{ translatedText.create_password_save }}
            </button>
          </div>
        </form>
        <img class="footer-img" [src]="'/assets/' +  environment.whitelabel.logo" alt="" />
      </div>
    </div>
    <!-- <div class="col-md-6 login_right">
      <img src="../../../assets/login.svg" alt="Login">
    </div> -->
  </div>
</div>
