// https://github.com/inorganik/ngx-countUp
import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { CountUp, CountUpOptions } from 'countup.js';

@Directive({
  selector: '[countUp]',
})
export class CountUpDirective implements OnChanges {
  // the value you want to count to
  @Input('countUp') endVal: number;

  @Input() options: CountUpOptions = {
    duration: 1,
    decimal: ',',
    separator: '.',
  };
  @Input() reanimateOnClick = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() complete = new EventEmitter<void>();

  // Re-animate if preference is set.
  @HostListener('click')
  onClick() {
    if (this.reanimateOnClick) {
      this.animate();
    }
  }

  countUp: CountUp;

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // don't animate server-side (universal)
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const { options, endVal } = changes;
    if (endVal?.currentValue !== undefined) {
      if (this.countUp !== undefined) {
        this.zone.runOutsideAngular(() => {
          this.countUp.update(this.endVal);
        });
      } else {
        this.initAndRun();
      }
    } else if (options?.currentValue !== undefined) {
      this.initAndRun();
    }
  }

  animate(): void {
    this.zone.runOutsideAngular(() => {
      this.countUp.reset();
      this.countUp.start(() => {
        this.zone.run(() => {
          this.complete.emit();
        });
      });
    });
  }

  private initAndRun(): void {
    this.zone.runOutsideAngular(() => {
      this.countUp = new CountUp(
        this.el.nativeElement,
        this.endVal,
        this.options,
      );
      if (!this.options.enableScrollSpy) {
        this.animate();
      }
    });
  }
}
