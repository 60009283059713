<div class="loading-container" *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" class="teacher-task-list-container">
  <div class="row teacher-task-list-filter-wrap">
    <div
      class="col-md-12 task-filter-row"
      *ngIf="
        status != 'all' ||
        filterFormGroup.get('type').value?.length > 0 ||
        filterFormGroup.get('groups').value?.length > 0 ||
        (deadlineRange.get('start').value !== null && deadlineRange.get('end').value !== null)
      "
    >
      <button mat-flat-button color="unset" type="button" class="reset-filter" (click)="resetFilter()">
        {{ translatedText.teacher_task_list_reset_filter }}
      </button>
    </div>
    <div class="col-xl-3 col-md-6">
      <mat-form-field class="book_list_select task_status_select" floatLabel="auto">
        <mat-label matTooltip="{{ translatedText.teacher_task_list_status_info }}">
          {{ translatedText.teacher_task_list_status }}
        </mat-label>
        <mat-select
          panelClass="dashboard-task-select"
          [(value)]="status"
          disableRipple
          (selectionChange)="selectTaskStatus($event)"
        >
          <mat-option class="dropdown-select" value="all">{{ translatedText.teacher_task_list_all_tasks }}</mat-option>
          <mat-option value="active">{{ translatedText.teacher_task_list_active_tasks }}</mat-option>
          <mat-option value="finished">{{ translatedText.teacher_task_list_finished_tasks }}</mat-option>
          <mat-option value="expired">{{ translatedText.teacher_task_list_expired_tasks }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-3 col-md-6">
      <mat-form-field class="book_list_select task_type_select" floatLabel="auto">
        <mat-label class="task-label-wrap" matTooltip="{{ translatedText.teacher_task_list_task_type_info }}"
          >{{ translatedText.teacher_task_list_task_type }}
        </mat-label>
        <mat-select
          [formControl]="filterFormGroup.get('type')"
          floatLabel="never"
          panelClass="dashboard-task-select"
          disableOptionCentering
          multiple
        >
          <mat-select-trigger>
            {{ generateSelectionText(taskTypes, filterFormGroup.get('type').value) }}
          </mat-select-trigger>
          <app-select-all-option
            [control]="filterFormGroup.get('type')"
            [values]="taskTypes"
            [valuetype]="'object'"
            (selectionChange)="selectTaskType($event)"
            [translatedText]="translatedText"
          ></app-select-all-option>
          <mat-option *ngFor="let type of taskTypes" [value]="type?.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-3 col-md-6">
      <mat-form-field class="book_list_select task_status_select" floatLabel="auto">
        <mat-label class="task-label-wrap group" matTooltip="{{ translatedText.teacher_task_list_group_info }}">
          {{ translatedText.teacher_task_list_group }}
        </mat-label>
        <mat-select
          [formControl]="filterFormGroup.get('groups')"
          floatLabel="never"
          panelClass="dashboard-task-select"
          disableOptionCentering
          multiple
        >
          <mat-select-trigger>
            {{ generateSelectionText(taskGroupOptions, filterFormGroup.get('groups').value) }}
          </mat-select-trigger>
          <app-select-all-option
            [control]="filterFormGroup.get('groups')"
            [values]="taskGroupOptions"
            [valuetype]="'object'"
            (selectionChange)="onSelectChange($event, 'groups')"
            [translatedText]="translatedText"
          ></app-select-all-option>
          <mat-option *ngFor="let type of taskGroupOptions" [value]="type?.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-3 col-md-6">
      <!-- <mat-form-field class="book_list_select task_status_select ">
                <div>
                  <mat-label>{{translatedText.teacher_task_list_deadline}}:</mat-label>
                </div>
                <mat-select floatLabel="never" panelClass="dashboard-task-select" [(ngModel)]="taskDeadline" disableOptionCentering (selectionChange)="selectTaskDeadline($event)">
                  <mat-option value="week">Aktuelle Woche</mat-option>
                  <mat-option value="month">Aktueller Monat</mat-option>
                  <mat-option value="next_three_months">Nächsten 3 Monate</mat-option>
                  <mat-option value="next_six_months">Nächsten 6 Monate</mat-option>
                  <mat-option value="school_year">Schuljahr 22-23</mat-option>
                </mat-select>
            </mat-form-field> -->
      <mat-form-field class="book_list_select task_status_select datepicker_select">
        <mat-label matTooltip="{{ translatedText.teacher_task_list_deadline_info }}"
          >{{ translatedText.teacher_task_list_deadline }}
        </mat-label>
        <mat-date-range-input [formGroup]="deadlineRange" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="{{ translatedText.teacher_task_list_start }}"
            (ngModelChange)="selectTaskDeadline()"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="{{ translatedText.teacher_task_list_end }}"
            (ngModelChange)="selectTaskDeadline()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="deadlineRange.controls.start.hasError('matStartDateInvalid')">{{
          translatedText.teacher_task_list_start_error
        }}</mat-error>
        <mat-error *ngIf="deadlineRange.controls.end.hasError('matEndDateInvalid')">{{
          translatedText.teacher_task_list_end_error
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="isTableView && dataSource?.data.length > 0">
    <div class="table-wrapper">
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource?.data"
          multiTemplateDataRows
          class="mat-elevation-z8 active-table"
          matSort
          matSortDisableClear
          [matSortActive]="sortBy"
          [matSortDirection]="matSortDirection"
          (matSortChange)="onSortChange($event)"
        >
          <ng-container matColumnDef="username" sticky>
            <th mat-sort-header="username" mat-header-cell *matHeaderCellDef class="mat-column-align--left">
              <span class="caption">{{ translatedText.teacher_task_list_student }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                <div class="table-inline">
                  <img
                    class="avatar_img--small"
                    *ngIf="element?._student?.avatarUrl"
                    src="{{ element?._student?.avatarUrl }}"
                  />
                  <div class="inline_text">
                    <p class="paragraph paragraph--bold">
                      {{ element?._student?.username }}
                    </p>
                  </div>
                </div>
              </ng-container>

              <!-- <ng-container *ngIf="element?.students?.length > 1">
                            <div class="table-inline">
                                <mat-icon svgIcon="streamline_filled:class-2"></mat-icon>
                                <div class="inline_text">
                                    <p class="paragraph paragraph--bold">
                                        {{element?.groupName}}
                                    </p>
                                </div>
                            </div>
                        </ng-container> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="_group">
            <th mat-sort-header="_group" mat-header-cell *matHeaderCellDef class="mat-column-align--left">
              <span class="caption">{{ translatedText.teacher_task_list_group }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                <div class="table-inline">
                  <div class="inline_text">
                    <p class="paragraph paragraph--bold">
                      {{ element?._group.name }}
                    </p>
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th
              mat-sort-header="type"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.teacher_task_list_task_type_info_table }}"
            >
              <span class="caption">{{ translatedText.teacher_task_list_task_type_table }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- <span class="paragraph table_chip table_chip--{{element?.type}}"
                                [innerHtml]="generateTaskTypeTitle(element?.type, element?.mode)">
                            </span> -->
              <span
                class="paragraph table_chip table_chip--{{ element?.type }}"
                [ngClass]="{ 'table_chip--hasicon': generateTaskTypeTitle(element?.type, element?.mode).hasIcon }"
              >
                {{ generateTaskTypeTitle(element?.type, element?.mode).title }}
                <mat-icon
                  *ngIf="generateTaskTypeTitle(element?.type, element?.mode).hasIcon"
                  svgIcon="{{ generateTaskTypeTitle(element?.type, element?.mode).iconName }}"
                ></mat-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th
              mat-sort-header="created"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.teacher_task_list_created_at_info }}"
            >
              <span class="caption">{{ translatedText.teacher_task_list_created }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <p class="paragraph paragraph--s">
                {{ element?.created | date: 'dd.MM.yyyy' }}
              </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="deadline">
            <th
              mat-sort-header="deadline"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.teacher_task_list_deadline_info_table }}"
            >
              <span class="caption">{{ translatedText.teacher_task_list_deadline_table }}</span>
              <!-- <ng-container *ngIf="status == 'all' || status == 'expired'">
                                <span class="caption">{{translatedText.teacher_task_list_deadline_table}}</span>
                            </ng-container> -->
              <!-- <ng-container *ngIf="status == 'active'">
                                <span class="caption">{{translatedText.teacher_task_list_deadline_table}}</span>
                            </ng-container>
                            <ng-container *ngIf="status == 'finished'">
                                <span class="caption">Erledigt am</span>
                            </ng-container> -->
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- <ng-container *ngIf="element.completed_at">
                                <p class="paragraph paragraph--s">
                                    {{element?.completed_at | date: 'dd.MM.yyyy'}}
                                </p>
                            </ng-container> -->
              <!-- <ng-container *ngIf="!element.completed_at">
                                <p class="paragraph paragraph--s">
                                    {{element?.deadline | date: 'dd.MM.yyyy'}}
                                </p>
                            </ng-container> -->
              <p class="paragraph paragraph--s">
                {{ element?.deadline | date: 'dd.MM.yyyy' }}
              </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-sort-header="description"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--center"
              matTooltip="{{ translatedText.teacher_task_list_content_info }}"
            >
              <span class="caption">{{ translatedText.teacher_task_list_details }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.type === 'text' ||
                  element.type === 'diagnostic-pre' ||
                  element.type === 'diagnostic-mid' ||
                  element.type === 'diagnostic-post' ||
                  element.type === 'autonomous-placement'
                "
              >
                <p class="paragraph paragraph--s">
                  {{ element?.wordcount }} {{ translatedText.teacher_task_list_words }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time' && element.mode != 'autonomous'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{
                    element?.time === 1
                      ? translatedText.teacher_task_list_minute
                      : translatedText.teacher_task_list_minutes
                  }}
                </p>
              </ng-container>
              <ng-container *ngIf="element.mode == 'autonomous'">
                <p class="paragraph paragraph--s" *ngIf="element?.students?.length == 1">
                  {{ element?.subtasks[0]?.time }}
                  {{ element?.subtasks[0]?.time === 1 ?
                    translatedText.teacher_task_list_minute :
                    '{{translatedText.teacher_task_list_minutes




                  }}' }}
                </p>
                <p class="paragraph paragraph--s" *ngIf="element?.students?.length > 1">-</p>
              </ng-container>
              <ng-container *ngIf="element.mode == 'autonomous' && element.type == 'time'">
                <p class="paragraph paragraph--s">15 {{ translatedText.teacher_task_list_minutes }}</p>
              </ng-container>
              <ng-container *ngIf="element.type == 'time-choice'">
                <p class="paragraph paragraph--s">
                  {{ element?.time }}
                  {{
                    element?.time === 1
                      ? translatedText.teacher_task_list_minute
                      : translatedText.teacher_task_list_minutes
                  }}
                </p>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="book_name">
            <th
              mat-sort-header="book_name"
              mat-header-cell
              *matHeaderCellDef
              class="mat-column-align--left"
              matTooltip="{{ translatedText.teacher_task_list_book_info }}"
            >
              <span class="caption">{{ translatedText.teacher_task_list_content }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <span
                  *ngIf="element?.book_name != undefined && element?.book_name != ''"
                  class="paragraph paragraph--s"
                >
                  {{ replaceHtmlContent(element?.book_name) }}
                </span>
                <span
                  *ngIf="element.mode == 'autonomous' && element?.students?.length == 1"
                  class="paragraph paragraph--s"
                >
                  {{ replaceHtmlContent(element?.subtasks[0]?.book_name) }}
                </span>
                <span
                  *ngIf="
                    (element?.book_name == undefined || element?.book_name == '') &&
                    element?.type != 'time-choice' &&
                    element.mode == 'autonomous' &&
                    element?.students?.length > 1
                  "
                  class="paragraph paragraph--s"
                  >-</span
                >
                <span
                  *ngIf="
                    (element?.type == 'time-choice' && element?.book_name != '') ||
                    (element?.type == 'time-choice' && element?.book_name != undefined)
                  "
                  class="paragraph paragraph--s"
                  >{{ translatedText.teacher_task_list_self }}</span
                >
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="button" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="mat-column-align--right">
              <div class="table-buttons-wrap">
                <!-- <button mat-icon-button color="filled" type="button" class="license-accept" matSuffix [routerLink]="['/task-detail/' + element?._id]">
                                    <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                                </button> -->
                <!-- <button mat-icon-button color="filled" class="task_list_note" type="button" matTooltip="Aufgabe bearbeiten" matTooltipPosition="above" (click)="showAlert()">
                                <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                                </button> -->
                <div class="table-buttons">
                  <ng-container
                    *ngIf="element.mode != 'multiple' && element.completed_at && element.status !== 'expired'"
                  >
                    <button
                      mat-mini-fab
                      aria-label="view-detail"
                      matTooltip="{{ translatedText.teacher_task_list_show_result }}"
                      [queryParams]="{ studentId: element?._student?._id }"
                      [routerLink]="['/student-report/' + element?._id]"
                    >
                      <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="!element.completed_at || element.mode == 'multiple' || element.status == 'expired'"
                  >
                    <button
                      mat-mini-fab
                      aria-label="view-detail"
                      matTooltip="{{ translatedText.teacher_task_list_show_detail }}"
                      [routerLink]="['/task-detail/' + element?._master_task]"
                    >
                      <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                    </button>
                  </ng-container>

                  <button
                    mat-mini-fab
                    class="not-filled"
                    [matMenuTriggerFor]="moreTeacherMenu"
                    (click)="$event.stopPropagation()"
                    matTooltip="{{ translatedText.teacher_task_list_more_options }}"
                    type="button"
                  >
                    <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                  </button>
                </div>

                <mat-menu #moreTeacherMenu="matMenu">
                  <!-- <button mat-menu-item aria-label="view-detail" [routerLink]="['/task-detail/' + element?._id]">
                                        <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                                        <span>Detailansicht</span>
                                    </button> -->
                  <!-- <ng-container *ngIf="element.status == 'active'">
                                        <button mat-menu-item aria-label="view-detail" *ngIf="element.mode != 'autonomous'" (click)="editTask(element?._master_task, element.type)">
                                            <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                                            <span>Bearbeiten</span>
                                        </button>
                                    </ng-container> -->

                  <ng-container
                    *ngIf="
                      (element?.type === 'diagnostic-pre' ||
                        element?.type === 'diagnostic-mid' ||
                        element?.type === 'diagnostic-post') &&
                      element?.repeated === 0 &&
                      element?.status === 'finished'
                    "
                  >
                    <button
                      mat-menu-item
                      aria-label="view-detail"
                      (click)="repeatDiagnostic(element?._master_task, element?.type, element?._student._id)"
                      matTooltip="{{ translatedText.teacher_task_list_repeats_info }}"
                    >
                      <mat-icon svgIcon="streamline_outlined:refresh"></mat-icon>
                      <span>{{ translatedText.teacher_task_list_repeat_diagnostic }}</span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="element.completed_at">
                    <button
                      mat-menu-item
                      aria-label="view-detail"
                      [routerLink]="['/task-detail/' + element?._master_task]"
                    >
                      <mat-icon svgIcon="streamline_outlined:table"></mat-icon>
                      {{ translatedText.teacher_task_list_show_detail }}
                    </button>
                  </ng-container>

                  <ng-container>
                    <button
                      mat-menu-item
                      aria-label="view-detail"
                      (click)="openStudentOverview(element?._student._id, element?._group._id)"
                    >
                      <mat-icon svgIcon="streamline_outlined:user-circle"></mat-icon>
                      {{ translatedText.teacher_task_list_show_profile }}
                    </button>
                  </ng-container>

                  <ng-container>
                    <button
                      mat-menu-item
                      aria-label="view-class"
                      [routerLink]="['/class-detail/' + element?._group._id]"
                    >
                      <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
                      {{ translatedText.teacher_task_list_show_class }}
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expand" sticky>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.mode !== 'multiple'">
                <ng-container *ngIf="element.status == 'finished'">
                  <mat-icon
                    [matTooltip]="
                      translatedText.task_list_completed + ' ' + (element.completed_at | date: 'dd.MM.yyyy')
                    "
                    color="green"
                    svgIcon="streamline_outlined:checkmark"
                  ></mat-icon>
                </ng-container>

                <ng-container *ngIf="element.status == 'expired'">
                  <mat-icon color="red" svgIcon="streamline_outlined:delete-circle"></mat-icon>
                </ng-container>
              </ng-container>
              <button
                mat-icon-button
                aria-label="expand row"
                *ngIf="element?._split_task?.length > 1"
                (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
              >
                <mat-icon *ngIf="expandedElement !== element">navigate_next</mat-icon>
                <mat-icon *ngIf="expandedElement === element">expand_more</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" class="nested_table_td" [attr.colspan]="displayedColumns.length + 1">
              <ng-template [ngIf]="element?._split_task?.length > 1">
                <table
                  mat-table
                  [dataSource]="element?._split_task"
                  class="example-element-detail nested-table"
                  [ngClass]="element == expandedElement ? 'expanded' : 'collapsed'"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                >
                  <!-- Define the columns for the nested table -->
                  <ng-container matColumnDef="placeholder">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header"></th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <mat-icon
                        *ngIf="nestedElement.completed_at"
                        [matTooltip]="
                          translatedText.task_list_completed + ' ' + (element.completed_at | date: 'dd.MM.yyyy')
                        "
                        color="green"
                        svgIcon="streamline_outlined:checkmark"
                      ></mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header" class="mat-column-align--left">
                      {{ translatedText.teacher_task_list_class }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <div class="table-inline">
                        <img
                          class="avatar_img--small"
                          *ngIf="element?._student?.avatarUrl"
                          src="{{ element?._student?.avatarUrl }}"
                        />
                        <div class="inline_text">
                          <p class="paragraph paragraph--bold">
                            {{ element?._student?.username }}
                          </p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="_group">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header" class="mat-column-align--left">
                      {{ translatedText.teacher_task_list_group }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <div class="table-inline">
                        <div class="inline_text">
                          <p class="paragraph paragraph--bold">
                            {{ element?._group.name }}
                          </p>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-align--left">
                      <span class="caption">{{ translatedText.teacher_task_list_task_type_table }}</span>
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph table_chip table_chip--{{ element?.type }}"
                        [ngClass]="{
                          'table_chip--hasicon': generateTaskTypeTitle(element?.type, element?.mode).hasIcon
                        }"
                      >
                        {{ generateTaskTypeTitle(element?.type, element?.mode).title }}
                        <mat-icon
                          *ngIf="generateTaskTypeTitle(element?.type, element?.mode).hasIcon"
                          svgIcon="{{ generateTaskTypeTitle(element?.type, element?.mode).iconName }}"
                        ></mat-icon>
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                      <span class="caption">{{ translatedText.teacher_task_list_created_at }}</span>
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p class="paragraph paragraph--s">
                        {{ element?.created | date: 'dd.MM.yyyy' }}
                      </p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="deadline">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-align--left">
                      <span class="caption">{{ translatedText.teacher_task_list_deadline_table }}</span>
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p class="paragraph paragraph--s">
                        {{ element?.deadline | date: 'dd.MM.yyyy' }}
                      </p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                      <span class="caption">{{ translatedText.teacher_task_list_details }}</span>
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p class="paragraph paragraph--s">
                        {{ nestedElement?.time_used.toFixed(2) }} {{ translatedText.teacher_task_list_minutes }}
                        <!-- <ng-container *ngIf="nestedElement?.time_used < 1">
                                                {{ (nestedElement?.time_used * 60).toFixed(0)}} Sekunden
                                            </ng-container>
                                            <ng-template #minutesBlock>
                                                {{ nestedElement?.time_used < 2 ? translatedText.teacher_task_list_minute : '{{translatedText.teacher_task_list_minutes}}' }}
                                            </ng-template> -->
                      </p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="book_name">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-align--left">
                      <span class="caption">{{ translatedText.teacher_task_list_content }}</span>
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <div>
                        <span
                          *ngIf="
                            element?.book_name !== undefined &&
                            element?.book_name !== '' &&
                            element?.mode !== 'autonomous'
                          "
                          class="paragraph paragraph--s"
                        >
                          {{ replaceHtmlContent(element?.book_name) }}
                        </span>
                        <span *ngIf="element?.mode === 'autonomous'" class="paragraph paragraph--s">{{
                          replaceHtmlContent(element?.book_name)
                        }}</span>
                        <span
                          *ngIf="
                            element?.book_name === undefined ||
                            (element?.book_name === '' && element?.type != 'time-choice')
                          "
                          class="paragraph paragraph--s"
                          >-</span
                        >
                        <span
                          *ngIf="
                            (element?.type === 'time-choice' && element?.book_name == '') ||
                            element?.book_name === undefined
                          "
                          class="paragraph paragraph--s"
                          >{{ translatedText.teacher_task_list_self }}</span
                        >
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="button" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let nestedElement" class="mat-column-align--right">
                      <ng-container *ngIf="nestedElement.completed_at">
                        <button
                          mat-mini-fab
                          color="filled"
                          aria-label="Report"
                          matTooltip="{{ translatedText.teacher_task_list_show_result }}"
                          [queryParams]="{
                            studentId: element?._student?._id,
                            sessionUuid: nestedElement.session_uuid
                          }"
                          [routerLink]="['/student-report/' + element?._id]"
                        >
                          <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                        </button>
                      </ng-container>
                      <!-- <ng-container *ngIf="nestedElement.status == 'active'">
                                            <button mat-icon-button color="filled"  aria-label="Report" (click)="showAlert()">
                                                <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                                            </button>
                                        </ng-container> -->
                    </td>
                  </ng-container>

                  <!-- Replace the below line with your desired nested table columns -->
                  <!-- Add more ng-container elements for additional columns -->

                  <!-- Define the nested table rows -->
                  <tr class="hidden_nested_tr" mat-header-row *matHeaderRowDef="nestedColumns"></tr>
                  <tr mat-row *matRowDef="let nestedElement; columns: nestedColumns"></tr>
                </table>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: columnsToDisplayWithExpand"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
      <div class="paginator-container">
        <mat-paginator
          [hidePageSize]="true"
          [length]="dataSource?.meta.totalItems"
          [pageSize]="
            dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
              ? dataSource?.meta.itemsPerPage
              : dataSource?.meta.totalItems
          "
          (page)="pageEvent = $event; onPaginateChange($event)"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>

  <!-- <div *ngIf="getTasksByStatus('active').length == 0" class="empty_tasks_container">
        <img src="../../../assets/no_tasks.svg" alt="">
        <p class="paragraph" >Sie haben noch keine Aufgaben erstellt</p>
    </div> -->
  <div *ngIf="dataSource?.data.length == 0" class="empty_tasks_container">
    <img src="../../../assets/empty.svg" alt="" />
    <p class="paragraph paragraph--bold">{{ translatedText.teacher_task_list_no_tasks }}</p>
  </div>
</div>
