import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookService } from 'src/app/providers/book.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';

export interface DialogData {
  name: string;
}

// TODO: unused?
@Component({
  selector: 'app-book-complexity-rating',
  templateUrl: './book-complexity-rating.component.html',
  styleUrls: ['./book-complexity-rating.component.scss'],
})
export class BookComplexityRatingComponent implements OnInit, OnDestroy {
  @ViewChild('commentInput', { static: true }) commentInput: ElementRef;
  rating: number = 0;
  isbn;
  translatedText;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<BookComplexityRatingComponent>,
    private route: ActivatedRoute,
    private bookService: BookService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['isbn'] !== 'undefined') {
        this.isbn = this.injectedData['sibn'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  setRating(rating) {
    this.rating = rating;
  }

  submitForm() {
    console.log(this.commentInput.nativeElement.value);
    console.log(this.rating);
    const data = {
      rating: this.rating,
      comment: this.commentInput.nativeElement.value,
    };
    this.bookService
      .setBookRating(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dialogRef.close();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: this.translatedText?.book_complexity_success,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        error: (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.technical_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  closePdfOverlay() {
    // Reset the form
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
