import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminNewSchoolYearComponent } from 'src/app/admin/admin-new-school-year/admin-new-school-year/admin-new-school-year.component';
import { AdminService } from 'src/app/providers/admin.service';

@Component({
  selector: 'app-group-widget',
  templateUrl: './group-widget.component.html',
  styleUrls: ['./group-widget.component.scss'],
})
export class GroupWidgetComponent implements OnInit, OnDestroy {
  @Input('groups') groups: any;
  @Input('showError') showError: boolean = false;

  userType;
  diagnosticLicenseStatusActive = false;
  trainingLicenseStatusActive = false;
  licenseCount: number = 0;
  translatedText: any;
  currentSchoolYear: string | null = null;
  isSchoolYearUpdateRequired: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private adminService: AdminService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translations;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    this.licenseService
      .getActiveLicensesUpdatedListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.diagnosticLicenseStatusActive =
          status.di === 'active' ? true : false;
        if (this.diagnosticLicenseStatusActive) {
          this.licenseCount++;
        }
        this.trainingLicenseStatusActive =
          status.fo === 'active' ? true : false;
        if (this.trainingLicenseStatusActive) {
          this.licenseCount++;
        }
      });
    this.getSchoolYearUpdateInfo();
  }

  checkStudentsGender(students) {
    return students.filter((item) => item.gender === '').length;
  }

  showUpdateButton(group) {
    let currentYearNumber = Number(this.currentSchoolYear.slice(-2));
    let groupSchoolYearNumber = Number(group.school_year.slice(-2));

    return (
      (!group.archived && group.archived != undefined) &&
      ((groupSchoolYearNumber === currentYearNumber &&
        this.isSchoolYearUpdateRequired) ||
        groupSchoolYearNumber < currentYearNumber)
    );
  }

  onSelectedLicenseViewChange(group, value) {
    group.selectedLicenseView = value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groups && changes.groups.currentValue.length > 0) {
      if (!this.trainingLicenseStatusActive) {
        this.groups.map((item) => {
          item.selectedLicenseView = 'license-1';
        });
      }
    }
  }

  newSchoolYearOverlay(group: any) {
    let dialogRef = this.dialog.open(AdminNewSchoolYearComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        group: group,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      this.isSchoolYearUpdateRequired = data.yearChange;
      this.currentSchoolYear = data.year;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
