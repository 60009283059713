<app-group-create-stepper
  #cdkStepper
  [linear]="true"
  [stepValue]="step"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
  [blobUrl]="blobUrl"
  [isLoading]="isLoading"
>
  <cdk-step *ngIf="step === 1">
    <div class="step_padding step_padding--scroll">
      <div class="training-task_create_headline">
        <h3 class="h2 h2--bold">{{ translatedText.admin_group_class_details }}</h3>
      </div>
      <form [formGroup]="firstFormStep" class="group-create-form">
        <div class="paragraph paragraph--bold paragraph--l">
          <div class="selected-group-name-details">
            <div>{{ translatedText.admin_group_name_of_class }}</div>

            <div class="selected-group-name">
              <span *ngIf="firstFormStep.get('grade')?.value != ''">{{ firstFormStep.get('grade')?.value }}</span>
              <span class="disable-color" *ngIf="firstFormStep.get('grade')?.value == ''">0</span>
              <span *ngIf="firstFormStep.get('stream')?.value != ''">{{ firstFormStep.get('stream')?.value }}</span>
              <span class="disable-color" *ngIf="firstFormStep.get('stream')?.value == ''">A</span>
              - {{ firstFormStep.get('school_year')?.value | slice : 2 }}
            </div>
          </div>
        </div>
        <div class="row first_step">
          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_grade }}</mat-label>
            <mat-form-field>
              <mat-select
                formControlName="grade"
                (selectionChange)="gradeSelection($event)"
                type="role"
                disableOptionCentering
                panelClass="grade-select"
                placeholder="{{ translatedText.admin_group_create_select_paceholder }}"
              >
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
                <mat-option value="3">3</mat-option>
                <mat-option value="4">4</mat-option>
                <mat-option value="5">5</mat-option>
                <mat-option value="6">6</mat-option>
                <mat-option value="7">7</mat-option>
                <mat-option value="8">8</mat-option>
                <mat-option value="9">9</mat-option>
                <mat-option value="10">10</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormStep.get('grade').hasError('required')">
                <!-- Please choose a class. -->
                {{ translatedText.admin_group_create_group_grade_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_stream }}</mat-label>
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="{{ translatedText.admin_group_create_group_stream }}"
                aria-label="Klassenzug"
                [matAutocomplete]="auto"
                formControlName="stream"
                required
              />
              <!-- <mat-icon
                class="register-icon"
                matTooltip="{{ translatedText.admin_group_create_group_stream_tooltip }}"
                svgIcon="streamline_outlined:hint"
              ></mat-icon> -->
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  <span>{{ item }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="firstFormStep.get('stream').hasError('required')">
                <!-- Please enter a stream.  -->
                {{ translatedText.admin_group_create_group_stream_error }}
              </mat-error>
              <mat-error *ngIf="firstFormStep.get('stream').hasError('onlyLetters')">
                <!-- Please enter a stream.  -->
                {{ translatedText.admin_group_create_noDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_school_year }}</mat-label>
            <mat-form-field>
              <mat-select formControlName="school_year" type="role" disableOptionCentering panelClass="grade-select">
                <mat-option [value]="item.value" *ngFor="let item of schoolYears">{{ item.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="firstFormStep.get('school_year').hasError('required')">
                <!-- Please choose a class. -->
                {{ translatedText.admin_group_create_group_school_year_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="paragraph paragraph--bold paragraph--l">
              <div class="selected-group-name-details">
                <div>{{ translatedText.admin_dashboard_teachers }}</div>
              </div>
              <div>
                <div class="label-wrap">
                  <mat-label>{{ translatedText.admin_manage_teacher_selection }}</mat-label>
                  <p class="paragraph paragraph--s">{{ translatedText.admin_manage_maximum_teacher }}</p>
                </div>
                <mat-form-field class="group-select">
                  <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
                  <mat-select
                    formControlName="teachers"
                    (valueChange)="selectedTeacherValue($event)"
                    [max]="3"
                    panelClass="admin-group-select teacher-managing-group-select"
                    disableOptionCentering
                    [multiple]="isMultipleSelection"
                    [placeholder]="translatedText.home_text_61"
                  >
                    <mat-select-trigger>
                      <div class="selected-group-container">
                        <ng-container *ngFor="let teacher of filteredTeachers(); let i = index">
                          <div class="selected-group teacher_with_icon teacher_with_icon--gray">
                            <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                            <p class="paragraph paragraph--bold paragraph--xs">
                              {{ teacher?.firstname }} {{ teacher?.lastname }}
                            </p>
                            <mat-icon
                              [hidden]="checkForCurrentTeacher(teacher)"
                              svgIcon="streamline_filled:teacher_close_icon"
                              (click)="removeSelectedTeacher(i, $event)"
                            ></mat-icon>
                          </div>
                        </ng-container>
                      </div>
                    </mat-select-trigger>
                    <mat-option
                      class="teacher-selection"
                      *ngFor="let teacher of teachers"
                      [value]="teacher?._id"
                      [disabled]="
                        checkTeacherArray(teacher?._id, groupTeachers) ||
                        checkGrouplength(groupTeachers) ||
                        (teacher?._id == teacherId && teacher?._user?.role == 'teacher')
                      "
                    >
                      <div class="teacher_options-wrap">
                        <div class="teacher_with_icon teacher_with_icon--gray option">
                          <div class="d-inline-flex">
                            <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                            <p class="paragraph paragraph--s ml-3">
                              {{ teacher?.firstname }} {{ teacher?.lastname }}
                              <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                            </p>
                          </div>
                        </div>
                        <div class="teachers-group">
                          <p class="paragraph paragraph--s" *ngFor="let group of teacher._group">
                            {{ group?.name }} - {{ group?.school_year }}<span>,</span>
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="avatar-selection">
              <p class="paragraph paragraph--bold paragraph--l">
                {{ translatedText.admin_group_create_avatar_headline }}
              </p>
              <mat-radio-group formControlName="avatar" aria-label="Select an option">
                <mat-radio-button value="animal" [checked]="recommendedAvatar === 'animal'">
                  <div class="avatar_desc">
                    <p class="paragraph paragraph--bold">
                      {{ translatedText.admin_group_create_avatar_animal }}
                      <span *ngIf="recommendedAvatar == 'animal'" class="avatar_recommended">{{
                        translatedText.admin_group_create_avatar_recomended
                      }}</span>
                    </p>
                    <p class="paragraph paragraph paragraph--s">
                      {{ translatedText.admin_group_create_animal_grades }}
                    </p>
                  </div>
                  <img src="assets/animal_package.svg" alt="" />
                </mat-radio-button>
                <mat-radio-button value="planet" [checked]="recommendedAvatar === 'planet'">
                  <div class="avatar_desc">
                    <p class="paragraph paragraph--bold">
                      {{ translatedText.admin_group_create_avatar_planet }}
                      <span *ngIf="recommendedAvatar == 'planet'" class="avatar_recommended">{{
                        translatedText.admin_group_create_avatar_recomended
                      }}</span>
                    </p>
                    <p class="paragraph paragraph paragraph--s">
                      {{ translatedText.admin_group_create_planat_grades }}
                    </p>
                  </div>
                  <img src="assets/planet_package.svg" alt="" />
                </mat-radio-button>
              </mat-radio-group>
              <div class="avatar-hint-box">
                <app-hint-box
                  [title]="translatedText.admin_group_class_hint_avatar_title"
                  [text]="translatedText.admin_group_class_hint_avatar_text"
                ></app-hint-box>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </cdk-step>

  <cdk-step *ngIf="step === 2">
    <div class="step_padding">
      <div class="training-task_create_headline">
        <h3 class="h3 h3--bold">{{ translatedText.students_add_class_number }}</h3>
      </div>
      <form [formGroup]="studentAmountForm" class="student-amount-form">
        <div class="row second_step select_students_amount">
          <div class="col-md-12">
            <div class="student_selection_wrap">
              <p class="paragraph paragraph--bold paragraph--xl mb-3">
                {{ translatedText.admin_group_number_of_learns_in_class }}
              </p>
              <mat-form-field class="mat-form-field">
                <div class="number-wrap">
                  <button (click)="decreaseValue()">
                    <mat-icon svgIcon="streamline_filled:license-minus"></mat-icon>
                  </button>
                  <input
                    matInput
                    type="number"
                    formControlName="studentAmount"
                    [min]="0"
                    [max]="40"
                    (input)="onStudentAmountInput()"
                  />
                  <button (click)="increaseValue()">
                    <mat-icon svgIcon="streamline_filled:license-plus"></mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12">
            <mat-error *ngIf="studentAmountForm.hasError('min')">
              {{ translatedText.admin_group_create_students_amount_min }}
            </mat-error>
            <mat-error *ngIf="studentAmountForm.hasError('max')">
              {{ translatedText.admin_group_create_students_amount_max }}
            </mat-error>
          </div>
          <div class="col-md-12">
            <div class="admin-class-list-licenses-count">
              <div class="admin-class-list-licenses-wrap w-full diagnosis">
                <span class="licenses-title-wrap">
                  <mat-icon svgIcon="streamline_outlined:target" class="pink-icon"></mat-icon>
                  {{ translatedText.admin_group_list_table_header_diagnostic }}</span
                >
                <span
                  ><span [countUp]="totalCountLeftDiagnostic" *ngIf="totalCountDiagnostic < 100">{{
                    totalCountLeftDiagnostic
                  }}</span>
                  <span *ngIf="totalCountDiagnostic >= 100"> ∞ </span>
                  <ng-container *ngIf="totalCountLeftDiagnosticExp > 0">
                    |
                    <span [countUp]="totalCountLeftDiagnosticExp">{{ totalCountLeftDiagnosticExp }}</span>
                  </ng-container>
                </span>
              </div>
              <div class="admin-class-list-licenses-wrap w-full training">
                <span class="licenses-title-wrap">
                  <mat-icon svgIcon="streamline_outlined:lightning" class="green-icon"></mat-icon>
                  {{ translatedText.admin_group_list_table_header_training }}</span
                >
                <span>
                  <span [countUp]="totalCountLeftTraining">{{ totalCountLeftTraining }}</span>
                  <ng-container *ngIf="totalCountLeftTrainingExp > 0">
                    |
                    <span [countUp]="totalCountLeftTrainingExp">{{ totalCountLeftTrainingExp }}</span>
                  </ng-container>
                </span>
              </div>
            </div>

            <div class="admin-class-create-licenses-information" *ngIf="displayEnoughLicensesNote()">
              <p class="paragraph paragraph--bold">
                <mat-icon svgIcon="streamline_outlined:info-empty"></mat-icon>
                {{ translatedText.teacher_sidebar_hint_admin_group_information_create_title }}
              </p>
              <p class="paragraph">
                {{ translatedText.teacher_sidebar_hint_admin_group_information_create_text }}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </cdk-step>

  <cdk-step *ngIf="step === 3">
    <div class="step_padding profile_information_wrap">
      <app-create-learners
        [totalStudents]="studentAmountForm.get('studentAmount')?.value"
        [title]="translatedText.students_add_class_profile_info"
        [diagnosticTotalCount]="totalCountDiagnostic"
        [trainingTotalCount]="totalCountTraining"
        [diagnosticLeftCount]="totalCountLeftDiagnostic"
        [trainingLeftCount]="totalCountLeftTraining"
        [studentCount]="studentCount"
        [grade]="firstFormStep.get('grade')?.value"
      ></app-create-learners>
    </div>
  </cdk-step>
  <cdk-step *ngIf="step === 4">
    <div class="step_padding">
      <div class="training-task_create_headline">
        <h3 class="h3 h3--bold">{{ translatedText.generate_class_pdf }}</h3>
      </div>
    </div>
    <div class="generate-pdf-section">
      <div class="pdf-notification">
        <div class="notification-box open-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:shield-check-v2"></mat-icon>
                <div class="tn-heading">{{ translatedText.generate_pdf_data_protection }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.generate_pdf_data_protection_text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="pdf-container">
        <mat-icon svgIcon="streamline_filled:pdf-doc" class="icon-56" *ngIf="blobUrl === null"></mat-icon>
        <mat-icon svgIcon="streamline_filled:download-boxed" class="icon-56" *ngIf="blobUrl"></mat-icon>
        <p class="paragraph paragraph--bold paragraph--xl">
          {{ blobUrl === null ? translatedText.generate_class_pdf : translatedText.generate_class_pdf_ready }}
        </p>
        <p class="paragraph paragraph--m">
          {{ blobUrl === null ? translatedText.generate_pdf_attention_text : translatedText.generate_pdf_ready_text }}
        </p>
        <button
          mat-flat-button
          color="primary"
          class="mt-4"
          *ngIf="!isLoading && blobUrl === null"
          (click)="submitForm()"
        >
          {{ translatedText.generate_pdf_generate_PDF }}
        </button>
        <button mat-flat-button class="mt-4 button-light-green" *ngIf="isLoading">
          <mat-icon svgIcon="streamline_outlined:pdf-loader" class="mr-2"></mat-icon>
          {{ translatedText.generate_pdf_PDF_being_generated }}
        </button>
        <button mat-flat-button color="primary" class="mt-4" *ngIf="blobUrl" (click)="downloadPDF()">
          <mat-icon svgIcon="streamline_outlined:download-pdf" class="mr-2"></mat-icon>
          {{ translatedText.admin_group_list_download_pdf }}
        </button>
      </div>

      <div class="pdf-notification" *ngIf="!downloadSuccess">
        <div class="notification-box warn-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

                <div class="tn-heading">{{ translatedText.generate_pdf_save_where }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.generate_pdf_save_where_text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="pdf-notification" *ngIf="downloadSuccess">
        <div class="notification-box success-mode">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="tn-heading">
                <mat-icon svgIcon="streamline_outlined:checkmark"></mat-icon>

                <div class="tn-heading">{{ translatedText.admin_group_class_generate_pdf_successfully }}</div>
              </div>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_group_class_generate_pdf_successfully_desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
</app-group-create-stepper>
